import { IUser } from "us.common/interfaces/UserManagement/User";

export const userData: IUser = {
    id: 0,
    displayName: '',
    azureUserId: '',
    firstName: '',
    lastName: '',
    telephoneNo: '',
    email: '',
    description: '',
    maskPrivateData: false,
    activeState: true,
    hasDebtCollectionLicense: false,
    roles: [],
    creditors: [],
    creditorCompanies: [],
    creditorGroups: [],
    accessAuthorityLevelId: '',
    isCommentEnable: true,
    preferredLanguage: ""
}