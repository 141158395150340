import { MainCriterias } from "us.common/constants";
import {
  CombinedKeyType,
  IFormikSortMenuData,
  ITab,
  ITabsInfo,
} from "../Interfaces";
import { sortMenuBundle } from "./GetSortMenuItems";

/**
 * @function
 * @description To get the Sort menu data for the tab we are currently opened.
 * @param openTab The current viewing tab
 * @returns The sort menu data for the viewing tab
 */
export const getSortMenuPerTab = (
  openTab: MainCriterias
): IFormikSortMenuData[] => {
  try {
    return sortMenuBundle()
      .filter(({ tabOwnerInfo }) => {
        return (
          tabOwnerInfo.filter(({ tab }: any) => tab === openTab).length > 0
        );
      })
      .map(({ tabOwnerInfo, displayKey }) => ({
        ObjectProp: tabOwnerInfo.filter(({ tab }: any) => tab === openTab)[0]
          .matchingObjectProperty,
        displayKey: displayKey,
      }));
  } catch (e) {
    return []
  }
};

/**
 * @function
 * @description To get the information related to the open tab
 * @returns The tab information related  to the open tab
 */
export const getMatchingTabInfo = ({
  selectedTab,
  invoiceTab,
  caseTab,
  debtorTab,
  creditorTab,
}: ITabsInfo): ITab<CombinedKeyType> => {
  switch (selectedTab) {
    case MainCriterias.INVOICE:
      return invoiceTab;
    case MainCriterias.CASE:
      return caseTab;
    case MainCriterias.CREDITOR:
      return creditorTab;
    case MainCriterias.DEBTOR:
      return debtorTab;
    default:
      return invoiceTab;
  }
};
