import { isArray } from 'lodash';
import { ITabsInfo } from '../Interfaces';
import { MainCriterias } from 'us.common/constants';
import {
	ICaseSearchResult,
	IDebtorSearchResult,
} from 'us.common/reducers/MainSearch/Interfaces';
import { doesCaseInclude } from './DoesCaseInclude';
import { sortFunction } from './SortFunction';

/**
 * @description - Check whether the give id (case no, sub case no, debtor or creditor no) related result is viewed or not
 * @param {Array<string | number>} viewedList
 * @param {string | number} id
 */
export const isViewed = (
	viewedList: Array<string | number>,
	id: string | number
): Boolean => {
	try {
		if (viewedList.length > 0) {
			return viewedList.some((viewedId) => viewedId == id);
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};

/**
 * @description - Get debtor data to update
 * @param {number} debtorEntNo
 * @param {IDebtorSearchResult[]} debtorData
 */
export const getDebtorArData = (
	debtorEntNo: number,
	debtorData: IDebtorSearchResult[]
): IDebtorSearchResult[] => {
	try {
		if (isArray(debtorData)) {
			const data = debtorData.map((debtor: IDebtorSearchResult) => {
				if (debtor.debtorEntNo == debtorEntNo) {
					return {
						...debtor,
						isARCollapsed: !debtor.isARCollapsed,
					};
				} else {
					return { ...debtor };
				}
			});
			return data;
		} else {
			return [];
		}
	} catch (error) {
		return [];
	}
};

/**
 * @description - Get tab state history data
 * @param {ITabsInfo} tabState - Current tab state
 * @param {any} backNavigationData - Back navigation state data
 * @returns {ITabsInfo}
 */
export const getTabStateHolderHistory = (
	tabState: ITabsInfo,
	backNavigationData: any
): ITabsInfo => {
	try {
		if (tabState && backNavigationData) {
			const { activeTab, filters, currentHit } = backNavigationData;
			switch (activeTab) {
				case MainCriterias.INVOICE:
					return {
						...tabState,
						invoiceTab: {
							...tabState.invoiceTab,
							hitValue: currentHit,
						},
						selectedTab: activeTab,
						filterValues: filters,
					};
				case MainCriterias.CASE:
					return {
						...tabState,
						caseTab: {
							...tabState.caseTab,
							hitValue: currentHit,
						},
						selectedTab: activeTab,
						filterValues: filters,
					};
				case MainCriterias.DEBTOR:
					return {
						...tabState,
						debtorTab: {
							...tabState.debtorTab,
							hitValue: currentHit,
						},
						selectedTab: activeTab,
						filterValues: filters,
					};
				case MainCriterias.CREDITOR:
					return {
						...tabState,
						creditorTab: {
							...tabState.creditorTab,
							hitValue: currentHit,
						},
						selectedTab: activeTab,
						filterValues: filters,
					};
				default:
					return {
						...tabState,
						selectedTab: activeTab,
						filterValues: filters,
					};
			}
		} else {
			return tabState;
		}
	} catch (error) {
		return tabState;
	}
};

/* @description - Filter and sort main search result by tab data, sorting and filtering values
 * @param {any[]} data - tab results
 * @param {any} filterValues - filter values
 * @param {any} sortKey - sort key
 * @param {any} sortOrder - ASC or DESC (1 and 0)
 */
export const filterTabResult = (
	data: any[],
	filterValues: any,
	sortKey: any,
	sortOrder: any
) => {
	try {
		if (isArray(data)) {
			const filteredData = data
				.filter((caseL: ICaseSearchResult) =>
					doesCaseInclude(caseL, filterValues)
				)
				.sort((value1: { [x: string]: any }, value2: { [x: string]: any }) =>
					sortFunction(value1[sortKey], value2[sortKey], sortOrder)
				);
			return filteredData;
		} else {
			return [];
		}
	} catch (error) {
		return [];
	}
};