import { httpCollection } from 'us.helper/http/collection';
import { IRole, IRoleHistory } from "us.common/interfaces";
import { IPatchActiveState } from 'us.common/interfaces';

/**
 * Get role list
 */
export const getRoles = (): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data, status } = await httpCollection.get(
                'userservice',
                'roles',
                {}
            );
            if (status == 200) {
                resolve(data);
            } else {
                reject(data)
            }
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Get a role by roleId
 * @param action 
 */
export const getRolesById = <P extends { roleId: number }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.getById(
                'userservice',
                'roles',
                params.roleId
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 *  Add New Role
 * @param params 
 */
export const saveNewRole = (params: IRole): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.post(
                'userservice',
                'roles',
                params
            );
            resolve(data)
        } catch (error) {
            reject(error)
        }
    });
}

/**
 * Update existing role details
 * @param action 
 */
export const updateRole = (params: IRole): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.put(
                'userservice',
                `roles/${params.roleId}`,
                params
            );
            resolve(data)
        } catch (error) {
            reject(error)
        }
    });
}

/**
 * Delete Role using roleId
 * @param params 
 */
export const deleteRole = <P extends { roleId: number }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.delete(
                'userservice',
                'roles',
                params.roleId
            );
            resolve(data)
        } catch (error) {
            reject(error)
        }
    });
}

/**
 * Get Modules list with features and operations
 */
// export const getRoleModuleAndFeature = (): Promise<any> => {
//     return new Promise<{}>(async (resolve, reject) => {
//         try {
//             const { data } = await httpCollection.get(
//                 'userservice',
//                 'features',
//                 {}
//             );
//             resolve(data);
//         } catch (error) {
//             reject(error);
//         }
//     });
// }

export const getRoleModuleAndFeature = (): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.get(
                'userservice',
                'operations',
                {}
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Add a history record to role using roleId
 * @param params 
 */
export const saveRoleHistory = (params: any): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.post(
                'userservice',
                `roles/${params.roleId}/history`,
                params
            );
            resolve(data)
        } catch (error) {
            reject(error)
        }
    });
}

/**
 * Get role history records by roleId
 * @param params 
 */
export const getRolesHistoryById = <P extends { roleId: number }>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        const { roleId } = params
        try {
            const { data } = await httpCollection.getById(
                'userservice',
                'roles',
                `${roleId}/history`
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Change active state of role using roleId
 * @param params 
 */
export const roleDetailChangeById = <P extends IPatchActiveState>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
        try {
            const { data } = await httpCollection.patch(
                'userservice',
                `roles/${params.id}`,
                params.body
            );
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
}

export default {
    roles: {
        get: (): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.get(
                        'userservice',
                        'roles',
                        {}
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    role: {
        getById: <P extends { roleId: number }>(params: P): Promise<any> => {
            const { roleId } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.getById(
                        'userservice',
                        'roles',
                        roleId
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },


        save: (params: any): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.post(
                        'userservice',
                        'roles',
                        params
                    );
                    if (status == 201) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error)
                }
            });
        },
        update: (params: IRole): Promise<any> => {
            const { roleId } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.put(
                        'userservice',
                        `roles/${roleId}`,
                        params
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error)
                }
            });
        },
        delete: <P extends { roleId: number }>(params: P): Promise<any> => {
            const { roleId } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.delete(
                        'userservice',
                        'roles',
                        roleId
                    );
                    if (status == 204) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error)
                }
            });
        },
        patch: (params: any): Promise<any> => {
            const { roleId, body } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.patch(
                        'userservice',
                        `roles/${roleId}`,
                        body
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },
        getModules: (): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.get(
                        'userservice',
                        'operations',
                        {}
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error);
                }
            });
        },
        saveHistory: (params: IRoleHistory): Promise<any> => {
            const { roleId } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.post(
                        'userservice',
                        `roles/${roleId}/history`,
                        params
                    );
                    if (status == 201) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error)
                }
            });
        },
        getHistoryById: <P extends { roleId: number }>(params: P): Promise<any> => {
            const { roleId } = params
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data, status } = await httpCollection.getById(
                        'userservice',
                        'roles',
                        `${roleId}/history`
                    );
                    if (status == 200) {
                        resolve(data);
                    } else {
                        reject(data)
                    }
                } catch (error) {
                    reject(error);
                }
            });
        }
    }
}