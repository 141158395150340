import { AddOrUpdateSystemSettingParams } from '../Interfaces';

export function AddSystemSetting(this: any): AddOrUpdateSystemSettingParams {
	return {
		typeName: this.typeId === -1 ? this.newTypeName : this.typeName,
		name: this.name?.trim(),
		value: this.value?.trim(),
		description: this.description?.trim(),
		id: -1,
		typeId: this.typeId,
		isSensitiveData: this.isSensitiveData ?? true,
	};
}
