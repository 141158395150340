import React, { useState, useEffect,useMemo  } from 'react';
import { Table, Button, Divider } from 'antd';
import _ from 'lodash';
import { $AmountLabel } from 'us.common/components';
import { useTranslation } from 'react-i18next';
import { Filters, Sorter } from './Components';
import { CSVLink } from 'react-csv';
import { IconTypes, BoxIcons } from 'us.icons';
import { $Empty } from 'us.common/components';
import { connect } from 'react-redux';
import { ITableTree } from './interface';
import { VList } from "virtuallist-antd";
import "./TableTree.scss"; 

/**
 * @description - Table tree component for tree data.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 21/03/2021
 *  * @param data: any data array, add any hierarchical items as children as its parent element
 * the data array items must have child items as children field to work this TableTree
 * @param columns: please add additional special field to any parent item like title to show parent item text with extra space from other columns
 * @param showResetAllFilters: Display reset all function
 * @param filterOnType: Whether to display filtered data when user input
 * @param resetOnSourceChange: Reset table when dataSource change
 * @param renderCellParent: return any JSX element to render as parent cell
 * @param renderCellChild: return any JSX element to render as child cell
 */
const TableTree: React.FC<ITableTree> = ({
	data,
	columns,
	colSpan,
	virtual,
	filterOnType = false,
	showResetAllFilters = false,
	resetOnSourceChange = false,
	onSort,
	onFilter,
	onChange,
	renderCellParent,
	renderCellChild,
	renderExcelData = [],
	excelFileName,
	firstColSkipFilterProps = 0,
	currentCurrency,
	currentCurrencyLocal,
	currentDateFormat,
	currentLanguage,
	defaultFilters,
	defaultSorters,
	defaultData,
	components,
	pagination,
	scroll,
	...props
}) => {
	const { t } = useTranslation();
	const { locale } = props;

	const [sortedInfo, setSortedInfo] = useState<any>(defaultSorters ?? null);
	const [filteredData, setFilteredData] = useState<any>(defaultData ?? []);
	const [isFilteredDataEmpty, setFilteredDataEmpty] =
		useState<boolean>(false);

	const handleColumn = useState<any>(defaultFilters ?? []);
	const [filteredCols, setFilteredCols] = handleColumn;

	useEffect(() => {
		if (resetOnSourceChange) {
			setFilteredCols(defaultFilters ?? []);
			setFilteredData(defaultData ?? []);
			setSortedInfo(defaultSorters ?? null);
			setFilteredDataEmpty(false);
		}
	}, [data]);

  /**
   * @description AntD table virtualization.
   * Please use same value for table scroll-y.
   */
  const vComponents = useMemo(() => {
    return VList({
      resetTopWhenDataChange: false,
      height: scroll?.y ? scroll?.y : 'calc(100vh - 180px)',
    });
  }, [data]);

  const resetFilters = () => {
    setFilteredCols([]);
    setFilteredData([]);
  };

	// modify columns to set colSpan to parents
	const getColumns = () => {
		let dataCols: any = [];
		columns.map((column, index) => {
			let obj = {};
			let sortProps = column.hasOwnProperty('customSorter')
				? {
						sorter: true,
						sortOrder:
							sortedInfo?.field ===
								column?.dataIndex &&
							sortedInfo?.order,
				  }
				: {};
			let filterProps = column.hasOwnProperty('customFilter')
				? {
						...Filters({
							t,
							data,
							column,
							filterOnType,
							handleColumn,
							currentCurrencyLocal,
							currentDateFormat,
							currentLanguage,
							onFilter,
							onFinishFilter: (
								searchData: any
							) => {
								!searchData.length &&
									setFilteredDataEmpty(
										true
									);
								searchData.length &&
									setFilteredDataEmpty(
										false
									);
								setFilteredData(
									searchData
								);
								setSortedInfo(
									null
								);
							},
						}),
				  }
				: {};
			let headerTitle = column.showFilters
				? {
						title: (
							<div className='d-flex flex-column'>
								{!filteredCols.filter(
									(
										i: any
									) =>
										i.dataIndex ==
										column.dataIndex
								)[0]?.val &&
									column.title}{' '}
								{filteredCols.filter(
									(
										i: any
									) =>
										i.dataIndex ==
										column.dataIndex
								)[0]?.val && (
									<span
										style={{
											fontWeight: 'bold',
										}}>
										{
											column.title
										}{' '}
										<p>
											<span
												className='font-sm mt-n4'
												style={{
													fontWeight: 'bold',
												}}
												data-testid={`text-filtered-by-${column.dataIndex}`}>
												{t(
													'COMMON.FILTERED_BY'
												)}

												:{' '}
												{column?.customFilter !==
													'amount' &&
													filteredCols.filter(
														(
															i: any
														) =>
															i.dataIndex ==
															column.dataIndex
													)[0]
														?.val}
												{column?.customFilter ==
													'amount' && (
													<$AmountLabel
														value={
															filteredCols.filter(
																(
																	i: any
																) =>
																	i.dataIndex ==
																	column.dataIndex
															)[0]
																?.val
														}
													/>
												)}
											</span>
										</p>
									</span>
								)}
							</div>
						),
				  }
				: {};

			if (index === firstColSkipFilterProps) {
				obj = {
					...column,
					key: index,
					render: (text: any, row: any) =>
						renderCell(
							text,
							row,
							index,
							column
						),
				};
			} else {
				obj = {
					...filterProps,
					...column,
					key: index,
					render: (text: any, row: any) =>
						renderCell(
							text,
							row,
							index,
							column
						),
				};
			}

			dataCols.push({ ...obj, ...sortProps, ...headerTitle });
		});
		return dataCols;
	};

	const renderCell = (
		text: any,
		row: any,
		index: number,
		column: any
	) => {
		if (typeof column?.customRender == 'function') {
			return column?.customRender(
				text,
				row,
				index,
				column,
				columns
			);
		} else {
			if (!row.hasOwnProperty('children')) {
				if (
					typeof column?.customRenderChild ==
					'function'
				) {
					return column?.customRenderChild(
						text,
						row,
						index,
						column,
						columns
					);
				} else {
					return typeof renderCellChild ==
						'function'
						? renderCellChild({
								text,
								row,
								index,
								column,
						  })
						: text;
				}
			} else {
				if (
					typeof column?.customRenderParent ==
					'function'
				) {
					return column?.customRenderParent(
						text,
						row,
						index,
						column,
						columns
					);
				} else {
					return {
						children:
							typeof renderCellParent ==
							'function'
								? renderCellParent(
										{
											text,
											row,
											index,
											column,
										}
								  )
								: text,
						key: index,
						props: {
							colSpan:
								index == 0
									? colSpan
										? colSpan
										: columns.length
									: 0,
						},
					};
				}
			}
		}
	};
	const customLocale = {
		...locale,
		emptyText: locale?.emptyText ? (
			locale?.emptyText
		) : (
			<$Empty
				image={$Empty.PRESENTED_IMAGE_SIMPLE}
				description={t('COMMON.NO_DATA')}
			/>
		),
	};

	return (
		<>
			<div className='d-flex flex-fill align-items-center justify-content-end mt-2 mb-n2 pr-2'>
				{excelFileName && (
					<div data-testid='csv-link'>
						<Button
							size='small'
							icon={
								<BoxIcons
									type={
										IconTypes.BOX_ICON
									}
									name='csv'
								/>
							}>
							<CSVLink
								separator={
									currentCurrency ==
									'NOK'
										? ';'
										: ','
								}
								filename={
									excelFileName
								}
								data={
									renderExcelData
								}>
								{t(
									'COMMON.EXPORT'
								)}
							</CSVLink>
						</Button>
					</div>
				)}
				{/* <CSVDownload data={csvData} target="_blank" /> */}
				{showResetAllFilters && excelFileName && (
					<Divider
						type='vertical'
						className='mt-1'
					/>
				)}
				{showResetAllFilters && (
					<Button
						onClick={resetFilters}
						size='small'
						disabled={
							filteredCols.length == 0
						}
						data-testid='btn-reset-all'>
						{t('COMMON.RESET_ALL')}
					</Button>
				)}
			</div>
			<div className={virtual ? `virtual-table` : ""}>
			<Table
				dataSource={
					!isFilteredDataEmpty &&
					!filteredData.length
						? data
						: filteredData
				}
				columns={getColumns()}
				onChange={(
					pagination,
					filters,
					sorter,
					extra
				) => {
					onChange &&
						onChange(
							pagination,
							filters,
							sorter,
							extra
						);
					if (!isFilteredDataEmpty) {
						setSortedInfo(sorter);
						setFilteredData(
							Sorter({
								data,
								columns,
								filteredData,
								sortedInfo: sorter,
								onSort,
								resetFilters,
							})
						);
					}
				}}
				locale={customLocale}

				scroll={scroll}
				pagination={virtual ? false : pagination}
				components={virtual ? vComponents : components}
				{...props}
		
			/>
				</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	const { common } = state;

	const { currentDateFormat, currentLanguage, currentCurrency } = common;
	return {
		currentDateFormat,
		currentLanguage,
		currentCurrencyLocal: currentCurrency,
	};
};

export default connect(mapStateToProps, {})(TableTree);
