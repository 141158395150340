import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
    CaseHandler: {
      get: (params: any): Promise<any> => {
        return new Promise<{}>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "arservice",
              `ars/casehandlers`,
              {},
              ServiceConfig()[`arservice`]["casehandlers.get"]
            );
            resolve({ data, status });
          } catch (error) {
            reject(error);
          }
        });
      },
      update: (params: any): Promise<any> => {
        const { entityId, entityType, caseHandlerId } = params
        return new Promise<{}>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.patch(
              "arservice",
              `ars/casehandlers`,
              {entityId, entityType, caseHandlerId},
              ServiceConfig()[`arservice`]["casehandlers.patch"]
            );
            resolve({ data, status });
          } catch (error) {
            reject(error);
          }
        });
      },
    },

    CourtHandler: {
      get: (params: any): Promise<any> => {
        return new Promise<{}>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "arservice",
              `ars/courthandlers`,
              {},
              ServiceConfig()[`arservice`]["courthandlers.get"]
            );
            resolve({ data, status });
          } catch (error) {
            reject(error);
          }
        });
      },
      update: (params: any): Promise<any> => {
        const { entityId, entityType, courtHandlerId } = params
        return new Promise<{}>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.patch(
              "arservice",
              `ars/courthandlers`,
              {entityId, entityType, courtHandlerId},
              ServiceConfig()[`arservice`]["courthandlers.patch"]
            );
            resolve({ data, status });
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    
};