import React from 'react';
import { CustomSettings } from '../components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ICnt_CustomSettings extends RouteComponentProps {

}


const Cnt_CustomSettings = (props: ICnt_CustomSettings) => {

    const onMinimize = () => {
        props.history.push('')
    }

    return (
        <CustomSettings.Home onMinimize={onMinimize} />
    )
}

export default withRouter(Cnt_CustomSettings);