import React from "react";
import { $Row, $Col } from "us.common/components";
import { CheckOutlined } from "us.icons";
import { useField } from "formik";
import { ICheckBoxList } from "us.common/components/antd/CheckBoxList/Interface";
import { removeSelection } from "us.common/components/antd/CheckBoxList/Functions";

const Selection: React.FC<ICheckBoxList> = (props) => {
  const {
    name,
    options = [],
    optionText = "label",
    optionValue = "value",
    disabled = false,
    extraText = undefined,
  } = props;

  const [field, , helpers] = useField(name);

  const isSelected = (option: any) =>
    field.value?.includes(option?.[optionValue]);

  return (
    <>
      <div className="channels-content d-flex flex-column mb-4">
        <$Row gutter={[12, 12]}>
          {options.map((option: any, index: number) => (
            <$Col key={index} span={6}>
              <div
                onClick={() => {
                  if (isSelected(option)) {
                    helpers.setValue(
                      removeSelection(field.value, option, optionValue)
                    );
                  } else {
                    field.value &&
                      helpers.setValue([...field.value, option?.[optionValue]]);
                    !field.value && helpers.setValue([option?.[optionValue]]);
                  }
                }}
                className={
                  disabled
                    ? "mb-1 d-flex align-items-center p-2 item-content noHover"
                    : isSelected(option)
                    ? "mb-1 d-flex align-items-center p-2 item-content active"
                    : "mb-1 d-flex align-items-center p-2 item-content"
                }
              >
                <div
                  className={
                    isSelected(option)
                      ? "item-icon-content mr-2 active"
                      : "item-icon-content mr-2"
                  }
                >
                  {isSelected(option) && <CheckOutlined />}
                </div>
                <div className="flex-fill pl-2" style={{ width: "85%" }}>
                  <div className="channel-item-value">
                    {option?.[optionText]}
                  </div>
                  <div className="channel-item-txt text-truncate">
                    {extraText}
                  </div>
                </div>
              </div>
            </$Col>
          ))}
        </$Row>
      </div>
    </>
  );
};

export default Selection;
