import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, useField } from "formik";
import {
  $Button,
  $Input,
  $Search,
  $Skeleton,
  $TableTree,
  $Checkbox,
} from "../antd";
import { PostalAreaDetails } from "./Types";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import { PlusOutlined, SearchOutlined, FilterOutlined } from "us.icons";
import * as Action from "us.common/actions";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { getPostalCodes, getRemovePostalCode } from "us.common/functions";
import _ from "lodash";
import "./Style.scss";

interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IPostalAreaFormat {
  id: any;
  postalPlace: any;
  postalCode: any;
  countyCode: any;
  countyName: any;
  key: any;
}

interface ISelectPostalArea {
  drawerContentChangeClick: (data: PostalAreaDetails) => void;
  currentStep?: any;
  currentInfo?: any;
  cancelClick: (currentValues: any) => void;
  onClickAddPostalArea: () => void;
  updateFormData: (data: any) => void;
  updateDrawerName: (data: string) => void;
  clearPostalData: any;
  resetExisitingPostalCode: (data: any) => void;
}

interface IBase extends ISelectPostalArea {
  name?: string;
  multiple?: boolean;
  postalAreas: any[];
  getPostalAreas?: any;
  isLoading: boolean;
  countryCode?: string;
  selectedPostalCodes?: Array<any>;
  postalAreaClose?: () => void;
  onSelect?: (selectedKeys: Array<any>) => void;
  onSubmitSelection?: (selectedKeys: Array<any>) => void;
}

const SelectPostalArea: React.FC<IBase> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.DEBTOR"], { useSuspense: true });
  const {
    name = "selectedPostalCodes",
    postalAreas,
    getPostalAreas,
    isLoading,
    clearPostalData,
    countryCode,
    multiple = false,
    selectedPostalCodes = [],
    onSelect,
    postalAreaClose,
    onSubmitSelection,
    resetExisitingPostalCode,
  } = props;

  const [, , helpers] = useField(name);

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });
  const [selectedRowKey] = useState([]);

  const [searchText, setSearchText] = useState<string>("");

  const [data, setData] = useState<IPostalAreaFormat[]>([]);
  const [dataBkp, setDataBkp] = useState<IPostalAreaFormat[]>([]);

  const { updateFormData, updateDrawerName } = props;

  const postalFilters = {
    countryCode: countryCode ?? "NO",
    postalCode: "",
    searchType: "all",
  };
  const column: any = [
    {
      title: t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE"),
      // title: t('US.COMMON:COMMON.POSTAL_CODE'),
      dataIndex: "postalCode",
      key: "postalCode",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA"),
      //title: t('US.COMMON:COMMON.POSTAL_PLACE'),
      dataIndex: "postalPlace",
      key: "postalPlace",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE"),
      // title: t('US.COMMON:COMMON.MUNICIPALITY_CODE'),
      dataIndex: "countyCode",
      key: "countyCode",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME"),
      //title: t('US.COMMON:COMMON.MUNICIPALITY_NAME'),
      dataIndex: "countyName",
      key: "countyName",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ];

  useEffect(() => {
    clearPostalData({});
    updateDrawerName(
      t("US.COLLECTION.COMMON:ENTITYSELECTION.SELECTPOSTALAREA")
    );
  }, []);

  useEffect(() => {
    setData([...postalAreas]);
    setDataBkp([...postalAreas]);
  }, [postalAreas]);

  const handleChange = (
    pagination: any,
    filters: Partial<
      Record<
        | "postalPlace"
        | "PostalCode"
        | "countyCode"
        | "countyName"
        | "id"
        | "key",
        string[]
      >
    >,
    sorter: any
  ) => {
    setSortedInfo(sorter);
  };

  const rowDoubleClickHandler = (dataRow: IPostalAreaFormat, rowIndex: any) => {
    dataRow = dataRow || selectedRowKey;
    let newInfo = {
      ...props.currentInfo,
      PostalCode: dataRow.postalCode,
      City: dataRow.postalPlace,
      MunicipalityCode: dataRow.countyCode,
      MunicipalityName: dataRow.countyName,
    };
    delete newInfo.id;
    props.drawerContentChangeClick({
      currentStep: props.currentStep,
      updatedInfo: newInfo,
    });
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  const handleSelection = (rowKeys: Array<any>) => {
    helpers.setValue(rowKeys);
    onSubmitSelection && onSubmitSelection(rowKeys);
    postalAreaClose && postalAreaClose();
  };

  return (
    <Formik
      initialValues={{
        postalCodes: "",
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={() => {}}
    >
      {({ values, ...restProps }: any) => (
        <div className='select-postal-area'>
          <div className="d-flex mb-4">
            <$Search
              size="small"
              name="postalCodes"
              placeholder={t("US.COLLECTION.COMMON:COMMON.SEARCH")}
              onSearch={(e: any) =>
                getPostalAreas({
                  ...postalFilters,
                  searchText: e === "" ? "" : e,
                })
              }
              onPressEnter={(e: any) =>
                getPostalAreas({
                  ...postalFilters,
                  searchText: e.target.value === "" ? "" : e.target.value,
                })
              }
              allowClear={true}
            />
            <$Button
              type="dashed"
              size="small"
              icon={<PlusOutlined />}
              className="ml-2"
              onClick={() => {
                props.onClickAddPostalArea();
                updateFormData({});
                updateDrawerName(
                  t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA")
                );
                resetExisitingPostalCode({});
              }}
            >
              {t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_AREA")}
            </$Button>
          </div>
          <$Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
            <$TableTree
              onChange={handleChange}
              rowKey="postalCode"
              data={data}
              size="small"
              className=""
              onSort={handleSort}
              onFilter={handleFilter}
              filterOnType={true}
              resetOnSourceChange={true}
              bordered
              pagination={{ defaultPageSize: 20 }}
              columns={column}
              onRow={(record: any, rowIndex: any) => {
                return {
                  onDoubleClick: (event: any) => {
                    if (!multiple) {
                      rowDoubleClickHandler(record, rowIndex);
                    }
                  },
                };
              }}
              firstColSkipFilterProps={-1}
              scroll={{ x: "100%", y: "calc(100vh - 285px)" }}
              rowSelection={
                multiple
                  ? {
                      type: "checkbox",
                      hideSelectAll: true,
                      columnTitle: (
                        <$Checkbox
                          name="isAllCreditors"
                          checked={
                            data?.length == 0
                              ? false
                              : _.isEqual(
                                  getPostalCodes(data),
                                  getPostalCodes(selectedPostalCodes)
                                )
                          }
                          onChange={() => {
                            if (onSelect) {
                              selectedPostalCodes?.length !== data?.length
                                ? onSelect(data)
                                : onSelect([]);
                            }
                          }}
                        />
                      ),
                      onChange: (selectedRowKeys: Array<any>) => {
                        if (
                          selectedRowKeys.length < selectedPostalCodes.length
                        ) {
                          onSelect &&
                            onSelect(
                              selectedPostalCodes.filter(
                                (s: any) =>
                                  s.postalCode !=
                                  getRemovePostalCode(
                                    selectedPostalCodes,
                                    selectedRowKeys
                                  )
                              )
                            );
                        } else {
                          selectedRowKeys.map((p: any) => {
                            if (
                              !getPostalCodes(selectedPostalCodes).includes(p)
                            ) {
                              data.map((d: any) => {
                                if (d.postalCode === p) {
                                  onSelect &&
                                    onSelect([
                                      ...selectedPostalCodes,
                                      {
                                        postalCode: d.postalCode,
                                        postalPlace: d.postalPlace,
                                      },
                                    ]);
                                }
                              });
                            }
                          });
                        }
                      },
                      preserveSelectedRowKeys: true,
                      selectedRowKeys: getPostalCodes(selectedPostalCodes),
                    }
                  : undefined
              }
            />
          </$Skeleton>
          {multiple && (
            <div className="drawer-footer-fixed align-items-end justify-content-between">
              <div className="d-flex flex-column">
                <div className="font-weight-bold">
                  {isLoading ? 0 : selectedPostalCodes?.length}
                </div>
                <div>
                  {t(
                    "US.COLLECTION.COMMON:ENTITYSELECTION.SELECTED_POSTAL_CODES"
                  )}
                </div>
              </div>

              <div>
                <$Button
                  className="ml-3 mr-2"
                  data-testid="confirm-selection"
                  type="primary"
                  disabled={isLoading || selectedPostalCodes?.length === 0}
                  onClick={() => handleSelection(selectedPostalCodes)}
                >
                  {t("US.COLLECTION.COMMON:COMMON.SELECT")}
                </$Button>
                <$Button
                  onClick={() => postalAreaClose && postalAreaClose()}
                  data-testid="cancel-selection"
                >
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    postalAreas: state.entityDetails.postalAreas,
    isLoading: state.entity.isLoading,
  };
};

const mapDispatchToProps = {
  getPostalAreas: Action.entity.getPostalAreas,
  updateFormData: Action.entity.PostalCodeFormData,
  updateDrawerName: Action.entity.ChangePostalDrawerTitle,
  clearPostalData: Action.entity.clearPostalData,
  resetExisitingPostalCode: Action.entity.postalCodeAvailability.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPostalArea);