import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { entity } from 'us.common/actions';
import { $Row, $Col, $Input, $Button, $Form } from '../antd';
import { viewNames } from './constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface IAddPostalArea {
  drawerContentChangeClick: ({ currentStep }: any) => void;
  currentStep: any;
  cancelClick: ({ currentStep }: any) => any;
  municipal: { MunicipalityCode: string; MunicipalityName: string };
}

interface IBase extends IAddPostalArea {
  addPostalArea: any;
  newPostalAreaSucessMsg: any;
}

interface AddPostal extends Yup.MixedSchema {
  PostalCode: string;
  SelectedMunicipalityName: string;
  PostalPlace: string;
  SelectedMunicipalityCode: string;
  CityAreaNo: string;
}

const postalDetails = {
  PostalCode: '',
  SelectedMunicipalityName: '',
  PostalPlace: '',
  SelectedMunicipalityCode: '',
  CityAreaNo: '',
};

const AddPostalArea: React.FC<IBase> = (props) => {
  const { t } = useTranslation();

  const validations = Yup.object<AddPostal>().shape({
    PostalCode: Yup.string().required(
      t('US.TL.VALIDATIONS:REQUIRED.PLEASE_INPUT_POSTAL_CODE')
    ),
    SelectedMunicipalityName: Yup.string().required(
      t('US.TL.VALIDATIONS:REQUIRED.PLEASE_INPUT_MUNICIPALITY_NAME')
    ),
    PostalPlace: Yup.string().required(
      t('US.TL.VALIDATIONS:REQUIRED.PLEASE_INPUT_POSTAL_PLACE')
    ),
    SelectedMunicipalityCode: Yup.string().required(
      t('US.TL.VALIDATIONS:REQUIRED.PLEASE_INPUT_MUNICIPALITY_CODE')
    ),
  });

  const {
    addPostalArea,
    newPostalAreaSucessMsg,
    drawerContentChangeClick,
    currentStep,
    municipal,
  } = props;

  const handlePostalSubmit = (
    postalData: any,
    { setErrors, setStatus, resetForm, setSubmitting, ...rest }: any
  ) => {
    let dataVal = {
      PostalPlace: postalData.PostalPlace,
      PostalCode: postalData.PostalCode,
      Municipality: {
        id: 20,
        Code: postalData.SelectedMunicipalityCode,
        Name: postalData.SelectedMunicipalityName,
      },
    };
    addPostalArea(dataVal);
    drawerContentChangeClick({ currentStep: props.currentStep });
    setSubmitting(false);
  };

  // const [postalDetails, setPostalDetails] = useState<object>({
  //   PostalCode: "",
  //   SelectedMunicipalityName: "",
  //   PostalPlace: "",
  //   SelectedMunicipalityCode: "",
  //   CityAreaNo: "",
  // });

  let ref: any = useRef();

  const updatePostdetails = () => {
    postalDetails.PostalCode = ref.values.PostalCode;
    postalDetails.PostalPlace = ref.values.PostalPlace;
    postalDetails.CityAreaNo = ref.values.CityAreaNo;
  };

  //   useEffect(() => {
  //       if (municipal.MunicipalityCode?.trim() !== "") {
  //         const postdetails = postalDetails
  //       setFieldsValue({ SelectedMunicipalityName: municipal.MunicipalityName });
  //       setFieldsValue({ SelectedMunicipalityCode: municipal.MunicipalityCode });
  //     }

  //     if (
  //       Object.entries(newPostalAreaSucessMsg).length > 0 &&
  //       getFieldsValue().PostalCode
  //     ) {
  //       drawerContentChangeClick({ currentStep: props.currentStep });
  //     }
  //   }, [
  //     drawerContentChangeClick,
  //     getFieldsValue,
  //     municipal,
  //     newPostalAreaSucessMsg,
  //     props.currentStep,
  //     setFieldsValue,
  //   ]);

  return (
    <Formik
      initialValues={{
        ...postalDetails,
        SelectedMunicipalityName: municipal.MunicipalityName,
        SelectedMunicipalityCode: municipal.MunicipalityCode,
      }}
      enableReinitialize
      validationSchema={validations}
      innerRef={(instance) => {
        ref = instance;
      }}
      validateOnChange
      validateOnBlur
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        handlePostalSubmit(values, actions);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <>
          {/* {props.breadcrumb} */}
          <$Form name="newAddPostalArea" onSubmit={handleSubmit}>
            <h3 className="mb-3">{t('US.COMMON:COMMON.GENERAL')}</h3>
            <$Row gutter={16}>
              <$Col span={12}>
                <$Input
                  required
                  size="small"
                  name="PostalCode"
                  label={t('US.COMMON:COMMON.POSTAL_CODE')}
                  className="bui-label"
                />
              </$Col>
              <$Col span={12}>
                <a
                  onClick={() => {
                    updatePostdetails();
                    props.drawerContentChangeClick({
                      currentStep: viewNames.selectMinicipality,
                    });
                  }}
                >
                  <strong>{t('US.COMMON:COMMON.MUNICIPALITY_NAME')}</strong>
                </a>

                <$Input required size="small" name="SelectedMunicipalityName" />
              </$Col>
            </$Row>
            <$Row gutter={16}>
              <$Col span={12}>
                <$Input
                  required
                  size="small"
                  name="PostalPlace"
                  label={t('US.COMMON:COMMON.POSTAL_PLACE')}
                  className="bui-label"
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  required
                  size="small"
                  name="SelectedMunicipalityCode"
                  label={t('US.COMMON:COMMON.MUNICIPALITY_CODE')}
                  className="bui-label"
                />
              </$Col>
            </$Row>
            <$Row gutter={16}>
              <$Col span={12}>
                <$Input
                  size="small"
                  name="CityAreaNo"
                  label={t('US.COMMON:COMMON.CITY_AREA_NO')}
                  className="bui-label"
                />
              </$Col>
            </$Row>
            <div className="d-flex justify-content-end">
              <$Button
                className="mr-2"
                type="primary"
                disabled={!rest.isValid || isSubmitting}
                loading={isSubmitting || isValidating}
                htmlType="submit"
                onClick={(e: any) => handleSubmit(e)}
              >
                {t('US.COMMON:COMMON.SAVE')}
              </$Button>
              <$Button
                onClick={() =>
                  props.cancelClick({
                    currentStep: currentStep,
                    updatedInfo: undefined,
                    setp: undefined,
                  })
                }
              >
                {t('US.COMMON:COMMON.CANCEL')}
              </$Button>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    newPostalAreaSucessMsg: state.entityDetails.newPostalAreaSucessMsg,
  };
};

const mapDispatchToProps = {
  addPostalArea: entity.addPostalArea,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPostalArea);
