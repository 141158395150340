export enum ManageActivities {
  SET_STEP_DETAIL = "SET_STEP_DETAIL",

  INIT_ACTIVITY = "INIT_ACTIVITY",
  INIT_ACTIVITY_SUCCESS = "INIT_ACTIVITY_SUCCESS",
  INIT_ACTIVITY_FAIL = "INIT_ACTIVITY_FAIL",

  SAVE_ACTIVITY = "SAVE_ACTIVITY",
  SAVE_ACTIVITY_SUCCESS = "SAVE_ACTIVITY_SUCCESS",
  SAVE_ACTIVITY_FAIL = "SAVE_ACTIVITY_FAIL",

  GET_ACTIVITY_STATES = "GET_ACTIVITY_STATES",
  GET_ACTIVITY_STATES_SUCCESS = "GET_ACTIVITY_STATES_SUCCESS",
  GET_ACTIVITY_STATES_FAIL = "GET_ACTIVITY_STATES_FAIL",

  MANAGE_DRAWER = "MANAGE_DRAWER",
  MANAGE_BREADCRUMB = "MANAGE_BREADCRUMB",

  GET_ACTIVITY_SUMMARY = "GET_ACTIVITY_SUMMARY",
  GET_ACTIVITY_SUMMARY_SUCCESS = "GET_ACTIVITY_SUMMARY_SUCCESS",
  GET_ACTIVITY_SUMMARY_FAIL = "GET_ACTIVITY_SUMMARY_FAIL",

  SEARCH_ACTIVITY_SUMMARY = "SEARCH_ACTIVITY_SUMMARY",
  SEARCH_ACTIVITY_SUMMARY_SUCCESS = "SEARCH_ACTIVITY_SUMMARY_SUCCESS",
  SEARCH_ACTIVITY_SUMMARY_FAIL = "SEARCH_ACTIVITY_SUMMARY_FAIL",

  GET_ACTIVITY_TYPE_PROPERTIES = "GET_ACTIVITY_TYPE_PROPERTIES",
  GET_ACTIVITY_TYPE_PROPERTIES_SUCCESS = "GET_ACTIVITY_TYPE_PROPERTIES_SUCCESS",
  GET_ACTIVITY_TYPE_PROPERTIES_FAIL = "GET_ACTIVITY_TYPE_PROPERTIES_FAIL",

  GET_ACTIVITY_TYPE_PARAMETERS = "GET_ACTIVITY_TYPE_PARAMETERS",
  GET_ACTIVITY_TYPE_PARAMETERS_SUCCESS = "GET_ACTIVITY_TYPE_PARAMETERS_SUCCESS",
  GET_ACTIVITY_TYPE_PARAMETERS_FAIL = "GET_ACTIVITY_TYPE_PARAMETERS_FAIL",

  GET_ACTIVITY_FOLLOWUP_CATEGORY = "GET_ACTIVITY_FOLLOWUP_CATEGORY",
  GET_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS = "GET_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS",
  GET_ACTIVITY_FOLLOWUP_CATEGORY_FAIL = "GET_ACTIVITY_FOLLOWUP_CATEGORY_FAIL",

  SAVE_ACTIVITY_FOLLOWUP_CATEGORY = "SAVE_ACTIVITY_FOLLOWUP_CATEGORY",
  SAVE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS = "SAVE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS",
  SAVE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL = "SAVE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL",

  DELETE_ACTIVITY_FOLLOWUP_CATEGORY = "DELETE_ACTIVITY_FOLLOWUP_CATEGORY",
  DELETE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS = "DELETE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS",
  DELETE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL = "DELETE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL",

  GET_ACTIVITY_DETAIL = "GET_ACTIVITY_DETAIL",
  GET_ACTIVITY_DETAIL_SUCCESS = "GET_ACTIVITY_DETAIL_SUCCESS",
  GET_ACTIVITY_DETAIL_FAIL = "GET_ACTIVITY_DETAIL_FAIL",

  DELETE_ACTIVITY = "DELETE_ACTIVITY",
  DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL",

  GET_EXECUTION_PROFILES = "GET_EXECUTION_PROFILES",
  GET_EXECUTION_PROFILES_SUCCESS = "GET_EXECUTION_PROFILES_SUCCESS",
  GET_EXECUTION_PROFILES_FAIL = "GET_EXECUTION_PROFILES_FAIL",

  GET_EXECUTION_PROFILE_DETAILS = "GET_EXECUTION_PROFILE_DETAILS",
  GET_EXECUTION_PROFILE_DETAILS_SUCCESS = "GET_EXECUTION_PROFILE_DETAILS_SUCCESS",
  GET_EXECUTION_PROFILE_DETAILS_FAIL = "GET_EXECUTION_PROFILE_DETAILS_FAIL",

  DELETE_EXECUTION_PROFILE = "DELETE_EXECUTION_PROFILE",
  DELETE_EXECUTION_PROFILE_SUCCESS = "DELETE_EXECUTION_PROFILE_SUCCESS",
  DELETE_EXECUTION_PROFILE_FAIL = "DELETE_EXECUTION_PROFILE_FAIL",

  SAVE_EXECUTION_PROFILE = "SAVE_EXECUTION_PROFILE",
  SAVE_EXECUTION_PROFILE_SUCCESS = "SAVE_EXECUTION_PROFILE_SUCCESS",
  SAVE_EXECUTION_PROFILE_FAIL = "SAVE_EXECUTION_PROFILE_FAIL",

  SET_ACTIVITY_NAME_STATUS = "SET_ACTIVITY_NAME_STATUS",
  SET_ACTIVITY_CODE_STATUS = "SET_ACTIVITY_CODE_STATUS",

  GET_EVENT_LIST = "GET_EVENT_LIST",
  GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS",
  GET_EVENT_LIST_FAIL = "GET_EVENT_LIST_FAIL",
  
  GET_TEMPLATE_CATEGORIES = "GET_TEMPLATE_CATEGORIES",
  GET_TEMPLATE_CATEGORIES_SUCCESS = "GET_TEMPLATE_CATEGORIES_SUCCESS",
  GET_TEMPLATE_CATEGORIES_FAIL = "GET_TEMPLATE_CATEGORIES_FAIL",
}
