import React from "react";

export default ({ value }: { value: string }) => {
  const handleValue = (): string => {
    try {
      if (value) {
        const trimmedValue = value.trim();
        const size = trimmedValue.length;
        if (size <= 11) {
          if (size >= 7) {
            const firstPart = trimmedValue.substring(0, 4);
            const secondPart = trimmedValue.substring(4, 6);
            const ThirdPart = trimmedValue.substring(6, size);
            return `${firstPart} ${secondPart} ${ThirdPart}`;
          } else if (size < 7 && size > 4) {
            const firstPart = trimmedValue.substring(0, 4);
            const secondPart = trimmedValue.substring(4, size);
            return `${firstPart} ${secondPart}`;
          } else {
            const firstPart = trimmedValue.substring(0, size);
            return `${firstPart}`;
          }
        }
        return value;
      }
      return "";
    } catch (e) {
      return value;
    }
  };

  return <React.Fragment>{handleValue()}</React.Fragment>;
};
