import React from "react";
import { Route } from "react-router-dom";
// import { DomainView, CaseView } from "../layouts";
import Admin from "us.collection.admin";

import {
  MainSearch,
  UserRoleSettings,
  UserSettings,
  Home,
} from "./asyncRoutes";

export default () => {
  const MainViewModules = [Admin];
  return [
    <Route
      exact
      key="search"
      path={"/search"}
      render={(props) => <MainSearch {...props} />}
    />,
    <Route
      exact
      key="/user-role-settings"
      path={"/user-role-settings"}
      render={() => <UserRoleSettings />}
    />,
    <Route
      exact
      key="/user-settings"
      path={"/user-settings"}
      render={() => <UserSettings />}
    />,
    <Route exact key="/" path={"/"} render={() => <Home />} />,
    ...MainViewModules.map(({ Routes }) => Routes.asyncRoutes),
  ];
};
