import { IActivityExecutionActions, IAPIActionBase } from "us.common/interfaces";
import { ActivityExecution } from 'us.common/constants'


export const activityExecutionActions: Readonly<IAPIActionBase & IActivityExecutionActions> = {
    /**
     * A namespace
     * @namespace activities
     */
    activities: {
        /**
         * Get all activities start action
         */
        get: (data) => ({
            type: ActivityExecution.GET_ACTIVITIES_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        /**
         * Get all activities success action
         */
        success: (data) => ({
            type: ActivityExecution.GET_ACTIVITIES_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        /**
         * Get all activities fail action
         */
        fail: (error) => ({
            type: ActivityExecution.GET_ACTIVITIES_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    },
    /**
     * A namespace
     * @namespace parameters
     */
    parameters: {
        /**
         * Get activity parameters for action start action
         */
        get: (data) => ({
            type: ActivityExecution.GET_ACTIVITY_PARAMETERS_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        /**
         * Get activity parameters for action success action
         */
        success: (data) => ({
            type: ActivityExecution.GET_ACTIVITY_PARAMETERS_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        /**
         * Get activity parameters for action fail action
         */
        fail: (error) => ({
            type: ActivityExecution.EXECUTE_ACTIVITY_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
        updateDefaultData: (data) => ({
            type: ActivityExecution.UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA,
            payload: {
                data,
                isLoading: true,
            },
        }),
        mangeParameterDropDown: (data) => ({
            type: ActivityExecution.MANAGE_PARAMETER_DROP_DOWN,
            payload: {
                data,
                isLoading: true,
            },
        }),
    },
    /**
 * A namespace
 * @namespace activity
 */
    activity: {
        /**
         * Execute followUp activity for action start action
         */
        execute: (data, other) => ({
            type: ActivityExecution.EXECUTE_ACTIVITY_START,
            payload: {
                data,
                other,
                isLoading: true,
            },
        }),
        /**
         * Execute followUp activity for action success action
         */
        success: (data) => ({
            type: ActivityExecution.EXECUTE_ACTIVITY_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        /**
         * Execute followUp activity for action fail action
         */
        fail: (error) => ({
            type: ActivityExecution.EXECUTE_ACTIVITY_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
        set: (data) => ({
            type: ActivityExecution.SET_ACTIVITY_DATA,
            payload: {
                data,
                isLoading: true,
            },
        }),
        reset: (data) => ({
            type: ActivityExecution.RESET_ACTIVITY_DATA,
            payload: {
                data,
                isLoading: true,
            },
        }),
        setMode: (data) => ({
            type: ActivityExecution.SET_ACTIVITY_MODE,
            payload: {
                data,
                isLoading: true,
            },
        }),
    }
};
