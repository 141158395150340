import React, { useEffect, useState, memo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { creditorGroup } from "us.collection.admin/actions";
import {
  SaveCreditorGroup,
  UpdateCreditorGroup,
} from "us.collection.admin/repository";
import { IRootState } from "us.collection/interfaces";

import CreditorGroupSchema from "./Validations";

const {
  $Input,
  $Button,
  $Form,
  $Popconfirm,
  $TextArea
} = Common.Components;

export interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IAddCreditorGroup {
  creditorGroupDetails: any;
  drawer: any;
  saveCreditorGroup?: any;
  updateCreditorGroup?: any;
  onClose: any;
}

const AddCreditorGroup: React.FC<IAddCreditorGroup> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    creditorGroupDetails,
    saveCreditorGroup,
    updateCreditorGroup,
    drawer,
    onClose,
  } = props;

  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={{ ...creditorGroupDetails.data }}
      validationSchema={CreditorGroupSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        if (actions.isValidating) return;
        if (drawer?.isNewCreditorGroup) {
          const creditorGroupReq = SaveCreditorGroup.call(values);
          saveCreditorGroup(creditorGroupReq);
        } else {
          const creditorGroupReq = UpdateCreditorGroup.call(values);
          updateCreditorGroup(creditorGroupReq);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <$Form>
          <div>
            <div className="mb-3">
              <$Input
                label={t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NAME")}
                name="groupName"
                required
              />
            </div>
            <div>
              <$TextArea
                label={t(
                  "US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DESCRIPTION"
                )}
                name="description"
                autoSize={{ minRows: 8, maxRows: 8 }}
                required
              />
            </div>
          </div>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                disabled={!rest.isValid || isSubmitting || !rest.dirty}
                loading={isSubmitting || isValidating}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                type="primary"
              >
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              {/* {setIsEdited(rest.dirty)} */}
              {rest.dirty && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.ADDNEWCANCELMSG"
                  )}
                  placement="topLeft"
                  onConfirm={() => onClose()}
                  okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                  cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                >
                  <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                </$Popconfirm>
              )}
              {!rest.dirty && (
                <$Button onClick={() => onClose()}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              )}
            </div>
          </div>
        </$Form>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { creditorGroup } = state;
  const { creditorGroupDetails, drawer } = creditorGroup;

  return { creditorGroupDetails, drawer };
};

const mapDispatchToProps = {
  saveCreditorGroup: creditorGroup.creditorGroup.save,
  updateCreditorGroup: creditorGroup.creditorGroup.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditorGroup);
