import React, { useState } from 'react';
import { Button, Input, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { FilterOutlined } from 'us.icons';

interface IGuarantorDetails {
	SelectedGuarantorDta(params: any): void;
}

const GuarantorDetails: React.FC<IGuarantorDetails> = (props) => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState('');

	// column serch.........................................................................
	const getColumnSearchProps = (dataIndex: string) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}: any) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(
							e.target.value
								? [
										e
											.target
											.value,
								  ]
								: []
						)
					}
					onPressEnter={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					style={{
						width: 188,
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Button
					type='primary'
					onClick={() =>
						handleSearch(
							selectedKeys,
							confirm
						)
					}
					icon='search'
					size='small'
					style={{ width: 90, marginRight: 8 }}>
					{t('COMMON.SEARCH')}
				</Button>
				<Button
					onClick={() =>
						handleReset(clearFilters)
					}
					size='small'
					style={{ width: 90 }}>
					{t('COMMON.RESET')}
				</Button>
			</div>
		),
		filterIcon: (filtered: string) => (
			<FilterOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		onFilter: (value: string, record: any) => {
			return record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase());
		},
		onFilterDropdownVisibleChange: (visible: boolean) => {},
		render: (text: string) => (
			<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		),
	});

	const handleSearch = (selectedKeys: any, confirm: any) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const handleReset = (clearFilters: any) => {
		clearFilters();
		setSearchText('');
	};

	const columns: any = [
		{
			title: 'Person No',
			dataIndex: 'DebtorNumber',
			key: 'DebtorNumber',
			...getColumnSearchProps('DebtorNumber'),
		},
		{
			title: 'Entity No',
			dataIndex: 'DebtorEntNo',
			key: 'DebtorEntNo',
		},
		{
			title: 'Name',
			key: 'DebtorName',
			dataIndex: 'DebtorName',
			...getColumnSearchProps('DebtorName'),
		},
		{
			title: 'Address 1',
			key: 'Address1',
			dataIndex: 'Address1',
			...getColumnSearchProps('Address1'),
		},
		{
			title: 'Address 2',
			key: 'Address2',
			dataIndex: 'Address2',
			...getColumnSearchProps('Address2'),
		},
		{
			title: 'City',
			key: 'ZipName',
			dataIndex: 'ZipName',
			...getColumnSearchProps('ZipName'),
		},
	];

	const handleRowClick = (rowData: any, rowIndex: any) => {
		props.SelectedGuarantorDta(rowData);
	};

	return (
		<div>
			<Table
				columns={columns}
				dataSource={[]}
				size='small'
				className='mt-3'
				onRow={(record, rowIndex) => {
					return {
						onDoubleClick: () =>
							handleRowClick(
								record,
								rowIndex
							),
					};
				}}
			/>
		</div>
	);
};

export default GuarantorDetails;
