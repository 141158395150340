import {
  IInvoiceSearchResult,
  ICaseSearchResult,
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
  IDebtorSearchResult,
  IDebtorARSearchResult,
} from "../Interfaces";

/**
 * @function
 * @description Arrange invoices and cases data.
 * @param {Array<any>} data - invoices or cases payload
 * @param {string} type - arrange type
 * @returns An array of arranged invoices or cases
 */
const getInvoicesAndCasesArranged = (
  data: any[],
  type: "invoice" | "case"
): IInvoiceSearchResult[] | ICaseSearchResult[] => {
  try {
    if (type == "invoice") {
      return data.map((item:any) => ({
        ...item,
        key: item.subCaseNo,
      }));
    } else {
      return data.map((item:any) => ({
        ...item,
        key: item.caseNo,
      }));
    }
  } catch (e) {
    return [];
  }
};

/**
 * @function
 * @description Arrange creditor groups.
 * @param {Array<any>} data - groups payload
 * @returns An array of arranged creditors
 */
const getCreditorGroupArranged = (
  data: any[]
): ICreditorGroupSearchResult[] => {
  try {
    return data.map((creditorGroup:any) => ({
      ...creditorGroup,
      isCreditorGroupCollapsed: true,
      key: creditorGroup.groupId,
      creditors: creditorGroup.creditors.map(
        (creditor: ICreditorSearchResult) => ({
          ...creditor,
          key: creditor.creditorEntRoleId,
        })
      ),
    }));
  } catch (e) {
    return [];
  }
};

/**
 * @function
 * @description Arrange debtors.
 * @param {Array<any>} data - debtors payload
 * @returns An array of arranged debtors
 */
const getDebtorArranged = (data: any[]): IDebtorSearchResult[] => {
  try {
    return data.map((debtor:any) => ({
      ...debtor,
      isARCollapsed: true,
      key: debtor.debtorEntNo,
      arDetails: debtor.arDetails.map((arItem: IDebtorARSearchResult) => ({
        ...arItem,
        key: arItem.arNo,
        caseDetails: arItem.caseDetails.map((caseItem:any) => ({
          ...caseItem,
          key: caseItem.caseNo,
        })),
      })),
    }));
  } catch (e) {
    return [];
  }
};

export default {
  getInvoicesAndCasesArranged,
  getCreditorGroupArranged,
  getDebtorArranged,
};
