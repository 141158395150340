import React from "react";

/**
 * @function
 * @description - format example query
 * @param {string} exampleQuery  - Exmaple Query comes from API response
 * @returns formatted Query string
 */
export const formatExampleQuery = (exampleQuery: string): JSX.Element => {
  try {
    const obj =   {
      "text": exampleQuery,
      "highlight": [
        {
            "targetStrings": ["SELECT" ],
            "color": "#ffa310",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "FROM" ],
            "color": "#5a5ae2",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "INNER", "OUTER", "JOIN" ],
            "color": "#e93fe3",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "ON" ],
            "color": "#e9673f",
            "fontWeight": "bold"
        },
        {
            "targetStrings": [ "WHERE" ],
            "color": "#57d357",
            "fontWeight": "bold"
        }
      ]
    }

    const coloredWords = obj.text.split(" ").map(word => {
      const color = obj.highlight.reduce((acc, item) => {
          const index = item.targetStrings.indexOf(word)
          if (index >= 0) return item.color
          return acc
      }, '')
      const fontWeight = obj.highlight.reduce((acc, item) => {
          const index = item.targetStrings.indexOf(word)
          if (index >= 0) return item.fontWeight
          return acc
      }, '')
      return { word, color, fontWeight }
    })

    return (
      <>
        {coloredWords.map(item => (
          <span
            key={item.word}
            style={{
              "color": item.color,
              "fontWeight": item.fontWeight
            }}
          >
            {item.word}{" "}
          </span>
        ))}
      </>
    );
  } catch (error) {
    return <></>;
  }
};
