import { IAzureADUser, IUserRole } from 'us.common/interfaces';

export function SaveUser(this: any, aDDetail: IAzureADUser) {
	const {
		id,
		activeState,
		email,
		displayName,
		firstName,
		lastName,
		preferredLanguage,
		accessAuthorityLevelId = '',
		isCommentEnable,
		maskPrivateData,
		hasDebtCollectionLicense,
		roles,
		creditors,
		comment,
		isEdit,
		description,
	} = this;

	const { id: azureUserId } = aDDetail;

	return {
		id,
		displayName,
		azureUserId,
		firstName,
		lastName,
		email,
		maskPrivateData,
		activeState,
		hasDebtCollectionLicense,
		roles: roles
			.filter(({ isSelected }: IUserRole) => isSelected)
			.map(({ roleId }: IUserRole) => ({ roleId })),
		creditors: creditors.map((id: number | string) => ({
			creditorId: id,
		})),
		accessAuthorityLevelId,
		isCommentEnable,
		preferredLanguage,
		comment,
		isEdit,
		description,
	};
}
