import { AssignType } from "us.collection.admin/constants";

/**
 * @function
 * @description - set assignToList form form values
 * @param {any} values - formData
 * @param {function} setFieldValue - formik setFieldValue
 * @param {Array<any>} users - all the users
 * @param {Array<any>} roles - all the roles
 * @returns void
 */
export const handleTableData = (
  values: any,
  setFieldValue: any,
  users: Array<any>,
  roles: Array<any>
) => {
  try {
    const assignToList = values?.assignToList;
    const assignType = values?.assignType;

    if (assignType == AssignType.USER) {
      const matchedUser: any = users.find(
        (user: any) => user.id == values?.assignTo
      );
      setFieldValue("assignToList", [
        ...assignToList,
        {
          key: assignToList.length + 1,
          assignId: matchedUser?.id,
          assignTo: matchedUser?.displayName,
        },
      ]);
    } else if (assignType == AssignType.ROLE) {
      const matchedRole: any = roles.find(
        (role: any) => role.roleId == values?.assignTo
      );
      setFieldValue("assignToList", [
        ...assignToList,
        {
          key: assignToList.length + 1,
          assignId: matchedRole?.roleId,
          assignTo: matchedRole?.name,
        },
      ]);
    }
    setFieldValue("assignTo", "");
  } catch (error) {
    setFieldValue("assignTo", "");
  }
};

/**
 * @function
 * @description - delete assignee from assignee table
 * @param {Array<any>} assignees - assignees assigned to followup
 * @param {any} record - single record of assignee table
 * @param {function} setFieldValue - formik setFieldValue
 * @returns void
 */
export const handleDeleteAssignee = (
  assignees: Array<any>,
  record: any,
  setFieldValue: any
): void => {
  try {
    setFieldValue(
      "assignToList",
      assignees?.filter((assigned: any) => assigned.key != record.key)
    );
  } catch (error) {
    setFieldValue("assignToList", assignees);
  }
};

/**
 * @function
 * @description - generate assignTo list after select ones from the list
 * @param {any} values - formData
 * @param {Array<any>} userList - userList assigned to followup
 * @param {Array<any>} roleList - roleList assigned to followup
 * @returns Array<any>
 */
export const generateAssignToList = (
  values: any,
  userList: Array<any>,
  roleList: Array<any>,
  assignType: AssignType
): Array<any> => {
  try {
    if (assignType == AssignType.USER) {
      return userList.filter((user: any) => {
        const selectedAssignIds = values?.assignToList.map(
          (selected: any) => selected?.assignId
        );
        return !selectedAssignIds.includes(user?.id);
      });
    } else if (assignType == AssignType.ROLE) {
      return roleList.filter((role: any) => {
        const selectedAssignIds = values?.assignToList.map(
          (selected: any) => selected?.assignId
        );
        return !selectedAssignIds.includes(role?.roleId);
      });
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
