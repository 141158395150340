import { httpCollection } from 'us.helper/http/collection';

export default {
	settings: {
		getAll: (): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const data = await httpCollection.get(
						'entityservice',
						'systemsettings',
						{}
					);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
		update: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.put(
							'entityservice',
							`systemsettings/${params.id}`,
							params
						);
					if (status === 200 || status === 201) {
						const systemSettings =
							await httpCollection.get(
								'entityservice',
								'systemsettings',
								{}
							);
						resolve({
							data,
							status,
							systemSettings:
								systemSettings.data,
						});
					}
					resolve({
						data,
						status,
						systemSettings: [],
					});
				} catch (error) {
					reject(error);
				}
			});
		},
		add: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.post(
							'entityservice',
							'systemsettings',
							params
						);
					if (status === 200 || status === 201) {
						const systemSettings =
							await httpCollection.get(
								'entityservice',
								'systemsettings',
								{}
							);
						resolve({
							data,
							status,
							systemSettings:
								systemSettings.data,
						});
					}
					resolve({
						data,
						status,
						systemSettings: [],
					});
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
