import { IAPIAction } from "us.collection/interfaces";
import { CourtInfo } from "us.collection.admin/constants/Actions";
import { ICourtInfoAction } from "us.collection.admin/interfaces/CourtInfo";

export const courtInfo: Readonly<IAPIAction & ICourtInfoAction> = {
  courtTypes: {
    get: (data) => ({
      type: CourtInfo.GET_COURT_TYPES,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.GET_COURT_TYPES_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.GET_COURT_TYPES_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  relevantPostalCodesByItem: {
    getByParam: (data) => ({
      type: CourtInfo.GET_RELEVANT_POSTAL_AREAS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.GET_RELEVANT_POSTAL_AREAS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.GET_RELEVANT_POSTAL_AREAS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  courtItemInfo: {
    update: (data) => ({
      type: CourtInfo.UPDATE_COURT_ITEM,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.UPDATE_COURT_ITEM_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.UPDATE_COURT_ITEM_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  newCourtItem: {
    save: (data) => ({
      type: CourtInfo.ADD_NEW_COURT_ITEM,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.ADD_NEW_COURT_ITEM_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.ADD_NEW_COURT_ITEM_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  courtItems: {
    getByParam: (data) => ({
      type: CourtInfo.GET_COURT_INFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.GET_COURT_INFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: CourtInfo.GET_COURT_INFO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  courtItem: {
    delete: (data) => ({
      type: CourtInfo.DELETE_COURT_ITEM,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.DELETE_COURT_ITEM_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.DELETE_COURT_ITEM_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  initCourtInfo: {
    getByParam: (data) => ({
      type: CourtInfo.INIT_GET_COURT_INFO,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.INIT_GET_COURT_INFO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: CourtInfo.INIT_GET_COURT_INFO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  newCourtType: {
    save: (data) => ({
      type: CourtInfo.ADD_NEW_COURT_TYPE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.ADD_NEW_COURT_TYPE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.ADD_NEW_COURT_TYPE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  existingCourtType: {
    update: (data) => ({
      type: CourtInfo.EDIT_COURT_TYPE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.EDIT_COURT_TYPE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.EDIT_COURT_TYPE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  courtType: {
    delete: (data) => ({
      type: CourtInfo.DELETE_COURT_TYPE,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.DELETE_COURT_TYPE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.DELETE_COURT_TYPE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  relevantPostalCodes: {
    resetData: () => ({
      type: CourtInfo.RESET_RELEVANT_POSTAL_AREAS,
      payload: {
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.RESET_RELEVANT_POSTAL_AREAS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.RESET_RELEVANT_POSTAL_AREAS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },

  postalAreas: {
    get: (data) => ({
      type: CourtInfo.GET_POSTAL_AREAS_FOR_COURT,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.GET_POSTAL_AREAS_FOR_COURT_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.GET_POSTAL_AREAS_FOR_COURT_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  applicablePostalAreas: {
    get: (data) => ({
      type: CourtInfo.GET_APPLICABLE_POSTAL_AREAS_FOR_COURT,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CourtInfo.GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CourtInfo.GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
};
