import * as Yup from 'yup';

export default () => {
	return Yup.object({
		name: Yup.string().required(
			'US.TL.VALIDATIONS:REQUIRED.ROLE_NAME_REQUIRED'
		),
		comment: Yup.string().when('isEdit', {
			is: true,
			then: Yup.string().required(
				'US.TL.VALIDATIONS:REQUIRED.COMMENT_IS_REQUIRED'
			),
		}),
	});
};
