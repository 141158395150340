import { IActivitySummary } from "us.collection.admin/interfaces";
import _ from "lodash";

export const getExistedData = (
    activitySummaryList: Array<IActivitySummary>, entityType: string, fieldName: string
): Array<number | string> => {
    try {
        return _.chain(activitySummaryList)
            .groupBy("entitytype")
            .get(entityType)
            .map((activity: any) => activity[fieldName].toLowerCase())
            .value();
    } catch (error) {
        return [];
    }
};