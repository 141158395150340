import React from 'react';
import { useField } from 'formik';
import { connect } from 'react-redux';
import * as Components from 'antd';
import * as Utility from 'us.common/components/antd/utility';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import './DaysSelector.scss';

const moment = extendMoment(Moment as any);

interface IDaysSelector {
  name?: string;
  durationDateField?: string;
  formitem?: boolean;
  isAllDays?: boolean;
  availableDays?: Array<string>;
  onChange: (days: Array<string>) => void;
}

const DaysSelector: React.FC<IDaysSelector> = React.memo((props) => {
  const {
    name,
    durationDateField,
    isAllDays,
    formitem,
    availableDays,
    onChange,
  } = props;
  const days = [
    { day: 'Sunday', dayCode: 'S' },
    { day: 'Monday', dayCode: 'M' },
    { day: 'Tuesday', dayCode: 'T' },
    { day: 'Wednesday', dayCode: 'W' },
    { day: 'Thursday', dayCode: 'T' },
    { day: 'Friday', dayCode: 'F' },
    { day: 'Saturday', dayCode: 'S' },
  ];

  const [durationDate, ,] = useField(
    typeof durationDateField == 'undefined' ? `durationDate` : durationDateField
  );
  const [, meta] = useField(typeof name == 'undefined' ? 'name' : name);
  const error = meta?.error;
  const isTouched = meta?.touched;

  const handleChange = (day: string) => {
    try {
      let newAvailableDays =
        typeof availableDays == 'undefined' ? [] : availableDays;

      if (availableDays?.includes('all')) {
        const indexAllDay = newAvailableDays?.indexOf('all');
        newAvailableDays?.splice(indexAllDay, 1);
      }

      if (availableDays?.includes(day)) {
        const index = newAvailableDays?.indexOf(day);
        newAvailableDays?.splice(index, 1);
      } else {
        newAvailableDays?.push(day);
      }

      if (isAllDays && newAvailableDays?.length == 0) {
        newAvailableDays = ['all'];
      }
      onChange(newAvailableDays);
    } catch (e) {}
  };

  const isDayIncluded = (index: number) => {
    try {
      const range = moment.range(durationDate.value);
      if (range.diff('days') <= 6) {
        const selectedDateRange = Array.from(range.by('days'));
        if (selectedDateRange?.length <= 7) {
          if (selectedDateRange?.map((m) => m.day())?.includes(index)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  };
 
  return typeof formitem !== 'undefined' ? (
    <Components.Form.Item
      hasFeedback={false}
      help={isTouched && error}
      validateStatus={Utility.validateStatus(error, isTouched)}
    >
      <div className="day-toggle mb-2">
        {days?.map((item: any, index: number) => {
          return isDayIncluded(index) ? (
            <div
              key={index}
              className={`day-toggle-item ${
                availableDays?.includes(item?.day) ? 'active' : ''
              }`}
              onClick={() => handleChange(item?.day)}
            >
              {item?.dayCode}
            </div>
          ) : (
            <div key={index} className={`day-toggle-item disabled`}>
              {item?.dayCode}
            </div>
          );
        })}
      </div>
    </Components.Form.Item>
  ) : (
    <div className="day-toggle mb-2">
      {days?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`day-toggle-item ${
              availableDays?.includes(item?.day) ? 'active' : ''
            }`}
            onClick={() => handleChange(item?.day)}
          >
            {item?.dayCode}
          </div>
        );
      })}
      {isAllDays && (
        <div
          className={`ml-3 day-toggle-item ${
            availableDays?.join() == 'all' ? 'active' : ''
          }`}
          onClick={() => onChange(['all'])}
        >
          All
        </div>
      )}
    </div>
  );
});

const mapStateToProps = (state: any) => {
  return {
    currentDateFormat: state.common.currentDateFormat,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DaysSelector);
