import {
  MainCriterias,
  CaseSubCriterias,
  DebtorSubCriterias,
  CreditorSubCriterias,
} from "us.common/constants";
import { ISearchQuery } from "us.common/interfaces";

/**
 * @function
 * @description Determine if search text is a number or a string
 * @param inputValue the use typed value
 * @returns 0 - text search, 2 - text with spaces, 3 - int search
 */
const isStringOrNumber = (inputValue: string): number => {
  inputValue = inputValue.trim().replace(/\s+/g, " ");
  if (/\s+/g.test(inputValue)) {
    return 2;
  }
  return Number.isNaN(+inputValue) ? 0 : 3;
};

/**
 * @function
 * @description To get the corrosponding suggest value for the API call
 * @param objectParsedQuery The URL search query parsed value
 * @returns The number of the API search request suggest value
 */
export const getSuddestValue = (objectParsedQuery: ISearchQuery): number => {
  switch (objectParsedQuery.cat) {
    case MainCriterias.INVOICE:
      switch (objectParsedQuery.sub) {
        case CaseSubCriterias.DEBTOR_NAME:
          return 0; // 2 - string search
        case CaseSubCriterias.DEBTOR_NUMBER:
        case CaseSubCriterias.KID:
        case CaseSubCriterias.INVOICE_NUMBER:
          return isStringOrNumber(objectParsedQuery.value ?? "");
        case CaseSubCriterias.CASE_NUMBER:
          return 3;
        default:
          return isStringOrNumber(objectParsedQuery.value ?? "");
      }

    case MainCriterias.CASE:
      switch (objectParsedQuery.sub) {
        case CaseSubCriterias.DEBTOR_NAME:
          return 0; // 2,4
        case CaseSubCriterias.DEBTOR_NUMBER:
        case CaseSubCriterias.INVOICE_NUMBER:
        case CaseSubCriterias.KID:
          return isStringOrNumber(objectParsedQuery.value ?? "");
        case CaseSubCriterias.CASE_NUMBER:
          return 3;
        default:
          isStringOrNumber(objectParsedQuery.value ?? "");
      }

    case MainCriterias.DEBTOR:
      switch (objectParsedQuery.sub) {
        case DebtorSubCriterias.NUMBER:
        case DebtorSubCriterias.TELEPHONE:
        case DebtorSubCriterias.CASE_NUMBER:
        case DebtorSubCriterias.PERSON_NUMBER:
        case DebtorSubCriterias.AR_NUMBER:
        case DebtorSubCriterias.AR_NUMBER:
        case DebtorSubCriterias.REAL_ESTATE_BNR:
        case DebtorSubCriterias.REAL_ESTATE_FNR:
        case DebtorSubCriterias.REAL_ESTATE_GNR:
        case DebtorSubCriterias.REAL_ESTATE_SNR:
        case DebtorSubCriterias.EMPLOYER_NUMBER:
        case DebtorSubCriterias.EMPLOYER_NIN:
          return 3;
        case DebtorSubCriterias.BIRTHDAY:
          return Number.isNaN(objectParsedQuery.value) ? 0 : 2;
        case DebtorSubCriterias.NAME:
        case DebtorSubCriterias.DOC_OF_TITLE_SEC_NO:
        case DebtorSubCriterias.VEHICLE_REGISTER_NUMBER:
        case DebtorSubCriterias.FINANCIAL_ASSET_NAME:
        case DebtorSubCriterias.ADDRESS:
          return isStringOrNumber(objectParsedQuery.value ?? "");
        default:
          isStringOrNumber(objectParsedQuery.value ?? "");
      }

    case MainCriterias.CREDITOR:
      switch (objectParsedQuery.sub) {
        case CreditorSubCriterias.ADDRESS:
        case CreditorSubCriterias.GROUP_NAME:
        case CreditorSubCriterias.NAME:
          return 2; // 0
        case CreditorSubCriterias.GROUP_NUMBER:
        case CreditorSubCriterias.NUMBER:
          return 3;
        default:
          isStringOrNumber(objectParsedQuery.value ?? "");
      }

    default:
      return isStringOrNumber(objectParsedQuery.value ?? "");
  }
};
