import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";
import _ from "lodash";

export const getEntityDetail = async <P extends { entityRoleId: number }>(
  params: P
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "entityservice",
        `entities/${params.entityRoleId}`,
        {},
        ServiceConfig()[`entityservice`]["entities.:entityRoleId"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addPostalArea = async (params: any): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.post(
        "entityservice",
        `entities/postalCodes`,
        params,
        ServiceConfig()[`entityservice`][`entities.postalCode`]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMunicipals = async <
  P extends {
    countryCode: string;
    searchText: string;
  }
>(
  params: P
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "entityservice",
        `entities/municipalities`,
        {
          countryCode: params?.countryCode,
          searchText: params.searchText.trim(),
        },
        ServiceConfig()[`entityservice`][`entities.municipalities`]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPostelArea = async <
  P extends {
    countryCode: string;
    searchText: string;
    searchBy?: string;
  }
>(
  params: P
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.post(
        "entityservice",
        `postalCodes`,
        {
          countryCode: params.countryCode ?? "NO",
          searchText: params.searchText.trim(),
          searchBy: params.searchBy,
        },
        ServiceConfig()[`entityservice`][`postalCodes`]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
