import { IManageFeeProps } from "us.collection.admin/interfaces/ManageFee";

export const initialState: IManageFeeProps = {
  drawerInfo: {
    title: "",
    visible: false,
    drawerType: "",
    actionType: "",
    record: {},
  },
  feeType: {
    list: [],
    isListLoading: false,
  },
  feeBasis: {
    list: [],
    isListLoading: false,
  },
  feeBasisHistory: {
    list: [],
    isListLoading: false,
  },
  feeRange: {
    list: [],
    isListLoading: false,
  },
  savePayload: {
    data: [],
    isSaving: false,
  },
};
