import moment from "moment";
import { currentTimeFormat } from "us.common/constants";

export enum Days {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday",
}

export const initialNewProfileValues = {
  activityExecutionProfileId: -1,
  profileName: "",
  isDeleted: false,
  schedules: [
    {
      dayId: 1,
      day: Days.MONDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 2,
      day: Days.TUESDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 3,
      day: Days.WEDNESDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 4,
      day: Days.THURSDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 5,
      day: Days.FRIDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 6,
      day: Days.SATURDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
    {
      dayId: 7,
      day: Days.SUNDAY,
      isDayEnabled: false,
      timeSlot: {
        startTime: moment().set({"hour": 0, "minute": 0}).format(currentTimeFormat),
        endTime: moment().set({"hour": 23, "minute": 59}).format(currentTimeFormat),
      },
    },
  ],
};
