import React, { useState } from "react";
import {
  Affix,
  PageHeader,
  Divider,
  Button,
  Drawer,
  Table,
  Form,
  Select,
  Input,
} from "antd";
import { useTranslation } from "react-i18next";

interface ICustomSetting {
  onMinimize(): void;
}

export default (props: ICustomSetting) => {
  const { t } = useTranslation();
  const minimize = () => {
    props.onMinimize();
  };

  const onClose = () => {
    setDrawer({
      title: "",
      visible: false,
    });
  };
  const visibleDrawer = {
    title: "",
    visible: false,
  };

  const [drawer, setDrawer] = useState<any>(visibleDrawer);
  const showDrawer = () => {
    setDrawer({
      title: "",
      visible: true,
    });
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Affix offsetTop={48}>
        <div className="page-header">
          <div className="d-flex align-items-center">
            <PageHeader
              onBack={minimize}
              className="px-0 py-3 mr-n3"
              title={t("US.COLLECTION.COMMON:APPLAYOUT.CUSTOMSETTINGS")}
            />
            <Divider className="bui-devider" type="vertical" />
            <Button
              type="primary"
              size="small"
              icon="plus"
              onClick={showDrawer}
            >
              {t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.NEWSETTINGS")}
            </Button>
          </div>
        </div>
      </Affix>

      <div className="space-content">
        <Table rowKey="id" className="mt-3" size="small" />
      </div>
      <Drawer
        title={t("US.COLLECTION.COMMON:APPLAYOUT.CUSTOMSETTINGS")}
        placement="right"
        width={450}
        closable={false}
        visible={drawer.visible}
        onClose={() => onClose()}
      >
        <Form>
          <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.ENTITY")}>
            <Select></Select>
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.DATATYPE")}>
            <Select></Select>
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.COMMON:COMMON.NAME")}>
          <Input />
          </Form.Item>
          <Form.Item label={t("US.COLLECTION.ADMIN:CUSTOMSETTINGS.DEFAULTVALUE")} >
          <Input />
          </Form.Item>
          <Form.Item label= {t("US.COLLECTION.COMMON:COMMON.DISPLAYNAME")}>
          <Input />
          </Form.Item>
        </Form>

        <div className="drawer-footer-fixed align-content-center justify-content-end">
          <div>
            <Button className="mr-2" type="primary" htmlType="submit">
              {t("US.COLLECTION.COMMON:SAVE")}
            </Button>
            <Button onClick={() => handleCancel()}>
              {t("US.COLLECTION.COMMON:CANCEL")}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};
