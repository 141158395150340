import React from "react";
import { Input, Button } from "antd";
import { FilterOutlined, SearchOutlined } from "us.icons";
import moment from "moment";
import { IFilters } from "../../interface";
import { $InputAmount } from "us.common/components";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";

/**
 * @description - Filters for table tree.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 21/03/2021
 * @param data: any data array, add any hierarchical items as children as its parent element
 * the data array items must have child items as children field to work this TableTree.
 * @param column: Filterd column data.
 * @param filterOnType: Filter data when press search button or while typing.
 * @param handleColumn: Set filtered columns.
 * @param onFilter: Get filtered data.
 * @param onFinishFilter: Set data after finish filtering.
 */
const Filters = ({
  t,
  data,
  column,
  filterOnType,
  handleColumn,
  currentDateFormat,
  currentLanguage,
  currentCurrencyLocal,
  onFilter,
  onFinishFilter,
}: IFilters) => {
  const [filteredCols, setFilteredCols] = handleColumn;

  /**
   * @description - Filter followUp result according to the filters we applied
   * @param {string} dataIndex - index of the data item
   * @param {any} value - value of the data
   * @param {any} filters - current filter data
   * @returns {any} filtered data
   */

  const filterData = (dataIndex: string, value: any, filters: any) => {
    let newData: any = data;
    const searchData = (data: Array<any>) => {
      return data?.filter((o: any) =>
        [
          ...filteredCols.filter((n: any) => n.dataIndex !== dataIndex),
          { dataIndex: dataIndex, val: value, type: column?.customFilter },
        ].every((s: any) => {
          if (s?.type == ColumnFilterTypes.DATE) {
            return moment(o[s.dataIndex])
              .format(currentDateFormat)
              ?.includes(s.val?.toString());
          } else if (s?.type == ColumnFilterTypes.DATE_TIME) {
            return moment(o[s.dataIndex])
              .format(`${currentDateFormat}, HH:mm`)
              ?.includes(s.val?.toString());
          } else if (s?.type == ColumnFilterTypes.AMOUNT) {
            return o[s.dataIndex]?.toString()?.includes(s.val?.toString());
          } else {
            return o[s.dataIndex]
              ?.toString()
              ?.toLowerCase()
              ?.includes(s.val?.toString()?.toLowerCase());
          }
        })
      );
    };

    newData =
      typeof onFilter !== "undefined"
        ? onFilter(searchData, newData, filters)
        : newData;
    onFinishFilter(newData);
  };

  /**
   * @description - Get column filter details
   * @param {any} value - value of the data
   * @returns {any} filtered column data
   */

  const getColumnFilterDetails = (value: any) => {
    try {
      return value !== ""
        ? [
            ...filteredCols.filter(
              (n: any) => n.dataIndex !== column.dataIndex
            ),
            {
              dataIndex: column.dataIndex,
              val: value,
              type: column?.customFilter,
            },
          ]
        : [
            ...filteredCols.filter(
              (n: any) => n.dataIndex !== column.dataIndex
            ),
          ];
    } catch (e) {
      return [];
    }
  };

  const handleSetColumns = (value: any) => {
    setFilteredCols(getColumnFilterDetails(value));
  };

  if (typeof column?.filterComponent == "function") {
    return column?.filterComponent(
      column,
      filteredCols,
      setFilteredCols,
      filterData
    );
  } else {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          {column?.customFilter !== ColumnFilterTypes.AMOUNT ? (
            <Input
              name={`search-${column.dataIndex}`}
              type="primary"
              size="small"
              placeholder={t("COMMON.SEARCH")}
              value={
                filteredCols.filter(
                  (i: any) => i.dataIndex == column.dataIndex
                )[0]?.val
              }
              onChange={(e: any) => {
                handleSetColumns(e.target.value);
                filterOnType &&
                  filterData(
                    column.dataIndex,
                    e.target.value,
                    getColumnFilterDetails(e.target.value)
                  );
              }}
              onPressEnter={(e: any) => {
                filterData(
                  column.dataIndex,
                  e.target.value,
                  getColumnFilterDetails(e.target.value)
                );
                confirm();
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          ) : (
            <$InputAmount
              min={0}
              className="w-100"
              placeholder="0,00"
              name={`amount-${column.dataIndex}`}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrencyLocal}
              value={
                !filteredCols.filter(
                  (i: any) => i.dataIndex == column.dataIndex
                )[0]?.val
                  ? 0
                  : filteredCols.filter(
                      (i: any) => i.dataIndex == column.dataIndex
                    )[0]?.val
              }
              onChange={(amount: any) => {
                amount = amount == 0 ? "" : amount;
                handleSetColumns(amount);
                filterOnType &&
                  filterData(
                    column.dataIndex,
                    amount,
                    getColumnFilterDetails(amount)
                  );
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  filterData(
                    column.dataIndex,
                    filteredCols.filter(
                      (i: any) => i.dataIndex == column.dataIndex
                    )[0]?.val,
                    getColumnFilterDetails(
                      filteredCols.filter(
                        (i: any) => i.dataIndex == column.dataIndex
                      )[0]?.val
                    )
                  );
                  confirm();
                }
              }}
            />
          )}
          {!filterOnType && (
            <Button
              type="primary"
              onClick={() => {
                filterData(
                  column.dataIndex,
                  filteredCols.filter(
                    (i: any) => i.dataIndex == column.dataIndex
                  )[0]?.val,
                  getColumnFilterDetails(
                    filteredCols.filter(
                      (i: any) => i.dataIndex == column.dataIndex
                    )[0]?.val
                  )
                );
                confirm();
              }}
              size="small"
              icon={<SearchOutlined />}
              style={{ width: 90, marginRight: 8 }}
            >
              {t("COMMON.SEARCH")}
            </Button>
          )}
          <Button
            onClick={() => {
              handleSetColumns("");
              filterData(column.dataIndex, "", getColumnFilterDetails(""));
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("COMMON.RESET")}
          </Button>
        </div>
      ),
      filterIcon: () => {
        let isVisible =
          typeof filteredCols.filter(
            (i: any) => i.dataIndex == column.dataIndex
          )[0]?.val !== "undefined" &&
          filteredCols.filter((i: any) => i.dataIndex == column.dataIndex)[0]
            ?.val !== ""
            ? true
            : false;
        return (
          <FilterOutlined
            style={{
              color: isVisible ? "#1890ff" : undefined,
            }}
          />
        );
      },
      render: (text: string) => <div>{text}</div>,
    };
  }
};

export default Filters;
