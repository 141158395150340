import { initialState } from "us.collection.admin/reducers/CourtInfo/State";
import { CourtInfo } from "us.collection.admin/constants/Actions";
import { getCombinedData } from "../../functions/HelperFunctions";

const {
  GET_COURT_INFO,
  GET_COURT_INFO_SUCCESS,
  GET_COURT_INFO_FAIL,
  GET_COURT_TYPES,
  GET_COURT_TYPES_SUCCESS,
  GET_COURT_TYPES_FAIL,
  GET_RELEVANT_POSTAL_AREAS,
  GET_RELEVANT_POSTAL_AREAS_SUCCESS,
  GET_RELEVANT_POSTAL_AREAS_FAIL,
  INIT_GET_COURT_INFO,
  RESET_RELEVANT_POSTAL_AREAS,
  GET_POSTAL_AREAS_FOR_COURT,
  GET_POSTAL_AREAS_FOR_COURT_SUCCESS,
  GET_POSTAL_AREAS_FOR_COURT_FAIL,
  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT,
  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_SUCCESS,
  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_FAIL,
} = CourtInfo;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case INIT_GET_COURT_INFO:
      return Object.assign({}, state, {
        courtItemsLoading: true,
      });
    case GET_COURT_INFO:
      return Object.assign({}, state, {
        courtItems: [],
        courtItemsLoading: true,
        selectedCourtType: payload?.data,
      });
    case GET_COURT_INFO_SUCCESS:
      return Object.assign({}, state, {
        courtItems: payload?.data?.map((item: any) => {
          return { ...item, address: getCombinedData(item, "address") };
        }),
        courtItemsLoading: false,
      });
    case GET_COURT_INFO_FAIL:
      return Object.assign({}, state, {
        courtItems: state.courtItems,
        courtItemsLoading: false,
      });
    case GET_COURT_TYPES:
      return Object.assign({}, state, {
        courtTypes: state.courtTypes,
      });
    case GET_COURT_TYPES_SUCCESS:
      const courtTypesList = payload?.data?.map((type: any) => {
        return { ...type, name: type.name.trim() };
      });
      return Object.assign({}, state, {
        courtTypes: initialState.courtTypes.concat(courtTypesList),
      });
    case GET_COURT_TYPES_FAIL:
      return Object.assign({}, state, {
        courtTypes: state.courtTypes,
      });
    case GET_RELEVANT_POSTAL_AREAS:
      return Object.assign({}, state, {
        selectedPostalCodes: [],
        postalAreasForCourt: [],
      });
    case GET_RELEVANT_POSTAL_AREAS_SUCCESS:
      const codeList: any = [];
      payload?.data?.map((code: any) => {
        codeList.push({
          postalCode: code.postCode,
          postalPlace: code.postPlace,
        });
      });
      return Object.assign({}, state, {
        selectedPostalCodes: codeList,
      });
    case GET_RELEVANT_POSTAL_AREAS_FAIL:
      return Object.assign({}, state, {
        selectedPostalCodes: [],
      });
    case RESET_RELEVANT_POSTAL_AREAS:
      return Object.assign({}, state, {
        selectedPostalCodes: [],
        postalAreasForCourt: [],
        isLoadingPostalCode: false,
        isValidPostalCode: true,
      });
    case GET_POSTAL_AREAS_FOR_COURT:
      return Object.assign({}, state, {
        postalAreasForCourt: [],
        isLoadingPostalCode: true,
        isValidPostalCode: true,
      });
    case GET_POSTAL_AREAS_FOR_COURT_SUCCESS:
      return Object.assign({}, state, {
        postalAreasForCourt: payload?.data,
        isLoadingPostalCode: false,
        isValidPostalCode: true,
      });
    case GET_POSTAL_AREAS_FOR_COURT_FAIL:
      return Object.assign({}, state, {
        postalAreasForCourt: [],
        isLoadingPostalCode: false,
        isValidPostalCode: false,
      });
    case GET_APPLICABLE_POSTAL_AREAS_FOR_COURT:
      return Object.assign({}, state, {
        selectedPostalCodes: state.selectedPostalCodes,
      });
    case GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_SUCCESS:
      return Object.assign({}, state, {
        selectedPostalCodes: payload?.data,
      });
    case GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_FAIL:
      return Object.assign({}, state, {
        selectedPostalCodes: state.selectedPostalCodes,
      });
    default:
      return state;
  }
};
