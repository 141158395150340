import { ExtraColumnsOptions } from "us.common/components/SelectCreditors/Constants";

export const EXTRA_COLUMNS:Array<{label:string,value:ExtraColumnsOptions}> = [
    {
        label: "US.COLLECTION.COMMON:COMMON.MASTER_CREDITOR_ID",
        value: ExtraColumnsOptions.MASTER_CREDITOR_ID,
      },
      {
        label: "US.COLLECTION.COMMON:COMMON.MASTER_CREDITOR_NAME",
        value: ExtraColumnsOptions.MASTER_CREDITOR_NAME,
      },
      {
        label: "US.COLLECTION.COMMON:COMMON.ZIP_CODE",
        value: ExtraColumnsOptions.ZIP_CODE,
      },
      {
        label: "US.COLLECTION.COMMON:COMMON.ZIP_NAME",
        value: ExtraColumnsOptions.ZIP_NAME,
      },
]