import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { $Button, $TableTree, $Skeleton } from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { EVENT_ACTIVITY_TYPE } from "us.collection.admin/constants";
import { findSelectedName } from "./Functions";
import { useFormikContext } from "formik";
import _ from "lodash";

const { activity, parameters } = manageActivities;

/**
 * @description Activity Type Names
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */
const ActivityTypeNames: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const { setFieldValue, values } = useFormikContext();
  const { manageBreadCrumb, properties, events } = props;
  const { dataSource, isFetching } = properties;
  const { data, isLoading } = events ?? {};

  const { activityType } = values as any;

  /**
   * @function
   * @description adding value to activityTypeName field
   * @param {string} record selected record
   * @param {string} type Activity Type
   */
  const onSelectActivityName = (record: any, type: string) => {
    try {
      if (type == EVENT_ACTIVITY_TYPE) {
        const { name, displayName } = record;
        setFieldValue("activityTypeName", name);
        setFieldValue("name", name);
        setFieldValue("displayName", displayName);
      }
      {
        const { name } = record;
        setFieldValue("activityTypeName", name);
      }
      onClose();
    } catch (error) {}
  };
  /**
   * @function
   * @description handle cancel button click event of breadcrumb
   */
  const onClose = () => {
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: false,
        title: "",
      });
  };

  const columns: any = [
    {
      ...(activityType == EVENT_ACTIVITY_TYPE
        ? {
            title: t("US.COLLECTION.ADMIN:ACTIVITIES.DISPLAY_NAME"),
            dataIndex: "displayName",
            key: "displayName",
          }
        : {
            title: t("US.COLLECTION.ADMIN:ACTIVITIES.NAME"),
            dataIndex: "name",
            key: "name",
          }),
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ];

  return (
    <>
      <$Skeleton
        loading={isFetching || isLoading}
        active
        paragraph={{ rows: 2 }}
      >
        <$TableTree
          rowKey="id"
          data={activityType == EVENT_ACTIVITY_TYPE ? data : dataSource}
          size="small"
          onSort={(sortData, dataSource) => {
            return sortData(dataSource);
          }}
          onFilter={(searchData, dataSource) => {
            return searchData(dataSource);
          }}
          filterOnType={true}
          resetOnSourceChange={true}
          bordered
          pagination={{ defaultPageSize: 200 }}
          scroll={{ x: 400, y: "calc(100vh - 290px)" }}
          columns={columns}
          onRow={(record: any) => {
            return {
              onDoubleClick: () => {
                onSelectActivityName(record, activityType);
              },
            };
          }}
          rowSelection={{
            type: "radio",
            onChange: (selectedRowKeys: any) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          firstColSkipFilterProps={-1}
          data-testid="activityTypeNameTable"
        />
      </$Skeleton>
      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button
            className="mr-2"
            onClick={() =>
              onSelectActivityName(
                findSelectedName(
                  activityType == EVENT_ACTIVITY_TYPE ? data : dataSource,
                  selectedRowKeys
                ),
                activityType
              )
            }
            type="primary"
            disabled={selectedRowKeys.length == 0}
          >
            {t("US.COMMON:COMMON.SELECT")}
          </$Button>
          <$Button onClick={onClose}>{t("US.COMMON:COMMON.CANCEL")}</$Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { properties, events } = manageActivities;
  return {
    properties,
    events,
  };
};
const mapDispatchToProps = {
  manageBreadCrumb: activity.manageBreadCrumb,
  getParameters: parameters.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivityTypeNames);