/**
 * The Error404 component renders a message about 404 HTTP error.
 */

import _ from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export default function Page404() {
    const { t } = useTranslation();
    return (
        <div className="container">
            <Route
                render={({ staticContext }) => {
                    if (staticContext) _.assign(staticContext, { status: 404 });
                    return null;
                }}
            />
            <h1 className="message">
            {t('US.COMMON:COMMON.404HTTPERROR')}
      </h1>
            <p className="details">
            {t('US.COMMON:COMMON.DETAIL')} :(
      </p>
        </div>
    );
}
