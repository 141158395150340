import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { $Divider, $Col, $Row } from "us.common/components";
import { CreditorSetupItem } from "./Components";
import { connect, ConnectedProps } from "react-redux";
import { userManagementActions } from "us.common/actions";
import { getCreditorGroupIds } from "./Functions";
import { useFormikContext } from "formik";

const { creditorCompanies, creditors } = userManagementActions;

const CreditorSetup: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
    creditorCompany,
    creditors,
    creditorGroups,
    getCreditorsCompanies,
    getCreditors,
    user,
  } = props;

  const { data: userDetail } = user;
  const {
    creditors: defaultCreditors = [],
    creditorGroups: defaultCreditorGroups = [],
    creditorCompanies: defaultCreditorCompanies = [],
  } = userDetail;

  const [groups, setGroups] = useState<Array<number | string | boolean>>(
    defaultCreditorGroups
  );
  const [companies, setCompanies] = useState<Array<number | string | boolean>>(
    defaultCreditorCompanies
  );
  const { setFieldValue } = useFormikContext() as any;

  const companyData = useMemo(() => {
    return groups.length == 0 ? [] : creditorCompany.data;
  }, [groups, creditorCompany.data]);

  const creditorData = useMemo(() => {
    return groups.length == 0
      ? []
      : companies.length == 0
      ? []
      : creditors.data;
  }, [companies, groups, creditors.data]);

  /**
   * @function
   * @descriptions handle Check Item in Creditor Groups
   * @param {Array<string | number | boolean>} checkedList
   */
  const onChangeCreditorGroups = (
    checkedList: Array<string | number | boolean>
  ) => {
    try {
      setGroups(checkedList);
      getCreditorsCompanies && getCreditorsCompanies(checkedList);
    } catch (error) {}
  };

  /**
   * @function
   * @descriptions handle Check Item in Creditor companies
   * @param {Array<string | number | boolean>} checkedList
   */
  const onChangeCreditorCompanies = (
    checkedList: Array<string | number | boolean>
  ) => {
    try {
      setCompanies(checkedList);
      getCreditors &&
        getCreditors({
          group: getCreditorGroupIds(creditorCompany.data, checkedList),
          company: checkedList,
        });
    } catch (error) {}
  };
  /**
   * @function
   * @descriptions handle Check Item in Creditors
   * @param {Array<string | number | boolean>} checkedList
   */
  const onChangeCreditors = (checkedList: Array<string | number | boolean>) => {
    setFieldValue("creditors", checkedList);
  };

  return (
    <div>
      <$Divider className="my-4" orientation="left">
        {t("US.COMMON:MANAGE_USER.CREDITOR_SETUP")}
      </$Divider>
      <$Row gutter={16}>
        <$Col span={8}>
          <CreditorSetupItem
            name="CREDITOR_GROUP"
            data={creditorGroups.data}
            checkBoxDisplayProperty="groupName"
            isLoading={creditorGroups.isLoading}
            handleCheckItem={onChangeCreditorGroups}
            defaultValue={defaultCreditorGroups}
          />
        </$Col>
        <$Col span={8}>
          <CreditorSetupItem
            name="CREDITOR_COMPANY"
            data={companyData}
            checkBoxDisplayProperty="companyName"
            isLoading={creditorCompany.isLoading}
            handleCheckItem={onChangeCreditorCompanies}
            defaultValue={defaultCreditorCompanies}
          />
        </$Col>
        <$Col span={8}>
          <CreditorSetupItem
            name="CREDITORS"
            data={creditorData}
            checkBoxDisplayProperty="creditorName"
            isLoading={creditors.isLoading}
            handleCheckItem={onChangeCreditors}
            defaultValue={defaultCreditors}
          />
        </$Col>
      </$Row>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { userManagement } = state;
  const { creditors, creditorCompany, creditorGroups, user } = userManagement;
  return {
    creditors,
    creditorCompany,
    creditorGroups,
    user,
  };
};
const mapDispatchToProps = {
  getCreditorsCompanies: creditorCompanies.get,
  getCreditors: creditors.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CreditorSetup);
