import React from "react";
import { useTranslation } from "react-i18next";
import { $Switch } from "us.common/components";
import { IControlItem } from "./Interfaces";

const ControlItem: React.FC<IControlItem> = (props) => {
  const { t } = useTranslation();
  const { name, defaultChecked, label, ...rest } = props;

  return (
    <div className="sc-item">
      <div className="d-flex align-items-center sc-item-switch">
        <$Switch {...rest} name={name} defaultChecked={defaultChecked} />
        <label className="ml-2">
          {t(`US.COLLECTION.ADMIN:ACTIVITIES.${label}`)}
        </label>
      </div>
    </div>
  );
};
export default ControlItem;
