import * as Yup from "yup";
import moment from "moment";
import { FollowUpAction } from "us.common/components/FollowUps/Constants";

interface FollowUpCard extends Yup.MixedSchema {
  key: number;
  comment: string;
  postponeDate: moment.Moment;
  actionPanel: FollowUpAction | "";
}

const FollowUpCardSchema = Yup.object<FollowUpCard>().shape({
  comment: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  postponeDate: Yup.date().when("actionPanel", {
    is: (actionPanel: FollowUpAction | "") =>
      actionPanel === FollowUpAction.POSTPONE,
    then: Yup.date()
      .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
      )
      .typeError(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
      ),
  }),
});

export default FollowUpCardSchema;
