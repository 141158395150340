import React, { useState } from "react";
import { ISelectedDay } from "./Interface";
import { $Switch, $TimePicker } from "us.common/components";
import moment from "moment";
import { useFormikContext, useField } from "formik";
import { currentTimeFormat } from "us.common/constants";
import {
  getSelectedHour,
  disabledHours,
  disabledMinutes,
  isStartTimeGreaterThanEndTime,
} from "./Functions";

/**
 * @description Selected Day Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 20/07/2022
 */
const SelectedDay: React.FC<ISelectedDay> = (props) => {
  const { setFieldValue } = useFormikContext();
  const { startTime, endTime, name, day } = props;

  const [selectedHour, setSelectedHour] = useState<number>(
    moment(startTime, currentTimeFormat).hour() ?? 0
  );

  const [fieldDay] = useField(day);
  const [fieldStartTime] = useField(startTime);
  const [fieldEndTime] = useField(endTime);

  const isDayEnabled: boolean = fieldDay.value;
  const startTimeValue: string = fieldStartTime.value;
  const endTimeValue: string = fieldEndTime.value;

  /**
   * @function
   * @description handle time change event
   * @param {string} fieldName field name which has to change time
   * @param {moment.Moment} time time
   */
  const onChange = (fieldName: string, time: moment.Moment) => {
    setFieldValue(fieldName, moment(time).format(currentTimeFormat));
  };

  /**
   * @function
   * @description handle start time change event
   * @param {moment.Moment} time time
   */
  const onChangeStartTime = (time: moment.Moment) => {
    try {
      onChange(startTime, time);
      setSelectedHour(getSelectedHour(time));
      if (isStartTimeGreaterThanEndTime(time, endTimeValue)) {
        onChange(endTime, moment("23:59", currentTimeFormat));
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="list-table-row">
        <div className="list-table-col pl-4" style={{ width: 170 }}>
          <div className="d-flex align-items-center">
            <$Switch
              name={day}
              size="small"
              checked={isDayEnabled}
              onChange={(checked: boolean) => {
                day && setFieldValue(day, checked);
              }}
            />
            <label className="pl-1">{name}</label>
          </div>
        </div>
        <div className="list-table-col pl-1" style={{ width: 200 }}>
          <$TimePicker
            name={startTime}
            currentTimeFormat={currentTimeFormat}
            allowClear={false}
            disabled={!isDayEnabled}
            style={{ width: "100%" }}
            value={moment(startTimeValue, currentTimeFormat)}
            onChange={(time: moment.Moment) => onChangeStartTime(time)}
            showNow={false}
          />
        </div>
        <div className="list-table-col pl-1" style={{ width: 200 }}>
          <$TimePicker
            name={endTime}
            currentTimeFormat={currentTimeFormat}
            allowClear={false}
            disabled={!isDayEnabled}
            style={{ width: "100%" }}
            value={moment(endTimeValue, currentTimeFormat)}
            onChange={(time: moment.Moment) => onChange(endTime, time)}
            disabledHours={() =>
              disabledHours(moment(startTimeValue, currentTimeFormat))
            }
            disabledMinutes={(hour: number) =>
              disabledMinutes(hour, selectedHour, startTimeValue)
            }
            showNow={false}
          />
        </div>
      </div>
    </>
  );
};

export default SelectedDay;
