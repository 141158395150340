import React, { useEffect, useState, useRef } from 'react';
import { Upload } from 'antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import ImgCrop from 'antd-img-crop';
import { FolderAddOutlined, CameraOutlined, LoadingOutlined } from 'us.icons';
import WebCam from './WebCam';
import { $Spin } from 'us.common/components';

interface IProfileImage {
	name: string;
	value?: string | null;
	requestAction?: any;
	disabled?: boolean;
	onChange?(value: any): void;
	image?: { url: string; isUploading: boolean };
	webCamDisabled?: boolean;
	imgProps?: {
		uploadProps?: { cropperProps?: any; [x: string]: any };
		webCamProps?: any;
	};
}

export const $ProfileImage = ({
	name,
	value,
	requestAction,
	disabled,
	image,
	webCamDisabled,
	imgProps,
	onChange,
}: IProfileImage) => {
	const { t } = useTranslation();

	const [fileList, setFileList] = useState<Array<any>>([]);
	const [isWebCamVisible, setWebCamVisible] = useState<boolean>(false);

	const [field, meta, helpers] = useField(name);
	const [, , imageDataHelpers] = useField('imageData');
	const [, , isUploadingHelpers] = useField('isUploading');

	/* Set value from formik  -default- */
	useEffect(() => {
		if (typeof value == 'undefined') {
			if (field.value == null) {
				setFileList([]);
			} else {
				setFileList([{ uid: '1', url: field.value }]);
			}
		}
	}, [field.value]);

	/* Set custom value */
	useEffect(() => {
		if (typeof value !== 'undefined') {
			if (value == null) {
				setFileList([]);
			} else {
				setFileList([{ uid: '1', url: value }]);
			}
		}
	}, [value]);

	/* Set URL after uploading to the server */
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		if (!image?.isUploading) {
			isUploadingHelpers.setValue(false);
			onChange
				? onChange(image?.url)
				: helpers.setValue(image?.url);
			setFileList([{ uid: '1', url: image?.url }]);
		}
	}, [image?.url]);

	/* Onchange upload component */
	const onUploadChange = async ({ file, fileList }: any) => {
		setFileList(fileList);
		fileList.length == 0 && onChange
			? onChange(null)
			: helpers.setValue(null);
		fileList.length == 0 && imageDataHelpers.setValue(null);
		fileList.length !== 0 && isUploadingHelpers.setValue(true);
	};

	const onPreview = async (file: any) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document?.write(image.outerHTML);
	};

	const onComplete = (file: any) => {
		if (requestAction) {
			requestAction(file);
		} else {
			setFileList([
				{ uid: '1', url: URL.createObjectURL(file) },
			]);
			imageDataHelpers.setValue(file);
		}
	};

	return (
		<div>
			<div className='d-flex'>
				<div>
					{!image?.isUploading && (
						<ImgCrop
							rotate
							modalTitle={t(
								'COMMON.EDIT_IMAGE'
							)}
							{...imgProps?.uploadProps}>
							<Upload
								listType='picture-card'
								fileList={
									fileList
								}
								onChange={
									onUploadChange
								}
								showUploadList={
									true
								}
								onPreview={
									onPreview
								}
								disabled={
									disabled
								}
								customRequest={(
									options: any
								) =>
									onComplete(
										options.file
									)
								}>
								{fileList.length <
									1 && (
									<div className='capture'>
										<FolderAddOutlined />
										<div className='ant-upload-text'>
											{t(
												'COMMON.UPLOAD'
											)}
										</div>
									</div>
								)}
							</Upload>
						</ImgCrop>
					)}
					{image?.isUploading && (
						<div className='d-flex justify-content-center align-items-center capture-content'>
							<div className='capture'>
								<$Spin
									size='small'
									indicator={
										<LoadingOutlined
											style={{
												fontSize: 16,
											}}
											spin
										/>
									}
								/>
								<div className='ant-upload-text'>
									Uploading...
								</div>
							</div>
						</div>
					)}
				</div>
				{fileList.length == 0 &&
					!disabled &&
					!webCamDisabled &&
					!image?.isUploading && (
						<div
							className='d-flex justify-content-center align-items-center capture-content'
							onClick={() =>
								setWebCamVisible(
									true
								)
							}>
							<div className='capture'>
								<CameraOutlined />
								<div className='ant-upload-text'>
									{t(
										'US.TL.MEMBERS:REGISTRATION.WEBCAM'
									)}
								</div>
							</div>
						</div>
					)}
			</div>

			<WebCam
				onPressOk={(file: any) => {
					setWebCamVisible(false);
					isUploadingHelpers.setValue(true);
					onComplete(file);
				}}
				isVisible={isWebCamVisible}
				onCancel={() => setWebCamVisible(false)}
				{...imgProps?.webCamProps}
			/>
		</div>
	);
};
