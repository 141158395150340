import {
  ICaseSearchResult,
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
  IDebtorARSearchResult,
  IDebtorSearchResult,
  IInvoiceSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { SortOrderType } from "../Interfaces";

/**
 * @function
 * @description To toggle the corrosponding sort value
 * @param sortOrder The sorting order either SortOrderEnum.ASC or SortOrderEnum.DESC
 * @param udpatedSortKey The key we are interest to the sorted with
 * @returns The Opposite value to the existing sort order
 */
export const getSortOrderToggled = (
  sortOrder: SortOrderType,
  udpatedSortKey: CombinedKeyType | undefined
): SortOrderType => {
  if (udpatedSortKey) {
    return SortOrderType.ASC;
  }
  return sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
};

type CombinedKeyType = keyof (IInvoiceSearchResult &
  ICaseSearchResult &
  ICreditorGroupSearchResult &
  ICreditorSearchResult &
  IDebtorSearchResult &
  IDebtorARSearchResult);