import { Role } from 'us.common/constants'
import { IRole } from 'us.common/interfaces/UserManagement/Role';


export const getRoles = () => ({
  type: Role.GET_ROLES_START,
});

export const getRolesSuccess = (data: any) => ({
  type: Role.GET_ROLES_SUCCESS,
  payload: data,
});
export const manageRoleDrawer = (data: any) => ({
  type: Role.MANAGE_ROLE_DRAWER,
  payload: data,
});

export const saveNewRole = (data: any) => ({
  type: Role.ADD_NEW_ROLE_START,
  payload: data,
});

export const saveRoleHistory = (data: any) => ({
  type: Role.ADD_ROLE_HISTORY_START,
  payload: data,
});
export const saveNewRoleSuccess = (data: any) => ({
  type: Role.ADD_NEW_ROLE_SUCCESS,
  payload: data,
});

export const updateRole = (data: any, history: any) => ({
  type: Role.UPDATE_ROLE_START,
  payload: data,
  history: history
});

export const updateRoleSuccess = (data: any) => ({
  type: Role.UPDATE_ROLE_SUCCESS,
  payload: data,
});

export const getRoleById = (data: any) => ({
  type: Role.GET_ROLE_BY_ID_START,
  payload: data,
});

export const getRoleByIdSuccess = (data: any) => ({
  type: Role.GET_ROLE_BY_ID_SUCCESS,
  payload: data,
});

export const getRoleModulesAndFeatures = (data: any) => ({
  type: Role.GET_ROLE_MODULES_AND_FEATURES_START,
  payload: data,
});

export const getRoleModulesAndFeaturesSuccess = (data: any) => ({
  type: Role.GET_ROLE_MODULES_AND_FEATURES_SUCCESS,
  payload: data,
});

export const deleteRole = (data: any) => ({
  type: Role.DELETE_ROLE_START,
  payload: data,
});

export const deleteRoleSuccess = (data: any, roleId: any) => ({
  type: Role.DELETE_ROLE_SUCCESS,
  payload: data,
  roleId: roleId,
});

export const resetSelectedRole = (data: any) => ({
  type: Role.RESET_SELECTED_ROLE_START,
  payload: data,
});
export const getRoleHistoryById = (data: any) => ({
  type: Role.GET_ROLE_HISTORY_BY_ID_START,
  payload: data,
});

export const getRoleHistorySuccess = (data: any) => ({
  type: Role.GET_ROLE_HISTORY_BY_ID_SUCCESS,
  payload: data,

});

export const editRole = (data: IRole) => ({
  type: Role.EDIT_ROLE_START,
  payload: data,
});

export const addRoleView = () => ({
  type: Role.ADD_NEW_ROLE_START,
});

export const handlerDrawer = () => ({
  type: Role.HANDLE_DRAWER_ROLE_START,
});

export const roleStateChange = (data: any, path: string) => ({
  type: Role.ROLE_STATE_CHANGE_START,
  payload: data,
  path
});

export const roleStateChangeSuccess = (data: any) => ({
  type: Role.ROLE_STATE_CHANGE_SUCCESS,
  payload: data
});