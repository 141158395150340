import React from 'react';
//import {ErrorBoundary} from 'react-error-boundary'

// export default class ErrorBoundary extends React.Component<{},{hasError:boolean}> {
//     constructor(props:any) {
//       super(props);
//       this.state = { hasError: false };
//     }
  
//     static getDerivedStateFromError(error:any) {
//       // Update state so the next render will show the fallback UI.
//       return { hasError: true };
//     }
  
//     componentDidCatch({error, errorInfo}:any) {
//       // You can also log the error to an error reporting service
//       //logErrorToMyService(error, errorInfo);
//     }
  
//     render() {
//       if (this.state.hasError) {
//         // You can render any custom fallback UI
//         return <h1>Something went wrong.</h1>;
//       }
  
//       return this.props.children; 
//     }
//   }


 
// import React, { Component, ErrorInfo, ReactNode } from "react";

// interface Props {
//   children: ReactNode;
// }

// interface State {
//   hasError: boolean;
// }

// class ErrorBoundary extends Component<Props, State> {
//   public state: State = {
//     hasError: false
//   };

//   public static getDerivedStateFromError(_: Error): State {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   } 

//   public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//     console.error("ErrorBoundary Uncaught error:", error, errorInfo);
//   } 

//   public render() {
//     if (this.state.hasError) {
//       return <h1>Sorry.. there was an error</h1>;
//     }

//     return this.props.children;
//   }
// } 

// export default ErrorBoundary;


// import Catch from "./functional-error-boundary"

// type Props = {
//   children: React.ReactNode
// }

// const ErrorBoundary = Catch(function ErrorBoundary(props: Props, error?: Error) {
//   if (error) {
//     return (
//       <div className="error-screen">
//         <h2>An error has occured</h2>
//         <h4>{error?.message}</h4>
//       </div>
//     )
//   } else {
//     return <React.Fragment>{props.children}</React.Fragment>
//   }
// })

// export default ErrorBoundary; 


function ErrorFallback({error, resetErrorBoundary}:any) {
    return (
      <div>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }
  
  function Bomb() {
    return(<button onClick={() => {throw new Error('💥 CABOOM 💥')}}>toggle explode</button>)
  }
  
  function ErrorHandler(props:any) {
    const [explode, setExplode] = React.useState(false)
    return (
      <div>
        {/* <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {}}
        >
          {Bomb()} 
        </ErrorBoundary> */}
      </div> 
    ) 
  }
 
  export default ErrorHandler;