import React, { useEffect } from "react";
import Common from "us.common";
import {
  BoxIcons,
  IconTypes,
  CheckCircleFilled,
  InfoCircleFilled,
  LoadingOutlined,
} from "us.icons";
import { connect } from "react-redux";
import {
  INavigationData,
  IReturnAction,
  IRootState,
} from "us.collection/interfaces";
import { matchPath, RouteComponentProps } from "react-router-dom";
import * as Actions from "us.collection/actions";
import { useTranslation } from "react-i18next";
import { IsDisabledMergeCaseItem } from "us.collection/functions";

const { $Button, $Tooltip, $Notification, $Spin } = Common.Components;

interface IPendingActivityExecution extends RouteComponentProps {
  execute?: (<T>(data: object | T[]) => IReturnAction<T>) | undefined;
  resetPendingActivityState?:
    | (<T>(data: object | T[]) => IReturnAction<T>)
    | undefined;
  isPendingActivityExecuteSuccess?: boolean;
  isPendingActivityNotAvailable?: boolean;
  metaData: any;
}
const PendingActivityExecution: React.FC<IPendingActivityExecution> = (
  props
) => {
  const { t } = useTranslation();

  const {
    execute,
    isPendingActivityExecuteSuccess,
    isPendingActivityNotAvailable,
    resetPendingActivityState,
    location,
    history,
    metaData,
  } = props;

  const state = location.state as INavigationData;
  const { creditorId } = state ?? {};
  const { caseId, entityType } = metaData.data ?? {}; //creditorId
  useEffect(() => {
    if (isPendingActivityExecuteSuccess) {
      if (getCaseNumberAndType().module != "history") {
        successNotification(
          t("US.COLLECTION.LAYOUT:CASE.ALL_PENDING_ACTIVITIES_ARE_EXECUTED"),
          ViewExecuted,
          ""
        );
      } else {
        successNotification(
          t("US.COLLECTION.LAYOUT:CASE.ALL_PENDING_ACTIVITIES_ARE_EXECUTED"),
          "",
          ""
        );
      }
    }
    if (isPendingActivityNotAvailable) {
      infoNotification(
        t(
          "US.COLLECTION.LAYOUT:CASE.NO_PENDING_ACTIVITIES_ARE_AVAILABLE_TO_EXECUTE"
        ),
        "",
        ""
      );
    }
    resetPendingActivityState && resetPendingActivityState({ status: false });
  }, [isPendingActivityExecuteSuccess, isPendingActivityNotAvailable]);

  /**
   * Get entityId, type, url and module
   * @return {object}  entityId, type, url, module
   */
  const getCaseNumberAndType = () => {
    const { params }: any = matchPath(props.location.pathname, {
      path: "/:caseType/:caseId?/:module?",
      exact: true,
      strict: false,
    });
    let module = params["module"];
    let caseType = params["caseType"];
    let entityId = params["caseId"];
    let type: string = "";
    let url: string = "";
    const urlId: string = params["caseId"];
    switch (caseType) {
      case "case":
        if (entityType === "S") {
          type = "S";
        } else {
          type = "C";
        }
        url = "case";
        entityId = caseId;
        break;
      case "ar":
        type = "A";
        url = "ar";
        break;
      case "creditor":
        type = "CRE";
        url = "creditor";
        entityId = creditorId;
        break;
      case "payment":
        type = "P";
        url = "payment";
        break;
      case "bureau":
        type = "BU";
        entityId = 0;
        url = "bureau";
        break;
    }
    switch (module) {
      case "payment-distribution":
        type = "P";
        url = "payment";
        break;
    }
    return {
      entityId,
      type,
      url,
      module,
      urlId,
    };
  };

  /**
   * Navigate to activity execution history view
   *
   */
  const navigateTo = () => {
    const { url, urlId } = getCaseNumberAndType();
    if (url.toLowerCase() === "bureau") {
      history.push(`/${url}/history`, location.state);
    } else {
      history.push(`/${url}/${urlId}/history`, location.state);
    }
    $Notification.close("2");
  };

  const ViewExecuted = (
    <a onClick={navigateTo}>
      {t("US.COLLECTION.LAYOUT:CASE.VIEW_EXECUTED_ACTIVITES")}
    </a>
  );

  /**
   * Execute all pending activities by entityId and entityType
   *
   */
  const executeAllPendingActivies = () => {
    execute &&
      execute({
        entityId: getCaseNumberAndType().entityId,
        entityType: getCaseNumberAndType().type,
      });
    pendingNotification(
      t("US.COLLECTION.LAYOUT:CASE.EXECUTING_ALL_PENDING_ACTIVITIES"),
      "",
      ""
    );
  };

  /**
   * Display pending notification
   *
   * @param {string} msg - notification message
   * @param {string} description - notification description
   * @param {*} key - unique key for notification
   */
  const pendingNotification = (msg: string, description: string, key: any) => {
    $Notification.info({
      message: msg,
      description: description,
      key: "1",
      placement: "topRight",
      icon: (
        <$Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ),
      duration: 15,
    });
  };

  /**
   * Display success notification and close pending notification
   *
   * @param {string} msg - notification message
   * @param {*} description - notification description
   * @param {*} key - unique key for notification
   */
  const successNotification = (msg: string, description: any, key: any) => {
    $Notification.success({
      message: msg,
      description: description,
      key: "2",
      placement: "topRight",
      icon: <CheckCircleFilled className="text-success" />,
      duration: getCaseNumberAndType().module != "history" ? 30 : 5,
    });
    $Notification.close("1");
  };

  /**
   * Display info notification and close pending notification
   *
   * @param {string} msg - notification message
   * @param {*} description - notification description
   * @param {*} key - unique key for notification
   */
  const infoNotification = (msg: string, description: any, key: any) => {
    $Notification.info({
      message: msg,
      //description: description,
      key: "3",
      placement: "topRight",
      icon: <InfoCircleFilled className="text-info" />,
      duration: 5,
    });
    $Notification.close("1");
  };

  return (
    <$Tooltip
      placement="topLeft"
      title={t("US.COLLECTION.LAYOUT:CASE.RUN_ALL_PENDING_ACTIVITIES")}
    >
      <$Button
        className="bui-btn-tool-icon mx-1"
        onClick={executeAllPendingActivies}
        disabled={metaData.data && IsDisabledMergeCaseItem(metaData.data)}
      >
        <BoxIcons type={IconTypes.BOX_ICON} name="run-activities" />
      </$Button>
    </$Tooltip>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { activity, dashboard } = state;
  const { isPendingActivityExecuteSuccess, isPendingActivityNotAvailable } =
    activity;
  const { metaData } = dashboard;
  return {
    isPendingActivityExecuteSuccess,
    isPendingActivityNotAvailable,
    metaData,
  };
};

const mapDispatchToProps = {
  execute: Actions.activityAction.pendingActivities.save,
  resetPendingActivityState: Actions.activityAction.pendingActivities.reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingActivityExecution);
