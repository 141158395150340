import { initialState } from "us.collection.admin/reducers/ManageFee/State";
import { ManageFee } from "us.collection.admin/constants/Actions";
import { INITIAL_DRAWER_DATA } from "us.collection.admin/components/ManageFee/Constants";

const {
  GET_FEE_TYPES,
  GET_FEE_TYPES_SUCCESS,
  GET_FEE_TYPES_FAIL,
  GET_FEE_BASIS,
  GET_FEE_BASIS_SUCCESS,
  GET_FEE_BASIS_FAIL,
  GET_FEE_RANGE,
  GET_FEE_RANGE_SUCCESS,
  GET_FEE_RANGE_FAIL,
  GET_FEE_BASIS_HISTORY,
  GET_FEE_BASIS_HISTORY_SUCCESS,
  GET_FEE_BASIS_HISTORY_FAIL,
  CHANGE_DRAWER_DETAILS,
  SAVE_FEE_TYPES,
  SAVE_FEE_TYPES_SUCCESS,
  SAVE_FEE_TYPES_FAIL,
  SAVE_FEE_BASIS,
  SAVE_FEE_BASIS_SUCCESS,
  SAVE_FEE_BASIS_FAIL,
  SAVE_FEE_RANGE,
  SAVE_FEE_RANGE_SUCCESS,
  SAVE_FEE_RANGE_FAIL,
  MERGE_FEE_RANGE,
} = ManageFee;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_FEE_TYPES:
      return Object.assign({}, state, {
        feeType: {
          list: [],
          isListLoading: true,
        },
      });
    case GET_FEE_TYPES_SUCCESS:
      return Object.assign({}, state, {
        feeType: {
          list: payload?.data,
          isListLoading: false,
        },
      });
    case GET_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        feeType: {
          list: [],
          isListLoading: false,
        },
      });
    case CHANGE_DRAWER_DETAILS:
      return Object.assign({}, state, {
        drawerInfo: payload?.data,
      });
    case GET_FEE_BASIS:
      return Object.assign({}, state, {
        feeBasis: {
          list: [],
          isListLoading: true,
        },
      });
    case GET_FEE_BASIS_SUCCESS:
      return Object.assign({}, state, {
        feeBasis: {
          list: payload?.data,
          isListLoading: false,
        },
      });
    case GET_FEE_BASIS_FAIL:
      return Object.assign({}, state, {
        feeBasis: {
          list: [],
          isListLoading: false,
        },
        feeRange: {
          list: [],
          isListLoading: false,
        },
      });
    case GET_FEE_BASIS_HISTORY:
      return Object.assign({}, state, {
        feeBasisHistory: {
          list: [],
          isListLoading: true,
        },
      });
    case GET_FEE_BASIS_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        feeBasisHistory: {
          list: payload?.data,
          isListLoading: false,
        },
      });
    case GET_FEE_BASIS_HISTORY_FAIL:
      return Object.assign({}, state, {
        feeBasisHistory: {
          list: [],
          isListLoading: false,
        },
      });
    case GET_FEE_RANGE:
      return Object.assign({}, state, {
        feeRange: {
          list: [],
          isListLoading: true,
        },
      });
    case GET_FEE_RANGE_SUCCESS:
      return Object.assign({}, state, {
        feeRange: {
          list: payload?.data,
          isListLoading: false,
        },
      });
    case GET_FEE_RANGE_FAIL:
      return Object.assign({}, state, {
        feeRange: {
          list: [],
          isListLoading: false,
        },
      });
    case MERGE_FEE_RANGE:
      return Object.assign({}, state, {
        feeRange: {
          list: payload?.data,
          isListLoading: false,
        },
        drawerInfo: INITIAL_DRAWER_DATA,
      });
    case SAVE_FEE_TYPES:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: true,
        },
      });
    case SAVE_FEE_TYPES_SUCCESS:
      return Object.assign({}, state, {
        drawerInfo: INITIAL_DRAWER_DATA,
        savePayload: {
          data: payload?.data,
          isSaving: false,
        },
      });
    case SAVE_FEE_TYPES_FAIL:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: false,
        },
      });
    case SAVE_FEE_BASIS:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: true,
        },
      });
    case SAVE_FEE_BASIS_SUCCESS:
      return Object.assign({}, state, {
        drawerInfo: INITIAL_DRAWER_DATA,
        savePayload: {
          data: payload?.data,
          isSaving: false,
        },
      });
    case SAVE_FEE_BASIS_FAIL:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: false,
        },
      });
    case SAVE_FEE_RANGE:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: true,
        },
      });
    case SAVE_FEE_RANGE_SUCCESS:
      return Object.assign({}, state, {
        drawerInfo: INITIAL_DRAWER_DATA,
        savePayload: {
          data: payload?.data,
          isSaving: false,
        },
      });
    case SAVE_FEE_RANGE_FAIL:
      return Object.assign({}, state, {
        savePayload: {
          data: [],
          isSaving: false,
        },
      });
    default:
      return state;
  }
};
