import { NonProductionDayType, NonProductionDayTypeTranslationKey } from "us.collection.admin/constants";

/**
 * @function
 * @description get translation key for relevant criteria 
 * @param {NonProductionDayTypeInterface} criteria selected criteria
 * @returns {string} Translation Key
 */
export const getTranslationKey = (criteria: string): string => {
    try {
        switch (criteria) {
            case NonProductionDayType.Non_SMS:
                return NonProductionDayTypeTranslationKey.Non_SMS;
            case NonProductionDayType.Non_Banking:
                return NonProductionDayTypeTranslationKey.Non_Banking;
            case NonProductionDayType.Non_Email:
                return NonProductionDayTypeTranslationKey.Non_Email;
            case NonProductionDayType.Non_Workflow:
                return NonProductionDayTypeTranslationKey.Non_Workflow;
            case NonProductionDayType.Non_Printing:
                return NonProductionDayTypeTranslationKey.Non_Printing;
            default:
                return "";
        }
    } catch (error) {
        return "";
    }
};