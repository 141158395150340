import _ from "lodash";
import { IActivity } from "us.common/interfaces";
import { IGroupProperty, ITableData } from "../Interfaces";
import { EVENT_ACTIVITY_TYPE } from "us.common/constants";
import { trimText } from "us.common/functions";

/**
 * @function
 * @description get data for TableTree
 * @param {Array<IActivity>} activities activities
 * @returns {Array<IActivityTableData>} grouping data
 */
export const getTableData = (
    activities: Array<IActivity>,
    groupingProperty: string
): Array<ITableData> => {
    let formatActivities: Array<ITableData> = [];
    try {
        Object.values(_.groupBy(activities, groupingProperty)).forEach(
            (groupActivities: Array<IActivity>, index: number) => {
                formatActivities.push({
                    key: index,
                    activityId: Math.random(),
                    group: groupActivities[0].type,
                    activityCode: "",
                    displayName: "",
                    description: "",
                    children: groupActivities,
                });
            }
        );
        return formatActivities;
    } catch (error) {
        return formatActivities;
    }
};

/**
 * @function
 * @description get grouping options
 * @param {Array<IActivity>} activities activity data
 * @param {IGroupProperty} groupingProperty grouping property
 * @returns {Array<{ label: number | string; value: number | string }>}
 */
export const getGroupsOptions = (
    activities: Array<IActivity>,
    groupingProperty: IGroupProperty,
): Array<{ label: number | string; value: number }> => {
    try {
        const { groupingValue, groupingLabel } = groupingProperty;
        let result: Array<{ label: number | string; value: number }> =
            activities.flatMap((activity: IActivity) => {
                if (activity[groupingLabel as keyof typeof activity] as number | string) {
                    return {
                        label: activity[groupingLabel as keyof typeof activity] as number | string,
                        value: activity[groupingValue as keyof typeof activity] as number,
                        };
                } else {
                    return []
                }
            });

        result.unshift({ label: "All", value: 0 });

        let groups = _.uniqBy(result, "label")
        const eventIndex = _.findIndex(groups, ({label}: any) => trimText(label) == EVENT_ACTIVITY_TYPE.toLowerCase());
        if(eventIndex > -1){
            groups = _.concat(groups, _.pullAt(groups, eventIndex));
        }
        
        return groups;
    } catch (error) {
        return [];
    }
};

/**
 * 
 * @param activities 
 * @param filterId 
 * @param groupingValue 
 * @returns 
 */
export const getFilterActivities = (
    activities: Array<IActivity>,
    filterId: number | string,
    groupingValue: string
): Array<IActivity> => {
    try {
        if (filterId === 0) {
            return activities;
        } else {
            return activities.filter(
                (activity: IActivity) =>
                    activity[groupingValue as keyof typeof activity] == filterId
            );
        }

    } catch (error) {
        return activities;
    }
};

/**
 * @param {Array<IActivity>} activities activity data
 * @returns {string} css class name
 */
export const handleLineSeparator = (
    activities: Array<IActivity>,
): string => {
    try {
        if(activities?.some(({type})=> trimText(type) == EVENT_ACTIVITY_TYPE.toLowerCase())){
            return " line-separator"
        }else{
            return ""
        }
    } catch (error) {
        return "";
    }
};
