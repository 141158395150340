import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from 'us.common';
import Collection from 'us.collection';
import { History } from 'history'

const reducers = {
  ...Common.Reducers,
  ...Collection.Reducers
}

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  ...reducers
})

export default rootReducer;