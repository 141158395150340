export enum Notifications {
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL",
  UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS",
  UPDATE_NOTIFICATIONS_FAIL = "UPDATE_NOTIFICATIONS_FAIL",

  RETRY_NOTIFICATION_ACTION = "RETRY_NOTIFICATION_ACTION",
  RETRY_NOTIFICATION_ACTION_SUCCESS = "RETRY_NOTIFICATION_ACTION_SUCCESS",
  RETRY_NOTIFICATION_ACTION_FAIL = "RETRY_NOTIFICATION_ACTION_FAIL",

  UPDATE_PENDING_NOTIFICATION = "UPDATE_PENDING_NOTIFICATION",
  UPDATE_PENDING_NOTIFICATION_SUCCESS = "UPDATE_PENDING_NOTIFICATION_SUCCESS",
  UPDATE_PENDING_NOTIFICATION_FAIL = "UPDATE_PENDING_NOTIFICATION_FAIL"
}
