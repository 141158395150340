import { SettingType, SystemSetting } from 'us.collection.admin/interfaces';

/**
 * @description - Get separated settings and setting types from the system settings with or without filtered
 * @param {Array<SystemSetting>} systemSettings - System settings list
 * @param {string | undefined} searchText - Search keyword to filter data
 * @returns {types: Array<SettingType>; settings: Array<Setting>} - `settingTypes` and `settings` as an object
 */
export const getSettingTypesAndSettings = (
	systemSettings: Array<SystemSetting>,
	searchText?: string
): { settingTypes: Array<SettingType>; settings: Array<SystemSetting> } => {
	try {
		if (systemSettings) {
			const settingTypes: Array<SettingType> = [];
			const settings: Array<SystemSetting> = [];

			// filter system settings by search text
			const filteredSettings = searchText
				? systemSettings.filter(
						({ name }: SystemSetting) =>
							name
								.toLowerCase()
								.indexOf(
									searchText.toLowerCase()
								) >= 0
				  )
				: systemSettings;

			// generate setting types array
			filteredSettings.map(
				({ typeId, typeName }: SystemSetting) => {
					// check the object already included or not
					const isInclude = settingTypes.some(
						(settingType: SettingType) =>
							settingType.typeId ===
							typeId
					);
					// add new object to the types array
					if (!isInclude) {
						settingTypes.push({
							typeId,
							typeName,
						});
					}
				}
			);

			// generate settings array
			filteredSettings.map((systemSetting: SystemSetting) => {
				// add new object to the settings array
				settings.push(systemSetting);
			});

			return {
				settingTypes: settingTypes.sort((a, b) =>
					a.typeName.localeCompare(b.typeName)
				),
				settings,
			};
		}
		return { settingTypes: [], settings: [] };
	} catch (error) {
		return { settingTypes: [], settings: [] };
	}
};
