import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { $Menu, $Skeleton } from "us.common/components";
import { IManageFollowUpLeftPanel } from "./Interfaces";
import "./ManageFollowUpLeftPanel.scss";

/**
 * @description - Manage Follow-up left panel
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942664743/Follow-up+Custom+Query+Workspace+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const ManageFollowUpLeftPanel: React.FC<IManageFollowUpLeftPanel> = (props) => {
  const { t } = useTranslation();
  const { push, replace } = useHistory();
  const { pathname } = useLocation();

  const { dataSource, isLoading, labelField, valueField, defaultSelectedKeys, disable } =
    props;

  const handleMenuItemClick = (value: string | number) => {
    const { params }: any = matchPath(pathname, {
      path: "/manage-followup/:module?",
      exact: false,
      strict: false,
    });
    if (params["module"] == "custom-query") {
      push(`/manage-followup/${params["module"]}/${value.toString()}`);
    } else {
      push(value.toString());
    }
  };

  return (
    <$Skeleton loading={isLoading} paragraph={{ rows: 2 }} active>
      <$Menu
        defaultSelectedKeys={defaultSelectedKeys}
        className="csql-entity-menu"
        disabled={disable}
      >
        {dataSource?.map((item: any, index: number) => (
          <$Menu.Item
            key={item?.[valueField]}
            onClick={() => handleMenuItemClick(item?.[valueField])}
          >
            {item?.[labelField]}
          </$Menu.Item>
        ))}
      </$Menu>
    </$Skeleton>
  );
};

export default ManageFollowUpLeftPanel;
