import _ from "lodash";
import { httpCollection } from "us.helper/http/collection";

export default {
  feeType: {
    getFeeTypes: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get("entityservice", "feetype", {});
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveNewFeeType: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "entityservice",
            "feetype",
            params
          );
          const typeData = await httpCollection.get(
            "entityservice",
            "feetype",
            {}
          );
          resolve({ data, status, typeData: typeData?.data });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  feeBasis: {
    getFeeBasis: (feeTypeId: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "entityservice",
            `feebasis?feeType=${feeTypeId}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveNewFeeBasis: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "entityservice",
            "feebasis",
            params
          );
          const basisData = await httpCollection.get(
            "entityservice",
            `feebasis?feeType=${params?.feeType}`,
            {}
          );
          resolve({ data, status, basisData: basisData?.data });
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteFeeBasis: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.patch(
            "entityservice",
            "feebasis",
            params?.payload
          );
          const basisData = await httpCollection.get(
            "entityservice",
            `feebasis?feeType=${params?.feeTypeId}`,
            {}
          );
          const historyData = await httpCollection.get(
            "entityservice",
            `feebasis/history?feeType=${params?.feeTypeId}`,
            {}
          );
          resolve({
            data,
            status,
            basisData: basisData,
            historyData: historyData,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  feeBasisHistory: {
    getFeeBasisHistory: (feeTypeId: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "entityservice",
            `feebasis/history?feeType=${feeTypeId}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  feeRange: {
    getFeeRanges: (feeBasisId: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "entityservice",
            `feerange?feeId=${feeBasisId}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveNewFeeRange: (addActionPayload: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const addedData = await httpCollection.post(
            "entityservice",
            "feerange",
            addActionPayload
          );
          resolve(addedData);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteFeeRange: (deleteActionPayload: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const deletedData = await httpCollection.patch(
            "entityservice",
            `feerange?feeRangeId=${deleteActionPayload.join(",")}`,
            {}
          );
          resolve(deletedData);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
