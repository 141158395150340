import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    municipalityName: Yup.string()
      .nullable()
      .required(
        "US.COLLECTION.ADMIN:VALIDATIONS.MUNICIPALITY_NAME_IS_REQUIRED"
      ),
    municipalityCode: Yup.string()
      .nullable()
      .required(
        "US.COLLECTION.ADMIN:VALIDATIONS.MUNICIPALITY_CODE_IS_REQUIRED"
      ),
    countryCode: Yup.string()
      .nullable()
      .required("US.COLLECTION.ADMIN:VALIDATIONS.COUNTRY_IS_REQUIRED"),
  });
};