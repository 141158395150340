export enum CourtInfo {
  GET_COURT_TYPES = "GET_COURT_TYPES",
  GET_COURT_TYPES_SUCCESS = "GET_COURT_TYPES_SUCCESS",
  GET_COURT_TYPES_FAIL = "GET_COURT_TYPES_FAIL",

  UPDATE_COURT_ITEM = "UPDATE_COURT_ITEM",
  UPDATE_COURT_ITEM_SUCCESS = "UPDATE_COURT_ITEM_SUCCESS",
  UPDATE_COURT_ITEM_FAIL = "UPDATE_COURT_ITEM_FAIL",

  ADD_NEW_COURT_ITEM = "ADD_NEW_COURT_ITEM",
  ADD_NEW_COURT_ITEM_SUCCESS = "ADD_NEW_COURT_ITEM_SUCCESS",
  ADD_NEW_COURT_ITEM_FAIL = "ADD_NEW_COURT_ITEM_FAIL",

  GET_RELEVANT_POSTAL_AREAS = "GET_RELEVANT_POSTAL_AREAS ",
  GET_RELEVANT_POSTAL_AREAS_SUCCESS = "GET_RELEVANT_POSTAL_AREAS_SUCCESS",
  GET_RELEVANT_POSTAL_AREAS_FAIL = "GET_RELEVANT_POSTAL_AREAS_FAIL",

  GET_COURT_INFO = "GET_COURT_INFO",
  GET_COURT_INFO_SUCCESS = "GET_COURT_INFO_SUCCESS",
  GET_COURT_INFO_FAIL = "GET_COURT_INFO_FAIL",

  DELETE_COURT_ITEM = "DELETE_COURT_ITEM",
  DELETE_COURT_ITEM_SUCCESS = "DELETE_COURT_ITEM_SUCCESS",
  DELETE_COURT_ITEM_FAIL = "DELETE_COURT_ITEM_FAIL",

  INIT_GET_COURT_INFO = "INIT_GET_COURT_INFO",
  INIT_GET_COURT_INFO_SUCCESS = "INIT_GET_COURT_INFO_SUCCESS",
  INIT_GET_COURT_INFO_FAIL = "INIT_GET_COURT_INFO_FAIL",

  ADD_NEW_COURT_TYPE = "ADD_NEW_COURT_TYPE",
  ADD_NEW_COURT_TYPE_SUCCESS = "ADD_NEW_COURT_TYPE_SUCCESS",
  ADD_NEW_COURT_TYPE_FAIL = "ADD_NEW_COURT_TYPE_FAIL",

  EDIT_COURT_TYPE = "EDIT_COURT_TYPE",
  EDIT_COURT_TYPE_SUCCESS = "EDIT_COURT_TYPE_SUCCESS",
  EDIT_COURT_TYPE_FAIL = "EDIT_COURT_TYPE_FAIL",

  DELETE_COURT_TYPE = "DELETE_COURT_TYPE",
  DELETE_COURT_TYPE_SUCCESS = "DELETE_COURT_TYPE_SUCCESS",
  DELETE_COURT_TYPE_FAIL = "DELETE_COURT_TYPE_FAIL",

  RESET_RELEVANT_POSTAL_AREAS = "RESET_RELEVANT_POSTAL_AREAS",
  RESET_RELEVANT_POSTAL_AREAS_SUCCESS = "RESET_RELEVANT_POSTAL_AREAS_SUCCESS",
  RESET_RELEVANT_POSTAL_AREAS_FAIL = "RESET_RELEVANT_POSTAL_AREAS_FAIL",

  GET_POSTAL_AREAS_FOR_COURT = "GET_POSTAL_AREAS_FOR_COURT",
  GET_POSTAL_AREAS_FOR_COURT_SUCCESS = "GET_POSTAL_AREAS_FOR_COURT_SUCCESS",
  GET_POSTAL_AREAS_FOR_COURT_FAIL = "GET_POSTAL_AREAS_FOR_COURT_FAIL",

  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT = "GET_APPLICABLE_POSTAL_AREAS_FOR_COURT",
  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_SUCCESS = "GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_SUCCESS",
  GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_FAIL = "GET_APPLICABLE_POSTAL_AREAS_FOR_COURT_FAIL",
}
