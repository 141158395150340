import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IParameters } from "./Interfaces";
import {
  $Table,
  $Input,
  $DatePicker,
  $Switch,
  $Select,
} from "us.common/components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ParameterType, InitialField } from "us.common/constants";
import { activityExecutionActions } from "us.common/actions";
import { Execution } from "../Execution";
import { useFormikContext } from "formik";

const { parameters, activity } = activityExecutionActions;

/**
 * @description Activity Execution
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3083632702/Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 19/08/2022
 */
const ParameterTable: React.FC<PropsFromRedux & IParameters> = (props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext() as any;
  const {
    currentDateFormat,
    parameters,
    manageDropDown,
    isDisabled,
    isHideDatePicker,
    resetActivity,
    isPopConfirmEnabled,
    propConfirmDetail,
    isActionExecution,
    selectedEntities,
    maxEntityCountExecuteAtOnce,
    mode,
  } = props;

  const { dataSource, isEnableDropDown } = parameters;

  useEffect(() => {
    if (dataSource.length > 0) {
      window.setTimeout(function () {
        document.querySelector(`[name=${dataSource[0]?.name}]` as any)?.focus();
      }, 300);
    }
  }, [dataSource]);

  /**
   * @function
   * @description manage parameter dropdwon
   */
  const onClose = () => {
    manageDropDown && manageDropDown({});
    resetActivity && resetActivity({});
  };

  /**
   * @function
   * @description set schedule date
   * @param {moment.Moment} date selected date
   * @param setFieldValue -formik setFieldValue prop
   */
  const onSelectDate = (date: moment.Moment, setFieldValue: any) => {
    setFieldValue(InitialField.ExecutingDateTimeWithParameter, date);
  };
  /**
   * @function
   * @description get field for each parameter
   * @param record a table data record
   * @returns relevant compenent
   */
  const getParameterField = (record: any, index: number): JSX.Element => {
    try {
      const { valueType, parameterType, name, value } = record;
      const tabIndex: number = index + 1;
      if (ParameterType.LIST === valueType) {
        return (
          <div>
            <$Select
              name={name}
              allOption={false}
              optionText="value"
              optionValue="id"
              style={{ width: "100%", margin: "4px 0 7px 0" }}
              options={value}
              onSearchBy={["value"]}
              dropdownMatchSelectWidth={false}
              tabIndex={tabIndex}
            />
          </div>
        );
      } else {
        switch (parameterType) {
          case ParameterType.INT:
          case ParameterType.STRING:
            return (
              <div>
                <$Input
                  name={name}
                  size="small"
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
          case ParameterType.DATETIME:
          case ParameterType.DATE:
            return (
              <div>
                <$DatePicker
                  name={name}
                  allowClear={true}
                  size="small"
                  placeholder={currentDateFormat}
                  format={currentDateFormat}
                  value={values[name]}
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
          case ParameterType.BOOLEAN:
            return (
              <div className="d-flex align-items-center mb-1">
                <$Switch name={name} tabIndex={tabIndex} />
              </div>
            );
          default:
            return (
              <div>
                <$Input
                  name={name}
                  size="small"
                  style={{ width: "100%", marginBottom: "-5px" }}
                  tabIndex={tabIndex}
                />
              </div>
            );
        }
      }
    } catch (error) {
      return <></>;
    }
  };

  const parametersColumns: any = [
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.PARAMETER_NAME"),
      dataIndex: "name",
      key: "name",
      width: 150,
      className: "text-nowrap",
      ellipsis: true,
    },
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.PARAMETER_DATA_TYPE"),
      dataIndex: "parameterType",
      key: "parameterType",
      width: 150,
      className: "text-nowrap",
      ellipsis: true,
    },
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.VALUE"),
      dataIndex: "value",
      key: "value",
      className: "parameters-value",
      render: (text: any, record: any, index: number) => {
        return <>{getParameterField(record, index)}</>;
      },
    },
  ];

  return (
    <div className="parameters-dropdown" style={{ width: 585 }}>
      <$Table
        rowKey={(record) => record.name}
        columns={parametersColumns}
        dataSource={dataSource}
        className="parameters mt-1 mb-3"
        size="small"
        pagination={false}
        bordered
        data-testid="settings-table"
      />
      <div className="d-flex justify-content-end mt-2">
        <Execution
          name="executingDateTimeWithParameter"
          hasParameters={true}
          isDisabled={isDisabled}
          isHideDatePicker={isHideDatePicker}
          onClose={onClose}
          isPopConfirmEnabled={isPopConfirmEnabled}
          tabStartedIndex={dataSource.length}
          propConfirmDetail={propConfirmDetail}
          isActionExecution={isActionExecution}
          maxEntityCountExecuteAtOnce={maxEntityCountExecuteAtOnce}
          selectedEntities={selectedEntities}
          mode={mode}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { common, activityExecution } = state;
  const { parameters } = activityExecution;
  const { currentDateFormat } = common;
  return {
    currentDateFormat,
    parameters,
  };
};

const mapDispatchToProps = {
  manageDropDown: parameters.mangeParameterDropDown,
  resetActivity: activity.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ParameterTable);
