import React from 'react';
import * as Components from 'antd';
import * as Utility from '../utility';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const SelectGroup = ({
	value,
	name,
	options,
	optionValue = 'value',
	optionText = 'label',
	placeholder,
	defaultValue,
	defaultLabel,
	hideSearch,
	allowClear,
	size,
	mode,
	onSearch,
	onChange,
	helpers,
	onSearchBy,
	filterOption,
	...rest
}: any) => {
	let equalKeyOption: any = [];
	Object.values(_.groupBy(options, 'name')).map((opr: any) => {
		if (opr.length > 1) {
			equalKeyOption.push({
				name: opr[0].name,
				options: opr.map((e: any) => {
					return {
						label: e.label,
						value: e.value,
					};
				}),
			});
		} else {
			equalKeyOption.push({
				name: opr[0].name,
				options: [
					{
						label: opr[0].label,
						value: opr[0].value,
					},
				],
			});
		}
	});

	return (
		<Components.Select
			value={value}
			placeholder={placeholder}
			showSearch={
				typeof hideSearch == 'undefined' ? true : false
			}
			size={typeof size == 'undefined' ? 'small' : size}
			mode={mode}
			notFoundContent='Not Found'
			filterOption={filterOption}
			defaultValue={defaultValue}
			getPopupContainer={(trigger: any) => trigger.parentNode}
			onChange={
				typeof onChange == 'function'
					? onChange
					: (v: any) => helpers?.setValue(v)
			}
			{...rest}>
			{equalKeyOption?.map((item: any, groupIndex: number) => (
				  <Components.Select.OptGroup 
					key={`${groupIndex}_${item?.name}`} 
					label={item.name}
				  >
					{item?.options.map(
						(opr: any, optionIndex: number) => (
							<Components.Select.Option
								key={`${optionIndex}_${opr?.[optionValue]}`}
								value={
									opr?.[
										optionValue
									]
								}>
								{
									opr?.[
										optionText
									]
								}
							</Components.Select.Option>
						)
					)}
				</Components.Select.OptGroup>
			))}
		</Components.Select>
	);
};

export default ({
	value,
	name,
	options,
	optionValue,
	optionText,
	...restProps
}: any) => {
	if (typeof name == 'undefined') {
		return (
			<SelectGroup
				value={value}
				options={options}
				optionValue={optionValue}
				optionText={optionText}
				name={'default'}
				{...restProps}
			/>
		);
	}

	const [field, meta, helpers] = useField(name);
	const { t } = useTranslation();

	const error = meta?.error;
	const isTouched = meta?.touched;

	return (
		<>
			{restProps.hasOwnProperty('formitem') && (
				<Components.Form.Item
					label={restProps?.formitem?.label}
					className={
						restProps?.formitem?.className
					}
					style={restProps?.formitem?.style}
					{...restProps?.formitem?.props}
					help={
						isTouched &&
						error &&
						t(error as string)
					}
					validateStatus={Utility.validateStatus(
						error,
						isTouched
					)}
					required={
						typeof restProps.required ==
						'undefined'
							? false
							: true
					}>
					<SelectGroup
						value={value}
						options={options}
						helpers={helpers}
						optionValue={optionValue}
						optionText={optionText}
						name={name}
						{...restProps}
					/>
				</Components.Form.Item>
			)}

			{!restProps.hasOwnProperty('formitem') && (
				<SelectGroup
					value={value}
					options={options}
					helpers={helpers}
					optionValue={optionValue}
					optionText={optionText}
					name={name}
					{...restProps}
				/>
			)}
		</>
	);
};
