import { versionedHttpCollection } from "us.helper/http/collection";

export default {
  UserProfilePhoto: {
    get: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.getGraphPhoto(
            "me/photo/$value",
            "v1.0",
            ""
          );
          resolve({ data, status });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
  },
  Organization: {
    get: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.getGraphDetail(
            "organization",
            "v1.0",
            ""
          );
          resolve({ data, status });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
  },
  menuQuickLinks: {
    get: ({ userId }: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "userservice",
            `users/${userId}/quicklink`,
            {},
            "v1"
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    add: (params: { userId: any; operationId: any }): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const saveData = await versionedHttpCollection.post(
            "userservice",
            `users/quicklink`,
            params,
            "v1"
          );
          const getData = await versionedHttpCollection.get(
            "userservice",
            `users/${params.userId}/quicklink`,
            {},
            "v1"
          );

          resolve({
            saveData: saveData.data,
            saveStatus: saveData.status,
            getData: getData.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
	remove: ({userId, operationId}: { userId: any; operationId: any }): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
		  try {
			const saveData = await versionedHttpCollection.delete(
			  "userservice",
			  `users/${userId}/quicklink/${operationId}`,
			  "",
			  {},
			  "v1"
			);
			const getData = await versionedHttpCollection.get(
			  "userservice",
			  `users/${userId}/quicklink`,
			  {},
			  "v1"
			);
  
			resolve({
			  saveData: saveData.data,
			  saveStatus: saveData.status,
			  getData: getData.data,
			});
		  } catch (error) {
			reject(error);
		  }
		});
	  },
  },
};
