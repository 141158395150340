export enum FollowUpUniqueColumn {
    FOLLOWUP_ID = "Followupid",
    EX_CASE_NO = "Excaseno",
    CASE_NO = "Caseno",
    CREDITOR_NO = "Creditorno",
    AR_NO = "Arno",
    PAYMENT_ID = "Paymentid",
    PAYMENT_NO = "Paymentno",
    WORKFLOW_STATE = "Workflowstate",
    FOLLOWUP_CASE_STATE = "Followupcasestate",
    CASE_TYPE = "Casetype",
    CASE_HANDLER = "Casehandler",
    COUNTRY_ID = "countryid",
    REASON = "reason",
    COMMENT = "comment",
    NOTE = "note",
    BALANCE = "balance",
    AMOUNT = "amount",
    MORE = "more",
    INFO = "info",
    TYPE = "type",
    FINAL_COL = "finalCol",
    LAST_ACTION_NAME = "Lastactionname",
    LAST_ACTION_STATUS = 'Lastactionstatus',
    LAST_ACTION_DATE = 'Lastactiondate',
    LAST_ACTION_USER = 'Lastactionuser'
}