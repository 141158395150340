import { useEffect } from 'react';

const addBodyClass = (className:any) => document.body.classList.add(className);
const removeBodyClass = (className:any) => document.body.classList.remove(className);
 
// ONLY USE THIS IN THE CONTAINERS...... 
/**
 * through this method we add and remove class in navigating back and forth from CaseDetail to other CaseLayout views.
 * @param className the name of the class that need to be toggled
 */
const ToggleClass = (className:any):void => {
    useEffect(() => {
        if(className === 'tl-home'){
            className instanceof Array ? className.map(removeBodyClass) : addBodyClass(className);
        }
        else if(className && className.trim()){
            className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
        }
        return () => {
            if(className === 'tl-home'){
                className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
            }
            else if(className && className.trim()){
                className instanceof Array ? className.map(removeBodyClass) : addBodyClass(className);
            }
        };
    }, [])
}

export default ToggleClass;

