import { Home } from './box';
import { IconTypes, IconNames, IParam } from './util';
import {
	SaveOutlined,
	CloseOutlined,
	PlusOutlined,
	MoreOutlined,
	EditOutlined,
	DeleteOutlined,
	FilterOutlined,
	SettingOutlined,
	LogoutOutlined,
	CloseCircleOutlined,
	SearchOutlined,
	DownOutlined,
	CheckOutlined,
	CopyOutlined,
	UserOutlined,
	LoadingOutlined,
	CameraOutlined,
	FolderAddOutlined,
	UploadOutlined,
	LeftOutlined,
	RightOutlined,
	FileTextOutlined,
	FolderOutlined,
	PlusCircleFilled,
	EnvironmentFilled,
	PhoneFilled,
	StarFilled,
	SwapOutlined,
	ExclamationCircleFilled,
	ArrowDownOutlined,
	ArrowRightOutlined,
	RiseOutlined,
	FallOutlined,
	MinusOutlined,
	ExclamationOutlined,
	CalendarOutlined,
	SwapRightOutlined,
	InfoCircleOutlined,
	HomeOutlined,
	FolderOpenFilled,
	FolderFilled,
	FileAddOutlined,
	DownloadOutlined,
	HourglassOutlined,
	CheckSquareOutlined,
	ReloadOutlined,
	CreditCardOutlined,
	QuestionCircleOutlined,
	CheckCircleFilled,
	InfoCircleFilled,
	FilePdfOutlined,
	CaretRightOutlined,
	CheckCircleOutlined,
	AppstoreOutlined,
	UnorderedListOutlined,
	FileExclamationOutlined,
	UpOutlined,
	DoubleLeftOutlined,
	SendOutlined,
	PlusSquareOutlined,
	MinusSquareOutlined,
	ProfileOutlined,
	DoubleRightOutlined,
	ImportOutlined,
	PushpinOutlined,
	UndoOutlined,
	CloseCircleFilled,
	ClockCircleFilled,
	ClockCircleOutlined,
	LinkOutlined,
	EyeOutlined,
	FolderOpenOutlined,
	EyeFilled,
	EllipsisOutlined,
	MenuOutlined,
	PlayCircleOutlined,
	PlayCircleFilled,
	HomeFilled,
	ZoomInOutlined,
	ZoomOutOutlined,
	BoldOutlined,
	UnderlineOutlined,
	ItalicOutlined,
	FontSizeOutlined,
	FontColorsOutlined,
	AlignLeftOutlined,
	AlignRightOutlined,
	AlignCenterOutlined,
	LineHeightOutlined,
	PictureOutlined,
	OrderedListOutlined,
	ClearOutlined,
	AuditOutlined,
	FileDoneOutlined,
	TableOutlined,
	InsertRowBelowOutlined,
	InsertRowRightOutlined,
	BgColorsOutlined,
	DeleteColumnOutlined,
	DeleteRowOutlined,
	LockOutlined,
	UnlockOutlined,
	ScissorOutlined,
	FileOutlined,
	SaveFilled,
	PrinterOutlined,
	ArrowLeftOutlined,
	PushpinFilled,
	FileTextFilled,
	SortAscendingOutlined,
	SortDescendingOutlined,
	ArrowUpOutlined,
	BarsOutlined,
	EyeInvisibleOutlined,
} from '@ant-design/icons';

const BoxIcons = (param: IParam) => {
	switch (param.type) {
		case IconTypes.BOX_ICON:
			return Home(param);

		default:
			return Home(param);
	}
};

export {
	BoxIcons,
	IconTypes,
	IconNames,
	SaveOutlined,
	CloseOutlined,
	PlusOutlined,
	MoreOutlined,
	EditOutlined,
	DeleteOutlined,
	FilterOutlined,
	SettingOutlined,
	LogoutOutlined,
	CloseCircleOutlined,
	SearchOutlined,
	DownOutlined,
	CheckOutlined,
	CameraOutlined,
	FolderAddOutlined,
	UploadOutlined,
	LeftOutlined,
	RightOutlined,
	CopyOutlined,
	UserOutlined,
	LoadingOutlined,
	FileTextOutlined,
	FolderOutlined,
	PlusCircleFilled,
	EnvironmentFilled,
	PhoneFilled,
	StarFilled,
	SwapOutlined,
	ExclamationCircleFilled,
	ArrowDownOutlined,
	ArrowRightOutlined,
	RiseOutlined,
	FallOutlined,
	ExclamationOutlined,
	MinusOutlined,
	CalendarOutlined,
	SwapRightOutlined,
	InfoCircleOutlined,
	HomeOutlined,
	FolderOpenFilled,
	FolderFilled,
	FileAddOutlined,
	DownloadOutlined,
	HourglassOutlined,
	CheckSquareOutlined,
	ReloadOutlined,
	CreditCardOutlined,
	QuestionCircleOutlined,
	CheckCircleFilled,
	InfoCircleFilled,
	FilePdfOutlined,
	CaretRightOutlined,
	CheckCircleOutlined,
	AppstoreOutlined,
	UnorderedListOutlined,
	FileExclamationOutlined,
	UpOutlined,
	DoubleLeftOutlined,
	SendOutlined,
	PlusSquareOutlined,
	MinusSquareOutlined,
	ProfileOutlined,
	DoubleRightOutlined,
	ImportOutlined,
	PushpinOutlined,
	UndoOutlined,
	CloseCircleFilled,
	ClockCircleFilled,
	ClockCircleOutlined,
	LinkOutlined,
	EyeOutlined,
	FolderOpenOutlined,
	EyeFilled,
	EllipsisOutlined,
	MenuOutlined,
	PlayCircleOutlined,
	PlayCircleFilled,
	HomeFilled,
	ZoomInOutlined,
	ZoomOutOutlined,
	BoldOutlined,
	UnderlineOutlined,
	ItalicOutlined,
	FontSizeOutlined,
	FontColorsOutlined,
	AlignLeftOutlined,
	AlignCenterOutlined,
	AlignRightOutlined,
	LineHeightOutlined,
	PictureOutlined,
	OrderedListOutlined,
	ClearOutlined,
	AuditOutlined,
	FileDoneOutlined,
	InsertRowBelowOutlined,
	InsertRowRightOutlined,
	TableOutlined,
	BgColorsOutlined,
	DeleteColumnOutlined,
	DeleteRowOutlined,
	LockOutlined,
	UnlockOutlined,
	ScissorOutlined,
	FileOutlined,
	SaveFilled,
	PrinterOutlined,
	ArrowLeftOutlined,
	PushpinFilled,
	FileTextFilled,
	SortAscendingOutlined,
	SortDescendingOutlined,
	ArrowUpOutlined,
	BarsOutlined,
	EyeInvisibleOutlined,
};
