import React, { useState } from 'react';
import CustomSQL from './CustomSQL';
import NewQuery from './NewQuery';
import { Button, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

interface IBulkOperation {

}

interface IDrawerState {
    isVisible: boolean;
    content: any;
}

enum DrawerName {
    NewQuery,
    CustomSQL
}

export default (props: IBulkOperation) => {

    const [isDrawerVisible, setIsDrawerVisible] = useState<IDrawerState>({ isVisible: false, content: undefined });
    const { t } = useTranslation();

    const setDrawer = (name: DrawerName) => {
        switch (name) {
            case DrawerName.CustomSQL:
                setIsDrawerVisible({ isVisible: true, content: (<CustomSQL />) })
                break;

            case DrawerName.NewQuery:
                setIsDrawerVisible({ isVisible: true, content: (<NewQuery />) })
                break;

            default:
                setIsDrawerVisible({ isVisible: false, content: undefined });
                break;
        }
    }

    const closeDrawer = () => {
        setIsDrawerVisible({ isVisible: false, content: undefined });
    }

    return (
        <>
            <p>Hi this is BulkOperation.Home</p>
            <Button onClick={() => setDrawer(DrawerName.CustomSQL)}>Custom SQL</Button>
            <Button onClick={() => setDrawer(DrawerName.NewQuery)}>New Query</Button>
            <p>{t('US.COLLECTION.ADMIN:BULKOPERATION.TEST')}</p>

            <Drawer
                title="Add New Activity"
                placement="right"
                onClose={closeDrawer}
                visible={isDrawerVisible.isVisible}
                getContainer={false}
                style={{ position: 'absolute' }}
            >
                {isDrawerVisible.content}
            </Drawer>
        </>
    )
}