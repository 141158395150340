import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  GDPRAuthorityComment: {
    save: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "userservice",
            `users/accessauthoritycomments`,
            params,
            ServiceConfig()[`userservice`]["users.accessauthoritycomments"]
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
