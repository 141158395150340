import React from "react";
import { ContextMenuAction } from "us.collection.admin/constants";
import { $ContextMenuItem, $Popconfirm } from "us.common/components";
import { BoxIcons, DeleteOutlined, EditOutlined, IconTypes } from "us.icons";
import { useTranslation } from "react-i18next";
import { OtherFollowUpType } from "us.common/components/FollowUps/Constants";

interface IContextMenu {
  onClick: (action: ContextMenuAction) => void;
  followupType: string;
}
/**
 * @description Manage Followup
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942926869/Manage+Follow-up+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 04/04/2022
 */
export const ContextMenu: React.FC<IContextMenu> = (props) => {
  const { t } = useTranslation();
  const { onClick, followupType } = props;
  return (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => onClick(ContextMenuAction.VIEW_RESULT)}
        aria-disabled={followupType == OtherFollowUpType.STATIC}
      >
        <$ContextMenuItem
          icon={<BoxIcons type={IconTypes.BOX_ICON} name="view-results" />}
          iconStyle={"pt-1 pb-1 pl-1"}
          label={ContextMenuAction.VIEW_RESULT}
          labelStyle={"p-1"}
        />
      </div>
      <div
        className="d-flex flex-row more-item"
        onClick={() => onClick(ContextMenuAction.EDIT)}
      >
        <$ContextMenuItem
          icon={<EditOutlined />}
          iconStyle={"p-1"}
          label={ContextMenuAction.EDIT}
          labelStyle={"p-1"}
        />
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:BULKOPERATION.DELETE_CONFIRM")}
        onConfirm={() => onClick(ContextMenuAction.DELETE)}
        onCancel={() => onClick(ContextMenuAction.CANCEL)}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <$ContextMenuItem
            icon={<DeleteOutlined />}
            iconStyle={"p-1"}
            label={ContextMenuAction.DELETE}
            labelStyle={"p-1"}
          />
        </div>
      </$Popconfirm>
    </div>
  );
};
