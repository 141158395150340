export enum ManageFollowup {
    GET_FOLLOWUP_CATEGORIES_START = "GET_FOLLOWUP_CATEGORIES_START",
    GET_FOLLOWUP_CATEGORIES_SUCCESS = "GET_FOLLOWUP_CATEGORIES_SUCCESS",
    GET_FOLLOWUP_CATEGORIES_FAIL = "GET_FOLLOWUP_CATEGORIES_FAIL",

    GET_FOLLOWUP_LIST_START = "GET_FOLLOWUP_LIST_START",
    GET_FOLLOWUP_LIST_SUCCESS = "GET_FOLLOWUP_LIST_SUCCESS",
    GET_FOLLOWUP_LIST_FAIL = "GET_FOLLOWUP_LIST_FAIL",

    REMOVE_FOLLOWUP_START = "REMOVE_FOLLOWUP_START",
    REMOVE_FOLLOWUP_SUCCESS = "REMOVE_FOLLOWUP_SUCCESS",
    REMOVE_FOLLOWUP_FAIL = "REMOVE_FOLLOWUP_FAIL",

    GET_FOLLOW_UP_ENTITY_TYPES = "GET_FOLLOW_UP_ENTITY_TYPES",
    GET_FOLLOW_UP_ENTITY_TYPES_SUCCESS = "GET_FOLLOW_UP_ENTITY_TYPES_SUCCESS",
    GET_FOLLOW_UP_ENTITY_TYPES_FAIL = "GET_FOLLOW_UP_ENTITY_TYPES_FAIL",

    GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES = "GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES",
    GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_SUCCESS = "GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_SUCCESS",
    GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_FAIL = "GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_FAIL",

    SAVE_CUSTOM_QUERY = "SAVE_CUSTOM_QUERY",
    SAVE_CUSTOM_QUERY_SUCCESS = "SAVE_CUSTOM_QUERY_SUCCESS",
    SAVE_CUSTOM_QUERY_FAIL = "SAVE_CUSTOM_QUERY_FAIL",

    EXECUTE_CUSTOM_QUERY = "EXECUTE_CUSTOM_QUERY",
    EXECUTE_CUSTOM_QUERY_SUCCESS = "EXECUTE_CUSTOM_QUERY_SUCCESS",
    EXECUTE_CUSTOM_QUERY_FAIL = "EXECUTE_CUSTOM_QUERY_FAIL",

    MANAGE_CUSTOM_QUERY_DRAWER = "MANAGE_CUSTOM_QUERY_DRAWER",
    
    GET_FOLLOWUP_RESULT_START = "GET_FOLLOWUP_RESULT_START",
    GET_FOLLOWUP_RESULT_SUCCESS = "GET_FOLLOWUP_RESULT_SUCCESS",
    GET_FOLLOWUP_RESULT_FAIL = "GET_FOLLOWUP_RESULT_FAIL",

    GET_FOLLOW_UP_ACTIVITIES = "GET_FOLLOW_UP_ACTIVITIES",
    GET_FOLLOW_UP_ACTIVITIES_SUCCESS = "GET_FOLLOW_UP_ACTIVITIES_SUCCESS",
    GET_FOLLOW_UP_ACTIVITIES_FAIL = "GET_FOLLOW_UP_ACTIVITIES_FAIL",

    SAVE_FOLLOW_UP_CATEGORY = "SAVE_FOLLOW_UP_CATEGORY",
    SAVE_FOLLOW_UP_CATEGORY_SUCCESS = "SAVE_FOLLOW_UP_CATEGORY_SUCCESS",
    SAVE_FOLLOW_UP_CATEGORY_FAIL = "SAVE_FOLLOW_UP_CATEGORY_FAIL",

    GET_FOLLOW_UP_USERS = "GET_FOLLOW_UP_USERS",
    GET_FOLLOW_UP_USERS_SUCCESS = "GET_FOLLOW_UP_USERS_SUCCESS",
    GET_FOLLOW_UP_USERS_FAIL = "GET_FOLLOW_UP_USERS_FAIL",

    GET_FOLLOW_UP_ROLES = "GET_FOLLOW_UP_ROLES",
    GET_FOLLOW_UP_ROLES_SUCCESS = "GET_FOLLOW_UP_ROLES_SUCCESS",
    GET_FOLLOW_UP_ROLES_FAIL = "GET_FOLLOW_UP_ROLES_FAIL",

    RESET_FOLLOWUP_RESULT = "RESET_FOLLOWUP_RESULT",

    GET_FOLLOWUP_DETAILS = "GET_FOLLOWUP_DETAILS",
    GET_FOLLOWUP_DETAILS_SUCCESS = "GET_FOLLOWUP_DETAILS_SUCCESS",
    GET_FOLLOWUP_DETAILS_FAIL = "GET_FOLLOWUP_DETAILS_FAIL",

    RESET_FOLLOWUP_DETAILS = "RESET_FOLLOWUP_DETAILS",
}