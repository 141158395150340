import React, { useEffect, createContext, useState } from "react";
import { SignalR } from "us.helper/signalr";

/**
 * @description SignalR context
 */
export const SignalRContext = createContext<any>([]);

/**
 * @description SignalR Provider
 */
const SignalRProvider: React.FC<any> = (props) => {
  const [connection, setConnection] = useState<any>(null);

  useEffect(() => {
    SignalR(
      () => {
        console.log("@@started");
      },
      (error) => {
        console.log("@@ended", error);
      },
      (con) => {
        setConnection(con);
      }
    );
  }, []);

  return (
    <SignalRContext.Provider value={{ connection }}>
      {props.children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
