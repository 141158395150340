import { UserAuthorizationAction } from "us.common/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.common/services";
import { UserAuthorization } from "us.common/constants";

const { AuthorizationProfile } = API.UserAuthorization;
const { userAuthorizationProfile } = UserAuthorizationAction;

const AuthorizationProfiles = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(AuthorizationProfile.get, params);
      if (status === 200 && data?.featureList.length) {
        yield put(userAuthorizationProfile.success(data));
      } else {
        yield put(userAuthorizationProfile.fail(data));
      }
    } catch (error) {
      yield put(userAuthorizationProfile.fail(error as any));
    }
  },
};

export default [
  takeLatest(
    UserAuthorization.GET_AUTHORIZATION_PROFILE_START,
    AuthorizationProfiles.get
  ),
];
