import { Workflow } from 'us.collection/constants';
import { IWorkflowState } from "./Interfaces";
import { initialState } from "./State";

const { 
    RETRY_WORKFLOW_START,
    RETRY_WORKFLOW_SUCCESS,
    RETRY_WORKFLOW_FAIL
 } = Workflow;

export default (state: IWorkflowState = initialState, action: any) => {
  switch (action.type) {
    case RETRY_WORKFLOW_START:
      return Object.assign({}, state, { 
        retryWorkflow:{
          data: undefined, 
          isError: false, 
          isLoading: true
        } 
      });
    case RETRY_WORKFLOW_SUCCESS:
      return Object.assign({}, state, {
        retryWorkflow:{
          data: action.payload.data,
          isError: false,
          isLoading: false,
        }
      });
    case RETRY_WORKFLOW_FAIL:
      return Object.assign({}, state, { 
        retryWorkflow:{
          data: undefined, 
          isError: false, 
          isLoading: false
        } 
     });
    default:
      return state;
  }
};
