import {
  MunicipalityColumns,
  INITIAL_FORM_VALUES,
} from "us.collection.admin/components/Municipalities/Constants";
import { Municipality, Country } from "us.collection.admin/interfaces";
import _ from "lodash";

/**
 * @description Get filter options for table columns
 * @param {Array<any>} countries - List of available countries
 * @param {MunicipalityColumns} columnKey - Current column key
 * @returns {Array<any>} - Returns country names or codes
 */
export const getCountryFilters = (
  countries: Array<Country>,
  columnKey: MunicipalityColumns
): Array<any> => {
  try {
    if (Array.isArray(countries)) {
      if (columnKey == MunicipalityColumns.COUNTRY_NAME) {
        return countries?.map(({ name }) => ({
          text: name,
          value: name,
        }));
      } else {
        return countries?.map(({ code }) => ({
          text: code,
          value: code,
        }));
      }
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

/**
 * @description Sort and format country options
 * @param {Array<Country>} countries - country list
 * @returns {Array} - Sorted and formatted countries
 */
export const getCountryOptions = (countries: Array<Country>): Array<any> => {
  try {
    if (Array.isArray(countries)) {
      return _.orderBy(
        countries.map(({ continent, name, code }: any) => ({
          name: continent,
          label: name,
          value: code,
        })),
        ["name", "label"],
        ["asc", "asc"]
      );
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

/**
 * @description Remove white spaces.
 * @param {string} text - Text to be cleaned up
 * @returns {string} - cleaned text
 */
export const trim = (text: string): string => {
  try {
    return text?.trim()?.toLowerCase();
  } catch (error) {
    return "";
  }
};

/**
 * @description Get the initial form values
 * @param {number} municipalityId - selected municipality id
 * @param {Array<any>} municipalities - List of municipalities
 * @returns {INITIAL_FORM_VALUES} - form values
 */
export const getInitFormValues = (
  municipalityId: number,
  municipalities: Array<any>
): typeof INITIAL_FORM_VALUES => {
  try {
    const { municipalityCode, municipalityName, countryCode } =
      _.find(municipalities, { municipalityId }) ?? {};
    return municipalityId == -1
      ? INITIAL_FORM_VALUES
      : {
          ...INITIAL_FORM_VALUES,
          municipalityId,
          municipalityCode,
          municipalityName,
          countryCode,
        };
  } catch (error) {
    return INITIAL_FORM_VALUES;
  }
};

/**
 * @description check for duplicating records
 * @param {Array<Municipality>} municipalities - Array of municipalities
 * @returns {boolean} - duplicating status
 */
export const isMunicipalityDuplicating = (
  municipalities: Array<Municipality>,
  values: any,
  initialValues: any
): boolean => {
  try {
    if (
      trim(initialValues.countryCode) == trim(values.countryCode) &&
      trim(initialValues.municipalityCode) == trim(values.municipalityCode)
    ) {
      return false;
    } else {
      return municipalities.some(
        ({ countryCode, municipalityCode }) =>
          trim(countryCode) == trim(values?.countryCode) &&
          trim(municipalityCode) == trim(values?.municipalityCode)
      );
    }
  } catch (error) {
    return false;
  }
};
