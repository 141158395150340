import {
  IAuthorizationInitialState,
  IUserProfile,
} from "../Interfaces/IInitialState";

const profile: IUserProfile = {
  accessAuthorityLevel: 0,
  accessAuthorityComment: "",
  isCommentEnable: false,
  featureList: [],
};

export const initialState: IAuthorizationInitialState = {
  authorizationProfile: profile,
};
