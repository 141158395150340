import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  AuthorizationProfile: {
    get: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "userservice",
            `users/${params}/authorizationprofile`,
            {},
            'v1'
          );
          resolve({ data, status });
        } catch (error) {
          console.log(error);

          reject(error);
        }
      });
    },
  },
};
