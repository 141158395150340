import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { $Row, $Col, $Input, $Button, $Form } from "../antd";
import { useTranslation } from "react-i18next";
import * as Action from "us.common/actions";
import { validationSchema } from "./Validation";
import { AsyncInputCode } from "./Component";
interface IAddPostalArea {
  drawerContentChangeClick: ({ currentStep }: any) => void;
  currentStep: any;
  cancelClick: ({ currentStep }: any) => any;
  municipal: { MunicipalityCode: string; MunicipalityName: string };
  AddPostalFormData: any;
  updateFormData: (data: any) => void;
  getPostalAreas: any;
  postalAreas: any;
  exisitingCodes: any;
  drawerName: string;
  isSaveSuccess: boolean;
  isExisitingCode: boolean;
}

interface IBase extends IAddPostalArea {
  addPostalArea: any;
  newPostalAreaSucessMsg: any;
  onClickSelectMuniciples: () => void;
  onCancelClick: () => void;
  updateDrawerName: (data: string) => void;
  setSaveStatus: (data: boolean) => void;
  countryCode?: string;
}

const AddPostalArea: React.FC<IBase> = (props) => {
  const { t } = useTranslation();
  const {
    addPostalArea,
    onCancelClick,
    updateFormData,
    updateDrawerName,
    exisitingCodes,
    isSaveSuccess,
    onClickSelectMuniciples,
    setSaveStatus,
    countryCode,
    drawerName,
    isExisitingCode,
  } = props;

  useEffect(() => {
    updateDrawerName(t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA"));
  }, []);

  useEffect(() => {
    if (isSaveSuccess) {
      onCancelClick();
    }
    setSaveStatus(false);
  }, [isSaveSuccess]);

  const handlePostalSubmit = (
    postalData: any,
    { setErrors, setStatus, resetForm, setSubmitting, ...rest }: any
  ) => {
    let req = {
      postalCodeId: -1,
      postalCode: postalData.postalCode,
      postalPlace: postalData.postalPlace,
      country: "",
      countyName: postalData.municipalityName,
      countyCode: postalData.municipalityCode,
      countryCode: props.countryCode ?? "NO",
    };
    addPostalArea(req);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        ...exisitingCodes,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        handlePostalSubmit(values, actions);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <>
          <$Form name="newAddPostalArea" onSubmit={handleSubmit}>
            <$Row gutter={16}>
              <$Col span={12}>
                <AsyncInputCode
                  name="postalCode"
                  size="small"
                  label={t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE")}
                  className="bui-label"
                  countryCode={countryCode ?? "NO"}
                />
              </$Col>
              <$Col span={12}>
                {!isExisitingCode && (
                  <a
                    style={{ marginTop: "2px", display: "block" }}
                    onClick={() => {
                      onClickSelectMuniciples(), updateFormData(values);
                    }}
                  >
                    <strong>
                      {t(
                        "US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME"
                      )}
                    </strong>
                  </a>
                )}
                {isExisitingCode && (
                  <strong>
                    {t("US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME")}
                  </strong>
                )}

                <$Input
                  required
                  size="small"
                  name="municipalityName"
                  disabled
                />
              </$Col>
            </$Row>
            <$Row gutter={16}>
              <$Col span={12}>
                <$Input
                  required
                  size="small"
                  name="postalPlace"
                  label={t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA")}
                  className="bui-label"
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  size="small"
                  name="municipalityCode"
                  disabled
                  label={t(
                    "US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE"
                  )}
                  className="bui-label"
                />
              </$Col>
            </$Row>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <$Button
                className="mr-2"
                type="primary"
                disabled={!rest.isValid || isExisitingCode}
                loading={isSubmitting || isValidating}
                htmlType="submit"
                onClick={(e: any) => {
                  handleSubmit(e);
                  //onCancelClick();
                }}
              >
                {t("US.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button onClick={() => onCancelClick()}>
                {t("US.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { entity, entityDetails } = state;
  const { newPostalAreaSucessMsg } = entity;
  const {
    AddPostalFormData,
    postalAreas,
    exisitingCode,
    drawerName,
    isSaveSuccess,
    isExisitingCode,
  } = entityDetails;
  return {
    newPostalAreaSucessMsg,
    AddPostalFormData,
    postalAreas,
    exisitingCodes: exisitingCode,
    drawerName,
    isSaveSuccess,
    isExisitingCode,
  };
};

const mapDispatchToProps = {
  addPostalArea: Action.entity.addPostalArea,
  updateFormData: Action.entity.PostalCodeFormData,
  getPostalAreas: Action.entity.getPostalAreas,
  setSaveStatus: Action.entity.setSaveStatus,
  updateDrawerName: Action.entity.ChangePostalDrawerTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPostalArea);
