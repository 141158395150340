import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  $Button,
  $DatePicker,
  $TextArea,
  $Tooltip,
} from "us.common/components";
import { BoxIcons, IconTypes } from "us.icons";
import { IFollowUpContent } from "us.common/components/FollowUps/Interfaces";
import {
  FollowUpAction,
  FollowUpPanel,
  OtherFollowUpType
} from "us.common/components/FollowUps/Constants";
import { FollowUpActionReq, DoneOrRemoveManualFollowUp } from "us.common/components/FollowUps/Repository";
import { FollowUpCardSchema } from "us.common/components/FollowUps/Validations";
import moment from "moment";

/**
 * @description - Follow-up card content when clicking after collapse panel
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const Content: React.FC<IFollowUpContent> = (props) => {
  const { t } = useTranslation();
  const { currentDateFormat } = useSelector((state: any) => state.common)
  const [actionPanel, setActionPanel] = useState<FollowUpAction | "">("");

  const {
    uniqueId,
    followUpPanel,
    onExecuteAction,
    followupId,
    followupType,
    entityDetails,
    activityExecution
  } = props;

  const handleFollowUpAction = (values: any) => {
    let request = {};
    if (followUpPanel == FollowUpPanel.OTHER) {
      request = FollowUpActionReq.call(values);
    } else {
      request = DoneOrRemoveManualFollowUp.call(values);
    }
    onExecuteAction(request);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        key: uniqueId,
        comment: "",
        postponeDate: "",
        actionPanel: actionPanel,
      }}
      validationSchema={FollowUpCardSchema}
      onSubmit={(values: any, actions: any) => {
        handleFollowUpAction(values);
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <div className="cfc-item-wrapper">
          {followUpPanel == FollowUpPanel.OTHER && (
            <div className="mt-1 mb-3">
              {activityExecution}
            </div>
          )}
          {(followUpPanel != FollowUpPanel.OTHER ||
            followupType == OtherFollowUpType.STATIC) && (
            <div className="mt-1 text-primary" data-testid="action-panel">
              <a
                className={`mr-4 ${
                  actionPanel == FollowUpAction.DONE ? "font-weight-bold" : ""
                }`}
                onClick={() => {
                  setActionPanel(FollowUpAction.DONE);
                  rest.setFieldValue("actionPanel", FollowUpAction.DONE);
                }}
              >
                {t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.DONE")}
              </a>
              <a
                className={`mr-4 text-error ${
                  actionPanel == FollowUpAction.REMOVE ? "font-weight-bold" : ""
                }`}
                onClick={() => {
                  setActionPanel(FollowUpAction.REMOVE);
                  rest.setFieldValue("actionPanel", FollowUpAction.REMOVE);
                }}
              >
                {t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.REMOVE")}
              </a>
              {followUpPanel == FollowUpPanel.OTHER && (
                <a
                  className={`mr-4 ${
                    actionPanel == FollowUpAction.POSTPONE
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => {
                    setActionPanel(FollowUpAction.POSTPONE);
                    rest.setFieldValue("actionPanel", FollowUpAction.POSTPONE);
                  }}
                >
                  {t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.POSTPONE")}
                </a>
              )}
            </div>
          )}

          {[
            FollowUpAction.DONE,
            FollowUpAction.REMOVE,
            FollowUpAction.POSTPONE,
          ].includes(actionPanel as FollowUpAction) && (
            <div className="mt-1 cfc-item-panel">
              <$TextArea
                name="comment"
                autoSize={{ minRows: 3, maxRows: 3 }}
                size="small"
                placeholder={t("US.COLLECTION.COMMON:COMMON.COMMENT")}
                data-testid="comment-textArea"
              />

              <div className="pt-2 d-flex justify-content-end">
                {actionPanel == FollowUpAction.DONE && (
                  <$Tooltip
                    placement="top"
                    title={t(
                      `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.MARK_AS_DONE`
                    )}
                  >
                    <$Button
                      icon={
                        <BoxIcons
                          type={IconTypes.BOX_ICON}
                          name="done-followup"
                        />
                      }
                      type="primary"
                      size="small"
                      onClick={(e: any) => {
                        rest.setFieldValue("action", FollowUpAction.DONE);
                        handleSubmit(e);
                      }}
                    />
                  </$Tooltip>
                )}
                {actionPanel == FollowUpAction.REMOVE && (
                  <$Tooltip
                    placement="top"
                    title={t(
                      `US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.MARK_AS_REMOVE`
                    )}
                  >
                    <$Button
                      icon={
                        <BoxIcons
                          type={IconTypes.BOX_ICON}
                          name="remove-followup"
                        />
                      }
                      type="primary"
                      danger
                      size="small"
                      className="ml-1"
                      onClick={(e: any) => {
                        rest.setFieldValue("action", FollowUpAction.REMOVE);

                        handleSubmit(e);
                      }}
                    />
                  </$Tooltip>
                )}
              </div>

              {actionPanel == FollowUpAction.POSTPONE && (
                <div className="d-flex align-items-start justify-content-between">
                  <$DatePicker
                    name="postponeDate"
                    size="small"
                    value={values?.postponeDate}
                    allowClear={true}
                    placeholder={t(
                      "US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.POSTPONE_DATE"
                    )}
                    format={currentDateFormat}
                    style={{ width: 200 }}
                    disabledDate={(current: moment.Moment) =>
                      current && current.isBefore(moment().add(1, "day"), "day")
                    }
                  />
                  <$Tooltip
                    placement="top"
                    title={t(`US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.POSTPONE`)}
                  >
                    <$Button
                      icon={
                        <BoxIcons
                          type={IconTypes.BOX_ICON}
                          name="postpone-followup"
                        />
                      }
                      type="primary"
                      size="small"
                      className="ml-1"
                      style={{ marginTop: 3 }}
                      onClick={(e: any) => {
                        rest.setFieldValue("action", FollowUpAction.POSTPONE);
                        handleSubmit(e);
                      }}
                    />
                  </$Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};


export default Content;
