export enum AddDebtor {
  SEARCH_POSTAL_CODE_START = "SEARCH_POSTAL_CODE_START",
  SEARCH_POSTAL_CODE_SUCCESS = "SEARCH_POSTAL_CODE_SUCCESS",
  SEARCH_POSTAL_CODE_FAIL = "SEARCH_POSTAL_CODE_FAIL",

  SEARCH_MUNICIPALITY_CODE_START = "SEARCH_MUNICIPALITY_CODE_START",
  SEARCH_MUNICIPALITY_CODE_SUCCESS = "SEARCH_MUNICIPALITY_CODE_SUCCESS",
  SEARCH_MUNICIPALITY_CODE_FAIL = "SEARCH_MUNICIPALITY_CODE_FAIL",

  GET_OTHER_PARTY_ROLE_TYPE_STRAT = "GET_OTHER_PARTY_ROLE_TYPE_STRAT",
  GET_OTHER_PARTY_ROLE_TYPE_SUCCESS = "GET_OTHER_PARTY_ROLE_TYPE_SUCCESS",
  GET_OTHER_PARTY_ROLE_TYPE_FAIL = "GET_OTHER_PARTY_ROLE_TYPE_FAIL",

  SAVE_OTHER_PARTY_ROLE_TYPE_STRAT = "SAVE_OTHER_PARTY_ROLE_TYPE_STRAT",
  SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS = "SAVE_OTHER_PARTY_ROLE_TYPE_SUCCESS",
  SAVE_OTHER_PARTY_ROLE_TYPE_FAIL = "SAVE_OTHER_PARTY_ROLE_TYPE_FAIL",

  DELETE_OTHER_PARTY_ROLE_TYPE_STRAT = "DELETE_OTHER_PARTY_ROLE_TYPE_STRAT",
  DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS = "DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS",
  DELETE_OTHER_PARTY_ROLE_TYPE_FAIL = "DELETE_OTHER_PARTY_ROLE_TYPE_FAIL",

  SEARCH_ENTITY_STRAT = "SEARCH_ENTITY_STRAT",
  SEARCH_ENTITY_SUCCESS = "SEARCH_ENTITY_SUCCESS",
  SEARCH_ENTITY_FAIL = "SEARCH_ENTITY_FAIL",

  RESET_ENTITY_DATA = "RESET_ENTITY_DATA",
}
