import { matchPath } from "react-router-dom";
import { IMetaData, IEntityDetail } from "us.common/interfaces";
import { EntityType, EntityTypeShortForm } from 'us.common/constants'

/**
 * @function
 * @description get entity detail using location and meta data
 * @param {string} pathName browser path
 * @param {IMetaData| undefined} metaData meta data of particular entity
 * @returns {IEntityDetail}
 */
export const getEntityDetail = (pathName: string, metaData?: IMetaData): IEntityDetail => {
    let detail = {
        entityType: '',
        entityTypeShortForm: '',
        entityId: '',
        urlEntityType: '',
        subEntityType: ''
    }
    try {
        const { params }: any = matchPath(pathName, {
            path: "/:caseType/:caseId?/:module?",
            exact: true,
            strict: false,
        });
        const { entityType, caseId, creditorId } = metaData ?? {};
        const entityId = params["caseId"];
        const urlEntityType: string = params["caseType"];

        switch (urlEntityType.toLowerCase()) {
            case EntityType.CASE.toLowerCase():
                if (entityType?.toLowerCase() === 's') {
                    detail = Object.assign({}, detail, {
                        subEntityType: EntityType.SUB_CASE,
                        entityType: EntityType.SUB_CASE,
                        entityTypeShortForm: EntityTypeShortForm.SUB_CASE,
                        caseId
                    })
                } else {
                    detail = Object.assign({}, detail, {
                        subEntityType: EntityType.CASE,
                        entityType: EntityType.CASE,
                        entityTypeShortForm: EntityTypeShortForm.CASE,
                        caseId
                    })
                }
                break;
            case EntityType.AR.toLowerCase():
                detail = Object.assign({}, detail, {
                    subEntityType: EntityType.DEBTOR,
                    entityType: EntityType.AR,
                    entityTypeShortForm: EntityTypeShortForm.AR,
                })
                break;
            case EntityType.CREDITOR.toLowerCase():
                detail = Object.assign({}, detail, {
                    subEntityType: EntityType.CREDITOR,
                    entityType: EntityType.CREDITOR,
                    entityTypeShortForm: EntityTypeShortForm.CREDITOR,
                    creditorId
                })
                break;

            case EntityType.PAYMENT.toLowerCase():
                detail = Object.assign({}, detail, {
                    subEntityType: EntityType.PAYMENT,
                    entityType: EntityType.PAYMENT,
                    entityTypeShortForm: EntityTypeShortForm.PAYMENT,
                })
                break;
            case EntityType.BUREAU:
                detail = Object.assign({}, detail, {
                    subEntityType: EntityType.BUREAU,
                    entityType: EntityType.BUREAU,
                    entityTypeShortForm: EntityTypeShortForm.BUREAU,
                })
                break;
        }
        return { ...detail, entityId, urlEntityType };
    } catch (error) {
        return detail;
    }
}

/**
 * 
 * @param entityDetails 
 * @returns {number | String | undefined}
 */
export const getEntityId = (entityDetails: IEntityDetail): number | undefined => {
    try {
        const { entityId, entityType, caseId, creditorId } = entityDetails
        switch (entityType) {
            case EntityType.SUB_CASE:
            case EntityType.INVOICE:
            case EntityType.CASE:
                return caseId;
            case EntityType.CREDITOR:
                return creditorId;
            default:
                return entityId as number
        }
    } catch (error) {

    }
}