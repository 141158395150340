/**
 * @description - to handle the all checkbox click for creditors table
 * @param {*} selectedCreditors
 * @param {any[]} creditorIds
 * @param {(((<T>(data?: object | T[] | undefined) => void) &
 *         (<T>(data?: object | T[] | undefined) => void))
 *     | undefined)} selectCreditors
 * @param {((selectedKeys: any[]) => void) | undefined} onSelect
 */
export const handleAllCreditorClick = (
  selectedCreditors: any,
  creditorIds: any[],
  selectCreditors:
    | ((<T>(data?: object | T[] | undefined) => void) &
        (<T>(data?: object | T[] | undefined) => void))
    | undefined,
  onSelect: ((selectedKeys: any[]) => void) | undefined
) => {
  const selectedKeys = selectedCreditors?.length !== creditorIds.length ? creditorIds : [];
  selectCreditors && selectCreditors({ selectedKeys });
  onSelect && onSelect(selectedKeys);
};
