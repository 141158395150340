import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.common/actions";
import * as API from "us.common/services";
import { Role } from "us.common/constants";
import { $MessageBox } from "us.common/components";
import _ from "lodash";
import { setSaveRoleErrorMessage, setDeleteErrorMessage } from "us.common/functions";

const { roleManagementActions } = Actions;
const { role, roles } = roleManagementActions


const roleManagementSagas = {
    roles: {
        get: function* (action: any): any {
            try {
                const data = yield call(API.RoleService.roles.get);
                if (Array.isArray(data)) {
                    yield put(roles.success(data));
                } else {
                    yield put(roles.fail([]));
                }
            } catch (error) {
                yield put(roles.fail([]));
            }
        },
    },
    role: {
        getById: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.RoleService.role.getById, params);
                yield put(role.success(data));
            } catch (error) {
                yield put(role.fail({}));
            }
        },
        save: function* (action: any): any {
            const params = action.payload?.data;
            try {
                const data = yield call(API.RoleService.role.save, params);
                if (data?.name) {
                    $MessageBox(
                        "success",
                        `US.COMMON:MESSAGES.ROLE_CREATED_SUCCESSFULLY`,
                        "",
                        ""
                    );
                    if (role.saveSuccess) {
                        yield put(role.saveSuccess(data));
                    }
                    if (roles.get) {
                        yield put(roles.get({}));
                    }
                    if (role.openDrawer) {
                        yield put(role.openDrawer({
                            title: "",
                            isNew: true,
                            visible: false,
                            roleId: 0,
                        }))
                    }
                } else {
                    $MessageBox(
                        "error",
                        `US.COMMON:MESSAGES.ROLE_CAN_NOT_BE_CREATED`,
                        "",
                        ""
                    );
                }
            } catch (error) {
                $MessageBox(
                    "error",
                    `US.COMMON:MESSAGES.${setSaveRoleErrorMessage(error)}`,
                    "",
                    ""
                );
            }
        },
        update: function* (action: any): any {
            const params = action.payload.data?.request;
            const history = action.payload.data?.historyRequest;
            try {
                const data = yield call(API.RoleService.role.update, params);
                if (data?.name) {
                    $MessageBox(
                        "success",
                        `US.COMMON:MESSAGES.ROLE_UPDATES_SUCCESSFULLY`,
                        "",
                        ""
                    );
                    if (role.updateSuccess) {
                        yield put(role.updateSuccess(data));
                    }
                    if (role.saveHistory) {
                        yield put(role.saveHistory(history));
                    }
                    if (role.openDrawer) {
                        yield put(role.openDrawer({
                            title: "",
                            isNew: true,
                            visible: false,
                            roleId: 0,
                        }))
                    }
                    if (role.reset) {
                        yield put(role.reset({}))
                    }
                } else {
                    $MessageBox(
                        "error",
                        `US.COMMON:MESSAGES.ROLE_CAN_NOT_BE_UPDATED`,
                        "",
                        ""
                    );
                }
            } catch (error) {
                $MessageBox(
                    "error",
                    `US.COMMON:MESSAGES.ROLE_CAN_NOT_BE_UPDATED`,
                    "",
                    ""
                );
            }
        },

        delete: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.RoleService.role.delete, params);
                if (_.isEmpty(data)) {
                    $MessageBox(
                        "success",
                        `US.COMMON:MESSAGES.ROLE_IS_DELETED`,
                        "",
                        ""
                    );
                    if (role.deleteSuccess) {
                        yield put(role.deleteSuccess(params?.roleId));
                    }

                } else {
                    $MessageBox(
                        "error",
                        `US.COMMON:MESSAGES.ROLE_CAN_NOT_BE_DELETED`,
                        "",
                        ""
                    );
                }
            } catch (error) {
                $MessageBox(
                    "error",
                    `US.COMMON:MESSAGES.${setDeleteErrorMessage(error)}`,
                    "",
                    ""
                );
            }
        },
        getModulesAndFeatures: function* (action: any): any {
            try {
                const data = yield call(API.RoleService.role.getModules);
                if (role.getModulesSuccess && Array.isArray(data)) {
                    yield put(role.getModulesSuccess(data));
                } else if (role.getModulesFail) {
                    yield put(role.getModulesFail(data));
                }
            } catch (error) {
                if (role.getModulesFail) {
                    yield put(role.getModulesFail([]));
                }
            }
        },
        getHistory: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.RoleService.role.getHistoryById, params);
                if (role.getHistorySuccess && Array.isArray(data)) {
                    yield put(role.getHistorySuccess(data));
                } else if (role.getHistoryFail) {
                    yield put(role.getHistoryFail(data));
                }
            } catch (error) {
                if (role.getHistoryFail) {
                    yield put(role.getHistoryFail([]));
                }
            }
        },
        saveHistory: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.RoleService.role.saveHistory, params);
                if (role.saveHistorySuccess) {
                    yield put(role.saveHistorySuccess(data));
                }
            } catch (error) {
                if (role.saveHistoryFail) {
                    yield put(role.saveHistoryFail([]));
                }
            }
        },
        stateChange: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.RoleService.role.patch, params);
                if (data?.name) {
                    const message = data.activeState
                        ? "ROLE_IS_ACTIVATED"
                        : "ROLE_IS_DEACTIVATED"
                    $MessageBox(
                        "success",
                        `US.COMMON:MESSAGES.${message}`,
                        "",
                        ""
                    );

                    if (role.activateOrDeactivateSuccess) {
                        yield put(role.activateOrDeactivateSuccess(data));
                    }
                } else {
                    $MessageBox(
                        "success",
                        `US.COMMON:MESSAGES.CAN_NOT_BE_CHANGED_ACTIVE_STATE`,
                        "",
                        ""
                    );
                }
            } catch (error) {
                $MessageBox(
                    "error",
                    `US.COMMON:MESSAGES.CAN_NOT_BE_CHANGED_ACTIVE_STATE`,
                    "",
                    ""
                );
            }
        },
    }
};
export default [
    takeLatest(Role.GET_ROLES_START, roleManagementSagas.roles.get),
    takeLatest(Role.GET_ROLE_BY_ID_START, roleManagementSagas.role.getById),
    takeLatest(Role.UPDATE_ROLE_START, roleManagementSagas.role.update),
    takeLatest(Role.DELETE_ROLE_START, roleManagementSagas.role.delete),
    takeLatest(Role.ADD_NEW_ROLE_START, roleManagementSagas.role.save),
    takeLatest(
        Role.GET_ROLE_MODULES_AND_FEATURES_START,
        roleManagementSagas.role.getModulesAndFeatures
    ),
    takeLatest(
        Role.GET_ROLE_BY_ID_START,
        roleManagementSagas.role.getModulesAndFeatures
    ),
    takeLatest(
        Role.GET_ROLE_HISTORY_BY_ID_START,
        roleManagementSagas.role.getHistory
    ),
    takeLatest(
        Role.ADD_ROLE_HISTORY_START,
        roleManagementSagas.role.saveHistory
    ),
    takeLatest(
        Role.ROLE_STATE_CHANGE_START,
        roleManagementSagas.role.stateChange
    ),
];
