import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { MainCriterias } from 'us.common/constants';
import { connect, ConnectedProps } from 'react-redux';
import { Moment } from 'moment';
import { CreditorStatusTypes } from '../Constants';
import { ITabsInfo, IFilterValues } from '../Interfaces';
import {
	$Tooltip,
	$Checkbox,
	$Input,
	$DatePicker,
	$Select,
	$InputAmount,
	$Form,
} from 'us.common/components';

/**
 * @description - Search result filter panel.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const SearchFilters: React.FC<
	PropsFromRedux & {
		tabStates: ITabsInfo;
		filterValueChangeHandler: (filterValues: IFilterValues) => void;
	}
> = (props) => {
	const { t } = useTranslation();
	const {
		tabStates,
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		filterValueChangeHandler,
	} = props;

	const { selectedTab, filterValues } = tabStates;

	const setValue = (
		property: keyof IFilterValues,
		value: string | boolean,
		valiedate: boolean
	) => {
		filterValueChangeHandler({
			...filterValues,
			[property]: value,
		});
	};

	return (
		<Formik enableReinitialize initialValues={filterValues} onSubmit={() => {}}>
			{({
				values,
				setFieldValue,
			}: {
				values: IFilterValues;
				setFieldValue: (
					field: string,
					value: any,
					shouldValidate?: boolean
				) => void;
			}) => (
				<div className='search-filters'>
					<$Form>
						<div
							className={selectedTab === MainCriterias.CREDITOR ? '' : 'pt-4'}>
							<div className='mb-3'>
								{(selectedTab === MainCriterias.INVOICE ||
									selectedTab === MainCriterias.CASE ||
									selectedTab === MainCriterias.DEBTOR) && (
									<$Tooltip
										title={t('US.COLLECTION.COMMON:COMMON.OPEN')}
										placement='top'>
										<span>
											<$Checkbox
												name='isOpenCase'
												checked={filterValues.isOpenCase}
												className='ml-0 mr-0 checkbox-case-open'
												onChange={(e: any) =>
													setValue('isOpenCase', e.target.checked, false)
												}
												data-testid='searchFilter-caseOpen'
											/>
										</span>
									</$Tooltip>
								)}
								{selectedTab === MainCriterias.CASE && (
									<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.SENTENCE')}>
										<span>
											<$Checkbox
												name='isSentenceCase'
												checked={filterValues.isSentenceCase}
												className='ml-1 mr-0 checkbox-case-sentence'
												onChange={(e: any) =>
													setValue('isSentenceCase', e.target.checked, false)
												}
												data-testid='searchFilter-caseSentence'
											/>
										</span>
									</$Tooltip>
								)}
								{(selectedTab === MainCriterias.INVOICE ||
									selectedTab === MainCriterias.CASE) && (
									<$Tooltip
										title={t('US.COLLECTION.COMMON:COMMON.NEXT_DUE_DATE')}>
										<span>
											<$Checkbox
												name='isNextDueDateCase'
												checked={filterValues.isNextDueDateCase}
												className='ml-1 mr-0 checkbox-case-nextduedate'
												onChange={(e: any) =>
													setValue('isNextDueDateCase', e.target.checked, false)
												}
												data-testid='searchFilter-nextDueDate'
											/>
										</span>
									</$Tooltip>
								)}
								{selectedTab === MainCriterias.CASE && (
									<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.COURT')}>
										<span>
											<$Checkbox
												name='isCourtCase'
												checked={filterValues.isCourtCase}
												className='ml-1 mr-0 checkbox-case-court'
												onChange={(e: any) =>
													setValue('isCourtCase', e.target.checked, false)
												}
												data-testid='searchFilter-caseCourt'
											/>
										</span>
									</$Tooltip>
								)}
								{(selectedTab === MainCriterias.INVOICE ||
									selectedTab === MainCriterias.CASE ||
									selectedTab === MainCriterias.DEBTOR) && (
									<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.CLOSE')}>
										<span>
											<$Checkbox
												name='isCloseCase'
												className='ml-1 mr-0 checkbox-case-close'
												checked={filterValues.isCloseCase}
												onChange={(e: any) =>
													setValue('isCloseCase', e.target.checked, false)
												}
												data-testid='searchFilter-caseClose'
											/>
										</span>
									</$Tooltip>
								)}
							</div>
						</div>
						{selectedTab === MainCriterias.CREDITOR && (
							<div className='mb-3'>
								<label>{t('US.COMMON:COMMON.STATUS')}</label>
								<$Select
									name='creditorStatus'
									style={{ width: '100%' }}
									allOption={false}
									value={filterValues.creditorStatus}
									onChange={(val: string) =>
										setValue('creditorStatus', val, false)
									}
									options={[
										{
											label: t('US.COLLECTION.COMMON:COMMON.ALL'),
											value: CreditorStatusTypes.ALL,
										},
										{
											label: t('US.COLLECTION.COMMON:COMMON.ACTIVE'),
											value: CreditorStatusTypes.ACTIVE,
										},
										{
											label: t('US.COLLECTION.COMMON:COMMON.INACTIVE'),
											value: CreditorStatusTypes.INACTIVE,
										},
									]}
									data-testid='searchFilter-creditorStatus'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.CASE_NO')}
									name='caseNumber'
									size='small'
									allowClear
									value={filterValues.caseNumber}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('caseNumber', e.target.value.trim(), false)
									}
									data-testid='searchFilter-caseNo'
								/>
							</div>
						)}
						{selectedTab === MainCriterias.INVOICE && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.INVOICE_NO')}
									name='invoiceNumber'
									size='small'
									value={filterValues.invoiceNumber}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('invoiceNumber', e.target.value.trim(), false)
									}
									data-testid='searchFilter-invoiceNo'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$InputAmount
									label={t('US.COLLECTION.COMMON:COMMON.MAIN_AMOUNT')}
									name='mainAmount'
									size='small'
									currentLanguage={currentLanguage}
									currentCurrency={currentCurrency}
									value={+filterValues.mainAmount}
									className='w-100'
									onChange={(val: number) =>
										setValue('mainAmount', val ? val.toString() : '', false)
									}
									data-testid='searchFilter-mainAmount'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$InputAmount
									label={t('US.COLLECTION.COMMON:COMMON.BALANCE')}
									name='balance'
									size='small'
									currentLanguage={currentLanguage}
									currentCurrency={currentCurrency}
									value={+filterValues.balance}
									className='w-100'
									onChange={(val: number) =>
										setValue('balance', val ? val.toString() : '', false)
									}
									data-testid='searchFilter-balance'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE ||
							selectedTab === MainCriterias.DEBTOR) && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.DEBTOR')}
									name='debtorNameNo'
									size='small'
									value={filterValues.debtorNameNo}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('debtorNameNo', e.target.value, false)
									}
									data-testid='searchFilter-deborNumber'
								/>
							</div>
						)}
						{selectedTab === MainCriterias.DEBTOR && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.DEBTOR_NUMBER')}
									name='custId'
									size='small'
									value={filterValues.custId}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('custId', e.target.value.trim(), false)
									}
									data-testid='searchFilter-custId'
								/>
							</div>
						)}
						{selectedTab === MainCriterias.DEBTOR && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.AR_NUMBER')}
									name='arNo'
									size='small'
									value={filterValues.arNo}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('arNo', e.target.value.trim(), false)
									}
									data-testid='searchFilter-debtorArNo'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$DatePicker
									allowClear
									label={t('US.COLLECTION.COMMON:COMMON.BIRTHDATE')}
									placeholder={t('US.COLLECTION.COMMON:COMMON.SELECT_DATE')}
									name='birthdate'
									format={currentDateFormat}
									size='small'
									style={{ width: '100%' }}
									defaultPickerValue={null}
									value={filterValues.birthdate}
									onChange={(momentTime: Moment) =>
										setValue(
											'birthdate',
											momentTime ? momentTime.format('YYYY-MM-DD') : '',
											false
										)
									}
									data-testid='searchFilter-birthDate'
									className='w-100'
								/>
							</div>
						)}
						{selectedTab === MainCriterias.CREDITOR && (
							<div className='mt-3'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.CREDITOR_GROUP')}
									name='creditorGroupIdName'
									size='small'
									value={filterValues.creditorGroupIdName}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue(
											'creditorGroupIdName',
											e.target.value.trim(),
											false
										)
									}
									data-testid='searchFilter-creditorGroup'
								/>
							</div>
						)}
						<div className='mt-2'>
							<$Input
								label={t('US.COLLECTION.COMMON:COMMON.CREDITOR')}
								name='creditorNameNo'
								size='small'
								value={filterValues.creditorNameNo}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setValue('creditorNameNo', e.target.value, false)
								}
								data-testid='searchFilter-creditorNumber'
							/>
						</div>
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.PHONE')}
									name='phone'
									size='small'
									value={filterValues.phone}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('phone', e.target.value?.trim(), false)
									}
									data-testid='searchFilter-phoneNumber'
									className='w-100'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.ADDRESS')}
									name='address'
									size='small'
									value={filterValues.address}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue('address', e.target.value?.trim(), false)
									}
									data-testid='searchFilter-address'
								/>
							</div>
						)}
						{(selectedTab === MainCriterias.INVOICE ||
							selectedTab === MainCriterias.CASE) && (
							<div className='mt-2'>
								<$Input
									label={t('US.COLLECTION.COMMON:COMMON.LAST_MESSAGE_ACTIVITY')}
									name='lastMsgActivityName'
									size='small'
									value={filterValues.lastMsgActivityName}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue(
											'lastMsgActivityName',
											e.target.value.trim(),
											false
										)
									}
									data-testid='searchFilter-lastMessageActivity'
								/>
							</div>
						)}
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: any) => {
	const { common } = state;
	const { currentCurrency, currentDateFormat, currentLanguage } = common;
	return {
		currentCurrency,
		currentDateFormat,
		currentLanguage,
	};
};

const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchFilters);
