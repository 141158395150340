import { UserInformationAction } from "us.common/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.common/services";
import { UserInformation } from "us.common/constants";
import { $MessageBox, $Message } from "us.common/components";
import { i18n } from "us.helper";

const { UserProfilePhoto, Organization, menuQuickLinks } = API.UserInformation;
const {
  userProfilePhoto,
  tenantDetails,
  getQuickLinks,
  addQuickLink,
  removeQuickLink,
} = UserInformationAction;

const UserProfiles = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(UserProfilePhoto.get, params);
      if (status === 200) {
        yield put(userProfilePhoto.success(data));
      } else {
        yield put(userProfilePhoto.fail(data));
      }
    } catch (error) {
      yield put(userProfilePhoto.fail(error as any));
    }
  },
};
const Tenant = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(Organization.get, params);
      if (status === 200) {
        yield put(tenantDetails.success(data.value[0]));
      } else {
        yield put(tenantDetails.fail(data.value[0]));
      }
    } catch (error) {
      yield put(tenantDetails.fail(error as any));
    }
  },
};
const QuickLinks = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      const { data, status } = yield call(menuQuickLinks.get, params);
      if (status === 200 && Array.isArray(data)) {
        yield put(getQuickLinks.success(data));
      } else {
        yield put(getQuickLinks.fail(new Error()));
      }
    } catch (error) {
      yield put(getQuickLinks.fail(error as any));
    }
  },
  add: function* (action: any) {
    const params = action.payload?.data;
    $Message.loading(i18n.t("US.COMMON:MESSAGES.SAVING_QUICK_LINK"), 0);
    try {
      const { saveData, saveStatus, getData } = yield call(
        menuQuickLinks.add,
        params
      );
      $Message.destroy();
      if (saveStatus === 200 && !saveData?.isError && Array.isArray(getData)) {
        $MessageBox(
          "success",
          "US.COMMON:MESSAGES.QUICK_LINK_SAVED_SUCCESSFULLY",
          "",
          ""
        );
        yield put(addQuickLink.success(getData));
      } else {
        $MessageBox(
          "error",
          "US.COMMON:MESSAGES.QUICK_LINK_SAVE_FAILED",
          "",
          ""
        );
        yield put(addQuickLink.fail(new Error()));
      }
    } catch (error) {
      $Message.destroy();
      $MessageBox("error", "US.COMMON:MESSAGES.QUICK_LINK_SAVE_FAILED", "", "");
      yield put(addQuickLink.fail(error as any));
    }
  },
  remove: function* (action: any) {
    const params = action.payload?.data;
    $Message.loading(i18n.t("US.COMMON:MESSAGES.REMOVING_QUICK_LINK"), 0);
    try {
      const { saveData, saveStatus, getData } = yield call(
        menuQuickLinks.remove,
        params
      );
      $Message.destroy();
      if (saveStatus === 200 && !saveData?.isError && Array.isArray(getData)) {
        $MessageBox(
          "success",
          "US.COMMON:MESSAGES.QUICK_LINK_REMOVED_SUCCESSFULLY",
          "",
          ""
        );
        yield put(removeQuickLink.success(getData));
      } else {
        $MessageBox(
          "error",
          "US.COMMON:MESSAGES.QUICK_LINK_REMOVE_FAILED",
          "",
          ""
        );
        yield put(removeQuickLink.fail(new Error()));
      }
    } catch (error) {
      $Message.destroy();
      $MessageBox(
        "error",
        "US.COMMON:MESSAGES.QUICK_LINK_REMOVE_FAILED",
        "",
        ""
      );
      yield put(removeQuickLink.fail(error as any));
    }
  },
};

export default [
  takeLatest(UserInformation.GET_USER_PROFILE_PHOTO_START, UserProfiles.get),
  takeLatest(UserInformation.GET_TENANT_DETAILS_START, Tenant.get),
  takeLatest(UserInformation.GET_QUICK_LINKS_START, QuickLinks.get),
  takeLatest(UserInformation.ADD_QUICK_LINK_START, QuickLinks.add),
  takeLatest(UserInformation.REMOVE_QUICK_LINK_START, QuickLinks.remove),
];
