import {
    WorkflowState,
    CaseType
} from "us.collection/constants";
import { appInsights } from "us.helper";

/**
 * Set color code for case
 * @param state {string} - case worflow state
 * @returns {string} color code according to WF state
 */
export const getColorForWorkFlowState = (state: string): string => {
    try {
        switch (state.toLowerCase()) {
            case WorkflowState.ADDRESS_UNKNOWN:
            case WorkflowState.BANCRUPTCY:
            case WorkflowState.CONFORMATION:
            case WorkflowState.CORE_WORKFLOW:
            case WorkflowState.CREDIT_CHECK:
            case WorkflowState.CREDITTOR_BO:
            case WorkflowState.CREDITTOR_DW:
            case WorkflowState.CREDITTOR_REMINDER:
            case WorkflowState.CREDITTOR_RESPOND:
            case WorkflowState.DEBTSETTLEMENT:
            case WorkflowState.ERROR:
            case WorkflowState.HIGH_BALANCE_CAMPAIGN_COMPLETED:
            case WorkflowState.HIGHER_BALANCE_SURVEILLANCE:
            case WorkflowState.INITIALIZE:
            case WorkflowState.INITILIZING:
            case WorkflowState.KEEP_WAITING:
            case WorkflowState.LOW_BALANCE_CAMPAIGN_COMPLETED:
            case WorkflowState.LOW_BALANCE_SURVEILLANCE:
            case WorkflowState.MINOR:
            case WorkflowState.NAGGING:
            case WorkflowState.OBJECTED_CLAIM:
            case WorkflowState.OBJECTION:
            case WorkflowState.OVERVAKET:
            case WorkflowState.PART_PAYMENT:
            case WorkflowState.PAYMENT_AGREEMENT:
            case WorkflowState.QUARANTINE:
            case WorkflowState.REMINDER:
            case WorkflowState.REST_REMINDER:
            case WorkflowState.SALARY_DEDUCTION:
            case WorkflowState.START_SURVEILLANCE:
            case WorkflowState.SUBCASE_OBJECTED_CLAIM:
            case WorkflowState.SURVEILLANCE:
            case WorkflowState.SUV_OBJECTION_STATE:
            case WorkflowState.SUV_PART_PAYMENT:
            case WorkflowState.SUV_UNKNOWN:
            case WorkflowState.TRANSFER_TO_CASE:
            case WorkflowState.UNKNOWN:
            case WorkflowState.WAITING:
                return CaseType.OPEN;
            case WorkflowState.CLOSE:
            case WorkflowState.CLOSED:
            case WorkflowState.SUV_CLOSE_CASE:
                return CaseType.CLOSED;
            case WorkflowState.ENFORCEMENT:
            case WorkflowState.LEGAL:
            case WorkflowState.LEGAL_OVERVAKET:
            case WorkflowState.LEGAL_COLLECTION:
            case WorkflowState.LEGAL_SURVEILLANCE:
                return CaseType.COURT;
            case WorkflowState.FREEZE:
            case WorkflowState.MANUAL:
                return CaseType.FREEZE;
            case WorkflowState.SENTENCE_RECEIVED:
                return CaseType.SENT_TO_COURT;
            default:
                return CaseType.OPEN;
        }
    } catch (error) {
        appInsights.trackException(
            `WorkFlowState color selector Exeception - ${error}`
        );
        return CaseType.OPEN;
    }
};

