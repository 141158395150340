import React, { useContext } from "react";
import { IModulesProps } from "us.common/components/Menu/MegaMenu/interface";
import { ConfigurationContext } from "us.common/ConfigurationContext/ConfigurationProvider";

const Modules: React.FC<IModulesProps> = React.memo(
  ({ selectedModule, onSelectModule }) => {
    const context = useContext(ConfigurationContext);
    return (
      <div className="mt-3 module-menu-wrapper">
        {context?.configurationDetail.map(
          (module: { id: number; displayName: string; icon: string }) => {
            const { id, displayName, icon } = module;
            return (
              <a
                key={id}
                onClick={() => onSelectModule(module)}
                onMouseEnter={() => onSelectModule(module)}
                className={`d-flex align-items-center justify-content-start ${
                  id === selectedModule?.id ? "active" : ""
                }`}
              >
                <span className="module-icon-outer">
                  <img src={icon} />
                </span>
                <span>{displayName}</span>
              </a>
            );
          }
        )}
      </div>
    );
  }
);

export default Modules;
