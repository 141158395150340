import React from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { COLUMNS, FeeBasisHistoryTableColumns } from "../../Constants";
import { ReloadOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { IFeeBasisHistoryTable } from "./interface";
import { IRootState } from "us.collection/interfaces";
import { IFeeBasisDetails } from "us.collection.admin/interfaces";
import { getColumnFilterProps } from "./function";
import {
  DrawerType,
  ManageFeeAction,
} from "us.collection.admin/components/ManageFee/Constants";
import { getSelectedFeeTypeNameForDrawerTitle } from "us.collection.admin/components/ManageFee/Functions";
import { useLocation } from "react-router-dom";

const { $Button, $AmountLabel, $DateLabel, $TableTree, $Popconfirm, $Tooltip } =
  Components;

/**
 * @description - Fee Basis History Table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Table: React.FC<IFeeBasisHistoryTable & PropsFromRedux> = ({
  feeBasisHistory,
  onCallAction,
  feeType,
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");

  /**
   * @description - render rows
   */
  const renderCell = (record: IFeeBasisDetails, dataIndex: string) => {
    const { fromDate, amount, debtorType, modifiedDate, modifiedUser } = record;
    const row = {
      children: (
        <>
          {dataIndex === FeeBasisHistoryTableColumns.MENU && (
            <div className="align-items-center" key={0}>
              <$Tooltip
                placement="top"
                title={t("US.COLLECTION.ADMIN:MANAGE_FEE.REVERT")}
              >
                <$Popconfirm
                  title={
                    t(
                      "US.COLLECTION.ADMIN:MANAGE_FEE.DO_YOU_WANT_TO_RESTORE_THE_"
                    ) +
                    getSelectedFeeTypeNameForDrawerTitle(
                      feeType?.list,
                      Number(typeId)
                    )
                  }
                  onConfirm={() => {
                    onCallAction &&
                      onCallAction(
                        DrawerType.FEE_BASIS_HISTORY,
                        ManageFeeAction.REVERT,
                        record
                      );
                  }}
                  okText={t("COMMON.YES")}
                  cancelText={t("COMMON.NO")}
                  placement="topLeft"
                >
                  <$Button icon={<ReloadOutlined />} size="small" />
                </$Popconfirm>
              </$Tooltip>
            </div>
          )}
          {dataIndex === FeeBasisHistoryTableColumns.DATE && (
            <$DateLabel value={fromDate} />
          )}
          {dataIndex === FeeBasisHistoryTableColumns.AMOUNT && (
            <$AmountLabel value={amount} />
          )}
          {dataIndex === FeeBasisHistoryTableColumns.DEBTOR_TYPE && (
            <span data-testid={`debtorType`}>{debtorType}</span>
          )}
          {dataIndex === FeeBasisHistoryTableColumns.MODIFIED && (
            <>
              {/* {MODIFIED_DATE && ( */}
              <span>
                <$DateLabel value={modifiedDate ?? ""} />
              </span>
              {/* )} */}
              {/* {MODIFIED_USER && ( */}
              <small className="bui-label d-block text-truncate">
                {t("US.COLLECTION.ADMIN:MANAGE_FEE.BY")}
                {" - "}
                <$Tooltip placement="topLeft" title={modifiedUser ?? ""}>
                  {modifiedUser}
                </$Tooltip>
              </small>
              {/* )} */}
            </>
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map(({ key, title }, _index) => {
      //   const columnWidth = getColumnWidth(key);
      let column: any = {
        dataIndex: key,
        key,
        // width: columnWidth,
        title: title && t(`${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) => renderCell(record, key),
        align: key === FeeBasisHistoryTableColumns.AMOUNT ? "right" : "left",
        className:
          key === FeeBasisHistoryTableColumns.AMOUNT
            ? "right-has-sort right-has-filter"
            : "",
        width: key === FeeBasisHistoryTableColumns.MENU ? 45 : "",
      };
      if (key !== FeeBasisHistoryTableColumns.MENU) {
        column = {
          ...column,
          customSorter: (a: any, b: any) => {
            if (
              key === FeeBasisHistoryTableColumns.DATE ||
              key === FeeBasisHistoryTableColumns.MODIFIED
            ) {
              return new Date(a).getTime() - new Date(b).getTime();
            } else if (key === FeeBasisHistoryTableColumns.AMOUNT) {
              return a - b;
            } else {
              return a.localeCompare(b);
            }
          },
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$TableTree
      rowKey="rowKey"
      data-testid="fee-basis-history-table"
      data={feeBasisHistory?.list}
      size="small"
      columns={getColumns()}
      className="mt-3 header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      pagination={{
        defaultPageSize: 100,
      }}
      scroll={{ x: "100%", y: "calc(100vh - 305px)" }}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeBasisHistory, feeType } = manageFee;
  return {
    feeBasisHistory,
    feeType,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
