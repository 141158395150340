import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  getDynamicComponent,
  getElementStatus,
} from "us.common/components/DynamicPopOver/Functions";
import {
  IDynamicPopOver,
  IParameter,
} from "us.common/components/DynamicPopOver/Interface";

import {
  $Button,
  $Divider,
  $Skeleton,
  $DynamicComponent,
  $Row,
} from "us.common/components";

/**
 * @description - Dynamic popOver content.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2919530635/Court+Fee+Handling+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * @param metaData - Dynamic data to render the UI.
 * @param disabled - Wether the content is disabled.
 * @param isSaving - Wether the form is submitting.
 * @param loading - Wether the dynamic popOver content is loading.
 * @param onClose - onClose function for close button.
 * @param onConfirm - onConfirm function called with values after pressing ok button.
 */
const PopOverContent: React.FC<IDynamicPopOver> = ({
  metaData,
  disabled = false,
  isSaving = false,
  loading = false,
  onClose,
  onConfirm,
  onComplete,
}) => {
  const { t } = useTranslation();

  const { activityCode, parameters = [], isCallback } = metaData ?? {};

  const notInitialRender = useRef<any>(false)
  useEffect(() => {
    notInitialRender.current ?
    !isSaving && isCallback && onComplete && onComplete() :
    notInitialRender.current = true
  }, [isSaving]);

  return (
    <Formik
      initialValues={{
        activityCode,
        parameters: Array.isArray(parameters) ? parameters : [],
      }}
      enableReinitialize
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        onConfirm && onConfirm(values);
      }}
    >
      {({ dirty, values, handleSubmit }: any) => (
        <div style={{ minWidth: 300 ,  maxWidth: 550 }}>
          <$Skeleton loading={loading} active paragraph={{ rows: 2 }}>
            <div className="ant-form-vertical">
              <div className="channels-content d-flex flex-column mb-4">
                <$Row gutter={[12, 12]}>
                  {values.parameters.map(
                    (params: IParameter, index: number) => (
                      <$DynamicComponent
                        key={index}
                        name={`parameters[${index}].paramValue`}
                        value={params.paramValue}
                        component={getDynamicComponent(params)}
                        label={params.paramName}
                        size="small"
                        disabled={
                          !params.isEditable ||
                          getElementStatus(
                            parameters,
                            values.parameters,
                            params
                          ).disable
                        }
                        withColumns={true}
                        visible={
                          getElementStatus(
                            parameters,
                            values.parameters,
                            params
                          ).visible
                        }
                      />
                    )
                  )}
                </$Row>
              </div>

              <$Divider />
              <div className="d-flex align-content-center justify-content-end">
                <div>
                  <$Button
                    className="ml-3 mr-2"
                    data-testid="confirm-btn"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isSaving || disabled || !dirty}
                    loading={isSaving}
                  >
                    {t("US.COLLECTION.COMMON:COMMON.OK")}
                  </$Button>
                  <$Button
                    data-testid="cancel-btn"
                    onClick={(e: any) => onClose && onClose(e)}
                  >
                    {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                  </$Button>
                </div>
              </div>
            </div>
          </$Skeleton>
        </div>
      )}
    </Formik>
  );
};

export default PopOverContent;
