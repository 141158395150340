import { IAPIActionBase } from "us.common/interfaces";
import { CreditorSelection } from "us.common/constants";
import { ICreditorSelectionAction } from "us.common/interfaces"
export const creditorSelectionActions: Readonly<IAPIActionBase & ICreditorSelectionAction> = {
  init: {
    get: (data) => ({
      type: CreditorSelection.GET_INIT_CREDITORS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CreditorSelection.GET_INIT_CREDITORS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorSelection.GET_INIT_CREDITORS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditors: {
    get: (data) => ({
      type: CreditorSelection.GET_CREDITORS_BY_GROUP_ID_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    remove: (data) => ({
      type: CreditorSelection.REMOVE_CREDITORS_BY_GROUP_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    select: (data) => ({
      type: CreditorSelection.SELECT_DESELECT_CREDITORS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CreditorSelection.GET_CREDITORS_BY_GROUP_ID_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorSelection.GET_CREDITORS_BY_GROUP_ID_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
