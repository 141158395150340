import * as Yup from "yup";
import { setTotalAmount } from "us.collection/functions";

export default () => {
  return Yup.object().shape({
    mainAmount: Yup.number().test(
      "matchMain",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (mainAmount) {
        mainAmount = typeof mainAmount == "undefined" ? 0 : mainAmount;
        let isValid: boolean = true;
        if (mainAmount) {
          isValid = mainAmount <= this.parent.mainBalance;
        }
        return isValid;
      }
    ),

    collectionFee: Yup.number().test(
      "matchCollection",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (collectionFee) {
        collectionFee = typeof collectionFee == "undefined" ? 0 : collectionFee;
        let isValid: boolean = true;
        if (collectionFee) {
          isValid = collectionFee <= this.parent.collectionBalance;
        }
        return isValid;
      }
    ),

    courtFee: Yup.number().test(
      "matchCourtFee",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (courtFee) {
        courtFee = typeof courtFee == "undefined" ? 0 : courtFee;
        let isValid: boolean = true;
        if (courtFee) {
          isValid = courtFee <= this.parent.courtBalance;
        }
        return isValid;
      }
    ),

    costFee: Yup.number().test(
      "matchcostFee",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (costFee) {
        costFee = typeof costFee == "undefined" ? 0 : costFee;
        let isValid: boolean = true;
        if (costFee) {
          isValid = costFee <= this.parent.costBalance;
        }
        return isValid;
      }
    ),

    otherFee: Yup.number().test(
      "matchotherFee",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (otherFee) {
        otherFee = typeof otherFee == "undefined" ? 0 : otherFee;
        let isValid: boolean = true;
        if (otherFee) {
          isValid = otherFee <= this.parent.otherBalance;
        }
        return isValid;
      }
    ),

    interestMainAmount: Yup.number().test(
      "matchInterestMainAmount",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (interestMainAmount) {
        interestMainAmount =
          typeof interestMainAmount == "undefined" ? 0 : interestMainAmount;

        let isValid: boolean = true;
        if (interestMainAmount) {
          isValid = interestMainAmount <= this.parent.interestMainBalance;
        }
        return isValid;
      }
    ),

    interestCostAmount: Yup.number().test(
      "matchInterestCostAmount",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
      function (interestCostAmount) {
        interestCostAmount =
          typeof interestCostAmount == "undefined" ? 0 : interestCostAmount;
        let isValid: boolean = true;
        if (interestCostAmount) {
          isValid = interestCostAmount <= this.parent.interestCostBalance;
        }
        return isValid;
      }
    ),
    totalAmount: Yup.mixed().test("totalValue", "", function (totalAmount) {
      const { path, parent, createError } = this;
      const sum = setTotalAmount(parent);
      if (sum < parent.minCaseBalance) {
        return createError({
          path,
          message: `US.COLLECTION.VALIDATIONS:NOTIFICATION.AGREEMENT_BALANCE_SHOULD_BE_GREATER_THAN`,
        });
      }

      return true;
    }),
    // isInterestError: Yup.boolean(),
    // interestDate: Yup.date().when("isInterestError", {
    //   is: true,
    //   then: Yup.date().test(
    //     "interestDate",
    //     `US.COLLECTION.VALIDATIONS:NOTIFICATION.DATE_SHOULD_BE_GREATER_THAN_DUE_DATE`,
    //     (val: any) => {
	// 		debugger
    //       return false;
    //     }
    //   ),
   // }),
  });
};
