import { parse } from "query-string";
import { ActivitySummaryFilters } from "us.collection.admin/constants";

/**
 * @function
 * @description - return entity type from url params and data source
 * @param activitySummaryList {Array<any>} activity summary list data source
 * @param urlSearchParams {string} url params in search
 * @returns {string} return entity type
 */
export const getSelectedEntityType = (
  activitySummaryList: Array<any>,
  urlSearchParams: string
): string => {
  try {
    const currentFilters = parse(urlSearchParams);
    const entityTypeInUrl = currentFilters[ActivitySummaryFilters.ENTITY_TYPE];
    const entityType =
      (entityTypeInUrl &&
        activitySummaryList.find(
          ({ entitytype }: { entitytype: string }) =>
            entityTypeInUrl == entitytype
        )?.["entitytype"]) ??
      activitySummaryList[0]["entitytype"];
    return entityType;
  } catch (error) {
    return "";
  }
};
