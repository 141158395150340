import _ from 'lodash';
import {
  IAuthorityLevel,
  IAzureADUser,
  IUser,
  IUserDetail,
  IUserRole,
  IOptionItem,
} from 'us.common/interfaces';
import { userDetail } from 'us.common/reducers/UserManagement/State';

/**
 * @function
 * @description set Roles Detail
 * @param {Array<IUserRole>} roles
 * @param {Array<IUserRole>} userRoles
 * @returns {Array<IUserRole>}
 */
export const setRolesDetail = (
  roles: Array<IUserRole>,
  userRoles: Array<IUserRole>
): Array<IUserRole> => {
  try {
    return _.chain([...userRoles, ...roles])
      .sortBy('name')
      .uniqBy('roleId')
      .value();
  } catch (error) {
    return roles;
  }
};

/**
 * @function
 * @description get ADUser Initial Values
 * @param {IAzureADUser} aDUser
 * @returns
 */
export const getADUserInitialValues = (
  aDUser: IAzureADUser
): {
  firstName: string;
  lastName: string;
  telephoneNo: string;
} => {
  try {
    const { givenName, surname, businessPhones } = aDUser;
    return {
      firstName: givenName,
      lastName: surname,
      telephoneNo: businessPhones[0],
    };
  } catch (error) {
    return {
      firstName: '',
      lastName: '',
      telephoneNo: '',
    };
  }
};

/**
 * @function
 * @description get AccessAuthority LevelId
 * @param {string | number} accessAuthorityLevelId
 * @param {Array<IAuthorityLevel>} authorityLevels
 * @returns
 */
export const getAccessAuthorityLevelId = (
  accessAuthorityLevel: string,
  authorityLevels: Array<IAuthorityLevel>
): number | string => {
  try {
    return (
      authorityLevels.find(
        ({ name }: IAuthorityLevel) => accessAuthorityLevel == name
      )?.levelId ?? ''
    );
  } catch (error) {
    return '';
  }
};
/**
 * @function
 * @description get AccessAuthority LevelId
 * @param {string | number} accessAuthorityLevelId
 * @param {Array<IAuthorityLevel>} authorityLevels
 * @returns
 */
export const getAccessAuthorityLevel = (
  accessAuthorityLevelId: number,
  authorityLevels: Array<IAuthorityLevel>
): string => {
  try {
    return (
      authorityLevels.find(
        ({ levelId }: IAuthorityLevel) => accessAuthorityLevelId == levelId
      )?.name ?? ''
    );
  } catch (error) {
    return '';
  }
};
/**
 * @function
 * @description return the accending sort option List
 * @param values
 */

export const sortedOptionsData = (optionsData: any): Array<IOptionItem> => {
  try {
    return _.orderBy(optionsData, [(user) => user.label.toLowerCase()], 'asc');
  } catch (error) {
    return optionsData;
  }
};
