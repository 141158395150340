import { ManageFollowup } from "us.collection.admin/constants/Actions";
import { initialState } from "./State";
import { DefaultCategory } from "us.collection.admin/constants";
import { IFollowup } from "us.collection.admin/interfaces";
import {
  generateFollowUpResultColumns,
  generateArrayDataFromResponse,
  generateActivityListTable,
} from "us.collection.admin/functions";

const {
  GET_FOLLOWUP_CATEGORIES_START,
  GET_FOLLOWUP_CATEGORIES_SUCCESS,
  GET_FOLLOWUP_CATEGORIES_FAIL,
  GET_FOLLOWUP_LIST_START,
  GET_FOLLOWUP_LIST_SUCCESS,
  GET_FOLLOWUP_LIST_FAIL,
  REMOVE_FOLLOWUP_SUCCESS,
  GET_FOLLOWUP_RESULT_START,
  GET_FOLLOWUP_RESULT_FAIL,
  GET_FOLLOWUP_RESULT_SUCCESS,
  RESET_FOLLOWUP_RESULT,
  GET_FOLLOW_UP_ENTITY_TYPES,
  GET_FOLLOW_UP_ENTITY_TYPES_SUCCESS,
  GET_FOLLOW_UP_ENTITY_TYPES_FAIL,
  GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES,
  GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_SUCCESS,
  GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_FAIL,
  EXECUTE_CUSTOM_QUERY,
  EXECUTE_CUSTOM_QUERY_SUCCESS,
  EXECUTE_CUSTOM_QUERY_FAIL,
  GET_FOLLOW_UP_ACTIVITIES,
  GET_FOLLOW_UP_ACTIVITIES_SUCCESS,
  GET_FOLLOW_UP_ACTIVITIES_FAIL,
  SAVE_FOLLOW_UP_CATEGORY,
  SAVE_FOLLOW_UP_CATEGORY_SUCCESS,
  SAVE_FOLLOW_UP_CATEGORY_FAIL,
  GET_FOLLOW_UP_USERS,
  GET_FOLLOW_UP_USERS_SUCCESS,
  GET_FOLLOW_UP_USERS_FAIL,
  GET_FOLLOW_UP_ROLES,
  GET_FOLLOW_UP_ROLES_SUCCESS,
  GET_FOLLOW_UP_ROLES_FAIL,
  MANAGE_CUSTOM_QUERY_DRAWER,
  GET_FOLLOWUP_DETAILS,
  GET_FOLLOWUP_DETAILS_SUCCESS,
  GET_FOLLOWUP_DETAILS_FAIL,
  RESET_FOLLOWUP_DETAILS,
} = ManageFollowup;
function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_FOLLOWUP_CATEGORIES_START:
      return Object.assign({}, state, {
        categories: {
          data: state.categories.data,
          isFetching: true,
        },
      });
    case GET_FOLLOWUP_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: {
          data: [
            {
              categoryID: DefaultCategory.categoryID,
              categoryName: DefaultCategory.categoryName,
            },
            ...payload.data,
          ],
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_CATEGORIES_FAIL:
      return Object.assign({}, state, {
        categories: {
          data: [],
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_LIST_START:
      return Object.assign({}, state, {
        list: {
          data: [],
          isFetching: true,
        },
      });
    case GET_FOLLOWUP_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_LIST_FAIL:
      return Object.assign({}, state, {
        list: {
          data: [],
          isFetching: false,
        },
      });
    case REMOVE_FOLLOWUP_SUCCESS:
      return Object.assign({}, state, {
        list: {
          data: state.list.data.filter(
            (followup: IFollowup) =>
              followup.followupId != payload.data.followupId
          ),
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_RESULT_START:
      return Object.assign({}, state, {
        followupResults: {
          data: initialState.followupResults.data,
          result: [],
          columns: [],
          isFetching: true,
        },
      });
    case GET_FOLLOWUP_RESULT_SUCCESS:
      const { data, result, column } = payload.data;
      return Object.assign({}, state, {
        followupResults: {
          data,
          result,
          columns: column,
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_RESULT_FAIL:
      return Object.assign({}, state, {
        followupResults: {
          data: initialState.followupResults.data,
          result: [],
          columns: [],
          isFetching: false,
        },
      });
    case RESET_FOLLOWUP_RESULT:
      return Object.assign({}, state, {
        followupResults: {
          data: initialState.followupResults.data,
          result: [],
          columns: [],
          isFetching: false,
        },
      });
    case GET_FOLLOW_UP_ENTITY_TYPES:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          entities: {
            data: [],
            isFetching: true,
          },
        },
      });
    case GET_FOLLOW_UP_ENTITY_TYPES_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          entities: {
            data: payload?.data,
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_ENTITY_TYPES_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          entities: {
            data: [],
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          properties: {
            data: initialState.customQuery.properties.data,
            isFetching: true,
          },
        },
      });
    case GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          properties: {
            data: payload?.data,
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          properties: {
            data: initialState.customQuery.properties.data,
            isFetching: false,
          },
        },
      });
    case MANAGE_CUSTOM_QUERY_DRAWER:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          drawer: {
            ...payload?.data,
          },
        },
      });
    case EXECUTE_CUSTOM_QUERY:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          result: {
            data: initialState.customQuery.result.data,
            dataSource: [],
            columns: [],
            isFetching: true,
          },
        },
      });
    case EXECUTE_CUSTOM_QUERY_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          result: {
            data: payload?.data?.response,
            dataSource: generateArrayDataFromResponse(
              payload?.data?.response?.followupData
            ),
            columns: generateFollowUpResultColumns(
              payload?.data?.response,
              payload?.data?.entityType
            ),
            isFetching: false,
          },
        },
      });
    case EXECUTE_CUSTOM_QUERY_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          result: {
            data: initialState.customQuery.result.data,
            dataSource: [],
            columns: [],
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_ACTIVITIES:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          activities: {
            data: [],
            isFetching: true,
          },
        },
      });
    case GET_FOLLOW_UP_ACTIVITIES_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          activities: {
            data: payload?.data,
            isFetching: false,
          },
        },
        followUpDetails: {
          ...state.followUpDetails,
          data: {
            ...state.followUpDetails.data,
            activitiesList: generateActivityListTable(
              state.followUpDetails.data?.activitiesList,
              payload?.data
            ),
          },
        },
      });
    case GET_FOLLOW_UP_ACTIVITIES_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          activities: {
            data: [],
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_USERS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          users: {
            data: [],
            isFetching: true,
          },
        },
      });
    case GET_FOLLOW_UP_USERS_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          users: {
            data: payload?.data,
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_USERS_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          users: {
            data: [],
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_ROLES:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          roles: {
            data: [],
            isFetching: true,
          },
        },
      });
    case GET_FOLLOW_UP_ROLES_SUCCESS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          roles: {
            data: payload?.data,
            isFetching: false,
          },
        },
      });
    case GET_FOLLOW_UP_ROLES_FAIL:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          roles: {
            data: [],
            isFetching: false,
          },
        },
      });
    case GET_FOLLOWUP_DETAILS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          isNewCustomQuery: false,
        },
        followUpDetails: {
          data: initialState.followUpDetails.data,
          isFetching: true,
        },
      });
    case GET_FOLLOWUP_DETAILS_SUCCESS:
      const { followupCategoryId, setAsFollowp, assign, followupactivities } =
        payload?.data ?? {};
      return Object.assign({}, state, {
        followUpDetails: {
          data: {
            ...initialState.followUpDetails.data,
            ...payload?.data,
            followupCategory: followupCategoryId,
            isCaseHandler: setAsFollowp,
            assignToList: generateArrayDataFromResponse(assign),
            activitiesList: generateArrayDataFromResponse(followupactivities),
          },
          isFetching: false,
        },
      });
    case GET_FOLLOWUP_DETAILS_FAIL:
      return Object.assign({}, state, {
        followUpDetails: {
          data: initialState.followUpDetails.data,
          isFetching: false,
        },
      });
    case RESET_FOLLOWUP_DETAILS:
      return Object.assign({}, state, {
        customQuery: {
          ...state.customQuery,
          isNewCustomQuery: true,
        },
        followUpDetails: {
          data: initialState.followUpDetails.data,
          isFetching: false,
        },
      });
    default:
      return state;
  }
};
