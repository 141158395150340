import { NonProductionDayType } from "us.collection.admin/constants";

export const NonProductionDayTabs = [
  {
    name: "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_BANKING_DAYS",
    description:
      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DAYS_THAT_BANKING_FILES_DOES_NOT_PRODUCE",
    type: NonProductionDayType.Non_Banking,
  },
  {
    name: "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_SMS_DAYS",
    description:
      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DAYS_AND_TIME_SLOTS_THAT_SMS_DOES_NOT_SENT",
    type: NonProductionDayType.Non_SMS,
  },
  {
    name: "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_PRINTING_DAYS",
    description:
      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DAYS_AND_TIME_SLOTS_THAT_DOCUMENT_DOES_NOT_PRINT",
    type: NonProductionDayType.Non_Printing,
  },
  {
    name: "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_WORKFLOW_DAYS",
    description:
      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DAYS_THAT_WORKFLOW_IS_STOPPED",
    type: NonProductionDayType.Non_Workflow,
  },
  {
    name: "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_EMAIL_DAYS",
    description:
      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DAYS_AND_TIME_SLOTS_THAT_EMAIL_DOES_NOT_SENT",
    type: NonProductionDayType.Non_Email,
  },
];
