export default {
    groupBy: {
        label: 'US.COLLECTION.COMMON:COMMON.COLLECTION',
        url: '#'
    },
    subs: [
        {
            url: '/',
            label: 'US.COLLECTION.COMMON:COMMON.HOME'
        },
        {
            url: '/task-list',
            label: 'US.COLLECTION.COMMON:COMMON.TASKLIST'
        },
        {
            url: '/case/10001',
            label: 'US.COLLECTION.COMMON:COMMON.CASEDETAIL'
        }
    ]
}