import { initialState } from 'us.common/reducers/EntityDetails/State';
import { Entity } from 'us.common/constants/Entity';

const {
	GET_ENTITY_DETAIL_START,
	GET_ENTITY_DETAIL_SUCCESS,
	GET_POSTAL_AREAS_SUCCESS,
	GET_POSTAL_AREAS_FAIL,
	GET_MUNICIPALS_SUCCESS,
	GET_MUNICIPALS_FAIL,
	GET_POSTAL_AREAS,
	SELECTED_POSTAL_CODE_DETAIL,
	SELECTED_MUNICIPALITY_DETAIL,
	POSTAL_CODE_FORM_DATA,
	CHANGE_POSTAL_AREA_DRAWER_NAME,
	CLEAR_POSTAL_CODE_DATA,
	GET_POSTAL_CODE_AVAILABILITY_START,
	GET_POSTAL_CODE_AVAILABILITY_SUCCESS,
	GET_POSTAL_CODE_AVAILABILITY_FAIL,
	ADD_POSTAL_AREAS_SUCCESS,
	ADD_POSTAL_AREAS_FAIL,
	SET_SAVE_STATUS,
	UPDATE_ENTITY_DETAIL_SUCCESS,
	CLEAR_EXISITING_CODE
} = Entity;

export default (state: any = initialState, { payload, type }: any) => {
	let newState = state;

	switch (type) {
		case GET_ENTITY_DETAIL_START:
			newState = {
				...state,
				isFetching: true,
			};
			break;
		case GET_ENTITY_DETAIL_SUCCESS:
			let data =
				payload?.data.statusCode === 400
					? initialState.entity
					: payload?.data;
			newState = {
				...state,
				entity: data,
				isFetching: false,
			};
			break;

		case GET_POSTAL_AREAS_SUCCESS:
			newState = {
				...state,
				postalAreas: payload?.data.hasOwnProperty('errors')
					? []
					: payload?.data,
				isFetching: false,
			};
			break;
		case GET_POSTAL_AREAS_FAIL:
			newState = {
				...state,
				postalAreas: [],
				isFetching: false,
			};
			break;
		case GET_MUNICIPALS_SUCCESS:
			newState = {
				...state,
				municiples: payload?.data,
				isFetching: false,
			};
			break;
		case GET_MUNICIPALS_FAIL:
			newState = {
				...state,
				municiples: [],
				isFetching: false,
			};
			break;

		case SELECTED_POSTAL_CODE_DETAIL:
			newState = {
				...state,
				selectedPostalAreaDetail: payload?.data,
			};
			break;
		case SELECTED_MUNICIPALITY_DETAIL:
			newState = {
				...state,
				selectedMunicipality: payload?.data,
			};
			break;
		case POSTAL_CODE_FORM_DATA:
			newState = {
				...state,
				exisitingCode: payload?.data,
			};
			break;
		case CHANGE_POSTAL_AREA_DRAWER_NAME:
			newState = {
				...state,
				drawerName: payload?.data,
			};
			break;
		case CLEAR_POSTAL_CODE_DATA:
			newState = {
				...state,
				postalAreas: [],
				municiples: [],
				isFetching: false,
			};
			break;
		case GET_POSTAL_CODE_AVAILABILITY_SUCCESS:
			return Object.assign({}, state, {
				isExisitingCode: true
			});
		case GET_POSTAL_CODE_AVAILABILITY_FAIL:
			return Object.assign({}, state, {
				isExisitingCode: false
			});
		case ADD_POSTAL_AREAS_SUCCESS:
			return Object.assign({}, state, {
				isSaveSuccess: true
			});
		case SET_SAVE_STATUS:
			return Object.assign({}, state, {
				isSaveSuccess: false
			});
		case UPDATE_ENTITY_DETAIL_SUCCESS:
			return Object.assign({}, state, {
				entity: payload.data,
				isFetching: false,
			});
		case CLEAR_EXISITING_CODE:
			return Object.assign({}, state, {
				isExisitingCode: false
			});
	}
	return newState;
};
