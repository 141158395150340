import React from 'react';
import { useTranslation } from 'react-i18next';
import { $Tooltip, $Menu, $Dropdown } from 'us.common/components';
import {
	DownOutlined,
	SortAscendingOutlined,
	SortDescendingOutlined,
} from 'us.icons';
import { getMatchingTabInfo, getSortMenuPerTab } from '../Functions';
import {
	ISortSearch,
	CombinedKeyType,
	SortOrderType,
} from 'us.common/components/MainSearch/Interfaces';

/**
 * @description - Sort management component.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const SortSearch: React.FC<ISortSearch> = (props) => {
	const { tabInfo, changeSortOrderEvent } = props;
	const { selectedTab } = tabInfo;
	const { t } = useTranslation();

	const currentTabInfo = getMatchingTabInfo(tabInfo);

	const SortMenu = (
		<$Menu
			onClick={({ key }) => {
				changeSortOrderEvent(
					key as keyof CombinedKeyType
				);
			}}>
			{getSortMenuPerTab(selectedTab).map((menuKey) => (
				<$Menu.Item key={menuKey.ObjectProp}>
					<a
						target='_blank'
						rel='noopener noreferrer'>
						{t(
							`US.COLLECTION.COMMON:COMMON.${menuKey.displayKey}`
						)}
					</a>
				</$Menu.Item>
			))}
		</$Menu>
	);

	return (
		<div className='d-flex justify-content-end'>
			<div>
				{t('US.COLLECTION.COMMON:COMMON.SORT_BY')} :
				<$Dropdown
					overlay={SortMenu}
					trigger={['click']}
					data-testid='searchResults-sortMenuDropDown'>
					<a
						className='ant-dropdown-link'
						onClick={(
							e: React.MouseEvent<
								HTMLAnchorElement,
								MouseEvent
							>
						) => e.preventDefault()}
						data-testid='searchResults-sortMenuDropDownItem'>
						{` ${t(
							`US.COLLECTION.COMMON:COMMON.${
								getSortMenuPerTab(
									selectedTab
								).find(
									(
										menuItem
									) =>
										menuItem.ObjectProp ===
										currentTabInfo.sortKey
								)?.displayKey
							}`
						)} `}
						<DownOutlined />
					</a>
				</$Dropdown>
				<$Tooltip
					title={
						currentTabInfo.sortOrder ===
						SortOrderType.ASC
							? t(
									'US.COLLECTION.COMMON:COMMON.CLICK_HERE_TO_MAKE_DESCENDING'
							  )
							: t(
									'US.COLLECTION.COMMON:COMMON.CLICK_HERE_TO_MAKE_ASCENDING'
							  )
					}>
					<a
						className='pl-3'
						data-testid='searchResults-sortDescendAndAscend'>
						{currentTabInfo.sortOrder ===
						SortOrderType.ASC ? (
							<SortDescendingOutlined
								onClick={(e) =>
									changeSortOrderEvent(
										currentTabInfo.sortKey,
										SortOrderType.DESC
									)
								}
							/>
						) : (
							<SortAscendingOutlined
								onClick={(e) =>
									changeSortOrderEvent(
										currentTabInfo.sortKey,
										SortOrderType.ASC
									)
								}
							/>
						)}
					</a>
				</$Tooltip>
			</div>
		</div>
	);
};
