import { MunicipalityColumns } from "us.collection.admin/components/Municipalities/Constants"

export const INITIAL_ITEM_MENU = {
  visible: false,
  municipalityId: -1,
  visibleTooltip: false,
};

export const COLUMNS = [
  {
    key: MunicipalityColumns.MENU,
    title: "",
    width: "40px",
  },
  {
    key: MunicipalityColumns.MUNICIPALITY_CODE,
    title: "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.MUNICIPALITY_CODE",
    width: "200px",
  },
  {
    key: MunicipalityColumns.MUNICIPALITY_NAME,
    title: "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.MUNICIPALITY_NAME",
    width: "180px",
  },
  {
    key: MunicipalityColumns.COUNTRY_NAME,
    title: "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.COUNTRY",
    width: "180px",
  },
  {
    key: MunicipalityColumns.COUNTRY_CODE,
    title: "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.STANDARD_CODE",
    width: "180px",
  },
];