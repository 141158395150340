import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { IDetails } from "us.collection.admin/components/ManageFee/Interfaces";
import {
  ManageFeeAction,
  INITIAL_DRAWER_DATA,
} from "us.collection.admin/components/ManageFee/Constants";
import { useLocation } from "react-router-dom";
import { FeeTypeValidationSchema } from "us.collection.admin/components/ManageFee/Validations";
import { AddNewFeeType } from "us.collection.admin/components/ManageFee/Repository";
import * as Actions from "us.collection.admin/actions";
import { checkFeeTypeDuplicate } from "us.collection.admin/components/ManageFee/Functions";
import "../../Home.scss";

const { $Button, $Form, $TextArea, $Popconfirm, $AsyncInput } =
  Common.Components;

/**
 * @description - Fee Type Detail view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Details: React.FC<PropsFromRedux & IDetails> = (props) => {
  const { t } = useTranslation();
  const {
    actionType,
    addNewFeeType,
    changeDrawerDetails,
    savePayload,
    feeType,
  } = props;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");
  const feeId = urlParams.get("feeId");

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   * @param {any} actions - Form Actions
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const typePayload = AddNewFeeType.call({ ...values, typeId, feeId });
    if (actionType === ManageFeeAction.ADD) {
      addNewFeeType && addNewFeeType(typePayload);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validationSchema={FeeTypeValidationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical" autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <div className="mb-3">
                <$AsyncInput
                  name="feeTypeName"
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.FEE_TYPE")}
                  className="w-100"
                  required
                  dataTestid="async-input-remitJournalPrifix"
                  placeholder={t("US.COLLECTION.ADMIN:MANAGE_FEE.FEE_TYPE")}
                  size="small"
                  autoFocus={false}
                  isValid={checkFeeTypeDuplicate(
                    feeType?.list,
                    values.feeTypeName
                  )}
                  asyncError={t(
                    "US.COLLECTION.VALIDATIONS:INVALID.FEE_TYPE_ALREADY_EXISTS"
                  )}
                />
              </div>
              <div>
                <$TextArea
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.DESCRIPTION")}
                  name="description"
                  autoSize={{ minRows: 8, maxRows: 8 }}
                  required
                />
              </div>
            </div>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  type="primary"
                  onClick={handleSubmit}
                  className="mr-2"
                  data-testid="save-btn"
                  loading={savePayload?.isSaving}
                  disabled={
                    !checkFeeTypeDuplicate(feeType?.list, values.feeTypeName) ||
                    Object.keys(values).length === 0 ||
                    !restProps.isValid
                  }
                >
                  {t("US.COMMON:COMMON.SAVE")}
                </$Button>
                {Object.keys(values).length !== 0 && (
                  <$Popconfirm
                    title={t(
                      "US.COMMON:COMMON.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                    )}
                    placement="topLeft"
                    onConfirm={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                    okText={t("US.COMMON:COMMON.YES")}
                    cancelText={t("US.COMMON:COMMON.NO")}
                  >
                    <$Button>{t("US.COMMON:COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {Object.keys(values).length === 0 && (
                  <$Button
                    onClick={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                  >
                    {t("US.COMMON:COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, manageFee } = state;
  const { currentDateFormat, currentCurrency, currentLanguage } = common;
  const { feeBasis, feeRange, savePayload, feeType } = manageFee;
  return {
    currentDateFormat,
    currentCurrency,
    currentLanguage,
    feeBasis,
    feeRange,
    feeType,
    savePayload,
  };
};
const { newFeeType, feeTypeActions } = Actions.manageFee;
const { openDrawer } = feeTypeActions;

const mapDispatchToProps = {
  addNewFeeType: newFeeType.save,
  changeDrawerDetails: openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Details);
