import {
  CaseSubCriterias,
  COLEN,
  CreditorSubCriterias,
  DebtorSubCriterias,
  InvoiceSubCriterias,
  MainCriterias,
} from "us.common/constants";
import { ILabeledValue } from "us.common/interfaces";
import { IOptions } from "us.common/components/SearchBar/Interfaces";

/**
 * @function
 * @description Called in the first rendering of the searh autocomplete options
 * @param selectedCriteria the main criteria selected, which is undefined
 * @param optionalCriterias it is - MainCriteriasEnum in this case
 * @param inputValue the user entered value
 * @returns the list of options for autocomplete (not including or handling the history)
 */
export const getInitialDropDownOptionValues = <
  T extends ILabeledValue<
    | MainCriterias
    | InvoiceSubCriterias
    | CaseSubCriterias
    | CreditorSubCriterias
    | DebtorSubCriterias
  >
>(
  selectedCriteria: ILabeledValue<MainCriterias> | undefined,
  optionalCriterias: T[],
  inputValue: string
): IOptions[] => {
  try {
    return (optionalCriterias as any).map((option: T) => {
      return {
        label: `${
          selectedCriteria ? selectedCriteria.label + COLEN + " " : ""
        }${option.label + COLEN} ${inputValue}`,
        value: `${
          selectedCriteria ? selectedCriteria.value + COLEN + " " : ""
        }${option.value + COLEN} ${inputValue}`,
        key: option.value + COLEN,
      };
    });
  } catch (e) {
    return [];
  }
};
