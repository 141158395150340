import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  $Skeleton,
  $Button,
  $Search,
  $TableTree
} from "us.common/components/antd";
import { PostalAreaDetails } from "./Types";
import { useTranslation } from "react-i18next";
import * as Action from "us.common/actions";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import "./Style.scss";

interface ISelectMunicipal {
  drawerContentChangeClick: (data: PostalAreaDetails) => void;
  onCancelClick: () => void;
  currentInfo?: any;
  currentStep: any;
  step?: number;
  updateFormData: (data: any) => void;
  AddPostalFormData: any;
  isLoading: boolean;
  updateDrawerName: (data: string) => void;
  exisitingCodes: any;
  clearPostalData: any;
  onRowClickMuniciples?: (data: any) => void;
}

interface IBase extends ISelectMunicipal {
  getMunicipals: (data: any) => void;
  municiples: any;
  countryCode?: string;
}

interface ISortOrder {
  columnKey: string;
  order: any;
}

const SelectMunicipal: React.FC<IBase> = (props) => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState<any>();
  const [isOKDissabled, setIsOKDissabled] = useState<boolean>(true);
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  const onSelectChange = (selectedRowKeys: any, selectedRows: any[]) => {
    // setSelectedRowKey(Number.parseInt(selectedRowKeys[0].toString()));
    setSelectedData(selectedRows);
    setIsOKDissabled(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };
  const {
    updateFormData,
    AddPostalFormData,
    getMunicipals,
    municiples,
    isLoading,
    updateDrawerName,
    exisitingCodes,
    clearPostalData,
    onCancelClick,
    countryCode,
    onRowClickMuniciples,
  } = props;

  useEffect(() => {
    updateDrawerName(
      t("US.COLLECTION.COMMON:ENTITYSELECTION.SELECTMUNICIPALITYNAME")
    );
    clearPostalData({});
  }, []);

  const columns: any = [
    {
      title: t('US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITY_CODE'),
      dataIndex: 'municipalityCode',
      key: 'municipalityCode',
      width: 200,
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITY_NAME'),
      dataIndex: 'municipalityName',
      key: 'municipalityName',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t('US.COLLECTION.COMMON:ENTITYSELECTION.COUNTRY'),
      dataIndex: 'countryName',
      key: 'countryName',
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  const rowSelection = {
    onChange: onSelectChange,
    hideDefaultSelections: true,
  };
  const rowDoubleClickHandler = (dataRow: any, index: any) => {
    props.onRowClickMuniciples && props.onRowClickMuniciples(dataRow);
    onCancelClick();
    updateFormData({
      ...exisitingCodes,
      isExisitingCode: false,
      municipalityName: dataRow.municipalityName,
      municipalityCode: dataRow.municipalityCode,
    });
    setIsOKDissabled(true);
    updateDrawerName(t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA"));
  };

  const setValue = () => {
    updateFormData({
      ...exisitingCodes,
      isExisitingCode: false,
      municipalityName: selectedData[0]?.municipalityName,
      municipalityCode: selectedData[0]?.municipalityCode,
    });
    onRowClickMuniciples &&
      onRowClickMuniciples({
        municipalityName: selectedData[0]?.municipalityName,
        municipalityCode: selectedData[0]?.municipalityCode,
      });
    updateDrawerName(t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA"));
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  return (
    <div className="select-municipal">
      <$Search
        name="code"
        enterButton
        size="small"
        placeholder="Search"
        className="mb-3"
        onSearch={(e: any) =>
          getMunicipals({
            countryCode: countryCode ?? "NO",
            searchText: e === "" ? "" : e,
          })
        }
        onPressEnter={(e: any) =>
          getMunicipals({
            countryCode: countryCode ?? "NO",
            searchText: e.target.value === "" ? " " : e.target.value,
          })
        }
        allowClear={true}
      />
      <$Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
        <$TableTree
          onChange={handleTableChange}
          rowSelection={{ type: 'radio', ...rowSelection }}
          rowKey={(record) => record.municipalityCode}
          data={municiples}
          size="small"
          className=""
          onSort={handleSort}
          onFilter={handleFilter}
          filterOnType={true}
          resetOnSourceChange={true}
          bordered
          pagination={{ defaultPageSize: 20 }}
          columns={columns}
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {
                rowDoubleClickHandler(record, rowIndex);
              },
            };
          }}
          firstColSkipFilterProps={-1}
          scroll={{ x: "100%", y: "calc(100vh - 370px)" }}
        />
      </$Skeleton>
      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <$Button
          className="mr-2"
          type="primary"
          disabled={isOKDissabled}
          onClick={() => {
            props.onCancelClick();
            setValue();
          }}
        >
          {t("US.COMMON:COMMON.OK")}
        </$Button>
        <$Button
          onClick={() => {
            props.onCancelClick();
            updateFormData({
              ...exisitingCodes,
              isExisitingCode: false,
            });
            updateDrawerName(
              t("US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA")
            );
          }}
        >
          {t("US.COMMON:COMMON.CANCEL")}
        </$Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    municiples: state.entityDetails.municiples,
    AddPostalFormData: state.entityDetails.AddPostalFormData,
    isLoading: state.entity.isLoading,
    exisitingCodes: state.entityDetails.exisitingCode,
  };
};

const mapDispatchToProps = {
  getMunicipals: Action.entity.getMunicipals,
  updateFormData: Action.entity.PostalCodeFormData,
  updateDrawerName: Action.entity.ChangePostalDrawerTitle,
  clearPostalData: Action.entity.clearPostalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMunicipal);
