import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import {
  COLUMNS,
  ColumnSearchType,
  CourtInfoTableColumn,
  Align,
  CourtInfoAction,
} from "us.collection.admin/components/Court/Constants";
import { MoreOutlined, FilterOutlined } from "us.icons";
import { ItemMenu } from "./Components";
import {
  ICourtInfoAction,
  ICourtInfo,
} from "us.collection.admin/components/Court/Interfaces";
import {
  getColumnType,
  getColumnWidth,
} from "us.collection.admin/components/Court/Functions";
import { ICourtInfoTable } from "./interface";
import { useField } from "formik";

const { $Button, $Table, $Popover, $Input, $Tooltip } = Components;

/**
 * @description - Court info table component to show all the courts
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2861694980/Manage+Court+Info+UI+-+View
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/02/2022
 */

const CourtInfoTable: React.FC<ICourtInfoTable> = (props) => {
  const {
    onCallAction,
    tableData,
    tableInfo,
    columnFilters,
    onTableChange,
    onChangeFilters,
    onFilter,
  } = props;
  const { t } = useTranslation();

  const inputSearchRef = React.useRef<any>();
  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    id: number;
    visibleTooltip: boolean;
  }>({ visible: false, id: -1, visibleTooltip: false });

  const { filters, sorter } = tableInfo;

  // auto close invoice item menu after 5 seconds
  const setAutoClose = (id: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          id,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @description Get column search input field current value by key
   * @param key - input field name
   * @returns value - Current value of the input field
   */
  const getColumnInputFieldValue = (key: string) => {
    if (columnFilters && columnFilters.length > 0) {
      const index: any = columnFilters.findIndex(
        (item: any) => item.dataIndex == key
      );
      if (index > -1) {
        return columnFilters[index].value;
      }
    } else {
      return "";
    }
  };

  /**
   * @description - Generate table columns search filters
   * @param - index of the table column
   * @returns - Search props elements
   */
  const getColumnSearchProps = (dataIndex: string, title: string) => {
    const type = getColumnType(dataIndex);
    const [, , helpers] = useField(dataIndex);
    return {
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          window.setTimeout(function () {
            typeof inputSearchRef?.current !== "undefined" &&
              inputSearchRef.current.focus();
            document.querySelector(`[name=${dataIndex}]` as any)?.focus();
          }, 200);
        }
      },
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          {(type === ColumnSearchType.STRING ||
            type === ColumnSearchType.NUMBER) && (
            <$Input
              name={dataIndex}
              placeholder={`${t("US.COLLECTION.COMMON:COMMON.SEARCH")} ${t(
                `US.COLLECTION.ECONOMY:PLACE_HOLDERS.${title}`
              )}`}
              onChange={(e: any) => {
                helpers?.setValue(e.target.value);
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                onChangeFilters(dataIndex, e.target.value);
                onFilter(dataIndex, e.target.value, type);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              autoComplete="off"
              ref={inputSearchRef}
            />
          )}
          <$Button
            id="btnReset"
            onClick={() => {
              helpers?.setValue(undefined);
              clearFilters();
              onChangeFilters(dataIndex, "");
              onFilter(dataIndex, "", type);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.RESET")}
          </$Button>
        </div>
      ),
      filterIcon: (filtered: string) => (
        <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    };
  };

  /**
   * @description - render rows
   */
  const renderCell = (text: any, record: ICourtInfo, dataIndex: string) => {
    const { courtItemId } = record;
    const row = {
      children: (
        <>
          {dataIndex === CourtInfoTableColumn.MENU && (
            <div className="align-items-center" key={courtItemId}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.id === courtItemId}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: courtItemId,
                  });
                  setAutoClose(courtItemId);
                }}
                content={
                  <ItemMenu
                    onCallAction={(actionType: ICourtInfoAction) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        id: courtItemId,
                      });
                      onCallAction(actionType, record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          )}
          {dataIndex !== CourtInfoTableColumn.MENU && (
            <$Tooltip placement="topLeft" title={text} key={courtItemId}>
              {text}
            </$Tooltip>
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description Get table header column title component
   * @param dataIndex - index of the column
   * @param title - title of the column
   * @param id - id of the column
   * @returns React `div` Element
   */
  const getHeaderTitle = (dataIndex: string, title: any, id: number) => {
    const type = getColumnType(dataIndex);
    const currentValue = getColumnInputFieldValue(dataIndex);
    return (
      <div className="d-flex flex-column">
        <span style={{ fontWeight: "bold" }}>
          <span data-testid={dataIndex}>
            {t(`US.COLLECTION.ADMIN:COURT.${title}`)}
          </span>
          {currentValue && (
            <p>
              <span
                className="font-sm mt-n4"
                style={{ fontWeight: "bold" }}
                data-testid={`filter-by-${dataIndex}`}
              >
                {t("COMMON.FILTERED_BY_")}:{" "}
                {(type === ColumnSearchType.STRING ||
                  type === ColumnSearchType.NUMBER) &&
                  currentValue}
              </span>
            </p>
          )}
        </span>
      </div>
    );
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    let columns: any[] = [];
    COLUMNS.map(({ id, key, title }, _index) => {
      const columnWidth = getColumnWidth(key);
      const headerTitle = id !== 0 ? getHeaderTitle(key, title, id) : "";
      let column: any = {
        dataIndex: key,
        key,
        title: headerTitle,
        render: (text: any, record: any) => renderCell(text, record, key),
      };
      if (id === 0) {
        column = {
          ...column,
          align: Align.CENTER,
          width: columnWidth,
        };
      } else {
        column = {
          ...column,
          align: Align.LEFT,
          width: columnWidth,
          sortOrder: sorter.columnKey === key && sorter.order,
          filteredValue: filters[key] || null,
          ...getColumnSearchProps(key, title),
          sorter: (a: any, b: any) => {
            if (
              key !== CourtInfoTableColumn.MENU &&
              key !== CourtInfoTableColumn.POSTAL_CODE
            ) {
              return a[key].localeCompare(b[key]);
            } else if (key !== CourtInfoTableColumn.MENU) {
              return a[key] - b[key];
            }
          },
          ellipsis: {
            showTitle: false,
          },
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$Table
      data-testid="courtInfo-table"
      columns={getColumns()}
      rowKey={(record) => record.courtItemId}
      dataSource={tableData}
      className="header-custom-tag"
      bordered
      size="small"
      pagination={{ defaultPageSize: 20 }}
      onChange={onTableChange}
      scroll={{ x: 1300, y: "calc(100vh - 195px)" }}
      onRow={(record:ICourtInfo, rowIndex) => {
        return {
          onDoubleClick: (event) => {
            setItemMenu({
              ...itemMenu,
              visible: false,
              id: record.courtItemId,
            });
            onCallAction(CourtInfoAction.EDIT, record);
          },
        };
      }}
    />
  );
};

export default CourtInfoTable;
