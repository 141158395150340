import { validateNumber } from "us.common/functions";
import moment from "moment";
import { appInsights } from "us.helper";

export const totalBalance = (amounts: any) => {
  try {
    let total =
      amounts.collectionBalance +
      amounts.costBalance +
      amounts.courtBalance +
      amounts.mainBalance +
      amounts.otherBalance +
      amounts.interestCostBalance +
      amounts.interestMainBalance;

    return total;
  } catch (error) {
    appInsights.trackException(
      `Error in totalBalance function in PaymentAgreement View. - ${error}`
    );
    return 0;
  }
};

export const getNumber = (value: any, currentLanguage: string): number => {
  try {
    let returnVal = validateNumber(value, currentLanguage);
    return returnVal && returnVal > 0 ? returnVal : 0;
  } catch (error) {
    appInsights.trackException(
      `Error in getNumber function in PaymentAgreement View. - ${error}`
    );
    return 0;
  }
};
export const totalAmount = (values: any) => {
  try {
    let total =
      getNumber(values?.collectionFee, values?.currentLanguage) +
      getNumber(values?.costFee, values?.currentLanguage) +
      getNumber(values?.courtFee, values?.currentLanguage) +
      getNumber(values?.otherFee, values?.currentLanguage) +
      getNumber(values?.mainAmount, values?.currentLanguage) +
      values.interestMainBalance +
      values.interestCostBalance;
    return total;
  } catch (error) {
    appInsights.trackException(
      `Error in totalAmount function in PaymentAgreement View. - ${error}`
    );
    return 0;
  }
};
export const dataMapper = (amounts: any) => {
  return {
    collectionFee: amounts?.collectionBalance,
    costFee: amounts?.costBalance,
    courtFee: amounts?.courtBalance,
    id: 0,
    interestCostAmount: amounts?.interestCostBalance,
    interestDate: moment(),
    interestMainAmount: amounts?.interestMainBalance,
    isSendSMSNotification: false,
    mainAmount: amounts?.mainBalance,
    otherFee: amounts?.otherBalance,
    payDate: moment().add(1, "days"),
    totalBalance: totalBalance(amounts),
  };
};

/**
 * Get totalAmount
 * @param parent parent object
 * @returns sum of inputs
 */
export function setTotalAmount(parent: any): number {
  try {
    const {
      collectionFee,
      costFee,
      courtFee,
      mainAmount,
      otherFee,
      interestCostAmount,
      interestMainAmount,
    } = parent;
    return (
      collectionFee +
      costFee +
      courtFee +
      mainAmount +
      otherFee +
      interestCostAmount +
      interestMainAmount
    );
  } catch (error) {
    appInsights.trackException(
      `Error in setTotalAmount function in PaymentAgreement View. - ${error}`
    );
    return 0;
  }
}
