import React from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, useField } from "formik";
import { SelectedDay } from "./Components";
import { ISelectedDays, IScheduleTime } from "./Interfaces";
import _ from "lodash";
import "./SelectedDays.scss";

/**
 * @description Selected Day Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const SelectedDays: React.FC<ISelectedDays> = (props) => {
  const { t } = useTranslation();
  const { name } = props;
  const [field] = useField(name);
  const data: Array<IScheduleTime> = field.value;

  return (
    <div className="dom-amt-list-table selected-days">
      <div className="list-table-row list-table-header">
        <div className="list-table-col pl-4" style={{ width: 170 }}>
          {t("US.COMMON:COMMON.SELECTED_DAYS")}
        </div>
        <div className="list-table-col pl-2" style={{ width: 200 }}>
          {t("US.COMMON:COMMON.START_TIME")}
        </div>
        <div className="list-table-col pl-2" style={{ width: 200 }}>
          {t("US.COMMON:COMMON.END_TIME")}
        </div>
      </div>
      <FieldArray
        name={name}
        render={() => (
          <>
            {data?.length > 0 &&
              _.orderBy(data, ["dayId"], ["asc"]).map(
                ({ dayId, day }: IScheduleTime, index: number) => (
                  <SelectedDay
                    key={dayId}
                    name={day}
                    day={`${name}[${index}].isDayEnabled`}
                    startTime={`${name}[${index}].timeSlot.startTime`}
                    endTime={`${name}[${index}].timeSlot.endTime`}
                  />
                )
              )}
          </>
        )}
      />
    </div>
  );
};

export default SelectedDays;
