import { UserInformation } from "us.common/constants";
import { initialState } from "./State";
import { getMenuQuickLinks } from "us.common/functions";

const {
  GET_USER_PROFILE_PHOTO_START,
  GET_USER_PROFILE_PHOTO_SUCCESS,
  GET_USER_PROFILE_PHOTO_FAIL,
  GET_TENANT_DETAILS_START,
  GET_TENANT_DETAILS_SUCCESS,
  GET_TENANT_DETAILS_FAIL,
  GET_QUICK_LINKS_START,
  GET_QUICK_LINKS_SUCCESS,
  GET_QUICK_LINKS_FAIL,
  ADD_QUICK_LINK_START,
  ADD_QUICK_LINK_SUCCESS,
  ADD_QUICK_LINK_FAIL,
  REMOVE_QUICK_LINK_START,
  REMOVE_QUICK_LINK_SUCCESS,
  REMOVE_QUICK_LINK_FAIL
} = UserInformation;

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_PROFILE_PHOTO_START:
      return Object.assign({}, state, {
        userProfilePhoto: undefined,
      });
    case GET_USER_PROFILE_PHOTO_SUCCESS:
      return Object.assign({}, state, {
        userProfilePhoto: action.payload.data,
      });
    case GET_USER_PROFILE_PHOTO_FAIL:
      return Object.assign({}, state, {
        userProfilePhoto: state.userProfilePhoto,
      });
    case GET_TENANT_DETAILS_START:
      return Object.assign({}, state, {
        tenant: {},
      });
    case GET_TENANT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        tenant: action.payload.data,
      });
    case GET_TENANT_DETAILS_FAIL:
      return Object.assign({}, state, {
        tenant: state.tenant,
      });
    case GET_QUICK_LINKS_START:
      return Object.assign({}, state, {
        quickLinks: {
          ...initialState.quickLinks,
          isLoading: true,
        },
      });
    case GET_QUICK_LINKS_SUCCESS:
      return Object.assign({}, state, {
        quickLinks: {
          data: getMenuQuickLinks(action.payload.data),
          isLoading: false,
        },
      });
    case GET_QUICK_LINKS_FAIL:
      return Object.assign({}, state, {
        quickLinks: {
          ...state.quickLinks,
          isLoading: false,
        },
      });

    case ADD_QUICK_LINK_START:
      return Object.assign({}, state, {
        quickLinks: {
          ...state.quickLinks,
          isSaving: true,
        },
      });
    case ADD_QUICK_LINK_SUCCESS:
      return Object.assign({}, state, {
        quickLinks: {
          data: getMenuQuickLinks(action.payload.data),
          isSaving: false,
        },
      });
    case ADD_QUICK_LINK_FAIL:
      return Object.assign({}, state, {
        quickLinks: {
          ...state.quickLinks,
          isSaving: false,
        },
      });

    case REMOVE_QUICK_LINK_START:
      return Object.assign({}, state, {
        quickLinks: {
          ...state.quickLinks,
          isSaving: true,
        },
      });
    case REMOVE_QUICK_LINK_SUCCESS:
      return Object.assign({}, state, {
        quickLinks: {
          data: getMenuQuickLinks(action.payload.data),
          isSaving: false,
        },
      });
    case REMOVE_QUICK_LINK_FAIL:
      return Object.assign({}, state, {
        quickLinks: {
          ...state.quickLinks,
          isSaving: false,
        },
      });

    default:
      return state;
  }
};
