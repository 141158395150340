import { IOperation } from "../interface";

/**
 * It returns true if the link is in the quickLinks array, otherwise it returns false.
 * @param quickLinks - Array<IOperation>
 * @param {IOperation} link - IOperation = {
 * @returns A boolean value.
 */
export const isQuickLink = (
  quickLinks: Array<IOperation>,
  link: IOperation
): boolean => {
  try {
    return quickLinks?.some(
      (item: IOperation) =>
        item.id === link.id ||
        item.name.toLowerCase().trim() === link.name.toLowerCase().trim()
    );
  } catch (error) {
    return false;
  }
};
