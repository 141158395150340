import { IInitialState } from '../Interfaces'
import moment from 'moment';

export const initialState: IInitialState = {
    activities: {
        data: [],
        isLoading: false
    },
    parameters: {
        data: {},
        isLoading: false
    },
    userAndCase: {
        user: '',
        caseNumber: '',
        level:''
    },
    initailValue: {},
    isExecuteSuccess: false,
    activityDetail: {
        activityCode: '',
        activityGroup: '',
        activityGroupId: '',
        activityId: '',
        canExecute: false,
        categoryId: '',
        categoryName: '',
        description: '',
        displayName: '',
        entitytype: '',
        name: '',
        type: '',
        actionType: 'runActivity',
        activityHistoryId: '',
        executedStatus: '',
        scheduledTime: '',
        clickTime: 0
    },
    isPendingActivityExecuteSuccess: false,
    isPendingActivityNotAvailable: false,
    isRefreshRoutines: true,
    isExecuting:false
}