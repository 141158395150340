import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import { MunicipalitiesTable, MunicipalityDetails } from "./Components";
import { RootState } from "us.helper/types";
import * as Actions from "us.collection.admin/actions";
import {
  MUNICIPALITY_DRAWER_DEFAULT,
  MunicipalityActions,
} from "us.collection.admin/constants";
import { Municipality } from "us.collection.admin/interfaces";
import { handleGoBack } from "us.helper/utility";
import "./Home.scss";

const { $Affix, $Drawer, $PageHeader, $Divider, $Button } = Common.Components;

/**
 * @description - Parent component for manage municipality.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3340959765/Manage+Municipalities+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 27/07/2023
 */
const MunicipalitiesHome: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { drawerInfo, initMunicipalities, manageDrawer, deleteMunicipality } =
    props;

  useEffect(() => {
    initMunicipalities && initMunicipalities({});
  }, []);

  /**
   * @description - Close the drawer
   */
  const closeDrawer = () => {
    manageDrawer && manageDrawer(MUNICIPALITY_DRAWER_DEFAULT);
  };

  /**
   * @description Open the drawer by type
   * @param {DrawerType} type - Type of the drawer
   * @param {number} municipalityId - Selected municipality id
   */
  const openDrawer = (type: MunicipalityActions, municipalityId: number) => {
    // decide the suitable title for the drawer
    const title =
      type === MunicipalityActions.EDIT
        ? t("US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.EDIT_MUNICIPALITY")
        : t("US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.NEW_MUNICIPALITY");

    // change drawer state
    manageDrawer &&
      manageDrawer({
        municipalityId,
        type,
        title,
      });
  };

  /**
   * @description handle municipality menu actions
   * @param action - action of clicked menu icon
   * @param municipalityId - selected municipality id
   */
  const onHandleTableAction = (
    action: MunicipalityActions,
    { municipalityId }: Municipality
  ) => {
    switch (action) {
      case MunicipalityActions.EDIT:
        openDrawer(MunicipalityActions.EDIT, municipalityId);
        break;
      case MunicipalityActions.DELETE:
        deleteMunicipality && deleteMunicipality({ municipalityId });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="space-content">
        <$Affix offsetTop={48}>
          <div className="page-header header-border">
            <div className="d-flex align-items-center">
              <$PageHeader
                className="px-0"
                onBack={() => handleGoBack(history)}
                title={t(
                  "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.MANAGE_MUNICIPALITIES"
                )}
              />
              <$Divider className="bui-devider" type="vertical" />
              <$Button
                data-testid="new-municipality"
                className="mr-2"
                type="default"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => openDrawer(MunicipalityActions.NEW, -1)}
              >
                {t(
                  "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.NEW_MUNICIPALITY"
                )}
              </$Button>
            </div>
          </div>
        </$Affix>
      </div>

      <div className="flex-fill px-4">
        <MunicipalitiesTable
          onCallAction={(action: MunicipalityActions, record: Municipality) =>
            onHandleTableAction(action, record)
          }
        />
      </div>

      <$Drawer
        title={drawerInfo.title}
        width={"400px"}
        visible={drawerInfo.visible}
        onClose={closeDrawer}
        destroyOnClose={true}
        maskClosable={false}
      >
        <MunicipalityDetails onCancel={closeDrawer} />
      </$Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { municipalities } = state;
  const { drawerInfo } = municipalities;

  return {
    drawerInfo,
  };
};

const { init, municipality } = Actions.municipalities;
const { manageDrawer, get } = init;

const mapDispatchToProps = {
  deleteMunicipality: municipality.delete,
  initMunicipalities: get,
  manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MunicipalitiesHome);
