import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    courtName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_COURT_ITEM_NAME"
    ),
    address1: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_COURT_ITEM_ADDRESS"
    ),
    postalCode: Yup.number()
      .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_COURT_ITEM_POSTAL_PLACE"
      )
      .test(
        "postalCode",
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_COURT_ITEM_POSTAL_PLACE",
        function (val: any) {          
          return val != "";
        }
      ),
    telephone:Yup.string() 
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_TELEPHONE") 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(), 
  });
};
