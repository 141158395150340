import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";

import { $TableTree, $Skeleton, $DateLabel } from "us.common/components";
import { userManagementActions } from "us.common/actions";
import { RootState } from "us.helper/types";

const { user } = userManagementActions;

const RoleManagement: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const { getHistory, drawer, userHistory } = props;
  const { data, isLoading } = userHistory;
  const { selectedUser } = drawer;
  const { id } = selectedUser;

  useEffect(() => {
    getHistory && getHistory({ userId: id });
  }, []);

  const columns: any = [
    {
      title: t("US.COMMON:MANAGE_USER.MODIFIED_USER"),
      dataIndex: "displayName",
      key: "displayName",
      ellipsis: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COMMON:MANAGE_ROLES.DATE_&_TIME"),
      dataIndex: "createdOn",
      key: "createdOn",
      width: 160,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        return (
          moment.utc(record?.createdOn).isValid() && (
            <$DateLabel value={record?.createdOn} />
          )
        );
      },
    },
    {
      title: t("US.COMMON:COMMON.COMMENT"),
      dataIndex: "comment",
      key: "comment",
      ellipsis: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
  ];

  return (
    <$Skeleton loading={isLoading} active paragraph={{ rows: 2 }}>
      <$TableTree
        rowKey="roleHistoryId"
        data={data}
        columns={columns}
        size="small"
        className="mt-3"
        onSort={(sortData, dataSource) => sortData(dataSource)}
        onFilter={(searchData, dataSource) => searchData(dataSource)}
        filterOnType={true}
        resetOnSourceChange={true}
        bordered
        pagination={{ defaultPageSize: 200 }}
        scroll={{ x: "100%", y: "calc(100vh - 215px)" }}
      />
    </$Skeleton>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userManagement } = state;
  const { drawer, userHistory } = userManagement;
  return {
    drawer,
    userHistory,
  };
};
const mapDispatchToProps = {
  getHistory: user.getHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RoleManagement);
