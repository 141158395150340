import { ICriteria } from "us.collection.admin/interfaces";
/**
  * @function
  * @description set all criteria value true
  * @param {Array<ICriteria>} criteria
  * @returns {Array<ICriteria>}
  */
export const setAllCriteriaTrue = (criteria: Array<ICriteria>): Array<ICriteria> => {
    try {
        return criteria.map((item: ICriteria) => ({ ...item, value: true }));
    } catch (error) {
        return criteria;
    }
};