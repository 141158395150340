import { Municipality } from "us.collection.admin/interfaces";
import _ from "lodash";

/**
 * @description Sort municipality list.
 * @param {Array} municipalities - List of municipalities
 * @returns {Array} - Sorted list
 */
export const sortMunicipalities = (
  municipalities: Array<Municipality>
): Array<Municipality> => {
  try {
    if (Array.isArray(municipalities)) {
      return _.orderBy(municipalities, ["municipalityId"], ["desc"]);
    } else {
      return [];
    }
  } catch (error) {
    return municipalities;
  }
};
