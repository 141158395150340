import { takeLatest, put, call } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { ManageActivities } from "us.collection.admin/constants/Actions";
import Common from "us.common";
import { filterActivitySummary } from "us.collection.admin/functions";
import { initialStepDetail } from "us.collection.admin/reducers/ManageActivities/State";
import { parse } from "query-string";
import { ActivitySummaryFilters, Entity, EntityType } from "us.collection.admin/constants";
import { isArray } from "lodash";

const { $MessageBox } = Common.Components;

const { manageActivities } = Actions;

const ManageActivitiesSagas = {
  activities: {
    get: function* (action: any): any {
      try {
        const data = yield call(API.manageActivities.activities.get);
        if (Array.isArray(data)) {
          const searchVal =
            parse(location.search)[ActivitySummaryFilters.SEARCH_KEY] ?? "";
          yield put(
            manageActivities.activities.success({
              rawData: data,
              filteredData: filterActivitySummary(data, searchVal.toString()),
            })
          );
        } else {
          yield put(manageActivities.activities.fail(data));
        }
      } catch (error) {
        yield put(manageActivities.activities.fail(error as any));
      }
    },
  },
  activity: {
    init: function* (action: any): any {
      try {
		const { entityType } = action?.payload?.data ?? {};

        const data = yield call(API.manageActivities.activity.init);
        if (manageActivities.activity.initSuccess) {
          if (isArray(data?.entityTypes) && data?.entityTypes.length > 0 && (entityType.toLowerCase()==EntityType.CASE || entityType.toLowerCase() ==EntityType.INVOICE))  {
            const activityStates = yield call(API.manageActivities.states.get, {
              entityType
            });
            if (Array.isArray(activityStates)) {
              yield put(manageActivities.states.success(activityStates));
            } else {
              yield put(manageActivities.states.fail(activityStates));
            }
          }
          yield put(manageActivities.activity.initSuccess(data));
        }
      } catch (error) {
        if (manageActivities.activity.initFail) {
          yield put(manageActivities.activity.initFail(error as any));
        }
      }
    },
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const isNew: boolean = action?.payload?.isNew ?? false;
      try {
        const data = yield call(API.manageActivities.activity.save, params);
        $MessageBox(
          "success",
          isNew
            ? `US.COMMON:MESSAGES.SUCCESSFULLY_ADDED`
            : `US.COMMON:MESSAGES.SUCCESSFULLY_UPDATED`,
          "",
          ""
        );
        yield put(manageActivities.activity.success(data));
        if (manageActivities.activities.get) {
          yield put(manageActivities.activities.get({}));
        }
        if (manageActivities.activity.manageDrawer) {
          yield put(
            manageActivities.activity.manageDrawer({
              isVisible: false,
              isEdit: false,
            })
          );
        }
        if (manageActivities.activity.setStep) {
          yield put(
            manageActivities.activity.setStep({ ...initialStepDetail })
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          isNew
            ? "US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_ADDITION_FAILED"
            : "US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_UPDATE_FAILED",
          "",
          ""
        );
        yield put(manageActivities.activity.fail(error as any));
      }
    },
    delete: function* (action: any): any {
      try {
        const params = action?.payload.data ?? {};
        const data = yield call(API.manageActivities.activity.delete, params);
        if (data.isSuccess) {
          if (manageActivities.activity.deleteSuccess) {
            yield put(manageActivities.activity.deleteSuccess(data));
          }
          $MessageBox(
            "success",
            "US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_SUCCESSFULLY_DELETED",
            "",
            ""
          );
          if (manageActivities.activities.get) {
            yield put(manageActivities.activities.get({}));
          }
        } else {
          if (manageActivities.activity.deleteFail) {
            yield put(manageActivities.activity.deleteFail(data));
          }
          if (data?.errorMessage?.includes("it is in use")) {
            $MessageBox(
              "error",
              "US.COLLECTION.ADMIN:ACTIVITIES.CANNOT_DELETE_THIS_ACTIVITY_AS_IT_HAS_BEEN_CURRENTLY_USED",
              "",
              ""
            );
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ADMIN:ACTIVITIES.ERROR_IN_ACTIVITY_DELETION",
              "",
              ""
            );
          }
        }
      } catch (error) {
        if (manageActivities.activity.deleteFail) {
          yield put(manageActivities.activity.deleteFail(error as any));
        }
        if ((error as any)?.errorMessage?.includes("it is in use")) {
          $MessageBox(
            "error",
            "US.COLLECTION.ADMIN:ACTIVITIES.CANNOT_DELETE_THIS_ACTIVITY_AS_IT_HAS_BEEN_CURRENTLY_USED",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ADMIN:ACTIVITIES.ERROR_IN_ACTIVITY_DELETION",
            "",
            ""
          );
        }
      }
    },
  },
  properties: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageActivities.properties.get, params);
        yield put(manageActivities.properties.success(data));
      } catch (error) {
        yield put(manageActivities.properties.fail(error as any));
      }
    },
  },
  parameters: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageActivities.parameters.get, params);
        if (Array.isArray(data?.extendedProperties)) {
          yield put(manageActivities.parameters.success(data));
        } else {
          yield put(manageActivities.parameters.fail(data));
        }
      } catch (error) {
        yield put(manageActivities.parameters.fail(error as any));
      }
    },
  },
  templateCategories: {
    get: function* (action: any): any {
      let params = action?.payload?.data ?? {};
      if (params.entityType == Entity.INVOICE) {
        params = { entityType: Entity.SUB_CASE };
      }
      try {
        const data = yield call(
          API.manageActivities.templateCategories.get,
          params
        );
        yield put(manageActivities.template.success(data));
      } catch (error) {
        yield put(manageActivities.template.fail(error as any));
      }
    },
  },
  followupCategory: {
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.followupCategory.save,
          params
        );
        yield put(manageActivities.followupCategory.success(data));
      } catch (error) {
        yield put(manageActivities.followupCategory.fail(error as any));
      }
    },
    delete: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.followupCategory.delete,
          params
        );
        if (data.hasOwnProperty("isError") && !data?.isError) {
          $MessageBox(
            "success",
            `US.COMMON:MESSAGES.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
          if (manageActivities.followupCategory.deleteSuccess) {
            yield put(manageActivities.followupCategory.deleteSuccess(params));
          }
        } else if (
          data?.message.includes("Cannot delete followup category in use")
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:ACTIVITIES.YOU_CANNOT_DELETE_THIS_CATEGORY_AS_IT_HAS_BEEN_CURRENTLY_USED`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_DELETION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_DELETION_FAILED`,
          "",
          ""
        );
        if (manageActivities.followupCategory.deleteFail) {
          yield put(manageActivities.followupCategory.deleteFail(error as any));
        }
      }
    },
  },
  states: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageActivities.states.get, params);
        if (Array.isArray(data)) {
          yield put(manageActivities.states.success(data));
        } else {
          yield put(manageActivities.states.fail(data));
        }
      } catch (error) {
        yield put(manageActivities.states.fail(error as any));
      }
    },
  },
  followupCategories: {
    get: function* (action: any): any {
      try {
        const data = yield call(API.manageActivities.followupCategories.get);
        if (Array.isArray(data)) {
          yield put(manageActivities.followupCategories.success(data));
        } else {
          yield put(manageActivities.followupCategories.fail(data));
        }
      } catch (error) {
        yield put(manageActivities.followupCategories.fail(error as any));
      }
    },
  },
  activityDetail: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.activityDetail.get,
          params
        );
        yield put(manageActivities.activityDetail.success(data));
        if (data.activityExecutionProfileId > -1) {
          if (manageActivities.executionProfile.get)
            yield put(
              manageActivities.executionProfile.get({
                profileId: data.activityExecutionProfileId,
              })
            );
        }
      } catch (error) {
        yield put(manageActivities.activityDetail.fail(error as any));
      }
    },
  },
  executionProfiles: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.executionProfiles.get,
          params
        );
        yield put(manageActivities.executionProfiles.success(data));
      } catch (error) {
        yield put(manageActivities.executionProfiles.fail(error as any));
      }
    },
  },
  executionProfile: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.executionProfile.get,
          params
        );
        yield put(manageActivities.executionProfile.success(data));
      } catch (error) {
        yield put(manageActivities.executionProfile.fail(error as any));
      }
    },
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.executionProfile.save,
          params
        );
        if (manageActivities.executionProfile.saveOrEditSuccess) {
          yield put(
            manageActivities.executionProfile.saveOrEditSuccess({
              ...params,
              ...data,
            })
          );
        }
        $MessageBox("success", `US.COMMON:MESSAGES.SUCCESSFULLY_SAVED`, "", "");
        if (manageActivities.executionProfiles.get) {
          yield put(manageActivities.executionProfiles.get({}));
        }
      } catch (error) {
        if (manageActivities.executionProfile.saveOrEditFail) {
          yield put(
            manageActivities.executionProfile.saveOrEditFail(error as any)
          );
        }
      }
    },
    delete: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.manageActivities.executionProfile.delete,
          params
        );
        if (data.hasOwnProperty("isSuccess") && data?.isSuccess) {
          $MessageBox(
            "success",
            `US.COMMON:MESSAGES.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
          if (manageActivities.executionProfile.deleteSuccess) {
            yield put(manageActivities.executionProfile.deleteSuccess(params));
          }
          if (manageActivities.executionProfiles.get) {
            yield put(manageActivities.executionProfiles.get({}));
          }
        } else if (
          data?.errors?.includes(
            "Profile Id you are trying to delete is not valid"
          )
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:ACTIVITIES.PROFILE_ID_YOU_ARE_TRYING_TO_DELETE_IS_NOT_VALID`,
            "",
            ""
          );
        } else if (
          data?.errorMessage?.includes(
            "Profile is not found for the given profile id"
          )
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:ACTIVITIES.PROFILE_IS_NOT_FOUND_FOR_THE_GIVEN_PROFILE_ID`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:ACTIVITIES.EXECUTION_PROFILE_DELETION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:ACTIVITIES.EXECUTION_PROFILE_DELETION_FAILED`,
          "",
          ""
        );
        if (manageActivities.executionProfile.deleteFail) {
          yield put(manageActivities.executionProfile.deleteFail(error as any));
        }
      }
    },
  },
  eventList: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageActivities.eventList.get, params);
        yield put(manageActivities.eventList.success(data));
      } catch (error) {
        yield put(manageActivities.eventList.fail(error as any));
      }
    },
  },
};

export default [
  takeLatest(
    ManageActivities.GET_ACTIVITY_SUMMARY,
    ManageActivitiesSagas.activities.get
  ),
  takeLatest(
    ManageActivities.INIT_ACTIVITY,
    ManageActivitiesSagas.activity.init
  ),
  takeLatest(
    ManageActivities.GET_ACTIVITY_TYPE_PROPERTIES,
    ManageActivitiesSagas.properties.get
  ),
  takeLatest(
    ManageActivities.GET_ACTIVITY_TYPE_PARAMETERS,
    ManageActivitiesSagas.parameters.get
  ),
  takeLatest(
    ManageActivities.GET_ACTIVITY_STATES,
    ManageActivitiesSagas.states.get
  ),
  takeLatest(
    ManageActivities.GET_ACTIVITY_FOLLOWUP_CATEGORY,
    ManageActivitiesSagas.followupCategories.get
  ),
  takeLatest(
    ManageActivities.SAVE_ACTIVITY_FOLLOWUP_CATEGORY,
    ManageActivitiesSagas.followupCategory.save
  ),
  takeLatest(
    ManageActivities.DELETE_ACTIVITY_FOLLOWUP_CATEGORY,
    ManageActivitiesSagas.followupCategory.delete
  ),
  takeLatest(
    ManageActivities.GET_ACTIVITY_DETAIL,
    ManageActivitiesSagas.activityDetail.get
  ),
  takeLatest(
    ManageActivities.SAVE_ACTIVITY,
    ManageActivitiesSagas.activity.save
  ),
  takeLatest(
    ManageActivities.DELETE_ACTIVITY,
    ManageActivitiesSagas.activity.delete
  ),
  takeLatest(
    ManageActivities.GET_EXECUTION_PROFILES,
    ManageActivitiesSagas.executionProfiles.get
  ),
  takeLatest(
    ManageActivities.GET_EXECUTION_PROFILE_DETAILS,
    ManageActivitiesSagas.executionProfile.get
  ),
  takeLatest(
    ManageActivities.SAVE_EXECUTION_PROFILE,
    ManageActivitiesSagas.executionProfile.save
  ),
  takeLatest(
    ManageActivities.DELETE_EXECUTION_PROFILE,
    ManageActivitiesSagas.executionProfile.delete
  ),
  takeLatest(
    ManageActivities.GET_EVENT_LIST,
    ManageActivitiesSagas.eventList.get
  ),
  takeLatest(
    ManageActivities.GET_TEMPLATE_CATEGORIES,
    ManageActivitiesSagas.templateCategories.get
  ),
];
