import { Role } from 'us.common/constants'
import { IAPIActionBase, IRoleAndUserManagementAction } from 'us.common/interfaces';

export const roleManagementActions: Readonly<IAPIActionBase & IRoleAndUserManagementAction> = {
    roles: {
        get: (data: any) => ({
            type: Role.GET_ROLES_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: Role.GET_ROLES_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: Role.GET_ROLES_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
    },
    role: {
        get: (data: any) => ({
            type: Role.GET_ROLE_BY_ID_START,
            payload: {
                data,
                isLoading: true,
            },
        }),
        success: (data: any) => ({
            type: Role.GET_ROLE_BY_ID_SUCCESS,
            payload: {
                data,
                isLoading: false,
            },
        }),
        fail: (error: any) => ({
            type: Role.GET_ROLE_BY_ID_FAIL,
            payload: {
                error,
                isLoading: false,
            },
        }),
        save: (data) => ({
            type: Role.ADD_NEW_ROLE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        saveSuccess: (data) => ({
            type: Role.ADD_NEW_ROLE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        saveFail: (error) => ({
            type: Role.ADD_NEW_ROLE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        openDrawer: (data) => ({
            type: Role.MANAGE_ROLE_DRAWER,
            payload: {
                data,
                isLoading: true
            }
        }),
        delete: (data) => ({
            type: Role.DELETE_ROLE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        deleteSuccess: (data) => ({
            type: Role.DELETE_ROLE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        deleteFail: (error) => ({
            type: Role.DELETE_ROLE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        activateOrDeactivate: (data) => ({
            type: Role.ROLE_STATE_CHANGE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        activateOrDeactivateSuccess: (data) => ({
            type: Role.ROLE_STATE_CHANGE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        activateOrDeactivateFail: (error) => ({
            type: Role.ROLE_STATE_CHANGE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        update: (data) => ({
            type: Role.UPDATE_ROLE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        updateSuccess: (data) => ({
            type: Role.UPDATE_ROLE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        updateFail: (error) => ({
            type: Role.UPDATE_ROLE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        getModules: (data) => ({
            type: Role.GET_ROLE_MODULES_AND_FEATURES_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        getModulesSuccess: (data) => ({
            type: Role.GET_ROLE_MODULES_AND_FEATURES_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        getModulesFail: (error) => ({
            type: Role.GET_ROLE_MODULES_AND_FEATURES_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        reset: (data) => ({
            type: Role.RESET_ROLE_FORM,
            payload: {
                data,
                isLoading: true
            }
        }),
        getHistory: (data) => ({
            type: Role.GET_ROLE_HISTORY_BY_ID_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        getHistorySuccess: (data) => ({
            type: Role.GET_ROLE_HISTORY_BY_ID_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        getHistoryFail: (error) => ({
            type: Role.GET_ROLE_HISTORY_BY_ID_FAIL,
            payload: {
                error,
                isLoading: false

            }
        }),
        saveHistory: (data) => ({
            type: Role.ADD_ROLE_HISTORY_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        saveHistorySuccess: (data) => ({
            type: Role.ADD_ROLE_HISTORY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        saveHistoryFail: (error) => ({
            type: Role.ADD_ROLE_HISTORY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    }
};