import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  $Checkbox,
  $Search,
  $CheckboxGroup,
  $Tooltip,
  $Skeleton,
} from "us.common/components";
import { ICreditorSetupItem, ICheckDetail } from "./Interfaces";
import {
  ICreditor,
  ICreditorCompany,
  ICreditorGroup,
} from "us.common/interfaces";
import { getDataIds } from "./Functions";

const CreditorSetupItem: React.FC<ICreditorSetupItem> = (props) => {
  const {
    name,
    data,
    checkBoxDisplayProperty,
    isLoading,
    handleCheckItem,
    defaultValue,
  } = props;
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");
  const [checkDetail, setCheckDetail] = useState<ICheckDetail>({
    indeterminate: false,
    checkAll: false,
    checkedList: defaultValue,
  });

  const filteredData = useMemo(() => {
    return data.filter((item: ICreditor | ICreditorGroup | ICreditorCompany) =>
      item[checkBoxDisplayProperty as keyof typeof item]
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  }, [data, searchText]);

  /**
   * @function
   * @description get Checkbox Item
   * @param {string | number} value
   * @param {any} label
   * @returns
   */
  const getCheckboxItem = (value: string | number, label: any) => {
    try {
      return {
        label: (
          <$Tooltip placement="topLeft" title={label}>{`${label}`}</$Tooltip>
        ),
        value: value,
      };
    } catch (error) {
      return { label, value };
    }
  };

  /**
   * @function
   * @description get Checkbox Options
   * @param {Array<ICreditor | ICreditorGroup | ICreditorCompany>} dataList
   * @returns
   */
  const getCheckboxOptions = (
    dataList: Array<ICreditor | ICreditorGroup | ICreditorCompany>
  ) => {
    try {
      return dataList.map(
        (item: ICreditor | ICreditorCompany | ICreditorGroup) => {
          const { id } = item;
          return getCheckboxItem(
            id,
            item[checkBoxDisplayProperty as keyof typeof item]
          );
        }
      );
    } catch {
      return [];
    }
  };

  const onChange = (list: Array<string | number | boolean>) => {
    try {
      handleCheckItem(list);
      setCheckDetail({
        checkedList: list,
        indeterminate: !!list.length && list.length < data.length,
        checkAll: list.length === data.length,
      });
    } catch (error) {}
  };

  const onCheckAllChange = (e: any) => {
    try {
      const checkedIds = getDataIds(filteredData);
      setCheckDetail({
        checkedList: e.target.checked ? checkedIds : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      handleCheckItem(e.target.checked ? checkedIds : []);
    } catch (error) {}
  };

  return (
    <>
      <strong className="d-block mb-2">
        {t(`US.COMMON:MANAGE_USER.${name}`)}
      </strong>
      <div className="mb-1">
        <$Search
          name="search"
          size="small"
          allowClear={true}
          placeholder={t(`US.COMMON:MANAGE_USER.${name}`)}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className="creditor-scroll-box">
        <$Skeleton loading={isLoading}>
          <div>
            {filteredData.length > 0 && (
              <$Checkbox
                indeterminate={checkDetail.indeterminate}
                checked={checkDetail.checkAll}
                onChange={onCheckAllChange}
              >
                {t("US.COLLECTION.COMMON:COMMON.ALL")}
              </$Checkbox>
            )}
          </div>
          <$CheckboxGroup
            options={getCheckboxOptions(filteredData)}
            value={checkDetail.checkedList}
            onChange={onChange}
          />
        </$Skeleton>
      </div>
    </>
  );
};

export default CreditorSetupItem;
