import React from "react";
import { useTranslation } from "react-i18next";
import { $DateLabel } from "us.common/components";
import { FollowUpPanel } from "us.common/components/FollowUps/Constants";
import { IFollowUpHeader } from "us.common/components/FollowUps/Interfaces";

/**
 * @description - Follow-up card header in collapse item
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const Header: React.FC<IFollowUpHeader> = (props) => {
  const { t } = useTranslation();

  const { title, date, description, assignedTo, lastViewed, followUpPanel } =
    props;

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-primary font-weight-bold">{title}</div>
      </div>
      <div className="cfc-item-font-sm mt-1">
        {date && (
          <span>
            {`${date.label}: `}
            <$DateLabel value={date.value} />
            {" | "}
          </span>
        )}
        {assignedTo && followUpPanel == FollowUpPanel.OTHER && (
          <span>
            {`${t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.ASSIGNED_TO")}: `}
            {assignedTo}
            {" | "}
          </span>
        )}
        {lastViewed && followUpPanel == FollowUpPanel.OTHER && (
          <span>
            {`${t("US.COLLECTION.FOLLOWUP:MANUAL_FOLLOWUP.LAST_VIEWED")}: `}
            {lastViewed}
            {" | "}
          </span>
        )}
      </div>
      {description && (
        <div className="text-muted cfc-item-font-sm">
          {`${description.label}: `}
          {description.value}
        </div>
      )}
    </div>
  );
};

export default Header;
