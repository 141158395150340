import { ActivitySummaryFilters } from "us.collection.admin/constants";
import { ManageActivities } from "us.collection.admin/constants/Actions";
import {
  filterActivitySummary,
  setTreeDataSource,
  removeFollowupCategory,
  addFollowupCategory,
  setNameData,
  setExtendedFields,
  setEntityTypes,
  getActivitySetUpEditValue,
  getActivityStatesEditValue,
  getSetupControlEditValue,
  getStepsForEditActivity,
  getExecutionProfileEditValue,
  formatProfileDetailsByResponse,
} from "us.collection.admin/functions";
import { initialState, initialStepDetail } from "./State";

const {
  SET_STEP_DETAIL,
  INIT_ACTIVITY,
  INIT_ACTIVITY_SUCCESS,
  INIT_ACTIVITY_FAIL,
  MANAGE_BREADCRUMB,
  MANAGE_DRAWER,
  GET_ACTIVITY_SUMMARY,
  GET_ACTIVITY_SUMMARY_SUCCESS,
  GET_ACTIVITY_SUMMARY_FAIL,
  SEARCH_ACTIVITY_SUMMARY,
  SEARCH_ACTIVITY_SUMMARY_SUCCESS,
  SEARCH_ACTIVITY_SUMMARY_FAIL,
  GET_ACTIVITY_STATES,
  GET_ACTIVITY_STATES_SUCCESS,
  GET_ACTIVITY_STATES_FAIL,
  GET_ACTIVITY_FOLLOWUP_CATEGORY,
  GET_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
  GET_ACTIVITY_FOLLOWUP_CATEGORY_FAIL,
  DELETE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
  SAVE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
  GET_ACTIVITY_TYPE_PROPERTIES,
  GET_ACTIVITY_TYPE_PROPERTIES_SUCCESS,
  GET_ACTIVITY_TYPE_PROPERTIES_FAIL,
  GET_ACTIVITY_TYPE_PARAMETERS_SUCCESS,
  GET_ACTIVITY_TYPE_PARAMETERS_FAIL,
  GET_ACTIVITY_DETAIL,
  GET_ACTIVITY_DETAIL_SUCCESS,
  GET_ACTIVITY_DETAIL_FAIL,
  GET_EXECUTION_PROFILES,
  GET_EXECUTION_PROFILES_SUCCESS,
  GET_EXECUTION_PROFILES_FAIL,
  GET_EXECUTION_PROFILE_DETAILS,
  GET_EXECUTION_PROFILE_DETAILS_SUCCESS,
  GET_EXECUTION_PROFILE_DETAILS_FAIL,
  SAVE_EXECUTION_PROFILE_SUCCESS,
  GET_TEMPLATE_CATEGORIES,
  GET_TEMPLATE_CATEGORIES_SUCCESS,
  GET_TEMPLATE_CATEGORIES_FAIL,
  GET_EVENT_LIST,
  GET_EVENT_LIST_SUCCESS,
  GET_EVENT_LIST_FAIL,
} = ManageActivities;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_ACTIVITY_SUMMARY:
      return Object.assign({}, state, {
        activities: {
          list: {
            ...state.activities.list,
            isFetching: true,
          },
        },
      });
    case GET_ACTIVITY_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        activities: {
          list: {
            data: payload.data.rawData,
            dataSource: payload.data.filteredData,
            isFetching: false,
          },
        },
      });
    case GET_ACTIVITY_SUMMARY_FAIL:
      return Object.assign({}, state, {
        activities: {
          list: {
            data: [],
            isFetching: false,
          },
        },
      });
    case SEARCH_ACTIVITY_SUMMARY:
      return Object.assign({}, state, {
        activities: {
          ...state.activities,
          list: {
            ...state.activities.list,
            dataSource: filterActivitySummary(
              state.activities.list.data,
              payload?.data?.[ActivitySummaryFilters.SEARCH_KEY]
            ),
          },
        },
      });
    case SEARCH_ACTIVITY_SUMMARY_SUCCESS:
      return Object.assign({}, state, {});
    case SEARCH_ACTIVITY_SUMMARY_FAIL:
      return Object.assign({}, state, {});

    case SET_STEP_DETAIL:
      return Object.assign({}, state, {
        stepDetail: payload.data,
      });
    case INIT_ACTIVITY:
      return Object.assign({}, state, {
        activity: {
          entityTypes: {
            data: [],
            isFetching: true,
          },
          activityTypes: {
            data: [],
            isFetching: true,
          },
          activityGroups: {
            data: [],
            isFetching: true,
          },
          parameters: {
            data: [],
            isFetching: false,
          },
        },
      });
    case INIT_ACTIVITY_SUCCESS:
      const { entityTypes, activityTypes, activityGroups } = payload.data;
      return Object.assign({}, state, {
        activity: {
          entityTypes: {
            data: setEntityTypes(entityTypes),
            isFetching: false,
          },
          activityTypes: {
            data: activityTypes,
            isFetching: false,
          },
          activityGroups: {
            data: activityGroups,
            isFetching: false,
          },
        },
      });
    case INIT_ACTIVITY_FAIL:
      return Object.assign({}, state, {
        activity: {
          entityTypes: {
            data: [],
            isFetching: false,
          },
          activityTypes: {
            data: [],
            isFetching: false,
          },
          activityGroups: {
            data: [],
            isFetching: false,
          },
          parameters: {
            data: [],
            isFetching: false,
          },
        },
      });
    case MANAGE_BREADCRUMB:
      return Object.assign({}, state, {
        breadCrumb: payload.data,
      });
    case MANAGE_DRAWER:
      return Object.assign({}, state, {
        drawer: payload.data,
        parameters: {
          data: [],
          isFetching: false,
        },
      });
    case GET_ACTIVITY_STATES:
      return Object.assign({}, state, {
        states: {
          data: [],
          dataSource: [],
          isFetching: true,
        },
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            modifyStateValue: initialStepDetail.formValues.modifyStateValue,
          },
        },
      });
    case GET_ACTIVITY_STATES_SUCCESS:
      return Object.assign({}, state, {
        states: {
          data: payload.data,
          dataSource: setTreeDataSource(payload.data),
          isFetching: false,
        },
      });
    case GET_ACTIVITY_STATES_FAIL:
      return Object.assign({}, state, {
        states: {
          data: [],
          dataSource: [],
          isFetching: false,
        },
      });
    case GET_ACTIVITY_FOLLOWUP_CATEGORY:
      return Object.assign({}, state, {
        followupCategories: {
          data: [],
          isFetching: true,
        },
      });
    case GET_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        followupCategories: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_ACTIVITY_FOLLOWUP_CATEGORY_FAIL:
      return Object.assign({}, state, {
        followupCategories: {
          data: [],
          isFetching: false,
        },
      });
    case SAVE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        followupCategories: {
          data: addFollowupCategory(
            state.followupCategories.data,
            payload.data
          ),
          isFetching: false,
        },
      });
    case DELETE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS:
      const { categoryId } = payload.data;
      return Object.assign({}, state, {
        followupCategories: {
          data: removeFollowupCategory(
            state.followupCategories.data,
            categoryId
          ),
          isFetching: false,
        },
      });
    case GET_ACTIVITY_TYPE_PROPERTIES: {
      return Object.assign({}, state, {
        properties: {
          data: [],
          dataSource: [],
          extendedProperties: null,
          extendedField: setExtendedFields(null),
          isFetching: true,
        },
      });
    }
    case GET_ACTIVITY_TYPE_PROPERTIES_SUCCESS: {
      const { activityObjectList, extendedProperties } = payload.data;
      return Object.assign({}, state, {
        properties: {
          data: activityObjectList,
          dataSource: setNameData(activityObjectList),
          extendedProperties,
          extendedField: setExtendedFields(extendedProperties),
          isFetching: false,
        },
      });
    }
    case GET_ACTIVITY_TYPE_PROPERTIES_FAIL: {
      return Object.assign({}, state, {
        properties: {
          data: [],
          dataSource: [],
          extendedProperties: null,
          extendedField: setExtendedFields(null),
          isFetching: false,
        },
      });
    }
    case GET_ACTIVITY_TYPE_PARAMETERS_SUCCESS:
      return Object.assign({}, state, {
        parameters: {
          data: payload.data?.extendedProperties,
          isFetching: false,
        },
      });
    case GET_ACTIVITY_TYPE_PARAMETERS_FAIL:
      return Object.assign({}, state, {
        parameters: {
          data: [],
          isFetching: false,
        },
      });
    case GET_ACTIVITY_DETAIL:
      return Object.assign({}, state, {
        activityDetail: {
          data: {},
          isFetching: true,
        },
      });
    case GET_ACTIVITY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          steps: getStepsForEditActivity(payload.data),
          formValues: {
            activitySetUpValue: getActivitySetUpEditValue(payload.data),
            setControlValue: getSetupControlEditValue(payload.data),
            modifyStateValue: getActivityStatesEditValue(payload.data),
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              ...getExecutionProfileEditValue(payload.data),
            },
          },
        },
        activityDetail: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_ACTIVITY_DETAIL_FAIL:
      return Object.assign({}, state, {
        activityDetail: {
          data: {},
          isFetching: false,
        },
      });
    case GET_EXECUTION_PROFILES:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              isListFetching: true,
            },
          },
        },
      });
    case GET_EXECUTION_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              profiles: payload.data,
              isListFetching: false,
            },
          },
        },
      });
    case GET_EXECUTION_PROFILES_FAIL:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              isListFetching: false,
            },
          },
        },
      });
    case GET_EXECUTION_PROFILE_DETAILS:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              activityExecutionProfileId: payload.data.profileId,
              isDetailsFetching: true,
            },
          },
        },
      });
    case GET_EXECUTION_PROFILE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              ...formatProfileDetailsByResponse(payload.data), // write a function to format data
              isDetailsFetching: false,
            },
          },
        },
      });
    case GET_EXECUTION_PROFILE_DETAILS_FAIL:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              isDetailsFetching: false,
            },
          },
        },
      });
    case SAVE_EXECUTION_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        stepDetail: {
          ...state.stepDetail,
          formValues: {
            ...state.stepDetail.formValues,
            executionProfileValue: {
              ...state.stepDetail.formValues.executionProfileValue,
              ...formatProfileDetailsByResponse(payload.data),
              isDetailsFetching: false,
            },
          },
        },
      });
    case GET_TEMPLATE_CATEGORIES:
      return Object.assign({}, state, {
        templateCategories: {
          data: [],
          isFetching: true,
        },
      });
    case GET_TEMPLATE_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        templateCategories: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_TEMPLATE_CATEGORIES_FAIL:
      return Object.assign({}, state, {
        templateCategories: {
          data: [],
          isFetching: false,
        },
      });
    case GET_EVENT_LIST:
      return Object.assign({}, state, {
        events: {
          ...state.events,
          isLoading: true,
        },
      });
    case GET_EVENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        events: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_EVENT_LIST_FAIL:
      return Object.assign({}, state, {
        events: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
