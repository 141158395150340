import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { MoreOutlined } from "@ant-design/icons";
import Common from "us.common";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { manageFollowupActions } from "us.collection.admin/actions";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  ContextMenuAction,
  DefaultCategory,
} from "us.collection.admin/constants";
import { $DateLabel, $Drawer, $Empty } from "us.common/components";
import { ContextMenu, FollowupResult } from "./Components";
import { IDrawer } from "./Interfaces";
import * as Azure from "us.helper/azure";
import { setTableData } from "./Functions";
import { parse, stringify } from "query-string";
import { removeSearchParams } from "us.collection.admin/functions";

const { followups, followupResult, followUpDetails } = manageFollowupActions;
const { $Skeleton, $Popover, $TableTree, $Button } = Common.Components;

/**
 * @description Manage Followup - Followup Table
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942926869/Manage+Follow-up+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 04/04/2022
 */
const Followups: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { categoryId = DefaultCategory.categoryID } =
    useParams<{ categoryId?: string }>();
  const [popoverVisibleId, setPopoverVisibleId] = useState<number>(-1);
  const [drawer, setDrawer] = useState<IDrawer>({
    visible: false,
  });
  const [isCreatedByMe, setIsCreatedByMe] = useState<boolean>(false);
  const searchParams = parse(location.search);
  const {
    getFollowups,
    list,
    removeFollowup,
    resetFollowupResult,
    getFollowUpDetails,
  } = props;

  const currentUser: any = (
    window._ENV.REACT_APP_AZURE_AD_SETUP === true
      ? new Azure.ADAuth()
      : new Azure.B2CAuth()
  ).currentUser();

  const { data, isFetching } = list;

  const isSelfCreated: boolean =
    searchParams["self-created"] && searchParams["self-created"] == "true"
      ? true
      : false;

  useEffect(() => {
    if (categoryId === DefaultCategory.categoryID) {
      updateDefaultURL();
    } else {
      searchParams["self-created"] ?? addSearchParams("self-created", "false");
    }
    getFollowupsByCategoryId(categoryId);
  }, [categoryId]);

  useEffect(() => {
    setIsCreatedByMe(isSelfCreated);
  }, [isSelfCreated]);

  /**
   * @function
   * @description add search params
   * @param location location object
   * @param {string} field key of search param
   * @param {string} value value of search param
   */
  const addSearchParams = (field: string, value: string) => {
    const previousSearchParams = parse(location.search);
    const newSearchParams = stringify({
      ...previousSearchParams,
      [field]: value,
    });
    history.replace({ search: newSearchParams });
  };

  /**
   *@function
   *@description update Default URL and search params
   */
  const updateDefaultURL = () => {
    history.replace({
      pathname: `/manage-followup/${categoryId}`,
      search: stringify({ "self-created": "false", ...searchParams }),
    });
  };

  /**
   * @function
   * @description get Followups according to categoryId
   * @param {string} categoryId selected categoryId
   */
  const getFollowupsByCategoryId = (categoryId: string): void => {
    getFollowups &&
      getFollowups({
        categoryId:
          categoryId.toLowerCase() == DefaultCategory.categoryID
            ? -1
            : categoryId,
        isUserFollowup: false,
      });
  };

  /**
   * @function
   * @description Handle user click action
   * @param {ContextMenuAction} actionName clicked action name
   * @param record user select followup record
   */
  const onActionClick = (actionName: ContextMenuAction, record: any): void => {
    const { followupId } = record;
    try {
      switch (actionName) {
        case ContextMenuAction.VIEW_RESULT:
          setDrawer({ visible: true });
          addSearchParams("followup-id", followupId);
          break;
        case ContextMenuAction.EDIT:
          getFollowUpDetails && getFollowUpDetails({ followupId, history });
          break;
        case ContextMenuAction.DELETE:
          removeFollowup && removeFollowup({ followupId });
          break;
        default:
          break;
      }
    } catch (error) {
    } finally {
      setPopoverVisibleId(-1);
    }
  };

  /**
   * @function
   * @description Table data sort
   * @param sortData
   * @param {Array<any>} dataSource - table data
   * @returns sorted data
   */
  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  /**
   * @function
   * @description Table data filter
   * @param sortData
   * @param {Array<any>} dataSource - table data
   * @returns filtered data
   */
  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };

  /**
   * @function
   * @description drawer close function
   */
  const onCancel = () => {
    setDrawer({ visible: false });
    resetFollowupResult && resetFollowupResult({});
    removeSearchParams(location, "followup-id");
  };

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: 50,
      customRenderChild: (text: any, record: any) => {
        const { followupType } = record;
        return (
          <$Popover
            placement="right"
            content={
              <ContextMenu
                onClick={(actionName: ContextMenuAction) => {
                  onActionClick(actionName, record);
                }}
                followupType={followupType}
              />
            }
            destroyTooltipOnHide
            trigger="click"
            visible={record.followupId == popoverVisibleId}
            onVisibleChange={(visible: boolean) => {
              setPopoverVisibleId(visible ? record.followupId : -1);
            }}
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:BULKOPERATION.DISPLAY_NAME"),
      dataIndex: "followupDisplayName",
      key: "followupDisplayName",
      ellipsis: true,
      width: 220,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.ADMIN:BULKOPERATION.ENTITY"),
      dataIndex: "entityType",
      key: "entityType",
      ellipsis: true,
      width: 120,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.ADMIN:BULKOPERATION.TYPE"),
      dataIndex: "followupType",
      key: "followupType",
      ellipsis: true,
      width: 160,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.ADMIN:BULKOPERATION.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      width: 260,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COLLECTION.ADMIN:BULKOPERATION.CREATED_ON"),
      dataIndex: "createdDate",
      key: "createdDate",
      width: 260,
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: "date",
      customRenderChild: (text: any, record: any) => {
        const { createdUser, createdDate } = record;
        return (
          <>
            {createdUser && (
              <span className="d-block">
                <$DateLabel value={createdDate} />
              </span>
            )}
            {createdUser && (
              <small title={createdUser} className="bui-label">
                {t("US.COLLECTION.ADMIN:BULKOPERATION.BY")}
                {" - "}
                {createdUser}
              </small>
            )}
          </>
        );
      },
    },
  ];

  return (
    <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
      {data.length === 0 && !isFetching && (
        <$Empty
          className="pt-5"
          description={`${t("US.COLLECTION.ADMIN:BULKOPERATION.NO_DATA")}`}
        />
      )}
      {(data.length > 0 || isFetching) && (
        <$TableTree
          rowKey="followupId"
          data={setTableData(data, isCreatedByMe, currentUser?.unique_name)}
          size="small"
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {
                onActionClick(ContextMenuAction.EDIT, record);
              },
            };
          }}
          className=""
          onSort={handleSort}
          onFilter={handleFilter}
          filterOnType={true}
          resetOnSourceChange={true}
          bordered
          pagination={{ defaultPageSize: 20 }}
          scroll={{ x: 600, y: "calc(100vh - 230px)" }}
          columns={columns}
        />
      )}
      <$Drawer
        title={t("US.COLLECTION.ADMIN:BULKOPERATION.FOLLOWUP_RESULT")}
        width={"90%"}
        visible={drawer.visible}
        onClose={onCancel}
        destroyOnClose
      >
        <FollowupResult />
        <div className="drawer-footer-fixed align-content-center">
          <div className="ml-auto">
            <$Button onClick={onCancel}>
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </div>
      </$Drawer>
    </$Skeleton>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { list } = manageFollowups;
  return {
    list,
  };
};

const mapDispatchToProps = {
  getFollowups: followups.get,
  removeFollowup: followups.remove,
  resetFollowupResult: followupResult.reset,
  getFollowUpDetails: followUpDetails.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Followups);
