import React from 'react';
import { AutoComplete, Form } from 'antd';
import { useField } from 'formik';
import * as Utility from './utility';
import { useTranslation } from 'react-i18next';

export const $AutoComplete = React.forwardRef(
	({ name, label, formItem, onPressEnter, ...rest }: any, ref: any) => {
		const [field, meta, helpers] = useField(name);
		const { t } = useTranslation();

		const error = meta?.error;
		const isTouched = meta?.touched;

		return (
			<Form.Item
				label={label}
				{...formItem}
				help={isTouched && error && t(error as string)}
				validateStatus={Utility.validateStatus(
					error,
					isTouched
				)}>
				<AutoComplete
					ref={ref}
					{...rest}
					{...field}
					onChange={(e: any) => {
						typeof rest?.onChange ===
						'function'
							? rest?.onChange(e)
							: helpers.setValue(e);
						helpers.setTouched(true);
					}}
				/>
			</Form.Item>
		);
	}
);
