import React, { useState, useRef } from 'react';
import {  Modal, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import './WebCam.less';
const pkg = 'web-cam';
const MEDIA_CLASS = `${pkg}-media`;

const noop = () => {};
const ZOOM_STEP = 0.1;

const MIN_ROTATE = 0;
const MAX_ROTATE = 360;
const ROTATE_STEP = 1;

const EasyCrop = ((props:any) => {
  const {
    src,
    aspect,
    shape,
    grid,

    hasZoom,
    zoomVal,
    rotateVal,
    setZoomVal,
    setRotateVal,

    minZoom,
    maxZoom,
    onComplete,

    cropperProps,
  } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
  

  const onCropComplete = React.useCallback(
    (croppedArea, croppedAreaPixels) => {
      onComplete(croppedAreaPixels);
    },
    [onComplete]
  );

  const onMediaLoaded = React.useCallback(
    (mediaSize) => {
      const { width, height } = mediaSize;
      const ratioWidth = height * aspect;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspect });
      }
    },
    [aspect]
  );

  return (
    <Cropper
      {...cropperProps}
     // ref={ref}
      image={src}
      crop={crop}
      cropSize={cropSize}
      onCropChange={setCrop}
      aspect={aspect}
      cropShape={shape}
      showGrid={grid}
      zoomWithScroll={hasZoom}
      zoom={zoomVal}
      rotation={rotateVal}
      onZoomChange={setZoomVal}
      onRotationChange={setRotateVal}
      minZoom={minZoom}
      maxZoom={maxZoom}
      onCropComplete={onCropComplete}
      onMediaLoaded={onMediaLoaded}
      classes={{ containerClassName: `${pkg}-container`, mediaClassName: MEDIA_CLASS }}
    />
  );
});

interface IEasyCrop {
  src?: string,
  aspect: number,
  shape: string,
  grid: boolean,

  hasZoom: boolean,
  zoomVal: number,
  rotateVal: boolean,
  setZoomVal: any,
  setRotateVal: any,

  minZoom: number,
  maxZoom: number,
  onComplete: any,

  cropperProps: object,
};


const WebCam = (props:any) => {
  const { t } = useTranslation();

  const webcamRef = React.useRef<any>(null);
  const [imgSrc, setImgSrc] = React.useState<any>(null);

  const [zoomVal, setZoomVal] = useState<number>(1);
  const [rotateVal, setRotateVal] = useState(0);


  const cropPixelsRef = useRef<any>();
  const beforeUploadRef = useRef<any>();
  const resolveRef = useRef<any>(noop);
  const rejectRef = useRef<any>(noop);

  const fileRef = useRef<any>();
  const hasZoom = true;
  
  const hasRotate = true;
  const quality = 0.4;
  const fillColor= 'white';
  const minZoom= 1;
  const maxZoom= 3;

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);


  const onClose = () => {
    setImgSrc(null);
    setZoomVal(1); 
    setRotateVal(0);
    props.onCancel()
  };

    /**
   * EasyCrop
   */
  const onComplete = React.useCallback((croppedAreaPixels) => {
    cropPixelsRef.current = croppedAreaPixels;
  }, []);

   /**
   * Controls
   */
  const isMinZoom = zoomVal - ZOOM_STEP < minZoom;
  const isMaxZoom = zoomVal + ZOOM_STEP > maxZoom;
  const isMinRotate = rotateVal === MIN_ROTATE;
  const isMaxRotate = rotateVal === MAX_ROTATE;

  const subZoomVal = React.useCallback(() => {
    if (!isMinZoom) setZoomVal(zoomVal - ZOOM_STEP);
  }, [isMinZoom, zoomVal]);

  const addZoomVal = React.useCallback(() => {
    if (!isMaxZoom) setZoomVal(zoomVal + ZOOM_STEP);
  }, [isMaxZoom, zoomVal]);

  const subRotateVal = React.useCallback(() => {
    if (!isMinRotate) setRotateVal(rotateVal - ROTATE_STEP);
  }, [isMinRotate, rotateVal]);

  const addRotateVal = React.useCallback(() => {
    if (!isMaxRotate) setRotateVal(rotateVal + ROTATE_STEP);
  }, [isMaxRotate, rotateVal]);

  const onOk = React.useCallback(async () => {
    const naturalImg:any = document.querySelector(`.${MEDIA_CLASS}`);
    const { naturalWidth, naturalHeight } = naturalImg;
    const canvas = document.createElement('canvas');
    const ctx:any = canvas.getContext('2d');

    // create a max canvas to cover the source image after rotated
    const maxLen = Math.sqrt(Math.pow(naturalWidth, 2) + Math.pow(naturalHeight, 2));
    canvas.width = maxLen;
    canvas.height = maxLen;

    // rotate the image
    if (hasRotate && rotateVal > 0 && rotateVal < 360) {
      const halfMax = maxLen / 2;
      ctx.translate(halfMax, halfMax);
      ctx.rotate((rotateVal * Math.PI) / 180);
      ctx.translate(-halfMax, -halfMax);
    }

    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // draw the source image in the center of the max canvas
    const left = (maxLen - naturalWidth) / 2;
    const top = (maxLen - naturalHeight) / 2;
    ctx.drawImage(naturalImg, left, top);

    // shrink the max canvas to the crop area size, then align two center points
    const maxImgData = ctx.getImageData(0, 0, maxLen, maxLen);
    const { width, height, x, y } = cropPixelsRef.current;
    canvas.width = width;
    canvas.height = height;
    ctx.putImageData(maxImgData, Math.round(-left - x), Math.round(-top - y));

    // get the new image
    //const { type, name, uid } = fileRef.current;
    canvas.toBlob(
      async (blob:any) => {
        setImgSrc(null);
        setZoomVal(1); 
        setRotateVal(0);
        props.onPressOk(blob)
      },
      'image/jpeg',
      quality
    );
  }, [hasRotate, quality, rotateVal]);

  return (
    <div>
      <Modal
        visible={props.isVisible}
        wrapClassName={`${pkg}-modal`}
        title={!imgSrc ? t('COMMON.WEBCAM') : t('COMMON.EDIT_IMAGE')}
        onOk={!imgSrc ? capture : onOk}
        onCancel={onClose}
        maskClosable={false}
        destroyOnClose
        okText={!imgSrc ? t('COMMON.CAPTURE') : t('COMMON.OK')}
        cancelText={t('COMMON.CANCEL')}
        //   {...modalProps}
      > 
        {imgSrc && 
            <EasyCrop
           // ref={ref}
            src={imgSrc}
            aspect={1}
            shape={'rect'}
            grid={false}
            hasZoom={true}
            zoomVal={zoomVal}
            rotateVal={rotateVal}
            setZoomVal={setZoomVal}
            setRotateVal={setRotateVal}
            minZoom={1}
            maxZoom={3}
            onComplete={onComplete}
           // cropperProps={cropperProps}
          />
        }
        {!imgSrc && (
          <Webcam
            width={470}
            height={300}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
        )}

        {imgSrc && (
          <div className={`${pkg}-control zoom`}>
            <button onClick={subZoomVal} disabled={isMinZoom}>－</button>
            <Slider
                min={minZoom}
                max={maxZoom}
                step={ZOOM_STEP}
                value={zoomVal}
                onChange={setZoomVal}
              />
            <button onClick={addZoomVal} disabled={isMaxZoom}>＋</button>
          </div>
        )}
        {imgSrc && (
          <div className={`${pkg}-control rotate`}>
            <button onClick={subRotateVal} disabled={isMinRotate}>↺</button>
            <Slider
                min={MIN_ROTATE}
                max={MAX_ROTATE}
                step={ROTATE_STEP}
                value={rotateVal}
                onChange={setRotateVal}
              />
            <button onClick={addRotateVal} disabled={isMaxRotate}>↻</button>
          </div>
        )}
      </Modal>
    </div> 
  );
};
export default WebCam;
