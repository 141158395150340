export enum PaymentAgreement {
    GET_PAYMENT_AGREEMENT_AMOUNTS_START = "GET_PAYMENT_AGREEMENT_AMOUNTS_START",
    GET_PAYMENT_AGREEMENT_AMOUNTS_SUCCESS = "GET_PAYMENT_AGREEMENT_AMOUNTS_SUCCESS",
    GET_PAYMENT_AGREEMENT_AMOUNTS_FAIL = "GET_PAYMENT_AGREEMENT_AMOUNTS_FAIL",

    GET_PAYMENT_AGREEMENT_DETAIL_START="GET_PAYMENT_AGREEMENT_DETAIL_START",
    GET_PAYMENT_AGREEMENT_DETAIL_SUCCESS="GET_PAYMENT_AGREEMENT_DETAIL_SUCCESS",
    GET_PAYMENT_AGREEMENT_DETAIL_FAIL="GET_PAYMENT_AGREEMENT_DETAIL_FAIL",

    SAVE_PAYMENT_AGREEMENT_START="SAVE_PAYMENT_AGREEMENT_START",
    SAVE_PAYMENT_AGREEMENT_SUCCESS="SAVE_PAYMENT_AGREEMENT_SUCCESS",
    SAVE_PAYMENT_AGREEMENT_FAIL="SAVE_PAYMENT_AGREEMENT_FAIL",

    DELETE_PAYMENT_AGREEMENT_START="DELETE_PAYMENT_AGREEMENT_START",
    DELETE_PAYMENT_AGREEMENT_SUCCESS="DELETE_PAYMENT_AGREEMENT_SUCCESS",
    DELETE_PAYMENT_AGREEMENT_FAIL="DELETE_PAYMENT_AGREEMENT_FAIL",

    GET_PAYMENT_AGREEMENT_INTEREST_START="GET_PAYMENT_AGREEMENT_INTEREST_START",
    GET_PAYMENT_AGREEMENT_INTEREST_SUCCESS="GET_PAYMENT_AGREEMENT_INTEREST_SUCCESS",
    GET_PAYMENT_AGREEMENT_INTEREST_FAIL="GET_PAYMENT_AGREEMENT_INTEREST_FAIL",

    GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START = "GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START",
    GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_SUCCESS = "GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_SUCCESS",
    GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_FAIL = "GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_FAIL",
}