import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const state: any = {
  accessToken: null,
};

export const v1LoginRequest: RedirectRequest = {
  scopes: ["User.ReadWrite"],
};

export const msalConfigV1: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string, //,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${window.location.origin}` as string,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const authenticationADV1 = {
  run: <P extends {}>(token: P, callback: any) => {
    state.accessToken = token;
    callback();
  },
  getAccessToken: () => state.accessToken,
};

export default authenticationADV1;
