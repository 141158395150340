import { IInitialState } from "../Interfaces";
import { initialStepDetail } from "./InitialStepDetail";

export const initialState: IInitialState = {
  activities: {
    list: {
      data: [],
      dataSource: [],
      isFetching: false,
    },
  },
  activity: {
    entityTypes: {
      data: [],
      isFetching: false,
    },
    activityTypes: {
      data: [],
      isFetching: false,
    },
    activityGroups: {
      data: [],
      isFetching: false,
    },
  },
  stepDetail: initialStepDetail,
  drawer: {
    isVisible: false,
    isEdit: false,
  },
  breadCrumb: {
    isVisible: false,
    title: "",
  },
  states: {
    data: [],
    dataSource: [],
    isFetching: false,
  },
  followupCategories: {
    data: [],
    isFetching: false,
  },
  properties: {
    data: [],
    dataSource: [],
    extendedProperties: null,
    extendedField: {
      templateProperty: [],
      selectedPrintMode: [],
      availablePrinterList: [],
      updateDocumentBaseState: [],
    },
    isFetching: false,
  },
  parameters: {
    data: [],
    isFetching: false,
  },
  activityDetail: {
    data: {},
    isFetching: false,
  },
  events: {
    data: [],
    isLoading: false,
  },
  templateCategories: {
    data: [],
    isFetching: false,
  },
};
