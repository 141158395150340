/**
 * @function
 * @description Check if the date is a valid date in the format yyyy/mm/dd
 * @param dateString string containing a date representation
 * @returns if the dateString holds a valid date
 */
export const isValidDate = (dateString: string): boolean => {
  try {
    const regEx = /^\d{4}\/\d{2}\/\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    const [year, month, date] = dateString.split("/");
    return (
      d.getDate() === +date &&
      d.getMonth() + 1 === +month &&
      d.getFullYear() === +year
    );
  } catch (e) {
    return false;
  }
};
