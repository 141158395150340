import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IRootState } from 'us.collection/interfaces';
import {
	$Button,
	$TableTree,
	$Tooltip,
	ITableTreeColumns,
	$Skeleton,
} from 'us.common/components';
import { EditOutlined } from 'us.icons';
import { SettingsTableColumn, CATEGORY_TABLE_COLUMNS } from './Constants';
import { getColumnWidth } from './Functions';
import { ISettingsTable } from './Interfaces';
import { findSettingsByTypeId } from 'us.collection.admin/components/SystemSettings/Functions';
import { SensitiveLabel } from './Components';
import { SystemSetting } from 'us.collection.admin/interfaces';

/**
 * @description -  System settings home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2901967003/View+System+Settings
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 16/03/2022
 */
const SettingsTable: React.FC<ISettingsTable & PropsFromRedux> = (props) => {
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	const typeId = urlParams.get('type-id');

	const { systemSettings, onEdit } = props;
	const { isLoading, settings } = systemSettings;
	let generatingTableData = true;
	const tableData = findSettingsByTypeId(settings, typeId);
	generatingTableData = false;

	const { t } = useTranslation();

	/**
	 * @function
	 * @description Get table columns list
	 * @returns {ITableTreeColumns} - TableTree columns
	 */
	const getColumns = (): ITableTreeColumns => {
		const columns: ITableTreeColumns = [];

		CATEGORY_TABLE_COLUMNS.map(({ key, title }: any) => {
			const width = getColumnWidth(key);
			let column: any = {
				key: key,
				dataIndex: key,
				width,
			};
			if (key === SettingsTableColumn.MENU) {
				// configure menu column
				column = {
					...column,
					align: 'center',
					customRenderChild: (
						_text: any,
						record: any
					) => (
						<$Tooltip
							placement='top'
							title={t(
								'US.COLLECTION.COMMON:COMMON.EDIT'
							)}>
							<$Button
								onClick={() =>
									onEdit(
										record
									)
								}
								icon={
									<EditOutlined />
								}
								size='small'
								id='btn-edit-setting'
							/>
						</$Tooltip>
					),
				};
			} else if (key === SettingsTableColumn.VALUE) {
				// configure other columns
				column = {
					...column,
					title: title && t(title),
					ellipsis: { showTitle: false },
					customSorter: (a: any, b: any) => {
						return a.localeCompare(b);
					},
					customFilter: true,
					customRenderChild: (
						text: any,
						record: any
					) => (
						<SensitiveLabel
							value={record[key]}
							masked={
								record.isSensitiveData
							}
						/>
					),
				};
			} else {
				// configure other columns
				column = {
					...column,
					title: title && t(title),
					ellipsis: { showTitle: false },
					customSorter: (a: any, b: any) => {
						return a.localeCompare(b);
					},
					customFilter: true,
					customRenderChild: (
						text: any,
						record: any
					) => (
						<$Tooltip
							placement='topLeft'
							title={record[key]}>
							{record[key]}
						</$Tooltip>
					),
				};
			}
			// add configured column data object to the columns array
			columns.push(column);
		});

		return columns;
	};

	return (
		<$Skeleton
			loading={isLoading || generatingTableData}
			active
			paragraph={{
				rows: 2,
			}}>
			<$TableTree
				rowKey={(record: SystemSetting) => record.id}
				data={tableData}
				size='small'
				className=''
				onSort={(sortData, dataSource) => {
					return sortData(dataSource);
				}}
				onFilter={(searchData, dataSource) => {
					return searchData(dataSource);
				}}
				onRow={(record, _rowIndex) => {
					return {
						onDoubleClick: (_event) =>
							onEdit(record),
					};
				}}
				filterOnType={true}
				resetOnSourceChange={true}
				bordered
				pagination={{
					defaultPageSize: 20,
				}}
				scroll={{
					x: 900,
					y: 'calc(100vh - 265px)',
				}}
				columns={getColumns()}
				data-testid='settings-table'
			/>
		</$Skeleton>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { systemSettings, settingUpdate } = state.systemSettings;
	return { systemSettings, settingUpdate };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsTable);
