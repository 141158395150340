import { ISearchQuery } from "us.common/interfaces";
import QueryString from "query-string";

/**
 * @function
 * @description To get the search URL query value parsed to ISearchQuery type object
 * @param parsedValue The string parsed search query value
 * @returns The ISearchQuery formatted object of search query value
 */
export const getQueryStringParsed = (
  parsedValue: QueryString.ParsedQuery<string>
): ISearchQuery => {
  const keys: string[] = Object.keys(parsedValue);
  let parsedQuery: any = {};
  keys.forEach((x) => (parsedQuery[x] = parsedValue[x]));
  return parsedQuery as ISearchQuery;
};
