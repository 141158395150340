import { CreditorSubCriterias } from "us.common/constants";
import { ILabeledValue } from "us.common/interfaces";

/**
 * @function
 * @description It takes an enum and returns an array of objects with the enum's values as the label, value, and key
 * @returns An array of objects with the following properties:
 * label: string
 * value: string
 * key: string
 */
export const getCreditorSubCriterias =
  (): ILabeledValue<CreditorSubCriterias>[] => {
    try {
      return (
        Object.values(CreditorSubCriterias) as Array<CreditorSubCriterias>
      ).map((value: any) => ({
        label: value,
        value: value,
        key: value,
      }));
    } catch (e) {
      return [];
    }
  };
