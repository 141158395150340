import { DynamicComponent } from "us.common/constants";

export const DATA_ITEM = {
  name: "",
  isNew: false,
  componentType: DynamicComponent.Label,
};

export const ItemTypes = {
  SEQUENCE: 'sequence',
};

export const SelectProps = {
  options: [],
  optionText: '',
  optionValue: '',
  onSearchBy: []
}

export const TypeCode = {
  ITEM_TYPE: 'IT',
  TRANSACTION_GROUP: 'TG',
}

export const Action = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  DESELECT: 'DESELECT',
}