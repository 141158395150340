/**
 * @function
 * @description - validation function for the primary column check
 * @param {string} query - sql query
 * @param {string} primaryCol - primary column of sql rule
 * @returns
 */
export const validatePrimaryCol = (
  query: string,
  primaryCol: string
): boolean => {
  try {
    const finalQuery = typeof query == "undefined" ? "" : query;
    const selectWithField = finalQuery.split(",")[0];
    const selectStartIndex = selectWithField.toLowerCase().indexOf("select");

    return selectStartIndex > -1 && wordInString(selectWithField, primaryCol);
  } catch (error) {
    return true;
  }
};

/**
 * @function
 * @description - check the a particular word is in the sentence
 * @param {string} sentence whole sentence
 * @param {string} word single word
 * @returns
 */
const wordInString = (sentence: string, word: string) =>
  new RegExp("\\b" + word + "\\b", "i").test(sentence);

/**
 * @function
 * @description - validation function for the unauthorized SQL syntax
 * @param {string} query - sql query
 * @returns
 */
export const validateForUnauthorizedSyntax = (query: string): boolean => {
  try {
    const unauthorizedSyntax = [
      "DELETE",
      "UPDATE",
      "INSERT INTO",
      "DROP",
      "TRUNCATE",
    ];
    return (
      !unauthorizedSyntax.some((keyword: string) =>
        wordInString(query, keyword)
      ) &&
      query?.toUpperCase()?.includes("SELECT") 
    );
  } catch (error) {
    return true;
  }
};
