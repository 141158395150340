import { Role } from "us.common/constants";
import { deleteExistingRole, getRoleDetail, setRoleActivityStatusAsAString, updateExistingRoles } from "us.common/functions";
import { initialState } from "./State";
import { roleDetail } from "./State/RoleDetail";

const {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  MANAGE_ROLE_DRAWER,
  GET_ROLE_MODULES_AND_FEATURES_START,
  GET_ROLE_MODULES_AND_FEATURES_SUCCESS,
  GET_ROLE_MODULES_AND_FEATURES_FAIL,
  GET_ROLE_BY_ID_START,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  RESET_ROLE_FORM,
  GET_ROLE_HISTORY_BY_ID_START,
  GET_ROLE_HISTORY_BY_ID_SUCCESS,
  GET_ROLE_HISTORY_BY_ID_FAIL,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  ROLE_STATE_CHANGE_SUCCESS
} = Role;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_ROLES_START:
      return Object.assign({}, state, {
        roles: {
          data: [],
          isLoading: true,
        },
      });
    case GET_ROLES_SUCCESS:
      return Object.assign({}, state, {
        roles: {
          data: setRoleActivityStatusAsAString(payload?.data),
          isLoading: false,
        },
      });
    case GET_ROLES_FAIL:
      return Object.assign({}, state, {
        roles: {
          data: [],
          isLoading: false,
        },
      });
    case MANAGE_ROLE_DRAWER:
      return Object.assign({}, state, {
        drawer: payload?.data
      });
    case GET_ROLE_MODULES_AND_FEATURES_START:
      return Object.assign({}, state, {
        modules: {
          data: [],
          isFetching: true,
        },
      });
    case GET_ROLE_MODULES_AND_FEATURES_SUCCESS:
      return Object.assign({}, state, {
        modules: {
          data: payload?.data,
          isFetching: false,
        },
      });
    case GET_ROLE_MODULES_AND_FEATURES_FAIL:
      return Object.assign({}, state, {
        modules: {
          data: [],
          isFetching: false,
        },
      });
    case GET_ROLE_BY_ID_START:
      return Object.assign({}, state, {
        roleDetail: {
          detail: roleDetail,
          isFetching: true
        }
      });
    case GET_ROLE_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        roleDetail: {
          detail: getRoleDetail(payload?.data),
          isFetching: false
        }
      });
    case GET_ROLE_BY_ID_FAIL:
      return Object.assign({}, state, {
        roleDetail: {
          detail: roleDetail,
          isFetching: false
        },
      });
    case RESET_ROLE_FORM:
      return Object.assign({}, state, {
        roleDetail: {
          detail: roleDetail,
          isFetching: false
        },
      });
    case GET_ROLE_HISTORY_BY_ID_START:
      return Object.assign({}, state, {
        roleHistory: {
          data: [],
          isFetching: true
        }
      });
    case GET_ROLE_HISTORY_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        roleHistory: {
          data: payload?.data,
          isFetching: false
        }
      });
    case GET_ROLE_HISTORY_BY_ID_FAIL:
      return Object.assign({}, state, {
        roleHistory: {
          data: [],
          isFetching: false
        }
      });
    case UPDATE_ROLE_SUCCESS:
      return Object.assign({}, state, {
        roles: {
          data: setRoleActivityStatusAsAString(updateExistingRoles(state.roles.data, payload?.data)),
          isLoading: false,
        },
      });
    case DELETE_ROLE_SUCCESS:
      return Object.assign({}, state, {
        roles: {
          data: setRoleActivityStatusAsAString(deleteExistingRole(state.roles.data, payload?.data)),
          isLoading: false,
        },
      });
    case ROLE_STATE_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        roles: {
          data: setRoleActivityStatusAsAString(updateExistingRoles(state.roles.data, payload?.data)),
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
