import * as Yup from "yup";

interface AddPostal extends Yup.MixedSchema {
    PostalCode: string;
    SelectedMunicipalityName: string;
    PostalPlace: string;
    SelectedMunicipalityCode: string;
    CityAreaNo: string;
}

export const validationSchema = Yup.object<AddPostal>().shape({
    postalCode: Yup.number()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD")
        .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"),
    postalPlace: Yup.string()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD")
        .test(
            "postalPlaceDigit",
            "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
            (val: any) => {
                let value = typeof val == "undefined" ? "" : val;
                return !/^\d+$/.test(value);
            }
        ),
});