import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { $Radio, $AmountLabel } from "us.common/components";
import { cumulativeCreditAmount } from "us.common/components/CreditNote/Functions";
import { ICreditOption } from "us.common/components/CreditNote/Interface";
import { CreditOptions } from "us.common/components/CreditNote/Constants";
import { InfoCircleOutlined } from "us.icons";

interface IOption {
  value: string;
  label: string;
  render: any;
}

const renderOption = (item: any) => {
  return item.render;
};

const CreditOption = ({ values, invoiceDetails, disabled }: ICreditOption) => {
  const { t } = useTranslation();
  const [field, , helper] = useField("creditOption");
  const [fieldCN, , helperCN] = useField("creditNoteDetails");

  const { balance, amount, creditedAmount } = invoiceDetails;

  useEffect(() => {
    (field.value === CreditOptions.ENTIRE_INVOICE_AMOUNT ||
      field.value === CreditOptions.ENTIRE_INVOICE_BALANCE) &&
      cumulativeCreditAmount(field.value, values, helperCN);
  }, [fieldCN.value]);

  // generate available options
  const getOptions = (): Array<IOption> => {
    const options: Array<IOption> = [];

    if (balance == amount) {
      options.push({
        value: CreditOptions.ENTIRE_INVOICE_AMOUNT,
        label: t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT"),
        render: (
          <div className="d-flex pl-2 flex-fill justify-content-between">
            <div>{t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT")}</div>
            <div className="font-weight-bold">
              <$AmountLabel value={amount} />
            </div>
          </div>
        ),
      });

      options.push({
        value: CreditOptions.EACH_ARTICLES,
        label: t("US.COMMON:CREDIT_NOTE.CREDIT_BY_EACH_ARTICLES"),
        render: (
          <div className="d-flex pl-2 flex-fill justify-content-between">
            <div>{t("US.COMMON:CREDIT_NOTE.CREDIT_BY_EACH_ARTICLES")}</div>
          </div>
        ),
      });
    } else {
      creditedAmount >= 0 &&
        options.push({
          value: CreditOptions.ENTIRE_INVOICE_AMOUNT,
          label: t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT"),
          render: (
            <div className="d-flex pl-2 flex-fill justify-content-between">
              <div>
                {t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT")}
              </div>
              <div className="font-weight-bold">
                <$AmountLabel value={amount} />
              </div>
            </div>
          ),
        });

      creditedAmount < 0 &&
        options.push({
          value: CreditOptions.ENTIRE_INVOICE_AMOUNT,
          label: t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT"),
          render: (
            <div className="d-flex pl-2 flex-fill justify-content-between">
              <div>
                {t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_AMOUNT")}
              </div>
              <div className="flex-fill">
              <div className=" d-flex justify-content-end">
                {amount - Math.abs(creditedAmount) !== 0 && (
                 <>
                   <div className="d-flex text-primary mr-4">
                      <div className="mr-1">
                        <InfoCircleOutlined />
                      </div>
                      <div className="mr-1 text-nowrap">
                        {t(
                          "US.COMMON:CREDIT_NOTE.THIS_INVOICE_HAS_A_CREDITED_AMOUNT_OF"
                        )} {" "}
                        <$AmountLabel value={Math.abs(creditedAmount)} />
                      </div>
                   </div>
                 </>
                )}
                <div className="font-weight-bold text-right">
                  <$AmountLabel value={amount - Math.abs(creditedAmount)} />
                </div>
                 </div>
                
              </div>
            </div>
          ),
        });

      if (balance !== 0) {
        options.push({
          value: CreditOptions.ENTIRE_INVOICE_BALANCE,
          label: t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_BALANCE"),
          render: (
            <div className="d-flex pl-2 flex-fill justify-content-between">
              <div>
                {t("US.COMMON:CREDIT_NOTE.CREDIT_ENTIRE_INVOICE_BALANCE")}
              </div>
              <div className="font-weight-bold">
                <$AmountLabel value={values?.balance} />
              </div>
            </div>
          ),
        });
      }

      options.push({
        value: CreditOptions.EACH_ARTICLES,
        label: t("US.COMMON:CREDIT_NOTE.CREDIT_BY_EACH_ARTICLES"),
        render: (
          <div className="d-flex pl-2 flex-fill justify-content-between">
            <div>{t("US.COMMON:CREDIT_NOTE.CREDIT_BY_EACH_ARTICLES")}</div>
          </div>
        ),
      });
    }

    return options;
  };

  return (
    <$Radio
      {...field}
      defaultValue="1"
      value={field?.value}
      className="d-flex flex-fill flex-column m-0"
      optionText="label"
      optionValue="value"
      name="creditOption"
      optionStyle="radio-st-contnet"
      options={getOptions()}
      optionContent={renderOption}
      disabled={disabled}
      id="creditOption"
      onChange={(e: any) => {
        cumulativeCreditAmount(e.target.value, values, helperCN);
        field.onChange(e);
        helper.setValue(e.target.value);
      }}
    />
  );
};

export default CreditOption;
