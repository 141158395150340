import { MainCriterias } from "us.common/constants";
import { ILabeledValue } from "us.common/interfaces";

/**
 * @function
 * @description It takes an enum and returns an array of objects with the enum values as the label, value, and key
 * @returns An array of objects with the following properties:
 * label: string
 * value: string
 * key: string
 */
export const getMainSearchCriterias = (): ILabeledValue<MainCriterias>[] => {
  try {
    return (Object.values(MainCriterias) as Array<MainCriterias>).map(
      (value) => ({
        label: value,
        value: value,
        key: value,
      })
    );
  } catch (e) {
    return [];
  }
};
