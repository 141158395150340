import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
  HubConnection,
} from '@microsoft/signalr';
import { httpCollection } from 'us.helper/http/collection';

export default (
  onStart = () => {},
  onError = (error: any) => {},
  onConnect = (connection: HubConnection) => {}
) => {
  negotiate()
    .then((response) => {
      const { accessToken, url } = response.data;
      const connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Debug)
        .withUrl(url, {
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => {
            return accessToken;
          },
          headers: {
            'x-ms-signalr-user-id': '222',
            'x-ms-signalr-company-id': '182',
            'x-ms-signalr-gym-id': "1"
          },
        })
        .withAutomaticReconnect()
        .build();

      try {
        connection
          .start()
          .then(() => {
            onStart();
            onConnect(connection);
          })
          .catch(onError);
      } catch (error) {
        console.error(error);
        onError(error);
      }

      return connection;
    })
    .catch((error) => onError(error));

  function negotiate() {
    return httpCollection.get('notificationservice', `negotiate`, {});
  }
};
