import * as Yup from "yup";
import {
  validatePrimaryCol,
  validateForUnauthorizedSyntax,
} from "../Functions";
import { ICustomQuery } from "../Interfaces";

const QuerySpaceSchema = Yup.object<ICustomQuery>().shape({
  primaryColumnName: Yup.string(),
  isStaticWorkFlow: Yup.boolean(),
  customQuery: Yup.string()
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_A_VALUE_TO_THE_WORKSPACE"
    )
    .test(
      "customQuery",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ENTER_A_SELECT_QUERY",
      (customQuery: any) => validateForUnauthorizedSyntax(customQuery)
    )
    .when(["primaryColumnName"], (primaryColumnName: any, Yup: any) =>
      Yup.test(
        "customQuery",
        "US.COLLECTION.VALIDATIONS:INVALID.FIRST_COLUMN_IS_NOT_VALID",
        (customQuery: string) =>
          validatePrimaryCol(customQuery, primaryColumnName)
      )
    ),
});

export default QuerySpaceSchema;