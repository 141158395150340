import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import {
  $Button,
  $Popconfirm,
  $Skeleton,
  $Switch,
  $Drawer,
  $Form,
  $Input,
  $Tabs,
  $Row,
  $Col,
  $TextArea,
  $Tree,
  $Divider,
} from "us.common/components";
import { Formik } from "formik";
import { roleManagementActions } from "us.common/actions";
import { IRoleModule } from "us.common/interfaces";
import { setTreeDataSource } from "./Functions";
import { ICheckedAction } from "./Interface";
import { SaveRole, SaveRoleHistory } from "./Repository";
import { RoleHistory } from "./Components";
import "./UserRoleSettings.scss";
import _ from "lodash";
import { ValidationSchema } from "./Validations";

const { role } = roleManagementActions;

const RoleDetails: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
    drawer,
    getRoleModules,
    getRoleDetailById,
    modules,
    roleDetail,
    addRole,
    updateRole,
    manageDrawer,
    resetForm,
  } = props;

  const { isNew, roleId } = drawer;
  const { data, isFetching } = modules;
  const { detail, isFetching: isDetailFetching } = roleDetail;
  const { checkedAction } = detail;

  const [checkActions, setCheckActions] =
    useState<ICheckedAction>(checkedAction);
  const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false);

  useEffect(() => {
    getRoleModules && getRoleModules({});
    !isNew && getRoleDetailById && getRoleDetailById({ roleId });
  }, []);

  useEffect(() => {
    setCheckActions(checkedAction);
  }, [checkedAction]);

  /**
   * @function
   * @description handle onCheck event
   * @param {React.Key[]} checkedKeysValue check key array
   */
  const onCheck = (checkedKeysValue: React.Key[], moduleId: number) => {
    try {
      setCheckActions((prevState) => ({
        ...prevState,
        [moduleId]: checkedKeysValue,
      }));
    } catch (error) {}
  };

  /**
   * @function
   * @description handleSubmit event
   * @param values form data
   */
  const handleSubmit = (values: any) => {
    try {
      const request = SaveRole.call(values, checkActions, isNew, roleId);
      if (isNew) {
        addRole && addRole(request);
      } else {
        const historyRequest = SaveRoleHistory.call(values, roleId);
        updateRole && updateRole({ request, historyRequest });
      }
    } catch (error) {}
  };

  /**
   * @function
   * @description handle drawer close event
   */
  const onClose = () => {
    manageDrawer &&
      manageDrawer({
        title: "",
        isNew: true,
        visible: false,
        roleId: 0,
      });
    resetForm && resetForm({});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...detail, status: "active", isEdit: !isNew }}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, handleSubmit, ...restProps }: any) => (
        <$Form onSubmit={handleSubmit}>
          <$Skeleton loading={isDetailFetching}>
            <div className="d-flex">
              <div className="d-flex flex-grow-1">
                <div className="d-flex flex-fill flex-column">
                  <div className="d-flex">
                    <div className="d-flex flex-1 mr-4">
                      <$Input
                        size="small"
                        required
                        label={t("US.COMMON:MANAGE_ROLES.ROLE_NAME")}
                        name="name"
                        value={values.name}
                        maxLength={90}
                        autoComplete="off"
                      />
                    </div>

                    <div className="d-flex flex-grow-1">
                      <div className="flex-fill">
                        <$Input
                          size="small"
                          label={t("US.COMMON:MANAGE_ROLES.DESCRIPTION")}
                          name="description"
                          value={values.description}
                          maxLength={600}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-1 align-items-top justify-content-end"
                style={{ width: "10rem" }}
              >
                <div>{t("COMMON.ACTIVE")}</div>
                <div className="ml-3">
                  <$Switch
                    name="activeState"
                    defaultChecked={true}
                    checked={values.activeState}
                    onChange={(checked: any) => {
                      restProps.setFieldValue("activeState", checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={isNew ? "role-tabs-wrap rtw-new" : "role-tabs-wrap"}>
              <$Skeleton loading={isFetching}>
                <$Tabs defaultActiveKey="Admin" tabPosition="left">
                  {data.map((module: IRoleModule) => {
                    const { displayName, id } = module;
                    return (
                      <$Tabs.TabPane
                        className="tab-pane-width"
                        tab={displayName}
                        key={id}
                      >
                        <div className="pt-1 pl-3">
                          <h2 className="sub-title mb-3">{displayName}</h2>
                          <div className="role-cap-tree">
                            <$Tree
                              className="pt-3"
                              checkable
                              defaultExpandAll
                              checkedKeys={checkActions[id]}
                              onCheck={(e: any, info: any) => onCheck(e, id)}
                              treeData={setTreeDataSource(module)}
                            />
                          </div>
                          
                        </div>
                      </$Tabs.TabPane>
                    );
                  })}
                </$Tabs>
              </$Skeleton>
            </div>
            {!isNew && (
              <>
                <div className="mt-4">
                  <$Row gutter={[24, 24]}>
                    <$Col span={24}>
                      <div className="flex-fill">
                        <$TextArea
                          style={{ width: "100%" }}
                          required
                          label={t("COMMON.COMMENT")}
                          name="comment"
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </div>
                    </$Col>
                  </$Row>
                </div>
              </>
            )}

            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                {!isNew && (
                  <>
                    <$Button
                      className="mr-3"
                      onClick={() => setIsHistoryVisible(true)}
                    >
                      {t("US.COMMON:MANAGE_ROLES.HISTORY")}
                    </$Button>
                    <$Divider type="vertical" className="my-3" />
                  </>
                )}
                <$Button
                  className="mr-2"
                  disabled={!restProps.isValid}
                  onClick={handleSubmit}
                  type="primary"
                >
                  {isNew ? t("COMMON.SAVE") : t("COMMON.UPDATE")}
                </$Button>
                {restProps.dirty && (
                  <$Popconfirm
                    title={
                      isNew
                        ? t(
                            "US.COMMON:MANAGE_ROLES.ROLE_CREATION_HAS_NOT_BEEN_COMPLETED_AND_YOUR_EXISTING_DATA_WILL_BE_LOST,DO_YOU_WISH_TO_CONTINUE_?"
                          )
                        : t(
                            "US.COMMON:MANAGE_ROLES.ROLE_EDIT_HAS_NOT_BEEN_COMPLETED_AND_YOUR_EXISTING_DATA_WILL_BE_LOST,DO_YOU_WISH_TO_CONTINUE_?"
                          )
                    }
                    placement="topLeft"
                    onConfirm={() => onClose()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <$Button>{t("COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {!restProps.dirty && (
                  <$Button onClick={() => onClose()}>
                    {t("COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </$Skeleton>
          <$Drawer
            title={t("US.COMMON:MANAGE_ROLES.HISTORY")}
            width={800}
            visible={isHistoryVisible}
            onClose={() => setIsHistoryVisible(false)}
          >
            <RoleHistory />
          </$Drawer>
        </$Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { roleManagement } = state;
  const { roles, drawer, modules, roleDetail } = roleManagement;
  return {
    roles,
    drawer,
    modules,
    roleDetail,
  };
};
const mapDispatchToProps = {
  getRoleModules: role.getModules,
  getRoleDetailById: role.get,
  addRole: role.save,
  updateRole: role.update,
  manageDrawer: role.openDrawer,
  resetForm: role.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RoleDetails);