import { versionedHttpCollection } from "us.helper/http/collection";
import { IFollowUpCategory, IFollowup } from "us.collection.admin/interfaces";
import { AllFollowupEntityTypeInterface } from "us.collection.admin/constants";

export default {
  /**
   * A namespace
   * @namespace categories
   */
  categories: {
    /**
     * Get all followUp categories
     * @returns {Promise} Promise object represents the API response
     */
    get: (): Promise<Array<IFollowUpCategory>> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "followupservice",
            `followupCategories`,
            {},
            "V1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    /**
     * Save followUp categories
     * @returns {Promise} Promise object represents the API response
     */
    save: <P extends { categoryName: string }>(
      params: P
    ): Promise<Array<IFollowUpCategory>> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "followupservice",
            `followupcategory`,
            { categoryName: params.categoryName },
            "V1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  /**
   * A namespace
   * @namespace list
   */
  list: {
    /**
     * Get all followUp list by CategoryId
     * @param params {number} categoryID - FollowUp CategoryId
     * @returns {Promise} Promise object represents the API response
     */
    get: <P extends { categoryId: number; isUserFollowup: boolean }>(
      params: P
    ): Promise<Array<IFollowup>> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { categoryId, isUserFollowup } = params;
          const { data, status } = await versionedHttpCollection.get(
            "followupservice",
            `followupListAdmin/${categoryId}/${isUserFollowup}`,
            {},
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  /**
   * @namespace
   * Remove followup
   */
  followup: {
    remove: <P extends { followupId: number }>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.patch(
            "followupservice",
            `followup`,
            params,
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  /**
   * A namespace
   * @namespace result
   */
  result: {
    /**
     * Get followUp result
     * @param params {number} followUpId - FollowUpId
     * @returns {Promise} Promise object represents the API response
     */
    get: <P extends { followupId: number }>(
      params: P,
      isHistory?: boolean
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "followupservice",
            `followupdata/${params.followupId}/${isHistory ? "true" : "false"}`,
            {},
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  /**
   * A namespace
   * @namespace customQuery
   */
  customQuery: {
    entityTypes: {
      /**
       * Get all entityTypes for customQuery
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: <P extends {}>(params: P): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "followupservice",
              "followupentitytypes",
              {},
              "v1"
            );
            if (status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    properties: {
      /**
       * Get properties for customQuery
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: <P extends { entityType: AllFollowupEntityTypeInterface }>(
        params: P
      ): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "followupservice",
              `followupproperty/${params?.entityType}`,
              {},
              "v1"
            );
            if (status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    result: {
      /**
       * Get result for customQuery
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: (params: string): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data } = await versionedHttpCollection.post(
              "followupservice",
              `followupresult`,
              { customQuery: params },
              "v1"
            );
            resolve(data);
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    activities: {
      /**
       * Get activities list for entityType
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: <P extends { entityType: AllFollowupEntityTypeInterface }>(
        params: P
      ): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "activityservice",
              `followupenabledactivities/${params.entityType}`,
              {},
              "v1"
            );
            if (status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    users: {
      /**
       * Get follow-up users
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: (): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "userservice",
              `users`,
              {},
              "v1"
            );
            if (status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    roles: {
      /**
       * Get follow-up user roles
       * @param params
       * @returns {Promise} Promise object represents the API response
       */
      get: (): Promise<Array<IFollowup>> => {
        return new Promise<any>(async (resolve, reject) => {
          try {
            const { data, status } = await versionedHttpCollection.get(
              "userservice",
              `roles`,
              {},
              "v1"
            );
            if (status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          } catch (error) {
            reject(error);
          }
        });
      },
    },
    /**
     * Save Custom Query Followup
     * @param params
     * @returns {Promise} Promise object represents the API response
     */
    save: (params: any): Promise<Array<IFollowup>> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "followupservice",
            `followup`,
            params,
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  /**
   * @namespace
   * Remove followup
   */
  followUpDetails: {
    get: <P extends { followupId: number }>(params: P): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "followupservice",
            `followup/${params.followupId}`,
            {},
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
