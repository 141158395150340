import { IAPIAction } from "us.collection/interfaces";
import { ManageActivities } from "us.collection.admin/constants";
import { IManageActivitiesAction } from "us.collection.admin/interfaces";

export const manageActivities: Readonly<IAPIAction & IManageActivitiesAction> =
  {
    activities: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_SUMMARY,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_SUMMARY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_SUMMARY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),

      search: (data) => ({
        type: ManageActivities.SEARCH_ACTIVITY_SUMMARY,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    activity: {
      setStep: (data) => ({
        type: ManageActivities.SET_STEP_DETAIL,
        payload: {
          data,
          isLoading: true,
        },
      }),
      init: (data) => ({
        type: ManageActivities.INIT_ACTIVITY,
        payload: {
          data,
          isLoading: true,
        },
      }),
      initSuccess: (data) => ({
        type: ManageActivities.INIT_ACTIVITY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      initFail: (error) => ({
        type: ManageActivities.INIT_ACTIVITY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),

      saveOrEdit: (data, isNew) => ({
        type: ManageActivities.SAVE_ACTIVITY,
        payload: {
          data,
          isNew,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: ManageActivities.SAVE_ACTIVITY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.SAVE_ACTIVITY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      delete: (data, replace, state) => ({
        type: ManageActivities.DELETE_ACTIVITY,
        payload: {
          data,
          replace,
          state,
          isLoading: true,
        },
      }),

      deleteSuccess: (data) => ({
        type: ManageActivities.DELETE_ACTIVITY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      deleteFail: (error) => ({
        type: ManageActivities.DELETE_ACTIVITY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      manageBreadCrumb: (data) => ({
        type: ManageActivities.MANAGE_BREADCRUMB,
        payload: {
          data,
          isLoading: true,
        },
      }),
      manageDrawer: (data) => ({
        type: ManageActivities.MANAGE_DRAWER,
        payload: {
          data,
          isLoading: true,
        },
      }),
    },
    states: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_STATES,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_STATES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_STATES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    properties: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PROPERTIES,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PROPERTIES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PROPERTIES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    parameters: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PARAMETERS,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PARAMETERS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_TYPE_PARAMETERS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    followupCategory: {
      save: (data) => ({
        type: ManageActivities.SAVE_ACTIVITY_FOLLOWUP_CATEGORY,
        payload: {
          data,
          isLoading: true,
        },
      }),
      success: (data) => ({
        type: ManageActivities.SAVE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.SAVE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
      delete: (data) => ({
        type: ManageActivities.DELETE_ACTIVITY_FOLLOWUP_CATEGORY,
        payload: {
          data,
          isLoading: true,
          replace: {},
          state: {},
        },
      }),
      deleteSuccess: (data) => ({
        type: ManageActivities.DELETE_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      deleteFail: (error) => ({
        type: ManageActivities.DELETE_ACTIVITY_FOLLOWUP_CATEGORY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    followupCategories: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_FOLLOWUP_CATEGORY,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_FOLLOWUP_CATEGORY_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_FOLLOWUP_CATEGORY_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    activityDetail: {
      get: (data) => ({
        type: ManageActivities.GET_ACTIVITY_DETAIL,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_ACTIVITY_DETAIL_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_ACTIVITY_DETAIL_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    executionProfiles: {
      get: (data) => ({
        type: ManageActivities.GET_EXECUTION_PROFILES,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_EXECUTION_PROFILES_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_EXECUTION_PROFILES_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    executionProfile: {
      get: (data) => ({
        type: ManageActivities.GET_EXECUTION_PROFILE_DETAILS,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_EXECUTION_PROFILE_DETAILS_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_EXECUTION_PROFILE_DETAILS_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),

      delete: (data) => ({
        type: ManageActivities.DELETE_EXECUTION_PROFILE,
        payload: {
          data,
          replace: {},
          state: {},
          isLoading: true,
        },
      }),

      deleteSuccess: (data) => ({
        type: ManageActivities.DELETE_EXECUTION_PROFILE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      deleteFail: (error) => ({
        type: ManageActivities.DELETE_EXECUTION_PROFILE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),

      saveOrEdit: (data, isNew) => ({
        type: ManageActivities.SAVE_EXECUTION_PROFILE,
        payload: {
          data,
          isNew,
          isLoading: true,
        },
      }),

      saveOrEditSuccess: (data) => ({
        type: ManageActivities.SAVE_EXECUTION_PROFILE_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      saveOrEditFail: (error) => ({
        type: ManageActivities.SAVE_EXECUTION_PROFILE_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
    template: {
      get: (data) => ({
        type: ManageActivities.GET_TEMPLATE_CATEGORIES,
        payload: {
          data:data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_TEMPLATE_CATEGORIES_SUCCESS,
        payload: {
          data:data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_TEMPLATE_CATEGORIES_FAIL,
        payload: {
          error:error,
          isLoading: false,
        },
      }),
    },
    eventList: {
      get: (data) => ({
        type: ManageActivities.GET_EVENT_LIST,
        payload: {
          data,
          isLoading: true,
        },
      }),

      success: (data) => ({
        type: ManageActivities.GET_EVENT_LIST_SUCCESS,
        payload: {
          data,
          isLoading: false,
        },
      }),

      fail: (error) => ({
        type: ManageActivities.GET_EVENT_LIST_FAIL,
        payload: {
          error,
          isLoading: false,
        },
      }),
    },
  };
