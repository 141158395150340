import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ICaseBalanceUpdate } from "./Interfaces";
import { $AmountLabel } from "us.common/components/antd";
import { Row } from "./Components";
import { calculateTotalValue } from "./Functions";
import "./CaseBalanceUpdate.scss";
import { BalanceTypes } from "./Constants";

/**
 * @description - Case Balance Update
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3040346132/Enforcement-Update+Case+Balance+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/07/2022
 */
const CaseBalanceUpdate: React.FC<ICaseBalanceUpdate> = (props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<any>();

  const { headerName = "", rows, accountSummary } = props;

  return (
    <div className="case-balance-update">
      <div className="dom-amt-list-table">
        {/* column headers */}
        <div className="list-table-row list-table-header">
          <div
            className="list-table-col ans-sentence"
            data-testid={"add-court-sentence-sentences"}
          >
            {headerName}
          </div>

          <div className="list-table-col ans-amount" />
          <div
            className="list-table-col ans-agreement"
            data-testid={"add-court-sentence-agreement"}
          >
            {t("US.COLLECTION.CASE:ADD_COURT_SENTENCE.AGREEMENT")}
          </div>
          <div
            className="list-table-col ans-from"
            data-testid={"add-court-sentence-interest-from"}
          >
            {t("US.COLLECTION.CASE:ADD_COURT_SENTENCE.INTEREST_FROM")}
          </div>
          <div
            className="list-table-col ans-till"
            data-testid={"add-court-sentence-interest-till"}
          >
            {t("US.COLLECTION.CASE:ADD_COURT_SENTENCE.INTEREST_TILL")}
          </div>
          <div
            className="list-table-col ans-rate"
            data-testid={"add-court-sentence-rate"}
          >
            {t("US.COLLECTION.CASE:ADD_COURT_SENTENCE.RATE")}
          </div>
        </div>
        {rows.map(
          ({
            label,
            amountLabelName,
            inputAmountName,
            interestFromName,
            interestToName,
            interestRateName,
            isInterestFromDisabled,
            isInterestToDisabled,
            isInterestRateDisabled,
          }) => (
            <Row
              key={label}
              label={label}
              amountLabelName={amountLabelName}
              inputAmountName={inputAmountName}
              interestFromName={interestFromName}
              interestToName={interestToName}
              interestRateName={interestRateName}
              values={values}
              accountSummary={accountSummary}
              isInterestFromDisabled={isInterestFromDisabled}
              isInterestToDisabled={isInterestToDisabled}
              isInterestRateDisabled={isInterestRateDisabled}
            />
          )
        )}
        {/* total value */}
        <div className="list-table-row ans-total">
          <div className="list-table-col text-nowrap">
            <strong data-testid={"add-court-sentence-sentence-total"}>
              {t("US.COLLECTION.CASE:ADD_COURT_SENTENCE.TOTAL")}
            </strong>
          </div>
          <div className="list-table-col text-right text-nowrap">
            <strong>
              <$AmountLabel
                value={calculateTotalValue(
                  accountSummary,
                  BalanceTypes.ACCOUNT_SUMMARY
                )}
              />
            </strong>
          </div>
          <div className="list-table-col text-right text-nowrap">
            <strong className="pr-2">
              <$AmountLabel
                value={calculateTotalValue(
                  values,
                  BalanceTypes.CASE_BALANCE,
                  rows
                )}
              />
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseBalanceUpdate;
