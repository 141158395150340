import _ from "lodash";
import { Mode, EVENT_ACTIVITY_TYPE } from "us.common/constants";
import { handleEndPointLocals, trimText } from "us.common/functions";
import { IActivity } from "us.common/interfaces";
import { IGroupingOptions, Mode as IMode } from "../Interfaces";

/**
 * @function
 * @description get suitable placeholder
 * @param {Mode} mode mode of activity execution bar
 * @param {boolean} isBulkExecutionEnabled
 * @param {boolean} hasActivities
 * @param {boolean} isFetchingActivities
 * @returns {string} place holder value
 */
export const getActivityPlaceholder = (
  mode: IMode,
  isBulkExecutionEnabled: boolean,
  hasActivities: boolean,
  isFetchingActivities: boolean
): string => {
  try {
    if (
      !isBulkExecutionEnabled &&
      !hasActivities &&
      !isFetchingActivities &&
      mode == Mode.Click
    ) {
      return handleEndPointLocals(
        "US.COMMON:ACTIVITY_EXECUTION.NO_HANDLING_ACTIVITIES_TO_EXECUTE"
      );
    } else {
      return handleEndPointLocals("US.COMMON:ACTIVITY_EXECUTION.RUN_ACTIVITY");
    }
  } catch (error) {
    return "";
  }
};

/**
 * @function
 * @description get select activity data
 * @param {Array<IActivity>} activities activities
 * @param {Number} activityId select activity
 * @returns {IActivity} select activity data
 */
export const getSelectedActivity = (
  activities: Array<IActivity>,
  activityId: number
): IActivity | undefined => {
  try {
    return activities.find((activity: IActivity) => {
    
      return activity.activityId === activityId;
    });
  } catch (error) {}
};

/**
 * @function
 * @description filter activity by acivity name or code
 * @param {Array<IActivity>} activities activities
 * @param {string} searchText -search value
 * @returns  {Array<IActivity>} filtered activities
 */
export const searchActivityByCodeOrName = (
  activities: Array<IActivity>,
  searchText: string
): Array<IActivity> => {
  try {
    return searchText.trim().length !== 0
      ? activities.filter((activity: IActivity) => {
          const { activityCode, displayName } = activity;
          return (
            activityCode.toLowerCase().includes(searchText.toLowerCase()) ||
            displayName.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      : activities;
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description get options without grouping for auto complete
 * @param {Array<IActivity>} activities activities
 * @returns {Array<{ label: string; value:  string }>}
 */
export const getOptionWithoutGrouping = (
  activities: Array<IActivity>
): Array<{ label: string; value: string; activityId: number }> => {
  try {
    return activities.map(({ displayName,activityId  }: IActivity) => ({
      label: displayName,
      value: `${displayName}_${activityId}`,
      activityId:activityId 
    }));
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description get options with grouping for auto complete
 * @param {Array<IActivity>} activities activities
 * @returns {Array<IGroupingOptions>} grouping option
 */
export const getOptionWithGrouping = (
  activities: Array<IActivity>,
  groupingProperty: string = "",
  isEventGrouping?: boolean
): Array<IGroupingOptions> => {
  let options: Array<IGroupingOptions> = [];
  try { 
    if (isEventGrouping) {  
      return [
        {
          label: "activities",
          groupingOptions: activities?.filter(
            ({ type }) => trimText(type) != EVENT_ACTIVITY_TYPE.toLowerCase()
          ),
        },
        {
          label: "events",
          groupingOptions: activities?.filter(
            ({ type }) => trimText(type) == EVENT_ACTIVITY_TYPE.toLowerCase()
          ),
        },
      ];
    } else {
      Object.values(_.groupBy(activities, groupingProperty)).forEach(
        (groupActivities: Array<IActivity>) => {
          if (groupActivities)
            options.push({
              label: groupActivities[0].activityType ?? "",
              groupingOptions: groupActivities,
            });
        }
      ); 
      return options;
    }
  } catch (error) {
    return options;
  }
};

/**
 * @function
 * @description set selected activity Display Name
 * @param {string} selectedActivityModifiedDisplayName - modified Display Name
 * @param {boolean} isActivityOptionWithCode
 * @returns  {string} return the selected Display Name 
 */
export const getSelectedActivityDisplayName= (
  selectedActivityModifiedDisplayName: string,
  isActivityOptionWithCode: boolean
)=> {
  try {
    let displayName=''

    if (isActivityOptionWithCode) {
      const lastIndex = selectedActivityModifiedDisplayName.lastIndexOf('_');
      const secondToLastIndex = selectedActivityModifiedDisplayName.lastIndexOf('_', lastIndex - 1);
      
      displayName =
        secondToLastIndex !== -1
        ? selectedActivityModifiedDisplayName.substring(0, secondToLastIndex)
        : selectedActivityModifiedDisplayName;
      } else {
      const lastIndex = selectedActivityModifiedDisplayName.lastIndexOf('_');
      
      displayName =
        lastIndex !== -1
        ? selectedActivityModifiedDisplayName.substring(0, lastIndex)
        : selectedActivityModifiedDisplayName;
      }
      return displayName
  } catch (error) {
    return '';
  }
};