import React, { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FieldArray } from "formik";
import "us.collection.admin/components/Court/Court.scss";
import { DeleteOutlined, PlusOutlined } from "us.icons";
import Common from "us.common";
import { AddNewCourtValidationSchema } from "us.collection.admin/components/Court/Validations";
import PostalArea from "us.common/containers/PostalArea/PostalArea";
import { AddNewCourtItem } from "us.collection.admin/components/Court/Repository";
import {
  isValid,
  getPostalId,
  isDirty,
} from "us.collection.admin/components/Court/Functions";
import { INewCourt } from "./interface";
import {
  PostalDrawerTypes,
  DrawerTypes,
  InitialFormData,
  InitialDrawerData,
  PostalCodeParams,
} from "us.collection.admin/components/Court/Constants";
import * as Actions from "us.collection.admin/actions";
import { isArray } from "lodash";

const {
  $Button,
  $Skeleton,
  $Tooltip,
  $Row,
  $Col,
  $Popconfirm,
  $Input,
  $Select,
  $Form,
  $Drawer,
  $MessageBox,
  $InputAccountNo,
  $InputWithValue,
} = Common.Components;

/**
 * @description - Add New Court drawer component to add new court
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2861694980/Manage+Court+Info+UI+-+View
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/02/2022
 */

const AddNewCourt: React.FC<PropsFromRedux & INewCourt> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    courtTypes,
    postalAreasForCourt,
    updateCourtInfo,
    drawerData,
    addNewCourtItem,
    selectedPostalCodes,
    getPostalAreas,
    selectedCourtType,
    getApplicablePostalAreas,
    isLoadingPostalCode,
    isValidPostalCode,
  } = props;

  const [drawerInfo, setDrawerInfo] = useState<any>(InitialDrawerData);
  const { onClose } = props;

  const [initialFromData, setInitialFromData] = useState<any>(
    drawerData?.drawerType === DrawerTypes.COURT_ITEM_EDIT
      ? {
          ...drawerData?.record,
          courtType: drawerData?.record?.typeId,
          selectedPostalCodes: selectedPostalCodes,
        }
      : InitialFormData
  );

  useEffect(() => {
    if (isArray(selectedPostalCodes)) {
      setInitialFromData({
        ...initialFromData,
        selectedPostalCodes,
      });
    }
  }, [selectedPostalCodes]);

  const postalAreaClose = () => {
    setDrawerInfo(InitialDrawerData);
  };

  /**
   * @description Submit changes
   * @param values - form values
   */

  const submitFormData = (values: any) => {
    const postalId =
      drawerData?.drawerType === DrawerTypes.COURT_ITEM_EDIT &&
      postalAreasForCourt.length === 0
        ? drawerData?.record?.postalId
        : getPostalId(postalAreasForCourt, values.postalCode);
    const payload = AddNewCourtItem.call({ ...values, postalId });
    if (drawerData?.drawerType === DrawerTypes.COURT_ITEM_EDIT) {
      updateCourtInfo && updateCourtInfo({ payload, selectedCourtType });
    } else {
      addNewCourtItem && addNewCourtItem({ payload, selectedCourtType });
    }
    onClose();
  };

  /**
   * @description - Call postal code search API action
   * @param searchText - search text
   */
  const handlePostalCode = (searchText: any) => {
    getPostalAreas &&
      getPostalAreas({
        countryCode: PostalCodeParams.COUNTRY_CODE,
        searchBy: PostalCodeParams.POSTAL_CODE,
        searchText,
      });
  };

  const changeDrawerInfo = (visibility: boolean, drawerType: any) => {
    setDrawerInfo({
      visibility,
      drawerType,
    });
  };

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
      initialValues={{
        ...initialFromData,
        isValidPostalCode,
        relevantPostalCodeInput: "",
      }}
      validationSchema={AddNewCourtValidationSchema}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        submitFormData(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <div className="new-court">
          <$Form>
            <$Row gutter={16} className="mb-2">
              <$Col span={11}>
                <div className="mb-2">
                  <div style={{ marginBottom: "6px" }}>
                    <span className="required mr-1">*</span>
                    {t("US.COLLECTION.ADMIN:COURT.COURT_TYPE")}
                  </div>
                  <$Select
                    required
                    style={{ width: "100%" }}
                    placeholder=""
                    className="mr-0"
                    options={courtTypes}
                    onSearchBy={["name", "courtTypeId"]}
                    optionValue="courtTypeId"
                    optionText="name"
                    allOption={false}
                    name="courtType"
                    size="small"
                    defaultValue="ALL"
                    dataTestid="select_court_type"
                  />
                </div>

                <div style={{ paddingTop: 2 }}>
                  <$Input
                    name="courtName"
                    label={t("US.COLLECTION.ADMIN:COURT.NAME")}
                    size="small"
                    required
                  />
                </div>

                <div style={{ paddingTop: 2 }}>
                  <$Input
                    name="address1"
                    label={t("US.COLLECTION.ADMIN:COURT.ADDRESS_1")}
                    size="small"
                    required
                  />
                </div>

                <div style={{ paddingTop: 2 }}>
                  <$Input
                    name="address2"
                    label={t("US.COLLECTION.ADMIN:COURT.ADDRESS_2")}
                    size="small"
                  />
                </div>

                <div className="mt-2">
                  <$Row gutter={16} className="mb-2">
                    <$Col span={10}>
                      <a
                        className="d-block"
                        data-testid="postal-code-link"
                        style={{ paddingBottom: "0.13rem" }}
                        onClick={() =>
                          changeDrawerInfo(true, PostalDrawerTypes.POSTAL_CODES)
                        }
                      >
                        <span className="required mr-1">*</span>
                        <strong>
                          {t("US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE")}
                        </strong>
                      </a>
                      <$Input
                        name="postalCode"
                        size="small"
                        onBlur={(e: any) => {
                          if (
                            e.target.value != "" &&
                            e.target.value != initialFromData.postalCode
                          ) {
                            setInitialFromData({
                              ...values,
                              postalCode: e.target.value,
                              postalArea: "",
                            });
                            handlePostalCode(e.target.value);
                          }
                        }}
                      />
                    </$Col>
                    <$Col span={14}>
                      <$Skeleton
                        loading={isLoadingPostalCode}
                        active
                        paragraph={{ rows: 0 }}
                      >
                        <$InputWithValue
                          name="postalArea"
                          label={t(
                            "US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA"
                          )}
                          size="small"
                          className="w-100"
                          disabled
                          value={
                            drawerData?.drawerType ===
                              DrawerTypes.COURT_ITEM_EDIT &&
                            postalAreasForCourt.length === 0
                              ? drawerData?.record?.postPlace
                              : postalAreasForCourt[0]?.postalPlace
                          }
                        />
                      </$Skeleton>
                    </$Col>
                  </$Row>
                </div>

                <$Input
                  name="telephone"
                  label={t("US.COLLECTION.ADMIN:COURT.TELEPHONE")}
                  size="small"
                  maxLength={15}
                />
              </$Col>
              <$Col span={11} offset={2}>
                <$Input
                  name="orgNo"
                  label={t("US.COLLECTION.ADMIN:COURT.ORGANIZATION_NO")}
                  size="small"
                />

                <$InputAccountNo
                  name="bankAccountNo"
                  label={t("US.COLLECTION.ADMIN:COURT.BANK_ACCOUNT")}
                  size="small"
                />

                <div style={{ marginTop: "7px" }}>
                  <a
                    className="d-block"
                    style={{ marginBottom: "7px" }}
                    data-testid="relevant-postal-codes-link"
                    onClick={() =>
                      changeDrawerInfo(
                        true,
                        PostalDrawerTypes.RELEVANT_POSTAL_CODES
                      )
                    }
                  >
                    <strong>
                      {t("US.COLLECTION.ADMIN:COURT.RELEVANT_POSTAL_CODES")}
                    </strong>
                  </a>
                  <div
                    className="relevant-postal-codes"
                    style={{ height: 220 }}
                  >
                    <FieldArray
                      name="selectedPostalCodes"
                      render={(arrayHelpers) => (
                        <div>
                          <div className="d-flex">
                            <div className="flex-grow-1 mr-2">
                              <$Input
                                placeholder={t(
                                  "US.COLLECTION.ADMIN:COURT.POSTAL_CODE"
                                )}
                                name="relevantPostalCodeInput"
                                size="small"
                              />
                            </div>
                            <$Button
                              size="small"
                              disabled={
                                values.relevantPostalCodeInput == undefined ||
                                values.relevantPostalCodeInput === ""
                              }
                              onClick={() => {
                                rest.setFieldValue(
                                  "relevantPostalCodeInput",
                                  ""
                                );
                                setInitialFromData({
                                  ...values,
                                  selectedPostalCodes:
                                    values.selectedPostalCodes,
                                });
                                getApplicablePostalAreas &&
                                  getApplicablePostalAreas({
                                    countryCode: PostalCodeParams.COUNTRY_CODE,
                                    searchBy: PostalCodeParams.POSTAL_CODE,
                                    searchText: values.relevantPostalCodeInput,
                                    selectedApplicableCodes: values.selectedPostalCodes
                                  });
                              }}
                              style={{ marginTop: 5 }}
                              type="link"
                              icon={<PlusOutlined />}
                            >
                              {t("US.COLLECTION.ADMIN:COURT.ADD")}
                            </$Button>
                          </div>
                          <div className="rpc-wrap">
                            {values?.selectedPostalCodes?.length > 0 &&
                              values?.selectedPostalCodes?.map(
                                (code: any, index: any) => (
                                  <div className="rpc-item" key={index}>
                                    <div className="rpc-title">
                                      {code.postalCode +
                                        " - " +
                                        code.postalPlace}
                                    </div>
                                    <div className="rpc-icon">
                                      <$Popconfirm
                                        title={t(
                                          "US.COLLECTION.ADMIN:COURT.DELETE_MUNICIPALITY_CONFIRM"
                                        )}
                                        onConfirm={() => {
                                          rest.setFieldValue(
                                            "selectedPostalCodes",
                                            values.selectedPostalCodes.filter(
                                              (code: any, i: any) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        <$Tooltip
                                          placement="top"
                                          title={t(
                                            "US.COLLECTION.COMMON:COMMON.DELETE"
                                          )}
                                        >
                                          <$Button
                                            type="link"
                                            size="small"
                                            danger
                                          >
                                            <DeleteOutlined />
                                          </$Button>
                                        </$Tooltip>
                                      </$Popconfirm>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </$Col>
            </$Row>

            {/* Postal Area ---------------------------------------------------------------------------------*/}
            <$Drawer
              title={
                drawerInfo?.drawerType === PostalDrawerTypes.POSTAL_CODES
                  ? t("US.COMMON:COMMON.SELECT_POSTAL_AREA")
                  : t("US.COMMON:COMMON.SELECT_RELEVANT_POSTAL_AREAS")
              }
              width={800}
              visible={drawerInfo?.visibility}
              onClose={postalAreaClose}
              destroyOnClose
            >
              {
                <PostalArea
                  name="selectedPostalCodes"
                  multiple={
                    drawerInfo?.drawerType ===
                    PostalDrawerTypes.RELEVANT_POSTAL_CODES
                  }
                  onSubmitSelection={(selectedKeys: Array<any>) => {
                    setInitialFromData({
                      ...values,
                      selectedPostalCodes: selectedKeys,
                    });
                  }}
                  postalAreaClose={postalAreaClose}
                  drawerContentChangeClick={(e: any) => {
                    const { PostalCode, City } = e?.updatedInfo;
                    getPostalAreas &&
                      getPostalAreas({
                        countryCode: PostalCodeParams.COUNTRY_CODE,
                        postalCode: "",
                        searchType: PostalCodeParams.ALL_SEARCH_TYPE,
                        searchText: PostalCode,
                      });
                    setInitialFromData({
                      ...values,
                      postalArea: City,
                      postalCode: PostalCode,
                    });
                    drawerInfo?.drawerType !==
                    PostalDrawerTypes.RELEVANT_POSTAL_CODES
                      ? postalAreaClose()
                      : "";
                  }}
                  {...props}
                />
              }
            </$Drawer>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                data-testid="save-btn"
                disabled={
                  !isValid(rest.errors) ||
                  (drawerData?.drawerType === DrawerTypes.COURT_ITEM_ADD
                    ? false
                    : !isDirty(
                        drawerData?.record,
                        values,
                        selectedPostalCodes
                      )) ||
                  (drawerData?.drawerType === DrawerTypes.COURT_ITEM_EDIT &&
                    postalAreasForCourt.length === 0)
                    ? drawerData?.record?.postPlace == undefined
                    : postalAreasForCourt[0]?.postalPlace == undefined
                }
                onClick={() => {
                  if (values.courtType != -1) {
                    handleSubmit();
                  } else {
                    $MessageBox(
                      "error",
                      "US.COLLECTION.VALIDATIONS:REQUIRED.CANNOT_CREATE_COURT_FOR_ALL_COURT_TYPES",
                      "",
                      ""
                    );
                  }
                }}
                type="primary"
              >
                {drawerData?.drawerType === DrawerTypes.COURT_ITEM_ADD
                  ? t("US.COLLECTION.COMMON:COMMON.SAVE")
                  : t("US.COLLECTION.COMMON:COMMON.UPDATE")}
              </$Button>
              <$Button onClick={onClose}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { courtInfo, entityDetails } = state;
  const {
    courtTypes,
    selectedPostalCodes,
    selectedCourtType,
    postalAreasForCourt,
    isLoadingPostalCode,
    isValidPostalCode,
  } = courtInfo;
  const { postalAreas } = entityDetails;
  return {
    courtTypes,
    postalAreasForCourt,
    postalAreas,
    selectedPostalCodes,
    selectedCourtType,
    isLoadingPostalCode,
    isValidPostalCode,
  };
};

const { courtInfo } = Actions;
const { courtItemInfo, newCourtItem, postalAreas, applicablePostalAreas } =
  courtInfo;

const mapDispatchToProps = {
  updateCourtInfo: courtItemInfo.update,
  addNewCourtItem: newCourtItem.save,
  getPostalAreas: postalAreas.get,
  getApplicablePostalAreas: applicablePostalAreas.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddNewCourt);
