import { CreditorStatusTypes } from 'us.common/components/MainSearch/Constants';
import { IMainSearchResults } from '../Interfaces';
import { CaseSearchModel } from './CaseSearch';
import { CreditorSearchModel } from './CreditorSearch';
import { DebtorSearchModel } from './DebtorSearch';
import { InvoiceSearchModel } from './InvoiceSearch';
import { SearchHistoryModel } from './ISearchHistory';
import { MainCriterias } from 'us.common/constants';

export const initialState: IMainSearchResults = {
	invoice: InvoiceSearchModel,
	case: CaseSearchModel,
	creditor: CreditorSearchModel,
	debtor: DebtorSearchModel,
	history: SearchHistoryModel,
	backNavigation: {
		isBack: false,
		canGoBack: false,
		activeTab: MainCriterias.INVOICE,
		viewedIds: [],
		currentHit: 1,
		queryString: null,
		filters: {
			isOpenCase: true,
			isNextDueDateCase: true,
			isCloseCase: true,
			isCourtCase: true,
			isSentenceCase: true,
			caseNumber: '',
			invoiceNumber: '',
			mainAmount: '',
			balance: '',
			debtorNameNo: '',
			birthdate: '',
			creditorNameNo: '',
			phone: '',
			address: '',
			lastMsgActivityName: '',
			creditorStatus: CreditorStatusTypes.ACTIVE,
			creditorGroupIdName: '',
			custId: '',
			arNo: '',
		},
	},
	viewedList: [],
};
