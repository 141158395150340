import { versionedHttpCollection } from 'us.helper/http/collection';

export default {
	/**
	 * A namespace
	 * @namespace activities
	 */
	activities: {
		/**
		 * Get all activities
		 * @returns {Promise} Promise object represents the API response
		 */
		get: (): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`activitysummary`,
						{},
						'V1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace activity
	 */
	activity: {
		init: (): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					/**
					 * Get Entity Types
					 */
					const entityTypes = await versionedHttpCollection.get(
						'activityservice',
						`activityentitytypes`,
						{},
						'v1'
					);

					/**
					 * Get Activity Types
					 */
					const activityTypes = await versionedHttpCollection.get(
						'activityservice',
						`activitytypes`,
						{},
						'v1'
					);
					/**
					 * Get Activity Types
					 */
					const activityGroups = await versionedHttpCollection.get(
						'activityservice',
						`activityGroups`,
						{},
						'v1'
					);

					resolve({
						entityTypes: entityTypes.status == 200 ? entityTypes.data : [],
						activityTypes:
							activityTypes.status == 200 ? activityTypes.data : [],
						activityGroups:
							activityGroups.status == 200 ? activityGroups.data : [],
					});
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * save activity
		 * @returns {Promise} Promise object represents the API response
		 */
		save: (params: any): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.post(
						'activityservice',
						`activity`,
						params,
						'v1'
					);
					if (status == 201) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * Delete activity
		 * @returns {Promise} Promise object represents the API response
		 */
		delete: <
			P extends {
				activityId: number;
			}
		>(
			params: P
		): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.delete(
						'activityservice',
						`activity`,
						params.activityId,
						{},
						'V1'
					);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},

	/**
	 * A namespace
	 * @namespace properties
	 */
	properties: {
		/**
		 * Get parameters
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { entityType: string; activityType: string }>(
			params: P
		): Promise<Array<any>> => {
			const { entityType, activityType } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`activityproperties/${entityType}/${activityType}`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace Parameters
	 */
	parameters: {
		/**
		 * Get parameters
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { spName: string }>(params: P): Promise<Array<any>> => {
			const { spName } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`spparameters/${spName}`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace templateCategories
	 */
	templateCategories: {
		/**
		 * Get parameters
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { entityType: string }>(params: P): Promise<Array<any>> => {
			const { entityType } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'communicationservice',
						`template/category/${entityType}`,
						{},
						'v1'
					);
					if (status == 200 && Array.isArray(data)) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace states
	 */
	states: {
		/**
		 * Get states
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { entityType: string }>(params: P): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				const { entityType } = params;
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`statesforentitytype/${entityType}`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace followupCategories
	 */
	followupCategories: {
		/**
		 * Get followupCategories
		 * @returns {Promise} Promise object represents the API response
		 */
		get: (): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`commonfollowupcategories`,
						{},
						'V1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace followupCategory
	 */
	followupCategory: {
		/**
		 * save followupCategory
		 * @returns {Promise} Promise object represents the API response
		 */
		save: (params: any): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.post(
						'activityservice',
						`commonfollowupcategory`,
						params,
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * delete followup category
		 * @param params
		 * @returns {Promise} Promise object represents the API response
		 */
		delete: <
			P extends {
				categoryId: number;
			}
		>(
			params: P
		): Promise<any> => {
			const { categoryId } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } = await versionedHttpCollection.delete(
						'activityservice',
						`commonfollowupcategory`,
						categoryId,
						{},
						'v1'
					);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace activityDetail
	 */
	activityDetail: {
		/**
		 * Get activity Detail
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { activityId: number }>(params: P): Promise<Array<any>> => {
			const { activityId } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`activity/${activityId}`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace executionProfiles
	 */
	executionProfiles: {
		/**
		 * Get activity Detail
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends {}>(params: P): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`activityexecutionprofiles`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	/**
	 * A namespace
	 * @namespace executionProfile
	 */
	executionProfile: {
		/**
		 * Get activity Detail
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { profileId: number }>(params: P): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { profileId } = params;
					const { data, status } = await versionedHttpCollection.get(
						'activityservice',
						`activityexecutionprofile/${profileId}`,
						{},
						'v1'
					);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * save execution profile
		 * @returns {Promise} Promise object represents the API response
		 */
		save: (params: any): Promise<Array<any>> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } = await versionedHttpCollection.post(
						'activityservice',
						`activityexecutionprofile`,
						params,
						'v1'
					);
					if (status == 200 || status == 201) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		/**
		 * delete execution profile
		 * @param params
		 * @returns {Promise} Promise object represents the API response
		 */
		delete: <
			P extends {
				profileId: number;
			}
		>(
			params: P
		): Promise<any> => {
			const { profileId } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data } = await versionedHttpCollection.delete(
						'activityservice',
						`activityexecutionprofile`,
						profileId,
						{},
						'v1'
					);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
  eventList: {
    /**
     * Get event list detail
     * @returns {Promise} Promise object represents the API response
     */
     get: ({workflowType}: {workflowType:string}): Promise<Array<any>> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.get(
            "workflowservice",
            `workflows/events/domainviewactive?workflowtype=${workflowType}`,
            {},
            "v1"
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  }
};
