import React from 'react';
import {DatePicker, Form} from 'antd';
import {useField, Field} from 'formik';
import * as Utility from './utility';
import {useTranslation} from 'react-i18next';

const {RangePicker} = DatePicker;

export const $RangePicker = ({
  errors,
  name,
  picker,
  label,
  hasFeedback,
  currentTimeFormat,
  disabledTime,
  disabledDate,
  value,
  disabled,
  size,
  onOk,
  placeholder,
  touched,
  noStyle,
  onChange,
  onOpenChange,
  minuteStep,
  formItem,
  style,
  order = true,
  onCalendarChange
}: any) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const {t} = useTranslation();

  return (
    <Form.Item
      label={label}
      hasFeedback={hasFeedback}
      noStyle={typeof noStyle == 'undefined' ? false : true}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
      {...formItem}
    >
      <Field
        as={RangePicker}
        {...field}
        style={style}
        picker={picker}
        minuteStep={minuteStep}
        size={size}
        name={name}
        onOk={onOk}
        format={currentTimeFormat}
        placeholder={placeholder}
        disabledTime={disabledTime}
        disabledDate={disabledDate}
        onOpenChange={onOpenChange}
        onCalendarChange={onCalendarChange}
        disabled={disabled}
        onChange={(times: [moment.Moment, moment.Moment]) => {
          onChange && onChange(times)
          helpers.setValue(times);
          helpers.setTouched(true);
        }}
        order={order}
      />
    </Form.Item>
  );
};