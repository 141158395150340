import React from "react";
import { ColumnsType } from "us.common/components/antd";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { useSelector } from "react-redux";
import { getRouteUrl } from "us.helper";
import {
  $InputAmount,
  $AmountLabel,
  $Input,
  $Button,
} from "us.common/components";
import { Input } from "antd";
import { IColumns } from "us.common/components/CreditNote/Interface";
import { ColumnNames } from "us.common/components/CreditNote/Constants";

import { SearchOutlined, FilterOutlined } from "us.icons";

export const columns = (
  isDefault: boolean,
  disabled: boolean,
  tableColumns: IColumns[]
) => {
  const { t } = useTranslation();
  const [field, , helper] = useField("creditNoteDetails");
  const [fieldCreditOption] = useField("creditOption");

  const { currentLanguage, currentCurrency } = useSelector(
    (state: any) => state.common
  );

  const getColumnSearchProps = (
    dataIndex: string,
    title: string,
    type?: "amount"
  ) => {
    const inputSearchRef = React.useRef<any>();
    return {
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          window.setTimeout(function () {
            typeof inputSearchRef?.current !== "undefined" &&
              inputSearchRef.current.focus();
            document
              .querySelector(`[name=columnSearchAmount${dataIndex}]` as any)
              ?.focus();
          }, 1);
        }
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          {!type && (
            <Input
              name="columnSearch"
              placeholder={`${t(
                "US.COLLECTION.COMMON:COMMON.SEARCH"
              )} ${title}`}
              value={selectedKeys[0]}
              onChange={(e: any) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              autoComplete="off"
              style={{ width: 188, marginBottom: 8, display: "block" }}
              ref={inputSearchRef}
            />
          )}
          {type == "amount" && (
            <$InputAmount
              id="column-search-amount"
              min={0}
              className="w-100"
              placeholder="0,00"
              name={`columnSearchAmount${dataIndex}`}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              value={!selectedKeys[0] ? "" : selectedKeys[0]}
              onChange={(amount: any) => {
                amount = amount == 0 ? "" : amount;
                setSelectedKeys(amount ? [amount] : []);
              }}
              onKeyPress={(e: any) => e.key === "Enter" && confirm()}
              autoComplete="off"
              dataTestid="column-search"
            />
          )}
          <$Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.SEARCH")}
          </$Button>
          <$Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.RESET")}
          </$Button>
        </div>
      ),
      filterIcon: (filtered: string) => (
        <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: string, record: any) => {
        const filteredData =
          type == "amount"
            ? record[dataIndex]?.toString()?.includes(value)
            : record[dataIndex]
                ?.toString()
                ?.toLowerCase()
                ?.includes(value?.toLowerCase());
        return filteredData;
      },
    };
  };

  return [
    {
      title: t("US.COMMON:CREDIT_NOTE.ARTICLE_NO"),
      dataIndex: ColumnNames.ARTICLE_NO,
      width: "100px",
      ellipsis: true,
      ...getColumnSearchProps(
        ColumnNames.ARTICLE_NO,
        t("US.COMMON:CREDIT_NOTE.ARTICLE_NO")
      ),
    },
    {
      title: t("COMMON.NAME"),
      dataIndex: ColumnNames.ARTICLE_NAME,
      ...getColumnSearchProps(ColumnNames.ARTICLE_NAME, t("COMMON.NAME")),
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.CASE_NO"),
      dataIndex: ColumnNames.CASE_NO,
      width: "100px",
      ...getColumnSearchProps(
        ColumnNames.CASE_NO,
        t("US.COMMON:CREDIT_NOTE.CASE_NO")
      ),
      render: (text: string, record: any) => {
        const { caseNo } = record;
        return (
          <a onClick={() => getRouteUrl.caseType(caseNo)} target="_blank">
            {caseNo !== 0 && caseNo}
            {caseNo === 0 && ""}
          </a>
        );
      },
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.QTY"),
      dataIndex: ColumnNames.QUANTITY,
      width: "60px",
      ...getColumnSearchProps(
        ColumnNames.QUANTITY,
        t("US.COMMON:CREDIT_NOTE.QTY")
      ),
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.UNIT_PRICE"),
      dataIndex: ColumnNames.UNIT_PRICE,
      align: "right",
      width: "100px",
      ...getColumnSearchProps(
        ColumnNames.UNIT_PRICE,
        t("US.COMMON:CREDIT_NOTE.UNIT_PRICE"),
        "amount"
      ),
      render: (text: string, record: any) => (
        <$AmountLabel value={record?.unitPrice} />
      ),
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.VAT"),
      dataIndex: ColumnNames.VAT,
      width: "100px",
      align: "right",
      ...getColumnSearchProps(
        ColumnNames.VAT,
        t("US.COMMON:CREDIT_NOTE.VAT"),
        "amount"
      ),
      render: (text: string, record: any) => (
        <$AmountLabel value={record?.vatAmount} />
      ),
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.INVOICED_PRICE"),
      dataIndex: ColumnNames.TOTAL,
      align: "right",
      width: "100px",
      ...getColumnSearchProps(
        ColumnNames.TOTAL,
        t("US.COMMON:CREDIT_NOTE.INVOICED_PRICE"),
        "amount"
      ),
      render: (text: string, record: any) => (
        <$AmountLabel value={record?.total} />
      ),
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.DESCRIPTION"),
      dataIndex: ColumnNames.DESCRIPTION,
      render: (text: string, record: any, index: number) => {
        return (
          <$Input
            name={`creditNoteDetails[${index}].description`}
            value={field.value[index]?.description}
            maxLength={255}
            disabled={disabled}
            size="small"
          />
        );
      },
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.REDUCE_PRICE_TO"),
      dataIndex: ColumnNames.REDUCED_PRICED,
      width: "130px",
      render: (text: string, record: any, index: number) => {
        return (
          <$InputAmount
            currentLanguage={currentLanguage}
            currentCurrency={currentCurrency}
            size="small"
            value={field.value[index]?.reducedPriced}
            disabled={
              parseFloat(record?.total) < 0 || fieldCreditOption.value != 3
            }
            name={`creditNoteDetails[${index}].reducedPriced`}
            onChange={(val: number) => {
              field.value[index] = {
                ...record,
                reducedPriced: val,
                creditedAmount: record?.total - val,
              };
              helper.setValue(field.value);
            }}
            className="bui-number-input"
          />
        );
      },
    },
    {
      title: t("US.COMMON:CREDIT_NOTE.CREDITING_AMOUNT"),
      dataIndex: ColumnNames.CREDITING_AMOUNT,
      width: "180px",
      align: "right",
      render: (text: string, record: any, index: number) => {
        return (
          <$InputAmount
            currentLanguage={currentLanguage}
            currentCurrency={currentCurrency}
            size="small"
            value={field.value[index]?.creditedAmount}
            disabled={
              disabled ||
              parseFloat(record?.total) < 0 ||
              fieldCreditOption.value != 3 ||
              (isDefault ? record?.quantity == 0 : false)
            }
            name={`creditNoteDetails[${index}].creditedAmount`}
            onChange={(creditedAmount: number) => {
              field.value[index] = {
                ...record,
                creditedAmount: creditedAmount,
                reducedPriced: record?.total - creditedAmount,
              };
              helper.setValue(field.value);
            }}
            className="bui-number-input"
          />
        );
      },
    },
  ].filter(({ dataIndex }: { dataIndex: IColumns }) =>
    tableColumns.length ? tableColumns.includes(dataIndex) : true
  ) as ColumnsType<any>;
};
