import React, { useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { RootState } from "us.helper/types";
import { MunicipalityActions } from "us.collection.admin/constants";
import {
  getCountryOptions,
  getInitFormValues,
  isMunicipalityDuplicating,
} from "us.collection.admin/components/Municipalities/Functions";
import { IMunicipalityDetails } from "us.collection.admin/components/Municipalities/Interfaces";
import { MunicipalityValidation } from "us.collection.admin/components/Municipalities/Validations";
import * as Actions from "us.collection.admin/actions";
import _ from "lodash";

const {
  $Button,
  $Input,
  $Form,
  $Popconfirm,
  $AsyncInput,
  $SelectGroup,
} = Common.Components;

/**
 * @description - Municipality drawer component for update municipalities and add new ones
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3340959765/Manage+Municipalities+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 26/07/2023
 */
const MunicipalityDetails: React.FC<IMunicipalityDetails & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();

  const {
    countries,
    isFormSaving,
    drawerInfo,
    municipalityList,
    saveMunicipality,
    onCancel,
  } = props;
  const { data: countryList, isLoading: isCountriesLoading } = countries;
  const { municipalityId, type } = drawerInfo;

  // Get initial form values
  const initialValues = useMemo(() => {
    return getInitFormValues(municipalityId, municipalityList.data);
  }, [municipalityId, municipalityList.data]);

  /**
   * @description Handle submit button action
   * @param saveParams form values
   * @param actions form actions
   */
  const handleSubmit = (saveParams: any, actions: any) => {
    if (actions.isValidating) return;

    saveMunicipality && saveMunicipality({ saveParams });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange
      validateOnBlur
      validationSchema={MunicipalityValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical">
            <$SelectGroup
              name="countryCode"
              formitem={{
                label: t("US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.COUNTRY"),
              }}
              size="small"
              virtual={false}
              value={values?.countryCode}
              loading={isCountriesLoading}
              disabled={isCountriesLoading}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              required
              options={getCountryOptions(countryList)}
            />
            <$AsyncInput
              name="municipalityCode"
              label={t("US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.CODE")}
              size="small"
              disabled={type == MunicipalityActions.EDIT}
              required
              isNumber={true}
              maxLength={9}
              isValid={
                !isMunicipalityDuplicating(
                  municipalityList.data,
                  values,
                  initialValues
                )
              }
              asyncError={t(
                "US.COLLECTION.ADMIN:VALIDATIONS.MUNICIPALITY_ALREADY_EXISTS"
              )}
            />
            <$Input
              required
              size="small"
              name="municipalityName"
              label={t("US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.NAME")}
            />
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Popconfirm
                title={t("COMMON.SURE_TO_PROCEED_?")}
                placement="topLeft"
                data-testid="pop-confirm"
                okButtonProps={{
                  dataTestid: "pop-confirm-ok",
                }}
                onConfirm={() => handleSubmit()}
                okText={t("COMMON.YES")}
                cancelText={t("COMMON.NO")}
                disabled={
                  isFormSaving ||
                  !restProps.isValid ||
                  !restProps.dirty ||
                  isMunicipalityDuplicating(
                    municipalityList.data,
                    values,
                    initialValues
                  )
                }
              >
                <$Button
                  className="ml-3 mr-2"
                  type="primary"
                  data-testid="save-btn"
                  loading={isFormSaving}
                  disabled={
                    isFormSaving ||
                    !restProps.isValid ||
                    !restProps.dirty ||
                    isMunicipalityDuplicating(
                      municipalityList.data,
                      values,
                      initialValues
                    )
                  }
                >
                  {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                </$Button>
              </$Popconfirm>
              <$Button
                data-testid="cancel-item-type"
                onClick={() => onCancel()}
              >
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
const mapStateToProps = (state: RootState) => {
  const { municipalities } = state;
  const { municipalityList, countries, drawerInfo, isFormSaving } =
    municipalities;

  return {
    isFormSaving,
    drawerInfo,
    countries,
    municipalityList,
  };
};

const { municipalityData } = Actions.municipalities;

const mapDispatchToProps = {
  saveMunicipality: municipalityData.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MunicipalityDetails);
