import { Municipalities } from "us.collection.admin/constants/Actions";
import { IMunicipalitiesActions } from "us.collection.admin/interfaces";
import { IAPIAction } from "us.collection/interfaces";

const {
  INIT_GET_MUNICIPALITIES,
  INIT_GET_MUNICIPALITIES_SUCCESS,
  INIT_GET_MUNICIPALITIES_FAIL,
  MANAGE_MUNICIPALITIES_DRAWER,

  GET_MUNICIPALITIES,
  GET_MUNICIPALITIES_SUCCESS,
  GET_MUNICIPALITIES_FAIL,

  DELETE_MUNICIPALITY,

  SAVE_MUNICIPALITY,
  SAVE_MUNICIPALITY_SUCCESS,
  SAVE_MUNICIPALITY_FAIL,

} = Municipalities;

export const municipalities: Readonly<IMunicipalitiesActions & IAPIAction> = {
  init: {
    get: (data) => ({
      type: INIT_GET_MUNICIPALITIES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    manageDrawer: (data) => ({
      type: MANAGE_MUNICIPALITIES_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: INIT_GET_MUNICIPALITIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: INIT_GET_MUNICIPALITIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  municipality: {
    search: (data) => ({
      type: GET_MUNICIPALITIES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    delete: (data) => ({
      type: DELETE_MUNICIPALITY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_MUNICIPALITIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_MUNICIPALITIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  municipalityData: {
    save: (data) => ({
      type: SAVE_MUNICIPALITY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_MUNICIPALITY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_MUNICIPALITY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
