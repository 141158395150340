import Constants from '../constants';

/**
 * using this function we can take the string formatted number according to the language
 * @param num the number that is required to be formatted.
 * @param language the language to be converted.
 */
export const IntlNumber = (num: string, language: string): string => {
	let validatedNumber = validateNumber(num, language);
	if (validatedNumber)
		return new Intl.NumberFormat(language).format(validatedNumber);
	else return '0';
};

/**
 * This function will convert given number to given language and add currency.
 * @param num the number that is required to be formatted.
 * @param language language to be converted
 * @param currency the currency value to be used.
 */
export const IntlCurrency = (
	num: string | number,
	language: string,
	currency: string
): string => {
	let validatedCurrency = validateCurrency(num, language, currency);
	if (validatedCurrency) {
		let formattedNumStr = new Intl.NumberFormat(language, {
			style: 'currency',
			currency: currency,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(validatedCurrency);
		let localSeperateVales = new Intl.NumberFormat(language, {
			style: 'currency',
			currency: currency,
		}).formatToParts(validatedCurrency);
		let currencySymbol = localSeperateVales.find(
			(x) => x.type === 'currency'
		)?.value;
		return currencySymbol
			? formattedNumStr.replace(currencySymbol, '')
			: language === 'en-GB'
			? '0.00'
			: '0,00';
	} else {
		let formattedNumStr = new Intl.NumberFormat(language, {
			style: 'currency',
			currency: currency,
		}).format(0.0);
		let localSeperateVales = new Intl.NumberFormat(language, {
			style: 'currency',
			currency: currency,
		}).formatToParts(validatedCurrency);
		let currencySymbol = localSeperateVales.find(
			(x) => x.type === 'currency'
		)?.value;
		return currencySymbol
			? formattedNumStr.replace(currencySymbol, '')
			: '0.00';
	}
};

/**
 * This funciton will give the formatted currency
 * @param date the date that need to be formatted, can be either Date format or string format
 * @param language language to be converted
 * @param info to determine the format of the output, where values taken form -> Constants.dateParams
 */
export const IntlDate = (
	date: string | Date,
	language: string,
	info: string = Constants.dateParams.DATE
): string => {
	let options = {};
	switch (info) {
		case Constants.dateParams.DATE:
            options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            return getDateFormatted(date, language,options);

		case Constants.dateParams.DATE_LONG:
			options = { year: 'numeric', month: 'long', day: 'numeric' };
			return capitalizeMonth(getDateFormatted(date, language, options));

		case Constants.dateParams.DATE_LONG_CUSTOM:
			options = { year: 'numeric', month: 'short', day: 'numeric' };
			return capitalizeMonth(
				getDateCustomFormatted(date, language, options)
			);

		case Constants.dateParams.DATE_TIME12:
			options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			};
			return getDateFormatted(date, language, options);

		case Constants.dateParams.DATE_TIME24:
			options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: false,
			};
			return getDateFormatted(date, language, options);

		case Constants.dateParams.DATE_LONG_TIME12:
			options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			};
			return capitalizeMonth(getDateFormatted(date, language, options));

		case Constants.dateParams.DATE_LONG_TIME24:
			options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: false,
			};
			return capitalizeMonth(getDateFormatted(date, language, options));

		case Constants.dateParams.TIME_12:
			options = { hour: 'numeric', minute: 'numeric', hour12: true };
			return getDateFormatted(date, language, options);

		case Constants.dateParams.TIME_24:
			options = { hour: 'numeric', minute: 'numeric', hour12: false };
			return getDateFormatted(date, language, options);

		default:
			options = {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			};
			return getDateFormatted(date, language, options);
	}
};

const getDateFormatted = (
	date: string | Date,
	language: string,
	options: object = {}
): string => {
	try {
		if (new Date(date) <= new Date('0001-01-01T00:00:00')) {
			return '';
		}
		if (typeof date === 'string')
			return new Intl.DateTimeFormat(language, options).format(
				new Date(date)
			);
		else return new Intl.DateTimeFormat(language, options).format(date);
	} catch (error) {
		return date + '';
	}
};

const capitalizeMonth = (formattedDate: string): string => {
	const regEx = /[a-zA-Z]/;
	const matches = formattedDate.match(regEx);
	if (matches !== null) {
		const firstLetter: string = matches[0];
		return formattedDate.replace(regEx, firstLetter.toUpperCase());
	} else {
		return formattedDate;
	}
};

const getDateCustomFormatted = (
	date: string | Date,
	language: string,
	options: object = {}
): string => {
	try {
		if (new Date(date) <= new Date('0001-01-01T00:00:00')) {
			return '';
		}
		if (typeof date === 'string') {
			let formatter = new Intl.DateTimeFormat(language, options); //.format(new Date(date));
			let parts = formatter.formatToParts(new Date(date));
			let dateString = '';
			dateString =
				'' +
				parts.find((x) => x.type === 'day')?.value +
				'-' +
				parts.find((x) => x.type === 'month')?.value +
				'-' +
				parts.find((x) => x.type === 'year')?.value;
			return dateString;
		} else {
			// return new Intl.DateTimeFormat(language, options).format(date);
			let formatter = new Intl.DateTimeFormat(language, options); //.format(new Date(date));
			let parts = formatter.formatToParts(date);
			let dateString = '';
			dateString =
				'' +
				parts.find((x) => x.type === 'day')?.value +
				'-' +
				parts.find((x) => x.type === 'month')?.value +
				'-' +
				parts.find((x) => x.type === 'year')?.value;
			return dateString;
		}
	} catch (error) {
		console.error(error);
		return date + '';
	}
};

export const validateNumber = (
	input: string | number,
	language: string
): number | undefined => {
	if (!input) {
		return undefined;
	}
	if (typeof input === 'number') {
		if (input === 0) {
			return 0;
		} else {
			input = input.toString();
		}
	}

	let localSeperateVales = new Intl.NumberFormat(language).formatToParts(
		1000.23
	);
	if (localSeperateVales.length > 4) {
		let groupSeparator = localSeperateVales.find(
			(x) => x.type === 'group'
		)?.value;
		let decimalSeparator = localSeperateVales.find(
			(x) => x.type === 'decimal'
		)?.value;
		if (!groupSeparator || groupSeparator === ' ') groupSeparator = '';
		if (!decimalSeparator) decimalSeparator = '';

		// regular expression to check
		// if input value has any charactors other than numbers, currency,group seperator and decimal seperator
		const regExStr =
			'([^0-9' + groupSeparator + decimalSeparator + '\\.]+)';
		const regEx = new RegExp(regExStr);

		input = input.replace(new RegExp(/\s/, 'g'), '');

		if (!regEx.test(input)) {
			// if the number has only the required charactors,

			let cleanedNumber = input
				.split(groupSeparator)
				.join('')
				.split(decimalSeparator)
				.join('.');
			let num: number = Number.parseFloat(cleanedNumber);
			if (!isNaN(num)) {
				return num;
			} else {
				console.log('could not parse number -> ', input);
				return undefined;
			}
		} else {
			console.error('input number can not parsed to a number');
			return undefined;
		}
	} else {
		console.error('Intl error');
		return undefined;
	}
};

export const validateCurrency = (
	input: string | number,
	language: string,
	currency: string
): number | undefined => {
	if (!input) {
		return undefined;
	}
	if (typeof input === 'number') {
		if (input === 0) {
			return 0;
		} else {
			input = input.toString();
		}
	}

	let localSeperateVales = new Intl.NumberFormat(language, {
		style: 'currency',
		currency: currency,
	}).formatToParts(1000.23);
	if (localSeperateVales.length > 4) {
		let currencySymbol = localSeperateVales.find(
			(x) => x.type === 'currency'
		)?.value;
		let groupSeparator = localSeperateVales.find(
			(x) => x.type === 'group'
		)?.value;
		let decimalSeparator = localSeperateVales.find(
			(x) => x.type === 'decimal'
		)?.value;

		if (!currencySymbol) currencySymbol = '';
		if (!groupSeparator || groupSeparator === ' ') groupSeparator = '';
		if (!decimalSeparator) decimalSeparator = '';

		// regular expression to check
		// if input value has any charactors other than numbers, currency,group seperator and decimal seperator
		const regExStr =
			'([^0-9' +
			currencySymbol +
			'\\-' +
			groupSeparator +
			decimalSeparator +
			'\\.]+)';
		const regEx = new RegExp(regExStr);

		input = input.replace(new RegExp(/\s/, 'g'), '');

		if (!regEx.test(input)) {
			// if the number has only the required charactors,

			let cleanedNumber = input
				.split(currencySymbol)
				.join('')
				.split(groupSeparator)
				.join('')
				.split(decimalSeparator)
				.join('.');
			let num: number = Number.parseFloat(cleanedNumber);
			if (!isNaN(num)) {
				return num;
			} else {
				console.log('could not parse currency -> ', input);
				return undefined;
			}
		} else {
			console.error('input currency can not parsed to a number');
			return undefined;
		}
	} else {
		console.error('Intl error');
		return undefined;
	}

	/*
        // if input is purely a number
        let num: number = Number.parseFloat(input)
        if (!isNaN(num)) {
            return num;
        }
    
        // if the input is with formatted separators
        else {
            let localSeperateVales = new Intl.NumberFormat(language, { style: 'currency', currency: currency }).formatToParts(1000.23);
            if (localSeperateVales.length > 4) {
                let currencySymbol = localSeperateVales[0].value;
                let groupSeparator = localSeperateVales[2].value;
                let decimalSeparator = localSeperateVales[4].value;
    
                if (input.includes(currencySymbol) && input.includes(groupSeparator) && input.includes(decimalSeparator)) {
    
                }
    
                // if input is with localized currencySymbol included
                let currencyRemoved = input.replace(currencySymbol, '');
                num = Number.parseFloat(currencyRemoved);
                if (!isNaN(num)) {
                    return num;
                }
    
                // if input is with localized decimal seperator included
                let decimalSeparatorReplaced = input.replace(decimalSeparator, '.');
                num = Number.parseFloat(decimalSeparatorReplaced)
                if (!isNaN(num)) {
                    return num;
                }
    
                // if the input is with thousand separator included
                let thousandSeparatorRemoved = input.replace(groupSeparator, '');
                num = Number.parseFloat(thousandSeparatorRemoved)
                if (!isNaN(num)) {
                    return num;
                }
    
                // if the input is with both currency and decimal seperator
    
                // if the input is with both thousand and decimal sepearators
                let decimalAndThousandHandled = decimalSeparatorReplaced.replace(groupSeparator, '');
                num = Number.parseFloat(decimalAndThousandHandled);
                if (!isNaN(num)) {
                    return num;
                }
    
                console.log('could not parse -> ', input)
                return 0;
            }
            else {
                console.log('Intl has failed');
                return 0;
            }
    
        }*/
};

export const getPlaceHolderNumber = (language: string): string => {
	let value = new Intl.NumberFormat(language).format(1000.23);

	return value.replace(new RegExp(/[0-9]/, 'g'), 'x');
};
