import * as Yup from "yup";
import { calculateCreditAmount } from "../../Functions";
interface IAddCreditNoteValidation extends Yup.MixedSchema {
  amount: number;
  comment: string;
  creditNoteDetails: Array<{
    orderlineId: number;
    articleNo: number;
    articleName: string;
    quantity: number;
    reducedQuantity: number;
    reducedPriced: number;
    creditedAmount: number;
    vat: number;
    discount: number;
    total: number;
  }>;
}

export default () => {
  return Yup.object<IAddCreditNoteValidation>().shape({
    amount: Yup.number(),
    invoiceNo: Yup.number()
      .moreThan(0, "US.COLLECTION.VALIDATIONS:INVALID.INVALID_INVOICE_NO")
      .nullable(),
    creditNoteDetails: Yup.array()
      .ensure()
      .compact(function (v: any) {
        return v.total < 0;
      })
      .when("creditOption", {
        is: (val) => val !== "1",
        then: Yup.array().test(
          "cn",
          "US.COLLECTION.VALIDATIONS:INVALID.MORE_THAN",
          function (b: any) {
            return (
              calculateCreditAmount(this.parent.creditNoteDetails) <=
              this.parent.balance
            );
          }
        ),
      })
      .test(
        "minbalance",
        "US.COLLECTION.VALIDATIONS:INVALID.MORE_THAN",
        function (b: any) {
          return calculateCreditAmount(this.parent.creditNoteDetails) > 0;
        }
      )
      .test(
        "minbalance",
        "US.COLLECTION.VALIDATIONS:INVALID.MORE_THAN",
        function (b: any) {
          return calculateCreditAmount(this.parent.creditNoteDetails) > 0;
        }
      )
      .of(
        Yup.object().shape({
          creditedAmount: Yup.number()
            .typeError(
              "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CREDIT_AMOUNT"
            )
            .max(
              Yup.ref("total"),
              "US.COLLECTION.VALIDATIONS:REQUIRED.CREDIT_AMOUNT_SHOULD_BE_LESS_THAN_OR_EQUAL_TOTAL"
            )
            .min(
              0,
              "US.COLLECTION.VALIDATIONS:REQUIRED.CREDIT_AMOUNT_SHOULD_BE_GREATER_THAN_OR_EQUAL_0"
            ),
          reducedPriced: Yup.number()
            .typeError(
              "US.COLLECTION.VALIDATIONS:INVALID.INVALID_REDUCED_PRICE"
            )
            .max(
              Yup.ref("total"),
              "US.COLLECTION.VALIDATIONS:REQUIRED.REDUCED_PRICE_SHOULD_BE_LESS_THAN_OR_EQUAL_TOTAL"
            )
            .min(
              0,
              "US.COLLECTION.VALIDATIONS:REQUIRED.REDUCED_PRICE_SHOULD_BE_GREATER_THAN_OR_EQUAL_0"
            ),
        })
      ),
  });
};
