/**
 * @description - Filter options by user input.
 * @param {Array<any>} options  - Array of options
 * @param {Array<string> | undefined}onSearchBy - Filed names for search
 * @param {string} searchValue - User input value
 * @returns  An array of filtered data
 */
 export const searchOptions = (
    options: Array<any>,
    onSearchBy: Array<string> | undefined,
    searchValue: string
  ): Array<any> => {
    try {
      if (Array.isArray(onSearchBy)) {
        return options?.filter((filterRecord: any) => {
          return onSearchBy
            ?.map((searchByKey: string) => filterRecord[searchByKey])
            ?.toString()
            ?.toLowerCase()
            ?.includes?.(searchValue?.toLowerCase());
        });
      } else {
        return options;
      }
    } catch (error) {
      return options;
    }
  };
  