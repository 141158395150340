export default {
    ns: [
        "US.COLLECTION.ADMIN",
        "US.COLLECTION.BMD",
        "US.COLLECTION.CASE",
        "US.COLLECTION.COMMON",
        "US.COLLECTION.CREDITOR",
        "US.COLLECTION.DEBTOR",
        "US.COLLECTION.DOCUMENTS",
        "US.COLLECTION.ECONOMY",
        "US.COLLECTION.FOLLOWUP",
        "US.COLLECTION.HOME",
        "US.COLLECTION.LAYOUT",
        "US.COLLECTION.PARTPAYMENTS",
        "US.COLLECTION.ROUTINESANDACTIVITIES",
        "US.COLLECTION.SEARCH",
        "US.COLLECTION.TRANSACTIONS",
        "US.COLLECTION.VALIDATIONS"
      ]
}