import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { useField, Field } from 'formik';
import { Input, Form } from 'antd';
import * as Utility from '../antd/utility';

let Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman'];
Quill.register(Font, true);

const defaultModules = {
	toolbar: [
		[{ header: '1' }, { header: '2' }, { font: Font.whitelist }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
		],
		[{ script: 'sub' }, { script: 'super' }],
		['link', 'image', 'video'],
		['clean'],
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const defaultFormats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'script',
	'link',
	'image',
	'video',
];

export default ({
	theme,
	handleChange,
	value,
	modules,
	formats,
	style,
	placeholder,
	name,
	required = false,
	...restProps
}: any) => {
	const [field, meta, helpers] = useField(name);
	const error = meta?.error;
	const isTouched = meta?.touched;
	const { t } = useTranslation();
	return (
		<Form.Item
			help={error && t(error as string)}
			validateStatus={Utility.validateStatus(error, isTouched)}
			required={typeof required == 'undefined' ? false : true}>
			<ReactQuill
				theme={theme}
				onChange={handleChange}
				value={value}
				modules={
					typeof modules == 'undefined' ? defaultModules : modules
				}
				formats={
					typeof formats == 'undefined' ? defaultFormats : formats
				}
				style={typeof style == 'undefined' ? { height: '100%' } : style}
				placeholder={placeholder}
			/>
		</Form.Item>
	);
};
