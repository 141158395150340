import React from "react";
import { useTranslation } from "react-i18next";
import { $Switch, $Select } from "us.common/components";
import { removeLocalCreditors, isAllGroup } from "us.common/functions/creditorSelection";
import { useFormikContext, useField } from "formik";
import { IGroupSelection } from "us.common/components/SelectCreditors/Interfaces";

/**
 * @description - Group selection component.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2776727606/Select+Creditors+-+Common+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const GroupSelection: React.FC<IGroupSelection> = ({
  creditorsFieldValue,
  groupType,
  creditors,
  selectedCreditors,
  isGroupsLoading,
  creditorGroups,
  resetFilters,
  onSelect,
  getCreditors,
  removeCreditors,
}) => {
  const { t } = useTranslation();
  const { values }: any = useFormikContext();
  const [, , creditorGroupHelpers] = useField("creditorGroup");

  /**
   * @description - On select handler for group selection.
   * @param {string | number} selectedGroupId - User selected group id.
   */
  const handleOnSelect = (selectedGroupId: string | number) => {
    if (getCreditors)
      if (selectedGroupId !== "0") {
        if (isAllGroup(values)) {
          getCreditors({
            groupId: [Number(selectedGroupId)],
            isAllGroupSelected: true,
            type: groupType,
          });
          onSelect && onSelect([]);
        } else {
          getCreditors({
            groupId: [Number(selectedGroupId)],
            type: groupType,
          });
        }
        if (Array.isArray(values?.creditorGroup)) {
          creditorGroupHelpers.setValue(
            [...values?.creditorGroup, selectedGroupId]?.filter(
              (groupId: string) => groupId !== "0"
            )
          );
        } else {
          creditorGroupHelpers.setValue([selectedGroupId]);
        }
      } else {
        getCreditors({
          groupId: [-1],
          type: groupType,
          selectedKeys: creditorsFieldValue,
        });
        creditorGroupHelpers.setValue(["0"]);
      }
    resetFilters();
  };

  /**
   * @description - On deselect handler for group selection.
   * @param {string | number} selectedGroupId - User selected group id.
   */
  const handleOnDeselect = (selectedGroupId: string | number) => {
    removeCreditors && removeCreditors({ groupId: Number(selectedGroupId) });
    onSelect &&
      onSelect(
        removeLocalCreditors(creditors, selectedCreditors, Number(selectedGroupId)).selected
      );
    resetFilters();
  };

  /**
   * @description - On change handler for toggle.
   * @param {boolean} isChecked - Wether the toggle is on.
   */
  const handleOnSwitchChange = (isChecked: boolean) => {
    if (isChecked) {
      creditorGroupHelpers.setValue(["0"]);
      getCreditors &&
        getCreditors({
          groupId: [-1],
          type: groupType,
        });
      resetFilters();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>{t("US.COLLECTION.COMMON:COMMON.SELECT_CREDITOR_GROUP")}</div>
        <div className="d-flex align-items-center">
          <$Switch
            size="small"
            name="isAllGroups"
            checked={values.creditorGroup?.length == 1 && values.creditorGroup?.includes("0")}
            onChange={handleOnSwitchChange}
            disabled={isGroupsLoading || values.creditorGroup?.includes("0")}
          />
          <label className="ml-2">{t("US.COLLECTION.COMMON:COMMON.ALL_GROUP")}</label>
        </div>
      </div>
      <div>
        <$Select
          name="creditorGroup"
          mode="multiple"
          allowClear
          size="large"
          formitem={{}}
          options={creditorGroups}
          loading={isGroupsLoading}
          disabled={isGroupsLoading}
          optionValue="id"
          optionText="groupName"
          onSelect={handleOnSelect}
          onDeselect={handleOnDeselect}
        />
      </div>
    </>
  );
};
