import auth from "./auth";
import { EntitySagas } from "./Entity";
import { CreditorSelectionSagas } from "./CreditorSelection";
import { UserAuthorizationSagas } from './UserAuthorization';
import { DebtorSelectionSagas } from "./DebtorSelection";
import { MainSearchSaga } from './MainSearch';
import { addDebtorSagas } from "./NewDebtor";
import { UserInformationSagas } from "./UserInformation";
import { RoleManagementSagas } from './RoleManagement'
import { ActivityExecutionSagas } from './ActivityExecution'
import { UserManagementSagas } from './UserManagement'

export default [
  ...auth,
  ...EntitySagas,
  ...CreditorSelectionSagas,
  ...UserAuthorizationSagas,
  ...MainSearchSaga,
  ...DebtorSelectionSagas,
  ...addDebtorSagas,
  ...UserInformationSagas,
  ...RoleManagementSagas,
  ...ActivityExecutionSagas,
  ...UserManagementSagas
];
