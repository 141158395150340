import React from "react";
import * as Components from "antd";
import { $AmountLabel } from "us.common/components/antd";
import * as Utility from "../utility";
import { useField, FormikValues } from "formik";
import { ISelectParams } from "us.common/components/antd/Interfaces";
import { useTranslation } from "react-i18next";
import { searchOptions } from "./Functions";

const Select = React.forwardRef(
  (
    {
      value,
      name,
      options,
      optionValue = "value",
      optionText = "label",
      optionExtra = undefined,
      virtual = false,
      placeholder,
      defaultValue,
      defaultLabel,
      hideSearch,
      allowClear,
      size,
      mode,
      onSearch,
      onChange,
      onSearchBy = [optionValue, optionText],
      notFoundContent,
      dropdownRender,
      tabIndex,
      dropdownMatchSelectWidth = true,
      dataTestid,
      bordered = true,
      optionLabelProp,
      getPopupContainer,
      ...rest
    }: ISelectParams,
    ref: any
  ) => {
    const [data, setData] = React.useState<any>([]);
    const [field, meta, helpers] = useField<FormikValues>(name);

    React.useEffect(() => {
      if (typeof onSearchBy !== undefined) {
        setData(options);
      }
    }, [options]);
    return (
      <Components.Select
        {...field}
        tabIndex={tabIndex}
        defaultActiveFirstOption
        value={typeof value == "undefined" ? field?.value : value}
        placeholder={placeholder}
        showSearch={typeof hideSearch == "undefined" ? true : false}
        size={typeof size == "undefined" ? "small" : size}
        mode={mode}
        notFoundContent={notFoundContent}
        dropdownRender={dropdownRender}
        filterOption={false}
        virtual={virtual}
        defaultValue={defaultValue}
        ref={ref}
        bordered={bordered}
        data-testid={dataTestid}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={
          getPopupContainer
            ? getPopupContainer
            : (trigger: any) => trigger.parentNode
        }
        onChange={(val: any) => {
          onChange && onChange(val);
          helpers?.setValue(val);
        }}
        onSearch={(searchValue: any) => {
          setData(searchOptions(options, onSearchBy, searchValue));
          onSearch && onSearch(searchValue);
        }}
        allowClear={typeof allowClear == "undefined" ? false : true}
        {...rest}
        optionLabelProp={optionLabelProp}
      >
        {data?.map((item: any, index: number) => (
          <Components.Select.Option
            key={item.key ?? index.toString()}
            value={item?.[optionValue]}
          >
            <div className="d-flex flex-fill justify-content-between">
              <span>
                {optionExtra &&
                  optionExtra.hasOwnProperty("extraNumber") &&
                  `${item?.[optionExtra?.extraNumber]}
                  ${!optionExtra?.hideSeparator ? " - " : ""}`}
                <span>{item?.[optionText]}</span>
              </span>
              {optionExtra &&
              typeof optionExtra?.extraName !== "undefined" &&
              optionExtra.hasOwnProperty("isAmount") ? (
                <span className="text-muted font-sm">
                  Fee: <$AmountLabel value={item?.[optionExtra?.extraName]} />
                </span>
              ) : (
                <span className="text-muted font-sm">
                  {optionExtra &&
                    typeof optionExtra?.extraName !== "undefined" &&
                    item?.[optionExtra?.extraName]}
                </span>
              )}
            </div>
          </Components.Select.Option>
        ))}
      </Components.Select>
    );
  }
);

export default React.forwardRef(
  (
    {
      value,
      name,
      options,
      optionValue,
      optionText,
      defaultValue,
      allOption = true,
      dropdownRender,
      autoFocus,
      selectRef,
      ...restProps
    }: ISelectParams,
    ref: any
  ) => {
    if (typeof name == "undefined") {
      return (
        <Select
          value={value}
          options={options}
          optionValue={optionValue}
          optionText={optionText}
          name={"default"}
          {...restProps}
        />
      );
    }

    const [field, meta, helpers] = useField<FormikValues>(name);
    const { t } = useTranslation();

    const error = meta?.error;
    const isTouched = meta?.touched;

    const allOptions = allOption
      ? [
          {
            [optionValue as any]: defaultValue,
            [optionText as any]: "All",
          },
        ]
      : [];

    return (
      <>
        {restProps.hasOwnProperty("formitem") && (
          <Components.Form.Item
            label={restProps?.formitem?.label}
            className={restProps?.formitem?.className}
            style={restProps?.formitem?.style}
            extra={restProps?.formitem?.extra}
            {...restProps?.formitem?.props}
            help={isTouched && error && t(error as string)}
            validateStatus={Utility.validateStatus(error, isTouched)}
            required={typeof restProps.required == "undefined" ? false : true}
          >
            <Select
              value={value}
              options={allOptions.concat(options)}
              defaultValue={defaultValue}
              optionValue={optionValue}
              optionText={optionText}
              dropdownRender={dropdownRender}
              name={name}
              autoFocus={autoFocus}
              ref={selectRef}
              {...restProps}
            />
          </Components.Form.Item>
        )}

        {!restProps.hasOwnProperty("formitem") && (
          <Select
            value={value}
            options={allOptions.concat(options)}
            defaultValue={defaultValue}
            optionValue={optionValue}
            optionText={optionText}
            dropdownRender={dropdownRender}
            name={name}
            {...restProps}
          />
        )}
      </>
    );
  }
);
