import {
  IActivity,
  ISetting,
  IDefaultData,
} from "us.common/interfaces";
import { getParameterList, getActivityCode, convertEntityType, setExecuteDateTime } from "us.common/functions";


/**
 * @function
 * @description - repository function to prepare request object from form data for single activity execution endpoint
 * @param {any} this - formData
 * @param {Array<ISetting>} list - activity settings
 * @param {Array<IActivity>} activityList - list of activities
 * @param {IDefaultData} defaultData - default data that are not displayed to the user
 * @param {number} entityId entity Id
 * @param {string} entityType  entity Type
 * @returns request data
 */

export function ExecuteSingle(
  this: any,
  list: Array<ISetting>,
  activityList: Array<IActivity>,
  defaultData: IDefaultData,
  entityId: number = -1,
  entityType: string = '',
  isEnableParameter: boolean,
  entityTypeFilter?: string,
  version?: string
) {
  const { activityId } = this;

  const paramList = getParameterList(
    this,
    list,
    defaultData,
    entityId
  );
  return {
    uniqueId: -1,
    activityCode: getActivityCode(activityList, activityId, entityTypeFilter),
    executingDateTime: setExecuteDateTime(this, isEnableParameter),
    caseId: entityId,
    entityType: convertEntityType(entityType),
    parameterList: paramList,
    activityVersion: version
  };
}

