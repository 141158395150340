import React from "react";
import { FieldArray, useField } from "formik";
import { CheckOutlined } from "us.icons";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { IUserRole } from "us.common/interfaces";

const RoleSetUp = (props: any) => {
  const { values } = props;
  const { $Col, $Row } = Common.Components;
  const [field, meta, helpers] = useField("roles");
  const error = meta?.error;
  const isTouched = meta.touched;
  const { t } = useTranslation();
  return (
    <>
      <$Row gutter={[12, 12]} key="RoleSetup">
        <FieldArray
          name="roles"
          render={({ replace }) => (
            <>
              {values.roles.map((role: IUserRole, index: number) => {
                return (
                  <$Col key={role.roleId} span={8}>
                    <div
                      onClick={(e) => {
                        field.onChange(e);
                        replace(index, {
                          ...role,
                          isSelected: role.isSelected ? false : true,
                        });
                      }}
                      className={
                        role.isSelected
                          ? "role-setup-items mb-2 d-flex align-items-center p-2 active"
                          : "role-setup-items mb-2 d-flex align-items-center p-2"
                      }
                    >
                      <div
                        className={
                          role.isSelected
                            ? "item-icon-content mr-2 active"
                            : "item-icon-content mr-2"
                        }
                      >
                        <CheckOutlined />
                      </div>
                      <div
                        className="d-flex align-items-center flex-fill pl-2"
                        style={{ width: "85%" }}
                      >
                        <div className="d-flex ">{role.name}</div>
                      </div>
                    </div>
                  </$Col>
                );
              })}
            </>
          )}
        />
      </$Row>
      <$Row gutter={[12, 12]}>
        <div className="d-flex flex-grow-1 px-2">
          <div className="flex-fill">
            {error && isTouched ? (
              <div style={{ color: "red" }}>{t(error)}</div>
            ) : null}
          </div>
        </div>
      </$Row>
    </>
  );
};

export default RoleSetUp;
