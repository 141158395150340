import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDebtorSearchResult } from 'us.common/reducers/MainSearch/Interfaces';
import { IDebtor } from 'us.common/components/MainSearch/Interfaces';
import {
	$AmountLabel,
	$Avatar,
	$Button,
	$Col,
	$Row,
	$Table,
	$Tag,
	$Tooltip,
	ColumnsType,
} from 'us.common/components';
import { ResultView } from './ResultView';
import { getRouteUrl } from 'us.helper';
import { useHistory } from 'react-router-dom';
import { DownOutlined, UpOutlined } from 'us.icons';

/**
 * @description - Columns component for debtor result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const Debtor: React.FC<IDebtor> = (props) => {
	const {
		debtorData,
		currentHit,
		totalSearchResult,
		paginationChangeEvent,
		aRClickEvent,
	} = props;
	const { t } = useTranslation();
	const history = useHistory();

	/**
	 * Handle the row click event of dashboard view
	 * @param e click event.
	 * @param id case, ar or creditor number.
	 * @param path route name.
	 */
	const rowClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		id: number | string,
		path: 'case' | 'ar' | 'creditor'
	) => {
		e.preventDefault();
		if (window.APP_MODULE == 'collection') {
			if (e.ctrlKey) {
				getRouteUrl.moduleRoute(
					'collection',
					`/${path}/${id}`
				);
			} else {
				history.push(`/${path}/${id}`);
			}
		} else {
			getRouteUrl.moduleRoute('collection', `/${path}/${id}`);
		}
	};

	//AR Table
	function NestedTable(values: any) {
		const expandedRowRender = (...value: any) => {
			const arCaseColumns = [
				{
					title: t(
						'US.COLLECTION.COMMON:COMMON.CASE_NO'
					),
					dataIndex: 'caseNo',
					key: 'caseNo',
					className: 'pl-5',
					width: '247px',
					render: (text: any, record: any) => (
						<div className='d-flex align-items-center'>
							{record.isOpen ? (
								<$Tooltip title='Open case'>
									<div
										className={`row-tag tag-status-open`}
										style={{
											width: '5px',
										}}></div>
								</$Tooltip>
							) : (
								<$Tooltip title='Close case'>
									<div
										className={`row-tag tag-status-close`}
										style={{
											width: '5px',
										}}></div>
								</$Tooltip>
							)}
							<a
								onClick={(
									e: React.MouseEvent<
										HTMLAnchorElement,
										MouseEvent
									>
								) =>
									rowClick(
										e,
										record.caseNo,
										'case'
									)
								}>
								<span data-testid='searchResult-debtorCaseNavigation'>
									{text}
								</span>
							</a>
						</div>
					),
				},
				{
					title: t(
						'US.COLLECTION.COMMON:COMMON.INVOICE_NO'
					),
					dataIndex: 'invoiceNo',
					key: 'invoiceNo',
					width: '200px',
				},
				{
					title: t(
						'US.COLLECTION.COMMON:COMMON.MAIN_AMOUNT'
					),
					dataIndex: 'mainAmount',
					key: 'mainAmount',
					width: '200px',
					render: (text: any, record: any) => {
						return (
							<span>
								{
									<$AmountLabel
										value={parseFloat(
											record.mainAmount
										)}
									/>
								}
							</span>
						);
					},
				},
				{
					title: t(
						'US.COLLECTION.COMMON:COMMON.TOTAL_BALANCE'
					),
					dataIndex: 'totalBalance',
					key: 'totalBalance',
					render: (text: any, record: any) => {
						return (
							<span>
								{
									<$AmountLabel
										value={parseFloat(
											record.totalBalance
										)}
									/>
								}
							</span>
						);
					},
				},
			];
			const arCaseData = [];
			for (let i = 0; i < values.data.arDetails.length; ++i) {
				for (
					let j = 0;
					j <
					values.data.arDetails[i].caseDetails
						.length;
					++j
				) {
					if (
						value[0].ArNo ===
						values.data.arDetails[i].arNo
					)
						arCaseData.push({
							key: i + j,
							caseNo: values.data
								.arDetails[i]
								.caseDetails[j]
								.caseNo,
							caseId: values.data
								.arDetails[i]
								.caseDetails[j]
								.caseId,
							caseType: values.data
								.arDetails[i]
								.caseDetails[j]
								.caseType,
							invoiceNo: values.data
								.arDetails[i]
								.caseDetails[j]
								.ref,
							mainAmount: values.data
								.arDetails[i]
								.caseDetails[j]
								.amount,
							totalBalance:
								values.data
									.arDetails[
									i
								].caseDetails[j]
									.balance,
							isOpen: values.data
								.arDetails[i]
								.caseDetails[j]
								.isOpen,
						});
				}
			}
			return (
				<$Table
					rowKey={(record) => record?.key}
					columns={arCaseColumns}
					dataSource={arCaseData}
					pagination={false}
					className='ar-case-list'
				/>
			);
		};

		const arColumns = [
			{
				title: t('US.COLLECTION.COMMON:COMMON.AR_NO'),
				dataIndex: 'ArNo',
				key: 'ArNo',
				width: '200px',
				render: (text: any, record: any) => (
					<a
						onClick={(
							e: React.MouseEvent<
								HTMLAnchorElement,
								MouseEvent
							>
						) =>
							rowClick(
								e,
								record.ArNo,
								'ar'
							)
						}>
						<span data-testid='searchResult-debtorARNavigation'>
							{text}
						</span>
					</a>
				),
			},
			{
				title: t('US.COLLECTION.COMMON:COMMON.CUST_ID'),
				dataIndex: 'CustId',
				key: 'CustId',
				width: '200px',
			},
			{
				title: t(
					'US.COLLECTION.COMMON:COMMON.CREDITOR'
				),
				dataIndex: 'Creditor',
				key: 'Creditor',
				render: (text: any, record: any) => {
					return (
						<a
							onClick={(
								e: React.MouseEvent<
									HTMLAnchorElement,
									MouseEvent
								>
							) =>
								rowClick(
									e,
									record.CreditorId,
									'creditor'
								)
							}>
							<span data-testid='searchResult-debtorCreditorNavigation'></span>
							{text}
						</a>
					);
				},
			},
		];

		const arData = [];
		for (let i = 0; i < values?.data.arDetails?.length; ++i) {
			arData.push({
				key: i + 1,
				ArNo: values.data.arDetails[i].arNo,
				CustId: values.data.arDetails[i].custId,
				Creditor: `${values.data.arDetails[i].creditorNumber} - ${values.data.arDetails[i].creditorName}`,
				CreditorId: values.data.arDetails[i]
					.creditorNumber,
			});
		}

		return (
			<$Table
				rowKey={(record) => record?.key}
				columns={arColumns}
				expandable={{
					expandedRowRender,
					defaultExpandAllRows:
						arData.length === 1,
				}}
				dataSource={arData}
				pagination={false}
			/>
		);
	}

	const columns: ColumnsType<IDebtorSearchResult> = [
		{
			dataIndex: 'type',
			key: 'type',
			className: 'item-type',
			width: '50px',
			render: (subcaseno: number) => {
				return (
					<div>
						<$Avatar
							shape='square'
							size='small'>
							De
						</$Avatar>
					</div>
				);
			},
		},
		{
			dataIndex: 'result',
			key: 'result',
			className: 'pb-0',
			render: (
				subcaseno: number,
				values: IDebtorSearchResult
			) => {
				return (
					<div>
						<$Row gutter={16}>
							<$Col span={8}>
								<div>
									<a>
										<h3 className='mb-0'>
											{
												values.debtorName
											}
										</h3>
									</a>
								</div>
								<div className='d-flex align-items-start mt-1'>
									<$Tag className='tag-status-open'>
										{
											values.openCount
										}
									</$Tag>
									<span className='ml-1'>
										{t(
											'US.COLLECTION.COMMON:COMMON.OPENCASES'
										)}
									</span>
									<$Tag className='ml-4 tag-status-close'>
										{
											values.closeCount
										}
									</$Tag>
									<span className='ml-1'>
										{t(
											'US.COLLECTION.COMMON:COMMON.CLOSEDCASES'
										)}
									</span>
								</div>
							</$Col>
							<$Col span={4}>
								<div className='d-flex align-items-start'>
									<div className='pr-5'>
										<label>
											{t(
												'US.COLLECTION.COMMON:COMMON.TOTALBALANCE'
											)}
										</label>
										<h4 className='mb-0'>
											<$AmountLabel
												value={
													values.totalCaseBalance
												}
											/>
										</h4>
									</div>
								</div>
							</$Col>
							<$Col span={8}>
								<div className='mt-2'>
									{/*Toggle Button */}
									<a
										className='ant-dropdown-link'
										onClick={(
											e: React.MouseEvent<
												HTMLAnchorElement,
												MouseEvent
											>
										) => {
											e.preventDefault();
											aRClickEvent(
												values
											);
										}}
										data-testid='searchResults-debtorARExpand'>
										{' '}
										AR{' '}
										<$Button
											className='ml-2'
											size='small'>
											{values.isARCollapsed ? (
												<DownOutlined />
											) : (
												<UpOutlined />
											)}
										</$Button>
									</a>
								</div>
							</$Col>
						</$Row>
						{/*Toggle Area */}
						{!values.isARCollapsed && (
							<$Row gutter={16}>
								<$Col span={24}>
									<div className='p-3 mt-2 ar-table'>
										<NestedTable
											data={
												values
											}
										/>
									</div>
								</$Col>
							</$Row>
						)}
					</div>
				);
			},
		},
	];

	return (
		<ResultView
			rowKey='debtorEntNo'
			currentHit={currentHit}
			dataSource={debtorData}
			paginationChangeEvent={paginationChangeEvent}
			tableClassName='result-table debtor-result-table'
			tableColumns={columns}
			totalSearchResult={totalSearchResult}
		/>
	);
};
