import { DebtorSelection } from "us.common/constants";
import { getDebtorDetails } from "us.common/functions";
import { initialState } from "./State";
const {
  GET_DEBTOR_LIST_START,
  GET_DEBTOR_LIST_SUCCESS,
  GET_DEBTOR_LIST_FAIL,
  RESET_DEBTOR_SELECTION_TABLE,
  ADD_DEBTOR_START,
  ADD_DEBTOR_SUCCESS,
  ADD_DEBTOR_FAIL,
  ADD_DEBTOR_DRAWER
} = DebtorSelection;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_DEBTOR_LIST_START:
      return Object.assign({}, state, {
        debtors: {
          data: [],
          isLoading: true,
        },
      });
    case GET_DEBTOR_LIST_SUCCESS:
      return Object.assign({}, state, {
        debtors: {
            data: getDebtorDetails(payload?.data),
            isLoading: false,
          },
      });
    case GET_DEBTOR_LIST_FAIL:
      return Object.assign({}, state, {
        debtors: {
            data: [],
            isLoading: false,
        },
      });
    case RESET_DEBTOR_SELECTION_TABLE:
      return Object.assign({}, state, {
        debtors: {
            data: [],
            isLoading: false,
        },
      });
    case ADD_DEBTOR_START:
      return Object.assign({}, state, {
      });
    case ADD_DEBTOR_SUCCESS:
      return Object.assign({}, state, {
        addDebtorDrawer: { title: "", visible: false}
      });
    case ADD_DEBTOR_FAIL:
      return Object.assign({}, state, {
        });
    case ADD_DEBTOR_DRAWER:
      return Object.assign({}, state, {
        addDebtorDrawer: payload?.data
      });
    default:
      return state;
  }
};
