import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { CourtInfo } from "us.collection.admin/constants/Actions";
import Common from "us.common";
import CommonAPI from "us.common/services";
import _ from "lodash";

const { $MessageBox } = Common.Components;

const {
  getCourtTypes,
  getRelevantPostalCodes,
  getCourtItems,
  deleteCourtItem,
  initCourtInfo,
  addCourtType,
  editCourtType,
  deleteCourtType,
  addCourtItem,
} = API.courtInfo.courtInfo;

const { courtInfo } = Actions;

const {
  courtTypes,
  relevantPostalCodesByItem,
  courtItems,
  postalAreas,
  applicablePostalAreas,
} = courtInfo;

const CourtInfoSagas = {
  courtInfo: {
    init: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { courtInfo, courtTypesData } = yield call(initCourtInfo, params);
        if (courtInfo.status === 200) {
          yield put(courtItems.success(courtInfo.data));
        } else {
          yield put(courtItems.fail([]));
        }
        if (courtTypesData.status === 200) {
          yield put(courtTypes.success(courtTypesData.data));
        } else {
          yield put(courtTypes.success([]));
        }
      } catch (error) {
        yield put(courtTypes.success({}));
        yield put(courtItems.fail(new Error()));
      }
    },
    getCourtTypes: function* (action: any) {
      try {
        const { data } = yield call(getCourtTypes);
        yield put(courtTypes.success(data));
      } catch (error) {
        yield put(courtTypes.fail([]));
      }
    },
    getCourtItems: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getCourtItems, params);
        if (status === 200) {
          yield put(courtItems.success(data));
        } else {
          yield put(courtItems.fail([]));
        }
      } catch (error) {
        yield put(courtItems.fail([]));
      }
    },
    getCourtItemRelevantPostalCodes: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getRelevantPostalCodes, params);
        if (status === 200) {
          yield put(relevantPostalCodesByItem.success(data));
        }
      } catch (error) {
        yield put(relevantPostalCodesByItem.success([]));
      }
    },
    deleteCourtItem: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, courtData } = yield call(deleteCourtItem, params);
        if (data?.status === 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_IS_DELETED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(courtItems.success(courtData.data));
        } else {
          yield put(courtItems.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_DELETE_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtItems.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_ITEM_DELETE_FAILED`,
          "",
          ""
        );
      }
    },
    updateCourtItem: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, courtData } = yield call(addCourtItem, params);
        if (data?.status == 201 || data?.status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_IS_UPDATED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(courtItems.success(courtData?.data));
        } else if (data?.status == 409) {
          yield put(courtItems.fail([]));
          $MessageBox("error", `${data?.data?.errors[0]?.message}`, "", "");
        } else {
          yield put(courtItems.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_UPDATE_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtItems.fail(new Error()));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_ITEM_UPDATE_FAILED`,
          "",
          ""
        );
      }
    },
    addNewCourtType: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, courtTypeData } = yield call(addCourtType, params);
        if (data?.status == 201 || data?.status == 200) {
          yield put(courtTypes.success(courtTypeData?.data));
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_WAS_SUCCESSFULLY_SAVED`,
            "",
            ""
          );
        } else {
          yield put(courtTypes.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_CREATION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtTypes.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_TYPE_CREATION_FAILED`,
          "",
          ""
        );
      }
    },
    editCourtType: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, courtTypeData } = yield call(editCourtType, params);
        if (data?.status == 201 || data?.status == 200) {
          yield put(courtTypes.success(courtTypeData?.data));
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_IS_UPDATED_SUCCESSFULLY`,
            "",
            ""
          );
        } else {
          yield put(courtTypes.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_UPDATE_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtTypes.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_TYPE_UPDATE_FAILED`,
          "",
          ""
        );
      }
    },
    deleteCourtType: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, typesData } = yield call(deleteCourtType, params);
        if (data?.status == 200 || data?.status == 201) {
          yield put(courtTypes.success(typesData?.data));
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_IS_SUCCESSFULLY_DELETED`,
            "",
            ""
          );
        } else if (data?.status == 409) {
          yield put(courtTypes.fail([]));
          $MessageBox("error", `${data?.data?.errors[0]?.message}`, "", "");
        } else {
          yield put(courtTypes.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_TYPE_DELETE_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtTypes.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_TYPE_DELETE_FAILED`,
          "",
          ""
        );
      }
    },
    addNewCourtItem: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, courtData } = yield call(addCourtItem, params);
        if (data?.status == 201 || data?.status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_WAS_SUCCESSFULLY_SAVED`,
            "",
            ""
          );
          yield put(courtItems.success(courtData?.data));
        } else if (data?.status == 409) {
          yield put(courtItems.fail([]));
          $MessageBox("error", `${data?.data?.errors[0]?.message}`, "", "");
        } else {
          yield put(courtItems.fail([]));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.COURT_ITEM_CREATION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(courtItems.fail([]));
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:COURT.COURT_ITEM_CREATION_FAILED`,
          "",
          ""
        );
      }
    },
    getPostalAreas: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(CommonAPI.entityDetails.getPostelArea, params);
        if (!_.isEmpty(data)) {
          yield put(postalAreas.success(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.PLEASE_SELECT_VALID_POSTAL_CODE`,
            "",
            ""
          );
          yield put(postalAreas.fail([]));
        }
      } catch (error) {
        yield put(postalAreas.fail([]));
      }
    },
    getApplicablePostalAreas: function* (action: any) {
      const {countryCode,searchBy,searchText,selectedApplicableCodes} = action?.payload?.data ?? {};

      const payload = {
        countryCode,
        searchBy,
        searchText,
      }
      try {
        const data = yield call(CommonAPI.entityDetails.getPostelArea, payload);
        if (!_.isEmpty(data)) {
          yield put(applicablePostalAreas.success([...selectedApplicableCodes, ...data]));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:COURT.PLEASE_SELECT_VALID_POSTAL_CODE`,
            "",
            ""
          );
          yield put(applicablePostalAreas.fail([]));
        }
      } catch (error) {
        yield put(applicablePostalAreas.fail([]));
      }
    },
  },
};

export default [
  takeLatest(CourtInfo.INIT_GET_COURT_INFO, CourtInfoSagas.courtInfo.init),
  takeLatest(CourtInfo.GET_COURT_TYPES, CourtInfoSagas.courtInfo.getCourtTypes),
  takeLatest(
    CourtInfo.GET_RELEVANT_POSTAL_AREAS,
    CourtInfoSagas.courtInfo.getCourtItemRelevantPostalCodes
  ),
  takeLatest(
    CourtInfo.UPDATE_COURT_ITEM,
    CourtInfoSagas.courtInfo.updateCourtItem
  ),
  takeLatest(CourtInfo.GET_COURT_INFO, CourtInfoSagas.courtInfo.getCourtItems),
  takeLatest(
    CourtInfo.DELETE_COURT_ITEM,
    CourtInfoSagas.courtInfo.deleteCourtItem
  ),
  takeLatest(
    CourtInfo.ADD_NEW_COURT_TYPE,
    CourtInfoSagas.courtInfo.addNewCourtType
  ),
  takeLatest(CourtInfo.EDIT_COURT_TYPE, CourtInfoSagas.courtInfo.editCourtType),
  takeLatest(
    CourtInfo.DELETE_COURT_TYPE,
    CourtInfoSagas.courtInfo.deleteCourtType
  ),
  takeLatest(
    CourtInfo.ADD_NEW_COURT_ITEM,
    CourtInfoSagas.courtInfo.addNewCourtItem
  ),
  takeLatest(
    CourtInfo.GET_POSTAL_AREAS_FOR_COURT,
    CourtInfoSagas.courtInfo.getPostalAreas
  ),
  takeLatest(
    CourtInfo.GET_APPLICABLE_POSTAL_AREAS_FOR_COURT,
    CourtInfoSagas.courtInfo.getApplicablePostalAreas
  ),
];
