import React, { useEffect, memo } from "react";
import {
  $Affix,
  $Switch,
  $Tooltip,
  $PageHeader,
  $Divider,
  $Button,
  $Drawer,
} from "us.common/components";
import { PlusOutlined, ReloadOutlined } from "us.icons";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import "./Styles.scss";
import { RootState } from "us.helper/types";
import { connect, ConnectedProps } from "react-redux";
import { EntityPanel, ActivityTable, ActivityDetail } from "./Components";
import { manageActivities } from "us.collection.admin/actions";
import { useHistory, useLocation } from "react-router-dom";
import { initialStepDetail } from "us.collection.admin/reducers/ManageActivities/State";

const { activities, activity } = manageActivities;

/**
 * @description -  Activity Summary Home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3001221121/Get+Activity+Summary+UI+Implementation
 * @author Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 31/05/2022
 */
const ActivitySummary: React.FC<PropsFromRedux> = memo((props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { goBack, replace, action } = history;
  const { state } = useLocation();
  const {
    getActivities,
    drawer,
    manageDrawer,
    manageBreadCrumb,
    setStepDetail,
  } = props;
  const { isVisible, isEdit } = drawer;

  useEffect(() => {
    if(action !== "REPLACE"){
      getActivities && getActivities({});
    }
  }, [action]);

  /**
   * @function
   * @description Close drawer and breadcrumb
   */
  const onClose = () => {
    manageDrawer && manageDrawer({ isVisible: false, isEdit: false });
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: false,
        title: "",
      });
    setStepDetail && setStepDetail({ ...initialStepDetail });
  };

  /**
   * @function
   * @description handle back button navigation
   */
  const onBack = (): void => {
    const { length, push } = history;
    length <= 1 ? push("/") : goBack();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{ activitiesSearch: "", expandAll: true }}
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...rest
      }: any) => (
        <>
          <div>
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="pl-2"
                      title={t(
                        "US.COLLECTION.ADMIN:ACTIVITIES.MANAGE_ACTIVITIES"
                      )}
                      onBack={onBack}
                    />
                    <$Divider className="bui-devider" type="vertical" />

                    <$Button
                      type="default"
                      size="small"
                      className="mr-2"
                      data-testid='new-activity'
                      icon={<PlusOutlined />}
                      onClick={() =>
                        manageDrawer &&
                        manageDrawer({ isVisible: true, isEdit: false })
                      }
                    >
                      {t("US.COLLECTION.ADMIN:ACTIVITIES.NEW_ACTIVITY")}
                    </$Button>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-1 align-items-center">
                      <div className="mr-2">
                        <$Switch
                          name="expandAll"
                          checked={values.expandAll}
                          onChange={(value: any) =>
                            setFieldValue("expandAll", value)
                          }
                        />
                      </div>
                      <div style={{ marginTop: 3 }}>
                        {t("US.COLLECTION.ADMIN:ACTIVITIES.EXPAND_ALL")}
                      </div>
                    </div>
                    <$Tooltip
                      placement="right"
                      title={t("US.COLLECTION.ADMIN:ACTIVITIES.REFRESH")}
                    >
                      <$Button
                        className="ml-4"
                        icon={<ReloadOutlined />}
                        type="dashed"
                        size="small"
                        onClick={() => {
                          replace({ state });
                          setFieldValue("activitiesSearch", "");
                          getActivities && getActivities({});
                        }}
                      />
                    </$Tooltip>
                  </div>
                </div>
              </div>
            </$Affix>
          </div>
          <div className="activities-layout">
            <EntityPanel />
            <ActivityTable />
          </div>
          <$Drawer
            title={t(
              `US.COLLECTION.ADMIN:ACTIVITIES.${
                isEdit ? "EDIT" : "ADD_NEW"
              }_ACTIVITY`
            )}
            width={1000}
            visible={isVisible}
            onClose={onClose}
            destroyOnClose
            className="activities-add"
          >
            <ActivityDetail />
          </$Drawer>
        </>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { drawer } = manageActivities;
  return { drawer };
};

const mapDispatchToProps = {
  getActivities: activities.get,
  manageDrawer: activity.manageDrawer,
  manageBreadCrumb: activity.manageBreadCrumb,
  setStepDetail: activity.setStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivitySummary);
