import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import { useFormikContext } from "formik";
import { IRootState } from "us.collection/interfaces";
import {
  $Row,
  $Col,
  $TableTree,
  $Select,
  $Button,
  $Popconfirm,
  $Divider
} from "us.common/components";
import { manageFollowupActions } from "us.collection.admin/actions";
import { DeleteOutlined, PlusOutlined } from "us.icons";
import {
  handleTableData,
  handleDeleteActivity,
  generateActivities,
} from "./Functions";

const { activities } = manageFollowupActions;

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2943713306/Follow-up+Custom+Query+Save+View+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const ActivitySelection: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { values, setFieldValue }: { values: any; setFieldValue: any } =
    useFormikContext();

  const { getFollowUpActivities, activities } = props;
  const { data: allActivities } = activities;
  const { activitiesList } = values;

  useEffect(() => {
    const { params }: any = matchPath(pathname, {
      path: "/manage-followup/custom-query/:entityType?",
      exact: true,
      strict: false,
    });
    const entityType = params["entityType"];
    entityType &&
      getFollowUpActivities &&
      getFollowUpActivities({ entityType });
  }, []);

  /**
   * @function
   * @description - return the table columns for the activity list
   * @param {Array<any>} activitiesList - farm data value of activities list
   * @param {function} setFieldValue - formik setFieldValue function
   * @returns activitiesList column for the table
   */
  const activitiesListColumns = (
    activitiesList: Array<any>,
    setFieldValue: any
  ): Array<any> => {
    return [
      {
        title: "",
        key: "more",
        dataIndex: "more",
        width: "40px",
        customRenderChild: (text: any, record: any) => {
          return (
            <$Popconfirm
              placement="top"
              title={`${t(
                "US.COLLECTION.ADMIN:BULKOPERATION.ASSIGN_TO_DELETE_CONFIRM"
              )}?`}
              okText={t("US.COLLECTION.COMMON:COMMON.YES")}
              cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
              onConfirm={() =>
                handleDeleteActivity(activitiesList, record, setFieldValue)
              }
            >
              <$Button icon={<DeleteOutlined />} size="small" danger />
            </$Popconfirm>
          );
        },
      },
      {
        title: t("US.COLLECTION.ADMIN:BULKOPERATION.CODE"),
        dataIndex: "activityCode",
        key: "activityCode",
        width: 80,
        ellipsis: true,
      },
      {
        title: t("US.COLLECTION.ADMIN:BULKOPERATION.NAME"),
        dataIndex: "activityName",
        key: "activityName",
        ellipsis: true,
      },
      {
        title: t("US.COLLECTION.ADMIN:BULKOPERATION.DISPLAY_NAME"),
        dataIndex: "displayName",
        key: "displayName",
        ellipsis: true,
      },
    ];
  };

  return (
    <>
      <$Row gutter={8} className="mt-3">
        <$Col span={24}>
          <$Select
            size="small"
            name="activity"
            className="w-100"
            formitem={{
              label: t("US.COLLECTION.ADMIN:BULKOPERATION.ACTIVITIES"),
            }}
            allOption={false}
            dropdownMatchSelectWidth={false}
            options={generateActivities(values, allActivities)}
            optionText="displayName"
            optionValue="activityId"
            onSearchBy={["displayName", "activityCode"]}
            dataTestid={"activity-select"}
          />
        </$Col>
      </$Row>
      <$Divider orientation="left">
        <$Button
          onClick={() =>
            handleTableData(values, setFieldValue, allActivities)
          }
          size="small"
          icon={<PlusOutlined />}
          disabled={values?.activity == ""}
          data-testid={"addActivity-btn"}
        >
          {t("US.COLLECTION.ADMIN:BULKOPERATION.ADD_ACTIVITY")}
        </$Button>
      </$Divider>
      <$TableTree
        rowKey="key"
        data={activitiesList}
        size="small"
        className="csq-table my-3"
        filterOnType={true}
        resetOnSourceChange={true}
        bordered
        pagination={false}
        scroll={{ y: "calc(100vh - 545px)" }}
        columns={activitiesListColumns(activitiesList, setFieldValue)}
        data-testid={"activitiesList-table"}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery } = manageFollowups;
  const { activities } = customQuery;
  return { activities };
};

const mapDispatchToProps = {
  getFollowUpActivities: activities.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivitySelection);
