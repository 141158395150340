import { MainCriterias } from "us.common/constants";
import {
  IInvoiceSearchResult,
  ICaseSearchResult,
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
  IDebtorSearchResult,
  IDebtorARSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { IFormikFilterKeyValue, IFormikInitVal } from "../Interfaces";

/**
 * @function
 * @description To get update the formik state with the filtering values
 * @param setValues The formik prop to update the form values
 * @param currentTab The current tab selected
 * @param filterInfo The filtering values stored in the formik state
 */
export const handleFilterCriterias = (
  setValues: any,
  currentTab: MainCriterias,
  filterInfo: IFormikFilterKeyValue<
    IInvoiceSearchResult &
      ICaseSearchResult &
      ICreditorGroupSearchResult &
      ICreditorSearchResult &
      IDebtorSearchResult &
      IDebtorARSearchResult
  >
) => {
  let newInvoiceFilters;
  setValues((preVals: IFormikInitVal) => {
    switch (currentTab) {
      case MainCriterias.INVOICE:
        newInvoiceFilters = [
          ...preVals.tabInfo.invoice.filterKeyValues.map((keyvalue) =>
            keyvalue.key === filterInfo.key
              ? {
                  ...keyvalue,
                  value: filterInfo.value,
                }
              : keyvalue
          ),
        ];

        return {
          selectedTab: currentTab,
          tabInfo: {
            ...preVals.tabInfo,
            invoice: {
              ...preVals.tabInfo.invoice,
              filterKeyValues: newInvoiceFilters,
            },
          },
        };
      case MainCriterias.CASE:
        newInvoiceFilters = [
          ...preVals.tabInfo.case.filterKeyValues.map((keyvalue) =>
            keyvalue.key === filterInfo.key
              ? {
                  ...keyvalue,
                  value: filterInfo.value,
                }
              : keyvalue
          ),
        ];

        return {
          selectedTab: currentTab,
          tabInfo: {
            ...preVals.tabInfo,
            case: {
              ...preVals.tabInfo.case,
              filterKeyValues: newInvoiceFilters,
            },
          },
        };
      case MainCriterias.DEBTOR:
        newInvoiceFilters = [
          ...preVals.tabInfo.debtor.filterKeyValues.map((keyvalue) =>
            keyvalue.key === filterInfo.key
              ? {
                  ...keyvalue,
                  value: filterInfo.value,
                }
              : keyvalue
          ),
        ];

        return {
          selectedTab: currentTab,
          tabInfo: {
            ...preVals.tabInfo,
            debtor: {
              ...preVals.tabInfo.debtor,
              filterKeyValues: newInvoiceFilters,
            },
          },
        };
      case MainCriterias.CREDITOR:
        newInvoiceFilters = [
          ...preVals.tabInfo.creditor.filterKeyValues.map((keyvalue) =>
            keyvalue.key === filterInfo.key
              ? {
                  ...keyvalue,
                  value: filterInfo.value,
                }
              : keyvalue
          ),
        ];

        return {
          selectedTab: currentTab,
          tabInfo: {
            ...preVals.tabInfo,
            creditor: {
              ...preVals.tabInfo.creditor,
              filterKeyValues: newInvoiceFilters,
            },
          },
        };
      default:
        break;
    }
  });
};
