/**
 * @function
 * @description If the object has a length greater than 0, return the object, otherwise return an empty array.
 * @param {any} data - any
 * @returns An array of any type or an empty array.
 */
export const getData = (data: any): any[] | [] => {
  try {
    return Object.keys(data).length > 0 ? data : [];
  } catch (e) {
    return [];
  }
};
