import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { COLUMNS, FeeBasisTableColumns } from "../../Constants";
import { MoreOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { IFeeBasisTable } from "./interface";
import { IRootState } from "us.collection/interfaces";
import { IFeeBasisDetails } from "us.collection.admin/interfaces";
import { getColumnFilterProps, sortFeeBasicData } from "./function";
import { ItemMenu } from "us.collection.admin/components/ManageFee/Components";
import {
  ManageFeeAction,
  ITEM_MENU_DEFAULT_STATE,
  DrawerType,
} from "us.collection.admin/components/ManageFee/Constants";
import { IItemMenuPopup } from "us.collection.admin/components/ManageFee/Interfaces";
import moment from "moment";
import { useLocation } from "react-router-dom";

const { $Button, $AmountLabel, $DateLabel, $Popover, $TableTree } = Components;

/**
 * @description - Fee Basis Table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Table: React.FC<IFeeBasisTable & PropsFromRedux> = (props) => {
  const { onCallAction, onDoubleClickHandler, feeBasis } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const feeId = urlParams.get("feeId");

  const [itemMenu, setItemMenu] = useState<IItemMenuPopup>(
    ITEM_MENU_DEFAULT_STATE
  );
  // auto close invoice item menu after 5 seconds
  const setAutoClose = () => {
    setTimeout(() => {
      setItemMenu(ITEM_MENU_DEFAULT_STATE);
    }, 5000);
  };

  /**
   * @description - render rows
   */
  const renderCell = (record: IFeeBasisDetails, dataIndex: string) => {
    const { feeId, amount, fromDate, debtorType } = record;
    const row = {
      children: (
        <>
          {dataIndex === FeeBasisTableColumns.MENU && (
            <div className="align-items-center" key={feeId}>
              <$Popover
                placement="right"
                trigger="click"
                data-testid="popover-btn"
                visible={itemMenu.visible && itemMenu.id == feeId}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: feeId,
                  });
                  setAutoClose();
                }}
                content={
                  <ItemMenu
                    onCallAction={(
                      drawerType: DrawerType,
                      actionType: ManageFeeAction
                    ) => {
                      setItemMenu(ITEM_MENU_DEFAULT_STATE);
                      onCallAction(drawerType, actionType, record);
                    }}
                    drawerType={DrawerType.FEE_BASIS}
                    isPastDate={moment(fromDate).isBefore(moment())}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  id="btnItemMenu"
                  type="default"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          )}
          {dataIndex === FeeBasisTableColumns.DATE && (
            <$DateLabel value={fromDate} />
          )}
          {dataIndex === FeeBasisTableColumns.AMOUNT && (
            <$AmountLabel value={amount} />
          )}
          {dataIndex === FeeBasisTableColumns.DEBTOR_TYPE && (
            <span data-testid={`debtorType`}>{debtorType}</span>
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map(({ key, title }, _index) => {
      let column: any = {
        dataIndex: key,
        key,
        title: title && t(`${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) => renderCell(record, key),
        align: key === FeeBasisTableColumns.AMOUNT ? "right" : "left",
        className:
          key === FeeBasisTableColumns.AMOUNT
            ? "right-has-sort right-has-filter"
            : "",
        width: key === FeeBasisTableColumns.MENU ? 45 : "",
      };
      if (key !== FeeBasisTableColumns.MENU) {
        column = {
          ...column,
          customSorter: (a: any, b: any) => {
            if (key === FeeBasisTableColumns.DATE) {
              return new Date(a).getTime() - new Date(b).getTime();
            } else if (key === FeeBasisTableColumns.AMOUNT) {
              return a - b;
            } else {
              return a.localeCompare(b);
            }
          },
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <>
      <$TableTree
        rowKey="feeId"
        data={sortFeeBasicData(feeBasis?.list)}
        data-testid="fee-basis-table"
        size="small"
        columns={getColumns()}
        className="mt-3 header-custom-tag"
        rowClassName={(record: any) =>
          record?.feeId == feeId ? "selected-fee-row" : ""
        }
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        onRow={(record, _rowIndex) => {
          return {
            onDoubleClick: (_event) => onDoubleClickHandler(record.feeId),
          };
        }}
        filterOnType
        resetOnSourceChange
        bordered
        showResetAllFilters
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{ x: 520, y: "calc(100vh - 315px)" }}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeBasis } = manageFee;
  return {
    feeBasis,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
