import { IconType } from 'antd/lib/notification';
import ServiceConfig from 'service.config.js';
import { $Notification } from 'us.common/components';
import { versionedHttpCollection } from 'us.helper/http/collection';

const openNotificationWithIcon = (
	type: IconType,
	msg: string,
	description: string,
	key: any
) => {
	$Notification[type]({
		message: msg,
		description: description,
		key,
	});
};

export const Invoice = {
	search: async <P>(params: P): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data, status, statusText } = await versionedHttpCollection.post(
					'searchservice',
					'invoices',
					params,
					ServiceConfig()[`searchservice`]['search.invoice']
				);
				if (status === 200 || status === 204) {
					resolve(data);
				} else {
					openNotificationWithIcon(
						'error',
						status.toString(),
						statusText,
						Math.random()
					);
					reject(data);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
};

export const CaseDetail = {
	search: async <P extends {}>(params: P): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data, status, statusText } = await versionedHttpCollection.post(
					'searchservice',
					'cases',
					params,
					ServiceConfig()[`searchservice`]['search.case']
				);
				if (status === 200 || status === 204) {
					resolve(data);
				} else {
					openNotificationWithIcon(
						'error',
						status.toString(),
						statusText,
						Math.random()
					);
					reject(data);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
};

export const DebtorDetail = {
	search: async <P>(params: P): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data, status, statusText } =
					await versionedHttpCollection.post<P>(
						'searchservice',
						'debtors',
						params,
						ServiceConfig()[`searchservice`]['search.debtor']
					);
				if (status === 200 || status === 204) {
					resolve(data);
				} else {
					openNotificationWithIcon(
						'error',
						status.toString(),
						statusText,
						Math.random()
					);
					reject(data);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
};

export const CreditorDetail = {
	search: async <P>(params: P): Promise<any> => {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const { data, status, statusText } = await versionedHttpCollection.post(
					'searchservice',
					'creditors',
					params,
					ServiceConfig()[`searchservice`]['search.creditor']
				);
				if (status === 200 || status === 204) {
					resolve(data);
				} else {
					openNotificationWithIcon(
						'error',
						status.toString(),
						statusText,
						Math.random()
					);
					reject(data);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
};

export const history = {
	get: async <P>(): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data } = await versionedHttpCollection.get(
					'searchservice',
					'history',
					{},
					ServiceConfig()[`searchservice`]['search.history']
				);
				resolve(data);
			} catch (error) {
				reject(error);
			}
		});
	},
	save: async <P>(params: P): Promise<any> => {
		return new Promise<{}>(async (resolve, reject) => {
			try {
				const { data, status, statusText } = await versionedHttpCollection.post(
					'searchservice',
					'history',
					params,
					ServiceConfig()[`searchservice`]['search.history']
				);
				if (status === 200 || status === 204) {
					resolve(data);
				} else {
					openNotificationWithIcon(
						'error',
						status.toString(),
						statusText,
						Math.random()
					);
					reject(data);
				}
			} catch (error) {
				reject(error);
			}
		});
	},
};
