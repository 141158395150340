import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";

import Common from "us.common";
import { ISettingDetails } from "./Interfaces";
import { IRootState } from "us.collection/interfaces";
import { DrawerType } from "us.collection.admin/components/SystemSettings/Constants";
import { getSystemSettingById } from "us.collection.admin/components/SystemSettings/Functions";
import { SettingDetailsValidationSchema } from "./Validations";
import {
  AddSystemSetting,
  UpdateSystemSetting,
} from "us.collection.admin/components/SystemSettings/Repositories";
import * as Actions from "us.collection.admin/actions";
import { getSettingTypeNameByTypeId, isChangedFormValues } from "./Functions";
import { PlusOutlined } from "us.icons";
import { SettingType } from "us.collection.admin/interfaces";

const {
  $Button,
  $Form,
  $Input,
  $Select,
  $TextArea,
  $Popconfirm,
  $Divider,
  $MessageBox,
  $Checkbox,
} = Common.Components;

/**
 * @description -  System settings home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2901967003/View+System+Settings
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 16/03/2022
 */
const SettingDetails: React.FC<PropsFromRedux & ISettingDetails> = (props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const settingId = urlParams.get("id");
  const typeId = urlParams.get("type-id");

  const {
    type,
    settingUpdate,
    systemSettings,
    settingSave,
    onClose,
    addSetting,
    updateSetting,
    onChange,
    addNewSettingType,
  } = props;
  const { data, settingTypes } = systemSettings;
  const setting = getSystemSettingById(data, settingId);

  useEffect(() => {
    if (
      (!settingUpdate.isLoading &&
        settingUpdate.data.hasOwnProperty("systemSettingsId") &&
        settingUpdate.data?.systemSettingsId > 0) ||
      (!settingSave.isLoading &&
        settingSave.data.hasOwnProperty("systemSettingsId") &&
        settingSave.data?.systemSettingsId > 0)
    ) {
      onClose();
    }
  }, [settingUpdate, settingSave]);

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   */
  const handleSubmit = (values: any) => {
    const typeName = getSettingTypeNameByTypeId(settingTypes, values.typeId);
    if (type === DrawerType.ADD) {
      const payload = AddSystemSetting.call({
        ...values,
        typeName,
      });
      addSetting && addSetting(payload);
    } else {
      const payload = UpdateSystemSetting.call({
        ...values,
        typeName,
      });
      updateSetting && updateSetting(payload);
    }
  };

  /**
   * @description - Handle when any field value change
   * @param {any} restProps - formik rest props
   * @param {string} value - Field current value
   * @param {string} name - Field name
   */
  const handleOnFieldChange = (restProps: any, value: string, name: string) => {
    restProps.setFieldValue(name, value);
    onChange(true);
  };

  const handleAddNewTypeName = (values: any) => {
    if (values.newTypeName) {
      // check the object already included or not
      const isInclude = settingTypes.some(
        (settingType: SettingType) =>
          settingType.typeName.toLowerCase() ===
          values.newTypeName.toLowerCase()
      );

      if (!isInclude) {
        const newSettingType: SettingType = {
          typeId: -1,
          typeName: values.newTypeName,
        };
        addNewSettingType && addNewSettingType(newSettingType);
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SETTING_TYPE_ALREADY_EXISTS!",
          "",
          ""
        );
      }
    } else {
      $MessageBox(
        "warning",
        "US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SETTING_TYPE_SHOULD_NOT_BE_EMPTY",
        "",
        ""
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        isSensitiveData: true,
        ...setting,
        typeId: typeId ? parseInt(typeId) : undefined,
      }}
      validationSchema={SettingDetailsValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        isValid,
        ...restProps
      }: any) => (
        <>
          <div className="sc-add">
            <$Form>
              <$Select
                name="typeId"
                formitem={{
                  label: t(
                    "US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_TYPE"
                  ),
                }}
                size="small"
                options={settingTypes}
                optionValue="typeId"
                optionText="typeName"
                allOption={false}
                disabled={settingId !== null}
                required
                tabIndex={1}
                autoFocus
                onSearchBy={["typeName"]}
                showSearch
                onChange={(value: string) => {
                  handleOnFieldChange(restProps, value, "typeId");
                }}
                dropdownRender={(menu: any) => (
                  <div>
                    {menu}
                    <$Divider
                      style={{
                        margin: "4px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <$Input
                        style={{
                          flex: "auto",
                        }}
                        name="newTypeName"
                        maxLength={50}
                        size="small"
                        className="w-100"
                        autoFocus
                      />
                      <a
                        style={{
                          flex: "none",
                          padding: "8px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={() => handleAddNewTypeName(values)}
                      >
                        <PlusOutlined />
                        {t("US.COLLECTION.COMMON:COMMON.ADD")}
                      </a>
                    </div>
                  </div>
                )}
              />
              <$Input
                name="name"
                size="small"
                label={t("US.COLLECTION.ADMIN:SYSTEM_SETTINGS.NAME")}
                className="w-100"
                required
                disabled={settingId !== null}
                tabIndex={2}
                maxLength={50}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  handleOnFieldChange(restProps, value, "name");
                }}
              />
              <$Input
                name="value"
                size="small"
                label={t("US.COLLECTION.ADMIN:SYSTEM_SETTINGS.VALUE")}
                className="w-100"
                tabIndex={3}
                maxLength={100}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  handleOnFieldChange(restProps, value, "value");
                }}
              />
              <$Checkbox
                name="isSensitiveData"
                className="mb-2"
                formitem
                checked={values.isSensitiveData}
                onChange={() => {
                  restProps.setFieldValue(
                    "isSensitiveData",
                    !values.isSensitiveData
                  );
                  onChange(true);
                }}
              >
                {t("US.COLLECTION.ADMIN:SYSTEM_SETTINGS.IS_SENSITIVE_DATA")}
              </$Checkbox>
              <$TextArea
                name="description"
                autoSize={{
                  minRows: 6,
                  maxRows: 6,
                }}
                size="small"
                label={t("US.COLLECTION.ADMIN:SYSTEM_SETTINGS.DESCRIPTION")}
                required
                tabIndex={4}
                maxLength={100}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  handleOnFieldChange(restProps, value, "description");
                }}
              />
            </$Form>
          </div>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              {isChangedFormValues(setting, values) && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.ADMIN:SYSTEM_SETTINGS.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                  )}
                  placement="topLeft"
                  onConfirm={onClose}
                  okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                  cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                >
                  <$Button
                    className="mr-2"
                    data-testid="save-btn"
                    disabled={!isValid}
                    loading={settingUpdate.isLoading || settingSave.isLoading}
                    tabIndex={5}
                    onClick={handleSubmit}
                    type="primary"
                  >
                    {type === DrawerType.ADD
                      ? t("US.COLLECTION.COMMON:COMMON.SAVE")
                      : t("US.COLLECTION.COMMON:COMMON.UPDATE")}
                  </$Button>
                  <$Button tabIndex={6}>
                    {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                  </$Button>
                </$Popconfirm>
              )}
              {!isChangedFormValues(setting, values) && (
                <$Button tabIndex={6} onClick={onClose}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { systemSettings, settingUpdate, settingSave } = state.systemSettings;
  return { settingUpdate, systemSettings, settingSave };
};

const { systemSettings } = Actions;
const { setting, newSetting } = systemSettings;

const mapDispatchToProps = {
  updateSetting: setting.update,
  addNewSettingType: setting.addNewSettingType,
  addSetting: newSetting.save,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingDetails);
