import React from "react";
import { ToggleClass } from "us.helper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Admin from "us.collection.admin";

interface ICntCreditorGroupHome extends RouteComponentProps {
  // caseNo: number;
}

const Cnt_NonProductionDaysHome: React.FC<ICntCreditorGroupHome> = (props) => {
  ToggleClass("change-body-color-dom");

  const onMinimize = (state: any) => {
    props.history.push(`/bureau`, state);
  };

  return (
    <>
      <Admin.Components.CreditorGroup.CreditorGroupHome
        onMinimize={onMinimize}
        {...props}
      />
    </>
  );
};

export default withRouter(Cnt_NonProductionDaysHome);
