export default {
  userManagementAPI: process.env.REACT_APP_USERMANAGEMENT_URL,
  endPoints: {
    user: "/users",
    role: "/roles",
  },
};
export * from "./NonProductionDays";
export * from "./ManageActivities";
export * from "./ManageFollowup";
export * from "./Municipalities";
export * from "./Actions";
