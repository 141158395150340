import * as Yup from "yup";

interface CreditorGroup extends Yup.MixedSchema {
  groupName: string;
  description: string;
}

const CreditorGroupSchema = Yup.object<CreditorGroup>().shape({
  groupName: Yup.string().required("Add a value into the field."),
  description: Yup.string().required("Add a value into the field."),
});

export default CreditorGroupSchema;
