import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { entity } from 'us.common/actions';
import { $Table, $Button, $Search } from '../antd';
import { PostalAreaDetails } from './Types';
import { useTranslation } from 'react-i18next';

interface ISelectMunicipal {
  drawerContentChangeClick: (data: PostalAreaDetails) => void;
  cancelClick: (step: string) => void;
  currentInfo?: any;
  currentStep: any;
  step?: number;
}

interface IBase extends ISelectMunicipal {
  getMunicipals:any;
  municiples: any;
}

const SelectMunicipal: React.FC<IBase> = (props) => {
  const { t } = useTranslation();
  const { municiples, getMunicipals } = props;
  const [selectedRowKey, setSelectedRowKey] = useState<number>();
  const [data, setData] = useState<any>();
  const [fullData, setFullData] = useState<any>();
  const [isOKDissabled, setIsOKDissabled] = useState<boolean>(true);

  const onSelectChange = (selectedRowKeys: any, selectedRows: any[]) => {
    setSelectedRowKey(Number.parseInt(selectedRowKeys[0].toString()));
    setIsOKDissabled(false);
  };

  const columns = [
    {
      title: t('US.COMMON:COMMON.CODE'),
      dataIndex: 'Code',
      key: 'Code',
    },
    {
      title: t('US.COMMON:COMMON.NAME'),
      dataIndex: 'Name',
      key: 'Name',
    },
  ];

  const rowSelection = {
    onChange: onSelectChange,
    hideDefaultSelections: true,
  };

  useEffect(() => {
    if (!selectedRowKey) {
      setIsOKDissabled(true);
    }
    if (municiples.length === 0) {
      getMunicipals();
    }
    if (municiples.length > 0 && !data) {
      let arrangedData = municiples.map((item: any) => {
        return { ...item, key: item.id };
      });
      setData(arrangedData);
      setFullData(arrangedData);
    }
  }, [data, getMunicipals, municiples, selectedRowKey]);

  const filterCodes = (inputValue: any) => {
    if (fullData) {
      setData(
        fullData.filter((item: any) => {
          let doesExist = false;
          for (let prop in item) {
            doesExist = item[prop]
              .toString()
              .toLowerCase()
              .includes(inputValue.target.value.toString().toLowerCase());
            if (doesExist) {
              return doesExist;
            }
          }
          return doesExist;
        })
      );
    }
  };

  const rowDoubleClickHandler = (dataRow: any, index: any) => {
    let newInfo = {
      ...props.currentInfo,
      MunicipalityCode: dataRow.Code,
      MunicipalityName: dataRow.Name,
    };
    setIsOKDissabled(true);
    props.drawerContentChangeClick({
      currentStep: props.currentStep,
      updatedInfo: newInfo,
      step: props.step,
    });
  };

  const okClickHandler = () => {
    if (selectedRowKey) {
      rowDoubleClickHandler(data[selectedRowKey - 1], 0);
    } else {
      setIsOKDissabled(true);
    }
  };

  return (
    <>
      <$Search
        name="code"
        onChange={(val: any) => filterCodes(val)}
        onSearch={(value: any) => filterCodes(value)}
        enterButton
        style={{ width: 700 }}
      />

      <$Table
        rowSelection={{ type: 'radio', ...rowSelection }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.Code}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: () => rowDoubleClickHandler(record, rowIndex),
          };
        }}
      />
      <div className="d-flex justify-content-end">
        <$Button
          className="mr-2"
          type="primary"
          disabled={isOKDissabled}
          onClick={() => okClickHandler()}
        >
          {t('US.COMMON:COMMON.OK')}
        </$Button>
        <$Button onClick={() => props.cancelClick(props.currentStep)}>
          {t('US.COMMON:COMMON.CANCEL')}
        </$Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    municiples: state.entityDetails.municiples,
  };
};

const mapDispatchToProps = {
  getMunicipals : entity.getMunicipals,
};

export default connect<
  { municiples: any },
  { getMunicipals: any },
  ISelectMunicipal
>(
  mapStateToProps,
  mapDispatchToProps
)(SelectMunicipal);
