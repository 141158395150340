import {
  MainCriterias,
  InvoiceSubCriterias,
  CaseSubCriterias,
  CreditorSubCriterias,
  DebtorSubCriterias,
  COLEN,
} from "us.common/constants";
import { ILabeledValue } from "us.common/interfaces";

/**
 * @function
 * @description Dropdown options
 * @param selectedCriteria can only be one of MainCriteriasEnum or can be undefined in searching through the main criterias
 * @param optionalCriterias can be one of T
 * @param inputValue the user typed value, do not include the Enum value
 * @param preVals the previous values of the Autocomplete
 * @returns The full Autocomplete dropdown including the history
 */
export const getDropDownOptionValues = <
  T extends ILabeledValue<
    | MainCriterias
    | InvoiceSubCriterias
    | CaseSubCriterias
    | CreditorSubCriterias
    | DebtorSubCriterias
  >
>(
  selectedCriteria: ILabeledValue<MainCriterias> | undefined,
  optionalCriterias: T[],
  inputValue: string,
  preVals: any
): any => {
  try {
    return {
      ...preVals,
      options: (optionalCriterias as any)
        .map((option: T) => {
          return {
            label: `${
              selectedCriteria ? selectedCriteria.label + COLEN + " " : ""
            }${option.label + COLEN} ${inputValue}`,
            value: `${
              selectedCriteria ? selectedCriteria.value + COLEN + " " : ""
            }${option.value + COLEN} ${inputValue}`,
            key: option.value + COLEN,
          };
        })
        .concat(
          preVals.options.filter(
            (optionLine: any) => optionLine.key === "hisotoryBar"
          )
        ),
      value: inputValue,
    };
  } catch (e) {
    return preVals;
  }
};
