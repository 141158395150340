import { SettingType } from 'us.collection.admin/interfaces';

/**
 * @description - Check whether the form values has changed by comparing initial values and the current values
 * @param {any} initialValues - Initial values of the form
 * @param {any} currentValues - Current values of the form
 * @returns {boolean}
 */
export const isChangedFormValues = (
	initialValues: any,
	currentValues: any
): boolean => {
	for (const [key, value] of Object.entries(currentValues)) {
		if (value !== initialValues[key]) return true;
	}
	return false;
};

/**
 * @description Get setting type name by type id
 * @param {Array<SettingType>} settingTypes - List of system settings types
 * @param {string | string[] | null} typeId - The URL params typeId to filter matching settings from the given list
 * @returns {string} - Matching setting type name
 */
export const getSettingTypeNameByTypeId = (
	settingTypes: Array<SettingType>,
	typeId: number
): string => {
	try {
		if (typeId) {
			const matchingType = settingTypes.find(
				(settingType: SettingType) =>
					settingType.typeId === typeId
			);
			return matchingType ? matchingType.typeName : '';
		}
		return '';
	} catch (error) {
		return '';
	}
};
