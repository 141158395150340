/**
 * Login actions
 */

export const authStart = (data: any) => ({
	type: 'AUTH_START',
	payload: data,
});

export const onTLAutoSignup = (data: any) => ({
	type: 'AUTH_AUTO_SIGNUP',
	payload: data,
});

export const authSuccess = (data: any) => ({
	type: 'AUTH_SUCCESS',
	payload: data,
});

export const authFail = (data: any) => ({
	type: 'AUTH_FAIL',
});

export const onBOXAutoSignup = (data: any) => ({
	type: 'AUTH_AUTO_SIGNUP_START',
	payload: data,
});

export const authFetchUseData = (data: any) => ({
	type: 'AUTH_CHECK_USER_DATA',
	payload: data,
});

export const onLogout = (data: any) => ({
	type: 'LOGOUT',
	payload: data,
});
