export enum EntityType {
	SUB_CASE = 'SubCase',
	CASE = 'Case',
	AR = 'AR',
	CREDITOR = 'Creditor',
	TRANSACTIONS = 'Payment',
	PAYMENT = 'Payment',
	BUREAU = 'Bureau',
	INVOICE = 'Invoice',
	DEBTOR = 'Debtor',
}

export enum EntityTypeShortForm {
	SUB_CASE = 'S',
	CASE = 'C',
	AR = 'A',
	CREDITOR = 'CRE',
	PAYMENT = 'P',
	BUREAU = 'BU',
	CREDITOR_GROUP = 'CG',
	CREDIT = 'CR',
}

export enum PhoneNumberTypes {
	MOBILE = 'mobile',
	WORK = 'work',
	HOME = 'home',
	SMS = 'sms',
}
