import React from 'react';
import * as Components from 'us.common/components';
import { useTranslation } from 'react-i18next';
import { IInfiniteScrolling } from './IInfiniteScrolling';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ArrowUpOutlined, InfoCircleOutlined } from 'us.icons';

const { $Skeleton, $Empty, $Button, $Tooltip } = Components;

const InfiniteScrolling: React.FC<IInfiniteScrolling> = (props) => {
	const { t } = useTranslation();
	const {
		pageLength,
		handleNextDataLoad,
		isMoreData,
		loader,
		endMessage,
		children,
		isExtraOptions,
		enableBackToTop,
		isDivScroll,
		onFocusToItem,
		onBackToTop,
	} = props;

	return (
		<InfiniteScroll
			dataLength={pageLength}
			next={handleNextDataLoad}
			hasMore={isMoreData}
			loader={
				loader ? (
					loader
				) : (
					<div className='px-4 py-2'>
						<$Skeleton
							paragraph={{ rows: 2 }}
							active
						/>
					</div>
				)
			}
			endMessage={
				<div>
					{endMessage ?? (
						<$Empty
							image={
								$Empty.PRESENTED_IMAGE_SIMPLE
							}
							description={t(
								'COMMON.NO_MORE_DATA'
							)}
						/>
					)}
				</div>
			}
			height={isDivScroll ? window.innerHeight - 96 : '100%'}>
			<>
				{children}
				{isExtraOptions && (
					<div className='is-actions'>
						{enableBackToTop && (
							<$Tooltip
								placement='top'
								title={t(
									'COMMON.BACK_TO_TOP'
								)}>
								<$Button
									shape='circle'
									className='mr-2'
									onClick={() => {
										typeof onBackToTop ===
										'function'
											? onBackToTop()
											: window.scrollTo(
													{
														top: 0,
														behavior: 'smooth',
													}
											  );
									}}
									icon={
										<ArrowUpOutlined />
									}
								/>
							</$Tooltip>
						)}
						{onFocusToItem && (
							<$Tooltip
								placement='top'
								title={t(
									'COMMON.BACK_TO_FOCUSED'
								)}>
								<$Button
									shape='circle'
									onClick={
										onFocusToItem
									}
									icon={
										<InfoCircleOutlined />
									}
								/>
							</$Tooltip>
						)}
					</div>
				)}
			</>
		</InfiniteScroll>
	);
};

export default InfiniteScrolling;
