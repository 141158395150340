import React from "react";
import { Form } from "antd";
import { useField } from "formik";
import * as Utility from "../utility";
import { useTranslation } from "react-i18next";
import { IInputAmountRangeParams } from "us.common/components/antd/Interfaces";
import PropTypes from "prop-types";
import ReactNumeric from "react-numeric";
import "./InputAmountRange.scss";

/**
 * @description - Input amount component for handle range of inputs.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/05/2022
 * */
const $I = <T extends IInputAmountRangeParams>({
  name,
  value,
  label,
  currentLanguage,
  placeholder,
  disabled = false,
  className,
  required,
  onBlur,
  onChange,
  onKeyPress,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  isAutoSwap = true,
  style,
  tabIndex,
  formitem,
  dataTestid,
}: T) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = error != "undefined" || meta?.touched;
  const { t } = useTranslation();

  /**
   * If the value is an array, return it. If the field value is an array, return it. Otherwise, return
   * an empty array.
   * @param {any} value - any
   */
  const getValue = (value: any) => {
    if (Array.isArray(value)) {
      return value;
    } else if (Array.isArray(field.value)) {
      return field.value;
    } else {
      return [];
    }
  };

  /**
   * "If the user is on the first input, and the second input is empty, focus on the second input. If
   * the user is on the second input, and the first input is empty, focus on the first input."
   * @param {any} e - any - the event object
   * @param {number} inputIndex - 0 or 1, depending on which input is being focused on
   */
  const onBlurHandler = (e: any, inputIndex: number) => {
    onBlur && onBlur(e);
    field.onBlur(e);
    helpers.setTouched(true);

    const [startValue, endValue] = getValue(value);

    if (inputIndex == 0) {
      if (typeof endValue == "undefined" && startValue && isAutoSwap) {
        document.querySelector(`[id=${name}-amount-range-1]` as any).focus();
      }
      if (isAutoSwap && startValue && endValue && startValue > endValue) {
        helpers?.setValue([endValue, startValue]);
      }
      if (isAutoSwap && !startValue && endValue) {
        helpers?.setValue([0, endValue]);
      }
    } else {
      if (typeof startValue == "undefined" && endValue && isAutoSwap) {
        document.querySelector(`[id=${name}-amount-range-0]` as any).focus();
      }
      if (isAutoSwap && startValue && endValue && startValue > endValue) {
        helpers?.setValue([endValue, startValue]);
      }
      if (isAutoSwap && !endValue && startValue) {
        helpers?.setValue([0, startValue]);
      }
    }
  };

  /**
   * "onChangeHandler is a function that handle amount input onchange event.
   * @param {number} eventValue - number - the value of the input
   * @param {number} inputIndex - The index of the input that was changed
   */
  const onChangeHandler = (eventValue: number, inputIndex: number) => {
    let valueArr = getValue(eventValue);
    valueArr[inputIndex] = eventValue;
    onChange ? onChange(valueArr) : helpers?.setValue(valueArr);
  };

  const inputComponent = (inputIndex: number) => (
    <div className="ant-form-item-control-input-content">
      <div
        className={`ant-input-number ant-input-number-sm ${className} ${
          disabled && "ant-input-number-disabled"
        }`}
      >
        <div className="ant-input-number-input-wrap">
          <ReactNumeric
            id={`${name}-amount-range-${inputIndex}`}
            currencySymbol=""
            onBlur={(e: any) => {
              onBlurHandler(e, inputIndex);
            }}
            data-testid={`${dataTestid}-${inputIndex}`}
            style={style}
            tabIndex={tabIndex}
            disabled={disabled}
            placeholder={placeholder}
            className="ant-input-number-input"
            decimalCharacter={currentLanguage === "en-GB" ? "." : ","}
            digitGroupSeparator={currentLanguage === "en-GB" ? "," : " "}
            onChange={(e: any, eventValue: number) =>
              onChangeHandler(eventValue, inputIndex)
            }
            onKeyPress={onKeyPress}
            value={getValue(value)[inputIndex]}
            maximumValue={
              max != undefined ? max.toString() : Number.MAX_SAFE_INTEGER
            }
            minimumValue={
              min != undefined ? min.toString() : Number.MIN_SAFE_INTEGER
            }
          />
        </div>
      </div>
    </div>
  );

  return (
    <Form.Item
      label={label}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
      required={typeof required == "undefined" ? false : true}
      extra={formitem?.extra}
    >
      <div className="d-flex">
        {inputComponent(0)}
        <div className="px-2 input-range-bg-color">-</div>
        {inputComponent(1)}
      </div>
    </Form.Item>
  );
};

$I.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  onPressEnter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $InputAmountRange = $I;
