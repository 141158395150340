import { createBrowserHistory } from "history";

/**
 * @function
 * @description remove search param
 * @param location location object
 * @param {string} field  name ofs search param
 */
export const removeSearchParams = (location: any, field: string) => {
  const history = createBrowserHistory();
  try {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(field);
    history.replace({
      search: queryParams.toString(),
    });
  } catch (error) {
    history.replace({
      search: "",
    });
  }
};

/**
 * @function
 * @description generateArrayFromData
 * @param {Array<any>} array
 * @returns {Array<any>} Array of data for redux state
 */
export const generateArrayDataFromResponse = (
  array: Array<any>
): Array<any> => {
  try {
    return array.map((data: any, index: number) => ({
      key: index + 1,
      ...data,
    }));
  } catch (error) {
    return array;
  }
};

/**
 * @function
 * @description generate activity list for followup
 * @param {Array<any>} activities  list of activities for followup
 * @param {Array<any>} allActivities  all the activities
 * @returns {Array<any>} activitiesList for the formData
 */
export const generateActivityListTable = (
  activities: Array<any>,
  allActivities: Array<any>
): Array<any> => {
  try {
    return activities.map((selectedActivity: any) => ({
      ...selectedActivity,
      ...allActivities?.find(
        (activity: any) => selectedActivity.activityId == activity.activityId
      ),
    }));
  } catch (error) {
    return activities;
  }
};
