/**
 * @description removes selected id from selected array
 * @param selected - selected keys
 * @param option - current option
 * @param optionValue - option key
 * @returns - array of selected ids
 */
export const removeSelection = (
  selected: Array<string | number | null>,
  option: any,
  optionValue: string
): Array<string | number | null> => {
  try {
    return [
      ...selected?.filter((fOption: any) => option?.[optionValue] !== fOption),
    ];
  } catch (error) {
    return [];
  }
};
