import { ISaveFeeRange } from "../../Interfaces";

export function AddNewFeeRange(this: any): ISaveFeeRange {
  return {
    id: this.id ?? -1,
    feeId: this.feeId,
    fromAmount: this.fromAmount ?? 0,
    toAmount: this.toAmount ?? 0,
    noOfFee: this.noOfFee,
    amount: this.amount,
    doubleAmount: this.doubleAmount,
  };
}
