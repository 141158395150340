export enum CreditorGroup {
    GET_CREDITOR_GROUPS_IN_BUREAU = "GET_CREDITOR_GROUPS_IN_BUREAU",
    GET_CREDITOR_GROUPS_IN_BUREAU_SUCCESS = "GET_CREDITOR_GROUPS_IN_BUREAU_SUCCESS",
    GET_CREDITOR_GROUPS_IN_BUREAU_FAIL = "GET_CREDITOR_GROUPS_IN_BUREAU_FAIL",

    GET_CREDITOR_GROUP_IN_BUREAU = "GET_CREDITOR_GROUP_IN_BUREAU",
    GET_CREDITOR_GROUP_IN_BUREAU_SUCCESS = "GET_CREDITOR_GROUP_IN_BUREAU_SUCCESS",
    GET_CREDITOR_GROUP_IN_BUREAU_FAIL = "GET_CREDITOR_GROUP_IN_BUREAU_FAIL",

    SAVE_CREDITOR_GROUP_IN_BUREAU = "SAVE_CREDITOR_GROUP_IN_BUREAU",
    SAVE_CREDITOR_GROUP_IN_BUREAU_SUCCESS = "SAVE_CREDITOR_GROUP_IN_BUREAU_SUCCESS",
    SAVE_CREDITOR_GROUP_IN_BUREAU_FAIL = "SAVE_CREDITOR_GROUP_IN_BUREAU_FAIL",

    UPDATE_CREDITOR_GROUP_IN_BUREAU = "UPDATE_CREDITOR_GROUP_IN_BUREAU",
    UPDATE_CREDITOR_GROUP_IN_BUREAU_SUCCESS = "UPDATE_CREDITOR_GROUP_IN_BUREAU_SUCCESS",
    UPDATE_CREDITOR_GROUP_IN_BUREAU_FAIL = "UPDATE_CREDITOR_GROUP_IN_BUREAU_FAIL",

    DELETE_CREDITOR_GROUP_IN_BUREAU = "DELETE_CREDITOR_GROUP_IN_BUREAU",
    DELETE_CREDITOR_GROUP_IN_BUREAU_SUCCESS = "DELETE_CREDITOR_GROUP_IN_BUREAU_SUCCESS",
    DELETE_CREDITOR_GROUP_IN_BUREAU_FAIL = "DELETE_CREDITOR_GROUP_IN_BUREAU_FAIL",

    SELECT_CREDITOR_GROUP = "SELECT_CREDITOR_GROUP",

    RESET_SELECTED_CREDITOR = "RESET_SELECTED_CREDITOR",
    
    MANAGE_CREDITOR_GROUP_DRAWER = "MANAGE_CREDITOR_GROUP_DRAWER",
    MANAGE_CREDITOR_GROUP_DRAWER_SUCCESS = "MANAGE_CREDITOR_GROUP_DRAWER_SUCCESS",
    MANAGE_CREDITOR_GROUP_DRAWER_FAIL = "MANAGE_CREDITOR_GROUP_DRAWER_FAIL",

    GET_CREDITORS_BY_GROUP_ID = "GET_CREDITORS_BY_GROUP_ID",
    GET_CREDITORS_BY_GROUP_ID_SUCCESS = "GET_CREDITORS_BY_GROUP_ID_SUCCESS",
    GET_CREDITORS_BY_GROUP_ID_FAIL = "GET_CREDITORS_BY_GROUP_ID_FAIL"
}