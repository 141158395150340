import { IActivitySummary } from "us.collection.admin/interfaces";
import _ from "lodash";
import { EntityTypePriority, EntityTypes } from "us.collection.admin/constants";

/**
 * @function
 * @description - search for at-least one word inside the string array
 * @param array {Array<any>} - string array where search key is need to be searched in
 * @param searchKey {string} - search value
 * @returns {boolean}
 */
const searchTextInArray = (array: Array<any>, searchKey: string): boolean => {
  try {

    const removeSpaces = (str: string): string => {
      return str.toLowerCase().trim().replace(/\s+/g, ' ');
    };
    const searchKeyWithoutSpaces = removeSpaces(searchKey);
    const isAWordMatched = array.some((element: any) => {
      const valueArrayWithoutSpaces = element ? removeSpaces(element.toString()) : "";
      return valueArrayWithoutSpaces.includes(searchKeyWithoutSpaces);
    });
    return isAWordMatched;
  } catch (error) {
    return true;
  }
};

/**
 * @function
 * @description - returns entity types from activity summary response
 * @param activitySummaryList {Array<IActivitySummary>} - activity summary list comes from API
 * @returns {Array<string>}
 */
export const getEntityTypes = (
  activitySummaryList: Array<IActivitySummary>
): Array<string> => {
  try {
    return _.uniq(_.map(activitySummaryList, "entitytype"));
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description - search data source against the entity type and search key that is user entered.
 * @param activitySummaryList {Array<IActivitySummary>} - activity summary list comes from API
 * @param searchKey string - search key
 * @returns {Array<any>}
 */
export const filterActivitySummary = (
  activitySummaryList: Array<IActivitySummary>,
  searchKey: string
): Array<any> => {
  try {
    const searchValue = searchKey == "undefined" ? "" : searchKey;
    const output = _.map(
      _.groupBy(
        // group by entityType
        _.orderBy(
          _.filter(
            activitySummaryList,
            ({
              entitytype,
              activityId,
              activityCode,
              name,
              displayName,
              type,
              description,
            }: IActivitySummary) => {
              return (
                entitytype != EntityTypes.PAYMENT &&
                searchTextInArray(
                  [
                    activityId,
                    activityCode,
                    name,
                    displayName,
                    type,
                    description,
                  ],
                  searchValue
                )
              );
            }
          ),
          ["activityCode"],
          ["asc"]
        ),
        "entitytype"
      ),
      (value: Array<IActivitySummary>, entType: string) => ({
        entitytype: entType,
        count: value.length,
        children: _.map(
          //group by activityGroup
          _.groupBy(value, "activityGroup"),
          (val: Array<IActivitySummary>, actGroup: string) => ({
            activityGroup: actGroup,
            children: val,
          })
        ),
      })
    );

    // sort filtered and formatted array by entity type priority
    const sortedOutput = output.sort(
      (a: any, b: any) =>
        (EntityTypePriority[a.entitytype] || EntityTypePriority.default) -
        (EntityTypePriority[b.entitytype] || EntityTypePriority.default)
    );
    return sortedOutput;
  } catch (error) {
    return [];
  }
};
