import { IInitialState } from "../Interfaces";

export const initialState: IInitialState = {
  groups: {
    data: [],
    isLoading: false,
  },
  creditors: {
    data: [],
    selected: [],
    isLoading: false,
  },
  isInitLoading: false,
  selectedGroups: {
      data: []
  }
};