import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nNS from 'i18n-ns';

const options = {
	order: ['localStorage', 'cookie'],
};

i18n.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: 'en',
		backend: {
			crossDomain: true,
			withCredentials: true,
			allowMultiLoading: true,
			detection: options,
			requestOptions: {
				mode: 'cors',
				credentials: 'same-origin',
				cache: 'default',
			},
			addPath: `https://app-translations-api.azurewebsites.net/api/addI18keys/${window._ENV.REACT_APP_TRANSLATION_ENV}/{{lng}}/{{ns}}.json`,
			parsePayload: function (
				namespace: any,
				key: any,
				fallbackValue: any
			) {
				return {
					[key.split('.')[0]
						? key.split('.')[0]
						: key]: key.split('.')[1],
				};
			},
			loadPath: `https://strgtlusboxtranslation.blob.core.windows.net/locals/${window._ENV.REACT_APP_TRANSLATION_ENV}/translations/{{lng}}/{{ns}}.json`,
		},
		initImmediate: true,
		fallbackLng: false,
		debug: false,
		defaultNS: 'US.COMMON',
		ns: i18nNS.ns,
		saveMissing:
			window._ENV.REACT_APP_TRANSLATION_ENV == 'dev'
				? true
				: false,
		react: {
			wait: true,
			useSuspense: false,
		},
	});

export default i18n;
