import { IFollowUpDetails } from "us.collection.admin/interfaces";
import { AssignType } from "./ManageFollowup";

export const followUpDetails: IFollowUpDetails = {
  followupId: -1,
  followupName: "",
  followupDisplayName: "",
  followupCategory: "",
  isDynamicFollowup: true,
  description: "",
  assignType: AssignType.ALL,
  assignTo: "",
  isCaseHandler: false,
  entityType: "",
  customQuery: "",
  assignToList: [],
  activity: "",
  activitiesList: [],
};
