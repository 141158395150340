import { ICheckedAction } from "../Interface";

export function SaveRole(this: any, checkedAction: ICheckedAction, isNew: boolean, roleId: number) {
    const { description, activeState, name } = this;
    const actions = Object.values(checkedAction)
        .reduce((preValue, currValue) => [...preValue, ...currValue], [])
        .filter(Number.isInteger)
        .map((id: number | string) => ({ id }));
    return {
        ... (isNew ? {} : { roleId }),
        name,
        description,
        activeState,
        actions,
    };
}
