
import { IUserSummary } from 'us.common/interfaces';

export const userSummary: IUserSummary = {
    id: 0,
    accessAuthorityLevelId: 0,
    activeState: false,
    azureUserId: '',
    displayName: '',
    email: '',
    hasDebtCollectionLicense: false,
    isCommentEnable: false,
    maskPrivateData: false,
    roles: []
}