import React from 'react';
import { Routines } from '../components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface ICnt_Routines extends RouteComponentProps {

}


const Cnt_Routines = (props: ICnt_Routines) => {

    const onMinimize = () => {
        props.history.push('')
    }

    return (
        <Routines.Home onMinimize={onMinimize} />
    )
}

export default withRouter(Cnt_Routines);