import moment from "moment";
import { IScheduleTime } from "us.collection.admin/interfaces";

export function smsTime(this: number): Array<any> {
  return [
    {
      dayId: 1,
      day: "Monday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 2,
      day: "Tuesday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 3,
      day: "Wednesday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 4,
      day: "Thursday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 5,
      day: "Friday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 6,
      day: "Saturday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
    {
      dayId: 7,
      day: "Sunday",
      isEnable: false,
      timeSlots: { startTime: moment().year(this).set({ hour: 8, minute: 0 }), endTime: moment().year(this).set({ hour: 20, minute: 0 }) },
    },
  ];
}
