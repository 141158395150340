import adAuth from "./ad";
import decodeJWT from "jwt-decode";

interface IJWTToken {
  [key: string]: string;
}

class AuthAD {
  isLoggedIn() {
    if (adAuth.getAccessToken()) {
      return true;
    }
    return false;
  }
  getToken() {
    return adAuth.getAccessToken();
  }
  currentUser() {
    const decoded = decodeJWT<IJWTToken>(adAuth.getAccessToken());
    return decoded;
  }
}

export default AuthAD;
