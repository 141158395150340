import ServiceConfig from "service.config.js";
import { versionedHttpCollection } from "us.helper/http/collection";

export const DebtorDetail = {
    search: async <P>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status, statusText } = await versionedHttpCollection.post<P>(
            "searchservice",
            "debtors",
            params,
            ServiceConfig()[`searchservice`]["search.debtor"]
          );
          if (status === 200 || status === 204) {
            resolve({data, status});
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    saveDebtor: async (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "entityService",
            "entity",
            params,
            ServiceConfig()[`entityservice`]['entity']
          );
          if (status === 201) {
            resolve({data, status});
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  };
  