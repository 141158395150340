import React, { useEffect, useState } from 'react'
import { DatePicker, Form } from 'antd';
import { useField, Field } from 'formik';
import moment from 'moment';
import * as Utility from './utility'
import { useTranslation } from 'react-i18next';

export const $DatePicker = ({ name, label, formProps, required, format, onChange, defaultPickerValue,
    defaultValue, dateFormat, disabledDate, value, style,
    size, placeholder, touched, allowClear = false, ...rest }: any) => {

    const [field, meta, helpers] = useField(name);

    const error = meta?.error
    const isTouched = meta?.touched
    const { t } = useTranslation();

    return (<Form.Item label={label}
        help={isTouched && error && t(error as string)}
        {...formProps}
        required={typeof required == 'undefined' ? false : true}
        validateStatus={Utility.validateStatus(error, isTouched)}>
        <Field
            as={DatePicker}
            {...field}
            {...rest}
            value={value && moment(value).isValid()
                ? moment(value)
                : null}
            size={size}
            name={name}
            allowClear={allowClear}
            style={style}
            format={format}
            id={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            disabledDate={disabledDate}
            defaultPickerValue={defaultPickerValue}
            onKeyDown={(e: any) => {
                if (!/^[a-zA-Z]+$/.test(e.target.value) && (e.keyCode == 13 || e.keyCode == 9)) {
                    if (e.target && e.target.value && e.target.value.length == 4 && typeof e.target.value == 'string') {
                        helpers.setValue(moment(e.target.value, 'DDMM'))
                    } else if (e.target && e.target.value && e.target.value.length == 6 && typeof e.target.value == 'string') {
                        helpers.setValue(moment(e.target.value, 'DDMMYY'))
                    } else if (e.target && e.target.value && e.target.value.length == 8 && typeof e.target.value == 'string') {
                        helpers.setValue(moment(e.target.value, 'DDMMYYYY'))
                    }
                }
            }}
            onChange={
                typeof onChange === 'function' ? onChange :
                    (date: any) => {
                        helpers.setValue(moment(date, format))

                    }
            } />
    </Form.Item>)
}