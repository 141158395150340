export enum Step {
  ACTIVITY_SETUP = "ACTIVITY_SETUP",
  EXECUTION_PROFILE = "EXECUTION_PROFILE",
  SET_CONTROLS = "SET_CONTROLS",
  MODIFY_STATES = "MODIFY_STATES",
}

export type StepTypes =
  | Step.ACTIVITY_SETUP
  | Step.MODIFY_STATES
  | Step.SET_CONTROLS
  | Step.EXECUTION_PROFILE;

export enum ActivityType {
  SP = "SP",
  STORED_PROCEDURE = "STORED_PROCEDURE",
  MESSAGE = "MESSAGE",
  API = "API",
  CODE = "CODE",
  EVENT = "EVENT",
}

export const EVENT_ACTIVITY_TYPE = "Event";

export enum ActivityTypeName {
  SEND_SMS = "SendSMS",
}
export enum EntityType {
  CASE = "case",
  INVOICE = "invoice",
  CREDITOR = "creditor",
  Debtor = "Debtor",
  AR = "AR",
}
export enum Entity {
    INVOICE = "Invoice",
    SUB_CASE = "SubCase",
}

export enum FieldName {
  FOLLOWUP_CATEGORY = "FOLLOWUP_CATEGORY",
  Template_Property = "templateProperty",
  Selected_Print_Mode = "selectedPrintMode",
  Available_Printer_List = "availablePrinterList",
  Update_Document_Base_State = "updateDocumentBaseState",
}

export enum StateName {
  ACTIVITY_SETUP = "activitySetUpValue",
  EXECUTION_PROFILE = "executionProfileValue",
  SET_CONTROLS = "setControlValue",
  MODIFY_STATES = "modifyStateValue",
}

export type StateNameTypes =
  | StateName.ACTIVITY_SETUP
  | StateName.MODIFY_STATES
  | StateName.SET_CONTROLS
  | StateName.EXECUTION_PROFILE;

export const ExtendedField: Array<string> = [
  FieldName.Template_Property,
  FieldName.Selected_Print_Mode,
  FieldName.Available_Printer_List,
  FieldName.Update_Document_Base_State,
];

export const StateTreeCheckAllKey = "all_1";

export enum AsyncFieldName {
  NAME = "NAME",
  ACTIVITY_CODE = "ACTIVITY_CODE",
}

export const DEFAULT_ACTIVITY_GROUP = 10
