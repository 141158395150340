export enum Municipalities {
  INIT_GET_MUNICIPALITIES = "INIT_GET_MUNICIPALITIES",
  INIT_GET_MUNICIPALITIES_SUCCESS = "INIT_GET_MUNICIPALITIES_SUCCESS",
  INIT_GET_MUNICIPALITIES_FAIL = "INIT_GET_MUNICIPALITIES_FAIL",
  MANAGE_MUNICIPALITIES_DRAWER = "MANAGE_MUNICIPALITIES_DRAWER",

  GET_MUNICIPALITIES = "GET_MUNICIPALITIES",
  GET_MUNICIPALITIES_SUCCESS = "GET_MUNICIPALITIES_SUCCESS",
  GET_MUNICIPALITIES_FAIL = "GET_MUNICIPALITIES_FAIL",

  SAVE_MUNICIPALITY = "SAVE_MUNICIPALITY",
  SAVE_MUNICIPALITY_SUCCESS = "SAVE_MUNICIPALITY_SUCCESS",
  SAVE_MUNICIPALITY_FAIL = "SAVE_MUNICIPALITY_FAIL",

  DELETE_MUNICIPALITY = "DELETE_MUNICIPALITY",
}
