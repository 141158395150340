export enum NotificationTypes {
  GLOBAL = "GLOBAL",
  LINK = "LINK",
}

/**
 * @description
 * @INTERNAL -> Route in same tab.
 * @INTERNAL_NEW -> Route in new tab (default).
 * @EXTERNAL_GET -> Retry GET request.
 * @EXTERNAL_POST -> Retry POST request.
 */
export enum ActionTypes {
  INTERNAL = "INTERNAL",
  INTERNAL_NEW = "INTERNAL-NEW",
  EXTERNAL_GET = "EXTERNAL-GET",
  EXTERNAL_POST = "EXTERNAL-POST"
}

export enum NotificationUpdateType {
  SEEN = "SEEN",
  DELETE = "DELETE",
}

export enum NotificationTriggerType {
  ON_NOTIFICATION_ADDED = "onNotificationAdded",
  ON_EVENT_ACK = "onEventAck",
  ON_EVENT_DONE = "onEventDone",
  ON_FOLLOWUP_REIMPORT = 'onFollowUpReImport',
  ON_ACTIVITY_EXECUTION = 'onActivityExecution'
}