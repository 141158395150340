import { IInitialState } from "us.collection.admin/reducers/CreditorGroup/Interfaces";
import { CreditorGroup } from "us.collection.admin/reducers/CreditorGroup/State";

export const initialState: IInitialState = {
  creditorGroups: {
    data: [],
    isFetching: false,
  },
  creditorGroupDetails: {
    data: CreditorGroup,
    isFetching: false,
  },
  drawer: {
    title: "",
    visible: false,
    isNewCreditorGroup: true,
  },
  selectedCreditors: {
    data: [],
    isFetching: false
  }
};
