import React from "react";
import { useSelector } from "react-redux";
import {
  $AmountLabel,
  $DatePicker,
  $InputAmount,
  $InputNumber,
} from "us.common/components";
import { CourtSentenceDates } from "../../Constants";
import { IAccountSummary } from "../../Interfaces";
import { IRow } from "./Interfaces/IRow";

/**
 * @description - Case Balance Update Single Row Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3040346132/Enforcement-Update+Case+Balance+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/07/2022
 */
const Row: React.FC<IRow> = (props) => {
  const { currentLanguage, currentCurrency, currentDateFormat } = useSelector(
    (state: any) => state.common
  );

  const {
    label,
    amountLabelName,
    inputAmountName,
    interestFromName,
    interestToName,
    interestRateName,
    values,
    accountSummary,
    isInterestFromDisabled,
    isInterestToDisabled,
    isInterestRateDisabled,
  } = props;

  return (
    <div className="list-table-row" key={label}>
      <div className="list-table-col text-nowrap">{label}</div>
      <div
        className={`list-table-col text-nowrap ${
          amountLabelName ? "text-right" : ""
        }`}
      >
        <strong>
          {amountLabelName && (
            <$AmountLabel
              value={
                accountSummary[amountLabelName as keyof IAccountSummary] ?? 0
              }
            />
          )}
        </strong>
      </div>
      {![CourtSentenceDates.DUE_DATE, CourtSentenceDates.LIMIT_DATE].includes(
        inputAmountName as CourtSentenceDates
      ) && (
        <div className="list-table-col ans-agreement">
          <$InputAmount
            className="text-right w-100"
            size="small"
            name={inputAmountName}
            currentCurrency={currentCurrency}
            currentLanguage={currentLanguage}
            value={values[inputAmountName] as number}
            min={0}
          />
        </div>
      )}
      {[CourtSentenceDates.DUE_DATE, CourtSentenceDates.LIMIT_DATE].includes(
        inputAmountName as CourtSentenceDates
      ) && (
        <div className="list-table-col">
          <$DatePicker
            name={inputAmountName}
            size="small"
            style={{ width: "100%" }}
            format={currentDateFormat}
            value={values[inputAmountName]}
          />
        </div>
      )}
      {interestFromName && (
        <div className="list-table-col">
          <$DatePicker
            name={
              interestFromName ?? inputAmountName.toString() + "_interestFrom"
            }
            data-testid={
              "sentence-interest-from-" + inputAmountName?.toString()
            }
            disabled={isInterestFromDisabled}
            size="small"
            style={{ width: "100%" }}
            format={currentDateFormat}
            value={values[interestFromName]}
          />
        </div>
      )}
      {interestToName && (
        <div className="list-table-col">
          <$DatePicker
            name={interestToName ?? inputAmountName.toString() + "_interestTo"}
            disabled={isInterestToDisabled}
            data-testid={"sentence-interest-to-" + inputAmountName?.toString()}
            size="small"
            style={{ width: "100%" }}
            format={currentDateFormat}
            value={interestToName && values[interestToName]}
          />
        </div>
      )}
      {interestRateName && (
        <div className="list-table-col">
          <$InputNumber
            className="text-left"
            size="small"
            name={
              interestRateName ?? inputAmountName.toString() + "_interestRate"
            }
            disabled={isInterestRateDisabled}
            min={0}
            max={100}
            step={0.01}
            addonAfter={"%"}
          />
        </div>
      )}
    </div>
  );
};

export default Row;
