import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.common/actions";
import * as API from "us.common/services";
import { DebtorSelection } from "us.common/constants";
import { $MessageBox } from "us.common/components";
const { debtorSelectionActions } = Actions;

const debtorSelectionSagas = {
  debtors: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.DebtorDetail.search,
          params
        );
        if (status === 200 && Array.isArray(data)) {
          yield put(
            debtorSelectionActions.debtorsList.success(data)
          );
        } else {
          yield put(debtorSelectionActions.debtorsList.fail(new Error()));
        }
      } catch (error) {
        yield put(debtorSelectionActions.debtorsList.fail(new Error()));
      }
    },
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.DebtorDetail.saveDebtor,
          params
        );
        if (status === 201) {
          $MessageBox(
            "success",
            `US.COMMON:MESSAGES.DEBTOR_SUCCESSFULLY_ADDED`,
            "",
            ""
          );
          yield put(
            debtorSelectionActions.addDebtor.success(data)
          );
        } else {
          $MessageBox(
            "error",
            `US.COMMON:MESSAGES.DEBTOR_ADDED_FAIL`,
            "",
            ""
          );
          yield put(debtorSelectionActions.addDebtor.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COMMON:MESSAGES.DEBTOR_ADDED_FAIL`,
          "",
          ""
        );
        yield put(debtorSelectionActions.addDebtor.fail(new Error()));
      }
    },
  },
};
export default [
  takeLatest(
    DebtorSelection.GET_DEBTOR_LIST_START,
    debtorSelectionSagas.debtors.get
  ),
  takeLatest(
    DebtorSelection.ADD_DEBTOR_START,
    debtorSelectionSagas.debtors.save
  ),
];
