import React, { useEffect, useState } from "react";
import {
  $DatePicker,
  $Button,
  $Popconfirm,
  $Tooltip,
  $MessageBox,
} from "us.common/components";
import { BoxIcons, IconTypes, LoadingOutlined } from "us.icons";
import { useTranslation } from "react-i18next";
import { IExecution } from "./Interfaces";
import moment from "moment";
import { useFormikContext } from "formik";
import { isValidActivity } from "us.common/functions";
import { connect, ConnectedProps } from "react-redux";
import { Action } from "us.common/constants";

/**
 * @description Execution part of Activity Execution bar
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912026690/Follow-up+Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 19/08/2022
 */
const Execution: React.FC<IExecution & PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);

  const {
    currentDateFormat,
    name,
    hasParameters,
    isDisabled,
    onClose: onCancel,
    isHideDatePicker,
    activities,
    isPopConfirmEnabled,
    propConfirmDetail,
    tabStartedIndex,
    parameters,
    isActionExecution,
    maxEntityCountExecuteAtOnce,
    selectedEntities,
    isExecuting,
    mode,
    activityExecutionMode,
    isDatePickerDisabled
  } = props;

  const { handleSubmit, values, setFieldValue } = useFormikContext() as any;
  const { data } = activities;
  const { activity, executingDateTime, executingDateTimeWithParameter } =
    values;
  const { title, isOnCancelSubmit } = propConfirmDetail ?? {};
  const { isEnableDropDown, isFetching } = parameters;
  const selectedDate: string | moment.Moment = isEnableDropDown
    ? executingDateTimeWithParameter
    : executingDateTime;

  useEffect(() => {
    if (!isEnableDropDown && isValidActivity(data, activity) && !isFetching) {
      window.setTimeout(function () {
        document.querySelector(`[name=executingDateTime]` as any)?.focus();
      }, 200);
    }
  }, [isEnableDropDown, activity]);

  /**
   * @function
   * @description handle notification
   * @returns {boolean}
   */
  const handleErrorNotificationAndStatus = (): boolean => {
    let validStatus: boolean = true;
    try {
      if (activity.trim().length === 0) {
        $MessageBox(
          "error",
          "US.COMMON:ACTIVITY_EXECUTION.PLEASE_SELECT_AN_ACTIVITY",
          "",
          ""
        );
        validStatus = false;
      } else if (!isValidActivity(data, activity)) {
        $MessageBox(
          "error",
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
          "",
          ""
        );
        validStatus = false;
      } else if (
        maxEntityCountExecuteAtOnce &&
        selectedEntities &&
        selectedEntities.length > maxEntityCountExecuteAtOnce &&
        !moment(selectedDate).isValid()
      ) {
        $MessageBox(
          "error",
          "US.COMMON:ACTIVITY_EXECUTION.PLEASE_USE_SCHEDULER",
          "",
          "",
          { maxEntityCountExecuteAtOnce }
        );
        validStatus = false;
      }
    } catch (error) {}
    return validStatus;
  };

  /**
   * @function
   * @description handle execution
   */
  const handleExecution = () => {
    try {
      if (handleErrorNotificationAndStatus()) {
        if (isPopConfirmEnabled) {
          setVisibleConfirm(true);
        } else {
          handleSubmit(values as any);
        }
      }
    } catch (error) {}
  };

  /**
   * @function
   * @description handle PopConfirmation
   * @param {boolean} isConfirm
   */
  const handlePopConfirmation = (isConfirm: boolean) => {
    isActionExecution &&
      setFieldValue("action", isConfirm ? Action.REMOVE : Action.DONE);

    if (isOnCancelSubmit && !isConfirm) {
      handleSubmit(values);
    } else if (isConfirm) {
      handleSubmit(values);
    }
    setVisibleConfirm(false);
  };

  return (
    <>
      {!isHideDatePicker && (
        <$Tooltip
          placement="topLeft"
          title={t("US.COMMON:ACTIVITY_EXECUTION.SCHEDULE_DATE_AND_TIME")}
        >
          <$DatePicker
            placeholder={`${currentDateFormat} HH:MM`}
            name={name}
            showTime={{ format: "HH:mm" }}
            format={`${currentDateFormat} HH:mm`}
            allowClear={true}
            value={values[name]}
            onOk={(date: moment.Moment) => setFieldValue(name, date)}
            style={{ width: 190 }}
            disabledDate={(d: any) => !d || d.isBefore(moment().startOf("day"))}
            disabled={isDisabled || isDatePickerDisabled}
            tabIndex={tabStartedIndex}
          />
        </$Tooltip>
      )}
      <$Popconfirm
        placement="topRight"
        id="popConfirmId"
        title={t(`US.COMMON:ACTIVITY_EXECUTION.${title}`)}
        onConfirm={(e: any) => handlePopConfirmation(true)}
        onCancel={(e: any) => handlePopConfirmation(false)}
        okText={t("US.COMMON:COMMON.YES")}
        cancelText={t("US.COMMON:COMMON.NO")}
        onVisibleChange={() => visibleConfirm && setVisibleConfirm(false)}
        visible={visibleConfirm && isPopConfirmEnabled}
        okButtonProps={{
          tabIndex: tabStartedIndex + 2,
          autoFocus: true,
        }}
        cancelButtonProps={{
          tabIndex: tabStartedIndex + 3,
        }}
      >
        <$Button
          type={hasParameters ? "primary" : "default"}
          style={{ width: hasParameters ? "90px" : "50px" }}
          className="ml-1"
          icon={
            <>
              {isExecuting && mode == activityExecutionMode && (
                <LoadingOutlined style={{ fontSize: 18 }} />
              )}
              {!(isExecuting && mode == activityExecutionMode) && (
                <BoxIcons type={IconTypes.BOX_ICON} name="run-activity" />
              )}
            </>
          }
          onClick={handleExecution}
          disabled={isDisabled}
          tabIndex={tabStartedIndex + 1}
        >
          {hasParameters && (
            <span className="ml-1">
              {t("US.COMMON:ACTIVITY_EXECUTION.RUN")}
            </span>
          )}
        </$Button>
      </$Popconfirm>
      {hasParameters && (
        <$Button
          className="ml-2"
          onClick={onCancel}
          tabIndex={tabStartedIndex + 4}
        >
          {t("US.COMMON:COMMON.CANCEL")}
        </$Button>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { activityExecution, common } = state;
  const {
    activities,
    parameters,
    activityFormDetail,
    isExecuting,
    activityExecutionMode,
  } = activityExecution;
  const { currentDateFormat } = common;

  return {
    activities,
    currentDateFormat,
    parameters,
    activityFormDetail,
    isExecuting,
    activityExecutionMode,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Execution);
