import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { manageFollowupActions } from "us.collection.admin/actions";
import Common from "us.common";
import { QuerySpace } from "us.collection.admin/components/ManageFollowup/CustomQuery/Components";
import "./CustomSql.scss";
import { useHistory } from "react-router-dom";
const { $PageHeader, $Breadcrumb, $Affix, ManageFollowUpLeftPanel } =
  Common.Components;

const { entityTypes } = manageFollowupActions;

/**
 * @description - Manage Follow-up CustomSql Home Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942664743/Follow-up+Custom+Query+Workspace+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const CustomSql: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { getEntityTypes, entities, isNewCustomQuery, followUpDetails } = props;

  useEffect(() => {
    getEntityTypes && getEntityTypes({});
  }, []);

  useEffect(() => {
    if (isNewCustomQuery) {
      entities.data[0] &&
        push(`/manage-followup/custom-query/${entities.data[0].entityType}`);
    } else {
      push(
        `/manage-followup/custom-query/${followUpDetails?.data?.entityType}`
      );
    }
  }, [entities.data]);

  return (
    <>
      <div className="custom-sql">
        <$Affix offsetTop={48}>
          <div className="page-header header-border pr-0">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="pt-2">
                <$Breadcrumb className="mb-0">
                  <$Breadcrumb.Item
                    onClick={() => push(`/manage-followup/all`)}
                  >
                    <a>
                      {t("US.COLLECTION.ADMIN:BULKOPERATION.MANAGE_FOLLOWUP")}
                    </a>
                  </$Breadcrumb.Item>
                  <$Breadcrumb.Item>
                    {t("US.COLLECTION.ADMIN:BULKOPERATION.CUSTOM_SQL")}
                  </$Breadcrumb.Item>
                </$Breadcrumb>
                <$PageHeader
                  className="px-0 mt-n1 mb-2"
                  title={t("US.COLLECTION.ADMIN:BULKOPERATION.CONFIGURE_QUERY")}
                  onBack={() => push(`/manage-followup/all`)}
                />
              </div>
            </div>
          </div>
        </$Affix>
        <div className="csql-wrap">
          <div className="csql-sidebar">
            <ManageFollowUpLeftPanel
              dataSource={entities?.data}
              labelField={"entityType"}
              valueField={"entityType"}
              isLoading={entities?.isFetching}
              defaultSelectedKeys={
                isNewCustomQuery
                  ? [entities?.data[0]?.entityType]
                  : [followUpDetails?.data?.entityType]
              }
              disable={!isNewCustomQuery}
            />
          </div>
          <div className="d-flex flex-column csql-container">
            <QuerySpace />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery, followUpDetails } = manageFollowups;
  const { entities, isNewCustomQuery } = customQuery;
  return { entities, isNewCustomQuery, followUpDetails };
};

const mapDispatchToProps = {
  getEntityTypes: entityTypes.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CustomSql);
