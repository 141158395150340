import { AxiosError } from 'axios';

/**
 * @description - Configuration options for the axios retry
 */
export const RetryConfig = {
	retries: 3, // retry 3 more times after first call
	retryDelay: (retryCount: any) => {
		// retry delay in milliseconds
		return retryCount * 2000;
	},
	retryCondition(error: AxiosError) {
		// retry only when the error code is 503
		return error?.response?.status === 503;
	},
};
