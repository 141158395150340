import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { ManageFee } from "us.collection.admin/constants/Actions";
import Common from "us.common";
import { i18n } from "us.helper";
import { DrawerType } from "us.collection.admin/components/ManageFee/Constants";
import _ from "lodash";

const { $MessageBox, $Message } = Common.Components;

const { manageFee } = Actions;
const {
  feeTypeActions,
  feeBasisActions,
  feeBasisHistoryActions,
  feeRangeActions,
  newFeeType,
  newFeeBasis,
  newFeeRange,
  existingFeeBasis,
  existingFeeRange,
} = manageFee;
const { feeType, feeBasis, feeBasisHistory, feeRange } = API.manageFee;

const ManageFeeSagas = {
  feeType: {
    getFeeTypeList: function* (action: any) {
      const { success, fail } = feeTypeActions;
      try {
        const { data, status } = yield call(feeType.getFeeTypes);
        if (status == 200) {
          yield put(success(data));
        } else {
          yield put(fail([]));
        }
      } catch (error) {
        yield put(fail([]));
      }
    },
    addNewFeeType: function* (action: any) {
      const { success, fail } = newFeeType;
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, typeData } = yield call(
          feeType.saveNewFeeType,
          params
        );
        if (Array.isArray(typeData)) {
          yield put(feeTypeActions.success(typeData));
        }
        if (status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_TYPE_SAVED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(success(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_TYPE_SAVE_FAILED`,
            "",
            ""
          );
          yield put(fail([]));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_TYPE_SAVE_FAILED`,
          "",
          ""
        );
        yield put(fail([]));
      }
    },
  },
  feeBasis: {
    getFeeBasisList: function* (action: any) {
      const { success, fail } = feeBasisActions;
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(feeBasis.getFeeBasis, params);
        if (status === 200) {
          yield put(success(data));
        } else {
          yield put(fail([]));
        }
      } catch (error) {
        yield put(fail([]));
      }
    },
    addNewFeeBasis: function* (action: any) {
      const { success, fail } = newFeeBasis;
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, basisData } = yield call(
          feeBasis.saveNewFeeBasis,
          params
        );
        if (Array.isArray(basisData)) {
          yield put(feeBasisActions.success(basisData));
        }
        if (status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_BASIS_SAVED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(success(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_BASIS_SAVE_FAILED`,
            "",
            ""
          );
          yield put(fail([]));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_BASIS_SAVE_FAILED`,
          "",
          ""
        );
        yield put(fail([]));
      }
    },
    deleteFeeBasis: function* (action: any) {
      const { success, fail } = existingFeeBasis;
      const params = action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.LOADING"), 0);
      try {
        const { data, status, basisData, historyData } = yield call(
          feeBasis.deleteFeeBasis,
          params
        );
        $Message.destroy();
        if (basisData?.status === 200) {
          yield put(feeBasisActions.success(basisData?.data));
        } else if (basisData?.status === 204) {
          yield put(feeBasisActions.success([]));
        }
        if (historyData?.status === 200) {
          yield put(feeBasisHistoryActions.success(historyData?.data));
        } else if (historyData?.status === 204) {
          yield put(feeBasisHistoryActions.success([]));
        }
        if (status == 200) {
          const msg =
            params?.type === DrawerType.FEE_BASIS
              ? "FEE_BASIS_DELETED"
              : "FEE_BASIS_HISTORY_REVERTED";
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:MANAGE_FEE.${msg}_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(success(data));
        } else {
          const msg =
            params?.type === DrawerType.FEE_BASIS
              ? "FEE_BASIS_DELETE"
              : "FEE_BASIS_HISTORY_REVERTE";
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_FEE.${msg}_FAILED`,
            "",
            ""
          );
          yield put(fail([]));
        }
      } catch (error) {
        const msg =
          params?.type === DrawerType.FEE_BASIS
            ? "FEE_BASIS_DELETE"
            : "FEE_BASIS_HISTORY_REVERTE";
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_FEE.${msg}_FAILED`,
          "",
          ""
        );
        yield put(fail([]));
      }
    },
  },
  feeBasisHistory: {
    getFeeBasisHistoryList: function* (action: any) {
      const { success, fail } = feeBasisHistoryActions;
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          feeBasisHistory.getFeeBasisHistory,
          params
        );
        if (status === 200) {
          yield put(success(data));
        } else {
          yield put(fail([]));
        }
      } catch (error) {
        yield put(fail([]));
      }
    },
  },
  feeRange: {
    getFeeRangeList: function* (action: any) {
      const { success, fail } = feeRangeActions;
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(feeRange.getFeeRanges, params);
        if (status === 200) {
          yield put(success(data));
        } else {
          yield put(fail([]));
        }
      } catch (error) {
        yield put(fail([]));
      }
    },
    addNewFeeRange: function* (action: any) {
      const { success, fail } = newFeeRange;
      const { feeId, addActionPayload, deleteActionPayload } =
        action?.payload?.data ?? {};

      try {
        let deletedData = { status: 0 };
        let addedData = { status: 0, data: {} };

        if (deleteActionPayload?.length > 0) {
          deletedData = yield call(
            feeRange.deleteFeeRange,
            deleteActionPayload
          );
        }
        if (
          !_.isEmpty(addActionPayload) ||
          (Array.isArray(addActionPayload) && addActionPayload?.length > 0)
        ) {
          addedData = yield call(
            feeRange.saveNewFeeRange,
            Array.isArray(addActionPayload)
              ? addActionPayload
              : [addActionPayload]
          );
        }

        const { data, status } = yield call(feeRange.getFeeRanges, feeId);
        if (status == 200) {
          yield put(feeRangeActions.success(data));
        }
        if (addedData.status == 200 || deletedData?.status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_SAVED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(success(addedData.data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_SAVE_FAILED`,
            "",
            ""
          );
          yield put(fail([]));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_SAVE_FAILED`,
          "",
          ""
        );
        yield put(fail([]));
      }
    },
    deleteFeeRange: function* (action: any) {
      const { success, fail } = existingFeeRange;
      const { feeId, addActionPayload, deleteActionPayload } =
        action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.LOADING"), 0);
      try {
        let deletedData = { status: 0, data: {} };
        let addedData = { status: 0, data: {} };
        if (deleteActionPayload?.length > 0) {
          deletedData = yield call(
            feeRange.deleteFeeRange,
            deleteActionPayload
          );
        }
        $Message.destroy();
        if (
          !_.isEmpty(addActionPayload) ||
          (Array.isArray(addActionPayload) && addActionPayload?.length > 0)
        ) {
          addedData = yield call(
            feeRange.saveNewFeeRange,
            Array.isArray(addActionPayload)
              ? addActionPayload
              : [addActionPayload]
          );
        }
        const { data, status } = yield call(feeRange.getFeeRanges, feeId);
        if (status == 200) {
          yield put(feeRangeActions.success(data));
        } else if (status == 204) {
          yield put(feeRangeActions.success([]));
        }
        if (addedData.status == 200 || deletedData.status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_DELETED_SUCCESSFULLY`,
            "",
            ""
          );
          yield put(success(deletedData.data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_DELETE_FAILED`,
            "",
            ""
          );
          yield put(fail([]));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_DELETE_FAILED`,
          "",
          ""
        );
        yield put(fail([]));
      }
    },
  },
};

export default [
  takeLatest(ManageFee.GET_FEE_TYPES, ManageFeeSagas.feeType.getFeeTypeList),
  takeLatest(ManageFee.GET_FEE_BASIS, ManageFeeSagas.feeBasis.getFeeBasisList),
  takeLatest(
    ManageFee.GET_FEE_BASIS_HISTORY,
    ManageFeeSagas.feeBasisHistory.getFeeBasisHistoryList
  ),
  takeLatest(ManageFee.GET_FEE_RANGE, ManageFeeSagas.feeRange.getFeeRangeList),
  takeLatest(ManageFee.SAVE_FEE_TYPES, ManageFeeSagas.feeType.addNewFeeType),
  takeLatest(ManageFee.SAVE_FEE_BASIS, ManageFeeSagas.feeBasis.addNewFeeBasis),
  takeLatest(ManageFee.SAVE_FEE_RANGE, ManageFeeSagas.feeRange.addNewFeeRange),
  takeLatest(
    ManageFee.DELETE_FEE_BASIS,
    ManageFeeSagas.feeBasis.deleteFeeBasis
  ),
  takeLatest(
    ManageFee.DELETE_FEE_RANGE,
    ManageFeeSagas.feeRange.deleteFeeRange
  ),
];
