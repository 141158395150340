import { initialState } from "./State";
import { Municipalities } from "us.collection.admin/constants/Actions";
import { sortMunicipalities } from "us.collection.admin/functions";
import _ from "lodash";

const {
  INIT_GET_MUNICIPALITIES,
  INIT_GET_MUNICIPALITIES_SUCCESS,
  INIT_GET_MUNICIPALITIES_FAIL,
  MANAGE_MUNICIPALITIES_DRAWER,

  GET_MUNICIPALITIES,
  GET_MUNICIPALITIES_SUCCESS,
  GET_MUNICIPALITIES_FAIL,

  SAVE_MUNICIPALITY,
  SAVE_MUNICIPALITY_SUCCESS,
  SAVE_MUNICIPALITY_FAIL,

  DELETE_MUNICIPALITY,
} = Municipalities;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case INIT_GET_MUNICIPALITIES:
      return Object.assign({}, state, {
        municipalityList: {
          isLoading: true,
          data: [],
        },
        countries: {
          isLoading: true,
          data: [],
        },
      });
    case INIT_GET_MUNICIPALITIES_SUCCESS:
      const { municipalities, countries } = payload.data ?? {};
      return Object.assign({}, state, {
        municipalityList: {
          isLoading: false,
          data: sortMunicipalities(municipalities),
        },
        countries: {
          isLoading: false,
          data: countries,
        },
      });
    case INIT_GET_MUNICIPALITIES_FAIL:
      return Object.assign({}, state, {
        municipalityList: {
          ...state.municipalityList,
          isLoading: false,
        },
        countries: {
          ...state.countries,
          isLoading: false,
        },
      });
    case GET_MUNICIPALITIES:
      return Object.assign({}, state, {
        municipalityList: {
          isLoading: true,
          data: [],
        },
      });
    case GET_MUNICIPALITIES_SUCCESS:
      return Object.assign({}, state, {
        municipalityList: {
          isLoading: false,
          isDeleting: false,
          data: sortMunicipalities(payload.data),
        },
      });
    case GET_MUNICIPALITIES_FAIL:
      return Object.assign({}, state, {
        municipalityList: {
          ...state.municipalityList,
          isLoading: false,
        },
      });
    case SAVE_MUNICIPALITY:
      return Object.assign({}, state, {
        isFormSaving: true,
      });
    case SAVE_MUNICIPALITY_SUCCESS:
      return Object.assign({}, state, {
        isFormSaving: false,
      });
    case SAVE_MUNICIPALITY_FAIL:
      return Object.assign({}, state, {
        isFormSaving: false,
      });
    case DELETE_MUNICIPALITY:
      return Object.assign({}, state, {
        isDeleting: true,
      });
    case MANAGE_MUNICIPALITIES_DRAWER:
      return Object.assign({}, state, {
        drawerInfo: {
          ...state.drawerInfo,
          ...payload.data,
          visible: !state.drawerInfo.visible,
        },
      });

    default:
      return state;
  }
};
