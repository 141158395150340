import { useEffect, useState, useContext } from "react";
import { SignalRContext } from "us.common/SignalRProvider";
import { ITrigger } from "us.common/SignalRProvider/interface";

/**
 * @description Custom hook for set trigger
 * @returns setTrigger function
 */
export const useSignalRTrigger = () => {
  const { connection } = useContext(SignalRContext);

  const [triggers, setSignalRTriggers] = useState<Array<ITrigger>>([]);

  useEffect(() => {
    if (connection) {
      triggers.map(({ name, callBack }: ITrigger) => {
        connection.on(name, callBack);
      });
    }
  }, [connection]);

  const setTrigger = (triggerList: Array<ITrigger>): any => {
    setSignalRTriggers(triggerList);
  };

  return {
    setTrigger,
  };
};
