import React from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { $Col, $Row } from "../antd";
import "./LoginRedirect.scss";
import rdb_logo from "./logo.svg";
import rdb_img from "./lgo-redirect.svg";

interface ILoginRedirect {
  onLoginClick: any;
}

const { $Button } = Components;

const LoginRedirect: React.FC<ILoginRedirect> = ({ onLoginClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="redirect-container">
        <div className="redirect-header align-items-center justify-content-between pb-5 pt-4">
          <div>
            {" "}
            <img alt="logo" src={rdb_logo} />
          </div>
          <div>
            {" "}
            <$Button
              type="default"
              size="middle"
              onClick={() => onLoginClick()}
            >
              Log In
            </$Button>
          </div>
        </div>
        <div className="redirect-content pt-5">
          <div className="flex-fill d-flex flex-column align-items-start justify-content-center">
            <h1>
              Total container for <br />
              your business solutions.
            </h1>
            <p>
              Standard set of modules to handle the collection needs of
              Collection Agencies.
            </p>
          </div>
          <div className="flex-fill text-right ">
            <img className="w-50" alt="img" src={rdb_img} />
          </div>
        </div>

        <div className="redirect-footer d-flex justify-content-between">
          <div>Ⓒ Unicorn Solutions As</div>

          <div>
            <a href="/">unicornbox.com</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginRedirect;
