import React, { createContext, useState, useEffect, useMemo } from 'react';
import componentPermission from './permissions';
import { ConnectedProps, connect } from 'react-redux';
import { UserAuthorization } from 'us.common/services';
import { useMsal } from '@azure/msal-react';
import {
	InteractionStatus,
	AuthenticationResult,
	InteractionRequiredAuthError,
} from '@azure/msal-browser';
import decodeJWT from 'jwt-decode';
import { AD } from 'us.helper/azure';
import * as Actions from 'us.common/actions';
import { Unauthorized, Loader } from 'us.common/components';
import { withSelfCheck } from './Functions';
import { i18n, initializeAppInsight } from 'us.helper';
import Common from 'us.common';
import { isArray } from 'lodash';
import {RootState} from 'us.helper/types';

export const ConfigurationContext = createContext<any>([]);
interface IJWTToken {
	[key: string]: string;
}

const ConfigurationProvider: React.FC<PropsFromRedux> = (props) => {
	const { instance, inProgress, accounts } = useMsal();
	const {
		featureList,
		setUserAuthorizationProfileSuccess,
		setUserAuthorizationProfileFail,
		getUserProfilePhoto,
		getTenantDetails,
		changeLanguage,
	} = props;

	const [loadingSteps, setLoadingSteps] = useState<number>(-1);

	const configurationDetail: any[] = useMemo(() => {
		if (isArray(featureList) && featureList.length > 0) {
			return withSelfCheck(featureList);
		} else {
			return [];
		}
	}, [featureList]);

	useEffect(() => {
		const accessTokenRequest = {
			scopes: ['user.read'],
			account: accounts[0],
		};
		if (inProgress === InteractionStatus.None && accounts[0]) {
			instance.acquireTokenSilent(accessTokenRequest)
				.then(
					(
						accessTokenResponse: AuthenticationResult
					) => {
						//Decode access token to initialize appInsight instance for the app
						const decodedObj =
							decodeJWT<IJWTToken>(
								accessTokenResponse.accessToken
							);
						initializeAppInsight(
							decodedObj
						);
						AD.run(
							accessTokenResponse.accessToken,
							() => {
								getAuthorizationProfile(
									accessTokenResponse.uniqueId
								);
							}
						);
						// Get profile photo and tenant id graph explorer
						getUserProfilePhoto &&
							getUserProfilePhoto({});
						getTenantDetails &&
							getTenantDetails({});
					}
				)
				.catch((error) => {
					if (
						error instanceof
						InteractionRequiredAuthError
					) {
						instance.acquireTokenRedirect(
							accessTokenRequest
						);
					}
					console.error(error);
				});
		}
	}, [instance, accounts, inProgress]);

	const getAuthorizationProfile = async (token: string) => {
		UserAuthorization.AuthorizationProfile.get(token)
			.then(({ data, status }) => {
				sessionStorage.setItem(
					'productVersion',
					data.productVersion
				);
				if (
					status === 200 &&
					data?.featureList.length
				) {
					changeLanguageHandler(
						data?.preferredLanguage
					);
					setLoadingSteps(1);
					setUserAuthorizationProfileSuccess(
						data
					);
				} else {
					setLoadingSteps(0);
					setUserAuthorizationProfileFail([]);
				}
			})
			.catch(() => {
				setLoadingSteps(0);
			});
	};

	/**
	 * @function
	 * @description - Handle language change.
	 * @param {string} lngCode - selected language.
	 */
	const changeLanguageHandler = (lngCode: 'no' | 'en') => {
		switch (lngCode) {
			case 'no':
				i18n.changeLanguage('no');
				changeLanguage(
					Common.Constants.languageCodes.nbNO,
					Common.Constants.currencyCodes.nbNO,
					Common.Constants.dateFormats.nbNO
				);
				break;
			case 'en':
				i18n.changeLanguage('en');
				changeLanguage(
					Common.Constants.languageCodes.enGB,
					Common.Constants.currencyCodes.enGB,
					Common.Constants.dateFormats.enGB
				);
				break;
			default:
				i18n.changeLanguage('no');
				changeLanguage(
					Common.Constants.languageCodes.nbNO,
					Common.Constants.currencyCodes.nbNO,
					Common.Constants.dateFormats.nbNO
				);
				break;
		}
	};

	return (
		<>
			{loadingSteps === -1 &&
				configurationDetail.length === 0 && (
					<Loader isDescriptionAvailable={true} />
				)}
			{loadingSteps === 1 && configurationDetail.length > 0 && (
				<ConfigurationContext.Provider
					value={{
						configurationDetail,
						componentPermission,
					}}>
					{props.children}
				</ConfigurationContext.Provider>
			)}
			{loadingSteps === 0 &&
				configurationDetail.length === 0 && (
					<Unauthorized />
				)}
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	featureList:
		state.AuthorizationProfile.authorizationProfile.featureList,
});

const { userAuthorizationProfile } = Actions.UserAuthorizationAction;
const { userProfilePhoto, tenantDetails } = Actions.UserInformationAction;

const mapDispatchToProps = {
	setUserAuthorizationProfileSuccess: userAuthorizationProfile.success,
	setUserAuthorizationProfileFail: userAuthorizationProfile.fail,
	getUserProfilePhoto: userProfilePhoto.get,
	getTenantDetails: tenantDetails.get,
	changeLanguage: Actions.changeLanguage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigurationProvider);
