import { IAPIActionBase } from "us.common/interfaces";
import { DebtorSelection } from "us.common/constants";

export const debtorSelectionActions: Readonly<IAPIActionBase> = {
  debtorsList: {
    get: (data) => ({
      type: DebtorSelection.GET_DEBTOR_LIST_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DebtorSelection.GET_DEBTOR_LIST_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DebtorSelection.GET_DEBTOR_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data: any) => ({
      type: DebtorSelection.RESET_DEBTOR_SELECTION_TABLE,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  addDebtor: {
    save: (data) => ({
      type: DebtorSelection.ADD_DEBTOR_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DebtorSelection.ADD_DEBTOR_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DebtorSelection.ADD_DEBTOR_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: DebtorSelection.ADD_DEBTOR_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
