export enum GDPRComment {
    GET_GDPRCOMMENT_VIEW_START = "GET_GDPRCOMMENT_VIEW_START",
    GET_GDPRCOMMENT_VIEW_SUCCESS = "GET_GDPRCOMMENT_VIEW_SUCCESS",
    GET_GDPRCOMMENT_VIEW_FAIL = "GET_GDPRCOMMENT_VIEW_FAIL",
    GET_GDPRCOMMENT_VIEW_RESET = "GET_GDPRCOMMENT_VIEW_RESET",

    ADD_GDPR_COMMENT_START = "ADD_GDPR_COMMENT_START",
    ADD_GDPR_COMMENT_SUCCESS = "ADD_GDPR_COMMENT_SUCCESS",
    ADD_GDPR_COMMENT_FAIL = "ADD_GDPRCOMMENT_FAIL",
}

export enum AuthorizationLevel {
    COMPULSARY = 1,
    OPTIONAL = 2,
    CAN_DISABLE = 3
}