import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Components from 'us.common/components';
import { MoreOutlined } from 'us.icons';

export default ({
	locales,
	title,
}: {
	locales: Array<string>;
	title: string;
}) => {
	const { t } = useTranslation();
	return (
		<Formik
			initialValues={{ title }}
			enableReinitialize
			validateOnChange
			validateOnBlur
			validateOnMount
			onSubmit={() => {}}>
			{({
				values,
				errors,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...rest
			}: any) => (
				<>
					<Components.$Popover
						placement='rightTop'
						content={
							<div
								onClick={(
									e: any
								) =>
									e.stopPropagation()
								}>
								<Components.$Input
									name='text'
									addonBefore={
										<Components.$Select
											size='middle'
											formitem={{
												label: 'language',
											}}
											onClick={(
												e: any
											) =>
												e.stopPropagation()
											}
											name='country'
											options={[
												{
													label: 'en',
													value: 'en',
												},
												{
													label: 'no',
													value: 'no',
												},
											]}
										/>
									}
									onClick={(
										e: any
									) =>
										e.stopPropagation()
									}
								/>
								<Components.$Button
									onClick={(
										e: any
									) =>
										e.stopPropagation()
									}>
									Update
								</Components.$Button>
							</div>
						}
						destroyTooltipOnHide>
						{t(title)}
						<Components.$Button
							onClick={(e) =>
								e.stopPropagation()
							}
							icon={<MoreOutlined />}
							size='small'
						/>
					</Components.$Popover>
				</>
			)}
		</Formik>
	);
};
