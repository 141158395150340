import { IOrderline } from "../Interface";
import { CreditOptions } from "us.common/components/CreditNote/Constants";

/**
 * @method
 * @param {Array} creditNoteDetails - Array of orderlines
 * @returns Total credit note amount
 */
export const calculateCreditAmount = (
  creditNoteDetails: Array<IOrderline>
): number => {
  try {
    let totalCreditAmount = creditNoteDetails
      ? creditNoteDetails.reduce(
          (
            totalAmount: number,
            { creditedAmount }: { creditedAmount: number }
          ) => {
            return totalAmount + creditedAmount;
          },
          0
        )
      : 0;

    return Number(totalCreditAmount.toFixed(2));
  } catch (e) {
    return 0;
  }
};

/**
 * @method
 * @param {Array} creditNoteDetails - Array of orderlines
 * @param {number} balance - invoice balance
 * @returns Total credit note balance
 */
export const calculateCreditBalance = (
  creditNoteDetails: Array<IOrderline>,
  balance: number
): number => {
  try {
    const total = creditNoteDetails
      ? creditNoteDetails.reduce(
          (
            totalAmount: number,
            { creditedAmount }: { creditedAmount: number }
          ) => {
            return totalAmount + creditedAmount;
          },
          0
        )
      : 0;

    return Number((balance - total).toFixed(2));
  } catch (e) {
    return 0;
  }
};

/**
 * @method
 * @param {object} values - Formik values with containing amount and balance
 * @param {string} creditOption - Selected option key
 * @returns Initial amount
 */
const initialAmount = <T extends { amount: number; balance: number }>(
  values: T,
  creditOption: string
): number => {
  if (creditOption === CreditOptions.ENTIRE_INVOICE_AMOUNT) {
    return Number(values.amount.toFixed(2));
  } else if (creditOption === CreditOptions.ENTIRE_INVOICE_BALANCE) {
    return Number(values.balance.toFixed(2));
  } else if (creditOption === CreditOptions.EACH_ARTICLES) {
    return 0;
  } else {
    return 0;
  }
};

/**
 * @method
 * @param {object} values - Formik values with containing creditNoteDetails, amount and balance
 * @param {string} creditOption - Selected option key
 * @param {any} helperCN - creditNoteDetails helper options
 * @returns total
 */
export const cumulativeCreditAmount = <
  T extends { creditNoteDetails: Array<any>; amount: number; balance: number }
>(
  creditOption: string,
  values: T,
  helperCN: any
): number => {
  try {
    return values.creditNoteDetails.reduce(
      (
        totalAmount: number,
        {
          creditedAmount,
          total,
          ...record
        }: { creditedAmount: number; total: number },
        index: number
      ) => {
        let nextAmount = 0;
        let nextReducedPriced = 0;
        let nextCreditedAmount = 0;

        if (total < 0) {
          nextAmount = totalAmount;
          nextCreditedAmount = 0;
          nextReducedPriced = 0;
        } else if (totalAmount >= total) {
          nextAmount = parseFloat((totalAmount - total).toFixed(2));
          nextCreditedAmount = total;
          nextReducedPriced = total - nextCreditedAmount;
        } else if (totalAmount <= total && totalAmount - total !== 0) {
          nextAmount = 0;
          nextCreditedAmount = totalAmount;
          nextReducedPriced = total - nextCreditedAmount;
        }
        values.creditNoteDetails[index] = {
          ...record,
          total: Number(total.toFixed(2)),
          reducedPriced: Number(nextReducedPriced.toFixed(2)),
          creditedAmount: Number(nextCreditedAmount.toFixed(2)),
        };

        helperCN.setValue(values.creditNoteDetails);

        return nextAmount;
      },
      initialAmount(values, creditOption)
    );
  } catch (e) {
    return 0;
  }
};
