import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  $AmountLabel,
  $Skeleton,
  $Divider,
  $DateLabel,
} from "us.common/components";
import { ISummary } from "us.common/components/CreditNote/Interface";

const Summery = ({ invoiceDetails, loading }: ISummary) => {
  const {
    invoiceNo,
    customerNo,
    customerName,
    dueDate,
    amount,
    balance,
    isExported,
  } = invoiceDetails;
  const { t } = useTranslation();
  return (
    <>
      {invoiceNo && (
        <$Skeleton active paragraph={{ rows: 2 }} loading={loading}>
          <div className="invoice-summary d-flex p-2">
            <div>
              <strong>
                {customerNo} - {customerName}
              </strong>
              <div>{t("US.COMMON:CREDIT_NOTE.CUSTOMER")}</div>
            </div>
            <$Divider
              className="mx-4"
              type="vertical"
              style={{ height: "100%" }}
            />
            {dueDate && moment.utc(dueDate).isAfter(moment.utc("0001-01-01")) && (
              <>
                <div>
                  <strong>
                    <$DateLabel value={dueDate} />
                  </strong>
                  <div>{t("US.COMMON:CREDIT_NOTE.DUE_DATE")}</div>
                </div>
                <$Divider
                  type="vertical"
                  className="mx-4"
                  style={{ height: "100%" }}
                />
              </>
            )}
            <div>
              <strong>
                <$AmountLabel value={amount} />
              </strong>
              <div>{t("US.COMMON:CREDIT_NOTE.AMOUNT")}</div>
            </div>
            <$Divider
              className="mx-4"
              type="vertical"
              style={{ height: "100%" }}
            />
            <div>
              <strong>
                <$AmountLabel value={balance} />
              </strong>
              <div>{t("US.COMMON:CREDIT_NOTE.BALANCE")}</div>
            </div>
            <$Divider
              className="mx-4"
              type="vertical"
              style={{ height: "100%" }}
            />
            <div>
              <strong>
                {isExported
                  ? t("US.COMMON:CREDIT_NOTE.EXPORTED")
                  : t("US.COMMON:CREDIT_NOTE.PENDING")}
              </strong>
              <div>{t("US.COMMON:CREDIT_NOTE.STATUS")}</div>
            </div>
          </div>
        </$Skeleton>
      )}
    </>
  );
};

export default Summery;
