import { IWorkflowState, IRetryWorkflowState } from "../Interfaces";

const RetryWorkflowInitial: IRetryWorkflowState = {
  data: undefined,
  isError: false,
  isLoading: false,
};

export const initialState: IWorkflowState = {
  retryWorkflow: RetryWorkflowInitial,
};