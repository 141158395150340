import { EntityTypeShortForm } from 'us.common/constants';
import { IMetaData } from 'us.common/interfaces';

/**
 * @function
 * @description check whether subCase is transferred to case or not
 * @param {IMetaData} metaData mate data
 * @returns {boolean}
 */
export const isSubCaseTransferredToCase = (metaData: IMetaData): boolean => {
	try {
		const { entityType, parentCaseId } = metaData;
		return (
			entityType === EntityTypeShortForm.SUB_CASE &&
			parentCaseId > 0
		);
	} catch (error) {
		return false;
	}
};
