import { Setting, SystemSetting } from "us.collection.admin/interfaces";

/**
 * @description Get system setting by id
 * @param {Array<SystemSetting>} settings - List of system settings to find by id
 * @param {string | null} id - The URL params id to find matching system setting from the given list
 * @returns {SystemSetting | undefined} - If find item will return it otherwise return undefined
 */
export const getSystemSettingById = (
  settings: Array<SystemSetting>,
  id: string | null
): SystemSetting | {} => {
  try {
    if (id && typeof id === "string") {
      const matchedRecord = settings.find(
        (setting: SystemSetting) => setting.id === parseInt(id)
      );
      return matchedRecord ?? {};
    }
    return {};
  } catch (error) {
    return {};
  }
};

/**
 * @description Get settings by typeId of the selected menu
 * @param {Array<SystemSetting>} systemSettings - List of system settings to filter by typeId
 * @param {string | null} typeId - The URL params typeId to filter matching settings from the given list
 * @returns {Array<Setting>} - Filtered settings
 */
export const findSettingsByTypeId = (
  systemSettings: Array<SystemSetting>,
  typeId: string | null
): Array<Setting> => {
  try {
    if (typeId && typeof typeId === "string") {
      const matchedRecords: Array<Setting> = [];
      systemSettings.filter((setting: SystemSetting) => {
        if (setting.typeId === parseInt(typeId)) {
          matchedRecords.push(setting);
        }
      });
      return matchedRecords;
    }
    return [];
  } catch (error) {
    return [];
  }
};
