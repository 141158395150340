import axios, { AxiosResponse, AxiosError, AxiosPromise } from "axios";
import axiosRetry from "axios-retry";
import * as Azure from "us.helper/azure";
import { RetryConfig } from "../retry-config";

const errorInterceptor = (error: AxiosError) => {
  // appInsights.trackException(
  //   `${error.response?.config.baseURL}${
  //     error.response?.config.url
  //   } - Status Code:- ${error.response?.status} - Status Text:- ${
  //     error.response?.statusText
  //   } - Data:- ${JSON.stringify(error.response?.data)}`
  // );
  switch (error?.response?.status) {
    case 400:
      break;
    case 401:
      break;
    case 404:
      break;
    default:
  }
  // return Promise.reject(error);
  return error.response;
};

const responseInterceptor = (response: AxiosResponse) => {
  switch (response.status) {
    case 200:
      break;
    case 204:
      break;
    default:
  }

  return response;
};

const axiosInstance = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY}`,
  },
  transformResponse: [
    function (data, headers) {
      let response;
      try {
        response = data ? JSON.parse(data) : {};
      } catch (error) {
        throw Error(`${JSON.stringify(error)}`);
      }
      return response;
    },
  ],
});

const axiosInstanceForNonJson = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`,
  headers: {
    Accept: "application/octet-stream",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY}`,
  },
  responseType: "arraybuffer",
  transformResponse: [
    function (data) {
      let response;
      try {
        response = data ? data : {};
      } catch (error) {
        throw Error(`${JSON.stringify(error)}`);
      }
      return response;
    },
  ],
});

const axiosInstanceMultipart = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Ocp-Apim-Subscription-Key": `${window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY}`,
  },
  transformResponse: [
    function (data) {
      let response;
      try {
        response = data ? data : {};
      } catch (error) {
        throw Error(`${JSON.stringify(error)}`);
      }
      return response;
    },
  ],
});

const axiosInstanceGraphExplorer = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_GRAPH_EXPLORER_BASE_API}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  },
  transformResponse: [
    function (data) {
      let response;
      try {
        response = data ? JSON.parse(data) : {};
      } catch (error) {
        throw Error(`${JSON.stringify(error)}`);
      }
      return response;
    },
  ],
});

const axiosInstanceGraphPhoto = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_GRAPH_EXPLORER_BASE_API}`,
  headers: {
    Accept: "image/jpeg",
    "Content-Type": "image/jpeg",
    Authorization: "",
  },
  responseType: "blob",
  transformResponse: [
    function (data, headers) {
      let image;
      try {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });
        const url = window.URL || window.webkitURL;
        image = url.createObjectURL(blob);
      } catch (error) {
        throw Error(`${JSON.stringify(error)}`);
      }
      return image;
    },
  ],
});

const reportingInstance = axios.create({
  baseURL: `https://${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "Ocp-Apim-Subscription-Key": `${window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY}`,
  },
});

// set retry request configuration
axiosRetry(axiosInstance, RetryConfig);
axiosRetry(axiosInstanceForNonJson, RetryConfig);
axiosRetry(axiosInstanceMultipart, RetryConfig);
axiosRetry(reportingInstance, RetryConfig);

// Set the auth token for any request
axiosInstance.interceptors.request.use(async (config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }
  const token = await auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.userId = auth.currentUser().oid;
  return config;
});

// Set the auth token for any request
reportingInstance.interceptors.request.use(async (config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }
  const token = await auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.userId = auth.currentUser().oid;
  return config;
});

axiosInstanceForNonJson.interceptors.request.use((config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const token = auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

axiosInstanceMultipart.interceptors.request.use((config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const token = auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

/**
 * for the graph explore we will use version 1 token from the ADV1 helper
 */
axiosInstanceGraphExplorer.interceptors.request.use(async (config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;

  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const token = auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

axiosInstanceGraphPhoto.interceptors.request.use(async (config) => {
  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;

  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const token = auth.getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
axiosInstanceForNonJson.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);
axiosInstanceMultipart.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);
axiosInstanceGraphExplorer.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);
axiosInstanceGraphPhoto.interceptors.response.use(
  responseInterceptor,
  errorInterceptor
);

const AzureHttp = {
  reporting: {
    get: function <D extends {} | Array<{}>>(
      service: string,
      endpoint: string,
      data: D,
      version: string | undefined = undefined
    ): AxiosPromise {
      return reportingInstance.get(`/${service}/${version}/${endpoint}`, {
        params: data,
      });
    },
  },
  post: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    version;
    return axiosInstance.post(`/${service}/${version}/${endpoint}`, data);
  },
  put: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.put(`/${service}/${version}/${endpoint}`, data);
  },
  get: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.get(`/${service}/${version}/${endpoint}`, {
      params: data,
    });
  },
  getById: function (
    service: string,
    endpoint: string,
    id: number | string,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.get(`/${service}/${version}/${endpoint}/${id}`);
  },
  delete: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    id: number | string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.delete(`/${service}/${version}/${endpoint}/${id}`, {
      data: data,
    });
  },
  patch: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.patch(`/${service}/${version}/${endpoint}`, data);
  },
  getFile: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstanceForNonJson.get(`/${service}/${version}/${endpoint}`, {
      params: data,
    });
  },
  uploadFile: function (
    service: string,
    endpoint: string,
    data: any,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstanceMultipart.post(
      `/${service}/${version}/${endpoint}`,
      data
    );
  },
  putById: function <D extends {} | Array<{}>>(
    service: string,
    endpoint: string,
    id: number | string,
    data: D,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstance.put(`/${service}/${version}/${endpoint}/${id}`, data);
  },
  downloadFile: function (
    service: string,
    endpoint: string,
    data: any,
    version: string | undefined = undefined
  ): AxiosPromise {
    return axiosInstanceForNonJson.post(
      `/${service}/${version}/${endpoint}`,
      data
    );
  },
  getGraphDetail: function <D extends {} | Array<{}>>(
    endpoint: string,
    version: string,
    data: D
  ): AxiosPromise {
    return axiosInstanceGraphExplorer.get(`/${version}/${endpoint}`, {
      params: data,
    });
  },
  getGraphPhoto: function <D extends {} | Array<{}>>(
    endpoint: string,
    version: string,
    data: D
  ): AxiosPromise {
    return axiosInstanceGraphPhoto.get(`/${version}/${endpoint}`, {
      params: data,
    });
  },
};

export default AzureHttp;
