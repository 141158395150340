import { Handler } from "us.collection/constants";
import { getHandlerList, getHandlerName } from "./Functions";
import { IHandlerDataModel } from "./Interfaces";
import { initialState } from "./State";

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Handler.GET_HANDLER_START:
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: state.handler.data,
          isLoading: true,
          isError: false,
        },
      });
    case Handler.GET_HANDLER_SUCCESS:
      const data = getHandlerList(state.handler?.data, action.payload.data);
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: data,
          isLoading: false,
          isError: false,
        },
      });
    case Handler.GET_HANDLER_FAIL:
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: { ...state.handler.data },
          isLoading: false,
          isError: true,
        },
      });
    case Handler.UPDATE_HANDLER_START:
      const handlerDetailsStart = getHandlerName(
        state.handler.data,
        action.payload?.data
      );
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: handlerDetailsStart,
          isLoading: false,
          isError: false,
        },
      });
    case Handler.UPDATE_HANDLER_SUCCESS:
      const handlerDetailsSuccess = getHandlerName(
        state.handler.data,
        action.payload?.data
      );
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: handlerDetailsSuccess,
          isLoading: false,
          isError: false,
        },
      });
    case Handler.UPDATE_HANDLER_FAIL:
      const handlerDetailsFail = getHandlerName(
        state.handler.data,
        action.payload?.data
      );
      return Object.assign({}, state, {
        handler: <IHandlerDataModel>{
          data: handlerDetailsFail,
          isLoading: false,
          isError: true,
        },
      });
    default:
      return state;
  }
};
