import React, { useEffect, useState } from 'react';
import { InputNumber, Form } from 'antd';
import { useField, Field } from 'formik';
import * as Utility from '../utility';
import { useTranslation } from 'react-i18next';

export const $InputNumber = ({ name, formItem, onBlur, ...rest }: any) => {
  const [field, meta, helpers] = useField(name);

  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();

  return (
    <Form.Item
      label={rest?.label}
      {...formItem}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
    >
      <Field
        as={InputNumber}
        {...field}
        {...rest}
        onChange={
          typeof rest?.onChange == 'function'
            ? rest?.onChange
            : (v: any) => helpers?.setValue(v)
        }
        onBlur={(e: any) => {
          onBlur && onBlur(e);
          field.onBlur(e);
        }}
      />
    </Form.Item>
  );
};
