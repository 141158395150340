import { IAPIAction } from "us.collection/interfaces";
import { ManageFollowup } from "us.collection.admin/constants/Actions";
import { IManageFollowupAction } from "us.collection.admin/interfaces";

export const manageFollowupActions: Readonly<
  IManageFollowupAction & IAPIAction
> = {
  categories: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_CATEGORIES_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_CATEGORIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOWUP_CATEGORIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    save: (data) => ({
      type: ManageFollowup.SAVE_FOLLOW_UP_CATEGORY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    saveSuccess: (data) => ({
      type: ManageFollowup.SAVE_FOLLOW_UP_CATEGORY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    saveFail: (error) => ({
      type: ManageFollowup.SAVE_FOLLOW_UP_CATEGORY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  followups: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_LIST_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_LIST_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOWUP_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    remove: (data) => ({
      type: ManageFollowup.REMOVE_FOLLOWUP_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    removeSuccess: (data) => ({
      type: ManageFollowup.REMOVE_FOLLOWUP_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    removeFail: (error) => ({
      type: ManageFollowup.REMOVE_FOLLOWUP_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  followupResult: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_RESULT_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_RESULT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOWUP_RESULT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: ManageFollowup.RESET_FOLLOWUP_RESULT,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  entityTypes: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ENTITY_TYPES,

      payload: {
        data,

        isLoading: true,
      },
    }),

    success: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ENTITY_TYPES_SUCCESS,

      payload: {
        data,

        isLoading: false,
      },
    }),

    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ENTITY_TYPES_FAIL,

      payload: {
        error,

        isLoading: false,
      },
    }),
  },
  customQuery: {
    save: (data) => ({
      type: ManageFollowup.SAVE_CUSTOM_QUERY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.SAVE_CUSTOM_QUERY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.SAVE_CUSTOM_QUERY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    getProperties: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    getPropertiesSuccess: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    getPropertiesFail: (error) => ({
      type: ManageFollowup.GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    execute: (data) => ({
      type: ManageFollowup.EXECUTE_CUSTOM_QUERY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    executeSuccess: (data) => ({
      type: ManageFollowup.EXECUTE_CUSTOM_QUERY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    executeFail: (error) => ({
      type: ManageFollowup.EXECUTE_CUSTOM_QUERY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    manageDrawer: (data) => ({
      type: ManageFollowup.MANAGE_CUSTOM_QUERY_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  activities: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ACTIVITIES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ACTIVITIES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ACTIVITIES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  users: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_USERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_USERS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOW_UP_USERS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  roles: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ROLES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ROLES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOW_UP_ROLES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  followUpDetails: {
    get: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManageFollowup.GET_FOLLOWUP_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManageFollowup.GET_FOLLOWUP_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: ManageFollowup.RESET_FOLLOWUP_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
