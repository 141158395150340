import { IInitialState } from "../Interfaces";
import { followUpResult } from "./FollowupResult";
import { customQueryResult } from "./CustomQueryResult";
import { customQueryProperty } from "./CustomQueryProperty";
import {
  CustomQueryDrawerType,
  followUpDetails,
} from "us.collection.admin/constants";

export const initialState: IInitialState = {
  categories: {
    data: [],
    isFetching: false,
  },
  list: {
    data: [],
    isFetching: false,
  },
  followupResults: {
    data: followUpResult,
    result: [],
    columns: [],
    isFetching: false,
  },
  columns: {
    data: [],
    isFetching: false,
  },
  customQuery: {
    entities: {
      data: [],
      isFetching: false,
    },
    properties: {
      data: customQueryProperty,
      isFetching: false,
    },
    result: {
      data: customQueryResult,
      dataSource: [],
      columns: [],
      isFetching: false,
    },
    activities: {
      data: [],
      isFetching: false,
    },
    users: {
      data: [],
      isFetching: false,
    },
    roles: {
      data: [],
      isFetching: false,
    },
    drawer: {
      type: CustomQueryDrawerType.VIEW_RESULT,
      title: "",
      visible: false,
    },
    isNewCustomQuery: true,
  },
  followUpDetails: {
    data: followUpDetails,
    isFetching: false,
  },
};
