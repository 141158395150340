export enum DrawerType {
	ADD = 'ADD',
	UPDATE = 'UPDATE',
}

export const DRAWER_DEFAULT_STATE = {
	visible: false,
	type: DrawerType.ADD,
	title: '',
};

export const data = [
	{
		id: 1,
		name: 'Test Name 1 Test Name 1 Test Name 1 Test Name 1 Te',
		value: 'Value 1',
		description: 'Test Description 1',
		typeId: 1,
		typeName: 'type1',
	},
	{
		id: 2,
		name: 'Test Name 2',
		value: 'Value 2',
		description: 'Test Description 2',
		typeId: 3,
		typeName: 'type3',
	},
];
