import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';


let testData = {
    "activityId": 120,
    "name": "CaseClose",
    "settings": [
        {
            "name": "EntityNo",
            "valueType": "Fix",
            "value": 459,
            "parameterType": "int"
        },
        {
            "name": "User",
            "valueType": "Fix",
            "value": "adminUser",
            "parameterType": "string"
        },
        {
            "name": "spParams",
            "valueType": "Fix",
            "value": "adminUser",
            "parameterType": "string"
        },
        {
            "name": "closestates",
            "valueType": "List",
            "value": [
                {
                    "id": 1,
                    "value": "Case Closed - Fully paid",
                    "extendedField": "Avsluttet fullt oppgjør",
                },
                {
                    "id": 2,
                    "value": "Case Closed - Paid before legal action",
                    "extendedField": "Betalt før rettslig pågang",
                },
                {
                    "id": 3,
                    "value": "Case Closed - Paid before registration",
                    "extendedField": "Betalt før registrering",
                }
            ],
            "parameterType": "int"
        }
    ]
}
let activity = [{
    "activityId": 120,
    "activityCode": "9004",
    "name": "aa",
    "displayName": "Transfer to case",
    "description": "Transfer subcase to case level.",
    "type": "SP",
    "categoryId": "1",
    "categoryName": "SP",
    "entitytype": "SubCase",
    "canExecute": 1,
    "activityGroupId": 1,
    "activityGroup": "PreCollection"
},
{
    "activityId": 121,
    "activityCode": "5656",
    "name": "TransferToCase",
    "displayName": "Transfer to case",
    "description": "Transfer subcase to case level.",
    "type": "Message",
    "categoryId": "1",
    "categoryName": "",
    "entitytype": "SubCase",
    "canExecute": 1,
    "activityGroupId": 6,
    "activityGroup": "New"
},
{
    "activityId": 456,
    "activityCode": "9005",
    "name": "Rajitha",
    "displayName": "case count",
    "description": "Transfer subcase to case level.",
    "type": "SP",
    "categoryId": "2",
    "categoryName": "",
    "entitytype": "SubCase",
    "canExecute": 1,
    "activityGroupId": 2,
    "activityGroup": "Collection"
}
]
export default {
    parameters: {
        get: <
            P extends {
                activityId: string;
                state: string;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "activityservice",
                        `activities/${params.activityId}/${params.state}/settings`,
                        {},
                        ServiceConfig()[`activityservice`]['activities.settings']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    activity: {
        execute: (params: any): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.post(
                        "activityservice",
                        "activities",
                        params,
                        ServiceConfig()[`activityservice`]['activities']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    activities: {
        get: <
            P extends {
                entitytype: string;
                entitystate: string;
                canExecute: boolean
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "activityservice",
                        `activities`,
                        params,
                        ServiceConfig()[`activityservice`]['activities']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    pendingActivities: {
        execute: <P extends {
            entityId: number | string,
            entityType: string
        }>(params: P): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.post(
                        "activityservice",
                        "pendingactivities",
                        params,
                        ServiceConfig()[`activityservice`]['pendingactivities']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    }
}