import {
  DefaultCategory,
  FollowupEntityType,
} from "us.collection.admin/constants";

/**
 * @function
 * @description - repository method for making request to save followup
 * @param this - form data
 * @param {boolean} isStaticWorkFlow - is static or dynamic workflow
 * @param {FollowupEntityType} entityType - entity types of the system
 * @param {string} customQuery - typed sql custom query for the followup
 * @returns - request object
 */
export function SaveFollowUp(
  this: any,
  isStaticWorkFlow: boolean,
  entityType: FollowupEntityType,
  customQuery: string
) {
  return {
    followupId: this.followupId,
    followupName: this.followupName,
    followupDisplayName: this.followupDisplayName,
    followupCategoryId: this.followupCategory,
    isDynamicFollowup: !isStaticWorkFlow,
    description: this.description,
    assignType: this.assignType,
    setAsFollowup: this.isCaseHandler,
    entityType,
    customQuery,
    assign: this.assignToList.map((assignEntity: any) => {
      return {
        assignId: assignEntity.assignId,
        assignTo: assignEntity.assignTo,
      };
    }),
    followupactivities: this.activitiesList.map((activity: any) => {
      return { activityId: activity.activityId };
    }),
  };
}
