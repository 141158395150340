import { initialState } from './State';
import { MainSearchConstants } from 'us.common/constants/MainSearchActions';
import {
	IInvoiceSearchResultModel,
	ISearchHistoryResultModel,
	IMainSearchResults,
	ICaseSearchResultModel,
	IDebtorSearchResultModel,
	ICreditorSearchResultModel,
	IInvoiceSearchResult,
	ICaseSearchResult,
	ICreditorSearchResult,
	IDebtorSearchResult,
} from './Interfaces';
import { IReturnAction } from 'us.common/interfaces';
import { DataFunctions } from './Functions';
const {
	getInvoicesAndCasesArranged,
	getCreditorGroupArranged,
	getDebtorArranged,
} = DataFunctions;

const {
	GET_ALL_SEARCH_DATA,
	GET_ALL_SEARCH_DATA_SUCCESS,
	GET_ALL_SEARCH_DATA_FAIL,

	GET_INVOICE_REF_SEARCH_DATA,
	GET_INVOICE_REF_SEARCH_DATA_SUCCESS,
	GET_INVOICE_REF_SEARCH_DATA_FAIL,

	GET_INVOICE_SEARCH_DATA,
	GET_INVOICE_SEARCH_DATA_SUCCESS,
	GET_INVOICE_SEARCH_DATA_FAIL,

	GET_CASE_SEARCH_DATA,
	GET_CASE_SEARCH_DATA_SUCCESS,
	GET_CASE_SEARCH_DATA_FAIL,

	GET_DEBTOR_SEARCH_DATA,
	GET_DEBTOR_SEARCH_DATA_SUCCESS,
	GET_DEBTOR_SEARCH_DATA_FAIL,
	TOGGLE_DEBTOR_AR,

	GET_CREDITOR_SEARCH_DATA,
	GET_CREDITOR_SEARCH_DATA_SUCCESS,
	GET_CREDITOR_SEARCH_DATA_FAIL,
	TOGGLE_CREDITOR_GROUP,

	GET_SEARCH_HISTORY,
	GET_SEARCH_HISTORY_SUCCESS,
	GET_SEARCH_HISTORY_FAIL,

	BROWSER_BACK_NAVIGATION,
	UPDATE_VIEWED_LIST,
} = MainSearchConstants;

export default (
	state: IMainSearchResults = initialState,
	action: IReturnAction<any>
) => {
	const getInvoiceObject = (
		data: IInvoiceSearchResult[] | [],
		isLoading: boolean,
		isError: boolean
	) => {
		return {
			invoice: <IInvoiceSearchResultModel>{
				isLoading: isLoading,
				data: getInvoicesAndCasesArranged(data, 'invoice'),
				isError: isError,
			},
		};
	};
	const getCaseObject = (
		data: ICaseSearchResult[] | [],
		isLoading: boolean,
		isError: boolean
	) => {
		return {
			case: <ICaseSearchResultModel>{
				isLoading: isLoading,
				data: getInvoicesAndCasesArranged(data, 'case'),
				isError: isError,
			},
		};
	};
	const getCreditorObject = (
		data: ICreditorSearchResult[] | [],
		isLoading: boolean,
		isError: boolean
	) => {
		return {
			creditor: <ICreditorSearchResultModel>{
				isLoading: isLoading,
				data: getCreditorGroupArranged(data),
				isError: isError,
			},
		};
	};
	const getDebtorObject = (
		data: IDebtorSearchResult[] | [],
		isLoading: boolean,
		isError: boolean
	) => {
		return {
			debtor: <IDebtorSearchResultModel>{
				isLoading: isLoading,
				data: getDebtorArranged(data),
				isError: isError,
			},
		};
	};
	switch (action.type) {
		//#region All search
		case GET_ALL_SEARCH_DATA:
			return Object.assign(
				{},
				state,
				getInvoiceObject([], true, false),
				getCaseObject([], true, false),
				getCreditorObject([], true, false),
				getDebtorObject([], true, false)
			);
		case GET_ALL_SEARCH_DATA_SUCCESS:
			return Object.assign(
				{},
				state,
				getInvoiceObject(
					((action.payload.data as any)[
						'invoiceses'
					] as IInvoiceSearchResult[]) ?? [],
					false,
					false
				),
				getCaseObject(
					(action.payload.data as any)['cases'] as ICaseSearchResult[],
					false,
					false
				),
				getCreditorObject(
					(action.payload.data as any)['creditors'] as ICreditorSearchResult[],
					false,
					false
				),
				getDebtorObject(
					(action.payload.data as any)['debtors'] as IDebtorSearchResult[],
					false,
					false
				)
			);
		case GET_ALL_SEARCH_DATA_FAIL:
			return Object.assign(
				{},
				state,
				getInvoiceObject([], false, true),
				getCaseObject([], false, true),
				getCreditorObject([], false, true),
				getDebtorObject([], false, true)
			);
		//#endregion

		//#region InvoiceRef search
		case GET_INVOICE_REF_SEARCH_DATA:
			return Object.assign(
				{},
				state,
				getInvoiceObject([], true, false),
				getCaseObject([], true, false)
			);
		case GET_INVOICE_REF_SEARCH_DATA_SUCCESS:GET_CASE_SEARCH_DATA_SUCCESS
			return Object.assign(
				{},
				state,
				getInvoiceObject(
					((action.payload.data as any)[
						'invoiceses'
					] as IInvoiceSearchResult[]) ?? [],
					false,
					false
				),
				getCaseObject(
					(action.payload.data as any)['cases'] as ICaseSearchResult[],
					false,
					false
				)
			);
		case GET_INVOICE_REF_SEARCH_DATA_FAIL:
			return Object.assign(
				{},
				state,
				getInvoiceObject([], false, true),
				getCaseObject([], false, true)
			);
		//#endregion

		//#region for invoice search data
		case GET_INVOICE_SEARCH_DATA:
			return Object.assign({}, state, getInvoiceObject([], true, false));
		case GET_INVOICE_SEARCH_DATA_SUCCESS:
			return Object.assign(
				{},
				state,
				getInvoiceObject(
					(action.payload.data as IInvoiceSearchResult[]) ?? [],
					false,
					false
				)
			);
		case GET_INVOICE_SEARCH_DATA_FAIL:
			return Object.assign({}, state, getInvoiceObject([], false, true));
		//#endregion

		//#region for case search data
		case GET_CASE_SEARCH_DATA:
			return Object.assign({}, state, getCaseObject([], true, false));

		case GET_CASE_SEARCH_DATA_SUCCESS:
			return Object.assign(
				{},
				state,
				getCaseObject(
					(action.payload.data as ICaseSearchResult[]) ?? [],
					false,
					false
				)
			);

		case GET_CASE_SEARCH_DATA_FAIL:
			return Object.assign(
				{},
				state,
				getCaseObject(
					(action.payload.data as ICaseSearchResult[]) ?? [],
					false,
					true
				)
			);
		//#endregion

		//#region for creditor search data
		case GET_CREDITOR_SEARCH_DATA:
			return Object.assign({}, state, getCreditorObject([], true, false));
		case GET_CREDITOR_SEARCH_DATA_SUCCESS:
			return Object.assign(
				{},
				state,
				getCreditorObject(
					(action.payload.data as ICreditorSearchResult[]) ?? [],
					false,
					false
				)
			);
		case GET_CREDITOR_SEARCH_DATA_FAIL:
			return Object.assign({}, state, getCreditorObject([], false, true));
		case TOGGLE_CREDITOR_GROUP:
			return Object.assign({}, state, {
				creditor: <ICreditorSearchResultModel>{
					isLoading: false,
					data: action.payload.data,
					isError: false,
				},
			});
		//#endregion

		//#region for debtor search data
		case GET_DEBTOR_SEARCH_DATA:
			return Object.assign({}, state, getDebtorObject([], true, false));
		case GET_DEBTOR_SEARCH_DATA_SUCCESS:
			return Object.assign(
				{},
				state,
				getDebtorObject(
					(action.payload.data as IDebtorSearchResult[]) ?? [],
					false,
					false
				)
			);
		case GET_DEBTOR_SEARCH_DATA_FAIL:
			return Object.assign({}, state, getDebtorObject([], false, true));
		case TOGGLE_DEBTOR_AR:
			return Object.assign({}, state, {
				debtor: <IDebtorSearchResultModel>{
					isLoading: false,
					data: action.payload.data,
					isError: false,
				},
			});
		//#endregion
		//#region for case search history
		case GET_SEARCH_HISTORY:
			return Object.assign({}, state, {
				history: <ISearchHistoryResultModel>{
					isLoading: true,
					data: [],
					isError: false,
				},
			});

		case GET_SEARCH_HISTORY_SUCCESS:
			return Object.assign({}, state, {
				history: <ISearchHistoryResultModel>{
					data: action.payload.data ?? [],
					isLoading: false,
					isError: false,
				},
			});

		case GET_SEARCH_HISTORY_FAIL:
			return Object.assign({}, state, {
				history: <ISearchHistoryResultModel>{
					isError: true,
					isLoading: false,
					data: [],
				},
			});
		case BROWSER_BACK_NAVIGATION:
			return Object.assign({}, state, {
				backNavigation: {
					...state.backNavigation,
					...action.payload.data,
				},
			});
		case UPDATE_VIEWED_LIST:
			return Object.assign({}, state, {
				viewedList: action.payload.data,
			});
		default:
			return state;
	}
};
