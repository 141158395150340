import { IInitialState } from "../Interfaces";
import { addDebtorInitialData } from "./AddDebtorInitial";

export const initialState: IInitialState = {
    debtors: {
      data: [],
      isLoading: false,
    },
    addDebtorDrawer: {
      title: "",
      visible: false
    },
    addDebtorInitial: addDebtorInitialData,
    otherPartyRoles: {
      data: [],
      currentTab: "",
      isLoading: false
    }
};