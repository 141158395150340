import { IUserSummaryWithExtendedFields } from 'us.common/interfaces';

/**
 * @function
 * @description get Table data according to filter value
 * @param { Array<IUserSummaryWithExtendedFields> } users
 * @param {string} filter
 * @returns { Array<IUserSummaryWithExtendedFields> }
 */
export const filterUserByActivityStatus = (
	users: Array<IUserSummaryWithExtendedFields>,
	filter: string
): Array<IUserSummaryWithExtendedFields> => {
	try {
		switch (filter) {
			case 'active':
				return users.filter(
					({
						activeState,
					}: IUserSummaryWithExtendedFields) =>
						activeState
				);
			case 'deactivated':
				return users.filter(
					({
						activeState,
					}: IUserSummaryWithExtendedFields) =>
						!activeState
				);
			default:
				return [...users];
		}
	} catch (error) {
		return users;
	}
};

/**
 * @function
 * @description get Table data according to filter value
 * @param {Array<IUserSummaryWithExtendedFields>} users
 * @returns { Array<IUserSummaryWithExtendedFields> }
 */
export const searchUserByNameAndEmail = (
	users: Array<IUserSummaryWithExtendedFields>,
	searchText: string
): Array<IUserSummaryWithExtendedFields> => {
	try {
		return users.filter(
			({
				displayName,
				email,
			}: IUserSummaryWithExtendedFields) =>
				displayName
					.toLowerCase()
					.includes(searchText.toLowerCase()) ||
				email
					.toLowerCase()
					.includes(searchText.toLowerCase())
		);
	} catch (error) {
		return users;
	}
};
