import { ISorter } from "../../interface";

/**
 * @description - Sorting for table tree.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 21/03/2021
 * @param data: any data array, add any hierarchical items as children as its parent element
 * the data array items must have child items as children field to work this TableTree.
 * @param columns: Table columns.
 * @param handleColumn: Set filtered columns.
 * @param sortedInfo: Sort direction information.
 * @param onSort: Get sorted data.
 */

const sorter = ({
  data,
  columns,
  filteredData,
  sortedInfo,
  onSort,
  resetFilters,
}: ISorter) => {
  let newData: any = filteredData.length == 0 ? data : filteredData;

  const sortData = (arr: Array<any>) => {
    const column: any = columns?.filter(
      (i: any) => i.dataIndex == sortedInfo?.field
    )[0];

    arr = [...arr].sort(function (a, b) {
      const x = a[sortedInfo.field];
      const y = b[sortedInfo.field];
      if (sortedInfo?.order == "ascend") {
        return column?.customSorter(x, y);
      } else if (sortedInfo?.order == "descend") {
        return column?.customSorter(y, x);
      } else {
        return;
      }
    });
    return arr;
  };

  if (onSort) {
    if (typeof sortedInfo?.order == "undefined") {
      resetFilters();
      return [];
    } else {
      return onSort(sortData, newData, sortedInfo);
    }
  } else {
    return newData;
  }
};

export default sorter;
