import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  $Form,
  $AsyncInput,
  $Input,
  $Button,
  $DatePicker,
  $Popconfirm,
  $SelectGroup,
  $Row,
  $Col,
  $Divider,
  $Select,
  $Drawer,
  $Popover,
  $Skeleton,
  $Collapse,
  $TableTree,
} from 'us.common/components';
import * as Actions from 'us.common/actions';
import { IAddDebtor, ICountryValue, INavigationData } from './Interfaces';
import {
  PlusOutlined,
  ExclamationCircleFilled,
  DeleteOutlined,
  MinusOutlined,
} from 'us.icons';
import {
  formatPersonNo,
  getFormatedOrganizationNo,
  getURLTypeAndId,
  validatePersonNo,
} from './Functions';
import { europeanCountry, DEFAULT_COUNTRY_CODE } from 'us.common/constants';
import { getPhoneNumbers } from 'us.common/functions';
import { PostalArea } from 'us.common/containers/PostalArea';
import { EntitySearch } from './Components';
import { DateFormats, drawerInitial, OtherPartyTypes } from './Constants';
import _ from 'lodash';
import { IOnFilter, IOnSort } from 'us.common/components';
import { AddDebtorValidation } from 'us.common/components/NewDebtor/Validation';
import {
  MunicipalityCode,
  NewDebtor,
  NewRoleType,
  OtherParty,
  PostalCode,
} from 'us.common/repository';

/**
 * @description - Component for New Debtor/Other Party
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 02/05/2022
 * */

const AddDebtor: React.FC<IAddDebtor> = (props) => {
  const { t } = useTranslation();
  const {
    currentDateFormat,
    onClose,
    metaData,
    getRoleType,
    saveRole,
    deleteRoleTypes,
    postalcode,
    municipility,
    searchPostalcode,
    searchMunicipility,
    arDetails,
    isArDetailsFetching,
    isAddOtherParty = false,
    addDebtorInitial,
    otherPartyRoles,
    onSubmit,
  } = props;

  const { state: locationState, pathname: pathName } = useLocation();

  const state = (locationState as INavigationData) ?? {};

  const [addRoleType, setAddRoleType] = useState('');
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [country, setCountry] = useState<ICountryValue>({
    preValue: '',
    newValue: '',
  });
  const [drawer, setDrawer] = useState(drawerInitial);

  useEffect(() => {
    isAddOtherParty &&
      getRoleType({
        otherPartyType: OtherPartyTypes.PERSON,
        currentTab: getURLTypeAndId(pathName, arDetails, state, metaData)
          .caseType,
      });
  }, []);

  /**
   * @description - Handle other party type change and get other party role type
   * @param otherPartyType - Select other party type
   * @param restProps - Formik props
   */
  const onChangeOtherPartyType = (otherPartyType: any, restProps: any) => {
    restProps.setFieldValue('otherpartyType', otherPartyType);
    restProps.setFieldValue('otherpartyRoleType', '');
    isAddOtherParty &&
      getRoleType({
        otherPartyType,
        currentTab: getURLTypeAndId(pathName, arDetails, state, metaData)
          .caseType,
      });
  };

  /**
   * @description - Handle birthday genarate using person number
   * @param personNo - Person number
   * @param rest - Formik props
   */
  const handleBirthday = (personNo: string, rest: any) => {
    const data = validatePersonNo(personNo.replaceAll(/\D/g, ''));
    if (data.isValid) {
      const formatedPersonNo = formatPersonNo(personNo);
      rest.setFieldValue('nin', formatedPersonNo);
      rest.setFieldValue('birthDate', moment(data.birthDate));
    }
  };
  /**
   * @description - Save Debtor/ Other party details
   * @param data - Form data
   * @param actions - Formik actions
   */
  const handleSubmit = (data: any, actions: any) => {
    data.phoneNumbers = getPhoneNumbers(data);
    if (isAddOtherParty) {
      actions.setSubmitting(true);

      const role: { label: string; value: number }[] =
        otherPartyRoles.data?.filter(
          (item: any) => item.value === data?.otherpartyRoleType
        );

      const bDate =
        data.birthDate.length === 0
          ? data.birthDate
          : moment(data.birthDate).format(DateFormats.REQ);

      if (data?.otherpartyType === OtherPartyTypes.COMPANY) {
        data.organizationNo = data?.nin.trim().replace(/ /g, '');
      } else {
        data.personNo = data?.nin.trim().replace(/ /g, '');
      }
      const { id, type, debtorEntRoleId } = getURLTypeAndId(
        pathName,
        arDetails,
        state,
        metaData
      );
      onSubmit &&
        onSubmit(
          OtherParty.call(
            {
              id,
              bDate,
              role,
              type,
              debtorEntRoleId,
            },
            data
          )
        );
    } else {
      const { otherpartyType, birthDate, companyNo, personNo } = data;
      if (otherpartyType === OtherPartyTypes.COMPANY) {
        data.birthDate = null;
        data.idNo = companyNo.trim().replace(/ /g, '');
      } else {
        data.birthDate = moment(birthDate).isValid()
          ? moment(birthDate).format(DateFormats.REQ)
          : null;
        data.idNo = personNo.trim().replace(/ /g, '');
      }
      data.pid = '';
      data.tags = [];
      onSubmit && onSubmit(NewDebtor.call(data));
    }
  };

  /**
   * @description - Add new role type
   * @param values - Formik values
   */
  const handleAddRoleType = (values: any) => {
    if (addRoleType.trim().length > 0) {
      saveRole(NewRoleType.call({ addRoleType, values }));
      setAddRoleType('');
    }
  };
  /**
   * @description - Handle country change
   * @param data - New selected country
   * @param rest - Formik props
   * @param values - Form values
   */
  const onChangeCountry = (data: any, rest: any, values: any) => {
    setCountry({
      preValue: country?.newValue,
      newValue: data,
    });
    rest.setFieldValue('country', data);
    if (values?.zipCode.length > 0) {
      setPopConfirmVisible(true);
    }
  };

  /**
   * @description - Postal code selected
   * @param selectedPostalAreaDetail - Selected details
   * @param rest - Formik props
   */
  const handlePostalDoubleClick = (
    selectedPostalAreaDetail: any,
    rest: any
  ) => {
    const { MunicipalityCode, MunicipalityName, PostalCode, City } =
      selectedPostalAreaDetail ?? {};
    setDrawer(drawerInitial);
    rest.setFieldValue('municipalityCode', MunicipalityCode);
    rest.setFieldValue('municipalityName', MunicipalityName);
    rest.setFieldValue('zipCode', PostalCode);
    rest.setFieldValue('zipName', City);
  };

  /**
   * @description - Search postal details
   * @param zipcode - Entered postal code
   * @param values - Form values
   * @param rest - Formik Props
   */
  const searchZipDetail = (zipcode: string, values: any, rest: any) => {
    if (zipcode?.length > 0) {
      if (searchPostalcode) {
        searchPostalcode({
          values,
          requestObject: PostalCode.call({ zipcode }, values),
        });
      }
    } else {
      rest.setFieldValue('zipName', '');
      rest.setFieldValue('municipalityName', '');
      rest.setFieldValue('municipalityCode', '');
    }
  };

  /**
   * @description - Search municipality code
   * @param countyCode - Entered code
   * @param values - Form values
   * @param rest - Formik props
   */
  const searchMunicipilityDetail = (
    countyCode: string,
    values: any,
    rest: any
  ) => {
    if (countyCode?.length > 0) {
      if (searchMunicipility) {
        searchMunicipility({
          values,
          requestObject: MunicipalityCode.call({ countyCode }, values),
        });
      }
    } else {
      rest.setFieldValue('municipalityName', '');
    }
  };

  /**
   * @description - Change Country
   * @param rest - Formik props
   */
  const countryPopOverConfirmYes = (rest: any) => {
    setPopConfirmVisible(false);
    rest.setFieldValue('municipalityCode', '');
    rest.setFieldValue('municipalityName', '');
    rest.setFieldValue('zipCode', '');
    rest.setFieldValue('zipName', '');
  };

  /**
   * @description - Not select new country
   * @param rest - Formik props
   */
  const countryPopOverConfirmNo = (rest: any) => {
    rest.setFieldValue('country', country?.preValue);
    setPopConfirmVisible(false);
  };

  /**
   * @description - Handle Organization number
   * @param organizationNo - Entered number
   * @param rest - Formik props
   */
  const formatOrganizationNo = (organizationNo: any, rest: any) => {
    if (organizationNo?.length === 9) {
      rest.setFieldValue('nin', getFormatedOrganizationNo(organizationNo));
    }
  };

  /**
   * @description - Select new entity
   * @param data - Entity details
   * @param restProps - Formik props
   */
  const setSearchData = (data: any, restProps: any) => {
    const {
      entRoleId,
      emailAddress,
      birthDate,
      firstName,
      lastName,
      address1,
      address2,
      address3,
      contacts,
      reference,
      zipCode,
      zipName,
      municipalityCode,
      municipalityName,
      countryCode,
      nIN,
    } = data;

    const telephone = contacts?.hasOwnProperty('Mobile')
      ? contacts?.Mobile?.trim()
      : contacts?.hasOwnProperty('sms')
      ? contacts?.sms?.trim()
      : '';

    restProps.setFieldValue('entRoleId', entRoleId);
    restProps.setFieldValue('emailAddress', emailAddress);
    restProps.setFieldValue('birthDate', moment(birthDate));
    restProps.setFieldValue('firstName', firstName);
    restProps.setFieldValue('lastName', lastName);
    restProps.setFieldValue('address1', address1);
    restProps.setFieldValue('address2', address2);
    restProps.setFieldValue('address3', address3);
    restProps.setFieldValue('telephone', telephone);
    restProps.setFieldValue('reference', reference);
    restProps.setFieldValue('zipCode', zipCode);
    restProps.setFieldValue('zipName', zipName);
    restProps.setFieldValue('municipalityCode', municipalityCode);
    restProps.setFieldValue('municipalityName', municipalityName);
    restProps.setFieldValue('country', countryCode);
    restProps.setFieldValue('nin', nIN);

    setDrawer(drawerInitial);
  };

  /**
   * @description - Delete role type
   * @param data - Role type details
   * @param type - Debtor/Other party type
   */
  const deleteRoleType = (data: any, type: string) => {
    deleteRoleTypes({ roleTypeId: data?.value, type });
  };

  /**
   * @description - Role type table coloums
   * @param values - Form values
   * @returns - Coloum array
   */
  const roleTypeTableColums = (values: any) => {
    const roleTypeColumns: any = [
      {
        title: '',
        key: 'more',
        dataIndex: 'more',
        width: '50px',
        customRenderChild: (text: any, record: any, index: number) => {
          return (
            <$Popconfirm
              title={t('US.COLLECTION.CASE:OTHERPARTY.DELETECONFIRM')}
              onConfirm={() => {
                deleteRoleType(record, values?.otherpartyType);
              }}
              okText={t('US.COLLECTION.COMMON:COMMON.YES')}
              cancelText={t('US.COLLECTION.COMMON:COMMON.NO')}
            >
              <$Button icon={<DeleteOutlined />} size="small" danger />
            </$Popconfirm>
          );
        },
      },
      {
        title: t('US.COLLECTION.CASE:OTHERPARTY.ROLE_NAME'),
        dataIndex: 'label',
        key: 'label',
        className: 'text-nowrap',
        customSorter: (a: any, b: any) => a.localeCompare(b),
        customFilter: true,
      },
    ];
    return roleTypeColumns;
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    //
    return sortData(dataSource);
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    //
    return searchData(dataSource);
  };

  return (
    <Formik
      initialValues={{
        ...addDebtorInitial,
        isAddOtherParty: isAddOtherParty,
        otherpartyRoleType: isAddOtherParty ? null : 'Employer',
      }}
      enableReinitialize
      validationSchema={AddDebtorValidation}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <$Skeleton loading={isArDetailsFetching} active paragraph={{ rows: 2 }}>
          <$Form onSubmit={handleSubmit}>
            <$Collapse
              bordered={false}
              defaultActiveKey={['1', '2']}
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
            >
              {/* Basic information */}
              <$Collapse.Panel
                header={t('US.COLLECTION.CASE:OTHERPARTY.BASIC_INFORMATIONS')}
                key="1"
                data-testid="otherparty-new-basicInformation"
              >
                <div className="pl-1">
                  <$Row gutter={16} className="mb-1">
                    <$Col span={8} data-testid="new-debtor-type-label">
                      <$Select
                        formitem={{
                          label: t('US.COLLECTION.CASE:OTHERPARTY.TYPE'),
                        }}
                        name="otherpartyType"
                        allOption={false}
                        size="small"
                        onSelect={(e: any) =>
                          onChangeOtherPartyType(e, restProps)
                        }
                        required
                        value={values.otherpartyType}
                        style={{
                          width: '100%',
                        }}
                        options={[
                          {
                            label: t('US.COLLECTION.CASE:OTHERPARTY.PERSON'),
                            value: OtherPartyTypes.PERSON,
                          },
                          {
                            label: t('US.COLLECTION.CASE:OTHERPARTY.COMPANY'),
                            value: OtherPartyTypes.COMPANY,
                          },
                        ]}
                        data-testid="new-debtor-type"
                        onSearchBy={['label', 'value']}
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16} className="mb-1">
                    <$Col span={12} data-testid="new-debtor-firstName-input">
                      <a
                        onClick={() =>
                          setDrawer({
                            ...drawerInitial,
                            title: t('US.COLLECTION.CASE:OTHERPARTY.SEARCH'),
                            entityDrawer: true,
                          })
                        }
                        style={{
                          display: 'block',
                          marginBottom: '2px',
                        }}
                        data-testid="new-debtor-firstName"
                      >
                        <strong>
                          {t('US.COLLECTION.CASE:OTHERPARTY.FIRSTNAME')}
                        </strong>
                      </a>
                      <$Input name="firstName" size="small" />
                    </$Col>
                    <$Col span={12} data-testid="new-debtor-lastName">
                      <$Input
                        name="lastName"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.LASTNAME')}
                        size="small"
                        required
                        data-testid="new-debtor-last"
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    {isAddOtherParty && (
                      <$Col span={8}>
                        <div
                          style={{
                            marginBottom: '6px',
                          }}
                        >
                          <a
                            onClick={() =>
                              setDrawer({
                                ...drawerInitial,
                                title: t(
                                  'US.COLLECTION.CASE:OTHERPARTY.MANAGE_ROLE_TYPES'
                                ),
                                roleTypeDrawer: true,
                              })
                            }
                            style={{
                              display: 'block',
                              marginBottom: '2px',
                            }}
                            data-testid="otherparty-new-roleTypeView"
                          >
                            <span className="required">*</span>
                            <strong>
                              {t('US.COLLECTION.CASE:OTHERPARTY.ROLETYPE')}
                            </strong>
                          </a>
                        </div>
                        <$Select
                          required
                          formitem={{}}
                          style={{
                            width: '100%',
                          }}
                          placeholder=""
                          className="mr-0"
                          options={otherPartyRoles.data}
                          onSearchBy={['label', 'value']}
                          allOption={false}
                          name="otherpartyRoleType"
                          dropdownRender={(menu: any) => (
                            <div>
                              {menu}
                              <$Divider
                                style={{
                                  margin: '4px 0',
                                }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  padding: 8,
                                }}
                              >
                                <$Input
                                  name="newRole"
                                  style={{
                                    flex: 'auto',
                                  }}
                                  value={addRoleType}
                                  onChange={(e: any) =>
                                    setAddRoleType(e.target.value)
                                  }
                                  data-testid="otherparty-new-addRoleType"
                                />
                                <a
                                  style={{
                                    flex: 'none',
                                    padding: '8px',
                                    display: 'block',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleAddRoleType(values)}
                                >
                                  <PlusOutlined />
                                  {t('US.COLLECTION.CASE:OTHERPARTY.ADD')}
                                </a>
                              </div>
                            </div>
                          )}
                          data-testid="otherparty-new-otherpartyRoleType"
                        />
                      </$Col>
                    )}
                    <$Col
                      span={
                        values.otherpartyType === OtherPartyTypes.COMPANY
                          ? 16
                          : 8
                      }
                      data-testid="new-debtor-nin"
                    >
                      <$Input
                        name="nin"
                        onBlur={
                          //
                          values.otherpartyType === OtherPartyTypes.PERSON
                            ? (e: any) =>
                                handleBirthday(e.target.value, restProps)
                            : (e: any) =>
                                formatOrganizationNo(e.target.value, restProps)
                        }
                        label={t(
                          'US.COLLECTION.CASE:OTHERPARTY.NATIONAL_IDENTITY_NUMBER'
                        )}
                        size="small"
                        data-testid="otherparty-new-nin"
                      />
                    </$Col>

                    {values.otherpartyType === OtherPartyTypes.PERSON && (
                      <$Col span={8} data-testid="new-debtor-birthDate-label">
                        <$DatePicker
                          name="birthDate"
                          allowClear={true}
                          label={t('US.COLLECTION.CASE:OTHERPARTY.BIRTHDATE')}
                          defaultPickerValue={moment().add(-15, 'years')}
                          format={currentDateFormat}
                          placeholder={t(
                            'US.COLLECTION.CASE:OTHERPARTY.SELECT_DATE'
                          )}
                          value={
                            moment(values.birthDate).isValid()
                              ? moment(values.birthDate)
                              : null
                          }
                          style={{
                            width: '100%',
                          }}
                          size="small"
                          data-testid="new-debtor-birthDate"
                          disabledDate={(date: any) =>
                            !date || date > moment().startOf('day')
                          }
                        />
                      </$Col>
                    )}
                  </$Row>
                  <$Row gutter={16} className="mt-1">
                    <$Col span={6}>
                      <$Input
                        name="sms"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.SMS')}
                        defaultValue={values.sms}
                        size="small"
                        data-testid="testid=new-debtor-sms"
                      />
                    </$Col>
                    <$Col span={6}>
                      <$Input
                        name="mobile"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.MOBILE')}
                        defaultValue={values.mobile}
                        size="small"
                      />
                    </$Col>
                    <$Col span={6}>
                      <$Input
                        name="home"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.TELEPHONE')}
                        defaultValue={values.home}
                        size="small"
                        data-testid="new-debtor-telephone"
                      />
                    </$Col>
                    <$Col span={6}>
                      <$Input
                        name="work"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.WORK')}
                        defaultValue={values.work}
                        size="small"
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16} className="mb-4">
                    <$Col span={12} data-testid="new-debtor-email">
                      <$Input
                        name="emailAddress"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.EMAIL')}
                        size="small"
                        data-testid="otherparty-new-emailAddress"
                      />
                    </$Col>
                    {isAddOtherParty && (
                      <$Col span={12}>
                        <$Input
                          name="reference"
                          label={t('US.COLLECTION.CASE:OTHERPARTY.REFERENCE')}
                          size="small"
                          data-testid="otherparty-new-reference"
                          maxLength={20}
                        />
                      </$Col>
                    )}
                    {!isAddOtherParty && (
                      <$Col
                        span={12}
                        data-testid="new-debtor-creditor-reference"
                      >
                        <$Input
                          name="creditorReference"
                          required
                          label={t(
                            'US.COLLECTION.CASE:OTHERPARTY.CREDITOR_REFERENCE'
                          )}
                          size="small"
                          data-testid="otherparty-new-reference"
                          maxLength={20}
                        />
                      </$Col>
                    )}
                  </$Row>
                </div>
              </$Collapse.Panel>

              {/* Address information */}
              <$Collapse.Panel
                header={t('US.COLLECTION.CASE:OTHERPARTY.ADDRESS_INFORMATION')}
                key="2"
                data-testid="otherparty-new-addressInformation"
              >
                <div className="pl-1">
                  <$Row gutter={16} className="mb-1">
                    <$Col span={8} data-testid="new-debtor-country-label">
                      <$Popover
                        placement="rightTop"
                        content={
                          <div
                            style={{
                              maxWidth: '300px',
                            }}
                          >
                            <div className="ant-popover-message">
                              <ExclamationCircleFilled />
                              <div className="ant-popover-message-title">
                                <div
                                  style={{
                                    paddingBottom: '0.3rem',
                                  }}
                                >
                                  {t(
                                    'US.COLLECTION.CASE:OTHERPARTY.POSTAL_CODE_WARNING'
                                  )}
                                </div>
                                <div
                                  style={{
                                    paddingBottom: '0.5rem',
                                  }}
                                >
                                  <strong>
                                    {t(
                                      'US.COLLECTION.CASE:OTHERPARTY.CONTINUE_CONFIRM'
                                    )}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className="ant-popover-buttons">
                              <$Button
                                size="small"
                                onClick={() =>
                                  countryPopOverConfirmNo(restProps)
                                }
                                data-testid="otherparty-new-countryPopOverConfirmNo"
                              >
                                {t('US.COMMON:COMMON.NO')}
                              </$Button>
                              <$Button
                                size="small"
                                type="primary"
                                onClick={() =>
                                  countryPopOverConfirmYes(restProps)
                                }
                                data-testid="otherparty-new-countryPopOverConfirmYes"
                              >
                                {t('US.COMMON:COMMON.YES')}
                              </$Button>
                            </div>
                          </div>
                        }
                        trigger="click"
                        visible={popConfirmVisible}
                      />
                      <$SelectGroup
                        className="country-select"
                        required
                        options={_.orderBy(
                          europeanCountry,
                          ['name', 'label'],
                          ['asc', 'asc']
                        )}
                        formitem={{
                          label: t('US.COLLECTION.CASE:OTHERPARTY.COUNTRY'),
                        }}
                        style={{
                          width: '100%',
                        }}
                        value={values.country}
                        name="country"
                        optionFilterProp="children"
                        onChange={(data: any) =>
                          onChangeCountry(data, restProps, values)
                        }
                        filterOption={(input: any, option: any) =>
                          option.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={8} data-testid="new-debtor-address1">
                      <$Input
                        name="address1"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS1')}
                        size="small"
                        data-testid="otherparty-new-address1"
                      />
                    </$Col>
                    <$Col span={8} data-testid="new-debtor-address2">
                      <$Input
                        name="address2"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS2')}
                        size="small"
                        data-testid="otherparty-new-address2"
                      />
                    </$Col>
                    <$Col span={8} data-testid="new-debtor-address3">
                      <$Input
                        name="address3"
                        label={t('US.COLLECTION.CASE:OTHERPARTY.POSTADDRESS3')}
                        size="small"
                        data-testid="otherparty-new-address3"
                      />
                    </$Col>
                  </$Row>

                  <$Row gutter={16} className="mb-1">
                    <$Col span={6} data-testid="new-debtor-postalcode-select">
                      <a
                        className="d-block"
                        style={{
                          paddingBottom: '0.13rem',
                        }}
                        onClick={() =>
                          setDrawer({
                            ...drawerInitial,
                            title: t(
                              'US.COLLECTION.COMMON:NEWDEBTOR.SELECT_POSTAL_AREA'
                            ),
                            postalCodeDrawer: true,
                          })
                        }
                        data-testid="new-debtor-postalcode"
                      >
                        <strong>
                          {t('US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE')}
                        </strong>
                      </a>
                      <$AsyncInput
                        name="zipCode"
                        size="small"
                        isNumber={values.country === DEFAULT_COUNTRY_CODE}
                        onBlur={(data: any) =>
                          searchZipDetail(
                            data?.target?.value,
                            values,
                            restProps
                          )
                        }
                      />
                    </$Col>
                    <$Col span={6} data-testid="new-debtor-postalarea">
                      <$Skeleton
                        loading={postalcode?.isLoading}
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="zipName"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA'
                          )}
                          size="small"
                          disabled={values.isExistingPostalCode}
                        />
                      </$Skeleton>
                    </$Col>
                    <$Col span={6} data-testid="new-debtor-municipality-code">
                      <$Skeleton
                        loading={postalcode?.isLoading}
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="municipalityCode"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE'
                          )}
                          size="small"
                          onBlur={(data: any) =>
                            searchMunicipilityDetail(
                              data?.target?.value,
                              values,
                              restProps
                            )
                          }
                          disabled={values.isExistingPostalCode}
                        />
                      </$Skeleton>
                    </$Col>
                    <$Col span={6} data-testid="new-debtor-municipality-name">
                      <$Skeleton
                        loading={
                          postalcode?.isLoading || municipility?.isLoading
                        }
                        active
                        paragraph={{
                          rows: 0,
                        }}
                      >
                        <$Input
                          name="municipalityName"
                          label={t(
                            'US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME'
                          )}
                          size="small"
                          disabled={values.isExistingMunicipalityCode}
                        />
                      </$Skeleton>
                    </$Col>
                  </$Row>
                </div>
              </$Collapse.Panel>
            </$Collapse>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="mr-2"
                disabled={isSubmitting}
                onClick={handleSubmit}
                type="primary"
                data-testid="new-debtor-save"
              >
                {t('US.COLLECTION.COMMON:COMMON.SAVE')}
              </$Button>
              {restProps.dirty && (
                <$Popconfirm
                  title={t('US.COLLECTION.CASE:OTHERPARTY.CANCEL_ERROR')}
                  placement="topLeft"
                  onConfirm={() => onClose()}
                  okText={t('US.COLLECTION.COMMON:COMMON.YES')}
                  cancelText={t('US.COLLECTION.COMMON:COMMON.NO')}
                >
                  <$Button data-testid="otherparty-new-cancel">
                    {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                  </$Button>
                </$Popconfirm>
              )}
              {!restProps.dirty && (
                <$Button
                  onClick={() => onClose()}
                  data-testid="new-debtor-cancel"
                >
                  {t('US.COLLECTION.COMMON:COMMON.CANCEL')}
                </$Button>
              )}
            </div>
          </div>

          <$Drawer
            title={drawer.title}
            width={drawer.roleTypeDrawer ? 500 : 900}
            visible={
              drawer.roleTypeDrawer ||
              drawer.entityDrawer ||
              drawer.postalCodeDrawer
            }
            onClose={() => setDrawer(drawerInitial)}
            destroyOnClose
          >
            {drawer.roleTypeDrawer && (
              <$Skeleton
                loading={false}
                active
                paragraph={{
                  rows: 2,
                }}
              >
                <$TableTree
                  rowKey="value"
                  data={otherPartyRoles.data}
                  size="small"
                  className="mt-3"
                  onSort={handleSort}
                  onFilter={handleFilter}
                  filterOnType={true}
                  resetOnSourceChange={true}
                  bordered
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  scroll={{
                    x: 400,
                  }}
                  columns={roleTypeTableColums(values)}
                />
              </$Skeleton>
            )}
            {drawer.entityDrawer && (
              <EntitySearch
                setData={(entity: any) => setSearchData(entity, restProps)}
              />
            )}
            {drawer.postalCodeDrawer && (
              <PostalArea
                postalAreaClose={() => setDrawer(drawerInitial)}
                drawerContentChangeClick={(e: any) =>
                  handlePostalDoubleClick(e?.updatedInfo, restProps)
                }
                value={values.country}
                {...props}
              />
            )}
          </$Drawer>
        </$Skeleton>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { common, domainView, transaction, addDebtor } = state;
  const { currentDateFormat } = common;
  const { metaData } = domainView;
  const { arDetails, isArDetailsFetching } = transaction;
  const { addDebtorInitial, otherPartyRoles, postalcode, municipility } =
    addDebtor;

  return {
    currentDateFormat,
    metaData,
    postalcode,
    municipility,
    arDetails,
    isArDetailsFetching,
    addDebtorInitial,
    otherPartyRoles,
  };
};

const { addDebtorActions } = Actions;
const { postalCode, municipalityCode, otherPartyRoleTypes, otherPartyRole } =
  addDebtorActions;

const mapDispatchToProps = {
  searchPostalcode: postalCode.get,
  searchMunicipility: municipalityCode.get,
  getRoleType: otherPartyRoleTypes.get,
  deleteRoleTypes: otherPartyRole.delete,
  saveRole: otherPartyRoleTypes.save,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDebtor);
