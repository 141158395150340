import { Action } from "us.common/constants"

export function ExecuteAction(this: any, selectedEntities?: Array<string | number>) {
    const { action, activity } = this
    return {
        followupdataIds: selectedEntities,
        actionName: activity,
        noteText: action,
        removeAfterExecution: action === Action.DONE ? false : true,
    }
}