import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.common/actions";
import * as API from "us.common/services";
import { AddDebtor } from "us.common/constants";
import _ from "lodash";
import { $MessageBox } from "us.common/components";
const { addDebtorActions } = Actions;

const addDebtorSagas = {
  postalCode: {
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.AddDebtor.postaCodeSearch,
          params.requestObject
        );
        if (status === 200) {
          yield put(addDebtorActions.postalCode.success(data));
        } else {
          yield put(addDebtorActions.postalCode.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.postalCode.fail(new Error()));
      }
    },
  },
  municipalityCode: {
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.AddDebtor.municipalityCodeSearch,
          params.requestObject
        );
        if (status === 200) {
          yield put(addDebtorActions.municipalityCode.success(data));
        } else {
          yield put(addDebtorActions.municipalityCode.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.municipalityCode.fail(new Error()));
      }
    },
  },
  roleType: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.AddDebtor.getRoleTypes,
          params.otherPartyType
        );
        if (status === 200) {
          yield put(addDebtorActions.otherPartyRoleTypes.success(data));
        } else {
          yield put(addDebtorActions.otherPartyRoleTypes.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.otherPartyRoleTypes.fail(new Error()));
      }
    },
    delete: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.AddDebtor.deleteRoleType,
          params.roleTypeId
        );
        if (status === 200) {
          const { data_, status_ } = yield call(
            API.AddDebtor.getRoleTypes,
            params.type
          );
          yield put(addDebtorActions.otherPartyRoleTypes.success(data_));
        } else {
          yield put(addDebtorActions.otherPartyRole.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.otherPartyRole.fail(new Error()));
      }
    },
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(API.AddDebtor.saveRoleType, params);
        if (status === 201) {
          yield put(addDebtorActions.otherPartyRoleTypes.success(data));
        } else {
          yield put(addDebtorActions.otherPartyRoleTypes.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.otherPartyRoleTypes.fail(new Error()));
      }
    },
  },
  entity: {
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(API.AddDebtor.entitySearch, params);
        if (status === 200) {
          yield put(addDebtorActions.entity.success(data));
        } else {
          yield put(addDebtorActions.entity.fail(new Error()));
        }
      } catch (error) {
        yield put(addDebtorActions.entity.fail(new Error()));
      }
    },
  },
};
export default [
  takeLatest(
    AddDebtor.SEARCH_POSTAL_CODE_START,
    addDebtorSagas.postalCode.search
  ),
  takeLatest(
    AddDebtor.SEARCH_MUNICIPALITY_CODE_START,
    addDebtorSagas.municipalityCode.search
  ),
  takeLatest(
    AddDebtor.GET_OTHER_PARTY_ROLE_TYPE_STRAT,
    addDebtorSagas.roleType.get
  ),
  takeLatest(
    AddDebtor.DELETE_OTHER_PARTY_ROLE_TYPE_STRAT,
    addDebtorSagas.roleType.delete
  ),
  takeLatest(
    AddDebtor.SAVE_OTHER_PARTY_ROLE_TYPE_STRAT,
    addDebtorSagas.roleType.save
  ),
  takeLatest(AddDebtor.SEARCH_ENTITY_STRAT, addDebtorSagas.entity.search),
];
