import { IFollowUpActivity } from "us.collection.admin/interfaces";

/**
 * @function
 * @description - set activitiesList form form values
 * @param {any} values - formData
 * @param {function} setFieldValue - formik setFieldValue
 * @param {Array<any>} activities - all the activities
 * @returns void
 */
export const handleTableData = (
  values: any,
  setFieldValue: any,
  activities: Array<any>
): void => {
  try {
    const activitiesList = values?.activitiesList;

    const searchedActivity = activities.find(
      (activity: IFollowUpActivity) => activity?.activityId == values?.activity
    );
    setFieldValue("activitiesList", [
      ...activitiesList,
      { key: activitiesList.length + 1, ...searchedActivity },
    ]);
    setFieldValue("activity", "");
  } catch (error) {
    setFieldValue("activity", "");
  }
};

/**
 * @function
 * @description - delete activity from activities table
 * @param {Array<any>} activities - activities assigned to followup
 * @param {any} record - single record of activity table
 * @param {function} setFieldValue - formik setFieldValue
 * @returns void
 */
export const handleDeleteActivity = (
  activities: Array<any>,
  record: any,
  setFieldValue: any
): void => {
  try {
    setFieldValue(
      "activitiesList",
      activities.filter((activity: any) => activity.key != record.key)
    );
  } catch (error) {
    setFieldValue("activitiesList", activities);
  }
};

/**
 * @function
 * @description - generate activities list after select ones from the list
 * @param {any} values - formData
 * @param {Array<any>} activities - activities assigned to followup
 * @returns Array<any>
 */
export const generateActivities = (
  values: any,
  activities: Array<any>
): Array<any> => {
  try {
    return activities
      .filter((activity: any) => {
        const selectedActivityIds = values?.activitiesList.map(
          (selected: any) => selected?.activityId
        );
        return !selectedActivityIds.includes(activity?.activityId);
      })
      .map((activity: any) => ({
        ...activity,
        displayName: `${activity.activityCode} - ${activity.activityName}`,
      }));
  } catch (error) {
    return activities;
  }
};
