import React from "react";
import { Input, Form } from "antd";
import { useField } from "formik";
import { IInputAsyncParams } from "us.common/components/antd/Interfaces";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "us.icons";
import "./InputAsync.scss";

const NUMBER_TYPE = /^\d+$/;

const $I = React.forwardRef(
  (
    {
      name,
      id,
      label,
      placeholder,
      type,
      size,
      isValid = true,
      isNumber = false,
      loading = false,
      className,
      disabled,
      allowClear,
      required,
      suffix,
      prefix,
      showCount,
      maxLength,
      onKeyDown,
      onBlur,
      onChange,
      addonBefore,
      addonAfter,
      onPressEnter,
      max,
      min,
      style,
      tabIndex,
      dataTestid,
      autoFocus,
      asyncError,
      formitem,
      onClear,
    }: IInputAsyncParams,
    ref: any
  ) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const isTouched = meta?.touched;
    const error = meta?.error;

    return (
      <Form.Item
        label={label}
        help={!isValid ? asyncError : isTouched && error && t(error as string)}
        validateStatus={
          !isValid ? "error" : isTouched && error ? "error" : undefined
        }
        className="input-number-hide-arrows"
        required={typeof required == "undefined" ? false : true}
        extra={formitem?.extra}
      >
        <Input
          {...field}
          id={id}
          placeholder={placeholder}
          type={type}
          style={style}
          max={max}
          min={min}
          ref={ref}
          size={size}
          tabIndex={tabIndex}
          disabled={disabled}
          className={className}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          prefix={prefix}
          showCount={showCount}
          data-testid={dataTestid}
          maxLength={maxLength}
          allowClear={allowClear}
          autoFocus={autoFocus}
          suffix={
            typeof suffix === "undefined" ? (
              loading ? (
                <LoadingOutlined style={{ fontSize: 12 }} spin />
              ) : undefined
            ) : (
              suffix
            )
          }
          onKeyDown={onKeyDown}
          onPressEnter={onPressEnter}
          onBlur={(e: any) => {
            onBlur && onBlur(e);
            field.onBlur(e);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (
              isNumber &&
              e.target.value &&
              !NUMBER_TYPE?.test(e.target.value)
            ) {
              return;
            }
            typeof onChange == "function"
              ? onChange(e)
              : helpers?.setValue(e.target.value);
            typeof onClear == "function" && !e.target.value && onClear(e);
          }}
        />
      </Form.Item>
    );
  }
);

$I.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  asyncError: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isValid: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  onPressEnter: PropTypes.func,
  onClear: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $AsyncInput = $I;
