import axios, { AxiosResponse, AxiosPromise } from 'axios';
import axiosRetry from 'axios-retry';
import * as Azure from 'us.helper/azure';
import { RetryConfig } from '../retry-config';

const errorInterceptor = (error: any) => {
	switch (error?.response?.status) {
		case 400:
			break;
		case 401:
			break;

		case 404:
			break;

		default:
	}

	if (error?.response?.status === 500) {
		// If the error has status code 429, retry the request
		return axios.request(error.config);
	}
	return error.response;
};

const responseInterceptor = (response: AxiosResponse) => {
	switch (response.status) {
		case 200:
			break;
		case 204:
			break;
		default:
	}

	return response;
};

const axiosInstance = axios.create({
	baseURL: `https://${window._ENV.REACT_APP_COLLECTION_API_MANAGER}-${window._ENV.REACT_APP_COLLECTION_API_ENV}.${window._ENV.REACT_APP_COLLECTION_API_BASE_URL}`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': `${window._ENV.REACT_APP_AZURE_SUBSCRIPTION_KEY}`,
	},
	transformResponse: [
		function (data) {
			let response;
			try {
				response = data ? JSON.parse(data) : {};
			} catch (error) {
				throw Error(`${JSON.stringify(error)}`);
			}
			return response;
		},
	],
});
axiosRetry(axiosInstance, RetryConfig);

// Set the auth token for any request
axiosInstance.interceptors.request.use((config) => {
	let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
	let auth;
	if (isAzureAD) {
		auth = new Azure.ADAuth();
	} else {
		auth = new Azure.B2CAuth();
	}

	const token = auth.getToken();
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

// axiosRetry(axiosInstance, RetryConfig);

const AzureHttp = {
	post: function <D extends {} | Array<{}>>(
		service: string,
		endpoint: string,
		data: D
	): AxiosPromise {
		return axiosInstance.post(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}`,
			data
		);
	},
	put: function <D extends {} | Array<{}>>(
		service: string,
		endpoint: string,
		data: D
	): AxiosPromise {
		return axiosInstance.put(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}`,
			data
		);
	},
	get: function <D extends {} | Array<{}>>(
		service: string,
		endpoint: string,
		data: D
	): AxiosPromise {
		return axiosInstance.get(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}`,
			{
				params: data,
			}
		);
	},
	getById: function (
		service: string,
		endpoint: string,
		id: number | string
	): AxiosPromise {
		return axiosInstance.get(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}/${id}`
		);
	},
	delete: function (
		service: string,
		endpoint: string,
		id: number | string
	): AxiosPromise {
		return axiosInstance.delete(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}/${id}`
		);
	},
	deleteWithParams: function <D extends {} | Array<{}>>(
		service: string,
		endpoint: string,
		data: D
	): AxiosPromise {
		return axiosInstance.delete(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}`,
			{
				params: data,
			}
		);
	},
	patch: function <D extends {} | Array<{}>>(
		service: string,
		endpoint: string,
		data: D
	): AxiosPromise {
		return axiosInstance.patch(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}`,
			data
		);
	},
	patchById: function (
		service: string,
		endpoint: string,
		id: number | string
	): AxiosPromise {
		return axiosInstance.patch(
			`/${service}/${window._ENV.REACT_APP_COLLECTION_API_VERSION}/${endpoint}/${id}`
		);
	},
};

export default AzureHttp;
