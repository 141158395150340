export enum FeeBasisHistoryAction {
  REVERT = "REVERT",
}
export enum FeeBasisHistoryTableColumns {
  MENU = "menu",
  DATE = "fromDate",
  AMOUNT = "amount",
  DEBTOR_TYPE = "debtorType",
  MODIFIED = "modifiedDate",
}

export const COLUMNS = [
  {
    id: 0,
    key: FeeBasisHistoryTableColumns.MENU,
    title: "",
  },
  {
    id: 1,
    key: FeeBasisHistoryTableColumns.DATE,
    title: "US.COMMON:COMMON.DATE",
  },
  {
    id: 2,
    key: FeeBasisHistoryTableColumns.AMOUNT,
    title: "US.COMMON:COMMON.AMOUNT",
  },
  {
    id: 3,
    key: FeeBasisHistoryTableColumns.DEBTOR_TYPE,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.DEBTOR_TYPE",
  },
  {
    id: 4,
    key: FeeBasisHistoryTableColumns.MODIFIED,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.MODIFIED",
  },
];
