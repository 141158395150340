import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.USER_NAME_IS_REQUIRED"
  ),
  displayName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.DISPLAY_NAME_IS_REQUIRED"
  ),
  comment: Yup.string().when("isEdit", {
    is: (val) => val == true,
    then: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.COMMENT_IS_REQUIRED"
    ),
  }),
  roles: Yup.array()
    .of(
      Yup.object().shape({
        roleId: Yup.number(),
        isSelected: Yup.boolean(),
      })
    )
    .compact((v: any) => !v.isSelected)
    .min(
      1,
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_AT_LEAST_ONE_ROLE"
    ),
});
