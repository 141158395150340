import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IEntitySearch } from 'us.common/components/NewDebtor/Interfaces';
import {
	$Skeleton,
	$AutoComplete,
	$TableTree,
	$DateLabel,
} from 'us.common/components';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Input } from 'antd';
import moment from 'moment';
import { Criteria, DateFormats } from '../../Constants';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import * as Actions from 'us.common/actions';
import { getEntitySearchOptionList } from '../../Functions';
import { Entity } from 'us.common/repository';

export const invalidDate = '0001-01-01T00:00:00';

/**
 * @description - Component for Entity search
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 02/05/2022
 * */

const EntitySearch: React.FC<IEntitySearch> = (props) => {
	const { t } = useTranslation();

	const [options, setOptions] = useState<any>([]);
	const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

	const { searchData, search, setData, currentDateFormat, reset } = props;

	useEffect(() => {
		reset({});
	}, []);

	const columns: any = [
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NAME'),
			dataIndex: 'name',
			key: 'name',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.NIN'),
			dataIndex: 'nIN',
			key: 'nIN',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a - b,
			customFilter: true,
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.BIRTH_DATE'),
			dataIndex: 'birthDate',
			key: 'birthDate',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				const { birthDate } = record;
				return (
					birthDate != invalidDate &&
					!moment
						.utc(birthDate)
						.isSame(
							moment.utc('1900-01-01')
						) && (
						<$DateLabel value={birthDate} />
					)
				);
			},
		},
		{
			title: t('US.COLLECTION.CASE:OTHERPARTY.ADDRESS'),
			dataIndex: 'address',
			key: 'address',
			className: 'text-nowrap',
			customSorter: (a: any, b: any) => a.localeCompare(b),
			customFilter: true,
		},
	];

	/**
	 * Search option list
	 */
	const mainCategories = [
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.NAME'
			).toString()}: `,
			value: `${Criteria.NAME}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.NIN'
			).toString()}: `,
			value: `${Criteria.NIN}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.BIRTH_DATE'
			).toString()}: `,
			value: `${Criteria.BIRTH_DATE}:`,
		},
		{
			label: `${t(
				'US.COLLECTION.CASE:OTHERPARTY.ADDRESS'
			).toString()}: `,
			value: `${Criteria.ADDRESS}:`,
		},
	];

	/**
	 * @description - Handle search option list change
	 * @param searchValue - Search text
	 */
	const onSearchTextChange = (searchValue: any) => {
		setDropDownOpen(true);
		let value: any = searchValue;
		if (searchValue.includes(':')) {
			const [searchCriteria, text] = searchValue.split(':');
			value = text;
		}
		setOptions(getEntitySearchOptionList(mainCategories, value));
	};

	/**
	 * Drop down visible handle
	 */
	const onDropdownVisibleChange = () => {
		if (dropDownOpen) {
			setDropDownOpen(false);
		}
	};

	/**
	 * @description - Format date
	 * @param date - Selected date
	 * @returns - Formated date
	 */
	const dateSetup = (date: string) => {
		if (moment(date, currentDateFormat, true).isValid()) {
			return moment(date, currentDateFormat).format(
				DateFormats.REQ
			);
		} else {
			return date;
		}
	};

	/**
	 * @description - Search entity
	 * @param searchValue - Search value
	 */
	const handleSearchData = (searchValue: any) => {
		setDropDownOpen(!dropDownOpen);
		if (searchValue.includes(':')) {
			if (searchValue.split(':').length === 2) {
				let [searchCriteria, text] =
					searchValue.split(':');
				text =
					searchCriteria === Criteria.BIRTH_DATE
						? dateSetup(text)
						: text?.trim();
				search(
					Entity.call({
						props,
						searchCriteria,
						text,
					})
				);
			} else {
				setOptions([]);
			}
		}
	};

	/**
	 * Handle pagination
	 */
	const handlePagination = () => {
		var entityList = document.getElementById('searchData');
		entityList?.scrollIntoView();
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		return sortData(dataSource);
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		return searchData(dataSource);
	};

	return (
		<Formik
			initialValues={{}}
			onSubmit={(values: any, actions: any) => {}}>
			<div id='searchData'>
				<div
					className='mb-3'
					id='autocompleteid'
					data-testid='entity-search'>
					<$AutoComplete
						name='search'
						placeholder={t(
							'US.COLLECTION.CASE:OTHERPARTY.SEARCH'
						)}
						size='small'
						options={options}
						open={dropDownOpen}
						onSearch={(
							searchValue: string
						) =>
							onSearchTextChange(
								searchValue
							)
						}
						onDropdownVisibleChange={
							onDropdownVisibleChange
						}
						onSelect={(
							inputValue: string
						) =>
							handleSearchData(
								inputValue
							)
						}>
						<Input.Search
							onSearch={(
								inputValue: string
							) =>
								handleSearchData(
									inputValue
								)
							}
						/>
					</$AutoComplete>
				</div>

				<$Skeleton
					loading={searchData?.isLoading}
					active
					paragraph={{ rows: 2 }}>
					<$TableTree
						rowKey='arItemNo'
						data={searchData?.data}
						size='small'
						className=''
						onSort={handleSort}
						onFilter={handleFilter}
						filterOnType={true}
						resetOnSourceChange={true}
						bordered
						pagination={{
							defaultPageSize: 15,
							onChange: () =>
								handlePagination(),
						}}
						scroll={{ x: 800 }}
						columns={columns}
						onRow={(
							record: any,
							rowIndex: any
						) => {
							return {
								onDoubleClick: (
									event: any
								) => {
									setData(
										record
									);
								},
							};
						}}
						firstColSkipFilterProps={-1}
						data-testid='entity-table'
					/>
				</$Skeleton>
			</div>
		</Formik>
	);
};

const { addDebtorActions } = Actions;
const { entity } = addDebtorActions;

const mapStateToProps = (state: any) => {
	const { common, domainView, addDebtor } = state;
	const { currentDateFormat, currentCurrency, currentLanguage } = common;
	const { metaData } = domainView;
	const { searchEntity } = addDebtor;

	return {
		currentDateFormat,
		currentCurrency,
		currentLanguage,
		metaData,
		searchData: searchEntity,
	};
};
const mapDispatchToProps = {
	search: entity.search,
	reset: entity.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitySearch);
