export enum Role {
    GET_ROLES_START = "GET_ROLES_START",
    GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
    GET_ROLES_FAIL = "GET_ROLES_FAIL",

    MANAGE_ROLE_DRAWER = "MANAGE_ROLE_DRAWER",
    HANDLE_DRAWER_ROLE_START = "HANDLE_DRAWER_ROLE_START",

    ADD_NEW_ROLE_START = "ADD_NEW_ROLE_START",
    ADD_NEW_ROLE_SUCCESS = "ADD_NEW_ROLE_SUCCESS",
    ADD_NEW_ROLE_FAIL = "ADD_NEW_ROLE_FAIL",

    UPDATE_ROLE_START = "UPDATE_ROLE_START",
    UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL",

    EDIT_ROLE_START = "EDIT_ROLE_START",
    EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS",
    EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL",

    ADD_ROLE_START = "ADD_ROLE_START",
    ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS",
    ADD_ROLE_FAIL = "ADD_ROLE_FAIL",

    GET_ROLE_BY_ID_START = "GET_ROLE_BY_ID_START",
    GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS",
    GET_ROLE_BY_ID_FAIL = "GET_ROLE_BY_ID_FAIL",

    GET_ROLE_MODULES_AND_FEATURES_START = "GET_ROLE_MODULES_AND_FEATURES_START",
    GET_ROLE_MODULES_AND_FEATURES_SUCCESS = "GET_ROLE_MODULES_AND_FEATURES_SUCCESS",
    GET_ROLE_MODULES_AND_FEATURES_FAIL = "GET_ROLE_MODULES_AND_FEATURES_FAIL",

    DELETE_ROLE_START = "DELETE_ROLE_START",
    DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL",

    RESET_SELECTED_ROLE_START = "RESET_SELECTED_ROLE_START",
    RESET_SELECTED_ROLE_SUCCESS = "RESET_SELECTED_ROLE_SUCCESS",
    RESET_SELECTED_ROLE_FAIL = "RESET_SELECTED_ROLE_FAIL",

    GET_ROLE_HISTORY_BY_ID_START = "GET_ROLE_HISTORY_BY_ID_START",
    GET_ROLE_HISTORY_BY_ID_SUCCESS = "GET_ROLE_HISTORY_BY_ID_SUCCESS",
    GET_ROLE_HISTORY_BY_ID_FAIL = "GET_ROLE_HISTORY_BY_ID_FAIL",

    ROLE_STATE_CHANGE_START = "ROLE_STATE_CHANGE_START",
    ROLE_STATE_CHANGE_SUCCESS = "ROLE_STATE_CHANGE_SUCCESS",
    ROLE_STATE_CHANGE_FAIL = "ROLE_STATE_CHANGE_FAIL",

    ADD_ROLE_HISTORY_START = "ADD_ROLE_HISTORY_START",
    ADD_ROLE_HISTORY_SUCCESS = "ADD_ROLE_HISTORY_SUCCESS",
    ADD_ROLE_HISTORY_FAIL = "ADD_ROLE_HISTORY_FAIL",




}