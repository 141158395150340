import { put, takeLatest, call, all } from "redux-saga/effects";
import { PaymentAgreement } from "us.collection/constants";
import * as API from "us.collection/services";
import * as Actions from "us.collection/actions";
import _ from "lodash";
import { $MessageBox } from "us.common/components";
import { appInsights } from "us.helper";
const { paymentAgreement } = Actions;

const agreement = {
  interest: {
    get: function* (action: any): any {
      const params = action.payload?.data;
      try {
        const data = yield call(
          API.default.PaymentAgreement.getInterests,
          params
        );
        if (!data.hasOwnProperty("errors")) {
          if (!data?.isError) {
            yield put(paymentAgreement.interests.success(data));
          } else {
            yield put(paymentAgreement.interests.fail(data));
          }
        }
      } catch (error) {
        appInsights.trackException(
          `Get agreement interest Saga Exception - ${error}`
        );
        yield put(paymentAgreement.interests.fail({}));
      }
    },
  },
  agreement: {
    save: function* (action: any): any {
      const params = action.payload?.data;
      let temParams = {
        entityType: params?.caseType,
        caseNo: params?.caseNo,
      };
      try {
        const data = yield call(
          API.default.PaymentAgreement.saveAgreement,
          params
        );
        if (data?.result?.agreementId > 0) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:MANAGEAGREEMENTS.SUCCESSFULLY_ADDED`,
            "",
            ""
          );
          const [amounts, details] = yield all([
            call(
              API.default.PaymentAgreement.getAgreementAmounts,
              params?.caseNo
            ),
            call(API.default.PaymentAgreement.getAgreementDetail, temParams),
          ]);
          yield put(
            paymentAgreement.amountsAndDetails.success({ amounts, details })
          );
        }
      } catch (error) {
        appInsights.trackException(
          `Save agreement interest Saga Exception - ${error}`
        );
        yield put(paymentAgreement.amountsAndDetails.fail(error as any));
      }
    },
    delete: function* (action: any): any {
      const params = action.payload?.data;
      try {
        const data = yield call(
          API.default.PaymentAgreement.deleteAgreement,
          params?.id
        );
        if (!data?.isError) {
          $MessageBox(
            "success",
            `US.COLLECTION.CASE:MANAGEAGREEMENTS.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
          const [amounts, details] = yield all([
            call(
              API.default.PaymentAgreement.getAgreementAmounts,
              params?.caseNo
            ),
            call(API.default.PaymentAgreement.getAgreementDetail, params),
          ]);
          yield put(
            paymentAgreement.amountsAndDetails.success({ amounts, details })
          );
        }
      } catch (error) {
        appInsights.trackException(
          `Delete agreement interest Saga Exception - ${error}`
        );
        yield put(paymentAgreement.amountsAndDetails.fail({}));
      }
    },
    amountsAndDetails: function* (action: any): any {
      const params = action.payload?.data;
      try {
        const [amounts, details] = yield all([
          call(
            API.default.PaymentAgreement.getAgreementAmounts,
            params?.caseNo
          ),
          call(API.default.PaymentAgreement.getAgreementDetail, params),
        ]);
        yield put(
          paymentAgreement.amountsAndDetails.success({ amounts, details })
        );
      } catch (error) {
        appInsights.trackException(
          `Get agreement amount and details Saga Exception - ${error}`
        );
        yield put(paymentAgreement.amountsAndDetails.fail({}));
      }
    },
  },
};

export default [
  takeLatest(
    PaymentAgreement.SAVE_PAYMENT_AGREEMENT_START,
    agreement.agreement.save
  ),
  takeLatest(
    PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_START,
    agreement.interest.get
  ),
  takeLatest(
    PaymentAgreement.DELETE_PAYMENT_AGREEMENT_START,
    agreement.agreement.delete
  ),
  takeLatest(
    PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START,
    agreement.agreement.amountsAndDetails
  ),
];
