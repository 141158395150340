import React from "react";
import { IDynamicComponent } from "../Interfaces";
import { DynamicComponent } from "us.common/constants";
import { $Col } from "us.common/components";
import { Elements } from "./Components";

/**
 * @description - Dynamic component Base.
 * @author Darshana Wasala , Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 21/03/2022
 */
export const $DynamicComponent = ({
  component,
  label,
  name,
  withColumns = false,
  required,
  children,
  options = [],
  value,
  visible = true,
  ...rest
}: IDynamicComponent) => {
  // Get span size for dynamic component
  const getSpanSize = () => {
    switch (component) {
      case DynamicComponent.TextArea:
        return 24;
      case DynamicComponent.Skeliton:
        return 0;
      default:
        return "";
    }
  };

  return (
    <>
      {visible && withColumns && getSpanSize() !== 0 && (
        <$Col span={getSpanSize()}>
          <Elements
            component={component}
            label={label}
            name={name}
            withColumns={withColumns}
            required={required}
            options={options}
            value={value}
            restProps={rest}
          />
        </$Col>
      )}
      {visible && !withColumns && (
        <Elements
          component={component}
          label={label}
          name={name}
          withColumns={withColumns}
          required={required}
          options={options}
          value={value}
          restProps={rest}
        />
      )}
    </>
  );
};
