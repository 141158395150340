import { httpCollection } from "us.helper/http/collection";

export default {
  courtInfo: {
    initCourtInfo: (params: string | number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const courtTypesData = await httpCollection.get(
            "entityservice",
            "courtTypes",
            {}
          );
          const courtInfo = await httpCollection.getById(
            "entityservice",
            "courtitems",
            params
          );

          resolve({
            courtInfo,
            courtTypesData,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCourtTypes: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "entityservice",
            "courtTypes",
            {}
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getRelevantPostalCodes: (params: string | number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const postalData = await httpCollection.get(
            "entityservice",
            `courtitems/${params}/postalcodes`,
            {}
          );
          resolve(postalData);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCourtItems: (params: string | number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.getById(
            "entityservice",
            "courtitems",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteCourtItem: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let courtData = {};
          const { courtItemId, selectedCourtType } = params;
          const data = await httpCollection.patchById(
            "entityservice",
            "courtitems",
            courtItemId
          );
          if (data.status === 200) {
            courtData = await httpCollection.getById(
              "entityservice",
              "courtitems",
              selectedCourtType
            );
          }
          resolve({ data, courtData });
        } catch (error) {
          reject(error);
        }
      });
    },
    addCourtType: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let courtTypeData = {};
          const data = await httpCollection.post(
            "entityservice",
            "courttypes",
            params
          );
          if (data.status === 201 || data.status === 200) {
            courtTypeData = await httpCollection.get(
              "entityservice",
              "courtTypes",
              {}
            );
          }
          resolve({ data, courtTypeData });
        } catch (error) {
          reject(error);
        }
      });
    },
    editCourtType: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let courtTypeData = {};
          const data = await httpCollection.put(
            "entityservice",
            "courttypes",
            params
          );
          if (data.status === 201 || data.status === 200) {
            courtTypeData = await httpCollection.get(
              "entityservice",
              "courtTypes",
              {}
            );
          }
          resolve({ data, courtTypeData });
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteCourtType: (courtItemId: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let typesData = {}
          const data = await httpCollection.patchById(
            "entityservice",
            "courttypes",
            courtItemId
          );
          if (data.status === 201 || data.status === 200) {
            typesData = await httpCollection.get(
              "entityservice",
              "courtTypes",
              {}
            );
          }
          resolve({ data, typesData });
        } catch (error) {
          reject(error);
        }
      });
    },
    addCourtItem: (params: any): Promise<any> => {
      const { payload, selectedCourtType } = params;
      return new Promise<{}>(async (resolve, reject) => {
        try {
          let courtData = {};
          const data = await httpCollection.post(
            "entityservice",
            "courtitems",
            payload
          );
          if (data.status === 201 || data?.status == 200) {
            courtData = await httpCollection.getById(
              "entityservice",
              "courtitems",
              selectedCourtType
            );
          }
          resolve({ data, courtData });
        } catch (error) {
          reject(error);
        }
      });
    },

  },
};
