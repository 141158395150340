import {
  FilterOptions,
  SearchByOptions,
} from "us.common/components/SelectCreditors/Constants/Constants";
import _ from "lodash";

/**
 * @method
 * @description This method is used to render autoComplete component selection.
 * @param {string} searchValue - Input value
 * @param {any} setValues - Formik set value
 */
export const handleAutoCompleteChange = (
  searchValue: string,
  setValues: any
): void => {
  setValues((preValues: any) => {
    return {
      ...preValues,
      searchOptions: SearchByOptions?.map((x: any) => {
        return {
          label: `${x.label}${getSearchValue(searchValue)}`,
          value: `${x.value}${getSearchValue(searchValue)}`,
        };
      }),
    };
  });
};

/**
 * @method
 * @description This function is used to filter creditors by selection type
 * @param {any} filterValue - filter by value
 * @param {Array} creditors - Array of creditors
 * @param {Array} selectedCreditors - Array of selected creditors
 * @returns {Array} of filtered creditors
 */
export const filterCreditors = (
  filterValue: any,
  creditors: any[],
  selectedCreditors: any[]
): Array<any> => {
  try {
    return creditors.filter((creditor: any) => {
      if (filterValue == FilterOptions.SELECTED) {
        return selectedCreditors?.includes(creditor?.creditorId);
      } else if (filterValue == FilterOptions.NOT_SELECTED) {
        return !selectedCreditors?.includes(creditor?.creditorId);
      } else {
        return true;
      }
    });
  } catch (e) {
    return [];
  }
};

/**
 * @method
 * @description Used to return formatted search input value
 * @param {string} searchValue - Input value
 * @returns {string} Formatted value
 */
export const getSearchValue = (searchValue: string): string => {
  try {
    return searchValue?.substring(searchValue?.indexOf(":") + 1)?.trim();
  } catch (e) {
    return "";
  }
};

/**
 * @method
 * @description Used to return creditor ids
 * @param {Array} creditors - Array of creditors
 * @param {Array} disabledKeys -  disabled creditors
 * @param {string} disabledPropName - Name of the property to disable the creditors
 * @returns {Array} Array of creditor ids
 */
 export const getCreditorIds = (
  creditors: any[],
  disabledKeys?: Array<number>,
  disabledPropName?: string
): Array<any> => {
  try {
    if (Array.isArray(creditors)) {
      if (Array.isArray(disabledKeys) && disabledPropName) {
        return creditors.flatMap((creditor: any) =>
          !disabledKeys.includes(Number(creditor[disabledPropName]))
            ? creditor.creditorId
            : []
        );
      } else {
        return creditors.flatMap(
          ({ creditorId }: { creditorId: number }) => creditorId
        );
      }
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

/**
 * @method
 * @description Set initial state for creditors.
 * @param payload - Action payload.
 * @param {Array} creditors - Array of creditors.
 * @param {Array} selectedCreditors - Array of selected creditors.
 * @returns {object} Initial state object for creditors.
 */
 export const setCreditorState = (
  payload: any,
  creditors: Array<number>,
  selectedCreditors: Array<number>
): {
  selected: Array<any>;
  data: Array<any>;
} => {
  try {
    const { isAllGroupSelected, groupId, searchText, isClear } = payload ?? {};

    if (isAllGroupSelected) {
      return {
        selected: [],
        data: [],
      };
    } else if (groupId?.includes(-1)) {
      return {
        selected: selectedCreditors,
        data: [],
      };
    } else if (searchText || isClear) {
      return {
        selected: selectedCreditors,
        data: [],
      };
    } else {
      return {
        selected: selectedCreditors,
        data: creditors,
      };
    }
  } catch (e) {
    return {
      selected: [],
      data: [],
    };
  }
};

/**
 * @method
 * @description Returns merged and sorted array of creditors.
 * @param {Array} creditors - Array of existing creditors.
 * @param {Array} selectedCreditors - Array of existing selected creditors.
 * @param {Array} creditorsPayload - Creditors from API response.
 * @param {Array} initSelectedKeys - Selected creditors.
 * @returns {object} Object of formatted state.
 */
 export const getCreditors = (
  creditors: Array<any>,
  selectedCreditors: Array<string> = [],
  creditorsPayload?: Array<any>,
  initSelectedKeys?: Array<any>
): {
  data: Array<any>;
  selected: Array<string>;
} => {
  try {
    // merge all creditors into a single array
    const mergedData: any[] = Array.isArray(creditorsPayload)
      ? [...creditors, ...creditorsPayload]
      : creditors;

    // sort creditors by creditor no
    const sortedCreditorsByCreditorNo: any[] = mergedData.sort(function (
      a: any,
      b: any
    ) {
      return parseInt(a.pid) - parseInt(b.pid);
    });

    return {
      data: sortedCreditorsByCreditorNo,
      selected: Array.isArray(initSelectedKeys)
        ? initSelectedKeys.includes(-1)
          ? [...getCreditorIds(mergedData, [])]
          : [...initSelectedKeys]
        : [...selectedCreditors],
    };
  } catch (e) {
    return { data: [], selected: [] };
  }
};

/**
 * @method
 * @description Remove creditors from redux state.
 * @param {Array} creditors - Array of existing creditors.
 * @param {Array} selectedCreditors - Array of selected creditors.
 * @param {Array} selectedGroupId - Group id that needs to remove.
 * @returns {object} Object of creditors and selected creditors.
 */
 export const removeLocalCreditors = (
  creditors: Array<any>,
  selectedCreditors: Array<string>,
  selectedGroupId: number
): {
  data: Array<any>;
  selected: Array<string>;
} => {
  try {
    const restCreditors = creditors?.filter(
      ({ groupId }: any) => groupId !== selectedGroupId
    );
    if (selectedGroupId !== 0) {
      return {
        data: restCreditors,
        selected: selectedCreditors?.filter((creditorId: any) =>
          getCreditorIds(restCreditors, [])?.includes(creditorId)
        ),
      };
    } else {
      return {
        data: [],
        selected: [],
      };
    }
  } catch (e) {
    return { data: [], selected: [] };
  }
};

/**
 * @method
 * @description Used to return selected creditor.
 * @param {Array} creditors - Array of creditors.
 * @param {Array} selectedKeys -  selected creditor ids.
 * @returns {Array} Array of selected creditor.
 */
 export const getSelectedCreditors = (
  creditors: any[],
  selectedKeys: Array<number>
): Array<any> => {
  try {
    return Array.isArray(creditors)
      ? creditors.filter(({ creditorId }: { creditorId: number }) =>
          selectedKeys.includes(creditorId)
        )
      : [];
  } catch (e) {
    return [];
  }
};

/**
 * @method
 * @description Return selected group ids when all group is selected.
 * @param {Array} selectedCreditors - Array of selected creditors with data.
 * @param {Array} creditorGroup -  selected group ids.
 * @param {Array} creditorIds -  all creditor ids.
 * @returns {Array} Array of selected group ids.
 */
export const findAvailableGroups = (
  selectedCreditors: any[],
  creditorGroup: Array<string | number>,
  creditorIds: Array<number>
): Array<string | number> => {
  try {
    if (
      creditorGroup?.includes("0") &&
      selectedCreditors?.length === creditorIds.length
    ) {
      return ["0"];
    } else {
      return Array.isArray(selectedCreditors)
        ? _.uniqBy(selectedCreditors, "groupId")?.map(
            ({ groupId }: any) => groupId
          )
        : creditorGroup;
    }
  } catch (e) {
    return creditorGroup;
  }
};

/**
 * @method
 * @description Returns all group selection status.
 * @param {object} values - Form values.
 * @returns {boolean} All group or not.
 */
export const isAllGroup = (values: any): boolean => {
  try {
    return (
      values.creditorGroup?.includes("0") ||
      typeof values.creditorGroup === "undefined" ||
      !values.creditorGroup?.length
    );
  } catch (e) {
    return false;
  }
};
