import React from 'react';
import * as Components from 'antd';
import * as Utility from '../utility';
import { useField, Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

export default ({
  value,
  name,
  options,
  optionValue,
  optionText,
  optionContent,
  radioButton,
  optionStyle,
  onClick,
  buttonStyle,
  noStyle,
  onChange,
  ...rest
}: any) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();

  return (
    <>
      <Components.Form.Item
        label={rest?.formitem?.label}
        style={rest?.formitem?.style}
        help={isTouched && error && t(error as string)}
        noStyle={noStyle}
        validateStatus={Utility.validateStatus(error, isTouched)}
        required={typeof rest.required == 'undefined' ? false : true}
      >
        <Field
          name={name}
          children={() => (
            <Components.Radio.Group
              {...rest}
              name={name}
              value={typeof value !== 'undefined' ? value : field.value}
              buttonStyle={buttonStyle}
              onChange={(e) => {
                helpers.setValue(e.target.value);
                onChange && onChange(e);
              }}
            >
              {options?.map((item: any, index: number) => {
                return typeof radioButton == 'undefined' ? (
                  <Components.Radio
                    disabled={item?.disabled}
                    key={index}
                    value={item?.[optionValue]}
                    className={optionStyle}
                    onClick={
                      typeof onClick !== 'function' ? null : onClick(item)
                    }
                    data-testid={item?.dataTestId}
                  >
                    {typeof optionContent === 'function' &&
                      optionContent(item, index)}
                    {typeof optionContent !== 'function' && item?.[optionText]}
                  </Components.Radio>
                ) : (
                  <Components.Radio.Button
                    disabled={item?.disabled}
                    key={index}
                    value={item?.[optionValue]}
                    className={optionStyle}
                    onClick={
                      typeof onClick !== 'function' ? null : onClick(item)
                    }
                    data-testid={item?.dataTestId}
                  >
                    {typeof optionContent === 'function' &&
                      optionContent(item, index)}
                    {typeof optionContent !== 'function' && item?.[optionText]}
                  </Components.Radio.Button>
                );
              })}
            </Components.Radio.Group>
          )}
        />
      </Components.Form.Item>
    </>
  );
};
