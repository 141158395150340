import { IHandler, IHandlerData, IHandlerDataModel } from "../Interfaces";

const Handler: IHandler = {
  name: "Not Assigned",
  list: [],
};

const HandlerData: IHandlerData = {
  case: Handler,
  court: Handler,
};

export const HandlerDataModel: IHandlerDataModel = {
  data: HandlerData,
  isLoading: false,
  isError: false,
};
