import { DropDown } from "../Interfaces";

 export enum ActivityVersion {
    V1 ="V1",
    V2="V2"
  }
  
  
 export enum ActivityVersionLabels {
  V1 ="USC 2.0",
  V2="USC 4.0"
 }

export const ACTIVITY_VERSION_DROP_DOWN_V2: DropDown<ActivityVersion>[] = [
    {
      label: ActivityVersionLabels.V1,
      value: ActivityVersion.V1,
    },
    {
      label: ActivityVersionLabels.V2,
      value: ActivityVersion.V2,
    }
  ];
export const ACTIVITY_VERSION_DROP_DOWN_V1: DropDown<ActivityVersion>[] = [
    {
      label: ActivityVersionLabels.V1,
      value: ActivityVersion.V1,
    }
  ];