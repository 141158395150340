export enum ColumnNames {
  ARTICLE_NO = "articleNo",
  ARTICLE_NAME = "articleName",
  CASE_NO = "caseNo",
  QUANTITY = "quantity",
  UNIT_PRICE = "unitPrice",
  VAT = "vatAmount",
  TOTAL = "total",
  DESCRIPTION = "description",
  REDUCED_PRICED = "reducedPriced",
  CREDITING_AMOUNT = "creditedAmount",
}

export enum CreditOptions {
  ENTIRE_INVOICE_AMOUNT = "1",
  ENTIRE_INVOICE_BALANCE = "2",
  EACH_ARTICLES = "3",
}

export const DefaultColumns = [
  ColumnNames.ARTICLE_NO,
  ColumnNames.ARTICLE_NAME,
  ColumnNames.CASE_NO,
  ColumnNames.QUANTITY,
  ColumnNames.UNIT_PRICE,
  ColumnNames.VAT,
  ColumnNames.TOTAL,
  ColumnNames.DESCRIPTION,
  ColumnNames.CREDITING_AMOUNT,
];
