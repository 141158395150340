import React from "react";
import * as Components from "antd";
import * as Utility from "../utility";
import { useField, Field } from "formik";
import { useTranslation } from "react-i18next";

export default ({
  value,
  setFieldValue,
  name,
  errors,
  formitem,
  formitemlabel,
  onChange,
  disabled,
  checked,
  touched,
  className,
  size,
  style,
  checkedChildren,
  unCheckedChildren,
  defaultChecked,
  ...restProps
}: any) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();

  return (
    <>
      {restProps.hasOwnProperty("formitem") && (
        <Components.Form.Item
          label={formitemlabel}
          help={isTouched && error && t(error as string)}
          validateStatus={Utility.validateStatus(error, isTouched)}
        >
          <Field
            as={Components.Switch}
            disabled={disabled}
            checked={checked}
            className={className}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            defaultChecked={defaultChecked}
            style={style}
            size={restProps.hasOwnProperty("size") ? size : "small"}
            {...field}
            onChange={
              typeof onChange == "function"
                ? onChange
                : (v: any) => helpers.setValue(v)
            }
          />
        </Components.Form.Item>
      )}

      {
        <Field
          as={Components.Switch}
          disabled={disabled}
          checked={checked}
          className={className}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          defaultChecked={defaultChecked}
          size={restProps.hasOwnProperty("size") ? size : "small"}
          {...field}
          style={style}
          onChange={
            typeof onChange == "function"
              ? onChange
              : (v: any) => helpers.setValue(v)
          }
        />
      }
    </>
  );
};
