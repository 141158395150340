import {
    ICreditor,
    ICreditorCompany,
    ICreditorGroup,
} from "us.common/interfaces";

export const getDataIds = (
    data: Array<ICreditor | ICreditorGroup | ICreditorCompany>
): Array<number | string> => {
    try {
        return data.map(
            ({ id }: ICreditor | ICreditorGroup | ICreditorCompany) => id
        );
    } catch (error) {
        return [];
    }
};
