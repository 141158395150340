import React from "react";
import { useTranslation } from "react-i18next";
import { $Drawer } from "us.common/components/antd";
import { ISelectCreditors } from "../../Interfaces";

export const SelectionHOC: React.FC<Partial<ISelectCreditors>> = (props) => {
  const { t } = useTranslation();
  const { children, withDrawer, drawerProps, title, width, visible, onClose } = props;
  if (withDrawer) {
    return (
      <$Drawer
        {...drawerProps}
        title={!title ? t("US.COLLECTION.COMMON:COMMON.SELECT_CREDITOR") : title}
        width={width}
        visible={visible}
        onClose={onClose}
        destroyOnClose={true}
        maskClosable={false}
      >
        {children}
      </$Drawer>
    );
  } else {
    return <>{children}</>;
  }
};
