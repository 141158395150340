import { i18n } from "us.helper";

/**
 * @function
 * @description - Handles API translation key
 * @param key - translation key from the API response
 * @returns translated string
 */

export const handleEndPointLocals = (key:string):string => {
    try{
        return i18n.t(key)
    }catch(e){
        return ""
    }
};
