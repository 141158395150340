import { StepTypes, EntityType } from "us.collection.admin/constants";
import { IActivityState, ISPParameter } from "us.collection.admin/interfaces";
import {
  setActivitySetUpRequest,
  setStateForRequest,
} from "us.collection.admin/functions";

export function SaveActivity(
  this: any,
  steps: Array<StepTypes>,
  activityStates: Array<IActivityState>,
  spParameters: Array<ISPParameter>,
  activityTypesList: Array<string>
) {
  const {
    activitySetUpValue,
    setControlValue,
    modifyStateValue,
    executionProfileValue,
  } = this;
  const { entityType, setExecutionProfile } = activitySetUpValue;
  const {
    isShowInHistory,
    isExecuteFormDomain,
    isEnableAutoWFFollowup,
    isAvailableForHandling,
    isShowInCP,
    isShowInDP,
    isModifyState,
    followupCategory,
    followupReason,
    noOfDaysForNextFollowupDate,
    isNotifyActivityFailure,
  } = setControlValue;

  const { states } = modifyStateValue;

  const { activityExecutionProfileId } = executionProfileValue;

  return {
    ...setActivitySetUpRequest(
      activitySetUpValue,
      spParameters,
      activityTypesList
    ),
    isShowInHistory,
    isExecuteFormDomain,
    isAvailableForHandling,
    isShowInCP,
    isShowInDP,
    isEnableAutoWFFollowup,
    noOfDaysForNextFollowupDate: isNaN(noOfDaysForNextFollowupDate)
      ? 0
      : Number(noOfDaysForNextFollowupDate),
    commonFollowupCategoryId: isNaN(followupCategory)
      ? 0
      : Number(followupCategory),
    commonFollowupReason: followupReason,
    isNotifyActivityFailure,
    isModifyState,
    selectedStates: [EntityType.INVOICE, EntityType.CASE].includes(
      entityType.toLowerCase()
    )
      ? setStateForRequest(activityStates, states)
      : [],
    activityExecutionProfileId: setExecutionProfile
      ? activityExecutionProfileId
      : -1,
  };
}
