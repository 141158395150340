import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import { $Skeleton, $TableTree, $Empty, $Button } from "us.common/components";
import { useTranslation } from "react-i18next";
import { IResultView } from "./Interfaces";

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2944401416/Follow-up+Custom+Query+Result+View+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const ResultView: React.FC<IResultView & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { result, onClose } = props;

  const { dataSource, columns, isFetching } = result;

  return (
    <>
      <$Skeleton loading={isFetching} paragraph={{ rows: 2 }} active>
        {dataSource.length === 0 && !isFetching && (
          <$Empty
            className="pt-5"
            description={`${t("US.COLLECTION.ADMIN:BULKOPERATION.NO_DATA")}`}
          />
        )}
        {dataSource.length > 0 && columns.length > 0 && !isFetching && (
          <$TableTree
            rowKey={"key"}
            data={dataSource}
            size="small"
            className=""
            onSort={(sortData, dataSource) => sortData(dataSource)}
            onFilter={(searchData, dataSource) => searchData(dataSource)}
            filterOnType={true}
            resetOnSourceChange={true}
            bordered
            pagination={{ defaultPageSize: 100 }}
            scroll={{ x: 600, y: "calc(100vh - 260px)" }}
            columns={columns}
            firstColSkipFilterProps={-1}
            data-testid="customQueryResult-table"
          />
        )}
      </$Skeleton>
      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button onClick={onClose}>
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery } = manageFollowups;
  const { result } = customQuery;
  return { result };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResultView);
