import { ActivityVersion } from "us.collection.admin/components/ManageActivities/Components/ActivityDetail/Components/ActivitySetUp/Constants";
import { Step, DEFAULT_ACTIVITY_GROUP } from "us.collection.admin/constants";

export const initialStepDetail = {
  currentStepId: 0,
  currentStepName: Step.ACTIVITY_SETUP,
  steps: [Step.ACTIVITY_SETUP, Step.SET_CONTROLS],
  formValues: {
    activitySetUpValue: {
      activityVersion:ActivityVersion.V1,
      templateCategoryId:0,
      activityId: -1,
      entityType: "",
      activityType: "",
      activityTypeName: "",
      name: "",
      displayName: "",
      description: "",
      activityCode: "",
      activityGroup: DEFAULT_ACTIVITY_GROUP,
      parameters: [],
      isCompleted: false,
      templateProperty: "",
      printMode: "",
      printerName: "",
      updateDocumentDate: "",
      setExecutionProfile: false
    },
    setControlValue: {
      isShowInHistory: true,
      isExecuteFormDomain: true,
      isEnableAutoWFFollowup: false,
      isAvailableForHandling: false,
      isShowInCP: false,
      isShowInDP: false,
      isModifyState: false,
      followupCategory: "",
      followupReason: "",
      newFollowupCategory: "",
      noOfDaysForNextFollowupDate: "",
      isFollowupCategoryExist: false,
      isCompleted: false,
      isNotifyActivityFailure: false,
    },
    modifyStateValue: { states: ["all_1"], isCompleted: false },
    executionProfileValue: {
      activityExecutionProfileId: -1,
      profiles: [],
      isListFetching: false,
      isDetailsFetching: false,
      isCompleted: false,
    },
  },
};
