import moment from 'moment';
import { matchPath } from 'react-router-dom';
import { CommunicationMethod, MainTab } from '../Constants';
import { INavigationData } from '../Interfaces';

/**
 * @description - Genarate birthday using person no and validate person no
 * @param personNumber - Person number
 * @returns - Birthday
 */
export const validatePersonNo = (
	personNumber: number | string
): {
	isValid: boolean;
	birthDate: string;
} => {
	const personNo = personNumber.toString();
	const validLength = validatePersonNoLength(personNo);
	let response = {
		isValid: false,
		birthDate: '',
	};
	try {
		if (validLength.isVaild) {
			const bDate = generateBirthDate(personNo);
			if (validLength.numberLength >= 9) {
				response = {
					isValid:
						Boolean(+bDate?.date) &&
						bDate?.date.getDate() ==
							bDate.day &&
						bDate.date.getMonth() + 1 ==
							bDate.month,
					birthDate: bDate.date.toString(),
				};
			} else if (validLength.numberLength === 6) {
				response = {
					isValid:
						Boolean(+bDate?.date) &&
						bDate?.date.getDate() ==
							bDate.day &&
						bDate.date.getMonth() + 1 ==
							bDate.month,
					birthDate: bDate.date.toString(),
				};
			}
		}
		if (
			moment().endOf('day') < moment(response.birthDate) &&
			response.isValid
		) {
			return {
				isValid: false,
				birthDate: '',
			};
		}
		return response;
	} catch (error) {
		return response;
	}
};

/**
 * @description - Validate person no length
 * @param personNo - Person number
 * @returns
 */
const validatePersonNoLength = (
	personNo: string
): {
	isVaild: boolean;
	numberLength: number;
} => {
	try {
		let isVaild = false;
		if (personNo.length > 11) {
			isVaild = false;
		} else if (
			(personNo.length >= 9 && personNo.length <= 11) ||
			personNo.length === 6
		) {
			isVaild = true;
		} else {
			isVaild = false;
		}
		return {
			isVaild,
			numberLength: personNo.length,
		};
	} catch (error) {
		return {
			isVaild: false,
			numberLength: 0,
		};
	}
};

/**
 * @description - Genarate Birthday
 * @param personNo - Person number
 * @returns - Birthday
 */
const generateBirthDate = (personNo: string) => {
	const day = parseInt(personNo.substring(0, 2));
	const month = parseInt(personNo.substring(2, 4));
	const year = personNo.substring(4, 6);
	let date: Date = new Date(moment().format());
	let yearCode: number = 0;
	try {
		if (personNo.length >= 9) {
			let centryCode = personNo.substring(6, 9);
			if (
				parseInt(centryCode) >= 0 &&
				parseInt(centryCode) <= 499
			) {
				yearCode = parseInt(`19${year}`);
			} else if (
				parseInt(centryCode) >= 500 &&
				parseInt(centryCode) <= 999
			) {
				yearCode = parseInt(`20${year}`);
			}
			date = new Date(yearCode, +month - 1, day);
		} else if (personNo.length === 6) {
			let currentYear = new Date()
				.getFullYear()
				.toString()
				.substring(0, 2);
			let assumeYear = parseInt(currentYear + year);
			if (assumeYear > new Date().getFullYear()) {
				assumeYear = assumeYear - 100;
			}
			date = new Date(assumeYear, +month - 1, day);
		}
		return {
			date,
			month,
			day,
		};
	} catch (error) {
		return {
			date,
			month,
			day,
		};
	}
};

/**
 * @description - Get Case type, tab type and debtorEntRoleId
 * @param pathName - URL path
 * @param entityType - Entity type
 * @param debtorId - Debtor Ent Role Id
 * @param caseId - Case Id
 * @param creditorId - Creditor Id
 * @param arDetails - Case Ar details
 * @returns
 */
export const getURLTypeAndId = (
	pathName: any,
	arDetails: any,
	state: INavigationData,
	metaData: any
): {
	caseType: string;
	type: string;
	id: number | string;
	debtorEntRoleId: number | string;
} => {
	try {
		const { params }: any = matchPath(pathName, {
			path: '/:type/:Id?/:module?',
			exact: false,
			strict: false,
		});
		const { creditorId } = state;
		const { entityType, debtorId, caseId } = metaData.data ?? {};

		let id = params['Id'];
		const caseType = params['type'];
		let type;
		let debtorEntRoleId;

		switch (caseType) {
			case 'case':
				if (entityType === 'S') {
					type = MainTab.SUBCASE;
				} else {
					type = MainTab.CASE;
				}
				debtorEntRoleId = debtorId;
				id = caseId;
				break;
			case 'ar':
				type = MainTab.AR;
				debtorEntRoleId = arDetails.debtorId;
				break;
			case 'creditor':
				type = MainTab.CREDITOR;
				id = creditorId;
				break;
			default:
				type = 'S';
				break;
		}
		return {
			caseType,
			type,
			id,
			debtorEntRoleId,
		};
	} catch (error) {
		return {
			caseType: '',
			type: '',
			id: 0,
			debtorEntRoleId: 0,
		};
	}
};

/**
 * @description - Format Person number
 * @param value - Person Number
 * @returns - Formated person number
 */
export const formatPersonNo = (value: any): string => {
	try {
		if (
			value?.length === 6 ||
			(value?.length >= 9 && value?.length <= 11)
		) {
			const parts = value.replace(/\s/g, '').match(/.{1,6}/g);
			return parts.join(' ');
		}
		return value;
	} catch (error) {
		return value;
	}
};

/**
 * @description - Format organization number number
 * @param organizationNo - Oraganization Number
 * @returns - Formated organization number
 */
export const getFormatedOrganizationNo = (organizationNo: any) => {
	try {
		if (organizationNo?.length === 9) {
			const parts = organizationNo
				.replace(/\s/g, '')
				.match(/.{1,3}/g);
			return parts.join(' ');
		}
		return organizationNo;
	} catch (error) {
		return organizationNo;
	}
};

/**
 * @description - Create phone number object
 * @param telephone - Telephone number
 * @param smsNo - SMS Number
 * @returns - Phone number array
 */
export const getPhoneNumbers = (
	telephone: number | string,
	smsNo: number | string
): Array<any> => {
	const phoneNumbers = [];
	try {
		phoneNumbers.push({
			type: CommunicationMethod.MOBILE,
			number: telephone,
		});
		phoneNumbers.push({
			type: CommunicationMethod.WORK,
			number: telephone,
		});
		phoneNumbers.push({
			type: CommunicationMethod.HOME,
			number: telephone,
		});
		phoneNumbers.push({
			type: CommunicationMethod.SMS,
			number: smsNo,
		});
		return phoneNumbers;
	} catch (error) {
		return phoneNumbers;
	}
};
/**
 * @description - Search option list
 * @param mainCategories - Basic option list
 * @param searchValue - Search text
 * @returns {Array} - Option list
 */
export const getEntitySearchOptionList = (
	mainCategories: Array<any>,
	searchValue: string
): Array<any> => {
	try {
		const list = mainCategories?.map((item: any) => {
			return {
				label: item.label + searchValue.trim(),
				value: item.value + searchValue.trim(),
			};
		});
		return list;
	} catch (error) {
		return [];
	}
};
