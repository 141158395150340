import React from 'react';

const MainSearch = React.lazy(() => import('us.collection/containers/MainSearch'));
const UserRoleSettings = React.lazy(() => import('us.common/containers/UserManagement/UserRoleSettings'));
const UserSettings = React.lazy(() => import('us.common/containers/UserManagement/UserSettings'));
const Home = React.lazy(() => import('us.collection/containers/Home'));

export {
    MainSearch,
    UserRoleSettings,
    UserSettings,
    Home
}