import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Admin from "us.collection.admin";

interface ICntNonProductionDaysHome extends RouteComponentProps {}
const Cnt_NonProductionDaysHome: React.FC<ICntNonProductionDaysHome> = (
  props
) => {
  return (
    <>
      <Admin.Components.NonProductionDays.NonProductionDaysHome {...props} />
    </>
  );
};

export default withRouter(Cnt_NonProductionDaysHome);
