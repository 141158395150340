/**
 * It takes a string, splits it into an array of words, and then joins the words back together with a
 * hyphen in between each word
 * @param {string} fieldName - The name of the field you want to convert to a query parameter.
 * @returns A function that takes a string and returns a string.
 */
export const generateQueryParamName = (fieldName: string): string => {
  let name = "";

  try {
    const res = fieldName.split(/(?=[A-Z])/);
    res.map((word, index) => {
      if (index === 0) {
        name = word.toLowerCase();
      } else {
        name = `${name}-${word.toLowerCase()}`;
      }
    });
    return name;
  } catch (error) {
    return name;
  }
};

/**
 * It takes an object and returns a string that can be used as a query string.
 * @param {Object} params - Object
 * @returns A string
 */
export const createSearchParams = (params: Object): string => {
  let search = "";

  try {
    if (Object.entries(params).length > 0) {
      Object.entries(params).map((item, index) => {
        const queryName = generateQueryParamName(item[0]);
        if (index === 0 && item[1]) {
          search = `?${queryName}=${item[1]}`;
        } else if (item[1]) {
          search = `${search}&${queryName}=${item[1]}`;
        }
      });
    }
    return search;
  } catch (error) {
    return search;
  }
};

/**
 * Format quick links array
 * @param {Array} quickLinks - Quick links array payload
 * @returns Quick links array
 */
export const getMenuQuickLinks = (quickLinks: Array<any>): Array<any> => {
  try {
    return quickLinks.map((quickLink: any) => ({
      ...quickLink,
      id: quickLink?.operationId,
    }));
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description Trim and lowercase the provided string
 */
export const trimText = (text: string) => text?.trim()?.toLowerCase();
