import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  $Dropdown,
  $Tooltip,
  $Badge,
  $Menu,
  $Button,
  $Skeleton,
  $Empty,
  $Divider,
} from "us.common/components";
import { BoxIcons, IconTypes } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { notification } from "us.common/actions";
import { INotificationMenu, IManageNotification } from "./Interface";
import * as Azure from "us.helper/azure";
import {
  NotificationUpdateType,
  NotificationTriggerType,
} from "us.common/constants/Component/Notification";
import { openGlobalNotification } from "us.common/components/NotificationMenu/Functions";
import { UpdateNotification } from "us.common/components/NotificationMenu/Repository";
import { useSignalRTrigger } from "us.common/SignalRProvider";
import { NotificationItem } from "./Components";

/**
 * @description - Notification menu component for view available notification and manage them.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/05/2022
 * */
const NotificationMenu: React.FC<INotificationMenu & PropsFromRedux> = ({
  notifications,
  pendingNotifications,
  notificationUnReadCount,
  notificationUpdateId,
  isNotificationsFetching,
  updatePendingNotification,
  getNotifications,
  updateNotification,
}) => {
  const { t } = useTranslation();

  const [manageNotification, setManageNotification] =
    useState<IManageNotification>({
      visible: false,
      offset: 5,
    });
  const { setTrigger } = useSignalRTrigger();

  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let authentication: Azure.ADAuth | Azure.B2CAuth;
  authentication = isAzureAD ? new Azure.ADAuth() : new Azure.B2CAuth();
  const currentUser: any = authentication.currentUser();

  useEffect(() => {
    handleInitialNotification();
  }, []);

  /**
   * @function
   * @description - Initially get notifications and activate signalR triggers.
   */
  const handleInitialNotification = () => {
    if (getNotifications) {
      // initially get notifications
      getNotifications({ id: currentUser.oid, offset: 0 });

      const triggers = [
        {
          name: NotificationTriggerType.ON_NOTIFICATION_ADDED,
          callBack: () => {
            getNotifications({ id: currentUser.oid, offset: 0 });
          },
        },
        {
          name: NotificationTriggerType.ON_EVENT_ACK,
          callBack: (content: any) => {
            const { eventID, isInProgress } = content ?? {};
            openGlobalNotification(content);
            updatePendingNotification &&
              updatePendingNotification({ eventId: eventID, isInProgress });
          },
        },
        {
          name: NotificationTriggerType.ON_EVENT_DONE,
          callBack: (content: any) => {
            const { eventID, isInProgress } = content ?? {};
            openGlobalNotification(content);
            updatePendingNotification &&
              updatePendingNotification({ eventId: eventID, isInProgress });
          },
        },
      ];

      setTrigger(triggers);
    }
  };

  /**
   * @function
   * @description - onRemoveNotification is a function that remove selected notification.
   * @param {number} notificationId - the id of the notification
   */
  const onRemoveNotification = (notificationId: number) => {
    const updateParams = UpdateNotification.call({
      notificationId: notificationId,
      userId: currentUser.oid,
      type: NotificationUpdateType.DELETE,
    });
    updateNotification && updateNotification(updateParams);
  };

  /**
   * @description - Menu content for notification.
   */
  const notificationContent = (
    <$Menu
      forceSubMenuRender
      multiple
      selectedKeys={notifications
        ?.filter((notification: any) => !notification.isSeen)
        .map((notification: any) => notification.notificationId)}
      onClick={({ key }: any) => {
        const updateParams = UpdateNotification.call({
          notificationId: key,
          userId: currentUser.oid,
        });
        updateNotification && updateNotification(updateParams);
        setManageNotification({
          ...manageNotification,
          visible: true,
        });
      }}
      className="notification-menu"
    >
      <div className="d-flex pl-3 mb-2 justify-content-between notification-item ">
        <span className="font-weight-bold">
          {t("US.COMMON:COMMON.NOTIFICATION")}
        </span>
        {notifications?.length > 0 && (
          <$Button
            type="link"
            size="small"
            className="font-sm mt-2 mr-n2"
            loading={notificationUpdateId == -1}
            onClick={() => onRemoveNotification(-1)}
          >
            {t("US.COMMON:COMMON.CLEAR_ALL")}
          </$Button>
        )}
      </div>
      {notifications?.map((notification: any) => (
        <$Menu.Item key={notification.notificationId}>
          <NotificationItem
            data={notification}
            onRemoveNotification={onRemoveNotification}
          />
        </$Menu.Item>
      ))}
      <$Skeleton
        paragraph={{ rows: 1 }}
        loading={isNotificationsFetching}
        active
        className="m-3"
      />
      {notifications.length == 0 && !isNotificationsFetching && (
        <$Empty
          image={$Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("US.COMMON:COMMON.NO_NOTICES_RIGHT_NOW")}
        />
      )}
      {notifications.length > 0 && (
        <>
          <$Divider className="my-2" />
          <div className="mb-2 text-center notification-item">
            <a
              onClick={() => {
                getNotifications &&
                  getNotifications({
                    id: currentUser.oid,
                    offset: manageNotification.offset,
                    scroll: true,
                  }) &&
                  setManageNotification({
                    ...manageNotification,
                    offset: manageNotification.offset + 5,
                  });
              }}
            >
              {t("US.COMMON:COMMON.VIEW_MORE")}
            </a>
          </div>
        </>
      )}
    </$Menu>
  );

  return (
    <>
      <$Dropdown
        overlay={notificationContent}
        className="d-flex flex-row"
        trigger={["click"]}
        overlayClassName="notfication-dropdown"
        placement="bottomCenter"
        arrow
        visible={manageNotification.visible}
        onVisibleChange={(visible: boolean) => {
          if (visible) {
            getNotifications &&
              getNotifications({ id: currentUser.oid, offset: 0 });
            setManageNotification({ offset: 5, visible });
          } else {
            setManageNotification({ ...manageNotification, visible });
          }
        }}
      >
        <$Tooltip
          placement="bottomLeft"
          title={t("US.COMMON:COMMON.NOTIFICATION")}
        >
          <a
            className={`d-flex align-items-center pt-1 ant-dropdown-link px-3 ${
              pendingNotifications.length > 0 ? "noti-progress" : ""
            }`}
          >
            {" "}
            <$Badge count={notificationUnReadCount}>
              {" "}
              <BoxIcons
                type={IconTypes.BOX_ICON}
                name="bell"
                className="text-white"
              />{" "}
            </$Badge>
          </a>
        </$Tooltip>
      </$Dropdown>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { notification } = state;
  const { unReadCount, pendingEvents, data, notificationUpdateId, isLoading } =
    notification;
  return {
    notifications: data,
    pendingNotifications: pendingEvents,
    notificationUnReadCount: unReadCount,
    isNotificationsFetching: isLoading,
    notificationUpdateId,
  };
};

const { user, event, update } = notification;
const mapDispatchToProps = {
  getNotifications: user.get,
  updateNotification: update.save,
  updatePendingNotification: event.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NotificationMenu);
