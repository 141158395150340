import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import {
  $Search,
  $Button,
  $Input,
  $Popconfirm,
  $Skeleton,
} from "us.common/components";
import { PlusOutlined, DeleteOutlined } from "us.icons";
import { manageActivities } from "us.collection.admin/actions";
import { IExecutionProfile } from "us.collection.admin/interfaces";
import { AddEditProfile } from "./Components";
import { FieldArray, useFormikContext } from "formik";
import { initialNewProfileValues } from "./Constants";
import { findProfiles } from "./Functions";

const { executionProfiles, executionProfile } = manageActivities;

/**
 * @description Activity Registration - Execution Profile
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3060891716/Activities+-+Execution+Profile+UI+Implementation
 * @author Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 25/07/2022
 */
const ExecutionProfile: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const [executionProfiles, setExecutionProfiles] = useState<
    Array<IExecutionProfile>
  >([]);
  const [isAddNewShow, setIsAddNewShow] = useState<boolean>(false);

  const {
    getExecutionProfiles,
    getExecutionProfileDetails,
    deleteExecutionProfile,
    stepDetail,
  } = props;
  const { formValues } = stepDetail;
  const { executionProfileValue } = formValues;
  const { profiles, activityExecutionProfileId, isListFetching } =
    executionProfileValue;

  useEffect(() => {
    getExecutionProfiles && getExecutionProfiles({});
  }, []);

  useEffect(() => {
    setExecutionProfiles(profiles);
  }, [profiles]);

  const searchProfile = (searchKey: string) => {
    const searchedProfiles = findProfiles(profiles, searchKey);
    setExecutionProfiles(searchedProfiles);
  };

  const onSelectProfile = (profileId: number) => {
    getExecutionProfileDetails && getExecutionProfileDetails({ profileId });
  };

  return (
    <div className="ad-layout-content">
      <div className="execution-profile">
        <div className="d-flex mb-4">
          <$Search
            size="small"
            name="profileSearch"
            placeholder={t(
              "US.COLLECTION.ADMIN:ACTIVITIES.SEARCH_EXECUTION_PROFILE"
            )}
            onSearch={(val: string) => searchProfile(val)}
            onPressEnter={(e: any) => searchProfile(e.target.value)}
            onChange={(e: any) => searchProfile(e.target.value)}
            allowClear={true}
            style={{ width: 300 }}
          />
          <$Button
            type="dashed"
            size="small"
            icon={<PlusOutlined />}
            className="ml-2"
            onClick={() => setIsAddNewShow(true)}
          >
            {t("US.COLLECTION.ADMIN:ACTIVITIES.NEW_EXECUTION_PROFILE")}
          </$Button>
        </div>

        {isAddNewShow && (
          <AddEditProfile
            isNew={true}
            profileValues={{ ...initialNewProfileValues, profiles }}
            onCancel={() => setIsAddNewShow(false)}
          />
        )}

        <div className="radio-list mt-4">
          <FieldArray
            name="selectedPostalCodes"
            render={(arrayHelpers) => (
              <$Skeleton
                loading={isListFetching}
                active
                paragraph={{ rows: 2 }}
              >
                {executionProfiles.length > 0 &&
                  executionProfiles.map(
                    ({ id, profileName }: IExecutionProfile, index: number) => (
                      <div className="radio-list-wrap" key={index}>
                        <div
                          className="radio-list-item"
                          onClick={() => onSelectProfile(id)}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              className={`ant-radio ${
                                activityExecutionProfileId == id &&
                                "ant-radio-checked"
                              }`}
                            >
                              <span className="ant-radio-inner"></span>
                            </span>
                            <span className="radio-list-item-text">
                              {profileName}
                            </span>
                          </div>
                          <$Popconfirm
                            title={t(
                              "US.COLLECTION.ADMIN:ACTIVITIES.DELETE_CONFIRM"
                            )}
                            onConfirm={() =>
                              deleteExecutionProfile &&
                              deleteExecutionProfile({
                                profileId: id,
                              })
                            }
                            okText={t("US.COMMON:COMMON.YES")}
                            cancelText={t("US.COMMON:COMMON.NO")}
                            placement="topRight"
                          >
                            <$Button
                              icon={<DeleteOutlined />}
                              size="small"
                              type="link"
                              danger
                            />
                          </$Popconfirm>
                        </div>
                        {activityExecutionProfileId == id && (
                          <AddEditProfile
                            isNew={false}
                            profileValues={values}
                          />
                        )}
                      </div>
                    )
                  )}
              </$Skeleton>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { states, stepDetail } = manageActivities;
  return { states, stepDetail };
};
const mapDispatchToProps = {
  getExecutionProfiles: executionProfiles.get,
  getExecutionProfileDetails: executionProfile.get,
  deleteExecutionProfile: executionProfile.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ExecutionProfile);
