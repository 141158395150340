import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICreditorGroupSearchResult } from 'us.common/reducers/MainSearch/Interfaces';
import { ICreditor } from 'us.common/components/MainSearch/Interfaces';
import { $Avatar, $Col, $Row, $Tag, $Button } from 'us.common/components';
import { ResultView } from './ResultView';
import { MinusSquareOutlined, PlusSquareOutlined } from 'us.icons';
import { getRouteUrl } from 'us.helper';
import { useHistory } from 'react-router-dom';

/**
 * @description - Columns component for creditor result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const Creditor: React.FC<ICreditor> = (props) => {
	const {
		creditorData,
		handleCreditorGroupClick,
		paginationChangeEvent,
		currentHit,
		totalSearchResult,
	} = props;

	const { t } = useTranslation();
	const history = useHistory();

	/**
	 * Handle the row click event of dashboard view
	 * @param e click event.
	 * @param linkId navigation id.
	 */
	const rowClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		linkId: number | string,
		path: 'creditor' | 'creditor-group'
	) => {
		e.preventDefault();
		if (window.APP_MODULE == 'collection') {
			if (e.ctrlKey) {
				getRouteUrl.moduleRoute(
					'collection',
					`/${path}/${linkId}`
				);
			} else {
				history.push(`/${path}/${linkId}`);
			}
		} else {
			getRouteUrl.moduleRoute(
				'collection',
				`/${path}/${linkId}`
			);
		}
	};

	//Creditors
	const CreditorsTable = (value: any) => {
		const crData = [];
		for (let i = 0; i < value?.data.creditors?.length; ++i) {
			crData.push({
				key: i,
				creditor: `${value.data.creditors[i].creditorNumber} - ${value.data.creditors[i].creditorName}`,
				crState: value.data.creditors[i]
					.isCreditorActive,
				CompanyID: value.data.creditors[i].companyId,
				Direct: value.data.creditors[i].teleMobile,
				creditorEntRoleId:
					value.data.creditors[i]
						.creditorEntRoleId,
				creditorNumber:
					value.data.creditors[i].creditorNumber,
			});
		}

		return (
			<>
				{crData.length > 0 && (
					<$Row gutter={16} key='1stRow'>
						<$Col
							span={12}
							xxl={{
								span: 8,
								offset: 0,
							}}
							xl={{
								span: 12,
								offset: 0,
							}}>
							<div className='d-flex align-items-top'>
								<div
									style={{
										width: '40px',
									}}></div>
								<strong className='flex-fill mb-2'>
									{t(
										'US.COLLECTION.COMMON:COMMON.CREDITOR'
									)}
								</strong>
							</div>
						</$Col>
						<$Col
							span={4}
							xxl={{
								span: 3,
								offset: 0,
							}}
							xl={{
								span: 4,
								offset: 0,
							}}>
							<strong className='flex-fill mb-2'>
								{t(
									'US.COLLECTION.COMMON:COMMON.STATUS'
								)}
							</strong>
						</$Col>
						<$Col span={4}>
							<strong className='flex-fill mb-2'>
								{t(
									'US.COLLECTION.COMMON:COMMON.COMPANYID'
								)}
							</strong>
						</$Col>
						<$Col span={4}>
							<strong className='flex-fill mb-2'>
								{t(
									'US.COLLECTION.COMMON:COMMON.DIRECT'
								)}
							</strong>
						</$Col>
					</$Row>
				)}
				{crData.map((x, index) => (
					<$Row
						gutter={16}
						key={index + '2ndRow'}>
						<$Col
							span={12}
							xxl={{
								span: 8,
								offset: 0,
							}}
							xl={{
								span: 12,
								offset: 0,
							}}
							key={index + 'type'}>
							<div className='d-flex align-items-top'>
								<div
									style={{
										width: '40px',
									}}>
									<$Avatar
										shape='square'
										size='small'>
										Cr
									</$Avatar>
								</div>
								<a
									onClick={(
										e: React.MouseEvent<
											HTMLAnchorElement,
											MouseEvent
										>
									) =>
										rowClick(
											e,
											x.creditorNumber,
											'creditor'
										)
									}>
									<div
										className='flex-fill'
										data-testid='searchResults-creditorNameAndEntRoleId'>
										{
											x.creditor
										}
									</div>
								</a>
							</div>
						</$Col>
						<$Col
							span={4}
							xxl={{
								span: 3,
								offset: 0,
							}}
							xl={{
								span: 4,
								offset: 0,
							}}
							key={index + 'state'}>
							{x.crState ? (
								<$Tag className='tag-status-open'>
									{t(
										'US.COLLECTION.COMMON:COMMON.ACTIVE'
									)}
								</$Tag>
							) : (
								<$Tag color='tag-status-deactive'>
									{t(
										'US.COLLECTION.COMMON:COMMON.INACTIVE'
									)}
								</$Tag>
							)}
						</$Col>
						<$Col
							key={
								index +
								'companyId'
							}
							span={4}>
							{x.CompanyID}
						</$Col>
						<$Col
							key={index + 'Direct'}
							span={4}>
							{x.Direct}
						</$Col>
					</$Row>
				))}
			</>
		);
	};

	const columns: ColumnsType<any> = [
		{
			dataIndex: 'type',
			key: 'type',
			className: 'item-type pt-2',
			width: '65px',
			render: (
				subcaseno: number,
				values: ICreditorGroupSearchResult
			) => {
				return (
					<div className='d-flex align-items-center'>
						<$Button
							type='link'
							size='small'
							onClick={(
								e: React.MouseEvent<
									HTMLAnchorElement,
									MouseEvent
								>
							) => {
								e.preventDefault();
								handleCreditorGroupClick(
									values
								);
							}}>
							{!values.isCreditorGroupCollapsed && (
								<MinusSquareOutlined
									style={{
										fontSize: 15,
									}}
								/>
							)}
							{values.isCreditorGroupCollapsed && (
								<PlusSquareOutlined
									style={{
										fontSize: 15,
									}}
								/>
							)}
						</$Button>
						<$Avatar
							shape='square'
							size='small'
							className='ml-2'>
							Cg
						</$Avatar>
					</div>
				);
			},
		},
		{
			dataIndex: 'result',
			key: 'result',
			className: 'pb-0 pt-2',
			render: (
				subcaseno: number,
				values: ICreditorGroupSearchResult
			) => {
				return (
					<div className='pb-3 pl-2'>
						<$Row gutter={16}>
							<$Col span={24}>
								<div>
									<a
										onClick={(
											e: React.MouseEvent<
												HTMLAnchorElement,
												MouseEvent
											>
										) =>
											rowClick(
												e,
												values.groupId,
												'creditor-group'
											)
										}>
										<strong className='mb-0'>
											{`${t(
												'US.COLLECTION.COMMON:COMMON.CREDITORGROUP'
											)} : ${
												values.groupId
											} - ${
												values.groupName
											}`}
										</strong>
									</a>
								</div>
							</$Col>
						</$Row>
						{/*Toggle Area */}
						{!values.isCreditorGroupCollapsed && (
							<$Row gutter={16}>
								<$Col span={24}>
									<div className='p-y mt-3 cr-table'>
										<CreditorsTable
											data={
												values
											}
										/>
									</div>
								</$Col>
							</$Row>
						)}
					</div>
				);
			},
		},
	];

	return (
		<ResultView
			rowKey='groupId'
			currentHit={currentHit}
			dataSource={creditorData}
			paginationChangeEvent={paginationChangeEvent}
			tableClassName='result-table creditor-result-table'
			tableColumns={columns}
			totalSearchResult={totalSearchResult}
		/>
	);
};
