import React from "react";
import { Form } from "antd";
import { useField } from "formik";
import { IInputAccountNoAsyncParams } from "us.common/components/antd/Interfaces";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./InputAccountNoAsync.scss";
import { $InputAccountNo } from "us.common/components";

const $I = React.forwardRef(
  (
    {
      name,
      id,
      label,
      placeholder,
      type,
      size,
      isValid = true,
      className,
      disabled,
      allowClear,
      required,
      suffix,
      prefix,
      maxLength,
      onKeyDown,
      onBlur,
      onChange,
      addonBefore,
      addonAfter,
      onPressEnter,
      max,
      min,
      style,
      tabIndex,
      dataTestid,
      autoFocus,
      asyncError,
      formitem,
      onClear,
    }: IInputAccountNoAsyncParams,
    ref: any
  ) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = meta?.error;

    return (
      <Form.Item
        label={label}
        help={!isValid ? asyncError : ""}
        validateStatus={!isValid ? "error" : ""}
        className="input-number-hide-arrows"
        required={typeof required == "undefined" ? false : true}
        extra={formitem?.extra}
      >
        <$InputAccountNo
          {...field}
          placeholder={placeholder}
          type={type}
          style={style}
          max={max}
          min={min}
          ref={ref}
          size={size}
          tabIndex={tabIndex}
          disabled={disabled}
          className={className}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          suffix={suffix}
          prefix={prefix}
          data-testid={dataTestid}
          maxLength={maxLength}
          allowClear={allowClear}
          onKeyDown={onKeyDown}
          onPressEnter={onPressEnter}
          onBlur={(e: any) => {
            onBlur && onBlur(e);
            field.onBlur(e);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            typeof onChange == "function"
              ? onChange(e)
              : helpers?.setValue(e.target.value);
            typeof onClear == "function" && !e.target.value && onClear(e);
          }}
        />
      </Form.Item>
    );
  }
);

$I.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validationMessage: PropTypes.string,
  asyncError: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  onPressEnter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $AsyncInputAccountNo = $I;
