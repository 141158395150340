import Admin from 'us.collection.admin/sagas';
import { PaymentAgreementSagas } from './PaymentAgreement'
// import { ObsoleteDateSagas } from './ObsoleteDate'
// import { TransTypesSagas } from './Transactions'
import { ActivitySagas } from './ActivityExecution'
// import { WorkflowExecutionSagas } from './WorkflowExecution'
import { HandlerSagas } from './Handler';
import { GDPRCommentSagas } from './GDPRComment';

export default [
  ...Admin,
  ...PaymentAgreementSagas,
  // ...ObsoleteDateSagas,
  // ...TransTypesSagas,
  ...ActivitySagas,
  // ...WorkflowExecutionSagas,
  ...HandlerSagas,
  ...GDPRCommentSagas
]