import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "us.helper/types";
import { $FormItem, $Input } from "us.common/components";
import { getExistedData } from "./Functions";
import { ManageActivities } from "us.collection.admin/constants";

export default React.forwardRef(
  (
    {
      name,
      label,
      formItem,
      required = true,
      currentValues,
      tabIndex,
      autoFocus,
      disabled,
      entityType,
      ...rest
    }: any,
    ref: any
  ) => {
    const [, setIsExist] = useState<boolean>(false);
    const { values, setFieldTouched } = useFormikContext() as any;
    const [field, meta, helpers] = useField({ name });

    const { activities, drawer, activityDetail } = useSelector(
      (state: RootState) => state.manageActivities
    );
    const dispatch = useDispatch();

    const { isEdit } = drawer;
    const { list } = activities;
    const fieldValue: string = field.value;
    const { data } = list;
    const { data: activity } = activityDetail;

    useEffect(() => {
      if (fieldValue?.length > 0 && entityType && !isEdit) {
        setIsExist(
          getExistedData(data, entityType, name).includes(
            fieldValue.toLowerCase()
          )
        );
      } else {
        setIsExist(false);
      }
    }, [entityType]);

    /**
     * @function
     * @description check whether enter value is existed or not
     * @param {string} value user enter value
     */
    const checkDataIsAlreadyExisted = (value: string): boolean => {
      try {
        if (isEdit) {
          if ((activity as any)[name] == value) {
            return false;
          } else {
            return getExistedData(data, entityType, name).includes(
              value.toLowerCase()
            );
          }
        } else {
          return getExistedData(data, entityType, name).includes(
            value.toLowerCase()
          );
        }
      } catch (error) {
        return false;
      }
    };
    /**
     * @function
     * @description check input value and update state
     * @param {string} value input value
     */
    const checkInputValue = (value: string): boolean => {
      try {
        return value.trim().length ? checkDataIsAlreadyExisted(value) : false;
      } catch (error) {
        setIsExist(false);
        return false;
      }
    };

    /**
     * @function
     * @description handle onBlur event
     * @param {any} e
     */
    const onBlur = (e: any) => {
      const value = e.target.value;
      checkInputValue(value);
      dispatch({
        type: ManageActivities.SET_ACTIVITY_CODE_STATUS,
        payload: {
          data: {
            ...values,
            isActivityCodeExist: checkInputValue(value),
          },
        },
      });
      setFieldTouched(name, true, true);
      field.onBlur(e);
    };

    return (
      <$FormItem
        label={label}
        {...formItem}
        required
      >
        <$Input
          {...field}
          {...rest}
          disabled={disabled}
          onBlur={(e: any) => onBlur(e)}
          onChange={(e: any) => {
            const value = e.target.value;
            checkInputValue(value);
            helpers?.setValue(value);
          }}
        />
      </$FormItem>
    );
  }
);
