import { IFollowupResult } from "us.collection.admin/interfaces";
import { FollowupEntityType, FollowupType } from "us.collection.admin/constants";

export const followUpResult: IFollowupResult = {
    followupName: "",
    entityType: FollowupEntityType.CASE,
    followupType: FollowupType.DYNAMIC_FOLLOW_UP,
    followupHeaders: [],
    followupData: [],
};
