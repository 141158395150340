import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    return (
        <>
            <p>Hi this is DailyCheckups.Home</p>
            <p>{t('US.COLLECTION.ADMIN:DAILYCHECKUPS.TEST')}</p>
        </>
    )
}