import { IAPIAction, IActivityAction } from "us.collection/interfaces";
import { Activity } from 'us.collection/constants';

export const activityAction: Readonly<IAPIAction & IActivityAction> = {
    activities: {
        get: (data) => ({
            type: Activity.GET_ACTIVITIES_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Activity.GET_ACTIVITIES_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Activity.GET_ACTIVITIES_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        setFilter: (data) => ({
            type: Activity.ACTIVITIES_FILTER_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        set: (data) => ({
            type: Activity.SET_USER_NAME_CASE_NUMBER,
            payload: {
                data,
                isLoading: true
            }
        }),
    },
    parameters: {
        get: (data) => ({
            type: Activity.GET_ACTIVITY_PARAMETERS_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Activity.GET_ACTIVITY_PARAMETERS_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Activity.GET_ACTIVITY_PARAMETERS_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    activity: {
        save: (data) => ({
            type: Activity.EXCUTE_ACTIVITY_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Activity.EXCUTE_ACTIVITY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Activity.EXCUTE_ACTIVITY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        set: (data) => ({
            type: Activity.SET_SELECT_ACTIVITY,
            payload: {
                data,
                isLoading: true
            }
        }),
        reset: (data) => ({
            type: Activity.RESET_SELECT_ACTIVITY,
            payload: {
                data,
                isLoading: true
            }
        })
    },
    pendingActivities: {
        save: (data) => ({
            type: Activity.ALL_PENDING_ACTIVITY_EXCUTE_STRAT,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: Activity.ALL_PENDING_ACTIVITY_EXCUTE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: Activity.ALL_PENDING_ACTIVITY_EXCUTE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        reset: (
            data
        ) => ({
            type: Activity.RESET_PENDING_ACTIVITY_STATE,
            payload: {
                data,
                isLoading: true
            }
        }),
    }
}