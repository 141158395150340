import {
  IUserInformationInitialState
} from "../Interfaces/IInitialState";

export const initialState: IUserInformationInitialState = {
  userProfilePhoto: {},
  tenant: {},
  quickLinks: {
    data: [],
    isSaving: false,
    isLoading: false
  }
};
