import { IAzureADUser } from "us.common/interfaces";


export const aDUserDetail: IAzureADUser = {
    id: '',
    businessPhones: [],
    displayName: '',
    givenName: '',
    mail: '',
    preferredLanguage: '',
    surname: '',
    userPrincipalName: '',
    oDataType: ''
}