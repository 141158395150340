import React from "react";
import { $Col, $Row } from "us.common/components";
import { NonExecutionDay } from "../NonExecutionDays";

/**
 * @description Non WorkFlow Days Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const NonWorkflowDays: React.FC = () => {
  return (
    <$Row gutter={16}>
      <$Col xl={{ span: 12 }} xxl={{ span: 10 }} className="px-4">
        <NonExecutionDay />
      </$Col>
    </$Row>
  );
};

export default NonWorkflowDays;
