import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicComponent } from "us.common/constants";
import { IElement } from "us.common/components/antd/Interfaces";
import { useSelector } from "react-redux";
import {
  $Skeleton,
  $Input,
  $TextArea,
  $DatePicker,
  $DateLabel,
  $InputNumber,
  $Select,
  $AmountLabel,
  $Switch,
  $InputAmount,
} from "us.common/components";

/**
 * @description - Dynamic component elements.
 * @author Darshana Wasala , Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 21/03/2022
 */
const Elements: React.FC<IElement> = ({
  component,
  label,
  name,
  required,
  options = [],
  value,
  restProps,
}) => {
  const { t } = useTranslation();

  const { currentDateFormat, currentLanguage, currentCurrency } = useSelector(
    (state: any) => state.common
  );

  switch (component) {
    case DynamicComponent.Skeliton:
      return (
        <$Skeleton
          className="mt-3"
          active={true}
          loading={true}
          paragraph={false}
          {...restProps}
        />
      );

    case DynamicComponent.Input:
      return (
        <$Input
          name={name}
          label={t(`US.COLLECTION.COMMON:COMMON.${label}`)}
          required={required}
          size={"small"}
          {...restProps}
        />
      );

    case DynamicComponent.Label:
      return (
        <div className="d-flex flex-column">
          {label && <div>{t(`US.COLLECTION.COMMON:COMMON.${label}`)}</div>}
          <div className="mt-1 font-weight-bold">{value}</div>
        </div>
      );

    case DynamicComponent.Switch:
      return (
        <div className="d-flex align-items-start mt-4">
          <div>
            {" "}
            <$Switch
              name={name}
              checked={value}
              size={"small"}
              {...restProps}
            />
          </div>
          <div className="ml-2">
            {t(`US.COLLECTION.COMMON:COMMON.${label}`)}
          </div>
        </div>
      );

    case DynamicComponent.TextArea:
      return (
        <$TextArea
          name={name}
          label={t(`US.COLLECTION.COMMON:COMMON.${label}`)}
          required={required}
          size={"small"}
          {...restProps}
        />
      );

    case DynamicComponent.DatePicker:
      return (
        <$DatePicker
          name={name}
          label={t(`US.COLLECTION.COMMON:COMMON.${label}`)}
          required={required}
          size={"small"}
          placeholder={currentDateFormat}
          format={currentDateFormat}
        />
      );

    case DynamicComponent.DateLabel:
      return (
        <div className="d-flex flex-column">
          <div>{t(`US.COLLECTION.COMMON:COMMON.${label}`)}</div>
          <div className="mt-1 font-weight-bold">
            <$DateLabel value={value} />
          </div>
        </div>
      );

    case DynamicComponent.InputNumber:
      return (
        <$InputNumber
          size={"small"}
          style={{ width: 275 }}
          name={name}
          label={t(`US.COLLECTION.COMMON:COMMON.${label}`)}
          required={required}
          {...restProps}
        />
      );

    case DynamicComponent.InputAmount:
      return (
        <div className="d-flex flex-column">
          <$InputAmount
            name={name}
            value={value}
            label={t(`US.COLLECTION.COMMON:COMMON.${label}`)}
            currentLanguage={currentLanguage}
            currentCurrency={currentCurrency}
          />
        </div>
      );

    case DynamicComponent.AmountLabel:
      return (
        <div className="d-flex flex-column">
          <div>{t(`US.COLLECTION.COMMON:COMMON.${label}`)}</div>
          <div className="mt-1 font-weight-bold">
            <$AmountLabel value={value} />
          </div>
        </div>
      );

    case DynamicComponent.Select:
      return (
        <$Select
          name={name}
          size={"small"}
          options={options ?? []}
          formitem={{
            label: label ? t(`US.COLLECTION.COMMON:COMMON.${label}`) : "",
          }}
          onSearchBy={["label"]}
          allOption={false}
          required={required}
          {...restProps}
        />
      );
    case DynamicComponent.Blank:
      return <></>;
    default:
      return <></>;
  }
};

export default Elements;
