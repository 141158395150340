export enum DefaultCategory {
  categoryID = "all",
  categoryName = "All",
  categoryNone = "None"
}
export enum ContextMenuAction {
  VIEW_RESULT = "VIEW_RESULT",
  EDIT = "EDIT",
  DELETE = "DELETE",
  CANCEL = "CANCEL",
}
export enum FollowupEntityType {
  CASE = "Case",
  INVOICE = "Invoice",
  CREDITOR = "Creditor",
  DEBTOR = "Debtor",
  PAYMENT = "Payment",
  SUBCASE = "SubCase",
}

export enum FollowupType {
  DYNAMIC_FOLLOW_UP = "DynamicFollowUp",
  STATIC_FOLLOW_UP = "StaticFollowUp",
}

export enum FollowupUniqueCol {
  FOLLOWUP_ID = "Followupid",
  EX_CASE_NO = "Excaseno",
  CASE_NO = "Caseno",
  CREDITOR_NO = "Creditorno",
  AR_NO = "Arno",
  PAYMENT_ID = "Paymentid",
  PAYMENT_NO = "Paymentno",
  WORKFLOW_STATE = "Workflowstate",
  FOLLOWUP_CASE_STATE = "Followupcasestate",
  CASE_TYPE = "Casetype",
  CASE_HANDLER = "Casehandler",
  COUNTRY_ID = "countryid",
  REASON = "reason",
  COMMENT = "comment",
  NOTE = "note",
  BALANCE = "balance",
  AMOUNT = "amount",
  MORE = "more",
  INFO = "info",
  TYPE = "type",
  FINAL_COL = "finalCol",
  LAST_ACTION_NAME = "Lastactionname",
  LAST_ACTION_STATUS = "Lastactionstatus",
  LAST_ACTION_DATE = "Lastactiondate",
  LAST_ACTION_USER = "Lastactionuser",
}

export enum FollowupEntityTypeBadge {
  CASE = "Ca",
  INVOICE = "In",
  CREDITOR = "Cr",
  DEBTOR = "De",
  PAYMENT = "Pa",
}
export enum UrlModule {
  CASE = "case",
  CREDITOR = "creditor",
  AR = "ar",
  PAYMENT = "payment",
}

export enum CustomQueryDrawerType {
  SAVE_QUERY = "Save Query",
  VIEW_RESULT = "View Result",
}

export enum AssignType {
  ALL = "All",
  USER = "User",
  ROLE = "Role"
}

export const InitialCustomQueryDrawer = {
  type: "",
  title: "",
  visible: true,
};

export type CustomQueryDrawerTypeInterface =
  | CustomQueryDrawerType.SAVE_QUERY
  | CustomQueryDrawerType.VIEW_RESULT;

export type FollowupEntityTypeBadgeInterface =
  | FollowupEntityTypeBadge.CASE
  | FollowupEntityTypeBadge.INVOICE
  | FollowupEntityTypeBadge.DEBTOR
  | FollowupEntityTypeBadge.CREDITOR
  | FollowupEntityTypeBadge.PAYMENT
  | "";
export type AllFollowupEntityTypeInterface =
  | FollowupEntityType.CASE
  | FollowupEntityType.INVOICE
  | FollowupEntityType.CREDITOR
  | FollowupEntityType.DEBTOR
  | FollowupEntityType.PAYMENT
  | FollowupEntityType.SUBCASE
  | "";

export type UrlModuleInterface =
  | UrlModule.CASE
  | UrlModule.AR
  | UrlModule.CREDITOR
  | UrlModule.PAYMENT
  | "";

export enum FormFieldName {
  AssignTo = 'assignTo',
  AssignToList = "assignToList"
}