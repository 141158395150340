import { IOnFilter, IOnSort } from "us.common/components";
import { appInsights } from "us.helper";

/**
 * @function
 * @description - sorting function on table tree
 * @param sortData - data sorting function
 * @param dataSource - data source with children data
 * @returns sorted data
 */
export const handleSortGroup: IOnSort = (sortData, dataSource): Array<any> => {
  try {
    return sortData(
      dataSource.map((data: any) => {
        return {
          ...data,
          children: sortData(data.children),
        };
      })
    );
  } catch (error) {
    appInsights.trackException(
      `handleSortGroup Exception in Activity Summary Table - ${error}`
    );
    return dataSource;
  }
};

/**
 * @function
 * @description - filtering function on table tree
 * @param searchData - data searching function
 * @param dataSource - data source with children data
 * @returns filtered data
 */
export const handleFilterGroup: IOnFilter = (
  searchData,
  dataSource
): Array<any> => {
  const result: any[] = [];
  try {
    dataSource.flatMap((data: any) => {
      searchData(data?.children)?.length != 0 &&
        result.push({
          ...data,
          children:
            searchData(data?.children)?.length == 0
              ? []
              : searchData(data?.children),
        });
    });
    return result;
  } catch (error) {
    appInsights.trackException(
      `handleFilterGroup filtering Exception in Activity Summary Table - ${error}`
    );
    return result;
  }
};

/**
 * @function
 * @description - expanded rows keys
 * @param dataSource {Array<any>} - table data source
 * @param expandedRows {Array<any>} - expanded row's keys array
 * @param rowKey {number} - currently expanded/collapsed clicked row
 * @param isExpand {boolean} - expand or collapse
 * @param isAll {boolean} - whether all the rows are collapse
 * @returns expanded row keys
 */
export const handleExpandedRows = (
  dataSource: Array<any>,
  expandedRows: Array<any>,
  rowKey: number,
  isExpand: boolean,
  isAll?: boolean
): Array<any> => {
  try {
    if (isAll) {
      return dataSource?.map((item: any) => {
        return item[`activityGroup`];
      });
    } else if (typeof isAll != "undefined" && !isAll) {
      return [];
    } else {
      if (isExpand) {
        return [...expandedRows, rowKey];
      } else {
        return expandedRows.filter(
          (expandedRow: number) => expandedRow != rowKey
        );
      }
    }
  } catch (error) {
    return [];
  }
};
