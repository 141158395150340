import { MainCriterias } from "us.common/constants";
import {
  ICaseSearchResult,
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
  IDebtorARSearchResult,
  IDebtorCaseSearchResult,
  IDebtorSearchResult,
  IInvoiceSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { SortOrderType } from "./SortOrderType";

export interface IFormikInitVal {
  tabInfo: IFormikTabs;
  selectedTab: MainCriterias;
}

export interface ITabsInfo {
  selectedTab: MainCriterias;
  filterValues: IFilterValues;
  invoiceTab: ITab<IInvoiceSearchResult>;
  caseTab: ITab<ICaseSearchResult>;
  creditorTab: ITab<ICreditorGroupSearchResult & ICreditorSearchResult>;
  debtorTab: ITab<IDebtorSearchResult & IDebtorARSearchResult & IDebtorCaseSearchResult>;
}

export interface ITab<T> {
  sortKey: keyof T;
  sortOrder: SortOrderType;
  hitValue: number;
  isTabVisible: boolean;
}

export interface IFilterValues {
  isOpenCase: boolean;
  isNextDueDateCase: boolean;
  isCloseCase: boolean;
  isCourtCase: boolean;
  isSentenceCase: boolean;
  caseNumber: string;
  invoiceNumber: string;
  mainAmount: string;
  balance: string;
  debtorNameNo: string;
  birthdate: string;
  creditorNameNo: string;
  phone: string;
  address: string;
  lastMsgActivityName: string;
  creditorStatus: string;
  creditorGroupIdName: string;
  custId: string;
  arNo: string;
}

export interface IFormikData {
  invoice: IInvoiceSearchResult[] | [];
  case: ICaseSearchResult[] | [];
  creditor: ICreditorGroupSearchResult[] | [];
  debtor: IDebtorSearchResult[] | [];
}

export interface IFormikTabs {
  invoice: IFormikTabInfo<IInvoiceSearchResult>;
  case: IFormikTabInfo<ICaseSearchResult>;
  creditor: IFormikTabInfo<ICreditorGroupSearchResult & ICreditorSearchResult>;
  debtor: IFormikTabInfo<IDebtorSearchResult & IDebtorARSearchResult & IDebtorCaseSearchResult>;
}

export interface IFormikTabInfo<T> {
  tabType: MainCriterias;
  sortData: IFormikSortKeyValue<T>;
  filterKeyValues: IFormikFilterKeyValue<T>[];
  isTabVisible: boolean;
  hitValue: number;
}

export interface IFormikSortMenuData {
  ObjectProp: keyof CombinedKeyType;
  displayKey: string;
}

export interface IFormikSortKeyValue<T> {
  key: keyof T;
  value: SortOrderType;
}
export interface IFormikFilterKeyValue<T> {
  key: FilterCriteriasEnum;
  value: string | boolean;
  ObjectProp?: keyof T;
}

export type CombinedKeyType =  IInvoiceSearchResult & 
  ICaseSearchResult &
  ICreditorGroupSearchResult &
  ICreditorSearchResult &
  IDebtorSearchResult &
  IDebtorARSearchResult &
  IDebtorCaseSearchResult

export enum FilterCriteriasEnum {
  CBX_OPEN,
  CBX_SENTENCE,
  CBX_NEXT_DUE_DATE,
  CBX_COURT,
  CBX_CLOSE,
  INPUT_CASE_NUMBER,
  INPUT_INVOICE_NUMBER,
  INPUT_MAIN_AMOUNT,
  INPUT_BALANCE,
  INPUT_DEBTOR_NAME_NUMBER,
  DATEPICK_BIRTHDATE,
  INPUT_CREDITOR_NAME_NUMBER,
  INPUT_PHONE,
  INPUT_ADDRESS,
  INPUT_LAST_MESSAGE_ACTIVITY,
  SELECT_CREDITOR_STATUS,
  INPUT_CREDITOR_GROUP,
  INPUT_DEBTOR_CUSTID,
  INPUT_DEBTOR_ARNO,
}

export enum PaginationDirection {
  NEXT,
  PREV,
  RESET,
}
