import { IColumnSearchType } from "us.collection.admin/components/Court/Interfaces";
import {
  ColumnSearchType,
  CourtInfoTableColumn,
} from "us.collection.admin/components/Court/Constants";

/**
 * @description - Get dynamic width property value for each column by id
 * @param key - Column key
 * @returns - Width (px)
 */
export const getColumnWidth = (key: string): string => {
  try {
    if (key === CourtInfoTableColumn.MENU) {
      return "40px";
    } else if (
      key === CourtInfoTableColumn.COURT_NAME ||
      key === CourtInfoTableColumn.COURT_ADDRESS
    ) {
      return "25%";
    } else {
      return "";
    }
  } catch (error) {
    return "100px";
  }
};

/**
 * @description - Get type of column (`IColumnSearchType`) by column id
 * @param title - Column title
 * @returns column type - `IColumnSearchType`
 */
export const getColumnType = (title: string): IColumnSearchType => {
  try {
    return title == CourtInfoTableColumn.POSTAL_CODE
      ? ColumnSearchType.NUMBER
      : ColumnSearchType.STRING;
  } catch (error) {
    return ColumnSearchType.STRING;
  }
};

/**
 * @description Check whether any column filters include or not
 * @param filters filters Array
 * @param tableInfo tableInfo Object
 * @returns boolean `true` if has any filters
 */
export const hasFilters = (filters: Array<any>, tableInfo: any): boolean => {
  const filterItems = [];

  try {
    filters.map(({ value }: { dataIndex: string; value: any }) => {
      if (value) {
        filterItems.push(value);
      }
    });
    return (
      filterItems.length > 0 ||
      (tableInfo?.sorter?.order !== undefined &&
        tableInfo?.sorter?.order !== "")
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description - Get validation status
 * @param errors - errors object
 * @returns - validation status
 */
export const isValid = (errors: any): boolean => {
  try {
    return Object.keys(errors).length === 0;
  } catch (error) {
    return false;
  }
};

/**
 * @description - Get postal id for selected postal code
 * @param postalAreas - all postal areas
 * @param selectedPostalCode - selected postal code
 * @returns - postal id
 */
export const getPostalId = (
  postalAreas: any,
  selectedPostalCode: any
): number => {
  try {
    return postalAreas.filter(
      (area: any) => area.postalCode === selectedPostalCode
    )[0]?.postalCodeId;
  } catch (error) {
    return 0;
  }
};

/**
 * @description - Get validation status
 * @param record - record for edit
 * @param values - values object
 * @param selectedPostalCodes - selectedPostalCodes object
 * @returns - validation status
 */
export const isDirty = (
  record: any,
  values: any,
  selectedPostalCodes: any
): boolean => {
  try {
    return (
      record?.courtName !== values?.courtName ||
      record?.address1 !== values?.address1 ||
      record?.address2 !== values?.address2 ||
      record?.typeId !== values?.courtType ||
      record?.postalCode !== values?.postalCode ||
      record?.telephone !== values?.telephone ||
      record?.orgNo !== values?.orgNo ||
      record?.bankAccountNo !== values?.bankAccountNo ||
      JSON.stringify(selectedPostalCodes) !==
        JSON.stringify(values.selectedPostalCodes)
    );
  } catch (error) {
    return false;
  }
};
