import _ from 'lodash';
import { $MessageBox } from 'us.common/components';

export const updateObject = (oldObject: any, updatedProperties: any) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

export const isValidURL = (url: string) => {
	var res = url.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	);
	return res !== null;
};

export const getRouteUrl = {
	caseType: (caseNo: string | number) => {
		if (
			caseNo &&
			caseNo != undefined &&
			caseNo.toString().length > 0 &&
			isValidURL(`${window._ENV.REACT_APP_COLLECTION_URL}`)
		) {
			window.open(`${window._ENV.REACT_APP_COLLECTION_URL}/case/${caseNo}`);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	paymentDistribution: (paymentId: number) => {
		if (
			paymentId &&
			paymentId !== undefined &&
			!isNaN(paymentId) &&
			isValidURL(`${window._ENV.REACT_APP_COLLECTION_URL}`)
		) {
			window.open(
				`${window._ENV.REACT_APP_COLLECTION_URL}/payment/${paymentId}/distribution`
			);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	moduleRoute: (moduleName: string, route?: string, isSameTab?: boolean) => {
		const target = isSameTab ? '_self' : undefined;
		if (moduleName && moduleName !== undefined && moduleName.length > 0) {
			const URL_KEY = `REACT_APP_${moduleName.toUpperCase()}_URL`;
			const envConfig = window._ENV as any;
			const moduleUrl = envConfig[URL_KEY];
			if (moduleUrl) {
				const routeUrl = route ? `${moduleUrl}${route}` : moduleUrl;
				window.open(routeUrl, target);
			} else {
				$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
			}
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	/**
	 * @description - Open creditor dashboard by validating the creditor no
	 * @param creditorNo
	 */
	creditor: (creditorNo: string | number) => {
		if (
			creditorNo &&
			creditorNo != undefined &&
			creditorNo.toString().length > 0 &&
			isValidURL(`${window._ENV.REACT_APP_COLLECTION_URL}`)
		) {
			window.open(
				`${window._ENV.REACT_APP_COLLECTION_URL}/creditor/${creditorNo}`
			);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	/**
	 * @description - Open ar dashboard by validating the ar no
	 * @param arNo
	 */
	ar: (arNo: string | number) => {
		if (
			arNo &&
			arNo != undefined &&
			arNo.toString().length > 0 &&
			isValidURL(`${window._ENV.REACT_APP_COLLECTION_URL}`)
		) {
			window.open(
				`${window._ENV.REACT_APP_COLLECTION_URL}/ar/${arNo}/transactions`
			);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	/**
	 * @description - Navigate to Manage Payment view by payment id
	 * @param paymentId
	 */
	mapPayment: (
		paymentId: number,
		view: 'default' | 'apportionment' = 'default'
	) => {
		if (
			paymentId &&
			paymentId !== undefined &&
			!isNaN(paymentId) &&
			isValidURL(`${window._ENV.REACT_APP_ECONOMY_URL}`)
		) {
			window.open(
				`${window._ENV.REACT_APP_ECONOMY_URL}/manage-payments?view=${view}&id=${paymentId}&open=true&periodType=all`
			);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
	/**
	 * @description - Navigate to Report view by report id
	 * @param reportId
	 */
	reportPage: (url: any) => {
		if (
			url &&
			url !== undefined &&
			isValidURL(`${window._ENV.REACT_APP_REPORTS_URL}`)
		) {
			window.open(
				`${window._ENV.REACT_APP_REPORTS_URL}/reporting-page?urlId=${url}`
			);
		} else {
			$MessageBox('error', `US.COMMON:COMMON.INVALID_LINK`, '', '');
		}
	},
};

/**
 * @description Handle application back action.
 * @param history - location history
 * @returns if history stack is empty navigate to the home page , otherwise navigate to the recent history location
 */
export const handleGoBack = (history: any) => {
	const { goBack, push, length } = history;
	// length => Returns a Number. The number of entries in the history stack
	if (length <= 1) {
		// if history stack is not empty, then navigate to the recent history location
		push('/');
	} else {
		// if history stack is empty, then navigate to the main page
		goBack();
	}
};

/**
 * @description - Dynamically setup the title with environment flag and client name
 */
export const setupTitle = () => {
	try {
		const appModule = window.APP_MODULE;
		const client = window._ENV.REACT_APP_CLIENT;
		const appEnv = window._ENV.REACT_APP_ENV;

		if (appModule) {
			// capitalize the first letter of the module name
			const appModuleName = ['ci', 'io', 'usc'].includes(
				appModule.toLowerCase()
			)
				? appModule.toUpperCase()
				: appModule.charAt(0).toUpperCase() + appModule.substring(1);

			if (['uat', 'std-uat', 'std-prod'].includes(appEnv)) {
				if (client) {
					// capitalize the first letter of the module name
					const clientName =
						client.charAt(0).toUpperCase() + client.substring(1);

					if (appEnv === 'std-prod') {
						// concat with the env
						// set title
						document.title = `${appModuleName} - ${clientName}`; // Collection - Debia
					} else {
						// concat with the env
						// set title
						document.title = `${appModuleName} - ${clientName} ${appEnv.toUpperCase()}`; // Collection - Debia uat
					}
				} else {
					console.error('window._ENV.REACT_APP_CLIENT not defined');
				}
			} else {
				// concat with the env
				// set title
				document.title = `${appModuleName} - ${appEnv.toUpperCase()}`; // Collection - dev
			}
		} else {
			console.error('window.APP_MODULE not defined');
		}
	} catch (error) {
		console.error(error);
	}
};

/*
 * @description Compare whether the form values have been updated or not with comparing the initial form values.
 * @param {any} formValues - Formik values object
 * @param {any} initialTableData - Initial values
 * @returns A function that takes two arguments for compare equality and return a boolean value.
 */
export const isFormDirty = (
	formValues: any,
	initialTableData: any
): boolean => {
	try {
		return !_.isEqual(_.cloneDeep(formValues), _.cloneDeep(initialTableData));
	} catch (error) {
		return false;
	}
};

/**
 * @description - Generate a blob using base64 string content
 * @param {string} b64Data - Base64 string content
 * @param {string} contentType - Content type
 * @param {number} sliceSize - Slice size
 */
export const b64toBlob = (
	b64Data: string,
	contentType: string = '',
	sliceSize: number = 512
) => {
	try {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	} catch (error) {
		console.error('b64toBlob conversion failed => ', error);
		const blob = new Blob([], { type: contentType });
		return blob;
	}
};
