import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import {
  $TableTree,
  $Button,
  $Tag,
  $Popconfirm,
  $Skeleton,
  $Popover,
  $Select,
  $Search,
  $Drawer,
  $Affix,
  $PageHeader,
  $Divider,
  $Tooltip,
  $Badge,
} from "us.common/components";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "us.icons";
import { handleGoBack } from "us.helper/utility";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { userManagementActions } from "us.common/actions";
import {
  IRoleSummary,
  IUserSummaryWithExtendedFields,
} from "us.common/interfaces";
import {
  filterUserByActivityStatus,
  searchUserByNameAndEmail,
} from "./Functions";
import { ChangeUserState } from "./Repository";
import { UserDetail } from "./Components";
import { userSummary } from "us.common/reducers/UserManagement/State";
import { RootState } from "us.helper/types";

const { users, user, b2CUser } = userManagementActions;

const UserManagement: React.FC<PropsFromRedux> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;

  const {
    getUsers,
    users,
    changeUserStatus,
    deleteUser,
    deleteB2CUser,
    manageDrawer,
    resetForm,
    drawer,
  } = props;

  const { data, isLoading } = users;
  const { title, visible } = drawer;
  const [userStatus, setUserStatus] = useState<string>('active');
  const [popVisible, setPopVisible] = useState<number>(-1);
  const [dataSource, setDataSource] =
    useState<Array<IUserSummaryWithExtendedFields>>(data);

  useEffect(() => {
    getUsers && getUsers({});
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setDataSource(filterUserByActivityStatus(data, userStatus));
    }
  }, [data]);

  /**
   * @function
   * @description handle state changes
   * @param {AnyARecord}record
   */
  const handleStateChange = (record: any) => {
    try {
      const request = ChangeUserState.call(record);
      changeUserStatus && changeUserStatus(request);
    } catch (error) {}
  };

  const onOpenDrawer = (title: string, isNew: boolean, record: any) => {
    manageDrawer &&
      manageDrawer({
        title: t(`US.COMMON:MANAGE_USER.${title}`),
        isNew,
        visible: true,
        selectedUser: record,
      });
  };

  /**
   * @function
   * @description handle drawer close event
   */
  const onClose = () => {
    manageDrawer &&
      manageDrawer({
        title: "",
        isNew: true,
        visible: false,
        selectedUser: userSummary,
      });
    resetForm && resetForm({});
  };

  /**
   * @function
   * @description handle search by role name
   * @param {string} searchText search value
   * @param {string} filter filter value
   */
  const handleSearch = (searchText: string, filter: string) => {
    try {
      setDataSource(searchUserByNameAndEmail(data, searchText));
    } catch (error) {
      setDataSource(data);
    }
  };

  const content = (record: any) => (
    <div className="table-more-content">
      <div className="d-flex  flex-row more-item">
        <div className="p-1">
          <EditOutlined />
        </div>
        <div
          className="p-1"
          onClick={() => {
            setPopVisible(-1);
            onOpenDrawer("EDIT_USER", false, record);
          }}
        >
          {t("COMMON.EDIT")}
        </div>
      </div>
      <$Popconfirm
        title={t("COMMON.SURE_TO_DELETE_?")}
        onConfirm={() => {
          setPopVisible(-1);
          isAzureAD
            ? deleteUser && deleteUser(record)
            : deleteB2CUser && deleteB2CUser(record);
        }}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
      <$Divider className="popup-divider my-2" />
      <$Popconfirm
        title={`${t("US.COMMON:MANAGE_USER.ACTIVESTATECONFIRM")} ${
          record.activeState
            ? t("US.COMMON:MANAGE_USER.DEACTIVATE")
            : t("US.COMMON:MANAGE_USER.ACTIVATE")
        }?`}
        onConfirm={() => {
          setPopVisible(-1);
          handleStateChange(record);
        }}
        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
      >
        <div className="d-flex flex-column">
          <$Button type="primary" className="mb-1" size="small">
            {record.activeState
              ? t("US.COMMON:MANAGE_USER.DEACTIVATE")
              : t("US.COMMON:MANAGE_USER.ACTIVATE")}
          </$Button>
        </div>
      </$Popconfirm>
    </div>
  );

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      customRenderChild: (text: any, record: any) => {
        return (
          <$Popover
            trigger="click"
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
            visible={record.id == popVisible}
            onVisibleChange={(visible: boolean) => {
              setPopVisible(visible ? record.id : -1);
            }}
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COMMON:MANAGE_USER.USER_NAME"),
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COMMON:MANAGE_USER.STATE"),
      dataIndex: "activeStateString",
      key: "activeStateString",
      width: 200,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        return (
          <>
            {record.activeState && (
              <$Tag className="tag-status-active">
                {t("US.COMMON:MANAGE_USER.ACTIVE")}
              </$Tag>
            )}
            {!record.activeState && (
              <$Tag className="tag-status-deactive">
                {t("US.COMMON:MANAGE_USER.INACTIVE")}
              </$Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("US.COMMON:MANAGE_USER.DISPLAY_NAME"),
      dataIndex: "displayName",
      key: "displayName",
      ellipsis: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
    },
    {
      title: t("US.COMMON:MANAGE_USER.ROLE_NAME"),
      dataIndex: "userRoleName",
      key: "userRoleName",
      ellipsis: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any) => {
        return record.roles?.map(({ name, roleId }: IRoleSummary) => {
          return (
            <$Badge
              className="mr-2"
              status="default"
              text={name}
              key={roleId}
            />
          );
        });
      },
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{ }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }: any) => (
        <div className="space-content">
          <$Affix offsetTop={48}>
            <div className="page-header header-border pr-0">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    title={t("US.COMMON:MANAGE_USER.MANAGE_USERS")}
                    onBack={() => handleGoBack(history)}
                  />

                  <$Button
                    type="dashed"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => onOpenDrawer("NEW_USER", true, userSummary)}
                  >
                    {t("US.COMMON:MANAGE_USER.NEW_USER")}
                  </$Button>
                </div>

                <div className="ml-5">
                  <$Search
                    name="search-users"
                    size="small"
                    allowClear={true}
                    placeholder={t("US.COMMON:MANAGE_USER.SEARCH_USER")}
                    onChange={(e: any) =>
                      handleSearch(e.target.value, userStatus)
                    }
                    style={{ width: 200 }}
                  />

                  <$Divider type="vertical" />

                  <$Tooltip title={t("COMMON.STATUS")}>
                    <$Select
                      name="status"
                      placeholder={t("COMMON.STATUS")}
                      size="small"
                      style={{ width: 120 }}
                      options={[
                        {
                          label: t("COMMON.ALL"),
                          value: "all",
                        },
                        {
                          label: t("COMMON.ACTIVE"),
                          value: "active",
                        },
                        {
                          label: t("COMMON.INACTIVE"),
                          value: "deactivated",
                        },
                      ]}
                      onSelect={(status: string) => {
                        setFieldValue("status", status);
                        setUserStatus(status);
                        setDataSource(filterUserByActivityStatus(data, status))
                      }}
                      defaultValue={"active"}
                      allOption={false}
                    />
                  </$Tooltip>
                </div>
              </div>
            </div>
          </$Affix>

          <$Skeleton loading={isLoading} active paragraph={{ rows: 2 }}>
            <$TableTree
              data={dataSource}
              columns={columns}
              rowKey="id"
              size="small"
              className="mt-3 header-custom-tag"
              onSort={(sortData, dataSource) => sortData(dataSource)}
              onFilter={(searchData, dataSource) => searchData(dataSource)}
              filterOnType={true}
              resetOnSourceChange={true}
              bordered
              pagination={{ defaultPageSize: 20 }}
              scroll={{ x: 1200, y: "calc(100vh - 215px)" }}
              onRow={(record: any, rowIndex: any) => {
                return {
                  onDoubleClick: (event: any) => {
                    onOpenDrawer("EDIT_USER", false, record);
                  },
                };
              }}
            />
          </$Skeleton>
          <$Drawer
            title={title}
            width={800}
            visible={visible}
            onClose={onClose}
            destroyOnClose
          >
            <UserDetail />
          </$Drawer>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userManagement } = state;
  const { users, drawer } = userManagement;
  return {
    users,
    drawer,
  };
};
const mapDispatchToProps = {
  getUsers: users.get,
  changeUserStatus: user.activateOrDeactivate,
  deleteUser: user.delete,
  deleteB2CUser: b2CUser.delete,
  manageDrawer: user.openDrawer,
  resetForm: user.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(UserManagement);
