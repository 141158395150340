import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization";

interface ICurrentUser {
  acct: number;
  acr: string;
  aio: string;
  amr: Array<any>;
  app_displayname: string;
  appid: string;
  appidacr: string;
  aud: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  idtyp: string;
  ipaddr: string;
  iss: string;
  name: string;
  nbf: number;
  oid: string;
  platf: string;
  puid: string;
  rh: string;
  scp: string;
  signin_state: Array<any>;
  sub: string;
  tenant_region_scope: string;
  tid: string;
  unique_name: string;
  upn: string;
  uti: string;
  ver: string;
  xms_st: any;
  xms_tcdt: number;
}

let currentUser: ICurrentUser | any = {};
let applicationInsights: Initialization;
let reactPlugin: ReactPlugin;

const initializeAppInsight = (user: ICurrentUser | any) => {
  currentUser = user;

  const browserHistory = createBrowserHistory({ basename: "" });
  reactPlugin = new ReactPlugin();
  applicationInsights = new ApplicationInsights({
    config: {
      connectionString: window._ENV.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
      disableTelemetry: window._ENV.REACT_APP_APPINSIGHTS_DISABLE_TELEMETRY,
      enableAutoRouteTracking: false,
      disableAjaxTracking: true,
      disableFetchTracking: true,
      isStorageUseDisabled: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  applicationInsights.loadAppInsights();
};

const getCustomProperties = (currentUser: ICurrentUser) => ({
  UserID: currentUser?.oid,
  UserName: currentUser?.unique_name,
  TenantID: currentUser?.tid,
  AppID: currentUser?.appid,
  IpAddr: currentUser?.ipaddr,
});

const appInsights = {
  trackException: (msg: string) =>
    window._ENV.REACT_APP_APPINSIGHTS_ENABLE_EXCEPTION &&
    applicationInsights.trackException(
      {
        error: new Error(`Exception-${msg}`),
        severityLevel: SeverityLevel.Error,
      },
      getCustomProperties(currentUser)
    ),
  trackTrace: (msg: string) =>
    window._ENV.REACT_APP_APPINSIGHTS_ENABLE_TRACE &&
    applicationInsights.trackTrace(
      {
        message: `Trace-${msg}`,
        severityLevel: SeverityLevel.Information,
      },
      getCustomProperties(currentUser)
    ),
  trackEvent: (name: string) =>
    window._ENV.REACT_APP_APPINSIGHTS_ENABLE_EVENT &&
    applicationInsights.trackEvent(
      { name: `Event-${name}` },
      getCustomProperties(currentUser)
    ),
};

export { reactPlugin, applicationInsights, initializeAppInsight, appInsights };
