import React from "react";
import { Add } from "./Components";
import { ICreditNote } from "./Interface";
import { DefaultColumns } from "./Constants";
import PropTypes from "prop-types";
import "./CreditNote.scss";

const $C = <T extends ICreditNote>({
  creditNote,
  invoiceDetails,
  orderlines,
  tableColumns = DefaultColumns,
  isViewOnly = false,
  isDefault = true,
  isInvoiceNo = true,
  isSaving = false,
  isExporting = false,
  isLoading = false,
  isExportVisible = false,
  onClose,
  onSave,
  onExport,
  onReset,
  onBlurInput,
  onClearInput,
  invoiceNo=''
}: T) => {
  return (
    <div className="credit-note">
      <Add
        creditNote={creditNote}
        invoiceDetails={invoiceDetails}
        orderlines={orderlines}
        tableColumns={tableColumns}
        isDefault={isDefault}
        isViewOnly={isViewOnly}
        isInvoiceNo={isInvoiceNo}
        isSaving={isSaving}
        isExporting={isExporting}
        isLoading={isLoading}
        isExportVisible={isExportVisible}
        onSave={onSave}
        onExport={onExport}
        onReset={onReset}
        onClose={onClose}
        onBlurInput={onBlurInput}
        onClearInput={onClearInput}
        invoiceNo={invoiceNo}
      />
    </div>
  );
};

$C.propTypes = {
  creditNote: PropTypes.any,
  invoiceDetails: PropTypes.any,
  orderlines: PropTypes.any,
  isViewOnly: PropTypes.bool,
  isDefault: PropTypes.bool,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  isExportVisible: PropTypes.bool,
  isExporting: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onExport: PropTypes.func,
  onBlurInput: PropTypes.func,
  onClearInput: PropTypes.func,
};

export const $CreditNote = $C;
