import { ICreditorCompany } from "us.common/interfaces";

/**
 * @function
 * @description get Creditor Group Ids
 * @param {Array<ICreditorCompany>} creditorCompanies creditor group ids
 * @param {Array<string | number | boolean>} checkedList 
 * @returns {Array<string | number>}
 */
export const getCreditorGroupIds = (
    creditorCompanies: Array<ICreditorCompany>,
    checkedList: Array<string | number | boolean>
): Array<string | number> => {
    try {
        const group: Array<string | number> = [];
        checkedList.forEach((item: string | number | boolean) => {
            const groupId =
                creditorCompanies.find(({ id }: ICreditorCompany) => id == item)
                    ?.groupId ?? 0;
            group.push(groupId);
        });
        return group;
    } catch (error) {
        return [];
    }
};