import {
  doesDebtorCaseInclude,
  doesARInclude,
  doesDebtorInclude,
} from "us.common/components/MainSearch/Functions";
import {
  IDebtorSearchResult,
  IDebtorARSearchResult,
  IDebtorCaseSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { IFilterValues } from "../Interfaces";

/**
 * @function
 * @description To get the debtor search results filtered
 * @param data Debtor search results - taken directly from the redux state
 * @param filterValues The formik filter key-values related to debtor
 * @returns Filtered list of Debtor search results based on the filter key-values
 */
export const getDebtorDataFiltered = (
  data: IDebtorSearchResult[],
  filterValues: IFilterValues
): IDebtorSearchResult[] | [] => {
  try {
    let arDetailHolder: IDebtorARSearchResult[];
    let caseDetailHolder: IDebtorCaseSearchResult[];
    let debtorOpenCaseCount: number;
    let debtorCloseCaseCount: number;
    const filteredData = data.flatMap((debtorData) => {
      [debtorOpenCaseCount, debtorCloseCaseCount] = [0, 0];
      arDetailHolder = debtorData.arDetails.flatMap((ardata) => {
        caseDetailHolder = ardata.caseDetails.flatMap((caseData) => {
          if (doesDebtorCaseInclude(caseData, filterValues)) {
            caseData.isOpen ? debtorOpenCaseCount++ : debtorCloseCaseCount++;
            return [caseData];
          } else {
            return [];
          }
        });
        if (
          caseDetailHolder.length > 0 &&
          doesARInclude(ardata, filterValues)
        ) {
          return [
            {
              ...ardata,
              caseDetails: caseDetailHolder,
            },
          ];
        } else {
          return [];
        }
      });
      if (
        arDetailHolder.length > 0 &&
        doesDebtorInclude(debtorData, filterValues)
      ) {
        return [
          {
            ...debtorData,
            arDetails: arDetailHolder,
            openCount: debtorOpenCaseCount,
            closeCount: debtorCloseCaseCount,
          },
        ];
      } else {
        return [];
      }
    });
    return filteredData.map((debtor) => ({
      ...debtor,
      isARCollapsed:
        filteredData.length === 1 && debtor.arDetails.length === 1
          ? false
          : debtor.isARCollapsed,
    }));
  } catch (e) {
    return [];
  }
};
