import { IAddNewCourtItem } from "../../Interfaces";
import { getPostalCodes } from "us.common/functions";

export function AddNewCourtItem (this: any): IAddNewCourtItem {
    const { courtType, courtName, address1, address2, postalCode, selectedPostalCodes, telephone, orgNo, bankAccountNo, postalId, courtItemId} = this
    return {
        courtItemId: courtItemId != undefined ? courtItemId : 0,
        courtTypeId: courtType,
        courtName,
        address1,
        address2,
        postalId: postalId != undefined ? postalId : 0,
        postalCode,
        countryCode: null,
        telephone,
        orgNo,
        bankAccountNo,
        relavantPostalCodes: getPostalCodes(selectedPostalCodes),
    }
}
