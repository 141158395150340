import { MainCriterias } from "us.common/constants";
import { ISortMenuBundler } from "../Interfaces";

/**
 * @function
 * @description To get all the sort menu items for all the four tabs
 * @returns All the sort menu items
 */
 export const sortMenuBundle = (): ISortMenuBundler[] => [
  {
    displayKey: "CASE_NO",
    tabOwnerInfo: [
      { matchingObjectProperty: "exCaseNo", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "exCaseNo", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "INVOICE_NUMBER",
    tabOwnerInfo: [{ matchingObjectProperty: "invoiceNumber", tab: MainCriterias.INVOICE }],
  },
  {
    displayKey: "MAIN_AMOUNT",
    tabOwnerInfo: [
      { matchingObjectProperty: "mainAmount", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "mainAmount", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "BALANCE",
    tabOwnerInfo: [
      { matchingObjectProperty: "balance", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "balance", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "BIRTHDATE",
    tabOwnerInfo: [
      { matchingObjectProperty: "birthDate", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "birthDate", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "DEBTOR_NUMBER",
    tabOwnerInfo: [
      { matchingObjectProperty: "debtorNo", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "debtorNo", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "DEBTOR_NAME",
    tabOwnerInfo: [
      { matchingObjectProperty: "debtorName", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "debtorName", tab: MainCriterias.CASE },
      { matchingObjectProperty: "debtorName", tab: MainCriterias.DEBTOR },
    ],
  },
  {
    displayKey: "CREDITOR_NUMBER",
    tabOwnerInfo: [
      { matchingObjectProperty: "creditorNo", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "creditorNo", tab: MainCriterias.CASE },
      { matchingObjectProperty: "creditorNumber", tab: MainCriterias.CREDITOR },
      { matchingObjectProperty: "creditorNumber", tab: MainCriterias.DEBTOR },
    ],
  },
  {
    displayKey: "CREDITOR_NAME",
    tabOwnerInfo: [
      { matchingObjectProperty: "creditorName", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "creditorName", tab: MainCriterias.CASE },
      { matchingObjectProperty: "creditorName", tab: MainCriterias.CREDITOR },
      { matchingObjectProperty: "creditorName", tab: MainCriterias.DEBTOR },
    ],
  },
  {
    displayKey: "PHONE",
    tabOwnerInfo: [
      { matchingObjectProperty: "phone", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "phone", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "ADDRESS",
    tabOwnerInfo: [
      { matchingObjectProperty: "address", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "address", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "LAST_MESSAGE_ACTIVITY",
    tabOwnerInfo: [
      { matchingObjectProperty: "lastMessageActivityName", tab: MainCriterias.INVOICE },
      { matchingObjectProperty: "lastMessageActivityName", tab: MainCriterias.CASE },
    ],
  },
  {
    displayKey: "CREDITOR_GROUP_NAME",
    tabOwnerInfo: [{ matchingObjectProperty: "groupName", tab: MainCriterias.CREDITOR }],
  },
  {
    displayKey: "CREDITOR_GROUP_NUMBER",
    tabOwnerInfo: [{ matchingObjectProperty: "groupId", tab: MainCriterias.CREDITOR }],
  },
  {
    displayKey: "TOTAL_BALANCE",
    tabOwnerInfo: [{ matchingObjectProperty: "totalCaseBalance", tab: MainCriterias.DEBTOR }],
  },
];
