import { GDPRCommentAction } from "us.collection/actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as API from "us.collection/services";
import { AuthorizationLevel, GDPRComment } from "us.collection/constants";
import { $MessageBox } from "us.common/components";
// import * as Actions from "us.collection.case/actions";
import { CaseType } from "us.helper/types/enums";
import { IRootState } from "us.collection/interfaces";
import { UserAuthorizationAction } from "us.common/actions";
import { IUserProfile } from "us.common/reducers/UserAuthorization/Interfaces";

// const {
//   DebtorWidgetActions,
//   CaseInfoWidgetActions,
//   AccountSummeryWidgetActions,
// } = Actions;

// const { debtorWidget: debtorActions } = DebtorWidgetActions;
// const { caseInformation: caseInfoActions } = CaseInfoWidgetActions;
// const { accountSummery: acSummeryActions } = AccountSummeryWidgetActions;

const getProfile = (state: IRootState) =>
  state.AuthorizationProfile.authorizationProfile;

const { userAuthorizationProfile } = UserAuthorizationAction;

const { GDPRAuthorityComment } = API.GDPRAuthority;

const { gdprComment } = GDPRCommentAction;

const GdprComment = {
  save: function* (action: any) {
    const params = action.payload?.data;
    const {
      caseId,
      entityType,
      creditorEntNumber,
      debtorId,
      caseNo,
      debtorEntNumber,
    } = params.metadata ?? {};
    try {
      const { data, status } = yield call(
        GDPRAuthorityComment.save,
        params.requestBody
      );
      if (status === 201) {
        yield put(gdprComment.success(data));

        const authorizeProfile: IUserProfile = yield select(getProfile);
        if (
          authorizeProfile.accessAuthorityLevel ==
          AuthorizationLevel.CAN_DISABLE
        ) {
          yield put(
            userAuthorizationProfile.success({
              ...authorizeProfile,
              isCommentEnable: params.requestBody?.isCommentEnable,
            })
          );
        }

        // After metadata call ------------------------------
        // if (caseId && caseId > 0) {
        //   if (caseInfoActions.get) {
        //     yield put(caseInfoActions.get({ caseId }));
        //   }
        //   if (acSummeryActions.get) {
        //     yield put(
        //       acSummeryActions.get({
        //         CaseNumber: caseId,
        //         EntityType: entityType === CaseType.S ? "Subcase" : "Case",
        //         EntityId: caseId,
        //       })
        //     );
        //   }
        //   if (creditorEntNumber && debtorId && entityType) {
        //     if (debtorActions.get) {
        //       yield put(
        //         debtorActions.get({
        //           otherPartyBelongsType: entityType,
        //           otherPartyEntityId: caseId,
        //           creditorEntNo: creditorEntNumber,
        //           debtorEntRoleId: debtorId,
        //           caseId,
        //           DebtorEntNo: debtorEntNumber,
        //           CaseNo: caseNo,
        //           CaseType: entityType === CaseType.S ? "Subcase" : "Case",
        //         })
        //       );
        //     }
        //   }
        // }
        //-------------------------------------------------
      } else {
        $MessageBox(
          "error",
          "US.COLLECTION.LAYOUT:CASE.ACCESS_AUTHORITY_COMMENT_SAVE_FAIL",
          "",
          ""
        );
        yield put(gdprComment.fail(data));
      }
    } catch (error) {
      yield put(gdprComment.fail(error as any));
    }
  },
};

export default [
  takeLatest(GDPRComment.ADD_GDPR_COMMENT_START, GdprComment.save),
];
