import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { onLogout } from '../../actions/authActions';

interface ILogoutProps {
    onLogout: any
}

const logout: React.SFC<ILogoutProps> = (props) => {
  const { onLogout } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <Redirect to="/login" />;
};

const mapDispatchToProps = {
    onLogout,
  };

  
export default connect(
  null,
  mapDispatchToProps
)(logout);
