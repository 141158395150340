import { entityDetail, postalCode } from './Entity'

export const initialState = {
    entity: entityDetail,
    isFetching: false,
    postalAreas: [],
    municiples: [],
    selectedPostalAreaDetail: {},
    selectedMunicipality: [],
    exisitingCode: postalCode,
    isSaveSuccess: false,
    isExisitingCode: false,
}