import React from "react";
import { Input, Form } from "antd";
import { useField } from "formik";
import * as Utility from "../utility";
import { IInputParams } from "us.common/components/antd/Interfaces";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./InputAsync.scss";

const $I = React.forwardRef(({
  name,
  label,
  placeholder,
  type,
  size,
  className,
  disabled,
  allowClear,
  required,
  suffix,
  prefix,
  maxLength,
  onKeyDown,
  onBlur,
  onChange,
  addonBefore,
  addonAfter,
  onPressEnter,
  max,
  min,
  style,
  tabIndex,
  dataTestid,
  autoFocus,
  onFocus,
  formitem,
  value
}: IInputParams, ref: any) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();
  
  return (
    <Form.Item
      label={label}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
      required={typeof required == 'undefined' ? false : true}
      extra={formitem?.extra}
      className="input-number-hide-arrows"
    >
      <Input
        {...field}
        placeholder={placeholder}
        type={type}
        style={style}
        max={max}
        min={min}
        ref={ref}
        size={size}
        tabIndex={tabIndex}
        disabled={disabled}
        className={className}
        addonBefore={addonBefore} 
        addonAfter={addonAfter}
        suffix={suffix}
        prefix={prefix}
        onFocus={onFocus}
        data-testid={dataTestid}
        maxLength={maxLength}
        allowClear={allowClear}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onPressEnter={onPressEnter}
        onBlur={(e: any) => {
          onBlur && onBlur(e);
          field.onBlur(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          typeof onChange == 'function'
            ? onChange(e)
            : helpers?.setValue(e.target.value);
        }}
        value={value}
      />
    </Form.Item>
  );
});

$I.propTypes = {
  // name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  onPressEnter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $InputWithValue = $I;
