import React from "react";
import { NoticeType } from "antd/lib/message";
import { $Message } from "us.common/components/antd";
import { Trans } from "react-i18next";

export default (
  type: NoticeType,
  title: string,
  description: any,
  key: string,
  values?: any
) => {
  $Message[type]({
    content: <Trans i18nKey={title} values={values} />,
    duration: 5,
    key,
  });
};
