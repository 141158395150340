import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  $Button,
  $DateLabel,
  $Drawer,
  $Popconfirm,
  $Popover,
  $Skeleton,
  $TableTree,
} from "us.common/components";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "us.icons";
import { AddNonExecutionDay } from "./Components";
import { INonProductionDay } from "us.collection.admin/interfaces";
import { getRedDayInitialValues } from "us.collection.admin/functions";
import { connect, ConnectedProps } from "react-redux";
import { nonProductionDays_New } from "us.collection.admin/actions";
import { RootState } from "us.helper/types";
import { getDrawerTitle, isNonProductionDateToday, isPreviousOrCurrentDate  } from "./Functions";

const { redDays } = nonProductionDays_New;

/**
 * @description Non Execution Day Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const NonExecutionDay: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { removeRedDay, selectedCriteria, redDays } = props;
  const { isFetching } = redDays;
  const [drawerDetail, setDrawerDetail] = useState<{
    isVisible: boolean;
    values: INonProductionDay;
  }>({
    isVisible: false,
    values: getRedDayInitialValues(selectedCriteria),
  });

  /**
   * @function
   * @description remove red days
   * @param {any} record
   */
  const remove = (record: any) => {
    removeRedDay && removeRedDay(record, selectedCriteria);
  };

  const content = (record: any, index: number) => (
    <div className="table-more-content">
      {!record?.isFixedDate && 
        <div className="d-flex  flex-row more-item">
          <div className="p-1">
            <EditOutlined />
          </div>
          <div className="p-1" onClick={() => handleDrawer(true, record)}>
            {t("US.COMMON:COMMON.EDIT")}
          </div>
        </div>
      }
      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.POP_CONFIRMATION")}
        onConfirm={() => remove(record)}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          aria-disabled={isNonProductionDateToday(record?.date)}
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      customFilter: false,
      customRenderChild: (text: any, record: any, index: number) => {
        return (
          !isPreviousOrCurrentDate(record?.date) && (
            <$Popover
              placement="rightTop"
              content={content(record, index)}
              destroyTooltipOnHide
            >
              <$Button icon={<MoreOutlined />} size="small" />
            </$Popover>
          )
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.DATE"),
      dataIndex: "date",
      key: "date",
      width: "132px",
      customRenderChild: (text: any, record: any, index: any) => {
        return (
          <span>
            <$DateLabel value={record.date} />
          </span>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.REASON"),
      dataIndex: "reason",
      key: "reason",
      customRenderChild: (text: any, record: any, index: any) => {
        return <span>{record.reason}</span>;
      },
    },
  ];

  /**
   * @function
   * @description handle drawer
   * @param {boolean} open
   * @param {any} values
   */
  const handleDrawer = (
    open: boolean,
    values = getRedDayInitialValues(selectedCriteria)
  ) => {
    setDrawerDetail({
      isVisible: open,
      values,
    });
  };

  return (
    <div>
      <div className="mb-3">
        <$Button
          className=""
          type="dashed"
          size="small"
          icon={<PlusOutlined />}
          onClick={() => handleDrawer(true)}
        >
          {t("US.COLLECTION.COMMON:COMMON.ADD_NEW")}
        </$Button>
      </div>
      <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
        <$TableTree
          rowKey="key"
          className="mt-2"
          data={redDays[selectedCriteria]}
          columns={columns}
          pagination={false}
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {
                handleDrawer(true, record);
              },
            };
          }}
          bordered={false}
          onSort={(sortData, dataSource) => {
            return sortData(dataSource);
          }}
          onFilter={(searchData, dataSource) => {
            return searchData(dataSource);
          }}
          filterOnType={true}
          resetOnSourceChange={true}
        />
      </$Skeleton>

      <$Drawer
        title={getDrawerTitle(drawerDetail.values)}
        width={450}
        visible={drawerDetail.isVisible}
        onClose={() => handleDrawer(false)}
        destroyOnClose
      >
        <AddNonExecutionDay
          selectedCriteria={selectedCriteria}
          values={drawerDetail.values}
          onClose={handleDrawer}
        />
      </$Drawer>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { nonProductionDays } = state;
  const { redDays, selectedCriteria } = nonProductionDays;
  return { redDays, selectedCriteria };
};

const mapDispatchToProps = {
  removeRedDay: redDays.remove,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(NonExecutionDay);
