import axios from "axios";
import { Notification } from "us.common/components";
import * as Azure from "us.helper/azure";

/**
 * Call server using http method.
 *
 * @param {String} url  URL
 * @param {String} method  Method
 * @param {Object} body request body
 *
 * @returns {Promise} response data
 */

let headers = {
  "Content-Type": "application/json",
  "Ocp-Apim-Subscription-Key": "2baeb10b57134fa8af644e6d632550db",
};

const axiosInstance = axios.create({
  headers,
});

const errorInterceptor = (error: any) => {
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      break;

    case 401:
      if (error.response.data?.errors) {
        Notification(
          "error",
          error?.response?.status.toString(),
          error.response.data?.errors,
          ""
        );
      } else {
        Notification(
          "error",
          error?.response?.status.toString(),
          error.response.data?.message,
          ""
        );
      }
      break;

    default:
      console.error(error.response.status, error.message);
  }
  // return Promise.reject(error);
  return error.response;
};

const responseInterceptor = (response: any) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }
  return response;
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

const httpAzure = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS" | "PATCH",
  body?: any
) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Ocp-Apim-Subscription-Key",
    "2baeb10b57134fa8af644e6d632550db"
  );

  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const token = auth.getToken();

  let headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": "2baeb10b57134fa8af644e6d632550db",
    Authorization: `Bearer ${token}`,
  };

  let data = [];

  try {
    const response = await axiosInstance.request({
      url: url,
      method: method,
      headers: headers,
      data: method == "GET" ? null : JSON.stringify(body),
      params: method == "GET" ? body : null,
      timeout: 20000,
    });
    let dataWrapped = await response.data;
    data = dataWrapped.result != undefined ? dataWrapped.result : dataWrapped;
    console.error(data);
  } catch (e) {
    console.error(e);
  }
  return data;
  /*
  var myHeaders = new Headers();
  myHeaders.append("Ocp-Apim-Subscription-Key", "6489acfc491c4448b532c48cc8379840");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch("https://apim-unicornbox-dev.azure-api.net/serviceapi/v1/basrUrl/case/2432", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));*/
};

export default httpAzure;
