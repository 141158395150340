import { SearchByLabels } from "us.common/components/SelectCreditors/Constants";

/**
 * @function
 * @description - get data object required to get creditors
 * @param {*} this - parameter object
 * @return {*} - calling object 
 */
export function GetCreditorsRequestObject(this: any): any {
  if (this.searchValue?.includes(SearchByLabels.CREDITOR_NAME)) {
    return {
      groupId: this.formattedGroupId,
      creditorName: this.searchText,
      type: this.groupType,
      searchText: this.searchText,
    };
  } else if (this.searchValue?.includes(SearchByLabels.CREDITOR_NO)) {
    return {
      groupId: this.formattedGroupId,
      creditorNo: this.searchText,
      type: this.groupType,
      searchText: this.searchText,
    };
  } else {
    return {
      groupId: this.formattedGroupId,
      type: this.groupType,
      searchText: this.searchText,
    };
  }
}
