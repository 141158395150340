import { MainCriterias } from "us.common/constants";
import { ISearchQuery } from "us.common/interfaces";

/**
 * @function
 * @description To check if the tab we are interested is visible based on the URL search query value.
 * @param tabValue The search main criteria - which we use to get the open tab
 * @param parsedQueryValue The URL search query value parsed
 * @returns The boolean value if the tab we are interested is visible
 */
export const isTabVisible = (tabValue: MainCriterias, parsedQueryValue: ISearchQuery): boolean => {
  if (!parsedQueryValue.cat) {
    return true;
  } else if (parsedQueryValue.cat === MainCriterias.INVOICE_REF) {
    return tabValue === MainCriterias.INVOICE || tabValue === MainCriterias.CASE;
  } else {
    return parsedQueryValue.cat === tabValue;
  }
};
