/**
 * @function
 * @description - Debtor selection table data object create
 * @param list - Debtor search end point responce
 * @returns - Debtors detail
 */

export const getDebtorDetails = (list: any): Array<any> => {
  try {
    const debtorList = Array<any>();
    list?.map((debtor: any) => {
      const {
        debtorEntRoleId,
        debtorEntNo,
        debtorName,
        address1,
        address2,
        zipCode,
        zipName,
        nin,
        arDetails,
      } = debtor ?? {};
      arDetails?.map((ar: any) => {
        debtorList.push({
          debtorEntRoleId,
          debtorEntNo,
          debtorName,
          address1,
          address2,
          zipCode,
          zipName,
          nin,
          custId: ar?.custId ?? "",
          arNo: ar?.arNo,
          debtorType: ar?.debtorType,
          key:Math.random(),
          debtorColoum: `${ar?.custId} ${debtorName} ${address1} ${address2}`
        });
      });
    });
    return debtorList.sort((a: any, b:any) => b.custId - a.custId);
  } catch (e) {
    return [];
  }
};
