import _ from "lodash";
import {
  INotification,
  INotifications,
} from "us.common/reducers/Notification/Interfaces";
import { NotificationUpdateType } from "us.common/constants/Component/Notification";

/**
 * @function
 * @description - Handles notification payload
 * @param previousState - Existing state
 * @param payload - API response data
 * @returns Merged or fresh notifications
 */

export const getNotificationPayload = (
  previousState: { scroll: boolean; data: Array<INotification> },
  payload: Array<INotification>
): Array<INotification> => {
  const { scroll, data } = previousState;
  try {
    if (Array.isArray(payload)) {
      return scroll
        ? _.uniqBy([...data, ...(payload ?? [])], "notificationId")
        : payload ?? [];
    } else {
      return data;
    }
  } catch (e) {
    return data;
  }
};

/**
 * @function
 * @description - Remove notifications
 * @param {INotifications} previousState - Previous notification state.
 * @param payload - Notification id and action type.
 * @returns Object with notifications and unread count.
 */
export const removeNotification = (
  previousState: INotifications,
  payload: any
): { data: Array<INotification>; unReadCount: number } => {
  const { data, unReadCount } = previousState;
  const { notificationId, type } = payload ?? {};
  try {
    if (type == NotificationUpdateType.DELETE) {
      if (notificationId !== -1) {
        return {
          data: data.filter(
            (notification: INotification) =>
              notification?.notificationId !== notificationId
          ),
          unReadCount: Number(payload?.data?.unRead),
        };
      } else {
        return { data: [], unReadCount: 0 };
      }
    } else {
      const updatedNotifications = data.map((notification: INotification) => {
        return notification.notificationId == notificationId
          ? { ...notification, isSeen: true }
          : notification;
      });
      return {
        data: updatedNotifications,
        unReadCount: Number(payload?.data?.unRead),
      };
    }
  } catch (e) {
    return { data, unReadCount };
  }
};

/**
 * @function
 * @description - Add or remove pending notification events.
 * @param {Array} pendingEvents - Pending notifications
 * @param {Number} eventId - Notification id
 * @returns Updated pending notifications
 */
export const updatePendingEvents = (
  pendingEvents: Array<any>,
  eventId: string,
  isInProgress: boolean
): Array<any> => {
  try {
    if (eventId) {
      if (pendingEvents.includes(eventId) && !isInProgress) {
        return pendingEvents.filter((id: any) => id !== eventId);
      } else {
        return [...pendingEvents, eventId];
      }
    } else {
      return pendingEvents;
    }
  } catch (e) {
    return pendingEvents;
  }
};
