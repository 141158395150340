const initialState = {
    currentLanguage: '',
    currentCurrency: '',
    currentDateFormat: '',
    error: null,
}

export default (state = initialState, action: any) => {
    let newState = state;

    switch (action.type) {

        case 'CHANGE_LANGUAGE':
            console.log('changing the language to---------> ', action);
            newState = {
                ...state,
                currentLanguage: action.language,
                currentCurrency: action.currency,
                currentDateFormat: action.dateFormat,
                error: null
            }
            break;

        default:
            break;
    }
    return newState;
};