import adb2cAuth from './b2c';
import decodeJWT from 'jwt-decode';

interface IJWTToken {
	[key: string]: string;
}
class AuthB2C {
	isLoggedIn() {
		if (adb2cAuth.getAccessToken()) {
			return true;
		}
		return false;
	}
	logout() {
		adb2cAuth.signOut();
	}
	getToken() {
		return adb2cAuth.getAccessToken();
	}
	currentUser() {
		const decoded = decodeJWT<IJWTToken>(adb2cAuth.getAccessToken());
		return decoded;
	}
}
export default AuthB2C;