import React from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { ReactComponent as ImgUnauthorized } from "./403.svg";
import { useMsal } from '@azure/msal-react';
import { BrowserUtils } from '@azure/msal-browser';

interface IUnauthorized {}

const { $Result, $Button } = Components;

const Unauthorized: React.FC<IUnauthorized> = (props) => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  /**
   * @description Logging out of the application
   */
  const logout = () => {
    instance.logoutRedirect({
			account: instance.getActiveAccount(),
			onRedirectNavigate: () => !BrowserUtils.isInIframe(),
		});
  }

  return (
    <>
      <$Result
        className="mt-4"
        icon={<ImgUnauthorized />}
        title={t("US.COMMON:UNAUTHORIZED.WE_ARE_SORRY") + "..."}
        subTitle={
          <span>
            {t(
              "US.COMMON:UNAUTHORIZED.THE_PAGE_YOU_ARE_TRYING_TO_ACCESS_HAS_RESTRICTED_ACCESS"
            )}
            . <br />{" "}
            {t(
              "US.COMMON:UNAUTHORIZED.PLEASE_REFER_TO_YOUR_SYSTEM_ADMINISTRATOR"
            )}
          </span>
        }
        extra={
          <$Button type="link" onClick={() => logout()}>
            {t("US.COMMON:UNAUTHORIZED.SIGN_OUT")}
          </$Button>
        }
      />
    </>
  );
};

export default Unauthorized;
