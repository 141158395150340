import { AssignType } from "us.collection.admin/constants";
import * as Yup from "yup";

interface ISaveQuery extends Yup.MixedSchema {
  followupName: string;
  followupDisplayName: boolean;
  followupCategory: number;
  assignType: string;
  assignTo: string;
  assignToList: Array<any>;
  activity: number;
  activitiesList: Array<any>;
  description: string;
}
const SaveQuerySchema = Yup.object<ISaveQuery>().shape({
  followupName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  followupDisplayName: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  followupCategory: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  assignType: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
  assignTo: Yup.string().when(["assignType","assignToList"], (assignType: string, assignToList: [], Yup: any) => {
    return Yup.test(
      "assignTo",
      "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_AN_ASSIGNEE",
      (assignTo: any) => assignType == AssignType.ALL || assignToList.length > 0 || assignTo
    );
  }),
  assignToList: Yup.array()
    .of(
      Yup.object().shape({
        assignId: Yup.number(),
        assignTo: Yup.string(),
      })
    )
    .when("assignType", (assignType: string, Yup: any) => {
      return Yup.test(
        "assignToList",
        "US.COLLECTION.VALIDATIONS:INVALID.THERE_SHOULD_BE_AT_LEAST_AN_ONE_ENTITY",
        (assignToList: any) => assignType == AssignType.ALL || assignToList.length > 0
      );
    }),
  description: Yup.string().required(
    "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
  ),
});

export default SaveQuerySchema;
