import { SettingType } from 'us.collection.admin/interfaces';

/**
 * @description Check whether a given type id included in the menu or not
 * @param {Array<SettingType>} settingTypes - List of system setting types
 * @param {string | null} typeId - The URL params typeId
 * @returns {SystemSetting | undefined} - If has type id in the menu return `true` else `false`
 */
export const hasInTheMenu = (
	settingTypes: Array<SettingType>,
	typeId: string | null
): boolean | {} => {
	try {
		if (typeId && typeof typeId === 'string') {
			return settingTypes.some(
				(setting: SettingType) =>
					setting.typeId === parseInt(typeId)
			);
		}
		return false;
	} catch (error) {
		return false;
	}
};
