import React, { useEffect, useState } from 'react';
import { InputNumber, Form } from 'antd';
import { IntlCurrency } from 'us.common/functions';
import { useSelector } from 'react-redux';

export default ({ value }: { value: number }) => {
  const { currentLanguage, currentCurrency } = useSelector(
    (state: any) => state.common
  );

  const handleValue = (): string => {
    try {
      return value.toFixed(2);
    } catch (e) {
      return '0';
    }
  };

  return (
    <React.Fragment>
      {IntlCurrency(handleValue(), currentLanguage, currentCurrency).trim()}
    </React.Fragment>
  );
};
