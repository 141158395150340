import { IAPIAction } from 'us.collection/interfaces';
import { SystemSettings } from 'us.collection.admin/constants/Actions';
import { ISystemSettingsAction } from 'us.collection.admin/interfaces';

const {
	GET_SYSTEM_SETTINGS,
	GET_SYSTEM_SETTINGS_SUCCESS,
	GET_SYSTEM_SETTINGS_FAIL,

	ADD_SYSTEM_SETTING,
	ADD_SYSTEM_SETTING_SUCCESS,
	ADD_SYSTEM_SETTING_FAIL,

	UPDATE_SYSTEM_SETTING,
	UPDATE_SYSTEM_SETTING_SUCCESS,
	UPDATE_SYSTEM_SETTING_FAIL,

	SEARCH_SYSTEM_SETTING,

	ADD_NEW_SYSTEM_SETTING_TYPE,
	RESET_SYSTEM_SETTING_TYPES,
} = SystemSettings;

export const systemSettings: Readonly<IAPIAction & ISystemSettingsAction> = {
	settings: {
		get: (data) => ({
			type: GET_SYSTEM_SETTINGS,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: GET_SYSTEM_SETTINGS_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: GET_SYSTEM_SETTINGS_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
		filter: (data) => ({
			type: SEARCH_SYSTEM_SETTING,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		resetSettingTypes: () => ({
			type: RESET_SYSTEM_SETTING_TYPES,
		}),
	},
	setting: {
		update: (data) => ({
			type: UPDATE_SYSTEM_SETTING,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: UPDATE_SYSTEM_SETTING_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: UPDATE_SYSTEM_SETTING_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
		addNewSettingType: (data) => ({
			type: ADD_NEW_SYSTEM_SETTING_TYPE,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
	},
	newSetting: {
		save: (data) => ({
			type: ADD_SYSTEM_SETTING,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: ADD_SYSTEM_SETTING_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: ADD_SYSTEM_SETTING_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
};
