import _ from "lodash";
import { Days } from "../Components/ExecutionProfile/Constants";

export function SaveExecutionProfile(this: any) {
  const { activityExecutionProfileId, profileName, schedules } = this;

  const monday = _.find(schedules, { day: Days.MONDAY });
  const tuesday = _.find(schedules, { day: Days.TUESDAY });
  const wednesday = _.find(schedules, { day: Days.WEDNESDAY });
  const thursday = _.find(schedules, { day: Days.THURSDAY });
  const friday = _.find(schedules, { day: Days.FRIDAY });
  const saturday = _.find(schedules, { day: Days.SATURDAY });
  const sunday = _.find(schedules, { day: Days.SUNDAY });

  return {
    profileId: activityExecutionProfileId,
    profileName,
    isMondayEnabled: monday?.isDayEnabled,
    mondayFromTime: monday?.timeSlot.startTime,
    mondayToTime: monday?.timeSlot.endTime,
    isTuesdayEnabled: tuesday?.isDayEnabled,
    tuesdayFromTime: tuesday?.timeSlot.startTime,
    tuesdayToTime: tuesday?.timeSlot.endTime,
    isWednesdayEnabled: wednesday?.isDayEnabled,
    wednesdayFromTime: wednesday?.timeSlot.startTime,
    wednesdayToTime: wednesday?.timeSlot.endTime,
    isThursdayEnabled: thursday?.isDayEnabled,
    thursdayFromTime: thursday?.timeSlot.startTime,
    thursdayToTime: thursday?.timeSlot.endTime,
    isFridayEnabled: friday?.isDayEnabled,
    fridayFromTime: friday?.timeSlot.startTime,
    fridayToTime: friday?.timeSlot.endTime,
    isSaturdayEnabled: saturday?.isDayEnabled,
    saturdayFromTime: saturday?.timeSlot.startTime,
    saturdayToTime: saturday?.timeSlot.endTime,
    isSundayEnabled: sunday?.isDayEnabled,
    sundayFromTime: sunday?.timeSlot.startTime,
    sundayToTime: sunday?.timeSlot.endTime,
    isDeleted: false,
  };
}
