import { Workflow } from 'us.collection/constants'
import { IAPIAction } from "us.collection/interfaces";

export const WorkflowAction: Readonly<IAPIAction> = {
    RetryWorkflow: {
        get: (data) => ({
          type: Workflow.RETRY_WORKFLOW_START,
          payload: {
            data,
            isLoading: true,
          },
        }),
        success: (data) => ({
          type: Workflow.RETRY_WORKFLOW_SUCCESS,
          payload: {
            data: data,
            isLoading: false,
          },
        }),
        fail: (error) => ({
          type: Workflow.RETRY_WORKFLOW_FAIL,
          payload: {
            error: error,
            isLoading: false,
          },
        }),
    },
}