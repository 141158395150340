import { UserAuthorization } from "us.common/constants";
import { initialState } from "./State";

export default (state = initialState, action: any) => {
  switch (action.type) {
    case UserAuthorization.GET_AUTHORIZATION_PROFILE_START:
      return Object.assign({}, state, {
        authorizationProfile: undefined,
      });
    case UserAuthorization.GET_AUTHORIZATION_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        authorizationProfile: action.payload.data,
      });
    case UserAuthorization.GET_AUTHORIZATION_PROFILE_FAIL:
      return Object.assign({}, state, {
        authorizationProfile: state.authorizationProfile,
      });
    default:
      return state;
  }
};
