import React, {useState} from "react";
import { Formik } from "formik";
import {
  IRootState,
  ISelectActivity,
  ISortOrder,
} from "us.collection/interfaces";
import * as Actions from "us.collection/actions";
import { connect } from "react-redux";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import {
  IOnFilter,
  IOnSort,
  ITableTreeCell,
  ITableTreeColumns,
} from "us.common/components/antd/TableTree";

const { $Skeleton, $Radio, $Tooltip, $TableTree } = Common.Components;

const SelectActivity: React.FC<ISelectActivity> = (props) => {
  const { t } = useTranslation();
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });
  const { close, activities, setFilter, handleSearchData } = props;

  const columns: any = [
    {
      title: "",
      dataIndex: "group",
      key: "group",
      className: "group-td",
      width: 24,
      customRenderParent: (text: any, record: any, index: number) => {
        return {
          children: text,
          key: index,
          props: {
            colSpan: 4,
          },
        };
      },
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <></>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.CODE"),
      dataIndex: "activityCode",
      key: "activityCode",
      width: 120,
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.activityCode}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.NAME"),
      dataIndex: "displayName",
      key: "displayName",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.displayName}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.description}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
  ];
  const setSelectedValue = (data: any) => {
    handleSearchData(data?.displayName);
    close();
  };

  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(
      dataSource.map((i: any) => {
        return {
          ...i,
          children: sortData(i.children),
        };
      })
    );
  };

  const handleFilter: IOnFilter = (searchData, dataSource) => {
    let result: any[] = [];
    dataSource.flatMap((i: any) => {
      searchData(i?.children)?.length != 0 &&
        result.push({
          ...i,
          children:
            searchData(i?.children)?.length == 0 ? [] : searchData(i?.children),
        });
    });
    return result;
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <div className="d-flex select-activity-body">
          <div className="activity-group">
            <p>
              <strong>
                {t("US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_GROUP")}
              </strong>
            </p>
            <div>
              <$Radio
                name="filter"
                optionText="label"
                optionValue="value"
                className=""
                size="small"
                defaultValue={"all"}
                buttonStyle="solid"
                optionStyle=""
                onChange={(e: any) => {
                  setFilter({
                    filterValue: e.target.value,
                  });
                }}
                options={activities?.data?.groups}
              />
            </div>
          </div>
          <div className="flex-grow-1 activity-list">
            <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
              <$TableTree
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onDoubleClick: (event: any) => {
                      setSelectedValue(record);
                    },
                  };
                }}
                rowKey={(record: any) => {
                  return record.hasOwnProperty("children") ? record.key : record.activityId;
                }}
                onChange={handleTableChange}
                onSort={handleSort}
                onFilter={handleFilter}
                filterOnType={true}
                data={activities.data?.dataSource}
                columns={columns}
                size="small"
                className="activity-table"
                bordered
                pagination={{ defaultPageSize: 20 }}
                scroll={{ x: 780, y: "calc(100vh - 170px)" }}
                defaultExpandAllRows={true}
                resetOnSourceChange={true}
                currentCurrency={'en-us'}
              />
            </$Skeleton>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, activity } = state;
  const { currentLanguage, currentDateFormat } = common;
  const { activities, parameters } = activity;
  return {
    currentLanguage,
    currentDateFormat,
    parameters,
    activities,
  };
};

const mapDispatchToProps = {
  getParameters: Actions.activityAction.parameters.get,
  setFilter: Actions.activityAction.activities.setFilter,
  setActivity: Actions.activityAction.activity.set,
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectActivity);
