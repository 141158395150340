import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { PlusOutlined } from "us.icons";
import { FeeRangeTable } from "./Components/Table";
import {
  ManageFeeAction,
  DrawerType,
} from "us.collection.admin/components/ManageFee/Constants";
import { IManageFee } from "us.collection.admin/components/ManageFee/Interfaces";
import { useLocation } from "react-router-dom";
import * as Actions from "us.collection.admin/actions";
import { getSelectedFeeTypeNameForDrawerTitle } from "us.collection.admin/components/ManageFee/Functions";
import "../../Home.scss";

const { $Skeleton, $Button, $Divider, $Col } = Common.Components;

/**
 * @description - Fee Range menu view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const FeeRange: React.FC<PropsFromRedux & IManageFee> = ({
  onCallAction,
  getFeeRangeData,
  feeRange,
  feeType,
  feeBasis,
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const feeId = urlParams.get("feeId");
  const typeId = urlParams.get("typeId");

  useEffect(() => {
    if (feeId) {
      getFeeRangeData && getFeeRangeData(feeId);
    }
  }, [feeId]);

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={() => {}}
    >
      {() => (
        <>
          <$Col
            className="collection-fee-range px-xxl-5"
            xl={{ span: Number(feeId) == -1 ? 10 : 14 }}
            xxl={{ span: 14 }}
          >
            <$Skeleton
              loading={feeRange?.isListLoading}
              active
              paragraph={{ rows: 2 }}
            >
              <div style={{ marginBottom: "-2rem", marginTop: "54px" }}>
                <strong>
                  {`${
                    getSelectedFeeTypeNameForDrawerTitle(
                      feeType?.list,
                      Number(typeId)
                    ) ?? ""
                  }${" "}`}
                  {t("US.COLLECTION.ADMIN:MANAGE_FEE.RANGE")}
                </strong>
                <$Divider className="bui-devider" type="vertical" />
                <$Button
                  onClick={() =>
                    onCallAction(DrawerType.FEE_RANGE, ManageFeeAction.ADD)
                  }
                  type="dashed"
                  size="small"
                  id="add-new-range"
                  icon={<PlusOutlined />}
                  disabled={feeBasis?.list?.length == 0}
                >
                  {t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_FEE_RANGE")}
                </$Button>
              </div>
              <FeeRangeTable onCallAction={onCallAction} />
            </$Skeleton>
          </$Col>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeRange, feeType, feeBasis } = manageFee;
  return {
    feeRange,
    feeType,
    feeBasis,
  };
};
const { feeRangeActions } = Actions.manageFee;
const { getById } = feeRangeActions;

const mapDispatchToProps = {
  getFeeRangeData: getById,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FeeRange);
