import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { COLUMNS, FeeRangeTableColumns } from "../../Constants";
import { MoreOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { IFeeRangeTable } from "./interface";
import { IRootState } from "us.collection/interfaces";
import { IFeeRangeDetails } from "us.collection.admin/interfaces";
import { getColumnFilterProps, getColumnWidth } from "./function";
import { ItemMenu } from "us.collection.admin/components/ManageFee/Components";
import { IItemMenuPopup } from "us.collection.admin/components/ManageFee/Interfaces";
import {
  ManageFeeAction,
  ITEM_MENU_DEFAULT_STATE,
  DrawerType,
} from "us.collection.admin/components/ManageFee/Constants";

const { $Button, $AmountLabel, $Popover, $TableTree } = Components;

/**
 * @description - Fee Range Table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Table: React.FC<IFeeRangeTable & PropsFromRedux> = (props) => {
  const { onCallAction, feeRange } = props;
  const { t } = useTranslation();

  const [itemMenu, setItemMenu] = useState<IItemMenuPopup>(
    ITEM_MENU_DEFAULT_STATE
  );
  // auto close invoice item menu after 5 seconds
  const setAutoClose = (id: number) => {
    setTimeout(() => {
      setItemMenu(ITEM_MENU_DEFAULT_STATE);
    }, 5000);
  };

  /**
   * @description - render rows
   */
  const renderCell = (
    record: IFeeRangeDetails,
    dataIndex: FeeRangeTableColumns,
    text: any
  ) => {
    const { id, noOfFee } = record;
    const row = {
      children: (
        <>
          {dataIndex === FeeRangeTableColumns.MENU && (
            <div className="align-items-center" key={id}>
              <$Popover
                placement="right"
                trigger="click"
                data-testid="popover-btn"
                visible={itemMenu.visible && itemMenu.id == id}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: id,
                  });
                  setAutoClose(id);
                }}
                content={
                  <ItemMenu
                    onCallAction={(
                      drawerType: DrawerType,
                      actionType: ManageFeeAction
                    ) => {
                      setItemMenu(ITEM_MENU_DEFAULT_STATE);
                      onCallAction(drawerType, actionType, record);
                    }}
                    drawerType={DrawerType.FEE_RANGE}
                    id={record?.id}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  id="btnItemMenu"
                  type="default"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          )}
          {[
            FeeRangeTableColumns.FROM_AMOUNT,
            FeeRangeTableColumns.TO_AMOUNT,
            FeeRangeTableColumns.AMOUNT,
            FeeRangeTableColumns.DOUBLE_AMOUNT,
          ].includes(dataIndex) && <$AmountLabel value={text} />}
          {dataIndex === FeeRangeTableColumns.NO_OF_FEES && (
            <span data-testid={`noOfFee`}>
              {Number(Number(noOfFee)?.toFixed(2))}
            </span>
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map(({ key, title }, _index) => {
      let column: any = {
        dataIndex: key,
        key,
        title: title && t(`${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) =>
          renderCell(record, key, _text),
        align: [
          FeeRangeTableColumns.FROM_AMOUNT,
          FeeRangeTableColumns.TO_AMOUNT,
          FeeRangeTableColumns.AMOUNT,
          FeeRangeTableColumns.DOUBLE_AMOUNT,
        ].includes(key)
          ? "right"
          : "left",
        className: [
          FeeRangeTableColumns.FROM_AMOUNT,
          FeeRangeTableColumns.TO_AMOUNT,
          FeeRangeTableColumns.AMOUNT,
          FeeRangeTableColumns.DOUBLE_AMOUNT,
        ].includes(key)
          ? "right-has-sort right-has-filter"
          : "",
        width: getColumnWidth(key)

      };
      if (key !== FeeRangeTableColumns.MENU) {
        column = {
          ...column,
          customSorter: (a: any, b: any) => {
            if (key === FeeRangeTableColumns.NO_OF_FEES) {
              return a - b;
            } else {
              return a - b;
            }
          },
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$TableTree
      rowKey="rowKey"
      data={feeRange.list}
      data-testid="fee-range-table"
      size="small"
      columns={getColumns()}
      className="mt-3 header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      scroll={{ x: 750, y: "calc(100vh - 315px)" }}
      pagination={false}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeRange } = manageFee;
  return {
    feeRange,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
