export enum CreditorSelection {
	GET_INIT_CREDITORS_START = "GET_INIT_CREDITORS_START",
	GET_INIT_CREDITORS_SUCCESS = "GET_INIT_CREDITORS_SUCCESS",
	GET_INIT_CREDITORS_FAIL = "GET_INIT_CREDITORS_FAIL",
  
	GET_CREDITORS_BY_GROUP_ID_START = "GET_COMMON_CREDITORS_BY_GROUP_ID_START",
	GET_CREDITORS_BY_GROUP_ID_SUCCESS = "GET_COMMON_CREDITORS_BY_GROUP_ID_SUCCESS",
	GET_CREDITORS_BY_GROUP_ID_FAIL = "GET_COMMON_CREDITORS_BY_GROUP_ID_FAIL",

	REMOVE_CREDITORS_BY_GROUP_ID = "REMOVE_CREDITORS_BY_GROUP_ID",
	SELECT_DESELECT_CREDITORS = "SELECT_DESELECT_CREDITORS",
  }