import moment from "moment";
import { ISaveFeeBasis } from "../../Interfaces";

export function AddNewFeeBasis(this: any): ISaveFeeBasis {
  return {
    feeId: this.feeId ? Number(this.feeId) : -1,
    feeType: Number(this.typeId),
    fromDate: moment(this.fromDate).format("YYYY-MM-DDT00:00:00"),
    amount: this.amount,
    debtorType: this.debtorType ?? "P",
  };
}
