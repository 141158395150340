export const initialState = {
	systemSettings: {
		isLoading: false,
		data: [],
		settingTypes: [],
		settings: [],
	},
	settingUpdate: {
		isLoading: false,
		data: {},
	},
	settingSave: {
		isLoading: false,
		data: {},
	},
};
