import moment from "moment";
import _ from "lodash"
import { checkDefaultParams } from "us.collection/functions";

export function Activity(
  this: any,
  activitiesList: any,
  activityDetail: any,
  tableData: Array<any>,
  parameterDate: string | moment.Moment,
  currentUser: any,
  caseDetails: { caseNo: string; eType: string; url: string; typeId: string },
  history: any,
  location: any
) {
  const { activities, executingDateTime } = this;
  const { data } = activitiesList
  const { actionType, activityCode, executedStatus, activityHistoryId } =
    activityDetail;
  const { unique_name } = currentUser;
  const { caseNo, eType, url, typeId } = caseDetails;

  const navigateTo = (routes: any) => {
    history.push(`/${url}/${typeId}${routes}`, location?.state);
    if (url.toLowerCase() === "bureau") {
      history.push(`/${url}${routes}`, location?.state);
    } else {
      history.push(`/${url}/${typeId}${routes}`, location?.state);
    }
  };

  let paramsId = {
    exist: false,
    key: "",
    value: "",
  };
  const paramsName = {
    exist: false,
    key: "",
  };
  const parameterList: Array<any> = [];
  tableData.map(({ name, parameterType }: any) => {
    parameterList.push({
      Name: name,
      DataType: parameterType,
      value:
        parameterType?.toLowerCase() === "date" ||
          parameterType?.toLowerCase() === "datetime"
          ? moment(parameterDate).isValid()
            ? moment(parameterDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD")
          : this[name],
    });
  });

  parameterList?.map(({ Name, value }: any) => {
    if (Name?.toLowerCase() === "username" || Name?.toLowerCase() === "user") {
      paramsName.exist = true;
      paramsName.key = Name;
    }
    if (
      !(Name?.toLowerCase() === "username" || Name?.toLowerCase() === "user") &&
      checkDefaultParams(Name, eType)
    ) {
      paramsId = {
        exist: true,
        key: Name,
        value: value,
      };
    }
  });
  if (!paramsName.exist) {
    parameterList.unshift({
      Name: "username",
      DataType: "varchar",
      value: unique_name,
    });
  }

  if (paramsId.exist) {
    _.remove(parameterList, function ({ Name }: any) {
      return Name === paramsId.key;
    });

    parameterList.unshift({
      Name: paramsId.key,
      DataType: "int",
      value: paramsId.value,
    });
  }

  return {
    uniqueId:
      actionType === "editActivity" && executedStatus != "executed"
        ? activityHistoryId
        : -1,
    activityCode:
      actionType === "editActivity"
        ? activityCode
        : data?.list?.filter(
          ({ label }: any) =>
            label?.toLowerCase() === activities?.toLowerCase()
        )[0]?.value,
    caseId: caseNo,
    entityType: eType,
    executingDateTime: moment(executingDateTime).isValid()
      ? moment(executingDateTime).format()
      : moment().format(),
    parameterList,
    navigateTo,
    activityName: activities
  };
}
