import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { manageActivities } from "us.collection.admin/actions";
import { FollowupDetail, ControlItem, ExecutionLimitation } from "./Components";
import { IControlItem } from "./Components/ControlItem/Interfaces";
import { Step } from "us.collection.admin/constants";
import _ from "lodash";
import {
  isStepsInclude,
  isStatesAvailableForEntityType,
} from "us.collection.admin/functions";
import { useFormikContext } from "formik";

const { activity } = manageActivities;

/**
 * @description Set Controls Step of Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */

const SetControls: React.FC<PropsFromRedux> = (props) => {
  const { values } = useFormikContext();
  const { stepDetail, setStepDetail } = props;
  const { steps, formValues } = stepDetail;
  const { entityType } = formValues.activitySetUpValue;
  const {
    isShowInHistory,
    isExecuteFormDomain,
    isEnableAutoWFFollowup,
    isAvailableForHandling,
    isShowInCP,
    isShowInDP,
    isModifyState,
  } = values as any;

  useEffect(() => {
    onChangeModifyStates(isModifyState);
  }, [isModifyState, entityType]);
  /**
   * @function
   * @description handle onChange event in isModifyState switch
   * @param {boolean} value checked value of isModifyState switch
   */
  const onChangeModifyStates = (value: boolean) => {
    try {
      let newSteps: Array<any> = steps;
      if (value && isStatesAvailableForEntityType(entityType)) {
        if (!isStepsInclude(steps, Step.MODIFY_STATES)) {
          newSteps.push(Step.MODIFY_STATES);
        }
      } else {
        newSteps = _.pull(steps, Step.MODIFY_STATES);
      }
      setStepDetail &&
        setStepDetail({
          ...stepDetail,
          steps: newSteps,
        });
    } catch (error) {}
  };

  const firstControlItemList: Array<IControlItem> = [
    {
      dataIndex: 1,
      name: "isShowInHistory",
      label: "SHOW_IN_HISTORY",
      defaultChecked: true,
      checked: isShowInHistory,
    },
    {
      dataIndex: 2,
      name: "isExecuteFormDomain",
      label: "CAN_EXECUTE_FROM_COLLECTION_MODULE",
      defaultChecked: true,
      checked: isExecuteFormDomain,
    },
    /* ************************************  UBX4-16339 Revert changes ********************************** */
    // {
    //   dataIndex: 3,
    //   name: "isEnableAutoWFFollowup",
    //   label: "ENABLE_AUTOMATIC_WORKFLOW_FOLLOWUP",
    //   defaultChecked: false,
    //   checked: isEnableAutoWFFollowup,
    // },
  ];
  const secondControlItemList: Array<IControlItem> = [
    {
      dataIndex: 4,
      name: "isAvailableForHandling",
      label: "AVAILABLE_FOR_FOLLOWUP_HANDLING",
      defaultChecked: false,
      checked: isAvailableForHandling,
    },
    {
      dataIndex: 5,
      name: "isShowInCP",
      label: "SHOW_IN_CREDITOR_PORTAL",
      defaultChecked: false,
      checked: isShowInCP,
    },
    {
      dataIndex: 6,
      name: "isShowInDP",
      label: "SHOW_IN_DEBTOR_PORTAL",
      defaultChecked: false,
      checked: isShowInDP,
    },
    /* ************************************  UBX4-16339 Revert changes ********************************** */
    // {
    //   dataIndex: 7,
    //   name: "isExecutionLimitation",
    //   label: "SET_EXECUTION_LIMITATIONS",
    //   defaultChecked: false,
    //   checked: isShowInDP,
    // },
  ];
  const thirdControlItemList: Array<IControlItem> = [
    {
      dataIndex: 8,
      name: "isModifyState",
      label: "MODIFY_SELECTED_STATES",
      defaultChecked: false,
      disabled: !isStatesAvailableForEntityType(entityType),
      checked: isStatesAvailableForEntityType(entityType)
        ? isModifyState
        : false,
    },
  ]
  return (
    <div className="ad-layout-content">
      <div className="set-controls">
        {firstControlItemList.map(
          (controlItem: IControlItem, index: number) => (
            <ControlItem {...controlItem} key={controlItem.dataIndex} />
          )
        )}
        {isEnableAutoWFFollowup && (
          <div className="sc-item">
            <FollowupDetail />
          </div>
        )}
        {secondControlItemList.map(
          (controlItem: IControlItem, index: number) => (
            <ControlItem {...controlItem} key={controlItem.dataIndex} />
          )
        )}
        
        {/* ************************************  UBX4-16339 Revert changes ********************************** */}
        {/* <ExecutionLimitation /> */}

        {thirdControlItemList.map(
          (controlItem: IControlItem, index: number) => (
            <ControlItem {...controlItem} key={controlItem.dataIndex} />
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { stepDetail } = manageActivities;

  return { stepDetail };
};
const mapDispatchToProps = {
  setStepDetail: activity.setStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SetControls);
