import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	CaseTab,
	DebtorTab,
	CreditorTab,
	InvoiceTab,
} from 'us.common/components/MainSearch/Components';
import { MainCriterias } from 'us.common/constants';
import { $Tabs } from 'us.common/components';
import { ITabResults } from 'us.common/components/MainSearch/Interfaces';
import _ from 'lodash';

/**
 * @description - Tab container component for control tabs.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const TabResults: React.FC<ITabResults> = (props) => {
	const { t } = useTranslation();
	const {
		selectedTab,
		tabInfo,
		invoices,
		cases,
		debtors,
		creditors,
		isInvoiceLoading,
		isCaseLoading,
		isDebtorLoading,
		isCreditorLoading,
		totalDebtorSearchResult = 0,
		totalCreditorSearchResult = 0,
		tabChangeEvent,
		paginationChangeEvent,
		aRClickEvent,
		creditorGroupClickEvent,
		changeSortOrderEvent,
	} = props;
	return (
		<$Tabs
			className='bui-tabs'
			defaultActiveKey={MainCriterias.INVOICE}
			onTabClick={(activeKey: string) => {
				tabChangeEvent(activeKey as MainCriterias);
			}}
			activeKey={selectedTab}>
			{tabInfo.invoiceTab.isTabVisible && (
				<$Tabs.TabPane
					tab={`${t('US.COLLECTION.COMMON:COMMON.INVOICE')} (${
						invoices.length
					})`}
					key={MainCriterias.INVOICE}>
					{tabInfo.selectedTab === MainCriterias.INVOICE && (
						<InvoiceTab
							{..._.pick(props, [
								'tabInfo',
								'totalInvoiceSearchResult',
								'history',
								'location',
								'match',
								'changeSortOrderEvent',
							])}
							currentHit={tabInfo.invoiceTab.hitValue}
							invoiceData={invoices}
							isLoading={isInvoiceLoading}
							paginationChangeEvent={(direction) =>
								paginationChangeEvent(direction, MainCriterias.INVOICE)
							}
						/>
					)}
				</$Tabs.TabPane>
			)}

			{tabInfo.caseTab.isTabVisible && (
				<$Tabs.TabPane
					tab={`${t('US.COLLECTION.COMMON:COMMON.CASE')} (${cases.length})`}
					key={MainCriterias.CASE}>
					{tabInfo.selectedTab === MainCriterias.CASE && (
						<CaseTab
							{..._.pick(props, [
								'tabInfo',
								'totalCaseSearchResult',
								'history',
								'location',
								'match',
							])}
							caseData={cases}
							currentHit={tabInfo.caseTab.hitValue}
							isLoading={isCaseLoading}
							changeSortOrderEvent={changeSortOrderEvent}
							paginationChangeEvent={(direction) =>
								paginationChangeEvent(direction, MainCriterias.CASE)
							}
						/>
					)}
				</$Tabs.TabPane>
			)}

			{tabInfo.debtorTab.isTabVisible && (
				<$Tabs.TabPane
					tab={`${t('US.COLLECTION.COMMON:COMMON.DEBTOR')} (${debtors.length})`}
					key={MainCriterias.DEBTOR}>
					{tabInfo.selectedTab === MainCriterias.DEBTOR && (
						<DebtorTab
							debtorData={debtors}
							currentHit={tabInfo.debtorTab.hitValue}
							isLoading={isDebtorLoading}
							changeSortOrderEvent={changeSortOrderEvent}
							tabInfo={tabInfo}
							totalDebtorSearchResult={totalDebtorSearchResult}
							paginationChangeEvent={(direction) =>
								paginationChangeEvent(direction, MainCriterias.DEBTOR)
							}
							aRClickEvent={aRClickEvent}
						/>
					)}
				</$Tabs.TabPane>
			)}

			{tabInfo.creditorTab.isTabVisible && (
				<$Tabs.TabPane
					tab={`${t('US.COLLECTION.COMMON:COMMON.CREDITOR')} (${
						creditors.length
					})`}
					key={MainCriterias.CREDITOR}>
					{tabInfo.selectedTab === MainCriterias.CREDITOR && (
						<CreditorTab
							creditorData={creditors}
							currentHit={tabInfo.creditorTab.hitValue}
							isLoading={isCreditorLoading}
							changeSortOrderEvent={changeSortOrderEvent}
							tabInfo={tabInfo}
							totalCreditorSearchResult={totalCreditorSearchResult}
							paginationChangeEvent={(direction) =>
								paginationChangeEvent(direction, MainCriterias.CREDITOR)
							}
							creditorGroupClickEvent={creditorGroupClickEvent}
						/>
					)}
				</$Tabs.TabPane>
			)}
		</$Tabs>
	);
};
