import React from "react";
import {
  Debtor,
  TabController,
} from "us.common/components/MainSearch/Components";
import { IDebtorTab } from "us.common/components/MainSearch/Interfaces";

/**
 * @description - Tab container for debtor result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const DebtorTab: React.FC<IDebtorTab> = (props) => {
  const {
    debtorData,
    tabInfo,
    isLoading,
    currentHit,
    paginationChangeEvent,
    totalDebtorSearchResult,
    aRClickEvent,
    changeSortOrderEvent,
  } = props;

  return (
    <TabController
      isLoading={isLoading}
      changeSortOrderEvent={changeSortOrderEvent}
      tabInfo={tabInfo}
    >
      <Debtor
        currentHit={currentHit}
        paginationChangeEvent={paginationChangeEvent}
        debtorData={debtorData}
        aRClickEvent={aRClickEvent}
        totalSearchResult={totalDebtorSearchResult}
      ></Debtor>
    </TabController>
  );
};
