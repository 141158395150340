import { put, call, takeLatest } from 'redux-saga/effects';
import * as Actions from 'us.collection/actions'
import * as API from "us.collection/services";
// import * as CaseAction from "us.collection.case/actions"
import { Activity } from 'us.collection/constants';
import { $MessageBox } from 'us.common/components';
import _ from 'lodash'
const { activityAction } = Actions
// const { DashboardActions } = CaseAction;
// const { metadata } = DashboardActions

const activitySagas = {

    parameters: {
        get: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.activityService.parameters.get, params);
                if (data.hasOwnProperty('code') && data?.code === 500 || data?.code === 404) {
                    yield put(activityAction.parameters.fail({}))
                } else {
                    yield put(activityAction.parameters.success(data))
                }
            } catch (error) {
                yield put(activityAction.parameters.fail(error as any))
            }
        }
    },
    activity: {
        execute: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.activityService.activity.execute, _.omit(params, ["navigateTo", "activityName"]));
                if (data.hasOwnProperty('message')) {
                    if (data?.message?.toLowerCase()?.includes('scheduled')) {
                        $MessageBox(
                            'success',
                            `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SCHEDULED_SUCCESSFULLY`,
                            '',
                            ''
                        );
                    } else {
                        const { entityType, caseId, activityName } = params ?? {}
                        // if (entityType && entityType.toLowerCase() === "subcase" && metadata.get && activityName === "Overfør til inkasso") {
                        //     yield put(metadata.get({ id: caseId, type: "caseid" }))
                        // }
                        $MessageBox(
                            'success',
                            `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_SUCCESSFULLY_EXECUTED`,
                            '',
                            ''
                        );
                    }
                    yield put(activityAction.activity.success(data))
                } else {
                    if (data.hasOwnProperty('errors')) {
                        $MessageBox(
                            'error',
                            `US.COLLECTION.COMMON:ACTIVITYEXECUTION.ACTIVITY_EXECUTION_FAILED`,
                            '',
                            ''
                        );
                    }

                    yield put(activityAction.activity.fail({}))
                }
                yield call(params?.navigateTo, '/history');
            } catch (error) {
                yield put(activityAction.activity.fail({}))
            }
        }
    },
    activities: {
        get: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.activityService.activities.get, params);
                if (_.isEmpty(data) || data?.hasOwnProperty('errors')) {
                    yield put(activityAction.activities.fail({}))
                } else {
                    yield put(activityAction.activities.success(data))
                }
            } catch (error) {
                yield put(activityAction.activities.fail(error as any))
            }
        },

    },
    pendingActivities: {
        execute: function* (action: any): any {
            const params = action.payload.data;
            try {
                const data = yield call(API.activityService.pendingActivities.execute, params);
                if (data.hasOwnProperty('isError') && !data?.isError) {
                    yield put(activityAction.pendingActivities.success(data));
                    const { entityType, entityId } = params ?? {}
                    // if (metadata.get && entityType === "S") {
                    //     yield put(metadata.get({ id: entityId, type: "caseid" }))
                    // }
                } else {
                    yield put(activityAction.pendingActivities.fail({}))
                }
            } catch (error) {
                yield put(activityAction.pendingActivities.fail({}))
            }
        }
    }
}
export default [
    takeLatest(Activity.GET_ACTIVITY_PARAMETERS_START, activitySagas.parameters.get),
    takeLatest(Activity.EXCUTE_ACTIVITY_STRAT, activitySagas.activity.execute),
    takeLatest(Activity.GET_ACTIVITIES_STRAT, activitySagas.activities.get),
    takeLatest(Activity.ALL_PENDING_ACTIVITY_EXCUTE_STRAT, activitySagas.pendingActivities.execute),

];