import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import moment from 'moment';

export default ({ value, ...rest }: any) => {

    const { currentDateFormat } = useSelector((state: any) => state.common)

    return (
        <React.Fragment {...rest}>
            {
                moment(value)?.format(currentDateFormat)
            }
        </React.Fragment>
    )
}