import ServiceConfig from "service.config.js";
import { versionedHttpCollection } from "us.helper/http/collection";

export const AddDebtor = {
  postaCodeSearch: async <P>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post<P>(
          "entityservice",
          "postalCodes",
          params,
          ServiceConfig()[`entityservice`][`postalCodes`]
        );
        if (status === 200 || status === 204) {
          resolve({ data, status });
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  municipalityCodeSearch: async <P>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "entityservice",
          `entities/municipalities`,
          params,
          ServiceConfig()[`entityservice`][`entities.municipalities`]
        );
        if (status === 200 || status === 204) {
          resolve({ data, status });
        } else {
          reject(data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  getRoleTypes: async <P>(params: P): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "arservice",
          `otherparty/otherpartyRoleTypes/${params}`,
          {},
          ServiceConfig()[`arservice`]["otherparty.otherpartyRoleTypes"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteRoleType: async <P>(params: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.delete(
          "entityservice",
          "entities/otherpartyroletype",
          params,
          {},
          ServiceConfig()[`entityservice`]["entities.otherpartyRoleType"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  saveRoleType: async <P>(params: number): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.post(
          "arservice",
          "otherparty/otherpartyRoleType",
          params,
          ServiceConfig()[`arservice`]["otherparty.otherpartyRoleType"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  entitySearch: async <
    P extends {
      roleType: string;
      searchCriteria: string;
      text: string;
    }
  >(
    params: P
  ): Promise<any> => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        const { data, status } = await versionedHttpCollection.get(
          "searchservice",
          `entitysearch`,
          {
            roleType: params.roleType,
            searchCriteria: params.searchCriteria,
            keyword: params.text,
          },
          ServiceConfig()[`searchservice`]["entitysearch"]
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
};
