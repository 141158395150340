export enum FeeBasisTableColumns {
  MENU = "menu",
  DATE = "fromDate",
  AMOUNT = "amount",
  DEBTOR_TYPE = "debtorType",
}

export const COLUMNS = [
  {
    id: 0,
    key: FeeBasisTableColumns.MENU,
    title: "",
  },
  {
    id: 1,
    key: FeeBasisTableColumns.DATE,
    title: "US.COMMON:COMMON.APPLICABLE_DATE",
  },
  {
    id: 2,
    key: FeeBasisTableColumns.AMOUNT,
    title: "US.COMMON:COMMON.AMOUNT",
  },
  {
    id: 3,
    key: FeeBasisTableColumns.DEBTOR_TYPE,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.DEBTOR_TYPE",
  },
];
