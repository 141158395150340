import moment from "moment";
import { getSuddestValue, getFieldType, getSearchQueryValueParsed } from "us.common/components/MainSearch/Functions";
import { DebtorSubCriterias } from "us.common/constants";
import { ISearchQuery } from "us.common/interfaces";
import { ISearchRequestObject } from "../Interfaces";

/**
 * @function
 * @description To get the API search object request body
 * @param objectParsedQuery The parsed URL search query
 * @param hit the current try of the search
 * @returns object of type ISearchRequestObject for API request search body
 */
export const getQueryObject = (
  objectParsedQuery: ISearchQuery,
  hit: number = 1
): ISearchRequestObject => {
  // converting the debtor birthday
  if (objectParsedQuery.sub && objectParsedQuery.sub === DebtorSubCriterias.BIRTHDAY) {
    const dateValidator: RegExp = /^[12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/;

    if (objectParsedQuery.value && dateValidator.test(objectParsedQuery.value.trim())) {
      const parsedDate: number = Date.parse(objectParsedQuery.value);
      if (!isNaN(parsedDate)) {
        objectParsedQuery.value = moment(new Date(parsedDate)).format("YYYY-MM-DD");
      }
    }
  }

  const { SearchValue, SecValue } = getSearchQueryValueParsed(objectParsedQuery.value ?? "");

  return {
    SearchValue,
    SecValue: SecValue ?? "",
    Suggest: getSuddestValue(objectParsedQuery),
    FieldType: getFieldType(objectParsedQuery.cat, objectParsedQuery.sub),
    SearchEntityState: "AllEntity",
    Hit: hit,
  };
};
