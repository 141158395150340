import React from 'react';

interface IEmployerNo {

}

const EmployerNo:React.FC<IEmployerNo> = (props) => {
    return (
        <p>Hi Employer no</p>
    );
}

export default EmployerNo;