export enum Handler {
  GET_HANDLER_START = "GET_HANDLER_START",
  GET_HANDLER_SUCCESS = "GET_HANDLER_SUCCESS",
  GET_HANDLER_FAIL = "GET_HANDLER_FAIL",

  UPDATE_HANDLER_START = "UPDATE_HANDLER_START",
  UPDATE_HANDLER_SUCCESS = "UPDATE_HANDLER_SUCCESS",
  UPDATE_HANDLER_FAIL = "UPDATE_HANDLER_FAIL",
}

export enum Handlers {
  CASE = "case",
  COURT = "court",
  HANDLER_NAMES = "handlerNames",
  CASE_HANDLER_LIST = "casehandlerList",
  COURT_HANDLER_LIST = "courthandlerList",
  NOT_ASSIGNED = "Not Assigned",
}
