import React from "react";
import { Form, Checkbox, CheckboxProps } from "antd";
import { Field } from "formik";
import { ICheckboxParams } from "./Interfaces";

export const $Checkbox = <T extends ICheckboxParams & CheckboxProps>({
  autoFocus,
  checked,
  defaultChecked,
  disabled,
  indeterminate,
  onChange,
  formitem,
  formitemlabel,
  noStyle,
  className,
  ...props
}: T) =>
  formitem ? (
    <Form.Item label={formitemlabel} noStyle={noStyle}>
      <Field
        as={Checkbox}
        autoFocus={autoFocus}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        indeterminate={indeterminate}
        onChange={onChange}
        className={className}
      >
      {props.children}
      </Field>
    </Form.Item>
  ) : (
    <Field
      as={Checkbox}
      autoFocus={autoFocus}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={onChange}
      className={className}
    >
    {props.children}
    </Field>
  ); 
