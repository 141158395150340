import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BoxIcons, IconTypes } from "us.icons";
import { getRouteUrl } from "us.helper";
import { $Button, $Collapse, $Tooltip } from "us.common/components";
import {
  IMenuProps,
  IOperation,
} from "us.common/components/Menu/MegaMenu/interface";
import { isQuickLink } from "../../../Functions";
import { useSelector } from "react-redux";
import { RootState } from "us.helper/types";

const Menu: React.FC<IMenuProps> = ({
  module,
  quickLinks,
  onAddToQuickLink,
  onRemoveFromQuickLink,
  onNavigate,
}) => {
  const { t } = useTranslation(["US.COLLECTION.COMMON"], {
    useSuspense: true,
  });
  const [selectedMenuLink, setSelectedMenuLink] = useState<IOperation | null>();
  const { name, displayName, features } = module;

  const { isSaving } = useSelector((state: RootState) => state.UserInformation.quickLinks);

  return (
    <div>
      <h2 className="font-weight-light ml-4">{displayName}</h2>
      <div className="mt-3 feature-menu-wrapper">
        <$Collapse
          ghost
          defaultActiveKey={Array.from(Array(100).keys())}
          collapsible={"header"}
          expandIconPosition="right"
        >
          {features &&
            features.map(
              (feature: {
                id: number;
                displayName: string;
                operations: Array<IOperation>;
              }) => (
                <$Collapse.Panel
                  key={feature.id}
                  header={
                    <span className="feature-group">{feature.displayName}</span>
                  }
                >
                  <div className="d-flex flex-column ml-2">
                    {feature &&
                      feature.operations &&
                      feature.operations.map((operation: IOperation) => {
                        const { id, displayName, route, self } = operation;
                        const isAnQuickLink = isQuickLink(
                          quickLinks,
                          operation
                        );
                        return (
                          <div
                            className="feature-link-item d-flex align-items-center justify-content-between"
                            onMouseEnter={() => {
                              if (selectedMenuLink?.id !== id) {
                                setSelectedMenuLink(operation);
                              }
                            }}
                            onMouseLeave={() => setSelectedMenuLink(null)}
                            key={id}
                          >
                            <a
                              className="flex-fill"
                              onClick={() => {
                                self
                                  ? onNavigate(route)
                                  : getRouteUrl.moduleRoute(name, route);
                              }}
                              key={id}
                            >
                              {displayName}
                            </a>
                            <span>
                              <$Tooltip
                                placement="left"
                                title={
                                  isAnQuickLink
                                    ? t(
                                        "US.COLLECTION.COMMON:COMMON.UNPIN_FROM_QUICK_LINKS"
                                      )
                                    : t(
                                        "US.COLLECTION.COMMON:COMMON.PIN_TO_QUICK_LINKS"
                                      )
                                }
                              >
                                <$Button
                                  onClick={() =>
                                    isAnQuickLink
                                      ? onRemoveFromQuickLink(operation)
                                      : onAddToQuickLink(operation)
                                  }
                                  size="small"
                                  type="link"
                                  disabled={isSaving}
                                >
                                  {!(
                                    selectedMenuLink &&
                                    selectedMenuLink.id === id
                                  ) &&
                                    isAnQuickLink && (
                                      <BoxIcons
                                        type={IconTypes.BOX_ICON}
                                        name="pin"
                                      />
                                    )}
                                  {selectedMenuLink &&
                                    selectedMenuLink.id === id &&
                                    isAnQuickLink && (
                                      <BoxIcons
                                        type={IconTypes.BOX_ICON}
                                        name="unpin"
                                      />
                                    )}
                                  {selectedMenuLink &&
                                    selectedMenuLink.id === id &&
                                    !isAnQuickLink && (
                                      <BoxIcons
                                        type={IconTypes.BOX_ICON}
                                        name="pin"
                                      />
                                    )}
                                </$Button>
                              </$Tooltip>
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </$Collapse.Panel>
              )
            )}
        </$Collapse>
      </div>
    </div>
  );
};

export default Menu;
