import { CreditorSelection } from "us.common/constants";
import { initialState } from "./State";
import {
  getCreditors,
  setCreditorState,
  removeLocalCreditors,
} from "us.common/functions/creditorSelection";
const {
  GET_INIT_CREDITORS_START,
  GET_INIT_CREDITORS_SUCCESS,
  GET_INIT_CREDITORS_FAIL,

  GET_CREDITORS_BY_GROUP_ID_START,
  GET_CREDITORS_BY_GROUP_ID_SUCCESS,
  GET_CREDITORS_BY_GROUP_ID_FAIL,

  SELECT_DESELECT_CREDITORS,
  REMOVE_CREDITORS_BY_GROUP_ID
} = CreditorSelection;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_INIT_CREDITORS_START:
      return Object.assign({}, state, {
        groups: {
          ...initialState.groups,
          isLoading: true,
        },
        creditors: {
          ...initialState.creditors,
          isLoading: true,
        },
        isInitLoading: true,
      });

    case GET_INIT_CREDITORS_SUCCESS:
      return Object.assign({}, state, {
        groups: {
          data: payload?.data.groups,
          isLoading: false,
        },
        creditors: {
          ...getCreditors(
            state.creditors.data,
            state.creditors.selected,
            payload.data.creditors,
            payload.data.selectedKeys
          ),
          isLoading: false,
        },
        isInitLoading: false,
      });

    case GET_INIT_CREDITORS_FAIL:
      return Object.assign({}, state, {
        groups: {
          ...initialState.groups,
          isLoading: false,
        },
        creditors: {
          ...initialState.creditors,
          isLoading: false,
        },
        isInitLoading: false,
      });

    case GET_CREDITORS_BY_GROUP_ID_START:
      return Object.assign({}, state, {
        creditors: {
          ...setCreditorState(
            payload.data,
            state.creditors.data,
            state.creditors.selected
          ),
          isLoading: true,
        },
      });
    case GET_CREDITORS_BY_GROUP_ID_SUCCESS:
      return Object.assign({}, state, {
        creditors: {
          ...getCreditors(
            state.creditors.data,
            state.creditors.selected,
            payload.data.creditors,
            payload.data.selectedKeys
          ),
          isLoading: false,
        },
      });
    case GET_CREDITORS_BY_GROUP_ID_FAIL:
      return Object.assign({}, state, {
        creditors: {
          ...state.creditors,
          isLoading: false,
        },
      });

    case SELECT_DESELECT_CREDITORS:
      return Object.assign({}, state, {
        creditors: {
          ...state.creditors,
          selected: payload.data.selectedKeys,
        },
      });

    case REMOVE_CREDITORS_BY_GROUP_ID:
      return Object.assign({}, state, {
        creditors: {
          ...state.creditors,
          ...removeLocalCreditors(
            state.creditors.data,
            state.creditors.selected,
            payload.data.groupId
          ),
          isLoading: false,
        },
      });

    default:
      return state;
  }
};
