import { put, call, takeLatest } from 'redux-saga/effects';
import * as API from 'us.collection.admin/services';
import * as Actions from 'us.collection.admin/actions';
import { SystemSettings } from 'us.collection.admin/constants/Actions';
import Common from 'us.common';
import { getSettingTypesAndSettings } from 'us.collection.admin/functions';

const { $MessageBox } = Common.Components;

const { getAll, add, update } = API.systemSettings.settings;

const { systemSettings } = Actions;

const { settings, setting, newSetting } = systemSettings;

const SystemSettingsSagas = {
	settings: {
		getAll: function* (action: any) {
			try {
				const { data, status } = yield call(getAll);
				if (status === 200) {
					const reArrangedData =
						getSettingTypesAndSettings(
							data
						);
					yield put(
						settings.success({
							data,
							...reArrangedData,
						})
					);
				} else {
					yield put(
						settings.fail({
							data,
							settingTypes: [],
							settings: [],
						})
					);
				}
			} catch (error) {
				yield put(settings.fail(new Error()));
			}
		},
		update: function* (action: any) {
			const params = action?.payload?.data ?? {};

			try {
				const { data, status, systemSettings } =
					yield call(update, params);
				if (
					status === 201 &&
					data.hasOwnProperty(
						'systemSettingsId'
					) &&
					data?.systemSettingsId > 0
				) {
					$MessageBox(
						'success',
						'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_IS_UPDATED_SUCCESSFULLY',
						'',
						''
					);
					yield put(
						setting.success({
							data,
							systemSettings,
						})
					);
				} else {
					$MessageBox(
						'error',
						'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_IS_UPDATED_FAILED',
						'',
						''
					);
					yield put(setting.fail(new Error()));
				}
			} catch (error) {
				$MessageBox(
					'error',
					'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_IS_UPDATED_FAILED',
					'',
					''
				);
				yield put(setting.fail(new Error()));
			}
		},
		add: function* (action: any) {
			const params = action?.payload?.data ?? {};

			try {
				const { data, status, systemSettings } =
					yield call(add, params);
				if (
					status === 201 &&
					data.hasOwnProperty(
						'systemSettingsId'
					) &&
					data?.systemSettingsId > 0
				) {
					$MessageBox(
						'success',
						`US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_IS_REGISTERED_SUCCESSFULLY`,
						'',
						''
					);
					yield put(
						newSetting.success({
							data,
							systemSettings,
						})
					);
				} else if (status === 400) {
					const errorObj = data?.errors[0] ?? {};
					const errorMessage = errorObj.error;
					$MessageBox(
						'error',
						errorMessage ??
							`US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_ADDED_FAILED`,
						'',
						''
					);
					yield put(newSetting.fail(new Error()));
				} else {
					$MessageBox(
						'error',
						`US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_ADDED_FAILED`,
						'',
						''
					);
					yield put(newSetting.fail(new Error()));
				}
			} catch (error) {
				$MessageBox(
					'error',
					`US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_ADDED_FAILED`,
					'',
					''
				);
				yield put(newSetting.fail(new Error()));
			}
		},
	},
};

export default [
	takeLatest(
		SystemSettings.GET_SYSTEM_SETTINGS,
		SystemSettingsSagas.settings.getAll
	),
	takeLatest(
		SystemSettings.ADD_SYSTEM_SETTING,
		SystemSettingsSagas.settings.add
	),
	takeLatest(
		SystemSettings.UPDATE_SYSTEM_SETTING,
		SystemSettingsSagas.settings.update
	),
];
