import { takeEvery, put, call } from 'redux-saga/effects';
import API from '../services';
import {
    authSuccess, onLogout, authFetchUseData
} from '../actions';
import { showLoader, hideLoader } from '../actions';

import { push } from 'connected-react-router';

/**
 * Login to the application
 * @param action 
 */
function* auth(action: any) {
    const params = action.payload;
    try {
        yield put(showLoader());
        // const data = yield call(API.auth.login, params);
        // yield put(authSuccess(data));
        // yield put(push(data.authRedirectPath));
        // yield put(hideLoader());
    } catch (error) {
        console.log("Login error");
        yield put(hideLoader());
    }
}

/**
 * 
 * @param action Check auth status of the application
 */
function* authCheck(action: any) {
    try {
        console.log('Logout from the app');

        yield put(showLoader());
        const token = localStorage.getItem('token');
        if (!token) {
            yield put(onLogout(false))
            yield put(hideLoader());
        } else {
            // //TODO: Check token expiration and Do logout or refresh token
            // let data = yield call(API.auth.getUserData, `?email=${localStorage.getItem('username')}`);
            // yield put(authFetchUseData(data[0]));
            // if (data[0].userRole === "TL_USER") {
            //     const gymData = yield call(API.auth.getAvailableGyms);
            //     //yield put(getAvailableGymsSuccess(gymData));
            // }
            // yield put(hideLoader());
        }
    } catch (error) {
        yield put(hideLoader());

    }
}

/** 
 * Logout from application 
 * */
function* logoutFromApp() {
    try {
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        // const response = yield call(API.auth.logout);
        // const { isSessionExpired, sessionExpiryMessage } = response;

        // if (isSessionExpired) {
        //     const expiryMessage = sessionExpiryMessage || 'Session Expired';

        // }
    } catch (error) { }
}

function* navigate(action: any) {
    const params = action.payload;
    yield put(push(params.url))
}

export default [
    takeEvery('AUTH_AUTO_SIGNUP_START', authCheck),
    takeEvery('AUTH_START', auth),
    takeEvery('NAVIGATE_START', navigate),
    takeEvery('LOGOUT', logoutFromApp),
];