/**
 * @method
 * @param postalCode - Array of postal data
 * @returns Array of postal codes
 */
export const getPostalCodes = (postalCodes: any[]): Array<any> => {
  try {
    return postalCodes.map(
      ({ postalCode }: { postalCode: string }) => postalCode
    );
  } catch (e) {
    return [];
  }
};

/**
 * @method
 * @param selectedPostalCodes - Array of selectedPostalCodes data
 * @param selectedRowKeys - Array of selectedRowKeys data
 * @returns deSelected postal code
 */
 export const getRemovePostalCode = (selectedPostalCodes: any[], selectedRowKeys: any): number => {
  try {
    return (getPostalCodes(selectedPostalCodes).filter(postalCode => !selectedRowKeys.includes(postalCode)))[0]
  } catch (e) {
    return -1;
  }
};