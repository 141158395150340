
export const navigateStart = (data: any) => ({
    type: 'NAVIGATE_START',
    payload: data,
});


export const changeLanguage = (language:string,currency:string,dateFormat:string) => ({
    type:'CHANGE_LANGUAGE',
    language:language,
    currency:currency,
    dateFormat:dateFormat
});
