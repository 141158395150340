import { IDataItem } from "../Interfaces";
import { DynamicComponent } from "us.common/constants";
import { TypeCode } from "us.common/components/DragNDrop/Constants";
import _ from "lodash";

/**
 * To get the updated sequence list.
 * @param {Array<IDataItem>} dataList - List of sequence to update
 * @param {Array<String>} selectedIds - selected options for the particular seq id
 * @param {number} index - id of updated sequence
 * @returns Updated List
 */
export const getUpdatedList = (
  dataList: Array<IDataItem>,
  selectedIds: Array<number>,
  index: number,
  options: Array<any>
): Array<IDataItem> => {
  try {
    const names: string[] = [];
    const updatedList: Array<IDataItem> = _.cloneDeep(dataList);

    selectedIds.map((id) => {
      const index = options.findIndex((option) => option.itemTypeId == id);
      if (index) {
        //
        names.push(options[index].description);
      }
    });

    updatedList[index].preferredIds = selectedIds.toString();
    updatedList[index].value = selectedIds;
    updatedList[index].name = names.toString();

    return updatedList;
  } catch (error) {
    return dataList;
  }
};

/**
 * To get the updated sequence list after delete the sequence record.
 * @param {Array<IDataItem>} dataList - List of sequence to update
 * @param {number} id - id of deleted sequence
 * @returns Updated List after delete
 */
export const getUpdatedListAfterDelete = (
  dataList: Array<IDataItem>,
  id: number
): Array<IDataItem> => {
  try {
    return dataList
      .filter((seq: IDataItem) => seq.id != id)
      .map((item: IDataItem) => ({
        ...item,
        id: item.id > id ? item.id - 1 : item.id,
      }));
  } catch (error) {
    return dataList;
  }
};

/**
 * To get the updated sequence list after Adding new sequence
 * @param {Array<IDataItem>} dataList - List of sequence to update
 * @returns Updated List after Add
 */
export const addNewSequenceList = (dataList: Array<any>): Array<IDataItem> => {
  try {
    return [
      ...dataList,
      {
        id: dataList.length,
        sequenceDetailId: 0,
        isNew: true,
        componentType: DynamicComponent.Select,
        type: TypeCode.ITEM_TYPE,
        name: "",
        preferredIds: "",
        value: undefined,
      },
    ];
  } catch (error) {
    return dataList;
  }
};

/**
 * To get the updated option list after add ,delete and deselect the options.
 * @param {Array<any>} defaultSelectOptions - default select options
 * @param {any} selectedOptions - selected options
 * @returns Updated option list
 */
export const getFilteredOptionList = (
  defaultSelectOptions: Array<any>,
  selectedOptions: Array<number>,
  isDelete?: boolean
): any => {
  try {
    if (selectedOptions) {
      if (isDelete) {
        return defaultSelectOptions?.map((option: any) =>
          selectedOptions.includes(option.itemTypeId)
        );
      } else {
        return defaultSelectOptions?.filter(
          (option: any) => !selectedOptions.includes(option.itemTypeId)
        );
      }
    } else {
      return defaultSelectOptions;
    }
  } catch (error) {
    return defaultSelectOptions;
  }
};

/**
 * To get the updated option list after add ,delete and deselect the options.
 * @param {Array<any>} defaultSelectOptions - default select options
 * @param {any} sequenceList - selected options
 * @returns Updated option list
 */
export const getInitialSelectOptions = (
  defaultSelectOptions: Array<any>,
  sequenceList: Array<IDataItem>
): any => {
  try {
    const selectedOptions: Array<number> = [];
    if (sequenceList) {
      sequenceList.map((dataItem: IDataItem, index: number) => {
        selectedOptions.concat(dataItem.value);
      });
      if (selectedOptions) {
        return defaultSelectOptions?.filter(
          (option: any) => !selectedOptions.includes(option.itemTypeId)
        );
      }
    }
    return defaultSelectOptions;
  } catch (error) {
    return defaultSelectOptions;
  }
};
