import React from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "us.icons";
import { CourtInfoAction } from "us.collection.admin/components/Court/Constants";
import { ICourtInfoAction } from "us.collection.admin/components/Court/Interfaces";
import Common from "us.common";

const { $Popconfirm } = Common.Components;

interface IItemMenu {
  onCallAction: (actionType: ICourtInfoAction) => void;
}

const ItemMenu: React.FC<IItemMenu> = ({ onCallAction }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex flex-row more-item"
        data-testid="edit-btn"
        onClick={() => {
          onCallAction(CourtInfoAction.EDIT);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t(
          "US.COLLECTION.ADMIN:COURT.DO_YOU_WANT_TO_DELETE_THE_COURT_ITEM?"
        )}
        onConfirm={() => {
          onCallAction(CourtInfoAction.DELETE);
        }}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );
};

export default ItemMenu;
