
import { IFollowupCategory } from 'us.collection.admin/interfaces';

/**
 * @function
 * @description check followup category is available or not
 * @param {Array<IFollowupCategory>} categories followup categories
 * @param {string} newCategoryName category name which is going to add
 * @returns {boolean} if - categoryName is existed ,otherwise false
 */
export const isFollowupCategoryExist = (categories: Array<IFollowupCategory>, newCategoryName: string): boolean => {
    try {
        return categories.some(({ categoryName }) => categoryName.toLowerCase() === newCategoryName.toLowerCase().trim())
    } catch (error) {
        return false
    }
}