import _ from "lodash";
import { StateTreeCheckAllKey } from "us.collection.admin/constants";
import { IActivityState, IState } from "us.collection.admin/interfaces";

/**
 * @function
 * @description filter states and groups
 * @param {Array<IActivityState>} activityStates activity states
 * @param {string} searchText string
 * @returns {Array<IActivityState>}
 */
export const searchStates = (
    activityStates: Array<IActivityState>,
    searchText: string
): Array<IActivityState> => {
    try {
        const result: Array<IActivityState> = activityStates.map(
            (activityState: IActivityState) => ({
                ...activityState,
                states: activityState.states.filter(
                    ({ stateDisplayName }: IState) =>
                        stateDisplayName.toLowerCase().includes(searchText.toLowerCase()) ||
                        activityState.groupDisplayName
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                ),
            })
        );
        _.remove(result, ({ states }: IActivityState) => states.length == 0);
        return result;
    } catch (error) {
        return activityStates;
    }
};

/**
 * @function 
 * @description removeUncheckedStates
 * @param {Array<IActivityState>} activityStates activity states
 * @param {Array<string|number>} previousStates useState previous state
 * @param {string | number} removeKey key of uncheck node
 * @returns {Array<string|number>}
 */
export const removeUncheckedStates = (
    activityStates: Array<IActivityState>,
    previousStates: Array<string | number>,
    removeKey: string | number
): Array<string | number> => {
    try {
        const newStates = removeItems(
            previousStates.filter(Number.isInteger),
            removeKey
        );
        if (typeof removeKey == "string") {
            return removeKey == StateTreeCheckAllKey ? [] : removeItems(newStates, getGroupingStates(activityStates, removeKey));
        }
        return newStates;
    } catch (error) {
        return previousStates;
    }
};

/**
 * @function
 * @description remove items form provide array
 * @param {Array<string | number>} itemArray item array
 * @param {Array<string | number> | string | number} removeItem  remove item 
 * @returns {Array<string | number>}
 */
const removeItems = (
    itemArray: Array<string | number>,
    removeItem: Array<string | number> | string | number
): Array<string | number> => {
    try {
        if (Array.isArray(removeItem)) {
            return itemArray.filter(
                (key: number | string) => !removeItem.includes(key)
            );
        } else {
            return itemArray.filter((key: number | string) => key != removeItem);
        }
    } catch (error) {
        return itemArray;
    }
};

/**
 * @function
 * @description get states according to groupKey
 * @param {Array<IActivityState>} activityStates activity states
 * @param {string} groupKey groupKey
 * @returns { Array<string | number>}
 */
const getGroupingStates = (
    activityStates: Array<IActivityState>,
    groupKey: string
): Array<string | number> => {
    try {
        let statesWithGroup: any = {};
        activityStates.forEach((activityState: IActivityState) => {
            const { groupId, states } = activityState;
            statesWithGroup = {
                ...statesWithGroup,
                [`groupId_${groupId}`]: states.map(({ stateId }: IState) => stateId),
            };
        });
        return statesWithGroup[groupKey];
    } catch (error) {
        return [];
    }
};