import { IRoleSummaryWithExtendedFields } from "us.common/interfaces";

/**
 * @function
 * @description get Table data according to filter value
 * @param { Array<IRoleSummaryWithExtendedFields> } roles 
 * @param {string} filter 
 * @returns { Array<IRoleSummaryWithExtendedFields> }
 */
export const filterRoleByActivityStatus = (
    roles: Array<IRoleSummaryWithExtendedFields>,
    filter: string
): Array<IRoleSummaryWithExtendedFields> => {
    try {
        switch (filter) {
            case "active":
                return roles.filter(
                    ({ activeState }: IRoleSummaryWithExtendedFields) => activeState
                );
            case "deactivated":
                return roles.filter(
                    ({ activeState }: IRoleSummaryWithExtendedFields) => !activeState
                );
            default:
                return roles;
        }
    } catch (error) {
        return roles;
    }
};

/**
 * @function
 * @description get Table data according to filter value
 * @param {Array<IRoleSummaryWithExtendedFields>} roles 
 * @returns { Array<IRoleSummaryWithExtendedFields> }
 */
export const searchRoleByName = (
    roles: Array<IRoleSummaryWithExtendedFields>,
    searchText: string
): Array<IRoleSummaryWithExtendedFields> => {
    try {
        return roles.filter(
            ({ name }: IRoleSummaryWithExtendedFields) => name.toLowerCase().includes(searchText.toLowerCase())
        );
    } catch (error) {
        return roles;
    }
};