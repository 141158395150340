import { MainCriterias } from "us.common/constants";
import { ISearchQuery } from "us.common/interfaces";

/**
 * @function
 * @description To get the current viewing tab based on the search url query
 * @param parsedQueryValue The URL search query parsed value
 * @returns The corrosponding tab name
 */
export const getSelectedTab = (parsedQueryValue: ISearchQuery): MainCriterias => {
  if (!parsedQueryValue.cat || parsedQueryValue.cat === MainCriterias.INVOICE_REF) {
    return MainCriterias.INVOICE;
  } else {
    return parsedQueryValue.cat;
  }
};
