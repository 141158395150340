import React, { useState, useEffect, memo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { Link, RouteComponentProps } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { AddCreditorGroup } from "../Add";
import { creditorGroup } from "us.collection.admin/actions";
import { INavigationData, IRootState } from "us.collection/interfaces";

const {
  $Input,
  $Popover,
  $Popconfirm,
  $Button,
  $Divider,
  $PageHeader,
  $Drawer,
  $Affix,
  $Skeleton,
  $Table,
} = Common.Components;

import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  FilterOutlined,
  SearchOutlined,
} from "us.icons";

interface ICreditorGroupHome extends RouteComponentProps {
  onMinimize(state: any): void;
  currentCurrency: string;
  currentLanguage: string;
  currentDateFormat: string;
  getCreditorGroups: any;
  creditorGroups: any;
  manageCreditorGroupDrawer?: any;
  drawer?: any;
  selectCreditor?: any;
  resetSelectedCreditor?: any;
  deleteCreditorGroup?: any;
}

export interface ISortOrder {
  columnKey: string;
  order: any;
}

const CreditorGroupHome: React.FC<ICreditorGroupHome> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.CASE", "US.COLLECTION.COMMON"], {
    useSuspense: true,
  });

  const {
    getCreditorGroups,
    creditorGroups,
    manageCreditorGroupDrawer,
    drawer,
    selectCreditor,
    resetSelectedCreditor,
    deleteCreditorGroup,
    onMinimize,
    location,
  } = props;

  const state = location.state as INavigationData;
  const [searchText, setSearchText] = useState<string>("");
  const [popVisible, setPopVisibile] = useState<number>(-1);
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  useEffect(() => {
    getCreditorGroups();
  }, []);

  const getColumnSearchProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <$Input
          name="columnSearch"
          placeholder={`${t("US.COLLECTION.COMMON:COMMON.SEARCH")} ${title}`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />

        <$Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t("US.COLLECTION.COMMON:COMMON.SEARCH")}
        </$Button>
        <$Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {t("US.COLLECTION.COMMON:COMMON.RESET")}
        </$Button>
      </div>
    ),
    filterIcon: (filtered: string) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: string, record: any) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    render: (text: string) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  // Popover actions in table
  const content = (record: any) => (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          setPopVisibile(-1);
          editCreditorGroupHandler(record);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DELETECONFIRM")}
        onConfirm={() => {
          setPopVisibile(-1);
          deleteCreditorGroupHandler(record);
        }}
        onCancel={() => setPopVisibile(-1)}
      >
        <div className="d-flex mb-2 flex-row more-item">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any, index: number) => {
        return (
          <$Popover
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
            trigger="click"
            visible={index == popVisible}
            onVisibleChange={(visbile: boolean) =>
              setPopVisibile(visbile ? index : -1)
            }
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NO"),
      dataIndex: "id",
      key: "id",
      className: "text-nowrap",
      width: "120px",
      sorter: (a: any, b: any) => a.id - b.id,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "id" && sortedInfo.order
        : null,
      ...getColumnSearchProps(
        "id",
        t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NO")
      ),
      render: (text: any, record: any) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/creditor-group/${record?.id}`,
                state: {
                  ...state,
                  currentTab: "creditorgroup",
                },
              }}
            >
              {text > 0 ? text : ""}
            </Link>
          </div>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NAME"),
      dataIndex: "groupName",
      key: "groupName",
      className: "text-nowrap",
      width: "280px",
      sorter: (a: any, b: any) => a.groupName.localeCompare(b.groupName),
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "groupName" && sortedInfo.order
        : null,
      ...getColumnSearchProps(
        "groupName",
        t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NAME")
      ),
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NUMBEROFCREDITORS"),
      dataIndex: "numberOfCreditors",
      key: "numberOfCreditors",
      className: "text-nowrap",
      width: "280px",
      sorter: (a: any, b: any) => a.numberOfCreditors - b.numberOfCreditors,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "numberOfCreditors" && sortedInfo.order
        : null,
      ...getColumnSearchProps(
        "numberOfCreditors",
        t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.NUMBEROFCREDITORS")
      ),
    },
    {
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description - b.description,
      sortOrder: sortedInfo
        ? sortedInfo.columnKey === "description" && sortedInfo.order
        : null,
      ...getColumnSearchProps(
        "description",
        t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.DESCRIPTION")
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const addNewCreditorGroupHandler = () => {
    resetSelectedCreditor({});
    manageCreditorGroupDrawer({
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.ADDNEWCREDITORGROUP"),
      visible: true,
      isNewCreditorGroup: true,
    });
  };

  const editCreditorGroupHandler = (record: any) => {
    selectCreditor(record);
    manageCreditorGroupDrawer({
      title: t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.EDIT_CREDITOR_GROUP"),
      visible: true,
      isNewCreditorGroup: false,
    });
  };

  const deleteCreditorGroupHandler = (record: any) => {
    deleteCreditorGroup(record?.id);
  };

  const onClose = () => {
    resetSelectedCreditor({});
    manageCreditorGroupDrawer({
      title: "",
      visible: false,
      isNewCreditorGroup: true,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={80}>
              <div className="page-header header-border">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      title={t(
                        "US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.MANAGECREDITORGROUPS"
                      )}
                      onBack={() => onMinimize(location.state)}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Skeleton
                      loading={creditorGroups?.isFetching}
                      active
                      paragraph={{ rows: 0 }}
                    >
                      <$Button
                        onClick={() => addNewCreditorGroupHandler()}
                        type="dashed"
                        size="small"
                        icon={<PlusOutlined />}
                      >
                        {t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.ADDNEW")}
                      </$Button>
                    </$Skeleton>
                  </div>
                </div>
              </div>
            </$Affix>

            <div className="mt-3">
              <InfoCircleOutlined className="mr-2" />
              {t("US.COLLECTION.ADMIN:MANAGECREDITORGROUPS.INFO")}
            </div>

            <$Skeleton
              loading={creditorGroups?.isFetching}
              active
              paragraph={{ rows: 2 }}
            >
              <$Table
                onChange={handleTableChange}
                rowKey="id"
                dataSource={creditorGroups?.data}
                size="small"
                className="mt-3 table-striped"
                bordered
                pagination={{ hideOnSinglePage: true, defaultPageSize: 20 }}
                columns={columns}
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onDoubleClick: (event: any) => {
                      editCreditorGroupHandler(record);
                    },
                  };
                }}
              />
            </$Skeleton>
          </div>

          <$Drawer
            title={drawer?.title}
            width={500}
            visible={drawer?.visible}
            onClose={onClose}
            destroyOnClose
          >
            <AddCreditorGroup onClose={onClose} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { common, creditorGroup } = state;
  const { currentCurrency, currentLanguage, currentDateFormat } = common;
  const { creditorGroups, drawer } = creditorGroup;

  return {
    currentCurrency,
    currentLanguage,
    currentDateFormat,
    creditorGroups,
    drawer,
  };
};

const mapDispatchToProps = {
  getCreditorGroups: creditorGroup.creditorGroups.get,
  manageCreditorGroupDrawer: creditorGroup.drawer.set,
  selectCreditor: creditorGroup.creditorGroup.select,
  resetSelectedCreditor: creditorGroup.creditorGroup.reset,
  deleteCreditorGroup: creditorGroup.creditorGroup.delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditorGroupHome);
