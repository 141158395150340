import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { IRootState } from "us.collection/interfaces";
import {
  $TextArea,
  $Switch,
  $Button,
  $Form,
  $Row,
  $Col,
  $Input,
  $Popconfirm,
} from "us.common/components";
import { ISaveQuery } from "./Interfaces";
import { manageFollowupActions } from "us.collection.admin/actions";
import { Category } from "us.collection.admin/components/ManageFollowup/CustomQuery/Components/SaveQuery/Components";
import { SaveFollowUp } from "us.collection.admin/repository";
import { AssigneeSelection, ActivitySelection } from "./Components";
import { SaveQuerySchema } from "./Validations";

const { customQuery } = manageFollowupActions;

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2943713306/Follow-up+Custom+Query+Save+View+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const SaveQuery: React.FC<ISaveQuery & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    saveCustomQuery,
    onClose,
    customQuery,
    isStaticWorkFlow,
    isNewCustomQuery,
    followUpDetails,
  } = props;

  const handleSubmit = (values: any, { setSubmitting, isValidating }: any) => {
    setSubmitting(true);
    const { params }: any = matchPath(pathname, {
      path: "/manage-followup/custom-query/:entityType?",
      exact: true,
      strict: false,
    });
    const entityType = params["entityType"];
    const saveFollowUpReq = SaveFollowUp.call(
      values,
      isStaticWorkFlow,
      entityType,
      customQuery
    );
    if (isValidating) return;
    saveCustomQuery && saveCustomQuery({ request: saveFollowUpReq, history });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={followUpDetails.data}
      validationSchema={SaveQuerySchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <$Form>
          <$Row gutter={16}>
            <$Col span={11}>
              <$Input
                size="small"
                name="followupName"
                label={t("US.COLLECTION.ADMIN:BULKOPERATION.NAME")}
                className="w-100"
                disabled={!isNewCustomQuery}
                required
                dataTestid="followupName-input"
              />
              <$Input
                size="small"
                name="followupDisplayName"
                label={t("US.COLLECTION.ADMIN:BULKOPERATION.DISPLAY_NAME")}
                className="w-100"
                required
                dataTestid="followupDisplayName-input"
              />
              <Category />
              <AssigneeSelection />
            </$Col>
            <$Col span={12} offset={1}>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: 25 }}
              >
                <div className="d-flex flex-1 align-items-center">
                  <div className="mr-2">
                    <$Switch
                      name="isCaseHandler"
                      checked={values?.isCaseHandler}
                    />
                  </div>
                  <div style={{ paddingTop: 3 }}>
                    {t(
                      "US.COLLECTION.ADMIN:BULKOPERATION.SHOW_IN_CASE_HANDLER_VIEW"
                    )}
                  </div>
                </div>
              </div>
              <ActivitySelection />
              <$TextArea
                label={t("US.COLLECTION.ADMIN:BULKOPERATION.DESCRIPTION")}
                name="description"
                autoSize={{ minRows: 4, maxRows: 4 }}
                required
                data-testid={"description-txtArea"}
              />
            </$Col>
          </$Row>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                type="primary"
                className="mr-2"
                disabled={!rest.isValid}
                loading={isSubmitting}
                onClick={(e: any) => handleSubmit(e)}
                data-testid={"save-btn"}
              >
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Popconfirm
                title={t(
                  "US.COLLECTION.ADMIN:BULKOPERATION.SAVE_QUERY_CANCEL_ERROR"
                )}
                placement="topLeft"
                okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                onConfirm={() => onClose()}
              >
                <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
              </$Popconfirm>
            </div>
          </div>
        </$Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery, followUpDetails } = manageFollowups;
  const { activities, isNewCustomQuery } = customQuery;
  return { activities, isNewCustomQuery, followUpDetails };
};

const mapDispatchToProps = {
  saveCustomQuery: customQuery.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SaveQuery);
