import { Mode } from "us.common/constants";
import { IInitialState } from "../Interfaces";

export const initialState: IInitialState = {
    activities: {
        data: [],
        isFetching: false,
    },
    defaultData: {
        entityType: '',
        userName: ''
    },
    parameters: {
        isEnableDropDown: false,
        list: [],
        dataSource: [],
        isFetching: false
    },
    activityFormDetail: {
        initialValues: {
            activity: '',
            executingDateTime: "",
            executingDateTimeWithParameter: "",
            action: '',
            isEdit: false
        },
    },
    isRefreshRoutineHistory: true,
    isExecuting: false,
    activityExecutionMode: Mode.Initial
};