import React from "react";
import * as Azure from "us.helper/azure";

let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
let auth: any;
if (isAzureAD) {
  auth = new Azure.ADAuth();
} else {
  auth = new Azure.B2CAuth();
}

export const AuthCallback = () => <>Hi</>;
