/**
 * @description create full address or full name
 * @param {Object} addressPayload - payload for create address or name
 * @returns {string} - return full address or full name
 */
export const getCombinedData = (addressPayload: any, type: string): string => {
    const { address1, address2, address3, firstName, lastName } = addressPayload;
    let combinedData: string = "";
    try {
        if (type === "address") {
            combinedData = [address1, address2, address3].join(" ");
        } else if (type === "name") {
            combinedData = [firstName, lastName].join(" ");
        }
    } catch (error) {
        console.error(error);
    }
    return combinedData;
};
