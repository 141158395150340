import React, { useEffect, useState } from "react";
import { Formik, useField } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { TableColumns, GroupSelection, SearchFilters } from "../Selection/Components";
import * as Actions from "us.common/actions";
import {
  ISelection,
  ISearchData,
} from "us.common/components/SelectCreditors/Interfaces/Interface";
import {
  FilterOptions,
  SearchByOptions,
  InitialFilters,
  GroupType,
  SearchByLabels,
} from "us.common/components/SelectCreditors/Constants/Constants";
import {
  getCreditorIds,
  handleAutoCompleteChange,
  filterCreditors,
  getSelectedCreditors,
  getSearchValue,
  findAvailableGroups,
  isAllGroup,
} from "us.common/functions/creditorSelection";
import _ from "lodash";
import { $Table, $Button, $Skeleton, $Checkbox } from "us.common/components";
import { GetCreditorsRequestObject } from "./Repository";
import { handleAllCreditorClick } from "../Functions";

/**
 * @description - Component for creditor selection form.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2776727606/Select+Creditors+-+Common+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const SelectionWithRedux: React.FC<ISelection & PropsFromRedux> = ({
  name,
  creditorNoName,
  groupName,
  isInitLoading,
  isCreditorsLoading,
  removeCreditors,
  selectCreditors,
  getCreditors,
  getInitData,
  onSelect,
  onClose,
  onConfirm,
  disabledKeys = [],
  disabledPropName = "pid",
  multiple = true,
  withDrawer = true,
  defaultAllSelectionFilter = false,
  groupType = GroupType.ALL,
  showCreditorGroup,
  creditorGroups,
  isGroupsLoading,
  creditors,
  selectedCreditors,
}) => {
  const { t } = useTranslation();

  const [field, , helpers] = useField(name);
  const [, , creditorNoHelpers] = useField(creditorNoName);
  const [selectedGroupField, , selectedGroupHelpers] = useField(groupName);

  const [searchData, setSearchData] = useState<ISearchData>({
    searchText: "",
    filterTable: null,
  });
  const [columnFilters, setColumnFilters] = useState<any>(InitialFilters);

  const creditorIds = getCreditorIds(creditors, disabledKeys, disabledPropName);

  const selectedGroupIds =
    typeof selectedGroupField.value !== "undefined" && !selectedGroupField.value?.includes("0")
      ? selectedGroupField.value
      : [-1];

  /**
   * @description - Fetch groups and creditors.
   */
  useEffect(() => {
    getInitData &&
      getInitData({
        groupId: selectedGroupIds,
        selectedKeys: multiple ? field.value : [field.value],
        type: groupType,
      });
  }, []);

  /**
   * @description - Initially filter selected creditors.
   */
  useEffect(() => {
    if (!isInitLoading) {
      if (field.value?.length == 1 && field.value?.includes(-1)) {
        onFilter(FilterOptions.SELECTED);
      } else {
        onFilter(defaultAllSelectionFilter ? FilterOptions.ALL : FilterOptions.SELECTED);
      }
    }
  }, [isInitLoading]);

  /**
   * @description -  Handle multiple row selection in table.
   * @returns {object} - properties of ant table
   */
  const rowSelection = () => {
    return {
      onChange: (selectedRowKeys: any[]) => {
        selectCreditors && selectCreditors({ selectedKeys: selectedRowKeys });
        onSelect && onSelect(selectedRowKeys);
      },
      getCheckboxProps: (record: any) => ({
        disabled: disabledKeys.includes(Number(record[disabledPropName])),
        key: record.creditorId,
      }),
      selectedRowKeys: selectedCreditors,
      preserveSelectedRowKeys: true,
    };
  };

  /**
   * @description - Selection filter for the creditors.
   * @param {object} values - Formik values.
   */
  const onFilter = (value: any) => {
    setSearchData({
      ...searchData,
      filterTable: filterCreditors(value, creditors, selectedCreditors),
    });
    setColumnFilters(InitialFilters);
  };

  /**
   * @description - Reset search text and selection filters.
   * @param {object} restProps - Formik props.
   */
  const resetFilters = (restProps: any) => {
    restProps.setFieldValue("selectionFilter", FilterOptions.ALL);
    setSearchData({
      ...searchData,
      searchText: "",
      filterTable: null,
    });
  };

  /**
   * @description - onChange handler for the autoComplete component.
   * @param {string} searchValue - Search input value.
   * @param {object} values - Formik values.
   * @param {object} restProps - Formik props.
   */
  const handleOnSearchTextChange = (searchValue: string, values: any, restProps: any) => {
    const formattedGroupId = isAllGroup(values) ? [-1] : values.creditorGroup;

    if (!searchValue && getCreditors) {
      if (typeof values.creditorGroup === "undefined" || !values.creditorGroup?.length) {
        removeCreditors && removeCreditors({ groupId: 0 });
      } else {
        getCreditors({
          groupId: formattedGroupId,
          type: groupType,
          isClear: true,
        });
      }
      resetFilters(restProps);
    } else {
      setSearchData({
        ...searchData,
        searchText: searchValue,
      });
    }
  };

  /**
   * @description - onSearch handler for the autoComplete search button.
   * @param {string} searchValue - Search input value.
   * @param {object} values - Formik values.
   * @param {object} restProps - Formik props.
   */
  const handleOnPressSearch = (searchValue: string, values: any, restProps: any) => {
    const searchText = getSearchValue(searchValue);
    const formattedGroupId = isAllGroup(values) ? [-1] : values.creditorGroup;

    if (getCreditors) {
      getCreditors(GetCreditorsRequestObject.call({
        searchValue,
        formattedGroupId,
        searchText,
        groupType
      }));
      resetFilters(restProps);
      setColumnFilters(InitialFilters);
    }
  };

  /**
   * @description - Form submission.
   * @param {object} data - Form values.
   */
  const handleSubmit = (data: any) => {
    let entityIds =
      data.creditorGroup?.length == 1 &&
      data.creditorGroup?.includes("0") &&
      selectedCreditors?.length === creditorIds.length
        ? [-1]
        : selectedCreditors;

    entityIds = !multiple ? selectedCreditors[0] : entityIds;

    const selectedCreditorsWithData = getSelectedCreditors(creditors, selectedCreditors);

    //Set creditor Ids
    helpers.setValue(entityIds);

    //Set creditor no
    selectedCreditors?.length > 0 &&
      !multiple &&
      creditorNoHelpers.setValue(selectedCreditorsWithData[0]?.pid);

    //Set group ids
    const availableGroups: Array<any> = findAvailableGroups(
      selectedCreditorsWithData,
      data.creditorGroup,
      creditorIds
    );

    selectedGroupHelpers.setValue(availableGroups);

    onConfirm && onConfirm(entityIds, availableGroups, selectedCreditorsWithData);
  };

  return (
    <Formik
      initialValues={{
        creditorGroup:
          selectedGroupField.value?.includes("0") || !selectedGroupField.value
            ? undefined
            : selectedGroupField.value,
        extraColumns: [],
        selectionFilter: defaultAllSelectionFilter ? FilterOptions.ALL : FilterOptions.SELECTED,
        searchOptions: SearchByOptions,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setValues,
        ...restProps
      }: any) => (
        <>
          {showCreditorGroup && (
            <GroupSelection
              creditorsFieldValue={field.value}
              groupType={groupType}
              resetFilters={() => resetFilters(restProps)}
              onSelect={(selectedKeys: any[]) => onSelect && onSelect(selectedKeys)}
              creditorGroups={creditorGroups}
              isGroupsLoading={isGroupsLoading}
              selectedCreditors={selectedCreditors}
              getCreditors={getCreditors}
              removeCreditors={removeCreditors}
              creditors={creditors}
            />
          )}

          <SearchFilters
            withDrawer={withDrawer}
            multiple={multiple}
            searchData={searchData}
            onFilter={onFilter}
            handleAutoCompleteChange={(searchValue: string) =>
              handleAutoCompleteChange(searchValue, setValues)
            }
            handleOnSearchTextChange={(searchValue: string) =>
              handleOnSearchTextChange(searchValue, values, restProps)
            }
            handleOnPressSearch={(value: any) => handleOnPressSearch(value, values, restProps)}
            isGroupsLoading={isGroupsLoading}
            isCreditorsLoading={isCreditorsLoading}
            selectedCreditors={selectCreditors}
          />

          <>
            <$Skeleton
              loading={isGroupsLoading || isCreditorsLoading}
              active
              paragraph={{ rows: 3 }}
            >
              <$Table
                rowKey={(record) => record.creditorId}
                rowSelection={{
                  type: multiple ? "checkbox" : "radio",
                  hideSelectAll: true,
                  columnTitle: multiple ? (
                    <$Checkbox
                      name="isAllCreditors"
                      checked={
                        creditorIds?.length !== 0 &&
                        selectedCreditors?.length === creditorIds.length
                      }
                      onChange={() => handleAllCreditorClick(selectedCreditors,creditorIds,selectCreditors,onSelect)}
                    />
                  ) : null,
                  ...rowSelection(),
                }}
                columns={TableColumns(values?.extraColumns, columnFilters)}
                dataSource={searchData.filterTable == null ? creditors : searchData.filterTable}
                bordered
                className="mt-3"
                onChange={(pagination: any, filters: any, sorter: any) => {
                  setColumnFilters({
                    filteredInfo: filters,
                    sortedInfo: sorter,
                  });
                }}
              />
            </$Skeleton>
          </>

          {withDrawer && (
            <div className="drawer-footer-fixed align-items-end justify-content-between">
              <div className="d-flex flex-column">
                {multiple && (
                  <>
                    <div className="font-weight-bold">
                      {isGroupsLoading
                        ? 0
                        : selectedGroupIds?.includes(-1) &&
                          creditors.length == 0 &&
                          typeof values.creditorGroup === "undefined"
                        ? t("COMMON.ALL")
                        : selectedCreditors?.length}
                    </div>
                    <div>{t("US.COLLECTION.COMMON:COMMON.SELECTED_CREDITORS")}</div>
                  </>
                )}
              </div>

              <div>
                <$Button
                  className="ml-3 mr-2"
                  data-testid="confirm-selection"
                  type="primary"
                  disabled={isGroupsLoading || selectedCreditors?.length === 0}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {t("US.COLLECTION.COMMON:COMMON.SELECT")}
                </$Button>
                <$Button onClick={onClose} data-testid="cancel-selection">
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { creditorSelection } = state;

  const { groups, creditors, isInitLoading } = creditorSelection;
  return {
    creditorGroups: groups.data,
    isGroupsLoading: groups.isLoading,
    isCreditorsLoading: creditors.isLoading,
    isInitLoading,
    creditors: creditors.data,
    selectedCreditors: creditors.selected,
  };
};

const { creditors, init } = Actions.creditorSelectionActions;

const mapDispatchToProps = {
  getCreditors: creditors.get,
  getInitData: init.get,
  selectCreditors: creditors.select,
  removeCreditors: creditors.remove,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SelectionWithRedux);
