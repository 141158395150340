import { versionedHttpCollection } from "us.helper/http/collection";

const testData = {
  smsSchedule: [
    {
      dayId: 1,
      day: "Sunday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 2,
      day: "Monday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 3,
      day: "Tuesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 4,
      day: "Wednesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 5,
      day: "Thursday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 6,
      day: "Friday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 7,
      day: "Saturday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
  ],
  printingSchedule: [
    {
      dayId: 1,
      day: "Sunday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 2,
      day: "Monday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 3,
      day: "Tuesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 4,
      day: "Wednesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 5,
      day: "Thursday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 6,
      day: "Friday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 7,
      day: "Saturday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
  ],
  emailSchedule: [
    {
      dayId: 1,
      day: "Sunday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 2,
      day: "Monday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 3,
      day: "Tuesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 4,
      day: "Wednesday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 5,
      day: "Thursday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 6,
      day: "Friday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
    {
      dayId: 7,
      day: "Saturday",
      timeSlots: { startTime: "00:00:00", endTime: "23:59:00" },
    },
  ],
};

const redDaysData = [
  {
    id: 1,
    date: "2020-05-10",
    reason: "One Red Day",
    isFixedDate: false,
    criteria: [
      { type: "nonBanking", value: true },
      { type: "nonSms", value: false },
      { type: "nonPrinting", value: true },
      { type: "nonWorkflow", value: false },
      { type: "nonEmail", value: false },
    ],
  },
  {
    id: 2,
    date: "2020-04-12T00:00:00",
    reason: "Two Red Day",
    isFixedDate: true,
    criteria: [
      { type: "nonBanking", value: false },
      { type: "nonSms", value: false },
      { type: "nonPrinting", value: true },
      { type: "nonWorkflow", value: true },
      { type: "nonEmail", value: true },
    ],
  },
];
export default {
  calendar: {
    update: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.post(
            "bmdservice",
            "calendars",
            params,
            'v1'
          );
          if (status == 201) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    init: <P extends {
      calendarId: number; dayId: number;
      startDate: string;
      endDate: string;
      criteria: string;
    }>(params: P): Promise<Array<any>> => {
      const { calendarId, criteria, startDate, endDate, dayId } = params;
      return new Promise<any>(async (resolve, reject) => {
        try {
          /**
           * Get Schedule time
           */
          const scheduleTime = await versionedHttpCollection.get(
            "bmdservice",
            "schedulesDays",
            {
              calendarId,
              dayId,
            },
            'v1'
          );

          /**
           * Get Red Days
           */
          const redDays = await versionedHttpCollection.get(
            "bmdservice",
            `redDays`,
            { calendarId, criteria, startDate, endDate },
            'v1'
          );

          resolve({
            scheduleTime: scheduleTime.status == 200 ? scheduleTime.data : {},
            redDays:
              redDays.status == 200 ? redDays.data : [],
          });
        } catch (error) {
          reject(error);
        }
      });
    }
  },

  ScheduleTimeSlots: {
    get: <P extends { calendarId: number; dayId: number }>(
      params: P
    ): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        const { calendarId, dayId } = params;
        try {
          const { data, status } = await versionedHttpCollection.get(
            "bmdservice",
            "schedulesDays",
            {
              calendarId,
              dayId,
            },
            'v1'
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  redDays: {
    get: <
      P extends {
        calendarId: number;
        startDate: string;
        endDate: string;
        criteria: string;
      }
    >(
      params: P
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { calendarId, criteria, startDate, endDate } = params;
          const { data, status } = await versionedHttpCollection.get(
            "bmdservice",
            `redDays`,
            { calendarId, criteria, startDate, endDate },
            'v1'
          );
          if (status == 200) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
