import { sortFunction } from "us.common/components/MainSearch/Functions";
import {
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { ITab } from "../Interfaces";

/**
 * @function
 * @description To get the creditor search results sorted
 * @param creditorDetails Filtered Creditor search results
 * @param tabInfo Formik tab information
 * @returns sorted input
 */
export const getCreditorValSorted = (
  creditorDetails: ICreditorGroupSearchResult[],
  {
    sortKey,
    sortOrder,
  }: ITab<ICreditorGroupSearchResult & ICreditorSearchResult>
) => {
  try {
    let creditorGroupLevelSort = false;
    if (!creditorDetails || creditorDetails.length === 0) {
      creditorGroupLevelSort = false;
    } else if (
      Object.keys(creditorDetails.length > 0 ? creditorDetails[0] : []).filter(
        (creditorgroup) => creditorgroup === sortKey
      ).length > 0
    ) {
      creditorGroupLevelSort = true;
    }
    return creditorGroupLevelSort
      ? creditorDetails.sort((value1, value2) =>
          sortFunction(
            value1[sortKey as keyof ICreditorGroupSearchResult],
            value2[sortKey as keyof ICreditorGroupSearchResult],
            sortOrder
          )
        )
      : (creditorDetails as ICreditorGroupSearchResult[]).map(
          (creditorGroup) => ({
            ...creditorGroup,
            creditors: creditorGroup.creditors.sort((value1, value2) =>
              sortFunction(
                value1[sortKey as keyof ICreditorSearchResult],
                value2[sortKey as keyof ICreditorSearchResult],
                sortOrder
              )
            ),
          })
        );
  } catch (e) {
    return [];
  }
};
