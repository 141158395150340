import React from "react";
import { Form } from "antd";
import { useField } from "formik";
import * as Utility from "../utility";
import { useTranslation } from "react-i18next";
import { IInputAmountParams } from "us.common/components/antd/Interfaces";
import ReactNumeric from "react-numeric";
import PropTypes from "prop-types";

const $I = <T extends IInputAmountParams>({
  name,
  value,
  label,
  tooltip,
  currentLanguage,
  currentCurrency,
  placeholder,
  size,
  disabled = false,
  className,
  allowClear,
  required,
  suffix,
  prefix,
  maxLength,
  onBlur,
  onChange,
  addonBefore,
  onPressEnter,
  onKeyPress,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  style,
  defaultValue,
  tabIndex,
  formitem,
  dataTestid,
}: T) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();

  const handleEvent = (event: any, value: number) => {
    // handle copy event
    if (event && event.ctrlKey && event.key == "x") {
      setTimeout(() => {
        typeof onChange == "function"
          ? onChange(0)
          : helpers?.setValue(undefined);
      }, 0);
    }

    // handle paste event
    if (event && event.ctrlKey && event.key == "v") {
      typeof onChange == "function"
        ? onChange(value)
        : helpers?.setValue(value);
    }
  };

  return (
    <Form.Item
      label={label}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
      required={typeof required == "undefined" ? false : true}
      extra={formitem?.extra}
      tooltip={tooltip}
    >
      <div className="ant-form-item-control-input-content">
        <div
          className={`ant-input-number ant-input-number-sm ${className} ${
            disabled && "ant-input-number-disabled"
          }`}
        >
          <div className="ant-input-number-input-wrap">
            <ReactNumeric
              id={name}
              currencySymbol=""
              {...field}
              onBlur={(e: any) => {
                onBlur && onBlur(e);
                field.onBlur(e);
              }}
              data-testid={dataTestid}
              style={style}
              tabIndex={tabIndex}
              disabled={disabled}
              placeholder={placeholder}
              className="ant-input-number-input"
              decimalCharacter={currentLanguage === "en-GB" ? "." : ","}
              digitGroupSeparator={currentLanguage === "en-GB" ? "," : " "}
              onChange={(e: any, value: number) =>
                typeof onChange == "function"
                  ? onChange(value)
                  : helpers?.setValue(value)
              }
              onKeyPress={onKeyPress}
              value={value}
              maximumValue={
                max != undefined ? max.toString() : Number.MAX_SAFE_INTEGER
              }
              minimumValue={
                min != undefined ? min.toString() : Number.MIN_SAFE_INTEGER
              }
              onKeyDown={handleEvent}
            />
          </div>
        </div>
      </div>
      {isTouched && error && (
        <div className="ant-form-item-explain ant-form-item-explain-error"></div>
      )}
    </Form.Item>
  );
};

$I.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  onPressEnter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $InputAmount = $I;
