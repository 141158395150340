import React from "react";
import {
  Creditor,
  TabController,
} from "us.common/components/MainSearch/Components";
import { ICreditorTab } from "us.common/components/MainSearch/Interfaces";

/**
 * @description - Tab container for creditor result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const CreditorTab: React.FC<ICreditorTab> = (props) => {
  const {
    creditorData,
    tabInfo,
    isLoading,
    currentHit,
    paginationChangeEvent,
    totalCreditorSearchResult,
    creditorGroupClickEvent,
    changeSortOrderEvent,
  } = props;
  return (
    <TabController
      isLoading={isLoading}
      changeSortOrderEvent={changeSortOrderEvent}
      tabInfo={tabInfo}
    >
      <Creditor
        currentHit={currentHit}
        paginationChangeEvent={paginationChangeEvent}
        creditorData={creditorData}
        handleCreditorGroupClick={creditorGroupClickEvent}
        totalSearchResult={totalCreditorSearchResult}
      ></Creditor>
    </TabController>
  );
};
