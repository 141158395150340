import { initialState } from "us.collection.admin/reducers/NonProductionDays/State";
import { NonProductionDay } from "us.collection.admin/constants/Actions";
import {
  addOrUpdateRedDayRelevantCriteria,
  removeRedDayFromRelevantCriteria,
  setRedDayForRelevantCriteria
} from "us.collection.admin/functions";
import _ from "lodash";

const {
  GET_NON_PRODUCTION_DAYS,
  GET_NON_PRODUCTION_DAYS_SUCCESS,
  GET_NON_PRODUCTION_DAYS_FAIL,
  SAVE_NON_PRODUCTION_DAYS,
  MANAGE_TABS,
  GET_SCHEDULES_TIME_SLOTS,
  GET_SCHEDULES_TIME_SLOTS_FAIL,
  GET_SCHEDULES_TIME_SLOTS_SUCCESS,
  DELETE_NON_PRODUCTION_DAYS,
  INIT_NON_PRODUCTION,
  INIT_NON_PRODUCTION_SUCCESS,
  INIT_NON_PRODUCTION_FAIL,
  SAVE_CALENDAR,
  SAVE_CALENDAR_SUCCESS,
  SAVE_CALENDAR_FAIL
} = NonProductionDay;


export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SAVE_NON_PRODUCTION_DAYS:
      const { data } = payload
      return Object.assign({}, state, {
        redDays: {
          ...state.redDays,
          ...addOrUpdateRedDayRelevantCriteria(_.omit(state.redDays, ["data", "isFetching"]), data)
        },
      });
    case MANAGE_TABS:
      return Object.assign({}, state, {
        selectedCriteria: payload.data.selectedCriteria
      });
    case INIT_NON_PRODUCTION:
      return Object.assign({}, state, {
        scheduleTime: {
          data: [],
          nonSms: [],
          nonEmail: [],
          nonPrinting: [],
          isFetching: true,
        },
        redDays: {
          data: [],
          nonBanking: [],
          nonSms: [],
          nonPrinting: [],
          nonWorkflow: [],
          nonEmail: [],
          isFetching: true,
        }
      });
    case INIT_NON_PRODUCTION_SUCCESS:
      const { scheduleTime, redDays } = payload.data
      const { smsSchedules: smsScheduleData = [], emailSchedules: emailScheduleData = [], printingSchedules: printingScheduleData = [] } = scheduleTime
      return Object.assign({}, state, {
        scheduleTime: {
          data: scheduleTime,
          nonSms: smsScheduleData,
          nonEmail: emailScheduleData,
          nonPrinting: printingScheduleData,
          isFetching: false,
        },
        redDays: {
          data: redDays,
          isFetching: false,
          ...setRedDayForRelevantCriteria(redDays)
        },
      });
    case INIT_NON_PRODUCTION_FAIL:
      return Object.assign({}, state, {
        scheduleTime: {
          data: [],
          nonSms: [],
          nonEmail: [],
          nonPrinting: [],
          isFetching: false,
        },
        redDays: {
          data: [],
          nonBanking: [],
          nonSms: [],
          nonPrinting: [],
          nonWorkflow: [],
          nonEmail: [],
          isFetching: false,
        }
      })

    case GET_SCHEDULES_TIME_SLOTS:
      return Object.assign({}, state, {
        scheduleTime: {
          data: [],
          nonSms: [],
          nonEmail: [],
          nonPrinting: [],
          isFetching: true,
        },
      });
    case GET_SCHEDULES_TIME_SLOTS_SUCCESS:
      const { smsSchedules, emailSchedules, printingSchedules } = payload.data
      return Object.assign({}, state, {
        scheduleTime: {
          data: payload.data,
          nonSms: smsSchedules,
          nonEmail: emailSchedules,
          nonPrinting: printingSchedules,
          isFetching: false,
        },
      });
    case GET_SCHEDULES_TIME_SLOTS_FAIL:
      return Object.assign({}, state, {
        scheduleTime: {
          data: [],
          nonSms: [],
          nonEmail: [],
          nonPrinting: [],
          isFetching: false,
        },
      })
    case DELETE_NON_PRODUCTION_DAYS:
      return Object.assign({}, state, {
        redDays: {
          ...state.redDays,
          ...removeRedDayFromRelevantCriteria(_.omit(state.redDays, ["data", "isFetching"]), payload.data)
        },
      })
    case GET_NON_PRODUCTION_DAYS:
      return Object.assign({}, state, {
        redDays: {
          data: [],
          nonBanking: [],
          nonSms: [],
          nonPrinting: [],
          nonWorkflow: [],
          nonEmail: [],
          isFetching: true,
        }
      })
    case GET_NON_PRODUCTION_DAYS_SUCCESS:
      return Object.assign({}, state, {
        redDays: {
          data: payload.data,
          isFetching: false,
          ...setRedDayForRelevantCriteria(payload.data)
        },
      })
    case GET_NON_PRODUCTION_DAYS_FAIL:
      return Object.assign({}, state, {
        redDays: {
          data: [],
          nonBanking: [],
          nonSms: [],
          nonPrinting: [],
          nonWorkflow: [],
          nonEmail: [],
          isFetching: false,
        }
      })
    case SAVE_CALENDAR:
      return Object.assign({}, state, {
        isSubmitting: true
      })
    case SAVE_CALENDAR_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false
      })
    case SAVE_CALENDAR_FAIL:
      return Object.assign({}, state, {
        isSubmitting: false
      })
    default:
      return state;
  }
};
