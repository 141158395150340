import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { $TableTree, $Skeleton, $DateLabel } from 'us.common/components';
import { roleManagementActions } from 'us.common/actions';
import moment from 'moment';
import { RootState } from 'us.helper/types';

const { role } = roleManagementActions;

const RoleManagement: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();

	const { getHistory, drawer, roleHistory } = props;
	const { roleId } = drawer;
	const { data, isFetching } = roleHistory;

	useEffect(() => {
		getHistory && getHistory({ roleId });
	}, []);

	const columns: any = [
		{
			title: t('US.COMMON:MANAGE_ROLES.DISPLAY_NAME'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
		{
			title: t('US.COMMON:MANAGE_ROLES.DATE_&_TIME'),
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: 160,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: 'date',
			customRenderChild: (text: any, record: any) => {
				return (
					moment
						.utc(record?.createdOn)
						.isValid() && (
						<$DateLabel
							value={
								record?.createdOn
							}
						/>
					)
				);
			},
		},
		{
			title: t('US.COMMON:COMMON.COMMENT'),
			dataIndex: 'comment',
			key: 'comment',
			ellipsis: true,
			customSorter: (a: any, b: any) => a?.localeCompare(b),
			customFilter: true,
		},
	];

	return (
		<$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
			<$TableTree
				rowKey='roleHistoryId'
				data={data}
				columns={columns}
				size='small'
				className='mt-3'
				onSort={(sortData, dataSource) =>
					sortData(dataSource)
				}
				onFilter={(searchData, dataSource) =>
					searchData(dataSource)
				}
				filterOnType={true}
				resetOnSourceChange={true}
				bordered
				pagination={{ defaultPageSize: 200 }}
				scroll={{ x: '100%', y: 'calc(100vh - 215px)' }}
			/>
		</$Skeleton>
	);
};

const mapStateToProps = (state: RootState) => {
	const { roleManagement } = state;
	const { drawer, roleHistory } = roleManagement;
	return {
		drawer,
		roleHistory,
	};
};
const mapDispatchToProps = {
	getHistory: role.getHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RoleManagement);
