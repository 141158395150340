export enum MainSearchConstants {
	GET_ALL_SEARCH_DATA = 'GET_ALL_SEARCH_DATA',
	GET_ALL_SEARCH_DATA_SUCCESS = 'GET_ALL_SEARCH_DATA_SUCCESS',
	GET_ALL_SEARCH_DATA_FAIL = 'GET_ALL_SEARCH_DATA_FAIL',

	GET_INVOICE_REF_SEARCH_DATA = 'GET_INVOICE_REF_SEARCH_DATA',
	GET_INVOICE_REF_SEARCH_DATA_SUCCESS = 'GET_INVOICE_REF_SEARCH_DATA_SUCCESS',
	GET_INVOICE_REF_SEARCH_DATA_FAIL = 'GET_INVOICE_REF_SEARCH_DATA_FAIL',

	GET_INVOICE_SEARCH_DATA = 'GET_INVOICE_SEARCH_DATA',
	GET_INVOICE_SEARCH_DATA_SUCCESS = 'GET_INVOICE_SEARCH_DATA_SUCCESS',
	GET_INVOICE_SEARCH_DATA_FAIL = 'GET_INVOICE_SEARCH_DATA_FAIL',

	GET_CASE_SEARCH_DATA = 'GET_CASE_SEARCH_DATA',
	GET_CASE_SEARCH_DATA_SUCCESS = 'GET_CASE_SEARCH_DATA_SUCCESS',
	GET_CASE_SEARCH_DATA_FAIL = 'GET_CASE_SEARCH_DATA_FAIL',

	GET_DEBTOR_SEARCH_DATA = 'GET_DEBTOR_SEARCH_DATA',
	GET_DEBTOR_SEARCH_DATA_SUCCESS = 'GET_DEBTOR_SEARCH_DATA_SUCCESS',
	GET_DEBTOR_SEARCH_DATA_FAIL = 'GET_DEBTOR_SEARCH_DATA_FAIL',

	GET_CREDITOR_SEARCH_DATA = 'GET_CREDITOR_SEARCH_DATA',
	GET_CREDITOR_SEARCH_DATA_SUCCESS = 'GET_CREDITOR_SEARCH_DATA_SUCCESS',
	GET_CREDITOR_SEARCH_DATA_FAIL = 'GET_CREDITOR_SEARCH_DATA_FAIL',

	GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY',
	SAVE_SEARCH_HISTORY = 'SAVE_SEARCH_HISTORY',
	GET_SEARCH_HISTORY_SUCCESS = 'GET_SEARCH_HISTORY_SUCCESS',
	GET_SEARCH_HISTORY_FAIL = 'GET_SEARCH_HISTORY_FAIL',

	TOGGLE_DEBTOR_AR = 'TOGGLE_DEBTOR_AR',
	TOGGLE_CREDITOR_GROUP = 'TOGGLE_CREDITOR_GROUP',

	BROWSER_BACK_NAVIGATION = 'BROWSER_BACK_NAVIGATION',

	UPDATE_VIEWED_LIST = 'UPDATE_VIEWED_LIST',
}
