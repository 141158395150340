import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import {
	CloseCircleOutlined,
	BoxIcons,
	IconTypes,
	UserOutlined,
	ExclamationCircleFilled,
} from 'us.icons';
import Constants from 'us.common/constants';
import {
	changeLanguage,
	navigateStart,
	userManagementActions,
} from 'us.common/actions';
import {
	$Layout,
	$Menu,
	$Dropdown,
	$Badge,
	MegaMenu,
	SearchBar,
	NotificationMenu,
	$Avatar,
	$Typography,
	$Divider,
	$Popover,
	$Button,
} from 'us.common/components';
import { MenuButtonStyle, MenuStyle } from './Constants';
import { useMsal } from '@azure/msal-react';
import { BrowserUtils } from '@azure/msal-browser';
import Common from 'us.common';
import { i18n } from 'us.helper';
import './AppLayout.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { IAppLayoutProps, ILanguageChangePopover } from './Interfaces';
import * as Azure from 'us.helper/azure';

const { user, preferredLanguage } = userManagementActions;

const { Content } = $Layout;

const { Text } = $Typography;

/**
 * @description - Main application layout.
 * @author Samitha Hewawasam <samitha@unicorn-solutions.com>
 * @since 23/05/2022
 * */
const AppLayout: React.FC<PropsFromRedux & IAppLayoutProps> = ({
	currentLanguage,
	children,
	headerExtra,
	userProfilePhoto,
	tenant,
	megaMenu = true,
	enableSearch = true,
	changeLanguage,
	updatePreferredLanguage,
}) => {
	const { t } = useTranslation(['US.COLLECTION.COMMON'], {
		useSuspense: true,
	});

	const auth = new Azure.ADAuth();
	const currentUser: any = auth.currentUser();

	const { instance } = useMsal();
	const { push } = useHistory();
	const { state } = useLocation();

	const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
	const [ellipsis] = useState(true);
	const [LanguagePopover, setLanguagePopover] =
		useState<ILanguageChangePopover>({
			selectedLanguage: currentLanguage,
			visibility: false,
		});

	const menuRef = useRef<any>();

	useEffect(() => {
		if (megaMenu) {
			// add when mounted
			document.addEventListener('mousedown', onClickMenuOutSide);

			// return function to be called when unmounted
			return () => {
				document.removeEventListener('mousedown', onClickMenuOutSide);
			};
		}
	}, []);

	/**
	 * @function
	 * @description - Function for handle user logout.
	 */
	const handleLogout = () => {
		// remove saved quick links
		localStorage.removeItem('@quickLinks');
		instance.logoutRedirect({
			account: instance.getActiveAccount(),
			onRedirectNavigate: () => !BrowserUtils.isInIframe(),
		});
	};

	/**
	 * @function
	 * @description - If the click is outside the menu, close the menu.
	 * @param e - this is the event object that is passed to the function.
	 */
	const onClickMenuOutSide = (e: any) => {
		if (menuRef.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		setMenuOpen(false);
	};

	/**
	 * @function
	 * @description - Menu and header navigation's.
	 * @param {string} url - navigation URL.
	 */
	const navigate = (url: string, locationState?: any) => {
		push(
			url,
			locationState && Object.keys(locationState).length > 0
				? locationState
				: state
		);
		setMenuOpen(false);
	};

	/**
	 * @function
	 * @description - Handle language change.
	 * @param {string} lngCode - selected language.
	 */
	const changeLanguageHandler = (lngCode: string) => {
		let languageCode = 'no';
		let language: string = Common.Constants.languageCodes.nbNO,
			currency: string = Common.Constants.currencyCodes.nbNO,
			dateFormat: string = Common.Constants.dateFormats.nbNO;
		switch (lngCode) {
			case Common.Constants.languageCodes.enUS:
				languageCode = 'en';
				i18n.changeLanguage('en');
				language = Common.Constants.languageCodes.enUS;
				currency = Common.Constants.currencyCodes.enUS;
				dateFormat = Common.Constants.dateFormats.enUS;
				break;

			case Common.Constants.languageCodes.enGB:
				languageCode = 'en';
				i18n.changeLanguage('en');
				language = Common.Constants.languageCodes.enGB;
				currency = Common.Constants.currencyCodes.enGB;
				dateFormat = Common.Constants.dateFormats.enGB;
				break;

			case Common.Constants.languageCodes.nbNO:
				languageCode = 'no';
				i18n.changeLanguage('no');
				language = Common.Constants.languageCodes.nbNO;
				currency = Common.Constants.currencyCodes.nbNO;
				dateFormat = Common.Constants.dateFormats.nbNO;
				break;

			case Common.Constants.languageCodes.deDE:
				languageCode = 'no';
				i18n.changeLanguage('no');
				language = Common.Constants.languageCodes.deDE;
				currency = Common.Constants.currencyCodes.deDE;
				dateFormat = Common.Constants.dateFormats.deDE;
				break;

			case Common.Constants.languageCodes.jaJP:
				languageCode = 'no';
				i18n.changeLanguage('no');
				language = Common.Constants.languageCodes.jaJP;
				currency = Common.Constants.currencyCodes.jaJP;
				dateFormat = Common.Constants.dateFormats.jaJP;
				break;

			default:
				languageCode = 'no';
				i18n.changeLanguage('no');
				break;
		}

		changeLanguage(language, currency, dateFormat);
		// update user with preferred language
		updatePreferredLanguage &&
			updatePreferredLanguage({
				userId: currentUser?.oid,
				languageCode,
			});
	};

	/**
	 * @function
	 * @description handle language confirm popover
	 * @param {string} languageCode- language code for selected language
	 */
	const languagePopoverHandler = (languageCode: string) => {
		setLanguagePopover({ selectedLanguage: languageCode, visibility: true });
	};

	const languageMenu = (
		<$Menu
			selectedKeys={
				currentLanguage == Constants.languageCodes.nbNO ? ['1'] : ['2']
			}>
			<$Menu.Item
				key='1'
				onClick={() => languagePopoverHandler(Constants.languageCodes.nbNO)}>
				Norwegian
			</$Menu.Item>
			<$Menu.Item
				key='2'
				onClick={() => languagePopoverHandler(Constants.languageCodes.enGB)}>
				English-US
			</$Menu.Item>
		</$Menu>
	);

	const recept = (
		<$Menu className='profile-menu-wrap'>
			<$Menu.Item key='1'>
				<div className='d-flex align-items-center'>
					<div className='mr-2'>
						<$Avatar
							src={userProfilePhoto}
							style={{
								backgroundColor: '#5E54BA',
							}}
							size={48}
							icon={<UserOutlined />}
						/>
					</div>
					<div className='d-flex flex-column profile-info'>
						<Text
							className='font-weight-bold '
							ellipsis={
								ellipsis
									? {
											tooltip: {},
									  }
									: false
							}>
							{instance.getActiveAccount()?.name
								? instance.getActiveAccount()?.name
								: t('US.COLLECTION.COMMON:COMMON.RECEPTIONIST')}
						</Text>
						<Text
							className='text-muted'
							style={{
								fontSize: '0.7rem',
							}}
							ellipsis={
								ellipsis
									? {
											tooltip: `${instance.getActiveAccount()?.username}`,
									  }
									: false
							}>
							{instance.getActiveAccount()?.username}{' '}
						</Text>
					</div>
				</div>
			</$Menu.Item>

			<$Divider className='my-2'></$Divider>
			<$Menu.Item key='2' onClick={() => handleLogout()}>
				{t('US.COLLECTION.COMMON:COMMON.SIGN_OUT')}
			</$Menu.Item>
		</$Menu>
	);

	return (
		<>
			<$Layout className='layout'>
				<div className='us-header'>
					<div className='logo' onClick={() => navigate('/')}>
						<$Badge
							count={`v${sessionStorage.getItem('productVersion')}`}
							style={{
								backgroundColor: '#49409A',
								color: '#a39dd6',
								boxShadow: '0 0 0 1px #a39dd6 inset',
							}}
							offset={[8, 14]}>
							<svg
								width='48'
								height='48'
								viewBox='0 0 48 48'
								version='1.1'
								xmlns='http://www.w3.org/2000/svg'>
								<rect fill='#49409A' width='48' height='48' />
								<g>
									<path fill='#4B429A' d='M0,0h48v48H0V0z' />
									<path
										fill='#FFFFFF'
										d='M33.2,19l-6.9-6.8L18.2,20l7,6.8c1.7,1.7,1.7,3.8,0.7,4.6c-0.9,0.8-2.6,0.6-4.3-1l-7-6.8
		L12.2,26l7,6.8c3,3,6.4,4.4,9.6,1.4c1.4-1.4,1.7-3.6,0.8-5.4c1.6,0.7,3.5,0.4,4.7-0.8C37,25.2,36.1,21.8,33.2,19z M31.4,25.4
		c-0.8,0.8-2,0.9-3.8-0.8l-4.2-4.1l3.2-3.1l4.2,4.1C32.6,23.1,32,24.6,31.4,25.4z'
									/>
									<path
										fill='#60BB4A'
										d='M12.1,17.3h4.6v4.6h-4.6C12.1,21.8,12.1,17.3,12.1,17.3z'
									/>
								</g>
							</svg>
						</$Badge>
					</div>
					{megaMenu && (
						<div className='us-mega-menu' ref={menuRef}>
							<div
								className={
									isMenuOpen
										? MenuButtonStyle.SELECTED
										: MenuButtonStyle.UN_SELECTED
								}
								onClick={() => setMenuOpen(!isMenuOpen)}
								id='megaMenuBtn'>
								<span className='mr-1'>
									{t('US.COLLECTION.COMMON:COMMON.MENU')}
								</span>
								<CloseCircleOutlined
									className='mega-menu-close'
									type='close-circle'
								/>
							</div>
							<MegaMenu
								style={isMenuOpen ? MenuStyle.OPEN : MenuStyle.CLOSE}
								isMenuOpen={isMenuOpen}
								onNavigate={navigate}
							/>
						</div>
					)}
					{enableSearch && (
						<div className='center-search' onClick={() => setMenuOpen(false)}>
							<div className='d-flex align-items-center justify-content-end auto-complete-con'>
								<SearchBar />
							</div>
						</div>
					)}
					{headerExtra}
					<div className='header-options flex-column align-items-start tenant-wrap'>
						<Text
							ellipsis={
								ellipsis
									? {
											tooltip: `${tenant?.displayName}`,
									  }
									: false
							}>
							{tenant?.displayName}{' '}
						</Text>
					</div>

					<div className='d-flex mr-3'>
						<NotificationMenu />

						<$Popover
							placement='bottomRight'
							content={
								<div style={{ maxWidth: '300px' }}>
									<div className='ant-popover-message'>
										<ExclamationCircleFilled />
										<div className='ant-popover-message-title'>
											<div
												style={{ paddingBottom: '0.3rem' }}
												data-testid={'language-change-conformation'}>
												{/* Are you sure, You want to change the language? */}
												{t(
													'US.COLLECTION.COMMON:COMMON.DO_YOU_WANT_TO_CHANGE_THE_LANGUAGE?'
												)}
											</div>
										</div>
									</div>
									<div className='ant-popover-buttons'>
										<$Button
											size='small'
											onClick={() => {
												setLanguagePopover({
													...LanguagePopover,
													visibility: false,
												});
											}}
											data-testid={'language-change-conformation-no'}>
											{t('US.COLLECTION.COMMON:COMMON.NO')}
										</$Button>
										<$Button
											size='small'
											type='primary'
											onClick={() => {
												changeLanguageHandler(LanguagePopover.selectedLanguage);
												setLanguagePopover({
													...LanguagePopover,
													visibility: false,
												});
											}}
											data-testid={'language-change-conformation-yes'}>
											{t('US.COLLECTION.COMMON:COMMON.YES')}
										</$Button>
									</div>
								</div>
							}
							trigger='click'
							visible={LanguagePopover.visibility}>
							<$Dropdown
								overlay={languageMenu}
								className='d-flex flex-row search-by-con'>
								<a
									className='d-flex align-items-center pt-1 ant-dropdown-link px-2'
									onClick={(e) => e.preventDefault()}>
									<$Badge
										count={currentLanguage == 'en-GB' ? 'ENG' : 'NO'}
										style={{
											backgroundColor: '#49409A',
											color: '#a39dd6',
											boxShadow: '0 0 0 1px #a39dd6 inset',
										}}>
										<BoxIcons
											className='icon-btn mb-1'
											type={IconTypes.BOX_ICON}
											name='language'
										/>
									</$Badge>
								</a>
							</$Dropdown>
						</$Popover>
					</div>
					<div className='header-options'>
						<$Dropdown
							trigger={['click']}
							overlay={recept}
							overlayClassName='profile-dropdown'>
							<a
								className='d-flex align-items-center ant-dropdown-link px-1'
								onClick={(e) => e.preventDefault()}>
								<$Avatar
									src={userProfilePhoto}
									style={{
										backgroundColor: '#5E54BA',
									}}
									icon={<UserOutlined />}
								/>
							</a>
						</$Dropdown>
					</div>
				</div>
				<Content>
					<div className='content'>{children}</div>
					<div id='hiddenOverlay'></div>
				</Content>
			</$Layout>
		</>
	);
};

const mapStateToProps = (state: any) => {
	const { common, UserInformation } = state;
	return {
		currentLanguage: common.currentLanguage,
		userProfilePhoto: UserInformation.userProfilePhoto,
		tenant: UserInformation.tenant,
	};
};

const mapDispatchToProps = {
	navigateStart,
	updateUser: user.update,
	updatePreferredLanguage: preferredLanguage.save,
	changeLanguage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppLayout);
