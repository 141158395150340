import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    feeTypeName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD"
    ),
    description: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD"
    ),
  });
};
