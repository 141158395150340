export function InitNonProductionDays(this: any) {

    const { calendarId = 1 } = this
    return {
        calendarId,
        criteria: "All",
        startDate: "",
        endDate: "",
        dayId: -1
    }
}