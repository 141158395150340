import {
  InvoiceSubCriterias,
  CaseSubCriterias,
  CreditorSubCriterias,
  DebtorSubCriterias,
  MainCriterias,
} from "us.common/constants";

/**
 * @function
 * @description To get the Field type for the API search criteria
 * @param mainCriteria The main search criteria selected
 * @param subCriteria The corrosponding sub-criteria selected
 * @returns The field value for the API search
 */
export const getFieldType = <
  T extends
    | InvoiceSubCriterias
    | CaseSubCriterias
    | CreditorSubCriterias
    | DebtorSubCriterias
    | undefined
>(
  mainCriteria: MainCriterias | undefined,
  subCriteria: T
): string => {
  switch (mainCriteria) {
    case MainCriterias.INVOICE_REF:
      return "InvoiceNo";

    case MainCriterias.INVOICE:
      switch (subCriteria) {
        case InvoiceSubCriterias.DEBTOR_NAME:
          return "DebtorName";
        case InvoiceSubCriterias.KID:
          return "KID";
        case InvoiceSubCriterias.INVOICE_NUMBER:
          return "InvoiceNo";
        case InvoiceSubCriterias.DEBTOR_NUMBER:
          return "DebtorNo";
        case InvoiceSubCriterias.CASE_NUMBER:
          return "ExCaseNo";
        default:
          return "ALL";
      }
    case MainCriterias.CASE:
      switch (subCriteria) {
        case CaseSubCriterias.CASE_NUMBER:
          return "ExCaseNo";
        case CaseSubCriterias.DEBTOR_NUMBER:
          return "DebtorNo";
        case CaseSubCriterias.DEBTOR_NAME:
          return "DebtorName";
        case CaseSubCriterias.KID:
          return "KID";
        case CaseSubCriterias.INVOICE_NUMBER:
          return "InvoiceNo";
        default:
          return "ALL";
      }
    case MainCriterias.DEBTOR:
      switch (subCriteria) {
        case DebtorSubCriterias.AR_NUMBER:
          return "ARNo";
        case DebtorSubCriterias.NAME:
          return "Name";
        case DebtorSubCriterias.NUMBER:
          return "Number";
        case DebtorSubCriterias.ADDRESS:
          return "Address";
        case DebtorSubCriterias.TELEPHONE:
          return "Telephone";
        case DebtorSubCriterias.PERSON_NUMBER:
          return "PersonNo";
        case DebtorSubCriterias.CASE_NUMBER:
          return "CaseNumber";
        case DebtorSubCriterias.BIRTHDAY:
          return "Birthday(yyyy/MM/dd)";
        case DebtorSubCriterias.REAL_ESTATE_GNR:
          return "Real Estate GNR";
        case DebtorSubCriterias.REAL_ESTATE_BNR:
          return "Real Estate BNR";
        case DebtorSubCriterias.REAL_ESTATE_SNR:
          return "Real Estate SNR";
        case DebtorSubCriterias.REAL_ESTATE_FNR:
          return "Real Estate FNR";
        case DebtorSubCriterias.EMPLOYER_NUMBER:
          return "Salary Employee No";
        case DebtorSubCriterias.EMPLOYER_NIN:
          return "Salary Organization No";
        case DebtorSubCriterias.DOC_OF_TITLE_SEC_NO:
          return "Document Of Title Section No";
        case DebtorSubCriterias.VEHICLE_REGISTER_NUMBER:
          return "Vehicle Registration Number";
        case DebtorSubCriterias.FINANCIAL_ASSET_NAME:
          return "Other Name/Type";
        default:
          return "ALL";
      }
    case MainCriterias.CREDITOR:
      switch (subCriteria) {
        case CreditorSubCriterias.NAME:
          return "Name";
        case CreditorSubCriterias.NUMBER:
          return "Number";
        case CreditorSubCriterias.ADDRESS:
          return "Address";
        case CreditorSubCriterias.GROUP_NUMBER:
          return "GroupNo";
        case CreditorSubCriterias.GROUP_NAME:
          return "GroupName";
        default:
          return "ALL";
      }
    default:
      return "ALL";
  }
};
