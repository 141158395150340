export enum SettingsTableColumn {
	MENU = 'menu',
	ID = 'id',
	NAME = 'name',
	VALUE = 'value',
	DESCRIPTION = 'description',
}

export const CATEGORY_TABLE_COLUMNS = [
	{
		key: SettingsTableColumn.MENU,
		title: '',
	},
	{
		key: SettingsTableColumn.NAME,
		title: 'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.NAME',
		
	},
	{
		key: SettingsTableColumn.VALUE,
		title: 'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.VALUE',
	},
	{
		key: SettingsTableColumn.DESCRIPTION,
		title: 'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.DESCRIPTION',
	},
];
