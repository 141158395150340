export enum FollowUpAction {
  DONE = "Done",
  REMOVE = "Remove",
  POSTPONE = "Postpone",
  UNDO = "Undo"
}

export enum FollowUpPanel {
  DUE = "due",
  UPCOMING = "upcoming",
  OTHER = "other",
}

export enum OtherFollowUpType {
  DYNAMIC= "Dynamic",
  STATIC = "Static"
}

export const DateFormat = "YYYY-MM-DD"