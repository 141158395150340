export enum MainTab {
  SUBCASE = "S",
  CASE = "C",
  AR = "A",
  CREDITOR = "Creditor",
}

export enum OtherPartyTypes {
  PERSON = "P",
  COMPANY = "N",
}

export enum Criteria {
  NAME = "Name",
  NIN = "NIN",
  BIRTH_DATE = "Birth Date",
  ADDRESS = "Address",
}

export const drawerInitial = {
  title: "",
  postalCodeDrawer: false,
  entityDrawer: false,
  roleTypeDrawer: false,
};

export enum DateFormats {
  REQ = "YYYY-MM-DD",
  MIN_DATE = "0001-01-01",
}

export enum CommunicationMethod {
  MOBILE = "mobile",
  WORK = "work",
  HOME = "home",
  SMS = "sms",
}
