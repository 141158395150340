import { MainSearchConstants } from 'us.common/constants/MainSearchActions';
import { IAPIActionBase, IMainSearchAction } from 'us.common/interfaces';

export const MainSearchActions: Readonly<IAPIActionBase & IMainSearchAction> = {
	all: {
		search: (data) => ({
			type: MainSearchConstants.GET_ALL_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_ALL_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_ALL_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
		set: (data) => ({
			type: MainSearchConstants.BROWSER_BACK_NAVIGATION,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		updateViewedList: (data) => ({
			type: MainSearchConstants.UPDATE_VIEWED_LIST,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
	},
	invoiceRef: {
		search: (data) => ({
			type: MainSearchConstants.GET_INVOICE_REF_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_INVOICE_REF_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_INVOICE_REF_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	invoice: {
		search: (data) => ({
			type: MainSearchConstants.GET_INVOICE_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_INVOICE_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_INVOICE_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	case: {
		search: (data) => ({
			type: MainSearchConstants.GET_CASE_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_CASE_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_CASE_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
	debtor: {
		search: (data) => ({
			type: MainSearchConstants.GET_DEBTOR_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_DEBTOR_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_DEBTOR_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
		updateData: (data) => ({
			type: MainSearchConstants.TOGGLE_DEBTOR_AR,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
	},
	creditor: {
		search: (data) => ({
			type: MainSearchConstants.GET_CREDITOR_SEARCH_DATA,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_CREDITOR_SEARCH_DATA_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_CREDITOR_SEARCH_DATA_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
		updateData: (data) => ({
			type: MainSearchConstants.TOGGLE_CREDITOR_GROUP,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
	},
	history: {
		get: () => ({
			type: MainSearchConstants.GET_SEARCH_HISTORY,
			payload: {
				data: {},
				isLoading: true,
			},
		}),
		save: (data) => ({
			type: MainSearchConstants.SAVE_SEARCH_HISTORY,
			payload: {
				data: data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: MainSearchConstants.GET_SEARCH_HISTORY_SUCCESS,
			payload: {
				data: data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: MainSearchConstants.GET_SEARCH_HISTORY_FAIL,
			payload: {
				error: error,
				isLoading: false,
			},
		}),
	},
};
