import { PhoneNumberTypes } from "us.common/constants"
import { PhoneNumber } from "us.common/interfaces"
const checkdigit = require("checkdigit");

/**
 * Filter role type according to level
 * @param data Role types
 */
export const filterOtherPartyRoleType = (
  currentTab: string,
  data: { roleName: string; roleId: number }[]
): Array<{ roleName: string; roleId: number }> => {
  try {
    if (currentTab.toString() === "creditor") {
      return data.filter(
        (item: { roleName: string; roleId: number }) =>
          item.roleName !== "Employer"
      );
    } else {
      return data;
    }
  } catch (error) {
    return data;
  }
};

/**
 * @description - Combined address
 * @param data - Form data
 * @returns - Address
 */
export const combineAddress = (data: any): string => {
  let address: string = "";
  try {
    if (data?.address1 && data?.address1.length > 0) {
      address = `${data.address1},`;
    }
    if (data?.address2 && data?.address2.length > 0) {
      address = address.concat(` ${data.address2},`);
    }

    if (data?.address3 && data?.address3.length > 0) {
      address = address.concat(` ${data.address3}`);
    } else {
      address = address.slice(0, -1);
    }
    return address;
  } catch (error) {
    return address;
  }
};

/**
 * concat two values and filter undefined
 * @param value1 param 01
 * @param value2 param 02
 * @returns string
 */
export const combineTwoFields = (value1: any, value2: any): string => {
  let result: string = "";
  try {
    if (value1 && value1?.toString().length > 0) {
      if (value2 && value2.toString().length > 0) {
        result = `${value1} ${value2}`;
      } else {
        result = value1;
      }
    } else if (value2) {
      result = value2;
    }
    return result;
  } catch (error) {
    return result;
  }
};

/**
 * Create Contact Object using phoneNumbers array that is given by entity detail response
 * @param data phoneNumbers array
 * @returns contact Object
 */
export const setContactNOs = (data: any[]) => {
  let nums: any = {};
  try {
    data?.map((item: any) => {
      nums[item.type] = item.number;
    });
    return nums;
  } catch (error) {
    return nums;
  }
};

/**
 * check organization number
 * @param no organization number
 * @returns if number is valid then retrun true ,otherwise return false
 */
export const organizationNoValidate = (no: string): boolean => {
  try {
    if (no === "") {
      return true;
    } else if (no?.startsWith(" ") || no?.endsWith(" ")) {
      return false;
    } else if (isNaN(Number(no.replace(/\s/g, "")))) {
      return false;
    } else {
      return (
        no?.toString().replace(/\D/g, "").length === 9 &&
        checkdigit.mod11.isValid(no?.toString().replace(/\D/g, ""))
      );
    }
  } catch (error) {
    return false;
  }
};

/**
 * Check person number
 * @param no person number
 * @returns if number is valid then retrun true ,otherwise return false
 */
export const personNoValidation = (no: string): boolean => {
  try {
    if (no === "") {
      return true;
    } else if (no?.startsWith(" ") || no?.endsWith(" ")) {
      return false;
    } else if (isNaN(Number(no.replace(/\s/g, "")))) {
      return false;
    } else {
      return (
        no?.toString().replace(/\D/g, "").length === 6 ||
        (no?.toString().replace(/\D/g, "").length >= 9 &&
          no?.toString().replace(/\D/g, "").length <= 11)
      );
    }
  } catch (error) {
    return false;
  }
};

/**
 * @description - Create phone numbers array from form fields.
 * @param values - Form values
 * @returns - Phone number array
 */
export const getPhoneNumbers = (values: any): Array<PhoneNumber> => {
  try {
    const { sms, mobile, home, work } = values ?? {};

    return [
      { type: PhoneNumberTypes.SMS, number: sms?.trim() },
      { type: PhoneNumberTypes.MOBILE, number: mobile?.trim() },
      { type: PhoneNumberTypes.HOME, number: home?.trim() },
      { type: PhoneNumberTypes.WORK, number: work?.trim() },
    ].filter(({ number }: PhoneNumber) => (number ? true : false));
  } catch (error) {
    return [];
  }
};