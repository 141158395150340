import {
	IAPIActionBase,
	IRoleAndUserManagementAction,
} from 'us.common/interfaces';
import { User } from 'us.common/constants';

export const userManagementActions: Readonly<
	IAPIActionBase & IRoleAndUserManagementAction
> = {
	init: {
		get: (data: any) => ({
			type: User.GET_INIT_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_INIT_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_INIT_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	users: {
		get: (data: any) => ({
			type: User.GET_USERS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_USERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_USERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	aDUsers: {
		get: (data: any) => ({
			type: User.GET_AZURE_AD_USERS_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_AZURE_AD_USERS_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_AZURE_AD_USERS_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	aDUser: {
		get: (data: any) => ({
			type: User.GET_AZURE_AD_USER_BY_ID_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_AZURE_AD_USER_BY_ID_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_AZURE_AD_USER_BY_ID_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		set: (data: any) => ({
			type: User.SELECT_AD_USER,
			payload: {
				data,
				isLoading: true,
			},
		}),
	},
	b2CUser: {
		save: (data) => ({
			type: User.SAVE_NEW_B2C_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: User.SAVE_NEW_B2C_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: User.SAVE_NEW_B2C_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		delete: (data) => ({
			type: User.DELETE_B2C_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		deleteSuccess: (data) => ({
			type: User.DELETE_B2C_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		deleteFail: (error) => ({
			type: User.DELETE_B2C_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	user: {
		get: (data: any) => ({
			type: User.GET_USER_BY_ID_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_USER_BY_ID_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_USER_BY_ID_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		save: (data) => ({
			type: User.SAVE_NEW_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		saveSuccess: (data) => ({
			type: User.SAVE_NEW_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		saveFail: (error) => ({
			type: User.SAVE_NEW_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		openDrawer: (data) => ({
			type: User.MANAGE_USER_DRAWER,
			payload: {
				data,
				isLoading: true,
			},
		}),
		delete: (data) => ({
			type: User.DELETE_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		deleteSuccess: (data) => ({
			type: User.DELETE_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		deleteFail: (error) => ({
			type: User.DELETE_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		reset: (data) => ({
			type: User.RESET_USER_FORM,
			payload: {
				data,
				isLoading: true,
			},
		}),
		activateOrDeactivate: (data) => ({
			type: User.USER_STATE_CHANGE_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		activateOrDeactivateSuccess: (data) => ({
			type: User.USER_STATE_CHANGE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		activateOrDeactivateFail: (error) => ({
			type: User.USER_STATE_CHANGE_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		update: (data) => ({
			type: User.UPDATE_USER_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		updateSuccess: (data) => ({
			type: User.UPDATE_USER_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		updateFail: (error) => ({
			type: User.UPDATE_USER_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		getHistory: (data) => ({
			type: User.GET_USER_HISTORY_BY_ID_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		getHistorySuccess: (data) => ({
			type: User.GET_USER_HISTORY_BY_ID_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		getHistoryFail: (error) => ({
			type: User.GET_USER_HISTORY_BY_ID_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
		saveHistory: (data) => ({
			type: User.ADD_USER_HISTORY_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		saveHistorySuccess: (data) => ({
			type: User.ADD_USER_HISTORY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		saveHistoryFail: (error) => ({
			type: User.ADD_USER_HISTORY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	creditorGroups: {
		get: (data: any) => ({
			type: User.GET_CREDITOR_GROUP_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_CREDITOR_GROUP_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_CREDITOR_GROUP_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	creditorCompanies: {
		get: (data: any) => ({
			type: User.GET_CREDITOR_COMPANY_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_CREDITOR_COMPANY_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_CREDITOR_COMPANY_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	creditors: {
		get: (data: any) => ({
			type: User.GET_CREDITOR_BY_COMPANY_ID_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_CREDITOR_BY_COMPANY_ID_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_CREDITOR_BY_COMPANY_ID_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	authorizationLevel: {
		get: (data: any) => ({
			type: User.GET_AUTHORIZATION_LEVEL_START,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data: any) => ({
			type: User.GET_AUTHORIZATION_LEVEL_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error: any) => ({
			type: User.GET_AUTHORIZATION_LEVEL_FAIL,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
	preferredLanguage: {
		save: (data) => ({
			type: User.UPDATE_PREFERRED_LANGUAGE,
			payload: {
				data,
				isLoading: true,
			},
		}),
		success: (data) => ({
			type: User.UPDATE_PREFERRED_LANGUAGE_SUCCESS,
			payload: {
				data,
				isLoading: false,
			},
		}),
		fail: (error) => ({
			type: User.UPDATE_PREFERRED_LANGUAGE_FAILED,
			payload: {
				error,
				isLoading: false,
			},
		}),
	},
};
