import React from "react";
import { ColumnsType } from "us.common/components";
import { useTranslation } from "react-i18next";
import { SearchOutlined, FilterOutlined } from "us.icons";
import { ColumnNames } from "us.common/components/SelectCreditors/Constants";
import { $Button, $InputWithValue } from "us.common/components";
import { getRouteUrl } from "us.helper";

/**
 * @description - Table columns for creditor selection.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2776727606/Select+Creditors+-+Common+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * */
const TableColumns = (selectedColumns: Array<string>, columnFilters: any) => {
  const { t } = useTranslation();
  const { filteredInfo } = columnFilters ?? {};

  const getColumnSearchProps = (dataIndex: string, title: string) => {
    const inputSearchRef = React.useRef<any>();
    return {
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          window.setTimeout(function () {
            typeof inputSearchRef?.current !== "undefined" &&
              inputSearchRef.current.focus();
            document
              .querySelector(`[name=columnSearchAmount${dataIndex}]` as any)
              ?.focus();
          }, 1);
        }
      },
      filteredValue: filteredInfo?.[dataIndex] || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <$InputWithValue
            name="columnSearch"
            placeholder={`${t("US.COLLECTION.COMMON:COMMON.SEARCH")} ${title}`}
            value={selectedKeys[0]}
            onChange={(e: any) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            autoComplete="off"
            style={{ width: 188, marginBottom: 8, display: "block" }}
            ref={inputSearchRef}
          />
          <$Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.SEARCH")}
          </$Button>
          <$Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.RESET")}
          </$Button>
        </div>
      ),
      filterIcon: (filtered: string) => (
        <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: string, record: any) => {
        return record[dataIndex]
          ?.toString()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      },
      title: (
        <div className="d-flex flex-column">
          <span style={{ fontWeight: "bold" }}>
            <span data-testid={dataIndex}>{title}</span>
            {Array.isArray(filteredInfo?.[dataIndex]) && (
              <p>
                <span
                  className="font-sm mt-n4"
                  style={{ fontWeight: "bold" }}
                  data-testid="filter-by"
                >
                  {t("COMMON.FILTERED_BY_")}: {filteredInfo?.[dataIndex][0]}
                </span>
              </p>
            )}
          </span>
        </div>
      ),
    };
  };

  const columns = [
    {
      dataIndex: ColumnNames.CREDITOR_NUMBER,
      key: ColumnNames.CREDITOR_NUMBER,
      width: "130px",
      ...getColumnSearchProps(
        ColumnNames.CREDITOR_NUMBER,
        t("US.COLLECTION.COMMON:COMMON.CREDITOR_NO")
      ),
    },
    {
      key: ColumnNames.CREDITOR_NAME,
      dataIndex: ColumnNames.CREDITOR_NAME,
      ...getColumnSearchProps(
        ColumnNames.CREDITOR_NAME,
        t("US.COLLECTION.COMMON:COMMON.CREDITOR")
      ),
      render: (
        text: any,
        { pid, creditorName, address1, address2, address3 }: any
      ) => {
        return (
          <>
            <div className="d-flex flex-column">
              <div>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    getRouteUrl.moduleRoute(
                      "collection",
                      `/creditor/${pid}`,
                      false
                    );
                  }}
                >
                  {creditorName}
                </a>
              </div>
              <div className="text-muted">
                {[address1, address2, address3]
                  .filter((line: any) => line)
                  .join(" ")}
              </div>
            </div>
          </>
        );
      },
    },

    {
      dataIndex: ColumnNames.COMPANY_ID,
      key: ColumnNames.COMPANY_ID,
      width: "200px",
      ...getColumnSearchProps(
        ColumnNames.COMPANY_ID,
        t("US.COLLECTION.COMMON:COMMON.ORG_NUMBER")
      ),
    },
    {
      dataIndex: ColumnNames.GROUP_NAME,
      key: ColumnNames.GROUP_NAME,
      width: "260px",
      ...getColumnSearchProps(
        ColumnNames.GROUP_NAME,
        t("US.COLLECTION.COMMON:COMMON.CREDITOR_GROUP")
      ),
    },
  ];

  const extraColumns = [
    {
      dataIndex: ColumnNames.MASTER_CREDITOR_ID,
      key: ColumnNames.MASTER_CREDITOR_ID,
      ...getColumnSearchProps(
        ColumnNames.MASTER_CREDITOR_ID,
        t("US.COLLECTION.COMMON:COMMON.MASTER_CREDITOR_ID")
      ),
    },
    {
      dataIndex: ColumnNames.MASTER_CREDITOR_NAME,
      key: ColumnNames.MASTER_CREDITOR_NAME,
      ...getColumnSearchProps(
        ColumnNames.MASTER_CREDITOR_NAME,
        t("US.COLLECTION.COMMON:COMMON.MASTER_CREDITOR_NAME")
      ),
    },
    {
      dataIndex: ColumnNames.ZIP_CODE,
      key: ColumnNames.ZIP_CODE,
      ...getColumnSearchProps(
        ColumnNames.ZIP_CODE,
        t("US.COLLECTION.COMMON:COMMON.ZIP_CODE")
      ),
    },
    {
      dataIndex: ColumnNames.ZIP_NAME,
      key: ColumnNames.ZIP_NAME,
      ...getColumnSearchProps(
        ColumnNames.ZIP_NAME,
        t("US.COLLECTION.COMMON:COMMON.ZIP_NAME")
      ),
    },
  ].filter(({ dataIndex }: { dataIndex: string }) =>
    selectedColumns.includes(dataIndex)
  );

  return [...columns, ...extraColumns] as ColumnsType<any>;
};

export default TableColumns;
