import React from "react";
import { Form, Input } from "antd";
import * as Utility from "./utility";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export const $TextArea = React.forwardRef(
  ({ name, label, formItem, required, onChange, onBlur, ...rest }: any, ref: any) => {
    const [field, meta, helpers] = useField(name);
    const { t } = useTranslation();

    const error = meta?.error;
    const isTouched = meta?.touched;

    return (
      <Form.Item
        label={label}
        {...formItem}
        help={isTouched && error && t(error as string)}
        validateStatus={Utility.validateStatus(error, isTouched)}
        required={typeof required == "undefined" ? false : true}
      >
        <TextArea
          ref={ref}
          {...rest}
          {...field}
          onBlur={(e: any) => {
            onBlur && onBlur(e);
            field.onBlur(e);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            typeof onChange == "function"
              ? onChange(e)
              : helpers?.setValue(e.target.value);
          }}
        />
      </Form.Item>
    );
  }
);
