import React from "react";
import { useField } from "formik";
import * as Utility from "us.common/components/antd/utility";
import { useDispatch, useSelector } from "react-redux";
import { entity } from "us.common/actions";
import { useTranslation } from "react-i18next";
import { $FormItem, $Input } from "../../../antd";

export default React.forwardRef(
  (
    {
      name,
      label,
      formItem,
      required = false,
      action,
      currentValues,
      tabIndex,
      autoFocus,
      disabled,
      countryCode,
      ...rest
    }: any,
    ref: any
  ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isExisitingCode } = useSelector(
      (state: any) => state.entityDetails
    );

    const [field, meta, helpers] = useField({ name });

    return (
      <$FormItem
        label={label}
        {...formItem}
        help={
          isExisitingCode &&
          t("US.COLLECTION.VALIDATIONS:INVALID.POSTAL_CODE_IS_ALREADY_USED")
        }
        validateStatus={Utility.validateStatus(
          t("US.COLLECTION.VALIDATIONS:INVALID.POSTAL_CODE_IS_ALREADY_USED"),
          isExisitingCode
        )}
        required
      >
        <$Input
          {...field}
          {...rest}
          onBlur={(e: any) => {
            let value = e.target.value;
            if (value.trim().length) {
              dispatch(
                entity.postalCodeAvailability.get({
                  countryCode,
                  searchText: value,
                  searchBy: "PostalCode",
                })
              );
              helpers.setError(
                isExisitingCode
                  ? t(
                      "US.COLLECTION.VALIDATIONS:INVALID.POSTAL_CODE_IS_ALREADY_USED"
                    )
                  : undefined
              );
            }
            field.onBlur(e);
          }}
          onChange={(e: any) => {
            const value = e.target.value;
            if (value?.length === 0) {
              dispatch(entity.postalCodeAvailability.reset({}));
            }
            helpers?.setValue(e.target.value);
          }}
        />
      </$FormItem>
    );
  }
);
