import React from "react";
import { useTranslation } from "react-i18next";
import {
  DrawerType,
  ManageFeeAction,
} from "us.collection.admin/components/ManageFee/Constants";
import { $Popconfirm } from "us.common/components";
import { EditOutlined, DeleteOutlined } from "us.icons";
import { IItemMenu } from "./interface";
import { getItemMenuDeleteTitle } from "us.collection.admin/components/ManageFee/Functions";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import { $AmountLabel } from "us.common/components";

/**
 * @description - Table popover item menu for record actions
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const ItemMenu: React.FC<IItemMenu & PropsFromRedux> = ({
  onCallAction,
  drawerType,
  isPastDate,
  id,
  feeRange,
}) => {
  const { t } = useTranslation();
  const searchIndex = feeRange?.list.findIndex((range: any) => range.id == id);
  
  return (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex  flex-row more-item"
        data-testid="edit-btn"
        onClick={() => onCallAction(drawerType, ManageFeeAction.EDIT)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("COMMON.EDIT")}</div>
      </div>
      {feeRange?.list.length > 1 && (
        <$Popconfirm
          title={
            drawerType == DrawerType.FEE_BASIS && isPastDate != undefined ? (
              getItemMenuDeleteTitle(isPastDate)
            ) : drawerType == DrawerType.FEE_RANGE ? (
              feeRange?.list.length == 1 ? (
                t(
                  "US.COLLECTION.ADMIN:MANAGE_FEE.DO_YOU_WANT_TO_DELETE_THE_FEE_RANGE_?"
                )
              ) : (
                <>
                  <div className="mb-3">
                    {t(
                      "US.COLLECTION.ADMIN:MANAGE_FEE.DO_YOU_WANT_TO_REMOVE_FOLLOWING_RANGES"
                    )}
                  </div>
                  {feeRange?.list.map((item: any, index: number) => {
                    if (
                      index == searchIndex - 1 ||
                      index == searchIndex ||
                      index == searchIndex + 1
                    ) {
                      return (
                        <div className="mb-2" key={item.id}>
                          <strong className="d-block bui-label">
                            {
                              <div>
                                <$AmountLabel value={item.fromAmount} /> {" - "}
                                <$AmountLabel value={item.toAmount} />
                              </div>
                            }
                          </strong>
                        </div>
                      );
                    }
                  })}
                  <div className="">
                    {t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_MERGED_FEE_RANGE_ ")}
                    <strong>
                      {
                        <div>
                          <$AmountLabel
                            value={
                              searchIndex == 0
                                ? feeRange?.list[searchIndex]?.fromAmount
                                : feeRange?.list[searchIndex - 1]?.fromAmount
                            }
                          />
                          {" - "}
                          <$AmountLabel
                            value={
                              searchIndex == feeRange?.list?.length - 1
                                ? feeRange?.list[searchIndex]?.toAmount
                                : feeRange?.list[searchIndex + 1]?.toAmount
                            }
                          />
                        </div>
                      }
                    </strong>
                    <br />
                    {t("US.COLLECTION.ADMIN:MANAGE_FEE.WILL_BE_ADDED")}
                  </div>
                </>
              )
            ) : (
              ""
            )
          }
          onConfirm={() => onCallAction(drawerType, ManageFeeAction.DELETE)}
          okText={t("COMMON.YES")}
          cancelText={t("COMMON.NO")}
          placement="topLeft"
        >
          <div
            className="d-flex mb-2 flex-row more-item text-error"
            data-testid="delete-btn"
          >
            <div className="p-1">
              <DeleteOutlined />
            </div>
            <div className="p-1">{t("COMMON.DELETE")}</div>
          </div>
        </$Popconfirm>
      )}
      {feeRange?.list.length == 1 && (
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
          onClick={() => onCallAction(drawerType, ManageFeeAction.DELETE)}
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("COMMON.DELETE")}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeRange } = manageFee;
  return {
    feeRange,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemMenu);
