import { getGDPRViewDetails } from "us.collection/functions";
import { IGDPRInitialState } from "../Interfaces";

/**
 * Get GDPR view of=r dashboard view
 * @param AuthorizationProfile Authorization profile details
 * @returns showGDPRCommentBox and showDashboard state
 */
export const getGDPRView = (authorizationProfile: any): IGDPRInitialState => {
  try {
    const { accessAuthorityLevel, isCommentEnable } = authorizationProfile;

    if (getGDPRViewDetails(accessAuthorityLevel, isCommentEnable)) {
      return {
        showGDPRCommentBox: true,
        showDashboard: false,
      };
    } else {
      return {
        showGDPRCommentBox: false,
        showDashboard: true,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      showGDPRCommentBox: false,
      showDashboard: false,
    };
  }
};
