import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { IDetails } from "us.collection.admin/components/ManageFee/Interfaces";
import {
  INITIAL_DRAWER_DATA,
  DrawerType,
  ManageFeeAction,
} from "us.collection.admin/components/ManageFee/Constants";
import { useLocation } from "react-router-dom";
import {
  getFeeDetailsById,
  getAmountByBasis,
  getFeeBasisAmount,
  addFeeRangeToTable,
  checkRangeIsDirty,
} from "us.collection.admin/components/ManageFee/Functions";
import { FeeRangeValidationSchema } from "us.collection.admin/components/ManageFee/Validations";
import { AddNewFeeRange } from "us.collection.admin/components/ManageFee/Repository";
import * as Actions from "us.collection.admin/actions";
import "../../Home.scss";

const { $Button, $Form, $Popconfirm, $Input, $InputAmount, $Message } =
  Common.Components;

/**
 * @description - Fee Type Detail view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Details: React.FC<PropsFromRedux & IDetails> = (props) => {
  const { t } = useTranslation();
  const {
    actionType,
    currentCurrency,
    currentLanguage,
    feeRange,
    feeBasis,
    addNewFeeRange,
    changeDrawerDetails,
    savePayload,
    drawerInfo,
  } = props;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");
  const feeId = urlParams.get("feeId");

  /**
   * @description - get selected Fee Range record from the Table
   */
  const feeData = useMemo(() => {
    return getFeeDetailsById(
      feeRange?.list,
      drawerInfo?.record?.id,
      actionType,
      DrawerType.FEE_RANGE
    );
  }, [feeRange?.list, drawerInfo?.record?.id, actionType]);

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   * @param {any} actions - Form Actions
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const rangePayload = AddNewFeeRange.call({ ...values, typeId, feeId });
    if (actionType === ManageFeeAction.ADD) {
      const payload = addFeeRangeToTable(rangePayload, feeRange?.list);
      if (payload == -1) {
        $Message.error(
          t(`US.COLLECTION.ADMIN:MANAGE_FEE.FEE_RANGE_ALREADY_EXISTS`)
        );
      } else {
        addNewFeeRange && addNewFeeRange(payload);
      }
    } else if (actionType === ManageFeeAction.EDIT) {
      addNewFeeRange &&
        addNewFeeRange({ addActionPayload: rangePayload, feeId });
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...feeData,
        feeBasis: getFeeBasisAmount(feeBasis?.list, Number(feeId), actionType),
        rangeLength: feeRange?.list?.length,
      }}
      validationSchema={FeeRangeValidationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical" autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <div className="mb-3">
                <$InputAmount
                  name="fromAmount"
                  value={values?.fromAmount}
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.FROM_AMOUNT")}
                  currentLanguage={currentLanguage}
                  currentCurrency={currentCurrency}
                  size="small"
                  className="w-100"
                  disabled={actionType === ManageFeeAction.EDIT}
                  max={999999999999999}
                  min={0}
                />
              </div>
              <div className="mb-3">
                <$InputAmount
                  name="toAmount"
                  value={values?.toAmount}
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.TO_AMOUNT")}
                  currentLanguage={currentLanguage}
                  currentCurrency={currentCurrency}
                  size="small"
                  className="w-100"
                  disabled={actionType === ManageFeeAction.EDIT}
                  max={999999999999999}
                  required={values.rangeLength != 0 ? true : undefined}
                  min={0}
                />
              </div>
              <div className="mb-3">
                <$Input
                  name="noOfFee"
                  value={Number(Number(values?.noOfFee)?.toFixed(2))}
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.NO_OF_FEES")}
                  size="small"
                  className="w-100"
                  required
                  maxLength={10}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    restProps.setFieldValue("noOfFee", val);
                    restProps.setFieldValue(
                      "amount",
                      getAmountByBasis({ ...values, noOfFee: val })
                    );
                    restProps.setFieldValue(
                      "doubleAmount",
                      Number(
                        (
                          getAmountByBasis({ ...values, noOfFee: val }) * 2
                        ).toFixed(2)
                      )
                    );
                  }}
                  // type="number"
                />
              </div>
              <div className="mb-3">
                <$InputAmount
                  name="amount"
                  value={values?.amount}
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.AMOUNT")}
                  currentLanguage={currentLanguage}
                  currentCurrency={currentCurrency}
                  size="small"
                  className="w-100"
                  disabled
                  required
                />
              </div>
              <div className="mb-3">
                <$InputAmount
                  name="doubleAmount"
                  value={values?.doubleAmount}
                  label={t("US.COLLECTION.ADMIN:MANAGE_FEE.DOUBLE_AMOUNT")}
                  currentLanguage={currentLanguage}
                  currentCurrency={currentCurrency}
                  size="small"
                  className="w-100"
                  disabled
                  required
                />
              </div>
            </div>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  type="primary"
                  onClick={handleSubmit}
                  className="mr-2"
                  data-testid="save-btn"
                  loading={savePayload?.isSaving}
                  disabled={
                    !checkRangeIsDirty(actionType, values, feeData) ||
                    Object.keys(restProps.errors).length != 0
                  }
                >
                  {actionType === ManageFeeAction.ADD
                    ? t("US.COMMON:COMMON.SAVE")
                    : t("US.COMMON:COMMON.UPDATE")}
                </$Button>
                {checkRangeIsDirty(actionType, values, feeData) && (
                  <$Popconfirm
                    title={t(
                      "US.COMMON:COMMON.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                    )}
                    placement="topLeft"
                    onConfirm={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                    okText={t("US.COMMON:COMMON.YES")}
                    cancelText={t("US.COMMON:COMMON.NO")}
                  >
                    <$Button>{t("US.COMMON:COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {!checkRangeIsDirty(actionType, values, feeData) && (
                  <$Button
                    onClick={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                  >
                    {t("US.COMMON:COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, manageFee } = state;
  const { currentDateFormat, currentCurrency, currentLanguage } = common;
  const { feeBasis, feeRange, savePayload, drawerInfo } = manageFee;
  return {
    currentDateFormat,
    currentCurrency,
    currentLanguage,
    feeBasis,
    feeRange,
    savePayload,
    drawerInfo,
  };
};
const { newFeeRange, feeTypeActions, feeRangeActions } = Actions.manageFee;
const { openDrawer } = feeTypeActions;

const mapDispatchToProps = {
  addNewFeeRange: newFeeRange.save,
  changeDrawerDetails: openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Details);
