import React from 'react';
import * as Components from 'antd';
import * as Utility from '../utility';
import { useField, Field } from 'formik';
import { ISearchParams } from 'us.common/components/antd/Interfaces';
import { useTranslation } from 'react-i18next';

export default ({
  name = 'search',
  placeholder,
  size,
  allowClear,
  className,
  required,
  showSearch,
  value,
  onBlur,
  onSearch,
  onChange,
  onPressEnter,
  style,
  formitem,
  loading = false
}: ISearchParams) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;
  const isTouched = meta?.touched;
  const { t } = useTranslation();

  return (
    <>
      {formitem && (
        <Components.Form.Item
          label={formitem.label}
          help={isTouched && error && t(error as string)}
          validateStatus={Utility.validateStatus(error, isTouched)}
          required={typeof required == 'undefined' ? false : true}
        >
          <Field
            as={Components.Input.Search}
            {...field}
            value={!value ? value : field.value}
            size={size}
            style={style}
            allowClear={allowClear}
            placeholder={placeholder}
            className={className}
            loading={loading}
            showSearch={showSearch}
            onSearch={(text:string) => onSearch && onSearch(text)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              helpers?.setValue(e.target.value);
              onChange && onChange(e);
            }}
            onPressEnter={(e:any) => onSearch && onSearch(e.target?.value)}
          />
        </Components.Form.Item>
      )}

      {!formitem && (
        <Field
          as={Components.Input.Search}
          {...field}
          value={!value ? value : field.value}
          size={size}
          style={style}
          allowClear={allowClear}
          placeholder={placeholder}
          className={className}
          loading={loading}
          showSearch={showSearch}
          onSearch={(text:string) => onSearch && onSearch(text)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            helpers?.setValue(e.target.value);
            onChange && onChange(e);
          }}
          onPressEnter={(e:any) => onSearch && onSearch(e.target?.value)}
        />
      )}
      {/* {!formitem && <Components.Input.Search />} */}
    </>
  );
};
