import { DynamicComponent } from "us.common/constants";
import { appInsights } from "us.helper";
import {
  PopOverDataType,
  DependencyTypes,
  Conditions,
} from "us.common/components/DynamicPopOver/Constants";
import { IParameter } from "us.common/components/DynamicPopOver/Interface";
import _ from "lodash";
/**
 * To get the matching component for the input form.
 * @param params Parameters for return specific component.
 * @returns The dynamic component type required.
 */
export const getDynamicComponent = (params: IParameter): DynamicComponent => {
  try {
    const { paramType, paramLength, isEditable } = params;
    if (paramType == PopOverDataType.VARCHAR && paramLength == 8) {
      return DynamicComponent.Input;
    }else if (paramType == PopOverDataType.VARCHAR && !isEditable) {
      return DynamicComponent.Label;
    } else if (paramType == PopOverDataType.INT) {
      return DynamicComponent.InputNumber;
    } else if (paramType == PopOverDataType.DECIMAL) {
      return DynamicComponent.InputAmount;
    } else if (paramType == PopOverDataType.DATE_TIME && isEditable) {
      return DynamicComponent.DatePicker;
    } else if (paramType == PopOverDataType.DATE_TIME && !isEditable) {
      return DynamicComponent.DateLabel;
    } else if (paramType == PopOverDataType.BIT) {
      return DynamicComponent.Switch;
    } else if (paramType == PopOverDataType.VARCHAR && paramLength == 255) {
      return DynamicComponent.TextArea;
    } else {
      return DynamicComponent.Blank;
    }
  } catch (error) {
    appInsights.trackException(
      `Error in dynamic popOver getDynamicComponent function. - ${error}`
    );
    return DynamicComponent.Blank;
  }
};

/**
 * This function provides status for the specific component using API payload.
 * @param initParameters initial redux parameters for status.
 * @param parameters Formik field parameters for status.
 * @param currentElement Currently visible element record.
 * @returns Object with properties for identify component is visible or disabled.
 */
export const getElementStatus = (
  initParameters: Array<IParameter>,
  parameters: Array<IParameter>,
  currentElement: IParameter
): {
  [DependencyTypes.DISABLE]: boolean;
  [DependencyTypes.VISIBLE]: boolean;
} => {
  try {
    const { dependency } = currentElement ?? {};

    if (Array.isArray(dependency)) {
      // Visible Function
      const componentForVisible = _.find(dependency, {
        dependencyType: DependencyTypes.VISIBLE,
      });
      let isComponentVisible = true;

      if (componentForVisible?.dependencyType == DependencyTypes.VISIBLE) {
        isComponentVisible = componentForVisible?.paramName?.every(
          (name: string) => {
            const components = componentForVisible?.isInitialValues
              ? initParameters
              : parameters;
            let param = _.find(components, { paramName: name });
            return param?.paramValue;
          }
        );
      }

      // Disable Function
      const componentForDisable = _.find(dependency, {
        dependencyType: DependencyTypes.DISABLE,
      });

      let isComponentDisabled = false;

      if (componentForDisable?.dependencyType == DependencyTypes.DISABLE) {
        isComponentDisabled = componentForDisable?.paramName?.every(
          (name: string) => {
            const components = componentForDisable?.isInitialValues
              ? initParameters
              : parameters;
            let param = _.find(components, { paramName: name });
            return componentForDisable?.condition == Conditions.FALSE
              ? true
              : param?.paramValue;
          }
        );
      }

      return {
        [DependencyTypes.DISABLE]: isComponentDisabled,
        [DependencyTypes.VISIBLE]: isComponentVisible,
      };
    } else {
      return {
        [DependencyTypes.DISABLE]: false,
        [DependencyTypes.VISIBLE]: true,
      };
    }
  } catch (error) {
    appInsights.trackException(
      `Error in dynamic popOver getElementStatus function. - ${error}`
    );
    return {
      [DependencyTypes.DISABLE]: false,
      [DependencyTypes.VISIBLE]: true,
    };
  }
};
