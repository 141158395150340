import * as entityDetails from "./EntityDetail/Entity";
import * as UserManagement from "./UserManagement";
import * as MainSearchService from "./MainSearch";
export * from "./CreditorSelection";
export * from "./UserAuthorization";
export * from "./ConfigurationService";
export * from "./DebtorSelection";
export * from "./NewDebtor";
export * from "./UserInformation";
export * from "./UserManagement";
export * from "./ActivityExecution";

export default {
  entityDetails,
  UserManagement,
  MainSearchService,
};
