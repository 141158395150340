import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { $Switch } from "us.common/components";
import { useTranslation } from "react-i18next";
import { IApplyNonProductionTypes } from "./Interfaces";
import { getTranslationKey } from "./Functions";
import { ICriteria } from "us.collection.admin/interfaces";

/**
 * @description ApplyTo Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const ApplyNonProductionTypes: React.FC<IApplyNonProductionTypes> = (props) => {
  const { selectedCriteria } = props;
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();
  const data: Array<any> = values?.criteria ?? [];

  const onChange = (value: boolean, index: number) => {
    try {
      data[index].value = value;
      setFieldValue("criteria", data);
    } catch (error) {}
  };

  return (
    <FieldArray
      name="criteria"
      render={() => (
        <>
          {data.length > 0 &&
            data.map(({ type }: ICriteria, index: number) => (
              <div className="mt-1 d-flex flex-1 align-items-top" key={index}>
                <div className="mr-3">
                  <$Switch
                    name={data[index].type}
                    checked={data[index].value}
                    onChange={(val: boolean) => onChange(val, index)}
                    disabled={type == selectedCriteria}
                  />
                </div>
                <div>
                  {t(
                    `US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.${getTranslationKey(
                      type
                    )}`
                  )}
                </div>
              </div>
            ))}
        </>
      )}
    />
  );
};
export default ApplyNonProductionTypes;
