import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { MainSearchConstants } from 'us.common/constants/MainSearchActions';
import API from 'us.common/services';
import * as Actions from 'us.common/actions';
import {
	ICaseSearchResult,
	ICreditorGroupSearchResult,
	IDebtorSearchResult,
	IInvoiceSearchResult,
	ISearchHistoryResult,
	ISearchResponse,
} from 'us.common/reducers/MainSearch/Interfaces';
import { ISearchRequestObject } from 'us.common/components/MainSearch/Interfaces';
import { getRouteUrl } from 'us.helper';
import { getData } from 'us.common/functions/MainSearch';

const { MainSearchActions } = Actions;

const mainSearchSagas = {
	all: {
		search: function* (action: any): any {
			const params: ISearchRequestObject =
				action.payload.data;
			let searchResponse: ISearchResponse = {
				invoice: [],
				case: [],
				creditor: [],
				debtor: [],
			};

			/**
			 * Invoice search
			 */
			try {
				const data: IInvoiceSearchResult[] | undefined =
					yield call(
						API.MainSearchService.Invoice
							.search,
						params
					);
				yield put(
					MainSearchActions.invoice.success(
						getData(data)
					)
				);
				searchResponse.invoice = data;
			} catch (error) {
				yield put(
					MainSearchActions.invoice.fail(
						error as Object
					)
				);
			}

			/**
			 * case
			 */
			try {
				const data: ICaseSearchResult[] | undefined =
					yield call(
						API.MainSearchService.CaseDetail
							.search,
						params
					);
				yield put(
					MainSearchActions.case.success(
						getData(data)
					)
				);
				searchResponse.case = data;
			} catch (error) {
				yield put(
					MainSearchActions.case.fail(
						error as Object
					)
				);
			}

			/**
			 * Debtor search
			 */
			try {
				const data: IDebtorSearchResult[] | undefined =
					yield call(
						API.MainSearchService
							.DebtorDetail.search,
						params
					);
				yield put(
					MainSearchActions.debtor.success(
						getData(data)
					)
				);
				searchResponse.debtor = data;
			} catch (error) {
				yield put(
					MainSearchActions.debtor.fail(
						error as Object
					)
				);
			}

			/**
			 * Creditor search
			 */
			try {
				const data:
					| ICreditorGroupSearchResult[]
					| undefined = yield call(
					API.MainSearchService.CreditorDetail
						.search,
					params
				);
				yield put(
					MainSearchActions.creditor.success(
						getData(data)
					)
				);
				searchResponse.creditor = data;
			} catch (error) {
				yield put(
					MainSearchActions.creditor.fail(
						error as Object
					)
				);
			}

			const navigationDetail = (state: any) =>
				state.mainSearch.backNavigation;
			const backNavigation: { isBack: boolean } =
				yield select(navigationDetail);

			if (
				getData(searchResponse.invoice).length === 1 &&
				getData(searchResponse.case).length === 0 &&
				getData(searchResponse.creditor).length === 0 &&
				getData(searchResponse.debtor).length === 0 &&
				!backNavigation.isBack
			) {
				getRouteUrl.moduleRoute(
					'collection',
					`/case/${searchResponse.invoice[0].exCaseNo}`
				);
			} else if (
				getData(searchResponse.invoice).length === 0 &&
				getData(searchResponse.case).length === 1 &&
				getData(searchResponse.creditor).length === 0 &&
				getData(searchResponse.debtor).length === 0 &&
				!backNavigation.isBack
			) {
				getRouteUrl.moduleRoute(
					'collection',
					`/case/${searchResponse.case[0].exCaseNo}`
				);
			} else if (
				getData(searchResponse.invoice).length === 0 &&
				getData(searchResponse.case).length === 0 &&
				getData(searchResponse.creditor).length === 0 &&
				getData(searchResponse.debtor).length === 1 &&
				getData(searchResponse.debtor[0].arDetails)
					.length === 1 &&
				!backNavigation.isBack
			) {
				getRouteUrl.moduleRoute(
					'collection',
					`/ar/${searchResponse.debtor[0].arDetails[0].arNo}`
				);
			} else if (
				getData(searchResponse.invoice).length === 0 &&
				getData(searchResponse.case).length === 0 &&
				getData(searchResponse.debtor).length === 0 &&
				getData(searchResponse.creditor).length === 1 &&
				getData(searchResponse.creditor[0].creditors)
					.length === 1 &&
				!backNavigation.isBack
			) {
				getRouteUrl.moduleRoute(
					'collection',
					`/creditor/${searchResponse.creditor[0].creditors[0].creditorNumber}`
				);
			}
		},
	},
	invoiceRef: {
		search: function* (action: any): any {
			const params: ISearchRequestObject =
				action.payload.data;
			try {
				const [invoiceData, caseData] = yield all([
					call(
						API.MainSearchService.Invoice
							.search,
						params
					),
					call(
						API.MainSearchService.CaseDetail
							.search,
						params
					),
				]);
				yield put(
					MainSearchActions.invoiceRef.success({
						invoiceses: getData(
							invoiceData
						),
						cases: getData(caseData),
					})
				);
				const navigationDetail = (state: any) =>
					state.mainSearch.backNavigation;
				const backNavigation: { isBack: boolean } =
					yield select(navigationDetail);
				if (
					getData(invoiceData).length === 1 &&
					getData(caseData).length === 0 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/case/${invoiceData[0].exCaseNo}`
					);
				} else if (
					getData(invoiceData).length === 0 &&
					getData(caseData).length === 1 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/case/${caseData[0].exCaseNo}`
					);
				}
			} catch (error) {
				yield put(
					MainSearchActions.invoiceRef.fail(
						error as Object
					)
				);
			}
		},
	},
	invoice: {
		search: function* (action: any) {
			const params: ISearchRequestObject =
				action.payload.data;
			try {
				const data: IInvoiceSearchResult[] | undefined =
					yield call(
						API.MainSearchService.Invoice
							.search,
						params
					);
				yield put(
					MainSearchActions.invoice.success(
						getData(data)
					)
				);
				const navigationDetail = (state: any) =>
					state.mainSearch.backNavigation;
				const backNavigation: { isBack: boolean } =
					yield select(navigationDetail);
				if (
					getData(data).length === 1 &&
					action.payload.data.Hit === 1 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/case/${
							getData(data)[0]
								.exCaseNo
						}`
					);
				}
			} catch (error) {
				yield put(
					MainSearchActions.invoice.fail(
						error as Object
					)
				);
			}
		},
	},
	case: {
		search: function* (action: any): any {
			const params: ISearchRequestObject =
				action.payload.data;
			try {
				const data: ICaseSearchResult[] | undefined =
					yield call(
						API.MainSearchService.CaseDetail
							.search,
						params
					);
				yield put(
					MainSearchActions.case.success(
						getData(data)
					)
				);
				const navigationDetail = (state: any) =>
					state.mainSearch.backNavigation;
				const backNavigation: { isBack: boolean } =
					yield select(navigationDetail);
				if (
					getData(data).length === 1 &&
					action.payload.data.Hit === 1 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/case/${
							getData(data)[0]
								.exCaseNo
						}`
					);
				}
			} catch (error) {
				yield put(
					MainSearchActions.case.fail(
						error as Object
					)
				);
			}
		},
	},
	debtor: {
		search: function* (action: any) {
			const params: ISearchRequestObject =
				action.payload.data;
			try {
				const data: IDebtorSearchResult[] | undefined =
					yield call(
						API.MainSearchService
							.DebtorDetail.search,
						params
					);
				yield put(
					MainSearchActions.debtor.success(
						getData(data)
					)
				);
				const navigationDetail = (state: any) =>
					state.mainSearch.backNavigation;
				const backNavigation: { isBack: boolean } =
					yield select(navigationDetail);
				if (
					action.payload.data.Hit === 1 &&
					data &&
					getData(data).length === 1 &&
					getData(data[0].arDetails).length ===
						1 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/ar/${data[0].arDetails[0].arNo}`
					);
				}
			} catch (error) {
				yield put(
					MainSearchActions.debtor.fail(
						error as Object
					)
				);
			}
		},
	},
	creditor: {
		search: function* (action: any) {
			const params: ISearchRequestObject =
				action.payload.data;
			try {
				const data:
					| ICreditorGroupSearchResult[]
					| undefined = yield call(
					API.MainSearchService.CreditorDetail
						.search,
					params
				);
				yield put(
					MainSearchActions.creditor.success(
						getData(data)
					)
				);
				const navigationDetail = (state: any) =>
					state.mainSearch.backNavigation;
				const backNavigation: { isBack: boolean } =
					yield select(navigationDetail);
				if (
					action.payload.data.Hit === 1 &&
					data &&
					getData(data).length === 1 &&
					getData(data[0].creditors).length ===
						1 &&
					!backNavigation.isBack
				) {
					getRouteUrl.moduleRoute(
						'collection',
						`/creditor/${data[0].creditors[0].creditorNumber}`
					);
				}
			} catch (error) {
				yield put(
					MainSearchActions.creditor.fail(
						error as Object
					)
				);
			}
		},
	},
	history: {
		get: function* (action: any) {
			try {
				const data: ISearchHistoryResult[] | undefined =
					yield call(
						API.MainSearchService.history
							.get
					);
				yield put(
					MainSearchActions.history.success(
						getData(data)
					)
				);
			} catch (error) {
				yield put(
					MainSearchActions.history.fail(
						error as Object
					)
				);
			}
		},
		save: function* (action: any) {
			const params = action.payload.data;
			try {
				const data: ISearchHistoryResult[] | undefined =
					yield call(
						API.MainSearchService.history
							.save,
						params
					);
				yield put(
					MainSearchActions.history.success(
						getData(data)
					)
				);
			} catch (error) {
				yield put(
					MainSearchActions.history.fail(
						error as Object
					)
				);
			}
		},
	},
};

export default [
	takeLatest(
		MainSearchConstants.GET_ALL_SEARCH_DATA,
		mainSearchSagas.all.search
	),
	takeLatest(
		MainSearchConstants.GET_INVOICE_REF_SEARCH_DATA,
		mainSearchSagas.invoiceRef.search
	),
	takeLatest(
		MainSearchConstants.GET_INVOICE_SEARCH_DATA,
		mainSearchSagas.invoice.search
	),
	takeLatest(
		MainSearchConstants.GET_CASE_SEARCH_DATA,
		mainSearchSagas.case.search
	),
	takeLatest(
		MainSearchConstants.GET_DEBTOR_SEARCH_DATA,
		mainSearchSagas.debtor.search
	),
	takeLatest(
		MainSearchConstants.GET_CREDITOR_SEARCH_DATA,
		mainSearchSagas.creditor.search
	),
	takeLatest(
		MainSearchConstants.GET_SEARCH_HISTORY,
		mainSearchSagas.history.get
	),
	takeLatest(
		MainSearchConstants.SAVE_SEARCH_HISTORY,
		mainSearchSagas.history.save
	),
];
