import { IAPIActionBase } from "us.common/interfaces";
import { UserAuthorization } from "us.common/constants";

export const UserAuthorizationAction: Readonly<IAPIActionBase> = {
  userAuthorizationProfile: {
    get: (data) => ({
      type: UserAuthorization.GET_AUTHORIZATION_PROFILE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserAuthorization.GET_AUTHORIZATION_PROFILE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserAuthorization.GET_AUTHORIZATION_PROFILE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
