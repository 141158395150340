import { SystemSettings } from 'us.collection.admin/constants/Actions';
import { getSettingTypesAndSettings } from 'us.collection.admin/functions';
import { initialState } from './State';

const {
	GET_SYSTEM_SETTINGS,
	GET_SYSTEM_SETTINGS_SUCCESS,
	GET_SYSTEM_SETTINGS_FAIL,

	ADD_SYSTEM_SETTING,
	ADD_SYSTEM_SETTING_SUCCESS,
	ADD_SYSTEM_SETTING_FAIL,

	UPDATE_SYSTEM_SETTING,
	UPDATE_SYSTEM_SETTING_SUCCESS,
	UPDATE_SYSTEM_SETTING_FAIL,

	SEARCH_SYSTEM_SETTING,
	ADD_NEW_SYSTEM_SETTING_TYPE,
	RESET_SYSTEM_SETTING_TYPES,
} = SystemSettings;

export default (state = initialState, { payload, type }: any) => {
	switch (type) {
		case GET_SYSTEM_SETTINGS:
			return Object.assign({}, state, {
				systemSettings: {
					isLoading: true,
					data: [],
					settingTypes: [],
					settings: [],
				},
			});
		case GET_SYSTEM_SETTINGS_SUCCESS:
			return Object.assign({}, state, {
				systemSettings: {
					...state.systemSettings,
					...payload.data,
					isLoading: false,
				},
			});
		case GET_SYSTEM_SETTINGS_FAIL:
			return Object.assign({}, state, {
				systemSettings: {
					isLoading: false,
					data: [],
					settingTypes: [],
					settings: [],
				},
			});
		case UPDATE_SYSTEM_SETTING:
			return Object.assign({}, state, {
				settingUpdate: {
					isLoading: true,
					data: {},
				},
			});
		case UPDATE_SYSTEM_SETTING_SUCCESS:
			const { data, systemSettings } = payload.data;
			return Object.assign({}, state, {
				systemSettings: {
					...state.systemSettings,
					data: systemSettings,
					...getSettingTypesAndSettings(
						systemSettings
					),
				},
				settingUpdate: {
					isLoading: false,
					data,
				},
			});
		case UPDATE_SYSTEM_SETTING_FAIL:
			return Object.assign({}, state, {
				settingUpdate: {
					isLoading: false,
					data: {},
				},
			});
		case ADD_SYSTEM_SETTING:
			return Object.assign({}, state, {
				settingSave: {
					isLoading: true,
					data: {},
				},
			});
		case ADD_SYSTEM_SETTING_SUCCESS:
			return Object.assign({}, state, {
				systemSettings: {
					...state.systemSettings,
					data: payload.data.systemSettings,
					...getSettingTypesAndSettings(
						payload.data.systemSettings
					),
				},
				settingSave: {
					isLoading: false,
					data: payload.data.data,
				},
			});
		case ADD_SYSTEM_SETTING_FAIL:
			return Object.assign({}, state, {
				settingSave: {
					isLoading: false,
					data: {},
				},
			});
		case SEARCH_SYSTEM_SETTING:
			return Object.assign({}, state, {
				systemSettings: {
					...state.systemSettings,
					...getSettingTypesAndSettings(
						state.systemSettings.data,
						payload.data
					),
				},
			});
		case ADD_NEW_SYSTEM_SETTING_TYPE:
			return Object.assign({}, state, {
				systemSettings: {
					...state.systemSettings,
					settingTypes: [
						...state.systemSettings
							.settingTypes,
						payload.data,
					],
				},
			});
		case RESET_SYSTEM_SETTING_TYPES:
			return Object.assign({}, state, {
				...initialState,
				systemSettings: {
					...state.systemSettings,
					...getSettingTypesAndSettings(
						state.systemSettings.data
					),
				},
			});
		default:
			return state;
	}
};
