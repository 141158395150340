export enum LastAction {
    SUCCESS = 'Success',
    OPEN = "Open",
    FAILED = 'Failed',
    VIEW = "View",
    PENDING = 'Pending',
    EXECUTED = 'Executed',
    PENDING_FAILED = "Pending-Failed",
    OTHER = "Other",
    ALL = 'all'
}