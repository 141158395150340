export function ChangeUserState(this: any) {
	const { activeState, id } = this;
	return {
		userId: id,
		body: [
			{
				op: 'replace',
				path: `/activeState`,
				value: !activeState,
			},
		],
	};
}
