import { Notifications } from "us.common/constants";
import { IAPIActionBase } from "us.common/interfaces";
import { INotificationAction } from "us.common/interfaces";

export const notification: Readonly<IAPIActionBase & INotificationAction> = {
  user: {
    get: (data: any) => ({
      type: Notifications.GET_NOTIFICATIONS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: Notifications.GET_NOTIFICATIONS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: Notifications.GET_NOTIFICATIONS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  update: {
    save: (data: any) => ({
      type: Notifications.UPDATE_NOTIFICATIONS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: Notifications.UPDATE_NOTIFICATIONS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: Notifications.UPDATE_NOTIFICATIONS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  action: {
    retry: (data: any) => ({
      type: Notifications.RETRY_NOTIFICATION_ACTION,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: Notifications.RETRY_NOTIFICATION_ACTION_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: Notifications.RETRY_NOTIFICATION_ACTION_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  event: {
    update: (data: any) => ({
      type: Notifications.UPDATE_PENDING_NOTIFICATION,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data: any) => ({
      type: Notifications.UPDATE_PENDING_NOTIFICATION_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error: any) => ({
      type: Notifications.UPDATE_PENDING_NOTIFICATION_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
