import { INonProductionDay } from "us.collection.admin/interfaces";

export enum NonProductionDayType {
    Non_Banking = "nonBanking",
    Non_SMS = "nonSms",
    Non_Printing = "nonPrinting",
    Non_Workflow = "nonWorkflow",
    Non_Email = "nonEmail"
}

export type NonProductionDayTypeInterface =
    NonProductionDayType.Non_Banking |
    NonProductionDayType.Non_Email |
    NonProductionDayType.Non_Printing |
    NonProductionDayType.Non_SMS |
    NonProductionDayType.Non_Workflow

export enum NonProductionDayTypeTranslationKey {
    Non_Banking = "NON_BANKING",
    Non_SMS = "NON_SMS",
    Non_Printing = "NON_PRINTING",
    Non_Workflow = "NON_WORKFLOW",
    Non_Email = "NON_EMAIL"
}

export type NonProductionDataType = {
    [key: string]: Array<INonProductionDay>
};
