export default {
	case: {
		tabs: {
			case: {
				isEnabled: true,
			},
		},
		routes: {
			agreements: {
				isEnabled: true,
				components: {},
			},
			assets: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
			caseDocuments: {
				isEnabled: true,
				components: {},
			},
			caseInterest: {
				isEnabled: true,
				components: {},
			},
			caseInformation: {
				isEnabled: true,
				components: {},
			},
			creditor: {
				isEnabled: true,
				components: {},
			},
			debtor: {
				isEnabled: true,
				components: {},
			},
			enforcement: {
				isEnabled: true,
				components: {},
			},
			extendedField: {
				isEnabled: true,
				components: {
					newField: {
						isEnabled: false,
					},
				},
			},
			gurantor: {
				isEnabled: true,
				components: {},
			},
			messageTemplates: {
				isEnabled: true,
				components: {},
			},
			notes: {
				isEnabled: true,
				components: {},
			},
			otherParty: {
				isEnabled: true,
				components: {},
			},
			partPayments: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			sentences: {
				isEnabled: true,
				components: {},
			},
			transactions: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			registerWorkflow: {
				isEnabled: true,
				components: {},
			},
			workflowStatusHistory: {
				isEnabled: true,
				components: {},
			},
			migrateNewWorkflowVersion: {
				isEnabled: true,
				components: {},
			},
			closeCase: {
				isEnabled: true,
				components: {},
			},
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			debtor: {
				isEnabled: true,
				components: {
					changeDebtor: {
						isEnabled: true,
					},
					caseInformation: {
						isEnabled: true,
					},
					creditInformation: {
						isEnabled: true,
						components: {},
					},
					badDebtInformation: {
						isEnabled: true,
						components: {},
					},
					caseList: {
						isEnabled: true,
						components: {},
					},
				},
			},
			caseInformation: {
				isEnabled: true,
				components: {},
			},
			accountSummary: {
				isEnabled: true,
				components: {
					invoice: {
						isEnabled: true,
					},
					paymentAgreement: {
						isEnabled: true,
					},
					partPayment: {
						isEnabled: true,
					},
				},
			},
			messageAndNotes: {
				isEnabled: true,
				components: {
					note: {
						isEnabled: true,
						components: {},
					},
					sms: {
						isEnabled: true,
						components: {},
					},
					email: {
						isEnabled: true,
						components: {},
					},
					letter: {
						isEnabled: true,
						components: {},
					},
					creditorNote: {
						isEnabled: true,
						components: {},
					},
				},
			},
			history: {
				isEnabled: true,
				component: {},
			},
			objection: {
				isEnabled: true,
				component: {
					calimOfPayment: {
						isEnabled: true,
					},
					debtorObjection: {
						isEnabled: true,
					},
					cancelObjection: {
						isEnabled: true,
					},
					debtorAgreedToPay: {
						isEnabled: true,
					},
					partPayment: {
						isEnabled: true,
					},
				},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	subcase: {
		tabs: {
			subcase: {
				isEnabled: true,
			},
		},
		routes: {
			agreements: {
				isEnabled: true,
				components: {},
			},
			assets: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
			caseDocuments: {
				isEnabled: true,
				components: {},
			},
			caseInterest: {
				isEnabled: true,
				components: {},
			},
			caseInformation: {
				isEnabled: true,
				components: {},
			},
			creditor: {
				isEnabled: true,
				components: {},
			},
			debtor: {
				isEnabled: true,
				components: {},
			},
			enforcement: {
				isEnabled: true,
				components: {},
			},
			extendedField: {
				isEnabled: true,
				components: {
					newField: {
						isEnabled: false,
					},
				},
			},
			gurantor: {
				isEnabled: true,
				components: {},
			},
			messageTemplates: {
				isEnabled: true,
				components: {},
			},
			notes: {
				isEnabled: true,
				components: {},
			},
			otherParty: {
				isEnabled: true,
				components: {},
			},
			partPayments: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			sentences: {
				isEnabled: true,
				components: {},
			},
			transactions: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			registerWorkflow: {
				isEnabled: true,
				components: {},
			},
			workflowStatusHistory: {
				isEnabled: true,
				components: {},
			},
			migrateNewWorkflowVersion: {
				isEnabled: true,
				components: {},
			},
			closeCase: {
				isEnabled: true,
				components: {},
			},
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			debtor: {
				isEnabled: true,
				components: {
					changeDebtor: {
						isEnabled: true,
					},
					caseInformation: {
						isEnabled: true,
					},
					creditInformation: {
						isEnabled: true,
						components: {},
					},
					badDebtInformation: {
						isEnabled: true,
						components: {},
					},
					caseList: {
						isEnabled: true,
						components: {},
					},
				},
			},
			caseInformation: {
				isEnabled: true,
				components: {},
			},
			accountSummary: {
				isEnabled: true,
				components: {
					invoice: {
						isEnabled: true,
					},
				},
			},
			messageAndNotes: {
				isEnabled: true,
				components: {
					note: {
						isEnabled: true,
						components: {},
					},
					sms: {
						isEnabled: true,
						components: {},
					},
					email: {
						isEnabled: true,
						components: {},
					},
					letter: {
						isEnabled: true,
						components: {},
					},
					creditorNote: {
						isEnabled: true,
						components: {},
					},
				},
			},
			history: {
				isEnabled: true,
				component: {},
			},
			objection: {
				isEnabled: true,
				component: {
					calimOfPayment: {
						isEnabled: true,
					},
					debtorObjection: {
						isEnabled: true,
					},
					cancelObjection: {
						isEnabled: true,
					},
					debtorAgreedToPay: {
						isEnabled: true,
					},
					partPayment: {
						isEnabled: true,
					},
				},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	ar: {
		tabs: {
			ar: {
				isEnabled: true,
			},
		},
		routes: {
			arTransaction: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			caseDocuments: {
				isEnabled: true,
				components: {},
			},
			caseAndSubcase: {
				isEnabled: true,
				components: {},
			},
			assets: {
				isEnabled: true,
				components: {},
			},
			applicableCourt: {
				isEnabled: true,
				components: {},
			},
			messageTemplates: {
				isEnabled: true,
				components: {},
			},
			otherParty: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
			notes: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			balanceUpdate: {
				isEnabled: true,
				components: {},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	creditor: {
		tabs: {
			creditor: {
				isEnabled: true,
			},
		},
		routes: {
			agreements: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			caseDocuments: {
				isEnabled: true,
				components: {},
			},
			otherParty: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
			notes: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			registerWorkflow: {
				isEnabled: true,
				components: {},
			},
			workflowStatusHistory: {
				isEnabled: true,
				components: {},
			},
			migrateNewWorkflowVersion: {
				isEnabled: true,
				components: {},
			},
			closeCase: {
				isEnabled: true,
				components: {},
			},
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	creditorgroup: {
		tabs: {
			creditorgroup: {
				isEnabled: true,
			},
		},
		routes: {
			selectedCreditors: {
				isEnabled: true,
				components: {},
			},
			agreements: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			caseDocuments: {
				isEnabled: true,
				components: {},
			},
			otherParty: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			registerWorkflow: {
				isEnabled: true,
				components: {},
			},
			workflowStatusHistory: {
				isEnabled: true,
				components: {},
			},
			migrateNewWorkflowVersion: {
				isEnabled: true,
				components: {},
			},
			closeCase: {
				isEnabled: true,
				components: {},
			},
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	payment: {
		tabs: {
			payment: {
				isEnabled: true,
			},
		},
		routes: {
			paymentDistribution: {
				isEnabled: true,
				components: {},
			},
			assets: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
	bureau: {
		tabs: {
			bureau: {
				isEnabled: true,
			},
		},
		routes: {
			manageCreditorGroups: {
				isEnabled: true,
				components: {},
			},
			routinesAndActivities: {
				isEnabled: true,
				components: {},
			},
			bmd: {
				isEnabled: true,
				components: {},
			},
		},
		components: {
			registerWorkflow: {
				isEnabled: true,
				components: {},
			},
			workflowStatusHistory: {
				isEnabled: true,
				components: {},
			},
			migrateNewWorkflowVersion: {
				isEnabled: true,
				components: {},
			},
			closeCase: {
				isEnabled: true,
				components: {},
			},
			activityExecutionBar: {
				isEnabled: true,
				components: {},
			},
			addToFollowUp: {
				isEnabled: true,
				components: {},
			},
			runAllPendingActivies: {
				isEnabled: true,
				components: {},
			},
			followupFooter: {
				isEnabled: true,
				components: {},
			},
		},
	},
};
