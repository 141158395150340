import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { FeeBasisHistoryTable } from "./Components/Table";
import { useLocation } from "react-router-dom";
import * as Actions from "us.collection.admin/actions";
import { IBasisHistory } from "./Interfaces";
import "../../Home.scss";

const { $Skeleton } = Common.Components;

/**
 * @description - Fee Basis History Main view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const FeeBasisHistory: React.FC<PropsFromRedux & IBasisHistory> = ({
  getFeeBasisHistoryData,
  feeBasisHistory,
  onCallAction,
}) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");

  useEffect(() => {
    if (typeId) {
      getFeeBasisHistoryData && getFeeBasisHistoryData(typeId);
    }
  }, [typeId]);

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {() => (
        <>
          <$Skeleton
            loading={feeBasisHistory?.isListLoading}
            active
            paragraph={{ rows: 2 }}
          >
            <FeeBasisHistoryTable onCallAction={onCallAction} />
          </$Skeleton>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeBasisHistory } = manageFee;
  return {
    feeBasisHistory,
  };
};
const { feeBasisHistoryActions } = Actions.manageFee;
const { getById } = feeBasisHistoryActions;

const mapDispatchToProps = {
  getFeeBasisHistoryData: getById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FeeBasisHistory);
