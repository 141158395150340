import React from "react";
import { useTranslation } from "react-i18next";
import { DragNDropItem } from "./Component";
import { $Divider, $Button } from "us.common/components";
import { Formik, FieldArray, useField } from "formik";
import { PlusOutlined } from "us.icons";
import { IDragNDrop, IDataItem } from "./Interfaces";
import {
  getUpdatedList,
  getUpdatedListAfterDelete,
  addNewSequenceList,
  getFilteredOptionList,
} from "./Functions";
import { isArray } from "lodash";

/**
 * @description - Drag and Drop component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/TCUI/pages/3051487282/Drag+and+Drop+Component
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 17/07/2022
 */
export const DragNDrop = ({
  name = "",
  isAddTypeVisible = false,
  title,
  selectPropsName = "",
  defaultSelectOptions,
  mode,
  onMove,
  onResetData,
  ...rest
}: IDragNDrop) => {
  const { t } = useTranslation();

  // update usefield values using helper
  const [field, _meta, helpers] = useField({ name });
  const [selectPropsField, _selectPropsMeta, selectPropsHelpers] =
    useField(selectPropsName);

  /**
   * @description Change the new selected item type name
   * @param {Array<string>} selectedOptions - selected item type ids list
   * @param {number} index - index of the updated sequence
   */
  const onChange = (selectedOptions: Array<number>, index: number) => {
    const updatedSequenceList = getUpdatedList(
      field.value,
      selectedOptions,
      index,
      selectPropsField.value.options
    );
    helpers.setValue(updatedSequenceList);
  };

  /**
   * @description Delete newly added Item type
   * @param {number} id - deleted item type id
   */
  const onDeleteItem = (id: number, value: Array<number>) => {
    helpers.setValue(getUpdatedListAfterDelete(field.value, id));
  };

  /**
   * @description - This function will use to disabled previously selected items from other select options and enabled only to select available options
   * when open - set filtered options
   * when close  - set again default options
   * @param {boolean} open - Select dropdown open or not
   * @param {number} itemId - Current select component related sequence id or item id
   */
  const handleOnDropdownVisibleChange = (open: boolean, itemId: number) => {
    try {
      if (open) {
        // define to store selected options from others select elements
        var selectedOptions: Array<number> = [];
        // updated the array with other select elements selected options
        field.value.map(({ id, value }: IDataItem) => {
          if (isArray(value) && id !== itemId) {
            selectedOptions = [...selectedOptions, ...value];
          }
        });
        // filter selected options by removing already selected options
        const filteredOptions = getFilteredOptionList(
          defaultSelectOptions,
          selectedOptions
        );
        // update the field
        selectPropsHelpers.setValue({
          ...selectPropsField.value,
          options: filteredOptions,
        });
      } else {
        // set again the default ooptions
        selectPropsHelpers.setValue({
          ...selectPropsField.value,
          options: defaultSelectOptions,
        });
      }
    } catch (error) {
      console.error(error);
      selectPropsHelpers.setValue({
        ...selectPropsField.value,
        options: defaultSelectOptions,
      });
    }
  };

  return (
    <Formik
      id={"form"}
      initialValues={{ sequenceDetails: field?.value ?? [] }}
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        isValidating,
        dirty,
        ...restProps
      }: any) => (
        <>
          <div>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <p className="font-weight-bold">{title}</p>
              </div>
              <div>
                <$Button size="small" onClick={onResetData}>
                  {t("US.COLLECTION.ECONOMY:COVERING_SEQUENCE.RESET_SEQUENCE")}
                </$Button>
              </div>
            </div>

            <div className="cq-list">
              <FieldArray
                name="sequenceDetails"
                render={() =>
                  values?.sequenceDetails?.map(
                    (sequence: IDataItem, index: number) => (
                      <DragNDropItem
                        key={index.toString()}
                        data={sequence}
                        index={index}
                        onMove={onMove}
                        onChange={onChange}
                        onDeleteItem={onDeleteItem}
                        selectProps={selectPropsField?.value}
                        mode={mode}
                        onDropdownVisibleChange={handleOnDropdownVisibleChange}
                      />
                    )
                  )
                }
              />
            </div>

            <$Divider orientation="left">
              <$Button
                data-testid="add-type"
                size="small"
                onClick={() => {
                  helpers.setValue(addNewSequenceList(values.sequenceDetails));
                }}
                icon={<PlusOutlined />}
                disabled={
                  field.value.filter((val: any) => !val.name).length > 0
                }
              >
                {t("US.COLLECTION.ECONOMY:COVERING_SEQUENCE.ADD_TYPE")}
              </$Button>
            </$Divider>
          </div>
        </>
      )}
    </Formik>
  );
};
