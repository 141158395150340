import { IFollowup } from "us.collection.admin/interfaces";

/**
 * @function
 * @description filter table data according to createdUs
 * @param  {Array<IFollowup>} data table data before filtering 
 * @param {boolean} isCreatedByMe true - if user want to filter followups which were created by them
 * @param {string} userName - current user's userName
 * @returns {Array<IFollowup>} filtered data
 */
export const setTableData = (
    data: Array<IFollowup>,
    isCreatedByMe: boolean,
    userName: string
): Array<IFollowup> => {
    try {
        return isCreatedByMe
            ? data.filter((followup: IFollowup) => followup.createdUser == userName)
            : data;
    } catch (error) {
        return data;
    }
};
