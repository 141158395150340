import React, { useEffect } from "react";
import Common from "us.common";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  NonBankingDays,
  NonEmailDays,
  NonSMSDays,
  NonPrintingDays,
  NonWorkflowDays,
} from "./Components";
import { CalendarOutlined, LeftOutlined, RightOutlined } from "us.icons";
import { NonProductionDayType } from "us.collection.admin/constants";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { nonProductionDays_New } from "us.collection.admin/actions";
import { UpdateNonProductionDays, InitNonProductionDays } from "./Repository";
import { NonProductionDayTabs } from "./Constants";
import moment from "moment";
import _ from "lodash";
import "./NonProductionDays.scss";

const { calendar } = nonProductionDays_New;
const {
  $Tabs,
  $Button,
  $Popconfirm,
  $PageHeader,
  $Divider,
  $Affix,
  $DatePicker,
  $Tooltip,
  $Col,
  $Row,
} = Common.Components;

/**
 * @description Non Productions Days Home Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const NonProductionsDays: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { goBack } = history;
  const {
    manageTabs,
    selectedCriteria,
    scheduleTime,
    updateNonProduction,
    redDays,
    initNonProduction,
    isSubmitting,
  } = props;
  const { nonEmail, nonSms, nonPrinting } = scheduleTime;

  useEffect(() => {
    const request = InitNonProductionDays.call({ calendarId: 1 });
    initNonProduction && initNonProduction(request);
  }, []);

  /**
   * @function
   * @description handle Tab Click
   * @param {string} activeKey current tab
   */
  const handleTab = (activeKey: string) => {
    manageTabs && manageTabs({ selectedCriteria: activeKey });
  };

  /**
   * @function
   * @description handle submit
   * @param {any} data form Data
   */
  const handleSubmit = (data: any) => {
    const request = UpdateNonProductionDays.call(
      data,
      _.omit(redDays, ["data", "isFetching"])
    );
    updateNonProduction && updateNonProduction(request);
  };

  const getTabContent = (type: NonProductionDayType) => {
    switch (type) {
      case NonProductionDayType.Non_Banking:
        return <NonBankingDays />;
      case NonProductionDayType.Non_SMS:
        return <NonSMSDays />;
      case NonProductionDayType.Non_Printing:
        return <NonPrintingDays />;
      case NonProductionDayType.Non_Workflow:
        return <NonWorkflowDays />;
      case NonProductionDayType.Non_Email:
        return <NonEmailDays />;
      default:
        return <NonBankingDays />;
    }
  };

  return (
    <Formik
      initialValues={{ nonSms, nonEmail, nonPrinting }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <div className="non-production-days space-content">
          <$Affix offsetTop={48}>
            <div className="page-header header-border">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={goBack}
                    title={t(
                      "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.CALENDER"
                    )}
                  />

                  <$Divider className="bui-devider" type="vertical" />

                  <div className="ml-3">
                    <div className="d-flex align-items-center">
                      {rest.dirty && (
                        <$Popconfirm
                          title={t(
                            "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                          )}
                          placement="topLeft"
                          okText={t("US.COMMON:COMMON.YES")}
                          cancelText={t("US.COMMON:COMMON.NO")}
                        >
                          <$Button size="small">
                            <LeftOutlined />
                          </$Button>
                        </$Popconfirm>
                      )}
                      {!rest.dirty && (
                        <$Button size="small">
                          <LeftOutlined />
                        </$Button>
                      )}
                      <div className="px-4 current-year">
                        <strong>{moment().format('YYYY')}</strong>
                      </div>
                      {rest.dirty && (
                        <$Popconfirm
                          title={t(
                            "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                          )}
                          placement="topLeft"
                          okText={t("US.COMMON:COMMON.YES")}
                          cancelText={t("US.COMMON:COMMON.NO")}
                        >
                          <$Button size="small">
                            <RightOutlined />
                          </$Button>
                        </$Popconfirm>
                      )}
                      {!rest.dirty && (
                        <$Button size="small">
                          <RightOutlined />
                        </$Button>
                      )}
                      <$Button size="small" className="ml-2">
                        <CalendarOutlined />
                        {rest.dirty && (
                          <$Popconfirm
                            title={t(
                              "US.COLLECTION.ADMIN:NONPRODUCTIONDAYS.POPCONFIRM1"
                            )}
                            placement="topLeft"
                            okText={t("US.COMMON:COMMON.YES")}
                            cancelText={t("US.COMMON:COMMON.NO")}
                          >
                            <$DatePicker
                              className="year-pick"
                              name="yearPicker"
                              picker="year"
                            />
                          </$Popconfirm>
                        )}
                        {!rest.dirty && (
                          <$DatePicker
                            className="year-pick"
                            name="yearPicker"
                            picker="year"
                          />
                        )}
                      </$Button>
                      <$Button
                        className="ml-3"
                        size="small"
                        type="primary"
                        onClick={handleSubmit}
                        style={{ width: 80 }}
                        loading={isSubmitting}
                      >
                        {t("US.COMMON:COMMON.SAVE")}
                      </$Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </$Affix>
          <div className="mb-5">
            <$Row gutter={16}>
              <$Col xl={{ span: 24 }} xxl={{ span: 24 }}>
                <$Tabs
                  className="bui-tabs"
                  defaultActiveKey={NonProductionDayType.Non_Banking}
                  activeKey={selectedCriteria}
                  onChange={(activeKey: string) => handleTab(activeKey)}
                >
                  {NonProductionDayTabs.map(
                    ({
                      name,
                      description,
                      type,
                    }: typeof NonProductionDayTabs[0]) => (
                      <$Tabs.TabPane
                        tab={
                          <$Tooltip placement="top" title={t(description)}>
                            {t(name)}
                          </$Tooltip>
                        }
                        key={type}
                      >
                        {getTabContent(type)}
                      </$Tabs.TabPane>
                    )
                  )}
                </$Tabs>
              </$Col>
            </$Row>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { nonProductionDays } = state;
  const { redDays, selectedCriteria, scheduleTime, isSubmitting } =
    nonProductionDays;
  return { redDays, selectedCriteria, scheduleTime, isSubmitting };
};

const mapDispatchToProps = {
  initNonProduction: calendar.init,
  manageTabs: calendar.manageTabs,
  updateNonProduction: calendar.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(NonProductionsDays);
