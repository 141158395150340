import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IRootState } from "us.collection/interfaces";
import { $Select, $Divider, $Input, $Button } from "us.common/components";
import { manageFollowupActions } from "us.collection.admin/actions";
import { PlusOutlined } from "us.icons";
import { IFollowUpCategory } from "us.collection.admin/interfaces";
import { DefaultCategory } from "us.collection.admin/constants";

const { categories } = manageFollowupActions;

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2943713306/Follow-up+Custom+Query+Save+View+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const Category: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: { values: any; setFieldValue: any } =
    useFormikContext();

  const { getFollowUpCategories, saveFollowUpCategory, categories } = props;

  const { data } = categories;

  useEffect(() => {
    getFollowUpCategories && getFollowUpCategories({});
  }, []);

  /**
   * @function
   * @description - save followup category action
   * @param {string} categoryName - typed new category name
   * @returns void
   */
  const handleAddCategory = (categoryName: string): void => {
    setFieldValue("categoryName", "");
    saveFollowUpCategory && saveFollowUpCategory({ categoryName });
  };

  return (
    <$Select
      size="small"
      name="followupCategory"
      className="w-100"
      required
      formitem={{
        label: t("US.COLLECTION.ADMIN:BULKOPERATION.CATEGORY"),
      }}
      allOption={false}
      dropdownMatchSelectWidth={false}
      options={data.map((category: IFollowUpCategory) =>
        category.categoryName != DefaultCategory.categoryName
          ? category
          : { categoryID: 0, categoryName: DefaultCategory.categoryNone }
      )}
      optionText="categoryName"
      optionValue="categoryID"
      onSearchBy={["categoryName"]}
      dataTestid="followupCategory-select"
      dropdownRender={(menu: any) => (
        <div>
          {menu}
          <$Divider style={{ margin: "4px 0" }} />
          <div className="d-flex align-items-center p-2">
            <div className="flex-grow-1">
              <$Input
                name="categoryName"
                className="w-100"
                size="small"
                dataTestid="categoryName-input"
              />
            </div>
            <$Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => handleAddCategory(values?.categoryName)}
              data-testid={"addCategory-btn"}
            >
              {t("COMMON.ADD")}
            </$Button>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { categories } = manageFollowups;
  return { categories };
};

const mapDispatchToProps = {
  getFollowUpCategories: categories.get,
  saveFollowUpCategory: categories.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Category);
