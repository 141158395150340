import moment from "moment";
import { currentTimeFormat } from "us.common/constants";

/**
 * @function
 * @description disabled hours
 * @param {moment.Moment} startTime start time
 * @returns {Array<number>} array of disabled hours
 */
export const disabledHours = (startTime: moment.Moment): Array<number> => {
    const hours: Array<number> = [];
    try {
        const currentHour: number = getSelectedHour(startTime);
        for (let i = 0; i < currentHour; i++) {
            hours.push(i);
        }
        return hours;
    } catch (error) {
        return hours;
    }
};

/**
 * @function
 * @description disabled minutes
 * @param {number} hour current hour
 * @param {number} selectedHour hour value of start time
 * @param {string} startTimeValue start time value
 * @returns {Array<number>} array of disabled minutes
 */
export const disabledMinutes = (
    hour: number,
    selectedHour: number,
    startTimeValue: string
): Array<number> => {
    const minutes: Array<number> = [];
    try {
        if (selectedHour === hour) {
            const currentMinutes: number = moment(
                startTimeValue,
                currentTimeFormat
            ).minutes();

            for (let i = 0; i <= currentMinutes; i++) {
                minutes.push(i);
            }
        }
        return minutes;
    } catch (error) {
        return minutes;
    }
};

/**
 * @function
 * @description get hour value of provide time
 * @param { moment.Moment} time 
 * @returns hour
 */
export const getSelectedHour = (time: moment.Moment): number => {
    try {
        return moment(time, currentTimeFormat).hour();
    } catch (error) {
        return 0;
    }
};

/**
 * @function
 * @description compare start time and end time
 * @param {moment.Moment} time value of start time field
 * @param {string} endTimeValue end time value
 * @returns {boolean}
 */
export const isStartTimeGreaterThanEndTime = (
    time: moment.Moment,
    endTimeValue: string
): boolean => {
    try {
        return moment(time, currentTimeFormat).isSameOrAfter(
            moment(endTimeValue, currentTimeFormat)
        );
    } catch (error) {
        return false;
    }
};
