export enum SystemSettings {
	ADD_SYSTEM_SETTING = 'ADD_SYSTEM_SETTING',
	ADD_SYSTEM_SETTING_SUCCESS = 'ADD_SYSTEM_SETTING_SUCCESS',
	ADD_SYSTEM_SETTING_FAIL = 'ADD_SYSTEM_SETTING_FAIL',

	UPDATE_SYSTEM_SETTING = 'UPDATE_SYSTEM_SETTING',
	UPDATE_SYSTEM_SETTING_SUCCESS = 'UPDATE_SYSTEM_SETTING_SUCCESS',
	UPDATE_SYSTEM_SETTING_FAIL = 'UPDATE_SYSTEM_SETTING_FAIL',

	GET_SYSTEM_SETTINGS = 'GET_SYSTEM_SETTINGS',
	GET_SYSTEM_SETTINGS_SUCCESS = 'GET_SYSTEM_SETTINGS_SUCCESS',
	GET_SYSTEM_SETTINGS_FAIL = 'GET_SYSTEM_SETTINGS_FAIL',

	SEARCH_SYSTEM_SETTING = 'SEARCH_SYSTEM_SETTING',

	ADD_NEW_SYSTEM_SETTING_TYPE = 'ADD_NEW_SYSTEM_SETTING_TYPE',
	RESET_SYSTEM_SETTING_TYPES = 'RESET_SYSTEM_SETTING_TYPES',
}
