import moment from "moment";
import { DateFormats } from "us.collection.admin/constants";
import { INonProductionDay } from "us.collection.admin/interfaces";
import { handleEndPointLocals } from "us.common/functions";

/**
 * @function
 * @description get drawer title
 * @param {INonProductionDay} values
 * @returns {string}
 */
export const getDrawerTitle = (values: INonProductionDay): string => {
    try {
        const { key, reason } = values;
        return !key.isEmpty()
            ? `${handleEndPointLocals("US.COMMON:COMMON.EDIT")} : ${reason}`
            : handleEndPointLocals("US.COLLECTION.COMMON:COMMON.ADD_NEW");
    } catch (error) {
        return "";
    }
};

/**
 * @function
 * @description check whether non production date is same as today
 * @param {string} nonProductionDate non production date
 * @returns {boolean}
 */
export const isNonProductionDateToday = (nonProductionDate: string): boolean => {
    try {
        return moment(nonProductionDate).isSame(moment().format(DateFormats.REQ));
    } catch (error) {
        return false;
    }
};

/**
 * @function
 * @description check whether non production date is same of before today
 * @param {string} nonProductionDate non production date
 * @returns {boolean}
 */
 export const isPreviousOrCurrentDate = (nonProductionDate: string): boolean => {
    try {
        return moment(nonProductionDate).isSameOrBefore(moment().format(DateFormats.REQ));
    } catch (error) {
        return false;
    }
};