import { handleEndPointLocals } from "us.common/functions";
import { IRoleFeature, IRoleOperation, IRoleAction, IRoleModule } from "us.common/interfaces";

/**
 * @function
 * @description set data for tree component
 * @param {IRoleModule} modules -API response
 * @returns tree component dataSource
 */
export const setTreeDataSource = (modules: IRoleModule) => {
    try {
        const { id, features = [] } = modules

        return [
            {
                title: handleEndPointLocals("US.COMMON:COMMON.ALL"),
                key: `all_${id}`,
                className: "fo-all",
                children: features.map(({ id, name, displayName, operations = [] }: IRoleFeature) => ({
                    title: displayName,
                    key: `${name}_${id}`,
                    className: "fo-group",
                    children: operations.map(({ id, name, displayName, actions = [] }: IRoleOperation) => (
                        {
                            title: displayName,
                            key: `${name}_${id}`,
                            className: "fo-group",
                            children:
                                actions.map(({ id, name }: IRoleAction) => ({
                                    title: name,
                                    key: id,
                                    className: "fo-item",
                                }))
                        }
                    ))
                }))
            }
        ]

    } catch (error) {
        return [];
    }
};