import React from "react";
import LottieAnimation from "us.common/components/Lottie";
import { LogoAnimation } from "us.common/components/animations";
export default ({ isDescriptionAvailable = false }) => (
  <div
    style={{
      position: "absolute",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      display: "flex",
      alignContent: "center",
      textAlign: "center",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      transform: "translateY( -100px)",
    }}
  >
    <LottieAnimation lotti={LogoAnimation} height={60} width={230} />
    {isDescriptionAvailable && (
      <p className="text-muted">Setting Up System Configuration</p>
    )}
  </div>
);

