import {
  ActivityType,
  EVENT_ACTIVITY_TYPE,
  EntityType,
} from "us.collection.admin/constants";
import { IActivityType } from "us.collection.admin/interfaces";
import { trimText } from "us.common/functions";
import _ from "lodash";

/**
 * @function
 * @description get activity type field name according to activity type
 * @param {string} activityType activity type
 * @returns {string} activity type field name
 */
export const getActivityTypeName = (activityType: string): string => {
  try {
    switch (activityType.toUpperCase()) {
      case ActivityType.SP:
        return ActivityType.STORED_PROCEDURE;
      case ActivityType.MESSAGE:
        return ActivityType.MESSAGE;
      case ActivityType.API:
        return ActivityType.API;
      case ActivityType.CODE:
        return ActivityType.CODE;
      case ActivityType.EVENT:
        return ActivityType.EVENT;
      default:
        return "";
    }
  } catch (error) {
    return "";
  }
};

/**
 * @function
 * @description filter activity types based on entity selection
 * @param {Array<IActivityType>} activityTypes activity type list
 * @returns {Array<IActivityType>} filtered activity types
 */
export const getActivityTypes = (
  activityTypes: Array<IActivityType>,
  selectedEntityType: string
): Array<IActivityType> => {
  try {
    if (
      trimText(selectedEntityType) == EntityType.CASE ||
      trimText(selectedEntityType) == EntityType.INVOICE
    ) {
      return activityTypes;
    } else {
      return activityTypes?.filter(
        ({ activityTypeName }) =>
          trimText(activityTypeName) != EVENT_ACTIVITY_TYPE?.toLowerCase()
      );
    }
  } catch (e) {
    return activityTypes;
  }
};

/**
 * @function
 * @description get event activity group id 
 * @param {Array} activityGroups activity group list
 * @returns {number} event activity group ID
 */
export const getEventActivityGroupId = (activityGroups: Array<any>): number => {
  try {
    if (Array.isArray(activityGroups)) {
      const activityGroupId = _.find(
        activityGroups,
        ({ activityGroupName }) =>
          trimText(activityGroupName) == EVENT_ACTIVITY_TYPE?.toLowerCase()
      )?.activityGroupId;
      return activityGroupId > 0 ? activityGroupId : 0;
    } else {
      return 0;
    }
  } catch (e) {
    return 0;
  }
};