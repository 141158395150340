import { IAPIAction } from "us.collection/interfaces";
import { Handler } from "us.collection/constants";

export const HandlerAction: Readonly<IAPIAction> = {
  handler: {
    get: (data) => ({
      type: Handler.GET_HANDLER_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Handler.GET_HANDLER_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Handler.GET_HANDLER_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  updateHandler: {
    update: (data) => ({
      type: Handler.UPDATE_HANDLER_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: Handler.UPDATE_HANDLER_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: Handler.UPDATE_HANDLER_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
