import React from "react";
import { SortSearch } from "./SortSearch";
import { $Skeleton } from "us.common/components";
import { ITabController } from "us.common/components/MainSearch/Interfaces";

/**
 * @description - Tab controller component with sort management.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const TabController: React.FC<ITabController> = (props) => {
  const { isLoading, tabInfo, changeSortOrderEvent } = props;

  return (
    <$Skeleton loading={isLoading} active paragraph={{ rows: 2 }}>
      <SortSearch
        tabInfo={tabInfo}
        changeSortOrderEvent={changeSortOrderEvent}
      />
      {props.children}
    </$Skeleton>
  );
};
