import moment from "moment";
import { IInitialState } from "us.collection.admin/reducers/NonProductionDays/Interfaces"
import { smsTime } from "us.collection.admin/functions";
import { NonProductionDayType } from "us.collection.admin/constants";

const selectedYear = moment().year();
export const initialState: IInitialState = {
  calendar: {
    data: {
      calendarId: -1,
      calendarName: "",
      selectedYear: selectedYear,
      startDate: moment.utc(`${selectedYear}-01-01`),
      endDate: moment.utc(`${selectedYear}-12-31`),
      selectedCriteria: "NonBanking",
      previousCalendars: [],
      isImported: false,
    },
    isFetching: false,
  },
  calendars: {
    data: [],
    isFetching: false,
  },

  smsSchedule: {
    data: smsTime.call(selectedYear),
    isFetching: false,
  },
  //////////////////////////////////
  scheduleTime: {
    data: [],
    nonSms: [],
    nonEmail: [],
    nonPrinting: [],
    isFetching: false,
  },
  redDays: {
    data: [],
    nonBanking: [],
    nonSms: [],
    nonPrinting: [],
    nonWorkflow: [],
    nonEmail: [],
    isFetching: false,
  },
  internationalHolidays: [],
  selectedCriteria: NonProductionDayType.Non_Banking,
  isSubmitting: false
};
