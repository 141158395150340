import React from "react";
import Common from "us.common";
import * as Azure from "us.helper/azure";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Router } from "react-router-dom";

const { LoginRedirect } = Common.Components;

export const IntialLayout = () => {
  const { instance } = useMsal();
  return (
    <UnauthenticatedTemplate>
      <LoginRedirect
        onLoginClick={() => instance.loginRedirect(Azure.loginRequest)}
      />
    </UnauthenticatedTemplate>
  );
};
