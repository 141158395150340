import { appInsights } from "us.helper";
import { BalanceTypes, CourtSentenceDates } from "../Constants";
import { IAccountSummary, IRowProperties } from "../Interfaces";

/**
 * @function
 * @description - calculate the total value for account summary or case balance
 * @param dataSource {IAccountSummary & { [key: string]: any }} - account summary values or case balance values depending on type
 * @param type {Array<IRowProperties>} - either accountSummary or caseBalance type
 * @param rows Array<IRowProperties> - optional parameter of rows provided to the view and used when type is caseBalance
 * @returns {number} - sum of the value fields
 */
export const calculateTotalValue = (
  dataSource: IAccountSummary & { [key: string]: any },
  type: BalanceTypes.ACCOUNT_SUMMARY | BalanceTypes.CASE_BALANCE,
  rows?: Array<IRowProperties>
): number => {
  try {
    if (type == BalanceTypes.ACCOUNT_SUMMARY) {
      return Object.values(dataSource).reduce(
        (prevVal: number, currVal: number) => Number(prevVal) + Number(currVal),
        0
      );
    } else {
      let sum = 0;
      rows?.map((row: IRowProperties) => {
        if (
          row["inputAmountName"] != CourtSentenceDates.DUE_DATE &&
          row["inputAmountName"] != CourtSentenceDates.LIMIT_DATE
        ) {
          sum = sum + dataSource[row["inputAmountName"]];
        }
      });
      return sum;
    }
  } catch (error) {
    appInsights.trackException(
      `calculateTotalValue function in Case Balance view in either sentence or enforcement exception - ${error}`
    );
    return 0;
  }
};
