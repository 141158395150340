import { CaseSubCriterias } from "us.common/constants";
import { ILabeledValue } from "us.common/interfaces";

/**
 * @function
 * @description It takes an enum and returns an array of objects with the enum's values as the object's properties
 * @returns An array of objects with the following properties:
 * label: string
 * value: string
 * key: string
 */
export const getCaseSubCriterias = (): ILabeledValue<CaseSubCriterias>[] => {
  try {
    return (Object.values(CaseSubCriterias) as Array<CaseSubCriterias>).map(
      (value:any) => ({
        label: value,
        value: value,
        key: value,
      })
    );
  } catch (e) {
    return [];
  }
};
