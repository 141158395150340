import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { AddNewCourt } from "us.collection.admin/components/Court/Components/AddNewCourt";
import { ManageCourtTypes } from "us.collection.admin/components/Court/Components/ManageCourtTypes";
import "./Court.scss";
import { BoxIcons, IconTypes, PlusOutlined } from "us.icons";
import Common from "us.common";
import { CourtInfoTable } from "./Components";
import {
  ITableInfo,
  IColumnSearchType,
  ICourtInfoAction,
  ICourtInfo,
  IDrawerInfo,
} from "us.collection.admin/components/Court/Interfaces";
import {
  DEFAULT_TABLE_INFO,
  CourtInfoAction,
  DrawerTypes,
} from "us.collection.admin/components/Court/Constants";
import { hasFilters } from "./Functions";
import * as Actions from "us.collection.admin/actions";
import { useHistory } from "react-router-dom";
import { ICourt } from "us.collection.admin/interfaces/CourtInfo";
import { handleGoBack } from "us.helper/utility";

const { $Button, $PageHeader, $Divider, $Skeleton, $Affix, $Select, $Drawer } =
  Common.Components;

/**
 * @description - Court info home view component to manage court information
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2861694980/Manage+Court+Info+UI+-+View
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/02/2022
 */

const Home: React.FC<PropsFromRedux & ICourt> = memo((props) => {
  const { t } = useTranslation(["US.COLLECTION.ADMIN", "US.COLLECTION.COMMON"]);
  const {
    courtTypes,
    courtItems,
    getCourtItems,
    getCourtItemRelevantPostalCodes,
    deleteCourtItem,
    courtItemsLoading,
    initCourtInfo,
    resetRelevantPostalCodes,
    selectedCourtType,
  } = props;

  const history = useHistory();

  const [drawerInfo, setDrawerInfo] = useState<IDrawerInfo>({
    visibility: false,
    drawerType: "",
    record: {},
  });
  const [tableInfo, setTableInfo] = useState<ITableInfo>(DEFAULT_TABLE_INFO);
  const [columnFilters, setColumnFilters] = useState<Array<any>>([]);

  useEffect(() => {
    initCourtInfo && initCourtInfo(-1);
  }, []);

  useEffect(() => {
    setTableInfo({ ...DEFAULT_TABLE_INFO, data: courtItems });
  }, [courtItems]);

  /**
   * @description - Close the drawer
   */
  const closeDrawer = () => {
    setDrawerInfo({
      visibility: false,
      drawerType: "",
    });
  };

  /**
   * @description - Reset table filters and sorting
   */
  const resetTable = () => {
    setTableInfo({ ...DEFAULT_TABLE_INFO, data: courtItems });
    setColumnFilters([]);
  };

  /**
   * @description - Filter table data by columns search input values
   * @param dataIndex - index key of the column
   * @param value - column search input value
   * @param type - column type `IColumnSearchType`
   * @returns filtered data - Array of filtered data
   */
  const filterTableData = (
    dataIndex: string,
    value: any,
    type: IColumnSearchType
  ) => {
    const filteredData = courtItems?.filter((remitItem: any) =>
      [
        ...columnFilters.filter(
          (filterItem: any) => filterItem.dataIndex !== dataIndex
        ),
        { dataIndex, value },
      ].every((searchText: any) => {
        return remitItem[searchText.dataIndex]
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText.value?.toString()?.toLowerCase());
      })
    );
    setTableInfo({ ...tableInfo, data: filteredData });
  };

  /**
   * @description Get column search input field current value by key
   * @param dataIndex - filtered column name
   * @param value - filter value
   * @returns columnFilters - filters column filters
   */
  const updateFilterText = (dataIndex: string, value: any) => {
    setColumnFilters([
      ...columnFilters?.filter((n: any) => n.dataIndex !== dataIndex),
      { dataIndex, value },
    ]);
  };

  /**
   * @description handle court item menu actions
   * @param action - action of clicked menu icon
   * @param record - selected court item for action
   */
  const onHandleCourtAction = (
    action: ICourtInfoAction,
    record: ICourtInfo
  ) => {
    const { courtItemId } = record;
    switch (action) {
      case CourtInfoAction.EDIT:
        setDrawerInfo({
          visibility: true,
          drawerType: DrawerTypes.COURT_ITEM_EDIT,
          record,
        });
        getCourtItemRelevantPostalCodes &&
          getCourtItemRelevantPostalCodes(courtItemId);
        break;
      case CourtInfoAction.DELETE:
        setTableInfo({
          ...DEFAULT_TABLE_INFO,
          data: courtItems.filter(
            (item: any) => item.courtItemId !== courtItemId
          ),
        });
        deleteCourtItem && deleteCourtItem({ courtItemId, selectedCourtType });
        break;
      default:
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ courtType: -1 }}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }: any) => (
        <>
          <div className="space-content manage-court">
            <$Affix offsetTop={48}>
              <div className="page-header header-border pr-0">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={() => handleGoBack(history)}
                      title={t("US.COLLECTION.ADMIN:COURT.MANAGE_COURT_INFO")}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Skeleton loading={false} active paragraph={{ rows: 0 }}>
                      <$Button
                        onClick={() => {
                          setDrawerInfo({
                            visibility: true,
                            drawerType: DrawerTypes.COURT_ITEM_ADD,
                          });
                          resetRelevantPostalCodes &&
                            resetRelevantPostalCodes();
                        }}
                        type="dashed"
                        data-testid="add_new_court"
                        size="small"
                        icon={<PlusOutlined />}
                      >
                        {t("US.COLLECTION.ADMIN:COURT.ADD_NEW_COURT")}
                      </$Button>
                      <$Button
                        onClick={() =>
                          setDrawerInfo({
                            visibility: true,
                            drawerType: DrawerTypes.MANAGE_COURT_TYPES,
                          })
                        }
                        type="dashed"
                        data-testid="Manage_court_types"
                        size="small"
                        icon={
                          <BoxIcons
                            className="mr-1"
                            type={IconTypes.BOX_ICON}
                            name="manage-court-types"
                          />
                        }
                        className="ml-2"
                      >
                        {t("US.COLLECTION.ADMIN:COURT.MANAGE_COURT_TYPES")}
                      </$Button>
                      <$Button
                        id="btn-reset-all"
                        type="default"
                        size="small"
                        className="ml-2"
                        onClick={() => {
                          resetForm();
                          resetTable();
                        }}
                        disabled={!hasFilters(columnFilters, tableInfo)}
                      >
                        {t("US.COLLECTION.COMMON:COMMON.RESET_ALL")}
                      </$Button>
                    </$Skeleton>
                  </div>
                  <div className="ml-5">
                    <span className="mr-2">
                      {t("US.COLLECTION.ADMIN:COURT.COURT_TYPE")}
                    </span>
                    <$Select
                      name="courtType"
                      size="small"
                      allOption={false}
                      style={{ width: 200 }}
                      defaultValue={"All"}
                      options={courtTypes}
                      optionValue="courtTypeId"
                      optionText="name"
                      onChange={(val: string) => {
                        getCourtItems && getCourtItems(val);
                      }}
                    />
                  </div>
                </div>
              </div>
            </$Affix>
            <$Skeleton
              loading={courtItemsLoading}
              active
              paragraph={{ rows: 2 }}
            >
              <div>
                <CourtInfoTable
                  tableData={tableInfo.data}
                  columnFilters={columnFilters}
                  tableInfo={tableInfo}
                  onFilter={filterTableData}
                  onChangeFilters={updateFilterText}
                  onTableChange={(
                    _pagination: any,
                    filters: any,
                    sorter: any
                  ) => setTableInfo({ ...tableInfo, filters, sorter })}
                  onCallAction={onHandleCourtAction}
                />
              </div>
            </$Skeleton>
          </div>
          <$Drawer
            title={
              drawerInfo?.drawerType === "AddNew"
                ? t("US.COLLECTION.ADMIN:COURT.NEW_COURT")
                : drawerInfo?.drawerType === "Edit"
                ? t("US.COLLECTION.ADMIN:COURT.EDIT_COURT")
                : t("US.COLLECTION.ADMIN:COURT.MANAGE_COURT_TYPES")
            }
            width={
              drawerInfo?.drawerType !== "ManageCourtTypes" ? "700px" : "600px"
            }
            visible={drawerInfo?.visibility}
            onClose={() =>
              setDrawerInfo({
                visibility: false,
                drawerType: "",
              })
            }
            destroyOnClose
            className={
              drawerInfo?.drawerType !== "ManageCourtTypes"
                ? "new-court"
                : "manage-court-types"
            }
          >
            {drawerInfo?.drawerType !== "ManageCourtTypes" && (
              <AddNewCourt onClose={closeDrawer} drawerData={drawerInfo} />
            )}
            {drawerInfo?.drawerType === "ManageCourtTypes" && (
              <ManageCourtTypes onClose={closeDrawer} />
            )}
          </$Drawer>
        </>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { courtInfo } = state;
  const {
    courtTypes,
    courtItems,
    courtItem,
    courtItemsLoading,
    selectedCourtType,
  } = courtInfo;

  return {
    courtTypes,
    courtItems,
    courtItem,
    courtItemsLoading,
    selectedCourtType,
  };
};

const { courtInfo } = Actions;
const {
  courtItems,
  relevantPostalCodesByItem,
  courtItem,
  initCourtInfo,
  relevantPostalCodes,
} = courtInfo;
const { getByParam } = courtItems;

const mapDispatchToProps = {
  getCourtItems: getByParam,
  getCourtItemRelevantPostalCodes: relevantPostalCodesByItem.getByParam,
  deleteCourtItem: courtItem.delete,
  initCourtInfo: initCourtInfo.getByParam,
  resetRelevantPostalCodes: relevantPostalCodes.resetData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
