
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { IconType } from 'antd/lib/notification'
import { $Notification } from 'us.common/components/antd'
import { Trans } from 'react-i18next';

export default (type: IconType, title: string, description: any, key: string) => {
    $Notification[type]({
        message: <Trans i18nKey={title}></Trans>,
        duration: 5,
        key,
        description: <Trans i18nKey={description}></Trans>,
        top: 54
    });
};