import React from "react";
import { useField } from "formik";
import { $Table } from "us.common/components";
import { columns } from "./Components";
import { ICreditNoteTable } from "us.common/components/CreditNote/Interface";

const CreditNotesTable = (props: ICreditNoteTable) => {
  const { isDefault, disabled = false, tableColumns = [] } = props;
  const [field, ,] = useField("creditNoteDetails");

  return (
    <$Table
      style={{ width: "100%" }}
      columns={columns(isDefault,disabled, tableColumns)}
      rowKey={(record) => record.articleNo}
      dataSource={field.value?.sort(
        (a: any, b: any) => Number(a?.articleNo) - Number(b?.articleNo)
      )}
      bordered
      size="small"
      pagination={false}
    />
  );
};

export default CreditNotesTable;
