import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { PlusOutlined } from "us.icons";
import { DrawerType, ManageFeeAction } from "../../Constants";
import { IMenuInfo } from "./Interfaces";
import { useHistory, useLocation } from "react-router-dom";
import {
  IManageFee,
  IFeeTypeData,
} from "us.collection.admin/components/ManageFee/Interfaces";
import { TYPE_MENU_DEFAULT_INFO } from "./Constants";
import {
  searchFeeTypes,
  updateUrl,
} from "us.collection.admin/components/ManageFee/Functions";
import "../../Home.scss";

const { $Menu, $Skeleton, $Affix, $Button, $Search } = Common.Components;

/**
 * @description - Fee Type menu view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const FeeType: React.FC<PropsFromRedux & IManageFee> = ({
  feeType,
  onCallAction,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace } = history;
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const feeTypeId = urlParams.get("typeId");
  const feeId = urlParams.get("feeId");

  const [menuInfo, setMenuInfo] = useState<IMenuInfo>(TYPE_MENU_DEFAULT_INFO);

  useEffect(() => {
    if (feeType?.list?.length > 0) {
      setMenuInfo({
        filteredMenuItems: feeType?.list,
        selectedMenuKeys: [feeType?.list[0]?.feeTypeId?.toString()],
      });
    }
  }, [feeType?.list]);

  const handleMenuItemClick = (typeId: number) => {
    const updatedFeeId = Number(feeId) == -1 ? Number(feeId) : 0;
    updateUrl(typeId, replace, updatedFeeId);
    setMenuInfo({
      ...menuInfo,
      selectedMenuKeys: [typeId?.toString()],
    });
  };

  /**
   * @description - Handle search fee type by filtering types
   * @param {string} searchText - search keyword to filter fee types
   */
  const handleSearch = (searchText: string) => {
    const searchedResults = searchFeeTypes(feeType?.list, searchText);

    updateUrl(searchedResults[0]?.feeTypeId, replace);
    setMenuInfo({
      filteredMenuItems: searchedResults,
      selectedMenuKeys: [searchedResults[0]?.feeTypeId.toString()],
    });
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {() => (
        <>
          <$Affix offsetTop={80}>
            <aside className="collection-fee-layout-side">
              <div className="pt-2">
                <div className="my-2 collection-fee-type-search">
                  <$Search
                    size="small"
                    name="search_fee_type"
                    className="w-100"
                    allowClear={true}
                    placeholder={t(
                      "US.COLLECTION.ADMIN:MANAGE_FEE.SEARCH_FEE_TYPE"
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleSearch(e.target.value)
                    }
                  />
                </div>
                <div>
                  <$Skeleton
                    loading={feeType.isListLoading}
                    paragraph={{ rows: 2 }}
                    active
                  >
                    {feeTypeId != null &&
                      menuInfo?.filteredMenuItems.length > 0 && (
                        <$Menu
                          defaultSelectedKeys={[feeTypeId]}
                          selectedKeys={menuInfo?.selectedMenuKeys}
                          className="collection-fee-type-menu"
                        >
                          {menuInfo?.filteredMenuItems.map(
                            ({ feeTypeId, feeTypeName }: IFeeTypeData) => (
                              <$Menu.Item
                                key={feeTypeId}
                                onClick={() => handleMenuItemClick(feeTypeId)}
                              >
                                {feeTypeName}
                              </$Menu.Item>
                            )
                          )}
                        </$Menu>
                      )}
                  </$Skeleton>
                </div>
                <div className="collection-fee-type-add">
                  <$Button
                    onClick={() =>
                      onCallAction(DrawerType.FEE_TYPE, ManageFeeAction.ADD)
                    }
                    type="dashed"
                    size="small"
                    id="add-new-type"
                    icon={<PlusOutlined />}
                    className=""
                  >
                    {t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_FEE_TYPE")}
                  </$Button>
                </div>
              </div>
            </aside>
          </$Affix>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeType } = manageFee;
  return {
    feeType,
  };
};
const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FeeType);
