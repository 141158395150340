export const addDebtorInitialData = {
  entRoleId: -1,
  otherpartyType: "P",
  emailAddress: "",
  personNo: "",
  organizationNo: "",
  otherpartyRoleType: null,
  birthDate: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  address3: "",
  telephone: "",
  reference: "",
  gender: "",
  zipCode: "",
  zipName: "",
  countyId: "",
  country: "NO",
  smsNo: "",
  isDefault: true,
  isAddressKnown: false,
  nin: "",
  isDebtorselection: false,
  creditorReference: "",
  isExistingPostalCode: true,
  isExistingMunicipalityCode: true,
  sms: "",
  home: "",
  mobile: "",
  work: ""
};
