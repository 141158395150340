import { IAPIActionBase } from "us.common/interfaces";
import { UserInformation } from "us.common/constants";

export const UserInformationAction: Readonly<IAPIActionBase> = {
  userProfilePhoto: {
    get: (data) => ({
      type: UserInformation.GET_USER_PROFILE_PHOTO_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserInformation.GET_USER_PROFILE_PHOTO_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserInformation.GET_USER_PROFILE_PHOTO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  tenantDetails: {
    get: (data) => ({
      type: UserInformation.GET_TENANT_DETAILS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserInformation.GET_TENANT_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserInformation.GET_TENANT_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  getQuickLinks: {
    get: (data) => ({
      type: UserInformation.GET_QUICK_LINKS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserInformation.GET_QUICK_LINKS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserInformation.GET_QUICK_LINKS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  addQuickLink: {
    save: (data) => ({
      type: UserInformation.ADD_QUICK_LINK_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserInformation.ADD_QUICK_LINK_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserInformation.ADD_QUICK_LINK_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  removeQuickLink: {
    save: (data) => ({
      type: UserInformation.REMOVE_QUICK_LINK_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UserInformation.REMOVE_QUICK_LINK_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UserInformation.REMOVE_QUICK_LINK_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
