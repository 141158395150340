export default {
	languageCodes: {
		nbNO: 'nb-NO',
		enUS: 'en-US',
		enGB: 'en-GB',
		jaJP: 'ja-JP',
		deDE: 'de-DE',
	},
	currencyCodes: {
		nbNO: 'NOK',
		enUS: 'USD',
		enGB: 'USD',
		jaJP: 'JPY',
		deDE: 'EUR',
	},
	dateFormats: {
		nbNO: 'DD.MM.YYYY',
		enUS: 'MM/DD/YYYY',
		enGB: 'DD/MM/YYYY',
		jaJP: 'YYYY/MM/DD',
		deDE: 'DD.MM.YYYY',
	},
	dateParams: {
		DATE: 'DATE',
		DATE_LONG: 'DATE_LONG',
		DATE_LONG_CUSTOM: 'DATE_LONG_CUSTOM',
		DATE_TIME12: 'DATE_TIME12',
		DATE_TIME24: 'DATE_TIME24',
		DATE_LONG_TIME12: 'DATE_LONG_TIME12',
		DATE_LONG_TIME24: 'DATE_LONG_TIME24',
		TIME_12: 'TIME_12',
		TIME_24: 'TIME_24',
	},
};

export const SESSION_TIMEOUT = 3600000;

export * from './User';
export * from './Role';
export * from './CommonAppData';
export * from './CreditorSelection';
export * from './UserAuthorization';
export * from './MainSearch';
export * from './Component';
export * from './Notifications';
export * from './MainSearchActions';
export * from './TenantList';
export * from './DebtorSelection';
export * from './NewDebtor';
export * from './UserInformation';
export * from './SelectedDays';
export * from './ActivityExecution';
export * from './Actions';
export * from './EntityDetail';
export * from './KeyboardKeys';
export * from './FollowUpUniqueColumn';
export * from './AuthorityLevel';
export * from './DateTimeFormats'