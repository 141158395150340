export enum Entity {
	GET_ENTITY_DETAIL_START = 'GET_ENTITY_DETAIL_START',
	GET_ENTITY_DETAIL_SUCCESS = 'GET_ENTITY_DETAIL_SUCCESS',
	GET_ENTITY_DETAIL_FAIL = 'GET_ENTITY_DETAIL_FAIL',

	GET_POSTAL_AREAS = 'GET_POSTAL_AREAS',
	GET_POSTAL_AREAS_SUCCESS = 'GET_POSTAL_AREAS_SUCCESS',
	GET_POSTAL_AREAS_FAIL = 'GET_POSTAL_AREAS_FAIL',

	GET_MUNICIPALS = 'GET_MUNICIPALS',
	GET_MUNICIPALS_SUCCESS = 'GET_MUNICIPALS_SUCCESS',
	GET_MUNICIPALS_FAIL = 'GET_MUNICIPALS_FAIL',

	ADD_POSTAL_AREAS = 'ADD_POSTAL_AREAS',
	ADD_POSTAL_AREAS_SUCCESS = 'ADD_POSTAL_AREAS_SUCCESS',
	ADD_POSTAL_AREAS_FAIL = 'ADD_POSTAL_AREAS_FAIL',

	SELECTED_POSTAL_CODE_DETAIL = 'SELECTED_POSTAL_CODE_DETAIL',
	SELECTED_MUNICIPALITY_DETAIL = 'SELECTED_MUNICIPALITY_DETAIL',

	POSTAL_CODE_FORM_DATA = 'POSTAL_CODE_FORM_DATA',
	CLEAR_POSTAL_CODE_DATA = 'CLEAR_POSTAL_CODE_DATA',
	CHANGE_POSTAL_AREA_DRAWER_NAME = 'CHANGE_POSTAL_AREA_DRAWER_NAME',

	GET_POSTAL_CODE_AVAILABILITY_START = 'GET_POSTAL_CODE_AVAILABILITY_START',
	GET_POSTAL_CODE_AVAILABILITY_SUCCESS = 'GET_POSTAL_CODE_AVAILABILITY_SUCCESS',
	GET_POSTAL_CODE_AVAILABILITY_FAIL = 'GET_POSTAL_CODE_AVAILABILITY_FAIL',

	SET_SAVE_STATUS = 'SET_SAVE_STATUS',

	UPDATE_ENTITY_DETAIL_SUCCESS = 'UPDATE_ENTITY_DETAIL_SUCCESS',
	CLEAR_EXISITING_CODE = 'CLEAR_EXISITING_CODE',
}
