import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import AddNew from './AddNew';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IActivityGroup extends RouteComponentProps {

}

export default withRouter((props: IActivityGroup) => {

    const { history } = props;
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
    const { t } = useTranslation();

    const AddClickHandler = () => {
        history.push('/activities');
    }

    const showDrawer = () => {
        setIsDrawerVisible(true);
    }

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    }


    return (
        <>

            <p>Hi this is ActivityGroups.Home</p>
            <Button onClick={showDrawer}>Add New</Button>
            <p>{t('US.COLLECTION.ADMIN:ACTIVITYGROUP.TEST')}</p>

            <Drawer
                title="Add New Activity"
                placement="right"
                onClose={closeDrawer}
                visible={isDrawerVisible}
                getContainer={false}
                style={{ position: 'absolute' }}
            >
                <AddNew />
            </Drawer>
        </>
    )
});