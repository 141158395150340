import { FeeRangeTableColumns } from "../../Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";

/**
 * @description Get column search input field current value by key
 * @param key - input field name
 * @returns value - Current value of the input field
 */
export const getColumnFilterProps = (key: FeeRangeTableColumns) => {
  switch (key) {
    case FeeRangeTableColumns.MENU:
      return {};
    case FeeRangeTableColumns.FROM_AMOUNT:
    case FeeRangeTableColumns.TO_AMOUNT:
    case FeeRangeTableColumns.AMOUNT:
    case FeeRangeTableColumns.DOUBLE_AMOUNT:
      return { customFilter: ColumnFilterTypes.AMOUNT, showFilters: true };
    default:
      return { customFilter: true, showFilters: true };
  }
};

/**
 * @description Get dynamic column width
 * @param key - Column key
 * @returns Width of the column
 */
export const getColumnWidth = (key: FeeRangeTableColumns): number => {
  switch (key) {
    case FeeRangeTableColumns.MENU:
      return 45;
    case FeeRangeTableColumns.FROM_AMOUNT:
      return 140;
    case FeeRangeTableColumns.TO_AMOUNT:
      return 130;
    case FeeRangeTableColumns.NO_OF_FEES:
      return 120;
    case FeeRangeTableColumns.AMOUNT:
      return 110;
    case FeeRangeTableColumns.DOUBLE_AMOUNT:
      return 150;
    default:
      return 100;
  }
};
