import React from "react";
import { Form } from "antd";
import { useField } from "formik";
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-github";
import * as Utility from "us.common/components/antd/utility";
import { ISQLEditor } from "./Interfaces";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * @description - SQL Editor Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942664743/Follow-up+Custom+Query+Workspace+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 26/04/2022
 */
const $I = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      required,
      disabled,
      className,
      onBlur,
      onChange,
      formitem,
      value,
      ...rest
    }: ISQLEditor,
    ref: any
  ) => {
    const [field, meta, helpers] = useField(name);
    const error = meta?.error;
    const isTouched = meta?.touched;
    const { t } = useTranslation();

    return (
      <Form.Item
        label={label}
        help={isTouched && error && t(error as string)}
        validateStatus={Utility.validateStatus(error, isTouched)}
        required={typeof required == "undefined" ? false : true}
        extra={formitem?.extra}
      >
        <AceEditor
          {...field}
          {...rest}
          placeholder={placeholder}
          className={className}
          mode="mysql"
          theme="textmate"
          name={name}
          onBlur={(e: any) => {
            onBlur && onBlur(e);
            field.onBlur(e);
          }}
          onChange={(val: any) => {
            helpers.setTouched(true)
            typeof onChange == "function"
              ? onChange(val)
              : helpers?.setValue(val);
          }}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          minLines={25}
          maxLines={25}
          width="100%"
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
          readOnly={disabled}
        />
      </Form.Item>
    );
  }
);

$I.propTypes = {
  // name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export const $SQLEditor = $I;
