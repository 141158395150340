import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { parse, stringify } from "query-string";
import { RootState } from "us.helper/types";
import {
  $Skeleton,
  $TableTree,
  $Popconfirm,
  $Popover,
  $Button,
  ITableTreeColumns,
} from "us.common/components";
import { MoreOutlined, EditOutlined, DeleteOutlined } from "us.icons";
import { manageActivities } from "us.collection.admin/actions";
import {
  ACTIVITY_SUMMARY_COLUMNS,
  ACTIVITY_SUMMARY_COLUMN_NAMES,
} from "./Constants";
import {
  handleFilterGroup,
  handleSortGroup,
  handleExpandedRows,
} from "./Functions";
import { useHistory, useLocation } from "react-router-dom";
import { useField } from "formik";
import { IActivitySummary } from "us.collection.admin/interfaces";
import _ from "lodash";
import {
  ActivitySummaryFilters,
  EntityTypes,
} from "us.collection.admin/constants";
import { DeleteActivity } from "us.collection.admin/components/ManageActivities/Repository";

const { activities, activity } = manageActivities;

/**
 * @description -  Activity Summary Home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3001221121/Get+Activity+Summary+UI+Implementation
 * @author Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 31/05/2022
 */
const ActivityTable: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { search, state } = useLocation();
  const { replace } = useHistory();
  const [popVisible, setPopVisible] = useState<number>(-1);
  const [expandedRows, setExpandedRows] = useState<Array<number>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);

  const [expandAllField] = useField("expandAll");
  const isExpandAll = expandAllField.value;

  const { searchActivities, deleteActivity, list, manageDrawer } = props;

  const { dataSource, isFetching } = list;

  useEffect(() => {
    const currentFilters = parse(search);
    searchActivities && searchActivities(currentFilters);
  }, [search]);

  useEffect(() => {
    const entityType = parse(search)[ActivitySummaryFilters.ENTITY_TYPE];
    const tableData = _.find(dataSource, {
      entitytype:
        entityType == EntityTypes.AR ? EntityTypes.DEBTOR : entityType,
    })?.children;
    setTableData(tableData);
    if (isExpandAll) {
      setExpandedRows(
        handleExpandedRows(tableData, expandedRows, 0, true, true)
      );
    } else {
      setExpandedRows([]);
    }
  }, [dataSource, isExpandAll]);

  useEffect(() => {
    const tableArray = document.getElementsByClassName("ant-table-body");
    tableArray &&
      tableArray.length > 0 &&
      tableArray[0].querySelector("table")?.scrollIntoView(true);
  }, [tableData]);

  /**
   * @function
   * @description - click on the delete option in more button
   * @param record {IActivitySummary} - record of data source
   * @returns {void}
   */
  const onClickDelete = (record: IActivitySummary): void => {
    setPopVisible(-1);
    const deleteReq = DeleteActivity.call(record);
    deleteActivity && deleteActivity(deleteReq, replace, state);
  };

  /**
   * @function
   * @description - Popover actions in table
   * @param record
   * @returns {JSX.Element}
   */
  const content = (record: IActivitySummary): JSX.Element => (
    <div className="table-more-content">
      <div
        className="d-flex flex-row more-item"
        onClick={() => {
          setPopVisible(-1);
          onEditActivity(record.activityId);
        }}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.ADMIN:ACTIVITIES.DELETECONFIRM")}
        onConfirm={() => onClickDelete(record)}
        onCancel={() => setPopVisible(-1)}
      >
        <div className="d-flex mb-2 flex-row more-item text-error">
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const onEditActivity = (activityId: number) => {
    const previousSearchParams = parse(location.search);
    const newSearchParams = stringify({
      ...previousSearchParams,
      "activity-id": activityId,
    });
    replace({ search: newSearchParams });

    manageDrawer && manageDrawer({ isVisible: true, isEdit: true });
  };
  const columns: ITableTreeColumns = ACTIVITY_SUMMARY_COLUMNS.map(
    (column: any) => {
      const { dataIndex } = column;
      if (dataIndex == ACTIVITY_SUMMARY_COLUMN_NAMES.ACTIVITY_GROUP) {
        return {
          ...column,
          key: dataIndex,
          ellipsis: true,
          customRenderParent: (text: any, record: any, index: number) => {
            return {
              children: (
                <>
                  <strong className="py-2">{record.activityGroup}</strong>
                </>
              ),
              key: index,
              props: {
                colSpan: 7,
              },
            };
          },
          customRenderChild: (text: any, record: any, index: number) => {
            return {
              children: <></>,
              key: index,
              props: {
                colSpan: 1,
              },
            };
          },
        };
      } else if (dataIndex == ACTIVITY_SUMMARY_COLUMN_NAMES.MORE) {
        return {
          ...column,
          key: dataIndex,
          customRenderChild: (text: any, record: any) => {
            return (
              <>
                <$Popover
                  trigger="click"
                  placement="rightTop"
                  content={content(record)}
                  destroyTooltipOnHide
                  visible={record.activityId == popVisible}
                  onVisibleChange={(visible: boolean) =>
                    setPopVisible(visible ? record.activityId : -1)
                  }
                >
                  <$Button icon={<MoreOutlined />} size="small" />
                </$Popover>
              </>
            );
          },
        };
      } else {
        return {
          ...column,
          title: column.title && t(column.title),
          key: dataIndex,
          ellipsis: true,
          customFilter: true,
          customSorter: (a: any, b: any) =>
            dataIndex == ACTIVITY_SUMMARY_COLUMN_NAMES.ACTIVITY_ID
              ? a - b
              : a?.localeCompare(b),
        };
      }
    }
  );
  return (
    <div className="flex-fill">
      <div className="activities-layout-content">
        <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
          <$TableTree
            rowKey={(record: any) => {
              return record.hasOwnProperty("children")
                ? record.activityGroup
                : record.activityId;
            }}
            data={tableData}
            onSort={handleSortGroup}
            onFilter={handleFilterGroup}
            filterOnType={true}
            size="small"
            className="activities-table"
            bordered
            pagination={{ defaultPageSize: 15 }}
            scroll={{
              x: 900,
              y: "calc(100vh - 215px)",
              scrollToFirstRowOnChange: true,
            }}
            columns={columns}
            defaultExpandAllRows={true}
            onRow={(record: any, rowIndex: any) => {
              return {
                onDoubleClick: (event: any) => {
                  onEditActivity(record.activityId);
                },
              };
            }}
            expandedRowKeys={expandedRows}
            onExpand={(isExpand: boolean, record: any) =>
              setExpandedRows(
                handleExpandedRows(
                  dataSource,
                  expandedRows,
                  record.activityGroup,
                  isExpand
                )
              )
            }
            firstColSkipFilterProps={-1}
            data-testid="activity-summary-table"
            resetOnSourceChange={true}
          />
        </$Skeleton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { activities } = manageActivities;
  const { list } = activities;
  return { list };
};

const mapDispatchToProps = {
  searchActivities: activities.search,
  manageDrawer: activity.manageDrawer,
  deleteActivity: activity.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivityTable);
