import { IEntity, IPostalCode } from 'us.common/interfaces';
import moment from "moment";
export const entityDetail: IEntity = {
  entityID: 0,
  entRoleId: 0,
  entityType: "",
  firstName: "",
  lastName: "",
  idNo: "",
  birthDate: moment(),
  pid: 0,
  gender: "",
  address1: "",
  address2: "",
  address3: "",
  zipCode: "",
  zipName: "",
  municipalityCode: "",
  municipalityName: "",
  countyCode: "",
  countryCode: "",
  email: "",
  preferredCommunicationMethod: "",
  bankAccountNo: 0,
  debtorType: "",
  tags: [
    {
      tag: "",
      value: ""
    }

  ],
  phoneNumbers: [
    {
      type: "",
      number: ""
    }
  ]
}

export const postalCode: IPostalCode = {
  postalCodeId: 0,
  postalCode: '',
  postalPlace: '',
  municipalityCode: '',
  municipalityName: '',
}