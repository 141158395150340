import { NonProductionDaySagas } from "./NonProductionDays";
import { CreditorGroupSagas } from "./CreditorGroup";
import { CourtInfoSagas } from "./CourtInfo";
import { SystemSettingsSaga } from "./SystemSettings";
import { ManageFollowupSagas } from "./ManageFollowup";
import { ManageFeeSagas } from "./ManageFee";
import { ManageActivitiesSagas } from "./ManageActivities";
import { MunicipalitiesSagas } from "./Municipalities";

export default [
  ...NonProductionDaySagas,
  ...CreditorGroupSagas,
  ...CourtInfoSagas,
  ...SystemSettingsSaga,
  ...ManageFollowupSagas,
  ...ManageFeeSagas,
  ...ManageActivitiesSagas,
  ...MunicipalitiesSagas
];
