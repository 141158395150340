const checkdigit = require('checkdigit')

/**
 * check organization number
 * @param no organization number
 * @returns if number is valid then retrun true ,otherwise return false
 */
export const organizationNoValidate = (no: string): boolean => {
    try {
        if (no === '') {
            return true
        } else if (no?.startsWith(' ') || no?.endsWith(' ')) {
            return false;
        }
        else if (isNaN(Number(no.replace(/\s/g, '')))) {
            return false;
        } else {
            return no?.toString().replace(/\D/g, '').length === 9 && checkdigit.mod11.isValid(no?.toString().replace(/\D/g, ''))
        }
    } catch (error) {
        return false;
    }
}

/**
 * Check person number
 * @param no person number
 * @returns if number is valid then retrun true ,otherwise return false
 */
export const personNoValidation = (no: string): boolean => {
    try {
        if (no === '') {
            return true
        } else if (no?.startsWith(' ') || no?.endsWith(' ')) {
            return false;
        }
        else if (isNaN(Number(no.replace(/\s/g, '')))) {
            return false;
        } else {
            return no?.toString().replace(/\D/g, '').length === 6 || (no?.toString().replace(/\D/g, '').length >= 9 && no?.toString().replace(/\D/g, '').length <= 11);
        }
    } catch (error) {
        return false;
    }
}