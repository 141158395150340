import {
	IDebtorCaseSearchResult,
	IDebtorSearchResult,
	IDebtorARSearchResult,
} from 'us.common/reducers/MainSearch/Interfaces';
import { IFilterValues } from '../Interfaces';

/**
 * @function
 * @description Determine if the case under debtor.ar should be included based on the filter criterias
 * @returns the boolean decision if the debtor.ar.case should be included
 */
export const doesDebtorCaseInclude = (
	{ isOpen }: IDebtorCaseSearchResult,
	{ isOpenCase, isCloseCase }: IFilterValues
): boolean => (isOpenCase && isOpen) || (isCloseCase && !isOpen);

/**
 * @function
 * @description Determine if the ar under debtor should be included based on the filter criterias
 * @returns the boolean decision if the debtor.ar should be included
 */
export const doesARInclude = (
	{ creditorName, creditorNumber, arNo, custId }: IDebtorARSearchResult,
	{ creditorNameNo, arNo: filterARNo, custId: filterCustId }: IFilterValues
): boolean => {
	try {
		return (
			(creditorNameNo
				? creditorName.toUpperCase().includes(creditorNameNo.toUpperCase()) ||
				  creditorNumber.toString().includes(creditorNameNo)
				: true) &&
			(filterARNo ? arNo === +filterARNo : true) &&
			(filterCustId ? custId === custId : true)
		);
	} catch (e) {
		return false;
	}
};

/**
 * @function
 * @description Determine if the debtor object should be included based on the filter criterias
 * @returns the boolean decision if the debtor should be included
 */
export const doesDebtorInclude = (
	{ debtorName, debtorEntNo }: IDebtorSearchResult,
	{ debtorNameNo }: IFilterValues
): boolean => {
	try {
		return debtorNameNo
			? debtorName.toUpperCase().includes(debtorNameNo.toUpperCase()) ||
					debtorEntNo.toString().includes(debtorNameNo)
			: true;
	} catch (e) {
		return false;
	}
};
