import {
  Configuration,
  UserAgentApplication,
  Logger,
  LogLevel,
  AuthenticationParameters,
} from "msal";
import React from "react";

const logger = new Logger(loggerCallback, { level: LogLevel.Warning });

function loggerCallback(logLevel: any, message: any, piiLoggingEnabled: any) {
  console.log(message);
}

let myMSALObj: any;

const state: any = {
  launchApp: null,
  accessToken: null,
};

const b2cPolicies = {
  names: {
    signUpSignIn: window._ENV.REACT_APP_SIGN_IN_POLICY,
    forgotPassword: window._ENV.REACT_APP_PASSWORD_RESET_POLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${window._ENV.REACT_APP_TANENT}.b2clogin.com/${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_SIGN_IN_POLICY}`,
    },
    forgotPassword: {
      authority: `https://${window._ENV.REACT_APP_TANENT}.b2clogin.com/${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_PASSWORD_RESET_POLICY}`,
    },
  },
};

// const apiConfig = {
//     b2cScopes: [`https://${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/api/demo.read`],
//     webApi: ``
// };

const apiConfig = {
  b2cScopes: [
    `https://${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_AZURE_CLIENT_ID}/user.read`,
  ],
  webApi: `https://${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/api`,
};
const msalConfig = {
  auth: {
    // clientId: window._ENV.REACT_APP_AZURE_CLIENT_ID,
    // authority: b2cPolicies.authorities.signUpSignIn.authority,
    // validateAuthority:
    clientId: window._ENV.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://${window._ENV.REACT_APP_TANENT}.b2clogin.com/${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_SIGN_IN_POLICY}`,
    redirectUri: window.location.origin,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
};
/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const loginRequest = {
  // scopes: [
  //     `https://${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/api/user_impersonation`
  // ],
  scopes: [
    `https://${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_AZURE_CLIENT_ID}/user.read`,
  ],
};

// Add here scopes for access token to be used at the API endpoints.
const tokenRequest = {
  scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

function authRedirectCallBack(error: any, response: any) {
  // Error handling
  if (error) {
    console.log(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      try {
        // Password reset policy/authority
        //  myMSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
        //myMSALObj.loginRedirect(`https://${window._ENV.REACT_APP_TANENT}.b2clogin.com/${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_PASSWORD_RESET_POLICY}`);
        myMSALObj.authority = `https://${window._ENV.REACT_APP_TANENT}.b2clogin.com/${window._ENV.REACT_APP_TANENT}.onmicrosoft.com/${window._ENV.REACT_APP_PASSWORD_RESET_POLICY}`;
      } catch (err) {
        console.log(err);
      }
    }
  } else {
    // We need to reject id tokens that were not issued with the default sign-in policy.
    // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
    // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    if (
      response.tokenType === "id_token" &&
      response.idToken.claims["acr"] !== window._ENV.REACT_APP_SIGN_IN_POLICY
    ) {
      //b2cPolicies.names.signUpSignIn
      // myMSALObj.logout();
      // window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
    } else if (
      response.tokenType === "id_token" &&
      response.idToken.claims["acr"] === window._ENV.REACT_APP_SIGN_IN_POLICY
    ) {
      //b2cPolicies.names.signUpSignIn

      if (myMSALObj.getAccount()) {
        //   updateUI();
      }
    } else if (response.tokenType === "access_token") {
      state.accessToken = response.accessToken;
      // logMessage("Request made to Web API:");
      // if (accessToken) {
      //     try {
      //         callApiWithAccessToken(apiConfig.webApi, accessToken);
      //     } catch (err) {
      //         console.log(err);
      //     }
      // }
    } else {
    }
  }
}

// main method to get token with redirect flow
function getTokenRedirect(successCallback: any) {
  return myMSALObj
    .acquireTokenSilent(loginRequest)
    .then((response: any) => {
      if (response.accessToken) {
        state.accessToken = response.accessToken;
        if (state.launchApp) {
          state.launchApp();
        }
        if (successCallback) {
          successCallback();
        }
      }
    })
    .catch((error: any) => {
      console.log(
        "Silent token acquisition fails. Acquiring token using redirect"
      );
      console.log(error);
      // fallback to interaction when silent call fails
      myMSALObj.acquireTokenRedirect(loginRequest);
    });
}

function acquireToken(successCallback?: any) {
  if (!myMSALObj.getAccount()) {
    //myMSALObj.handleRedirectCallback(authRedirectCallBack);
    myMSALObj.loginRedirect(loginRequest);
    // myMSALObj.loginRedirect("http://localhost:3001");
  } else {
    getTokenRedirect(successCallback);
  }
}

// helper function to access the resource with the token
function callApiWithAccessToken(endpoint: any, token: any) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  fetch(endpoint, options)
    .then((response) => response.json())
    .then((response) => {})
    .catch((error) => {
      console.log("Error calling the Web api:\n" + error);
    });
}

interface IProps {}

interface IState {
  signedIn?: any;
  error?: any;
}

const authenticationB2C = {
  initialize: () => {
    myMSALObj = new UserAgentApplication(msalConfig as any);
    // Register Callbacks for Redirect flow
    myMSALObj.handleRedirectCallback(authRedirectCallBack);
  },
  run: (launchApp: any) => {
    state.launchApp = launchApp;
    acquireToken();
  },
  required: (WrappedComponent: any, renderLoading: any) => {
    return class extends React.Component<IProps, IState> {
      constructor(props: any) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      UNSAFE_componentWillMount() {
        acquireToken(() => {
          this.setState({
            signedIn: true,
          });
        });
      }

      render() {
        if (this.state.signedIn) {
          return <WrappedComponent {...this.props} />;
        }
        return typeof renderLoading === "function" ? renderLoading() : null;
      }
    };
  },
  signOut: () => myMSALObj.logout(),
  getAccessToken: () => state.accessToken,
};

export default authenticationB2C;
