import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MunicipalityColumns } from "us.collection.admin/components/Municipalities/Constants";
import { MunicipalityActions } from "us.collection.admin/constants";
import { MoreOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { ItemMenu } from "./Components";
import { IMunicipalitiesTable } from "./Interfaces";
import {
  getCountryFilters,
  trim,
} from "us.collection.admin/components/Municipalities/Functions";
import {
  $Button,
  $Popover,
  $TableTree,
  $Skeleton,
  ITableTreeColumns,
} from "us.common/components";
import { INITIAL_ITEM_MENU, COLUMNS } from "./Constants";
import { VList } from "virtuallist-antd";

/**
 * @description - Municipalities table component for show all item types.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3340959765/Manage+Municipalities+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 26/07/2023
 */
const MunicipalitiesTable: React.FC<IMunicipalitiesTable & PropsFromRedux> = (
  props
) => {
  const { countries, municipalityList, onCallAction } = props;
  const { t } = useTranslation();

  const [itemMenu, setItemMenu] =
    useState<typeof INITIAL_ITEM_MENU>(INITIAL_ITEM_MENU);

  // Table virtualization
  const vComponents = useMemo(() => {
    return VList({
      resetTopWhenDataChange: false,
      height: "calc(100vh - 180px)", // same value for scroll-y
    });
  }, [municipalityList.data]);

  // auto close item menu after 5 seconds
  const setAutoClose = (municipalityId: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          municipalityId,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @function
   * @description Get table columns list
   * @returns {ITableTreeColumns} - TableTree columns
   */
  const getColumns = (): ITableTreeColumns => {
    const columns: ITableTreeColumns = [];

    COLUMNS.map(({ key, title, width }: any) => {
      let column: any = {
        key: key,
        dataIndex: key,
        title: title && t(title),
        width,
      };
      if (key === MunicipalityColumns.MENU) {
        // configure menu column
        column = {
          ...column,
          align: "center",
          customRenderChild: (_text: any, record: any) => (
            <div className="align-items-center" key={0}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={
                  itemMenu.visible &&
                  itemMenu?.municipalityId === record.municipalityId
                }
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    municipalityId: record.municipalityId,
                  });
                  setAutoClose(0);
                }}
                content={
                  <ItemMenu
                    onCallAction={(actionType: MunicipalityActions) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        municipalityId: record.municipalityId,
                      });
                      onCallAction(actionType, record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          ),
        };
      } else if (
        [
          MunicipalityColumns.COUNTRY_CODE,
          MunicipalityColumns.COUNTRY_NAME,
        ].includes(key)
      ) {
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customSorter: (a: any, b: any) => a?.localeCompare(b),
          customRenderChild: (text: any, record: any) => (
            <span>{record[key]}</span>
          ),
          filters: getCountryFilters(countries.data, key),
          filterSearch: true,
          onFilter: (value: string, { countryName, countryCode }: any) =>
            key == MunicipalityColumns.COUNTRY_NAME
              ? trim(countryName) == trim(value)
              : trim(countryCode) == trim(value),
        };
      } else {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          customSorter: (a: any, b: any) => a?.localeCompare(b),
          customRenderChild: (text: any, record: any) => (
            <span>{record[key]}</span>
          ),
        };
      }
      // add configured column data object to the columns array
      columns.push(column);
    });

    return columns;
  };

  return (
    <$Skeleton
      loading={municipalityList.isLoading}
      active
      paragraph={{
        rows: 2,
      }}
    >
      <div className="municipality-table">
        <$TableTree
          rowKey={(record: any) => record.municipalityId}
          data={municipalityList.data}
          size="small"
          filterOnType
          bordered
          className="mt-3"
          onSort={(sortData, dataSource) => {
            return sortData(dataSource);
          }}
          onFilter={(searchData, dataSource) => {
            return searchData(dataSource);
          }}
          pagination={false}
          onRow={(record, _rowIndex) => {
            return {
              onDoubleClick: () =>
                onCallAction(MunicipalityActions.EDIT, record),
            };
          }}
          scroll={{ x: 900, y: "calc(100vh - 180px)" }}
          columns={getColumns()}
          data-testid="municipality-type-table"
          locale={{
            filterConfirm: t("COMMON.OK"),
            filterReset: t("COMMON.RESET"),
          }}
          components={vComponents}
          resetOnSourceChange={true}
        />
      </div>
    </$Skeleton>
  );
};

const mapStateToProps = (state: RootState) => {
  const { municipalities } = state;
  const { municipalityList, countries } = municipalities;

  return {
    municipalityList,
    countries,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MunicipalitiesTable);
