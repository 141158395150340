export const initialState = {
  courtTypes: [
    {
      courtTypeId: -1,
      name: "ALL",
    },
  ],
  courtItems: [],
  courtItemsLoading: false,
  selectedPostalCodes: [],
  selectedCourtType: -1,
  postalAreasForCourt: [],
  isLoadingPostalCode: false,
  isValidPostalCode: true,
  initialFormData: {
    courtType: -1,
    courtName: "",
    address1: "",
    address2: "",
    postalCode: "",
    postalArea: "",
    telephone: "",
    orgNo: "",
    bankAccountNo: "",
    selectedPostalCodes: [],
  },
};
