import React from "react";
import { Form } from "antd";
import { useField } from "formik";
import * as Utility from "../utility";
import { IInputAccountNoParams } from "us.common/components/antd/Interfaces";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

/**
 * @function create returns control digit using modulus 11 algorithm
 * @param input value for create control digit
 */
const getControlDigit = (input: string) => {
  let sum = 0;
  input
    ?.split("")
    ?.reverse()
    ?.forEach(function (value, index) {
      sum += parseInt(value, 10) * ((index % 6) + 2);
    });
  const modulus = sum % 11;
  if (modulus === 0) {
    return "0";
  } else if (modulus === 1) {
    return "-";
  } else {
    return 11 - modulus + "";
  }
};

/**
 * @function isValid check provided value is valid
 * @param input value for create control digit
 *
 */
const isValid = (input: string) => {
  const formatted = input?.replace(/\D/g, "");
  const checkDigitIndex = formatted?.length - 1;
  return (
    /^[0-9-( )]+$/.test(input) &&
    formatted?.length === 11 &&
    formatted?.substring(checkDigitIndex) ===
      getControlDigit(formatted?.substring(0, checkDigitIndex))
  );
};

const $I = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      mask = "9999 99 99999",
      type,
      size,
      className = "ant-input-sm",
      disabled,
      validationMessage,
      required = false,
      maxLength,
      onKeyDown,
      onBlur,
      onChange,
      max,
      min,
      style,
      tabIndex,
      dataTestid,
    }: IInputAccountNoParams,
    ref: any
  ) => {
    const [field, meta, helpers] = useField({
      name,
      validate: (value) => {
        let message: any = undefined;
        if (!isValid(value) && value) {
          message = validationMessage
            ? validationMessage
            : "COMMON.INVALID_ACCOUNT_NO";
        }
        return message;
      },
    });

    const error = meta?.error;
    const isTouched = meta?.touched;
    const { t } = useTranslation();

    return (
      <Form.Item
        label={label}
        help={isTouched && error && t(error as string)}
        validateStatus={Utility.validateStatus(error, isTouched)}
        required={required}
      >
        <InputMask
          {...field}
          mask={mask}
          maskPlaceholder={placeholder}
          type={type}
          style={style}
          max={max}
          min={min}
          ref={ref}
          size={size}
          tabIndex={tabIndex}
          disabled={disabled}
          className={`ant-input ${className}`}
          data-testid={dataTestid}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          onBlur={(e: any) => {
            onBlur && onBlur(e);
            field.onBlur(e);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            typeof onChange == "function"
              ? onChange(e)
              : helpers?.setValue(e.target.value);
          }}
          // @ts-ignore:next-line
          maskChar={null}
        />
      </Form.Item>
    );
  }
);

$I.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validationMessage: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.element,
  prefix: PropTypes.element,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addonBefore: PropTypes.element,
  addonAfter: PropTypes.element,
  onPressEnter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const $InputAccountNo = $I;
