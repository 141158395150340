import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.common/actions";
import * as API from "us.common/services";
import { CreditorSelection } from "us.common/constants";
const { creditorSelectionActions } = Actions;

const creditorSelectionSagas = {
  init: {
    get: function* (action: any): any {
      try {
        const params = action?.payload?.data ?? {};
        const { selectedKeys } = params ?? {};
        const { groups, creditors, groupsStatus, creditorsStatus } = yield call(
          API.creditorSelection.init.get,
          params
        );
        if (
          groupsStatus == 200 &&
          creditorsStatus == 200 &&
          Array.isArray(creditors) &&
          Array.isArray(groups)
        ) {
          yield put(
            creditorSelectionActions.init.success({
              creditors,
              groups,
              selectedKeys,
            })
          );
        } else {
          yield put(creditorSelectionActions.init.fail(new Error()));
        }
      } catch (error) {
        yield put(creditorSelectionActions.init.fail(new Error()));
      }
    },
  },
  creditors: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.creditorSelection.creditors.get,
          params
        );
        if (status === 200 && Array.isArray(data)) {
          yield put(
            creditorSelectionActions.creditors.success({
              creditors: data,
              selectedKeys: params?.selectedKeys,
            })
          );
        } else {
          yield put(creditorSelectionActions.creditors.fail(new Error()));
        }
      } catch (error) {
        yield put(creditorSelectionActions.creditors.fail(new Error()));
      }
    },
  },
};
export default [
  takeLatest(
    CreditorSelection.GET_INIT_CREDITORS_START,
    creditorSelectionSagas.init.get
  ),
  takeLatest(
    CreditorSelection.GET_CREDITORS_BY_GROUP_ID_START,
    creditorSelectionSagas.creditors.get
  ),
];
