import { IAPIAction } from "us.collection/interfaces";
import { NonProductionDay } from "us.collection.admin/constants/Actions";
import { INonProductionDaysAction } from "us.collection.admin/interfaces";

export const nonProductionDays_New: Readonly<IAPIAction & INonProductionDaysAction> = {

  calendar: {
    update: (data) => ({
      type: NonProductionDay.SAVE_CALENDAR,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.SAVE_CALENDAR_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.SAVE_CALENDAR_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    manageTabs: (data) => ({
      type: NonProductionDay.MANAGE_TABS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    init: (data) => ({
      type: NonProductionDay.INIT_NON_PRODUCTION,
      payload: {
        data,
        isLoading: true,
      },
    }),
    initSuccess: (data) => ({
      type: NonProductionDay.INIT_NON_PRODUCTION_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),

    initFail: (error) => ({
      type: NonProductionDay.INIT_NON_PRODUCTION_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  redDays: {
    get: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_NON_PRODUCTION_DAYS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    saveOrUpdate: (data, selectedCriteria) => ({
      type: NonProductionDay.SAVE_NON_PRODUCTION_DAYS,
      payload: {
        data,
        selectedCriteria,
        isLoading: true,
      },
    }),
    remove: (data, selectedCriteria) => ({
      type: NonProductionDay.DELETE_NON_PRODUCTION_DAYS,
      payload: {
        data,
        selectedCriteria,
        isLoading: true,
      },
    }),
  },

  ScheduleTimeSlots: {
    get: (data) => ({
      type: NonProductionDay.GET_SCHEDULES_TIME_SLOTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: NonProductionDay.GET_SCHEDULES_TIME_SLOTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: NonProductionDay.GET_SCHEDULES_TIME_SLOTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

};