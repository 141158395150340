import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { $Select, $Input, $Skeleton } from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { useFormikContext } from "formik";
import { getActivityTypeName, getActivityTypes, getEventActivityGroupId } from "./Functions";
import {
  ActivityType,
  FieldName,
  Step,
  EVENT_ACTIVITY_TYPE,
  DEFAULT_ACTIVITY_GROUP,
} from "us.collection.admin/constants";
import _ from "lodash";
import { initialStepDetail } from "us.collection.admin/reducers/ManageActivities/State";

const { activity, properties } = manageActivities;

/**
 * @description Activity Type Detail
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */
const ActivityTypeDetail: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { activityType, entityType } = values as any;
  const {
    activityTypes,
    manageBreadCrumb,
    properties,
    setStepDetail,
    stepDetail,
    activityGroups
  } = props;
  const { extendedField, isFetching } = properties;
  const { data: activityTypeOptions, isFetching: isFetchingActivityTypes } =
    activityTypes;

  /**
   * @function
   * @description handle label click event
   */
  const onClickActivityTypeLabel = () => {
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: true,
        title: getActivityTypeName(activityType),
      });
  };

  const onChangeActivityType = (value: string) => {
    setFieldValue("activityTypeName", "");
    setFieldValue("templateCategoryId", 0);
    if (value === EVENT_ACTIVITY_TYPE) {
      setFieldValue("activityGroup", getEventActivityGroupId(activityGroups?.data));
      setStepDetail &&
        setStepDetail({
          ...stepDetail,
          steps: [Step.ACTIVITY_SETUP],
        });
    } else {
      setFieldValue("activityGroup", DEFAULT_ACTIVITY_GROUP);
      setStepDetail &&
        setStepDetail({
          ...stepDetail,
          steps: initialStepDetail.steps,
        });
    }
  };

  return (
    <>
      <$Skeleton
        active
        loading={isFetchingActivityTypes}
        paragraph={{ rows: 1 }}
      >
        <$Select
          name="activityType"
          formitem={{
            label: t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_TYPE"),
          }}
          size="small"
          options={getActivityTypes(activityTypeOptions, entityType)}
          allOption={false}
          required
          optionText="activityTypeName"
          optionValue="activityTypeName"
          onChange={(value: string) => onChangeActivityType(value)}
          onSearchBy={["activityTypeName"]}
          data-testid="add-activity-activityType"
        />
      </$Skeleton>
      {activityType?.length > 0 && (
        <div className="mt-2">
          <div>
            <a className="d-block" onClick={onClickActivityTypeLabel}>
              <strong>
                {t(
                  `US.COLLECTION.ADMIN:ACTIVITIES.${getActivityTypeName(
                    activityType
                  )}`
                )}
              </strong>
            </a>
            <$Input name="activityTypeName" size="small" disabled />
          </div>
          {activityType?.toUpperCase() == ActivityType.MESSAGE && (
            <$Skeleton loading={isFetching} active paragraph={{ rows: 1 }}>
              <$Select
                name="templateProperty"
                formitem={{
                  label: t("US.COLLECTION.ADMIN:ACTIVITIES.TEMPLATE_PROPERTY"),
                }}
                size="small"
                options={extendedField[FieldName.Template_Property]}
                optionText="name"
                optionValue="name"
                allOption={false}
              />
              <$Select
                name="printMode"
                formitem={{
                  label: t("US.COLLECTION.ADMIN:ACTIVITIES.PRINT_MODE"),
                }}
                size="small"
                options={extendedField[FieldName.Selected_Print_Mode]}
                optionText="name"
                optionValue="name"
                allOption={false}
              />
              <$Select
                name="printerName"
                formitem={{
                  label: t("US.COLLECTION.ADMIN:ACTIVITIES.PRINTER"),
                }}
                size="small"
                options={extendedField[FieldName.Available_Printer_List]}
                optionText="name"
                optionValue="name"
                allOption={false}
              />
              <$Select
                name="updateDocumentDate"
                formitem={{
                  label: t(
                    "US.COLLECTION.ADMIN:ACTIVITIES.UPDATE_DOCUMENT_DATE"
                  ),
                }}
                size="small"
                className="w-100"
                options={extendedField[FieldName.Update_Document_Base_State]}
                optionText="name"
                optionValue="name"
                allOption={false}
              />
            </$Skeleton>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { activity, properties, stepDetail } = manageActivities;
  const { activityTypes, activityGroups } = activity;
  return { activityTypes, properties, stepDetail, activityGroups };
};
const mapDispatchToProps = {
  setStepDetail: activity.setStep,
  manageBreadCrumb: activity.manageBreadCrumb,
  getProperties: properties.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivityTypeDetail);
