import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { IContextMenuItem } from "us.common/components/antd/Interfaces";

const $I = <T extends IContextMenuItem>({
  label,
  labelStyle = "p-1",
  icon,
  iconStyle = "p-1",
}: T) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={iconStyle}>{icon}</div>
      <div className={labelStyle}>
        {t(`US.COLLECTION.COMMON:COMMON.${label}`)}
      </div>
    </>
  );
};

$I.propTypes = {
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  icon: PropTypes.element,
  iconStyle: PropTypes.string,
};

export const $ContextMenuItem = $I;
