/**
 * @function
 * @description To get the search value foramtted
 * @param queryValue The value searched in the box main search
 * @returns The formated search value separated out
 */
export const getSearchQueryValueParsed = (
  queryValue: string
): {
  SearchValue: string;
  SecValue: string | undefined;
} => {
  queryValue = queryValue.trim().replace(/\s+/g, " ");
  let searchV = "";
  let secV;

  if (queryValue) {
    let splittedQuery: string[] = queryValue.split(" ");
    if (splittedQuery.length === 1) {
      // when only one word is there
      searchV = splittedQuery[0];
    } else if (splittedQuery.length === 2) {
      // when exactly 2 words are there
      searchV = splittedQuery[0];
      secV = splittedQuery[1];
    } else if (splittedQuery.length > 2) {
      // when more then 2 words are there
      (searchV = splittedQuery.shift() ?? ""), (secV = splittedQuery.join(" AND "));
    }
  }

  return {
    SearchValue: searchV,
    SecValue: secV,
  };
};
