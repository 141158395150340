import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.common/services";
import { activityExecutionActions } from "us.common/actions";
import { ActivityExecution, URLType } from "us.common/constants"
import { $MessageBox } from "us.common/components";
import { addNewVersionForActivity, entityTypeConversion } from "us.common/functions";

const { activities, parameters, activity } = activityExecutionActions;

const activityExecutionSagas = {
    activities: {
        get: function* (action: any): any {
            const params = action.payload.data ?? {};
            try {
                const data = yield call(API.activityExecutionService.activities.get, params);
                if(params?.tabLevel == URLType.CASE){
                    yield put(activities.success(addNewVersionForActivity(data)));
                }
                else{
                    yield put(activities.success(data));
                }
            } catch (error) {
                yield put(activities.fail(error as any));
            }
        },
    },
    parameters: {
        get: function* (action: any): any {
            const params = action.payload.data ?? {};
            try {
                const data = yield call(API.activityExecutionService.activity.parameters.get, params);
                yield put(parameters.success(data));
            } catch (error) {
                yield put(parameters.fail(error as any));
            }
        },
    },
    activity: {
        execute: function* (action: any): any {
            const params = action.payload.data ?? {};
            const navigateAfterExecution = action?.payload?.other?.navigateAfterExecution;
            const isBulkExecutionEnabled = action?.payload?.other?.isBulkExecutionEnabled ?? {};
            const user = action?.payload?.other.user ?? {};
            const reduxActivities = action?.payload?.other.reduxActivities ?? {};
            const actionRequest = action?.payload?.other.actionRequest ?? {};
            try {
                const data = yield call(API.activityExecutionService.activity.execute, params);
                yield put(activity.success(data));
                if (data.hasOwnProperty('message') && !isBulkExecutionEnabled) {
                    if (data?.message?.toLowerCase()?.includes('scheduled')) {
                        $MessageBox(
                            'success',
                            `US.COMMON:ACTIVITY_EXECUTION.ACTIVITY_SCHEDULED_SUCCESSFULLY`,
                            '',
                            ''
                        );
                    } else {
                        $MessageBox(
                            'success',
                            `US.COMMON:ACTIVITY_EXECUTION.ACTIVITY_SUCCESSFULLY_EXECUTED`,
                            '',
                            ''
                        );
                    }
                    if (Array.isArray(reduxActivities)) {
                        const [reduxActivity1, reduxActivity2] = reduxActivities
                        if (reduxActivity1?.action) {
                            yield put(
                                reduxActivity1.action(
                                    reduxActivity1?.method ? reduxActivity1?.method(actionRequest,
                                        { hide: true }) : actionRequest,
                                    { hide: true }

                                )
                            );
                        }
                        if (reduxActivity2?.action) {
                            yield put(
                                reduxActivity2.action(
                                    {
                                        entityId: params?.request?.caseId,
                                        entityType: entityTypeConversion(params?.request?.entityType),
                                    }
                                )
                            );
                        }
                    }
                }
                if (data.hasOwnProperty("batchId") && isBulkExecutionEnabled) {
                    const { batchId } = data;
                    $MessageBox(
                        "success",
                        `US.COMMON:ACTIVITY_EXECUTION.ACTIVITY_BATCH_IS_SCHEDULED_SUCCESSFULLY`,
                        "",
                        ""
                    );
                    if (Array.isArray(reduxActivities)) {
                        const [reduxActivity1, reduxActivity2] = reduxActivities
                        if (reduxActivity1.action) {
                            yield put(
                                reduxActivity1.action(
                                    reduxActivity1?.method ? reduxActivity1?.method({ ...actionRequest, batchId },
                                        { hide: true }) : { ...actionRequest, batchId },
                                    { hide: true }

                                )
                            );
                        }
                        if (reduxActivity2.action) {
                            yield put(
                                reduxActivity2?.action(
                                    reduxActivity2?.method ? reduxActivity2?.method(actionRequest, user) : ({ actionRequest, user })
                                )
                            );
                        }
                    }


                }
            } catch (error) {
                yield put(activity.fail(error as any));
                $MessageBox(
                    "error",
                    `US.COMMON:ACTIVITY_EXECUTION.ACTIVITY_EXECUTION_FAILED`,
                    "",
                    ""
                );
            } finally {
                if (activity.reset) {
                    yield put(activity.reset({}));
                }
                if (navigateAfterExecution) {
                    yield call(navigateAfterExecution)

                }
            }
        },
    }
};

export default [
    takeLatest(ActivityExecution.GET_ACTIVITIES_START, activityExecutionSagas.activities.get),
    takeLatest(ActivityExecution.GET_ACTIVITY_PARAMETERS_START, activityExecutionSagas.parameters.get),
    takeLatest(ActivityExecution.EXECUTE_ACTIVITY_START, activityExecutionSagas.activity.execute),
];
