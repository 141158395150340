import { IEntityAction } from 'us.common/interfaces/IEntityAction';
import { Entity } from 'us.common/constants/Entity';

export const entity: IEntityAction = {
	get: (data: number) => ({
		type: Entity.GET_ENTITY_DETAIL_START,
		payload: {
			entityRoleId: data,
		},
	}),
	getSuccess: (data: any) => ({
		type: Entity.GET_ENTITY_DETAIL_SUCCESS,
		payload: {
			data,
		},
	}),
	getFail: (error) => ({
		type: Entity.GET_ENTITY_DETAIL_FAIL,
		payload: {
			error,
		},
	}),

	getPostalAreas: (data: any) => ({
		type: Entity.GET_POSTAL_AREAS,
		payload: {
			data,
		},
	}),
	getPostalAreasSuccess: (data: any) => ({
		type: Entity.GET_POSTAL_AREAS_SUCCESS,
		payload: {
			data,
		},
	}),
	getPostalAreasFail: (error) => ({
		type: Entity.GET_POSTAL_AREAS_FAIL,
		payload: {
			error,
		},
	}),

	getMunicipals: (data: any) => ({
		type: Entity.GET_MUNICIPALS,
		payload: {
			data
		},
	}),
	getMunicipalsSuccess: (data: any) => ({
		type: Entity.GET_MUNICIPALS_SUCCESS,
		payload: {
			data,
		},
	}),
	getMunicipalsFail: (error) => ({
		type: Entity.GET_MUNICIPALS_FAIL,
		payload: {
			error,
		},
	}),

	addPostalArea: (data: any) => ({
		type: Entity.ADD_POSTAL_AREAS,
		payload: {
			data,
		},
	}),
	addPostalAreaSuccess: (data: any) => ({
		type: Entity.ADD_POSTAL_AREAS_SUCCESS,
		payload: {
			data,
		},
	}),
	addPostalAreaFail: (error) => ({
		type: Entity.ADD_POSTAL_AREAS_FAIL,
		payload: {
			error,
		},
	}),
	selectedPostalArea: (data: any) => ({
		type: Entity.SELECTED_POSTAL_CODE_DETAIL,
		payload: {
			data,
		},
	}),
	selectedMunicipality: (data: any) => ({
		type: Entity.SELECTED_MUNICIPALITY_DETAIL,
		payload: {
			data,
		},
	}),
	PostalCodeFormData: (data: any) => ({
		type: Entity.POSTAL_CODE_FORM_DATA,
		payload: {
			data,
		},
	}),
	ChangePostalDrawerTitle: (data) => ({
		type: Entity.CHANGE_POSTAL_AREA_DRAWER_NAME,
		payload: {
			data,
		},
	}),
	clearPostalData: (data) => ({
		type: Entity.CLEAR_POSTAL_CODE_DATA,
		payload: {
			data,
		},
	}),

	setSaveStatus: (data) => ({
		type: Entity.SET_SAVE_STATUS,
		payload: {
			data,
		},
	}),
	postalCodeAvailability: {
		get: (data: any) => ({
			type: Entity.GET_POSTAL_CODE_AVAILABILITY_START,
			payload: {
				data,
				isLoading: true
			}
		}),
		success: (data: any) => ({
			type: Entity.GET_POSTAL_CODE_AVAILABILITY_SUCCESS,
			payload: {
				data,
				isLoading: false
			}
		}),
		fail: (error: any) => ({
			type: Entity.GET_POSTAL_CODE_AVAILABILITY_FAIL,
			payload: {
				error,
				isLoading: false
			}
		}),
		reset: (data: any) => ({
			type: Entity.CLEAR_EXISITING_CODE,
			payload: {
				data,
				isLoading: false
			}
		})
	},
	same: {
		update: (data: any) => ({
			type: Entity.UPDATE_ENTITY_DETAIL_SUCCESS,
			payload: {
				data,
				isLoading: false
			}
		}),
	}
};
