import React from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "us.icons";
import { $Popconfirm } from "us.common/components";
import { MunicipalityActions } from "us.collection.admin/constants";
import { IItemMenu } from "us.collection.admin/components/Municipalities/Components/Table";

/**
 * @description - Municipality table action menu.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3340959765/Manage+Municipalities+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 26/07/2023
 */
const ItemMenu: React.FC<IItemMenu> = ({
  actions = [MunicipalityActions.EDIT, MunicipalityActions.DELETE],
  onCallAction,
}) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      {actions.includes(MunicipalityActions.EDIT) && (
        <div
          className="d-flex  flex-row more-item"
          data-testid="edit-btn"
          onClick={() => onCallAction(MunicipalityActions.EDIT)}
        >
          <div className="p-1">
            <EditOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
        </div>
      )}
      {actions.includes(MunicipalityActions.DELETE) && (
        <div hidden>
          <$Popconfirm
            title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
            data-testid="popUpConfirm-box"
            onConfirm={() => onCallAction(MunicipalityActions.DELETE)}
          >
            <div
              className="d-flex mb-2 flex-row more-item text-error"
              data-testid="delete-btn"
            >
              <div className="p-1">
                <DeleteOutlined />
              </div>
              <div className="p-1">
                {t("US.COLLECTION.COMMON:COMMON.DELETE")}
              </div>
            </div>
          </$Popconfirm>
        </div>
      )}
    </div>
  );
};

export default ItemMenu;
