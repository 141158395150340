import { Municipalities } from "us.collection.admin/interfaces";
import { MUNICIPALITY_DRAWER_DEFAULT } from "us.collection.admin/constants";

export const initialState: Municipalities = {
  municipalityList: {
    isLoading: false,
    data: [],
  },
  countries: {
    isLoading: false,
    data: [],
  },
  isFormSaving: false,
  isDeleting: false,
  drawerInfo: MUNICIPALITY_DRAWER_DEFAULT,
};
