import { DrawerType, ManageFeeAction } from "../Constants";
import { IFeeTypeDetails } from "us.collection.admin/interfaces";
import {
  IFeeTypeData,
  IFeeBasisData,
  IFeeRangeData,
  ISaveFeeBasis,
  ISaveFeeRange,
} from "../Interfaces";
import { i18n } from "us.helper";
import _ from "lodash";
import moment from "moment";

/**
 * @description Get column search input field current value by key
 * @param {Array<IFeeTypeData>} typeList - input field name
 * @param {number} typeId - input field name
 * @returns {string} value - Current value of the input field
 */
export const getSelectedFeeTypeNameForDrawerTitle = (
  typeList: Array<IFeeTypeData>,
  typeId: number
): string => {
  try {
    const selectedRecord = typeList?.filter(
      (type: IFeeTypeData) => type.feeTypeId == typeId
    );
    return selectedRecord[0]?.feeTypeName;
  } catch (error) {
    return "";
  }
};

/**
 * @description get drawer title by action
 * @param {FeeBasisAction} action - action
 * @param {DrawerType} drawerType - drawerType
 * @returns title of the drawer
 */
export const getDrawerTitle = (
  action: ManageFeeAction,
  drawerType: DrawerType,
  name?: string
) => {
  if (drawerType == DrawerType.FEE_TYPE) {
    switch (action) {
      case ManageFeeAction.ADD:
        return i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_TYPE");
      default:
        return i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_TYPE");
    }
  } else if (drawerType == DrawerType.FEE_BASIS) {
    switch (action) {
      case ManageFeeAction.ADD:
        return i18n.t(`US.COLLECTION.ADMIN:MANAGE_FEE.ADD`) + " " + name;
      default:
        return i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.EDIT") + " " + name;
    }
  } else if (drawerType == DrawerType.FEE_RANGE) {
    switch (action) {
      case ManageFeeAction.ADD:
        return (
          i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW") +
          " " +
          name +
          " " +
          i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.RANGE")
        );
      default:
        return (
          i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.EDIT") +
          " " +
          name +
          " " +
          i18n.t("US.COLLECTION.ADMIN:MANAGE_FEE.RANGE")
        );
    }
  } else {
    return "";
  }
};

/**
 * @description - Update the browser tab url according to given typeId value
 * @param {number} typeId - type Id if available
 * @param {any} replace - replace method of useLocation
 * @param {number} feeId - fee Id if available
 */
export const updateUrl = (
  typeId: number,
  replace: any,
  feeId?: number,
  rangeId?: number
) => {
  if (rangeId) {
    replace({
      pathname: "/manage-fee-info",
      search: `?typeId=${typeId}&feeId=${feeId}&rangeId=${rangeId}`,
    });
  } else if (feeId) {
    replace({
      pathname: "/manage-fee-info",
      search: `?typeId=${typeId}&feeId=${feeId}`,
    });
  } else {
    replace({
      pathname: "/manage-fee-info",
      search: `?typeId=${typeId}`,
    });
  }
};

/**
 * @description Get Fee details by id
 * @param {Array<IFeeBasisData | IFeeRangeData>} feeList - List of fee details to find by id
 * @param {number} id - The URL params id to find matching fee details from the given list
 * @param {ManageFeeAction} actionType - selected Action type
 * @param {DrawerType} drawerType - selected Action type
 * @returns {IFeeBasisData | IFeeRangeData | {}} If find item will return it otherwise return undefined
 */
export const getFeeDetailsById = (
  feeList: Array<IFeeBasisData | IFeeRangeData>,
  id: number,
  actionType: ManageFeeAction,
  drawerType: DrawerType
): any | {} => {
  try {
    const matchedRecord =
      drawerType == DrawerType.FEE_BASIS
        ? feeList.find((fee: any) => fee.feeId == id)
        : drawerType == DrawerType.FEE_RANGE
        ? feeList.find((fee: any) => fee.id == id)
        : {};
    return actionType === ManageFeeAction.EDIT
      ? matchedRecord
      : drawerType == DrawerType.FEE_BASIS
      ? { fromDate: moment() }
      : {};
  } catch (error) {
    return {};
  }
};

/**
 * @description get Amount By Basis
 * @param {any} values - form values
 * @returns {number} - return calculated amount
 */
export const getAmountByBasis = (values: any): number => {
  try {
    const { feeBasis, noOfFee } = values;
    if (feeBasis != 0 && feeBasis != undefined && noOfFee != undefined) {
      return Number(Number(feeBasis * noOfFee).toFixed(2));
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

/**
 * @description get fee basis Amount By id
 * @param {Array<IFeeBasisData>} feeList - List of fee basis details to find by id
 * @param {number} id - The URL params id to find matching fee details from the given list
 * @param {ManageFeeAction} actionType - selected Action type
 * @returns {number} If find item will return its amount otherwise return 0
 */
export const getFeeBasisAmount = (
  feeList: any,
  id: number,
  actionType: ManageFeeAction
): number => {
  try {
    const matchedRecord = feeList.find((fee: any) => fee.feeId == id);
    return matchedRecord?.amount;
  } catch (error) {
    return 0;
  }
};

/**
 * @description get item menu delete popup message
 * @param {boolean} isPastDate - deleted item date is past or not
 * @returns {string} message - return popup message according to date
 */
export const getItemMenuDeleteTitle = (isPastDate: boolean): string => {
  try {
    if (isPastDate) {
      return i18n.t(
        "US.COLLECTION.ADMIN:MANAGE_FEE.DELETING_THE_FEE_BASIS_WILL_REMOVE_THE_RECORD_PERMANENTLY_DO_YOU_WANT_TO_DELETE_THE_FEE_BASIS_?"
      );
    } else {
      return i18n.t(
        "US.COLLECTION.ADMIN:MANAGE_FEE.DO_YOU_WANT_TO_DELETE_THE_FEE_BASIS_?"
      );
    }
  } catch (error) {
    return "";
  }
};

/**
 * @description check fee type is duplicate or not
 * @param {any} feeTypeList - fee type list from menu
 * @param {string} feeType - newly added fee type
 * @returns {boolean} return true if type is already in the menu otherwise return false
 */
export const checkFeeTypeDuplicate = (
  feeTypeList: Array<IFeeTypeData>,
  feeType: string
): boolean => {
  try {
    if (feeType && feeType != "") {
      return (
        feeTypeList?.find(
          ({ feeTypeName }: IFeeTypeData) => feeTypeName == feeType
        ) == undefined
      );
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
};

/**
 * @description check basis record is duplicate or not
 * @param {Array<IFeeBasisData>} basisList - fee basis list from table
 * @param {ISaveFeeBasis} basisPayload - newly added fee basis payload
 * @returns {boolean} return true if fee basis is already in the table otherwise return false
 */
export const checkNewBasisRecordIsDuplicate = (
  basisList: Array<IFeeBasisData>,
  basisPayload: ISaveFeeBasis
): boolean => {
  try {
    return (
      basisList
        ?.filter((bl: any) => bl.feeId != basisPayload?.feeId)
        .find((item: IFeeBasisData) =>
          _.isEqual(
            _.cloneDeep({
              ...item,
              feeId: 0,
              amount: 0,
              modifiedUser: "",
              modifiedDate: "",
            }),
            _.cloneDeep({
              ...basisPayload,
              feeId: 0,
              amount: 0,
              modifiedUser: "",
              modifiedDate: "",
            })
          )
        ) != undefined
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description check range record is duplicate or not
 * @param {Array<IFeeRangeData>} rangeList - fee range list from table
 * @param {any} rangePayload - newly added fee range payload
 * @returns {boolean} return true if fee range is already in the table otherwise return false
 */
export const checkNewRangeRecordIsDuplicate = (
  rangeList: Array<IFeeRangeData>,
  rangePayload: ISaveFeeRange
): boolean => {
  try {
    return (
      rangeList?.find((item: IFeeRangeData) =>
        _.isEqual(
          _.cloneDeep({ ...item, feeId: 0 }),
          _.cloneDeep({ ...rangePayload, feeId: 0 })
        )
      ) != undefined
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description delete fee range from table
 * @param {any} record - deleted fee range record
 * @param {any} rangeList - fee range list from table
 * @returns {any} return fee range list after deleting record
 */
export const deleteFeeRangeFromTable = (record: any, rangeList: any): any => {
  try {
    const deletedIndex = rangeList?.findIndex(
      (range: any) => range.id == record?.id
    );
    const deleteActionPayload = [record?.id];
    const addActionPayload = [];

    if (rangeList.length > 1) {
      if (deletedIndex == 0) {
        const newRecord = {
          ...rangeList[1],
          fromAmount: rangeList[0].fromAmount,
        };
        rangeList.splice(0, 2, newRecord);
        addActionPayload.push(newRecord);
      } else if (deletedIndex == rangeList.length - 1) {
        const newRecord = {
          ...rangeList[deletedIndex - 1],
          toAmount: rangeList[deletedIndex].toAmount,
        };
        rangeList.splice(rangeList.length - 2, 2, newRecord);
        addActionPayload.push(newRecord);
      } else if (deletedIndex > 0) {
        deleteActionPayload.push(rangeList[deletedIndex + 1].id);
        const newRecord = {
          ...rangeList[deletedIndex - 1],
          toAmount: rangeList[deletedIndex + 1].toAmount,
        };
        rangeList.splice(deletedIndex - 1, 3, newRecord);
        addActionPayload.push(newRecord);
      }
    }
    return { deleteActionPayload, addActionPayload, feeId: record?.feeId };
  } catch (error) {
    return {};
  }
};

/**
 * @description add fee range for table
 * @param {any} record - added fee range record
 * @param {any} rangeList - fee range list from table
 * @returns {ay} return fee range list after adding record
 */
export const addFeeRangeToTable = (record: any, rangeList: any): any => {
  const { fromAmount, toAmount } = record;

  const deleteActionPayload: any = [];
  const addActionPayload = [];

  let startIndex = -1;
  let endIndex = -1;
  try {
    rangeList?.map((range: any, index: number) => {
      if (range.fromAmount <= fromAmount && range.toAmount >= fromAmount) {
        startIndex = index;
      }
      if (range.fromAmount <= toAmount && range.toAmount >= toAmount) {
        endIndex = index;
      }
      if (range.fromAmount > fromAmount && range.toAmount < toAmount) {
        deleteActionPayload.push(range.id);
      }
    });

    if (startIndex == -1 && endIndex == -1) {
      const newRecord = {
        ...record,
        id: -1,
      };
      addActionPayload.push(newRecord);
    } else if (startIndex == -1) {
      const newRecord = {
        ...record,
        id: -1,
      };
      if (toAmount != rangeList[endIndex]?.toAmount) {
        const editRecord = {
          ...rangeList[endIndex],
          fromAmount: record.toAmount + 0.01,
        };
        addActionPayload.push(editRecord);
      }
      addActionPayload.push(newRecord);
    } else if (endIndex == -1) {
      if (rangeList[startIndex].fromAmount == fromAmount) {
        const editRecord = {
          ...rangeList[startIndex],
          toAmount: record.toAmount,
          noOfFee: record.noOfFee,
        };
        addActionPayload.push(editRecord);
      } else if (rangeList[startIndex].toAmount == fromAmount) {
        const newRecord = {
          ...record,
          feeId: Number(record.feeId),
          id: -1,
          fromAmount:
            record.fromAmount == 0
              ? record.fromAmount
              : record.fromAmount + 0.01,
        };
        addActionPayload.push(newRecord);
      } else {
        const editRecord = {
          ...rangeList[startIndex],
          toAmount: record.fromAmount,
        };
        const newRecord = {
          ...record,
          feeId: Number(record.feeId),
          id: -1,
          fromAmount:
            record.fromAmount == 0
              ? record.fromAmount
              : record.fromAmount + 0.01,
        };
        addActionPayload.push(editRecord);
        addActionPayload.push(newRecord);
      }
    } else if (startIndex == endIndex) {
      let newRecord: any = {};
      let editRecord1: any = {};
      let editRecord2: any = {};

      if (
        rangeList[startIndex].fromAmount == record.fromAmount &&
        rangeList[endIndex].toAmount == record.toAmount
      ) {
        return -1;
      } else {
        if (rangeList[startIndex].fromAmount == record.fromAmount) {
          editRecord1 = {
            ...record,
            id: rangeList[startIndex]?.id,
          };
          newRecord = {
            ...rangeList[startIndex],
            id: -1,
            fromAmount: record.toAmount + 0.01,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(newRecord);
        } else if (rangeList[endIndex].toAmount == record.toAmount) {
          editRecord1 = {
            ...rangeList[startIndex],
            toAmount: record.fromAmount,
          };
          newRecord = {
            ...record,
            id: -1,
            fromAmount: record.fromAmount + 0.01,
            toAmount: rangeList[endIndex].toAmount,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(newRecord);
        } else {
          editRecord1 = {
            ...rangeList[startIndex],
            toAmount: record.fromAmount,
          };
          newRecord = {
            ...record,
            id: -1,
            fromAmount: record.fromAmount + 0.01,
          };
          editRecord2 = {
            ...rangeList[endIndex],
            id: -1,
            fromAmount: record.toAmount + 0.01,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(newRecord);
          addActionPayload.push(editRecord2);
        }
      }
    } else {
      if (record.fromAmount == rangeList[startIndex].fromAmount) {
        if (record.toAmount == rangeList[endIndex].toAmount) {
          deleteActionPayload.push(rangeList[endIndex].id);
          const editRecord1 = {
            ...record,
            toAmount: record.toAmount,
            id: rangeList[startIndex].id,
          };
          addActionPayload.push(editRecord1);
        } else {
          const editRecord1 = {
            ...record,
            toAmount: record.toAmount,
            id: rangeList[startIndex].id,
          };
          const editRecord2 = {
            ...rangeList[endIndex],
            fromAmount: record.toAmount + 0.01,
            id: rangeList[endIndex].id,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(editRecord2);
        }
      } else if (record.fromAmount == rangeList[startIndex].toAmount) {
        if (record.toAmount == rangeList[endIndex].toAmount) {
          if (record.fromAmount + 0.01 == rangeList[endIndex].fromAmount) {
            return -1;
          } else {
            const editRecord1 = {
              ...record,
              id: rangeList[endIndex].id,
              fromAmount: record.fromAmount + 0.01,
            };
            addActionPayload.push(editRecord1);
          }
        } else {
          const newRecord = {
            ...record,
            id: -1,
            fromAmount: record.fromAmount + 0.01,
          };
          const editRecord1 = {
            ...rangeList[endIndex],
            fromAmount: record.toAmount + 0.01,
          };
          addActionPayload.push(newRecord);
          addActionPayload.push(editRecord1);
        }
      } else if (
        rangeList[startIndex].fromAmount < record.fromAmount &&
        rangeList[startIndex].toAmount > record.fromAmount
      ) {
        if (record.toAmount == rangeList[endIndex].toAmount) {
          const editRecord1 = {
            ...rangeList[startIndex],
            toAmount: record.fromAmount,
          };
          const editRecord2 = {
            ...record,
            id: rangeList[endIndex].id,
            fromAmount: record.fromAmount + 0.01,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(editRecord2);
        } else {
          const editRecord1 = {
            ...rangeList[startIndex],
            toAmount: record.fromAmount,
          };
          const newRecord = {
            ...record,
            id: -1,
            fromAmount: record.fromAmount + 0.01,
          };
          const editRecord2 = {
            ...rangeList[endIndex],
            fromAmount: record.toAmount + 0.01,
          };
          addActionPayload.push(editRecord1);
          addActionPayload.push(newRecord);
          addActionPayload.push(editRecord2);
        }
      }
    }
    return {
      addActionPayload,
      deleteActionPayload,
      feeId: record?.feeId,
    };
  } catch (error) {
    return {};
  }
};

/**
 * @description check fee Basis form in dirty or not
 * @param {ManageFeeAction} actionType - action type
 * @param {any} values - form values
 * @param {any} feeData - intial fee basis data
 * @returns {boolean} return true if fee basis from is dirty otherwise return false
 */
export const checkBasisIsDirty = (
  actionType: ManageFeeAction,
  values: any,
  feeData: any
): boolean => {
  try {
    return actionType === ManageFeeAction.ADD
      ? !(Object.keys(values).length === 1 && values?.fromDate)
      : !_.isEqual(_.cloneDeep(feeData), _.cloneDeep(values));
  } catch (error) {
    return false;
  }
};

/**
 * @description check fee Range form in dirty or not
 * @param {ManageFeeAction} actionType - action type
 * @param {any} values - form values
 * @param {any} feeData - intial fee range data
 * @returns {boolean} return true if fee range from is dirty otherwise return false
 */
export const checkRangeIsDirty = (
  actionType: any,
  values: any,
  feeData: any
): boolean => {
  try {
    return actionType === ManageFeeAction.ADD
      ? !_.isEqual(
          _.cloneDeep({ ...values, feeBasis: 0, rangeLength: 0 }),
          _.cloneDeep({ feeBasis: 0, rangeLength: 0 })
        )
      : !_.isEqual(
          _.cloneDeep({ ...feeData, feeBasis: 0, rangeLength: 0 }),
          _.cloneDeep({ ...values, feeBasis: 0, rangeLength: 0 })
        );
  } catch (error) {
    return false;
  }
};


/**
 * @description check fee Basis form in dirty or not
 * @param {ManageFeeAction} feeTypes - Array of fee types
 * @param {any} searchText - form values
 * @returns {Array<IFeeTypeDetails>} return filtered fee types
 */
 export const searchFeeTypes = (
  feeTypes: Array<IFeeTypeDetails>,
  searchText: any,
): Array<IFeeTypeDetails> => {
  try {
    return feeTypes.filter((feeType: IFeeTypeDetails) => {
      return feeType.feeTypeName
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toString()?.toLowerCase());
    });
  } catch (error) {
    return [];
  }
};