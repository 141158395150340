import React, { useState, useEffect } from 'react';
import { entity } from 'us.common/actions';
import { connect } from 'react-redux';
import { Formik, useField } from 'formik';
import { $Button, $Input, $Table, $Search, $Skeleton } from '../antd';
import { PostalAreaDetails } from './Types';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from 'us.icons';

interface ISortOrder {
  columnKey: string;
  order: any;
}

interface IPostalAreaFormat {
  id: any;
  postalPlace: any;
  postalCode: any;
  countyCode: any;
  countyName: any;
  key: any;
}

interface ISelectPostalArea {
  drawerContentChangeClick: (data: PostalAreaDetails) => void;
  currentStep?: any;
  currentInfo?: any;
  cancelClick: (currentValues: any) => void;
}

interface IBase extends ISelectPostalArea {
  postalAreas: any[];
  getPostalAreas?: any;
  isLoading: boolean;
}

const SelectPostalArea: React.FC<IBase> = (props) => {
  const { t } = useTranslation();
  const { postalAreas, getPostalAreas, isLoading } = props;
  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: '',
    order: '',
  });
  const [selectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  // const [firstTime, setFirstTime] = useState<boolean>(true);

  // const [data, setData] = useState<IPostalAreaFormat[]>([]);
  // const [dataBkp, setDataBkp] = useState<IPostalAreaFormat[]>([]);

  const postalFilters = {
    countryCode: 'NO',
    postalCode: 'all',
    searchType: 'all',
  };

  const columns = [
    {
      title: t('US.COMMON:COMMON.POSTAL_PLACE'),
      dataIndex: 'postalPlace',
      key: 'postalPlace',
      sorter: (a: any, b: any) => a.postalPlace.length - b.postalPlace.length,
      sortOrder: sortedInfo.columnKey === 'postalPlace' && sortedInfo.order,
    },
    {
      title: t('US.COMMON:COMMON.POSTAL_CODE'),
      dataIndex: 'postalCode',
      key: 'postalCode',
      sorter: (a: any, b: any) => a.postalCode.length - b.postalCode.length,
      sortOrder: sortedInfo.columnKey === 'postalCode' && sortedInfo.order,
    },
    {
      title: t('US.COMMON:COMMON.MUNICIPALITY_CODE'),
      dataIndex: 'countyCode',
      key: 'countyCode',
      sorter: (a: any, b: any) => a.countyCode.length - b.countyCode.length,
      sortOrder: sortedInfo.columnKey === 'countyCode' && sortedInfo.order,
    },
    {
      title: t('US.COMMON:COMMON.MUNICIPALITY_NAME'),
      dataIndex: 'countyName',
      key: 'countyName',
      sorter: (a: any, b: any) => a.countyName.length - b.countyName.length,
      sortOrder: sortedInfo.columnKey === 'countyName' && sortedInfo.order,
    },
  ];

  // useEffect(() => {
  //   if (firstTime) {
  //     getPostalAreas();
  //     setFirstTime(false);
  //   }

  //   if (postalAreas.length > 0) {
  //     // if (data?.length === 0 && dataBkp?.length === 0) {
  //     let tempData = [];
  //     tempData = postalAreas?.map((item) => {
  //       return {
  //         id: item.id,
  //         PostalPlace: item?.postalPlace,
  //         PostalCode: item?.postalCode,
  //         MunicipalityCode: item?.countyCode,
  //         MunicipalityName: item?.countyName,
  //         key: item.id,
  //       };
  //     });
  //     if (tempData?.length > 0) {
  //       setData([...tempData]);
  //       setDataBkp([...tempData]);
  //     }
  //     // }
  //   }
  // }, [firstTime, getPostalAreas, postalAreas]);

  useEffect(() => {
    getPostalAreas(postalFilters);
  }, []);

  const handleChange = (
    pagination: any,
    filters: Partial<
      Record<
        | 'postalPlace'
        | 'PostalCode'
        | 'countyCode'
        | 'countyName'
        | 'id'
        | 'key',
        string[]
      >
    >,
    sorter: any
  ) => {
    setSortedInfo(sorter);
  };

  // const filterCodesOnSearch = (
  //   value: string,
  //   event?:
  //     | React.ChangeEvent<HTMLInputElement>
  //     | React.MouseEvent<HTMLElement, MouseEvent>
  //     | React.KeyboardEvent<HTMLInputElement>
  //     | undefined
  // ) => {};

  // const filterCodes = (inputValue: React.ChangeEvent<HTMLInputElement>) => {
  //   setData(
  //     dataBkp.filter((item: any) => {
  //       let doesExist = false;
  //       for (let prop in item) {
  //         doesExist = item[prop]
  //           .toString()
  //           .toLowerCase()
  //           .includes(inputValue.target.value?.toString()?.toLowerCase());
  //         if (doesExist) {
  //           return doesExist;
  //         }
  //       }
  //       return doesExist;
  //     })
  //   );
  // };

  const rowDoubleClickHandler = (dataRow: IPostalAreaFormat, rowIndex: any) => {
    dataRow = dataRow || selectedRowKeys;
    let newInfo = {
      ...props.currentInfo,
      PostalCode: dataRow.postalCode,
      City: dataRow.postalPlace,
      MunicipalityCode: dataRow.countyCode,
      MunicipalityName: dataRow.countyName,
    };
    delete newInfo.id;
    props.drawerContentChangeClick({
      currentStep: props.currentStep,
      updatedInfo: newInfo,
    });
  };

  return (
    <Formik
      initialValues={{
        postalCodes: '',
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={() => {}}
    >
      {({ values, ...restProps }: any) => (
        <>
          {/* <h3 className="mb-3">Select Postal Area</h3> */}
          <div className="d-flex mb-4">
            <$Search
              size="small"
              name="postalCodes"
              placeholder={t('US.COMMON:COMMON.SEARCHBY')}
              onSearch={(value: any) =>
                getPostalAreas({
                  ...postalFilters,
                  postalCode: value == '' ? 'all' : value,
                })
              }
              allowClear={true}
            />
          </div>
          <$Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
            <$Table
              size="small"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={postalAreas}
              onChange={handleChange}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => rowDoubleClickHandler(record, rowIndex),
                };
              }}
            />
          </$Skeleton>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  return {
    postalAreas: state.entityDetails.postalAreas,
    isLoading: state.entityDetails.isFetching,
  };
};

const mapDispatchToProps = {
  getPostalAreas: entity.getPostalAreas,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPostalArea);
