import { AddDebtor } from "us.common/constants";
import {
  combineAddress,
  combineTwoFields,
  filterOtherPartyRoleType,
  setContactNOs,
} from "us.common/functions";
import { initialState } from "../DebtorSelection/State";
const {
  SEARCH_POSTAL_CODE_START,
  SEARCH_POSTAL_CODE_SUCCESS,
  SEARCH_POSTAL_CODE_FAIL,
  SEARCH_MUNICIPALITY_CODE_START,
  SEARCH_MUNICIPALITY_CODE_SUCCESS,
  SEARCH_MUNICIPALITY_CODE_FAIL,
  GET_OTHER_PARTY_ROLE_TYPE_STRAT,
  GET_OTHER_PARTY_ROLE_TYPE_SUCCESS,
  GET_OTHER_PARTY_ROLE_TYPE_FAIL,
  DELETE_OTHER_PARTY_ROLE_TYPE_STRAT,
  DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS,
  DELETE_OTHER_PARTY_ROLE_TYPE_FAIL,
  SAVE_OTHER_PARTY_ROLE_TYPE_STRAT,
  SEARCH_ENTITY_STRAT,
  SEARCH_ENTITY_SUCCESS,
  SEARCH_ENTITY_FAIL,
  RESET_ENTITY_DATA,
} = AddDebtor;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SEARCH_POSTAL_CODE_START:
      return Object.assign({}, state, {
        addDebtorInitial: payload.data.values,
        postalcode: {
          isLoading: true,
        },
      });
    case SEARCH_POSTAL_CODE_SUCCESS:
      const { postalPlace, countyCode, countyName } = payload?.data[0];

      const zipName = postalPlace;
      const municipalityName = countyName;
      const municipalityCode = countyCode;
      const isExistingPostalCode = true;
      const isExistingMunicipalityCode = true;

      return Object.assign({}, state, {
        addDebtorInitial: {
          ...state.addDebtorInitial,
          zipName,
          municipalityCode,
          municipalityName,
          isExistingPostalCode,
          isExistingMunicipalityCode,
        },
        postalcode: {
          isLoading: false,
        },
      });
    case SEARCH_POSTAL_CODE_FAIL:
      return Object.assign({}, state, {
        addDebtorInitial: {
          ...state.addDebtorInitial,
          zipName: "",
          municipalityCode: "",
          municipalityName: "",
          isExistingPostalCode: false,
          isExistingMunicipalityCode: false,
        },
        postalcode: {
          isLoading: false,
        },
      });
    case SEARCH_MUNICIPALITY_CODE_START:
      return Object.assign({}, state, {
        addDebtorInitial: payload.data.values,
        municipility: {
          isLoading: true,
        },
      });
    case SEARCH_MUNICIPALITY_CODE_SUCCESS:
      const name = payload?.data[0].municipalityName ?? "";
      const isExistingMunicipalityCode_ = true;

      return Object.assign({}, state, {
        addDebtorInitial: {
          ...state.addDebtorInitial,
          municipalityName: name,
          isExistingMunicipalityCode: isExistingMunicipalityCode_,
        },
        municipility: {
          isLoading: false,
        },
      });
    case SEARCH_MUNICIPALITY_CODE_FAIL:
      return Object.assign({}, state, {
        addDebtorInitial: {
          ...state.addDebtorInitial,
          municipalityName: "",
          isExistingMunicipalityCode: false,
        },
        municipility: {
          isLoading: false,
        },
      });
    case GET_OTHER_PARTY_ROLE_TYPE_STRAT:
      return Object.assign({}, state, {
        otherPartyRoles: {
          ...state.otherPartyRoles,
          currentTab: payload?.data?.currentTab,
          isLoading: true,
        },
      });
    case GET_OTHER_PARTY_ROLE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        otherPartyRoles: {
          data: filterOtherPartyRoleType(
            state.otherPartyRoles.currentTab,
            payload?.data
          ).map((item: any) => ({ label: item.roleName, value: item.roleId })),
          isLoading: false,
        },
      });
    case GET_OTHER_PARTY_ROLE_TYPE_FAIL:
      return Object.assign({}, state, {
        otherPartyRoles: { ...state.otherPartyRoles, isLoading: false },
      });
    case DELETE_OTHER_PARTY_ROLE_TYPE_STRAT:
      return Object.assign({}, state, {});
    case DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS:
      return Object.assign({}, state, {});
    case DELETE_OTHER_PARTY_ROLE_TYPE_FAIL:
      return Object.assign({}, state, {});
    case SAVE_OTHER_PARTY_ROLE_TYPE_STRAT:
      return Object.assign({}, state, {});
    case SEARCH_ENTITY_STRAT:
      return Object.assign({}, state, {
        searchEntity: {
          data: [],
          isLoading: true,
        },
      });
    case SEARCH_ENTITY_SUCCESS:
      return Object.assign({}, state, {
        searchEntity: {
          data: payload?.data.map((item: any, index: number) => {
            return {
              key: index,
              ...item,
              name: combineTwoFields(item?.firstName, item?.lastName),
              address: combineAddress(item),
              contacts: setContactNOs(item.phoneNumbers),
            };
          }),
          isLoading: false,
        },
      });
    case SEARCH_ENTITY_FAIL:
      return Object.assign({}, state, {
        searchEntity: {
          data: [],
          isLoading: false,
        },
      });
    case RESET_ENTITY_DATA:
      return Object.assign({}, state, {
        searchEntity: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
