import { ISearchHistoryResult } from "us.common/reducers/MainSearch/Interfaces";
import { ILabeledValue } from "us.common/interfaces/MainSearch";
import {
  CaseSubCriterias,
  CreditorSubCriterias,
  DebtorSubCriterias,
  InvoiceSubCriterias,
  MainCriterias,
  COLEN,
} from "us.common/constants";
import {
  getCaseSubCriterias,
  getCreditorSubCriterias,
  getDebtorSubCriterias,
  getInvoiceSubCategories,
  getMainSearchCriterias,
  getInitialDropDownOptionValues,
} from "us.common/functions/MainSearch";
import { ICombinedEnums, ISearchBar } from "../Interfaces";

/**
 * @function
 * @description Sort history dropdown by date
 * @param firstHisObj first history object
 * @param secondHisObj second history object
 * @returns the difference of the time in number type
 */
export const sortDescendFromDate = (
  firstHisObj: ISearchHistoryResult,
  secondHisObj: ISearchHistoryResult
): number => {
  try {
    return (
      new Date(secondHisObj.timestamp).getTime() -
      new Date(firstHisObj.timestamp).getTime()
    );
  } catch (e) {
    return 0;
  }
};

/**
 * @function
 * @description Called to get the list for Autocomplete dropdown subcategories
 * @param mainCategoryValue the main critria selected
 * @returns the matching list of sub criterias dropdown
 */
export const getMatchingSubCriterias = (
  mainCategoryValue: MainCriterias
):
  | ILabeledValue<InvoiceSubCriterias>[]
  | ILabeledValue<CaseSubCriterias>[]
  | ILabeledValue<CreditorSubCriterias>[]
  | ILabeledValue<DebtorSubCriterias>[] => {
  switch (mainCategoryValue) {
    case MainCriterias.INVOICE:
      return getInvoiceSubCategories();
    case MainCriterias.CASE:
      return getCaseSubCriterias();
    case MainCriterias.CREDITOR:
      return getCreditorSubCriterias();
    case MainCriterias.DEBTOR:
      return getDebtorSubCriterias();
    default:
      return [];
  }
};

/**
 * @function
 * @description It takes a main criteria and returns the matching sub criteria
 * @param {MainCriterias} mainCriteria - MainCriterias
 * @returns Matching enum.
 */
export const getMatchingSubEnum = (
  mainCriteria: MainCriterias
): ICombinedEnums => {
  switch (mainCriteria) {
    case MainCriterias.INVOICE:
      return InvoiceSubCriterias;
    case MainCriterias.CASE:
      return CaseSubCriterias;
    case MainCriterias.CREDITOR:
      return CreditorSubCriterias;
    case MainCriterias.DEBTOR:
      return DebtorSubCriterias;
    default:
      return CaseSubCriterias;
  }
};

/**
 * @function
 * @description Get the regular expression prepared for validating input
 * @param mainCriteria type of MainCriteriaEnum
 * @param subCriteria one of the SubTypeEnums
 * @returns regularExpression object
 */
export const getSearchRegExps = (
  mainCriteria: string,
  subCriteria: string
): { [key: string]: RegExp } => {
  return {
    allSpaces: new RegExp("\\s+", "g"),
    inputWithMainCriteria: new RegExp(`^\\s*${mainCriteria + COLEN}`),
    inputWithSubCriteria: new RegExp(
      `^\\s*${mainCriteria + COLEN}\\s*${subCriteria + COLEN}`
    ),
  };
};

/**
 * @function
 * @description creating the initial autocomplete drop-down
 * @returns search bar dropdown
 */
export const getFormikSearchBarValues = (
  searchHistory: ISearchHistoryResult[],
  caseNo: string | number,
  GetHistoryUI: any,
  historyBar: any
): ISearchBar => {
  try {
    // for initial formik values
    if (searchHistory && searchHistory.length > 0) {
      let historyOptions = [];
      if (caseNo) {
        historyOptions = [
          ...searchHistory
            .filter((x: any) => x.caseId !== caseNo)
            .map((item: ISearchHistoryResult) => {
              return GetHistoryUI(item.caseNo, item.debtorName);
            })
            .slice(0, 5),
        ];
      } else {
        historyOptions = [
          ...searchHistory
            .sort((a: any, b: any) => sortDescendFromDate(a, b))
            .map((item: ISearchHistoryResult) => {
              return GetHistoryUI(item.caseNo, item.debtorName);
            })
            .slice(0, 5),
        ];
      }

      return {
        options: getInitialDropDownOptionValues(
          undefined,
          getMainSearchCriterias(),
          ""
        ).concat({
          label: historyBar,
          key: "hisotoryBar",
          value: "hisotoryBar",
          options: historyOptions,
        }),
        value: "",
      };
    } else {
      return {
        options: getInitialDropDownOptionValues(
          undefined,
          getMainSearchCriterias(),
          ""
        ),
        value: "",
      };
    }
  } catch (e) {
    return { options: [], value: "" };
  }
};
