import {  versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';

export const getTransactionType = <
  P extends {
    filterType: string;
    itemType: string;
  }
>(
  params: P
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "transactionService",
        "transactiontypes",
        {
          filterType: params.filterType,
          itemType: params.itemType,
        },
        ServiceConfig()[`transactionservice`]['transactiontypes']
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};