import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { $Select, $AutoComplete } from "us.common/components";
import { useFormikContext } from "formik";
import {
  FilterOptions,
  ExtraColumnsOptions,
} from "us.common/components/SelectCreditors/Constants/Constants";
import { ISearchFilters } from "us.common/components/SelectCreditors/Interfaces/Interface";
import { SearchOutlined } from "us.icons";
import { Input } from "antd";
import { EXTRA_COLUMNS } from "./Constants";

/**
 * @description - Search and filters component for creditor selection.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2776727606/Select+Creditors+-+Common+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const SearchFilters: React.FC<ISearchFilters> = ({
  withDrawer,
  multiple,
  searchData,
  selectedCreditors,
  isGroupsLoading,
  isCreditorsLoading,
  onFilter,
  handleAutoCompleteChange,
  handleOnSearchTextChange,
  handleOnPressSearch,
  restrictedExtraColumns
}) => {
  const { t } = useTranslation();
  const { values }: any = useFormikContext();

  const extraColumns = useMemo(
    () =>
    restrictedExtraColumns ?
      EXTRA_COLUMNS.filter(
        ({value}) => !Object.values(restrictedExtraColumns).includes(value)
      ).map(({label,value})=>({label:t(label),value}))
      : EXTRA_COLUMNS.map(({label,value})=>({label:t(label),value})),
    [restrictedExtraColumns]
  );

  return (
    <>
      <div className="font-weight-bold mt-3">
        {t("US.COLLECTION.COMMON:COMMON.CREDITORS")}
        {!withDrawer && multiple && selectedCreditors?.length > 0 && (
          <span className="text-muted ml-2" data-testid="selected-creditors">
            ({selectedCreditors?.length} {t("US.COLLECTION.COMMON:COMMON.SELECTED")})
          </span>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div>
          <$AutoComplete
            name="search"
            backfill
            data-testid="filterCreditorGroup"
            disabled={isGroupsLoading || isCreditorsLoading}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 300 }}
            listHeight="500px"
            style={{ width: "400px" }}
            size="small"
            options={values.searchOptions}
            onChange={(searchValue: string) => {
              handleAutoCompleteChange(searchValue);
              handleOnSearchTextChange(searchValue);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleOnPressSearch(searchData.searchText);
              }
            }}
            value={searchData.searchText}
            addonAfter={<SearchOutlined />}
          >
            <Input.Search
              placeholder={t("US.COLLECTION.COMMON:COMMON.FILTER_BY_NUMBER_AND_NAME")}
              onSearch={(inputValue: string) => handleOnPressSearch(inputValue)}
              size="small"
              disabled={isGroupsLoading || isCreditorsLoading}
              loading={isGroupsLoading || isCreditorsLoading}
              allowClear={true}
            />
          </$AutoComplete>
        </div>

        <div className="d-flex align-items-center">
          <$Select
            name="selectionFilter"
            size="small"
            options={[
              {
                label: t("COMMON.ALL"),
                value: FilterOptions.ALL,
              },
              {
                label: t("COMMON.SELECTED"),
                value: FilterOptions.SELECTED,
              },
              {
                label: t("COMMON.NOT_SELECTED"),
                value: FilterOptions.NOT_SELECTED,
              },
            ]}
            onChange={(value: any) => {
              onFilter(value);
            }}
            value={values?.selectionFilter}
            allOption={false}
            style={{ width: 150 }}
            disabled={isGroupsLoading || isCreditorsLoading}
          />
          <$Select
            name="extraColumns"
            size="small"
            options={extraColumns}
            mode="multiple"
            placeholder={t("US.COLLECTION.COMMON:COMMON.SELECT_COLUMNS")}
            allOption={false}
            style={{ width: 300 }}
            className="ml-2"
            disabled={isGroupsLoading || isCreditorsLoading}
          />
        </div>
      </div>
    </>
  );
};
