import { IOperation } from './../../components/Menu/MegaMenu/interface';
/**
 * @description Get modified configuration details
 * if current app module equal to any feature module, then will set self = true
 * otherwise self = false
 * @param configurationDetail - feature list from the authorization profile
 * @returns updated configuration with self
 */
export const withSelfCheck = (configurationDetail: Array<any>): Array<any> => {
	try {
		return configurationDetail.map((module: any) => {
			const {
				name,
				features,
			}: { name: string; features: Array<any> } = module;

			features.map((feature) => {
				const {
					operations,
				}: { operations: Array<IOperation> } = feature;
				operations.map((route) => {
					if (name === window.APP_MODULE) {
						route['self'] = true;
					} else {
						route['self'] = false;
					}
					route['module'] = module.name;
				});
				return feature;
			});
			return module;
		});
	} catch (error) {
		return configurationDetail;
	}
};
