import {
  organizationNoValidate,
  personNoValidation,
} from "us.common/functions";
import * as Yup from "yup";
import { validatePersonNo } from "../Functions";

export default () => {
  return Yup.object().shape({
    otherpartyType: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    lastName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    country: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    emailAddress: Yup.string()
      .email(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
      )
      .nullable(),
    isAddOtherParty: Yup.boolean(),
    otherpartyRoleType: Yup.string().when("isAddOtherParty", {
      is: true,
      then: Yup.string().required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
      ),
    }),
    zipCode: Yup.string()
      .typeError(
        'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
      ),
    zipName: Yup.string()
      .nullable()
      .typeError(
        'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
      )
      .when('zipCode', {
        is: (zipCode) => {
          zipCode = typeof zipCode == 'undefined' ? '' : zipCode;
          return (zipCode?.toString().length !== 0);
        },
        then: Yup.string()
          .required(
            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
          )
          .test(
            'zipNameDigit',
            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
            (val: any) => {
              let value =
                typeof val == 'undefined' ? '' : val;
              return !/^\d+$/.test(value);
            }
          ),
      }),
    nin: Yup.string().when("otherpartyType", {
      is: (val) => val === "N",
      then: Yup.string()
        .typeError(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
        )
        .test(
          "organizationNo",
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
          (val: any) => {
            val = typeof val == "undefined" ? "" : val.toString();
            return organizationNoValidate(val);
          }
        ),
      otherwise: Yup.string()
        .typeError(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
        )
        .test(
          "personNo",
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
          (value: any) => {
            let isValidDate = true;
            let val = value?.toString().replaceAll(/\D/g, "");
            if (val) {
              if (isNaN(val)) {
                isValidDate = false;
              } else {
                isValidDate = validatePersonNo(val).isValid;
              }
            } else {
              val = "";
            }
            return isValidDate || val?.toString() === "";
          }
        )
        .test(
          "personNo",
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
          (val: any) => {
            val = typeof val == "undefined" ? "" : val.toString();
            return personNoValidation(val);
          }
        ),
    }),
    home: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(), 
    mobile: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    work: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(), 
    sms: Yup.string() 
      .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD') 
      .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
      .nullable(),
    creditorReference: Yup.string().when("isAddOtherParty", {
      is: false,
      then: Yup.string().required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
      ),
    }),
  });
};
