import axios from 'axios';

/**
 * Call server using http method.
 *
 * @param {String} url  URL
 * @param {String} method  Method
 * @param {Object} body request body
 *
 * @returns {Promise} response data
 */

let headers = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  "Authorization": ''
}

const token = localStorage.getItem('token');
if (token) {
  headers = { ...headers, 'Authorization': `Bearer ${token}` }
}

const axiosInstance = axios.create({
  headers
});


const errorInterceptor = (error: any) => {
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      break;

    case 401:
      break;

    default:
      console.error(error.response.status, error.message);
  }
  return Promise.reject(error);
};

const responseInterceptor = (response: any) => {
  switch (response.status) {
    case 200:
      break;
    default:
  }

  return response;
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

const httpClient = async (url: string, method: "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS", body?: any) => {
  let headers = {
    'Accept': 'application/json',
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json',
    'Authorization': '',
    'Ocp-Apim-Subscription-Key' : '70c9d01209c54a2bbd4c2e466ad020f3'
  };

  const token = localStorage.getItem('token');
  if (token) {
    headers = { ...headers, 'Authorization': `Bearer ${token}` }
  }

  let data = [];

  try {

    const response = await axiosInstance.request({
      url: url,
      method: method,
      headers: headers,
      data: (method == "GET") ? null : JSON.stringify(body),
      timeout: 20000
    })

    let dataWrapped = await response.data;
    data = dataWrapped.result;
  } catch (e) {
  }
  return data;
};

export default httpClient;





