import { httpCollection } from "us.helper/http/collection";

export default {
  init: {
    get: ({ groupId, type }: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const groups = await httpCollection.getById(
            "entityservice",
            "creditorgroup",
            `${type}`
          );

          let creditors;
          if (groupId?.includes(-1)) {
            creditors = { data: [], status: 200 };
          } else {
            creditors = await httpCollection.post(
              "entityservice",
              "creditorsbytype",
              {
                groupId,
                type,
              }
            );
          }

          resolve({
            groups: groups.data,
            creditors: creditors.data,
            groupsStatus: groups.status,
            creditorsStatus: creditors.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  creditors: {
    get: ({ groupId, type, creditorNo, creditorName }: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "entityservice",
            "creditorsbytype",
            {
              groupId,
              type,
              creditorNo,
              creditorName,
            }
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
