import { ADAuth, AD, msalConfig, loginRequest } from "./ad";
import { ADAuthV1, ADV1, msalConfigV1, v1LoginRequest } from "./adv1";
import { B2CAuth, B2C } from "./b2c";

export {
  AD,
  ADAuth,
  B2C,
  B2CAuth,
  msalConfig,
  loginRequest,
  ADV1,
  ADAuthV1,
  msalConfigV1,
  v1LoginRequest,
};
