export const ACTIVITY_SUMMARY_COLUMNS = [
  {
    dataIndex: "activityGroup",
    width: 30,
  },
  {
    dataIndex: "more",
    width: 40,
  },
  {
    title: "US.COLLECTION.ADMIN:ACTIVITIES.CODE",
    dataIndex: "activityCode",
    width: 140,
  },
  {
    title: "US.COLLECTION.ADMIN:ACTIVITIES.NAME",
    dataIndex: "name",
  },
  {
    title: "US.COLLECTION.ADMIN:ACTIVITIES.DISPLAY_NAME",
    dataIndex: "displayName",
  },
  {
    title: "US.COLLECTION.ADMIN:ACTIVITIES.TYPE",
    dataIndex: "type",
    width: 150,
  },
  {
    title: "US.COLLECTION.ADMIN:ACTIVITIES.DESCRIPTION",
    dataIndex: "description",
  },
];

export enum ACTIVITY_SUMMARY_COLUMN_NAMES {
  ACTIVITY_GROUP = "activityGroup",
  MORE = "more",
  ACTIVITY_ID = "activityId",
}
