import React, { useEffect, useState } from 'react';
import { TimePicker, Form } from 'antd';
import { useField, Field } from 'formik';
import * as Utility from './utility';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
export const $TimePicker = ({
  id,
  errors,
  name,
  onChange,
  picker,
  minuteStep,
  label,
  currentTimeFormat,
  defaultValue,
  dateFormat,
  disabledTime,
  value,
  size,
  placeholder,
  ...rest
}: any) => {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();
  const error = meta?.error;
  const isTouched = meta?.touched;

  return (
    <Form.Item
      label={label}
      help={isTouched && error && t(error as string)}
      validateStatus={Utility.validateStatus(error, isTouched)}
    >
      <Field
        as={TimePicker}
        {...field}
        {...rest}
        value={value}
        size={size}
        picker={picker}
        format={currentTimeFormat}
        minuteStep={minuteStep}
        id={name}
        placeholder={placeholder}
        disabledTime={disabledTime}
        onChange={
          typeof onChange === 'function'
            ? onChange
            : (time: any) => helpers.setValue(moment(time, currentTimeFormat))
        }
      />
    </Form.Item>
  );
};
