import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { $Tooltip, $Input, $Avatar, $Empty } from "us.common/components";
import { FieldArray, useFormikContext } from "formik";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { manageActivities } from "us.collection.admin/actions";
import { ISPParameter } from "us.collection.admin/interfaces";
import { ActivityType } from "us.collection.admin/constants";
import { getNameOfActivityType } from "us.collection.admin/functions";

const { parameters } = manageActivities;

/**
 * @description Parameter setup
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */
const ParameterSetUp: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { activityTypeName, activityType } = values as any;
  const { getParameters, parameters, properties } = props;

  const { data } = parameters;
  const { data: activityTypeNameList } = properties;

  useEffect(() => {
    if (
      activityType?.toUpperCase() == ActivityType.SP &&
      activityTypeName &&
      activityTypeNameList?.length > 0
    ) {
      getParameters &&
        getParameters({
          spName: getNameOfActivityType(activityTypeNameList, activityTypeName),
        });
    }
  }, [activityTypeName, activityTypeNameList]);

  return (
    <FieldArray
      name="parameters"
      render={() => (
        <div className="dom-amt-list-table ad-parameters">
          {data.length > 0 &&
            data.map(
              (
                { parameterName, parameterLength, parameterType }: ISPParameter,
                index: number
              ) => (
                <div className="list-table-row" key={index}>
                  <div className="list-table-col ad-col-avatar">
                    <$Tooltip
                      placement="topLeft"
                      title={t("US.COLLECTION.ADMIN:ACTIVITIES.INPUT")}
                    >
                      <$Avatar shape="square" size="small">
                        {t("US.COLLECTION.ADMIN:ACTIVITIES.IN")}
                      </$Avatar>
                    </$Tooltip>
                  </div>
                  <div className="list-table-col">
                    <$Tooltip
                      placement="topLeft"
                      title={t("US.COLLECTION.ADMIN:ACTIVITIES.PARAMETER_NAME")}
                    >
                      {parameterName}
                    </$Tooltip>
                  </div>
                  <div className="list-table-col ad-col-input">
                    <$Input
                      name={`parameters[${index}]`}
                      size="small"
                      placeholder={t(
                        "US.COLLECTION.ADMIN:ACTIVITIES.DEFAULT_VALUE"
                      )}
                      addonBefore={
                        <$Tooltip
                          placement="topLeft"
                          title={t(
                            "US.COLLECTION.ADMIN:ACTIVITIES.PARAMETER_DATA_TYPE"
                          )}
                        >
                          {parameterType}
                        </$Tooltip>
                      }
                      addonAfter={
                        <$Tooltip
                          placement="topLeft"
                          title={t(
                            "US.COLLECTION.ADMIN:ACTIVITIES.DATA_TYPE_LENGTH"
                          )}
                        >
                          {parameterLength}
                        </$Tooltip>
                      }
                    />
                  </div>
                </div>
              )
            )}
          {!(data.length > 0) && (
            <$Empty
              image={$Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("COMMON.NO_DATA")}
              className="m-0"
            />
          )}
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { activity, parameters, properties } = manageActivities;
  const { entityTypes } = activity;

  return { entityTypes, parameters, properties };
};
const mapDispatchToProps = {
  getParameters: parameters.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ParameterSetUp);
