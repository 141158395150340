export enum WorkflowState {
    ADDRESS_UNKNOWN = 'addressunknown',
    BANCRUPTCY = 'bancruptcy',
    CLOSE = 'close',
    CLOSED = 'closed',
    CONFORMATION = 'conformation',
    CORE_WORKFLOW = 'coreworkflow',
    CREDIT_CHECK = 'creditcheck',
    CREDITTOR_BO = 'creditorbO',
    CREDITTOR_DW = 'creditordw',
    CREDITTOR_REMINDER = 'creditorreminder',
    CREDITTOR_RESPOND = 'creditorrespond',
    DEBTSETTLEMENT = 'debtsettlement',
    ENFORCEMENT = 'enforcement',
    ERROR = 'error',
    FREEZE = 'freeze',
    HIGH_BALANCE_CAMPAIGN_COMPLETED = 'highbalancecampaigncompleted',
    HIGHER_BALANCE_SURVEILLANCE = 'higherbalancesurveillance',
    INITIALIZE = 'initialize',
    INITILIZING = 'initilizing',
    KEEP_WAITING = 'keepwaiting ',
    LEGAL = 'legal',
    LEGAL_OVERVAKET = 'legal overvåket',
    LEGAL_COLLECTION = 'legalcollection',
    LEGAL_SURVEILLANCE = 'legalsurveillance',
    LOW_BALANCE_CAMPAIGN_COMPLETED = 'lowbalancecampaigncompleted',
    LOW_BALANCE_SURVEILLANCE = 'lowbalancesurveillance',
    MANUAL = 'manual',
    MINOR = 'minor',
    NAGGING = 'nagging',
    OBJECTED_CLAIM = 'objectedclaim',
    OBJECTION = 'objection',
    OVERVAKET = 'overvåket',
    PART_PAYMENT = 'partpayment',
    PAYMENT_AGREEMENT = 'paymentagreement',
    QUARANTINE = 'quarantine ',
    REMINDER = 'reminder',
    REST_REMINDER = 'restreminder',
    SALARY_DEDUCTION = 'salarydeduction',
    SENTENCE_RECEIVED = 'entence received',
    START_SURVEILLANCE = 'startsurveillance',
    SUBCASE_OBJECTED_CLAIM = 'subcaseobjectedclaim',
    SURVEILLANCE = 'surveilance',
    SUV_CLOSE_CASE = 'suvclosecase',
    SUV_OBJECTION_STATE = 'suvobjectionstate',
    SUV_PART_PAYMENT = 'suvpartpayment',
    SUV_UNKNOWN = 'suvunknown',
    TRANSFER_TO_CASE = 'transfertocase',
    UNKNOWN = 'unknown',
    WAITING = 'waiting'
}

export enum CaseType {
    OPEN = "open",
    CLOSED = "close",
    FREEZE = "freeze", // NEXT_DUE_DATE
    SENT_TO_COURT = "sentence",
    SENTENCE_RECEIVED = "sentenceReceived",
    COURT = 'court'
}
