import {
  EntityType,
  StateName,
  StateNameTypes,
  Step,
  StepTypes,
  ExtendedField,
  ActivityType,
  ActivityTypeName,
  StateTreeCheckAllKey,
  EVENT_ACTIVITY_TYPE,
} from "us.collection.admin/constants";
import {
  IActivityState,
  IActivityTypeDataSource,
  IExtendedProperty,
  IFollowupCategory,
  IState,
  IExtendedField,
  ISPParameter,
  IEntityType,
} from "us.collection.admin/interfaces";
import _ from "lodash";
import { IActivitySetUpInitialValues } from "us.collection.admin/reducers/ManageActivities/Interfaces";
import { Days } from "us.collection.admin/components/ManageActivities/Components/ActivityDetail/Components/ExecutionProfile/Constants";
import { handleEndPointLocals } from "us.common/functions/handleEndPointLocals";
import { ActivityVersion } from "us.collection.admin/components/ManageActivities/Components/ActivityDetail/Components/ActivitySetUp/Constants";

/**
 * @function
 * @description set data for tree component
 * @param {Array<IActivityState>} activityStates -API response
 * @returns tree component dataSource
 */
export const setTreeDataSource = (activityStates: Array<IActivityState>) => {
  try {
    return [
      {
        title: handleEndPointLocals("US.COMMON:COMMON.CHECK_ALL"),
        key: StateTreeCheckAllKey,
        className: "check-all",
        children: activityStates.map((activityState: IActivityState) => {
          const { groupDisplayName, groupId, states } = activityState;
          return {
            title: groupDisplayName,
            key: `groupId_${groupId}`,
            className: "ms-parent",
            children: setStatesAsChildren(states),
          };
        }),
      },
    ];
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description set states to tree
 * @param {Array<IState>} states states are returned by API
 * @returns {Array<{ key: number; title: string }>}
 */
const setStatesAsChildren = (
  states: Array<IState>
): Array<{ key: number; title: string }> => {
  try {
    return states.map((state: IState) => {
      const { stateDisplayName, stateId } = state;
      return {
        key: stateId,
        title: stateDisplayName,
      };
    });
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description check step state before add to step
 * @param {Array<Step>} steps step state
 * @param {StepTypes} step new step
 * @returns
 */
export const isStepsInclude = (
  steps: Array<Step>,
  step: StepTypes
): boolean => {
  try {
    return steps.includes(step);
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description check states availability for entity type
 * @param {string|any} entityType entity type
 * @returns true -if states available for entity type ,otherwise false
 */
export const isStatesAvailableForEntityType = (entityType: string): boolean => {
  try {
    const entityTypes: Array<string> = [EntityType.CASE, EntityType.INVOICE];
    return entityTypes.includes(entityType.toLowerCase());
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description remove deleted category
 * @param {Array<IFollowupCategory>} categories
 * @param {number}categoryId deleted category Id
 * @returns {Array<IFollowupCategory>} filtered categories
 */
export const removeFollowupCategory = (
  categories: Array<IFollowupCategory>,
  categoryId: number
): Array<IFollowupCategory> => {
  try {
    return categories.filter(
      (category: IFollowupCategory) => category.categoryId != categoryId
    );
  } catch (error) {
    return categories;
  }
};

/**
 * @function
 * @description add followup category
 * @param {Array<IFollowupCategory>} categories
 * @param {IFollowupCategory} category newly added followup category
 * @returns {Array<IFollowupCategory>}  categories
 */
export const addFollowupCategory = (
  categories: Array<IFollowupCategory>,
  category: IFollowupCategory
): Array<IFollowupCategory> => {
  try {
    categories.push(category);
    return categories;
  } catch (error) {
    return categories;
  }
};

/**
 * @function
 * @description get state name for form
 * @param {StepTypes} step
 * @returns
 */
export const getStateName = (step: StepTypes): StateNameTypes => {
  try {
    switch (step) {
      case Step.ACTIVITY_SETUP:
        return StateName.ACTIVITY_SETUP;
      case Step.SET_CONTROLS:
        return StateName.SET_CONTROLS;
      case Step.EXECUTION_PROFILE:
        return StateName.EXECUTION_PROFILE;
      case Step.MODIFY_STATES:
        return StateName.MODIFY_STATES;
      default:
        return StateName.ACTIVITY_SETUP;
    }
  } catch (error) {
    return StateName.ACTIVITY_SETUP;
  }
};

/**
 * @function
 * @description convert string array to Object array
 * @param {Array<string>} data
 * @returns
 */
export const setNameData = (
  data: Array<string>
): Array<IActivityTypeDataSource> => {
  try {
    return data.map((name: string, index: number) => ({
      id: index,
      name: name,
      value: name,
    }));
  } catch (error) {
    return [];
  }
};


/**
 * @function
 * @description set extend property object for suitable to activity registration form
 * @param {Array<IExtendedProperty> | null} extendedProperties extended properties which are got from the API
 * @returns {IExtendedField} extend property object
 */
export const setExtendedFields = (
  extendedProperties: Array<IExtendedProperty> | null
): IExtendedField => {
  let response: IExtendedField = {
    templateProperty: [],
    selectedPrintMode: [],
    availablePrinterList: [],
    updateDocumentBaseState: [],
  };
  try {
    if (extendedProperties) {
      ExtendedField.forEach((field: string) => {
        const selectedProperty: IExtendedProperty | undefined = _.find(
          extendedProperties,
          { name: field }
        );
        response[field] = selectedProperty
          ? setNameData(selectedProperty.value)
          : [];
      });
    }
  } catch (error) {
  } finally {
    return response;
  }
};

/**
 * @function
 * @description validate selected states
 * @param {Array<IActivityState>} states states array
 * @param {Array<any>} selectedStates selected states
 * @returns {boolean}
 */
const isSelectedStatesMatchToCurrentStates = (
  states: Array<IActivityState>,
  selectedStates: Array<any>
): boolean => {
  try {
    const stateIds: Array<number> = [];
    _.map(states, (state: IActivityState) => {
      state.states.forEach((state: IState) => {
        stateIds.push(state.stateId);
      });
    });
    _.remove(
      selectedStates,
      (element: string | number) => !_.isInteger(element)
    );

    return selectedStates.every((selectedState: any) =>
      stateIds.includes(selectedState)
    );
  } catch (error) {
    return true;
  }
};

/**
 * @function
 * @description Set value for states property of activity registration request body
 * @param {Array<IActivityState>} states states array
 * @param {Array<any>} selectedStates selected states
 * @returns { Array<number>} selected stateIds
 */
export const setStateForRequest = (
  states: Array<IActivityState>,
  selectedStates: Array<any>
): Array<number> => {
  try {
    const stateIds: Array<number> = [];
    if (selectedStates.includes("all_1")) {
      _.map(states, (state: IActivityState) => {
        state.states.forEach((state: IState) => {
          stateIds.push(state.stateId);
        });
      });
      return stateIds;
    } else {
      _.remove(
        selectedStates,
        (element: string | number) => !_.isInteger(element)
      );
      return selectedStates;
    }
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description Set value for parameter property of activity registration request body
 * @param {Array<ISPParameter>} spParameters parameters array
 * @param {Array<string>} parameterValue user entered value array
 * @returns {Array<any>}
 */
export const setSPParameter = (
  spParameters: Array<ISPParameter>,
  parameterValue: Array<string>
): Array<any> => {
  try {
    return spParameters.map((spParameter: ISPParameter, index: number) => ({
      ...spParameter,
      value: parameterValue[index] ? parameterValue[index] : "",
    }));
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description Set Activity setup values according to activity type
 * @param {IActivitySetUpInitialValues} activitySetUpValue Activity setup form values
 * @param {Array<ISPParameter>} spParameters parameters array
 * @returns
 */
export const setActivitySetUpRequest = (
  activitySetUpValue: IActivitySetUpInitialValues,
  spParameters: Array<ISPParameter>,
  activityTypesList: Array<string>
): Object => {
  try {
    const {
      activityId,
      entityType,
      activityType,
      activityTypeName,
      name,
      displayName,
      description,
      activityCode,
      activityGroup,
      parameters,
      templateProperty,
      printMode,
      printerName,
      updateDocumentDate,
      activityVersion,
      templateCategoryId,
    } = activitySetUpValue;

    const mainProperties = {
      activityId,
      type: activityType,
      name,
      displayName,
      activityCode,
      activityGroupId: activityGroup,
      activityVersion:
        String(activityType).toLocaleUpperCase() != ActivityType.MESSAGE
          ? ActivityVersion.V1
          : activityVersion,
      templateCategoryId: templateCategoryId,
      entityType: entityType == EntityType.AR ? EntityType.Debtor : entityType,
      parameters: setSPParameter(spParameters, parameters),
      description,
      activitySPOrDllName: getNameOfActivityType(
        activityTypesList,
        activityTypeName
      ),
    };
    return activityType.toUpperCase() == ActivityType.MESSAGE
      ? Object.assign(mainProperties, {
          templateProperty,
          printMode,
          printerName,
          updateDocumentDate,
        })
      : mainProperties;
  } catch (error) {
    return {};
  }
};

/**
 * @function
 * @description Change Debtor EntityType to AR
 * @param {Array<IEntityType>} entityTypes entityType object Array
 * @returns {Array<IEntityType>} entityType object Array
 */
export const setEntityTypes = (
  entityTypes: Array<IEntityType>
): Array<IEntityType> => {
  try {
    return entityTypes.map((entityType: IEntityType) =>
      entityType.entityType == EntityType.Debtor
        ? { ...entityType, entityType: EntityType.AR }
        : entityType
    );
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description get Activity setup values from API response
 * @param {any} payloadData API response
 * @returns
 */
export const getActivitySetUpEditValue = (payloadData: any) => {
  try {
    const {
      activityCode,
      activityId,
      activitySPOrDllName,
      description,
      displayName,
      entityType,
      name,
      parameters,
      printMode,
      printerName,
      templateProperty,
      type,
      updateDocumentDate,
      activityGroupId,
      activityExecutionProfileId,
      activityVersion,
      templateCategoryId,
    } = payloadData;
    return {
      activityId,
      activityVersion,
      templateCategoryId,
      entityType: entityType == EntityType.Debtor ? EntityType.AR : entityType,
      activityType: type,
      activityTypeName: activitySPOrDllName,
      name,
      displayName,
      description,
      activityCode,
      activityGroup: activityGroupId,
      parameters: getParametersForEditActivity(parameters),
      templateProperty,
      printMode,
      printerName,
      updateDocumentDate,
      isCompleted: true,
      setExecutionProfile: activityExecutionProfileId > 0,
    };
  } catch (error) {
    return {};
  }
};

/**
 * @function
 * @description get setup Control values from API response
 * @param {any} payloadData API response
 * @returns
 */
export const getSetupControlEditValue = (payloadData: any) => {
  try {
    const {
      commonFollowupCategoryId,
      commonFollowupReason,
      isAvailableForHandling,
      isEnableAutoWFFollowup,
      isExecuteFormDomain,
      isNotifyActivityFailure,
      isShowInCP,
      isShowInDP,
      isShowInHistory,
      noOfDaysForNextFollowupDate,
      isModifyState,
    } = payloadData;

    return {
      isShowInHistory,
      isExecuteFormDomain,
      isEnableAutoWFFollowup,
      isAvailableForHandling,
      isShowInCP,
      isShowInDP,
      isModifyState,
      followupCategory: commonFollowupCategoryId || "",
      followupReason: commonFollowupReason,
      newFollowupCategory: "",
      noOfDaysForNextFollowupDate: noOfDaysForNextFollowupDate || "",
      isFollowupCategoryExist: false,
      isNotifyActivityFailure,
      isCompleted: true,
    };
  } catch (error) {
    return {};
  }
};

/**
 * @function
 * @description get setup Execution Profile values from API response
 * @param {any} payloadData API response
 * @returns
 */
export const getExecutionProfileEditValue = (payloadData: any) => {
  try {
    const { activityExecutionProfileId } = payloadData;

    return {
      activityExecutionProfileId,
      isCompleted: true,
    };
  } catch (error) {
    return {};
  }
};

export const formatProfileDetailsByResponse = (payloadData: any) => {
  try {
    const {
      profileId,
      profileName,
      isMondayEnabled,
      mondayFromTime,
      mondayToTime,
      isTuesdayEnabled,
      tuesdayFromTime,
      tuesdayToTime,
      isWednesdayEnabled,
      wednesdayFromTime,
      wednesdayToTime,
      isThursdayEnabled,
      thursdayFromTime,
      thursdayToTime,
      isFridayEnabled,
      fridayFromTime,
      fridayToTime,
      isSaturdayEnabled,
      saturdayFromTime,
      saturdayToTime,
      isSundayEnabled,
      sundayFromTime,
      sundayToTime,
      isDeleted,
    } = payloadData;
    return {
      activityExecutionProfileId: profileId,
      profileName,
      isDeleted,
      schedules: [
        {
          dayId: 1,
          day: Days.MONDAY,
          isDayEnabled: isMondayEnabled,
          timeSlot: { startTime: mondayFromTime, endTime: mondayToTime },
        },
        {
          dayId: 2,
          day: Days.TUESDAY,
          isDayEnabled: isTuesdayEnabled,
          timeSlot: { startTime: tuesdayFromTime, endTime: tuesdayToTime },
        },
        {
          dayId: 3,
          day: Days.WEDNESDAY,
          isDayEnabled: isWednesdayEnabled,
          timeSlot: { startTime: wednesdayFromTime, endTime: wednesdayToTime },
        },
        {
          dayId: 4,
          day: Days.THURSDAY,
          isDayEnabled: isThursdayEnabled,
          timeSlot: { startTime: thursdayFromTime, endTime: thursdayToTime },
        },
        {
          dayId: 5,
          day: Days.FRIDAY,
          isDayEnabled: isFridayEnabled,
          timeSlot: { startTime: fridayFromTime, endTime: fridayToTime },
        },
        {
          dayId: 6,
          day: Days.SATURDAY,
          isDayEnabled: isSaturdayEnabled,
          timeSlot: { startTime: saturdayFromTime, endTime: saturdayToTime },
        },
        {
          dayId: 7,
          day: Days.SUNDAY,
          isDayEnabled: isSundayEnabled,
          timeSlot: { startTime: sundayFromTime, endTime: sundayToTime },
        },
      ],
    };
  } catch (error) {
    return {};
  }
};

/**
 * @function
 * @description get Activity states values from API response
 * @param {any} payloadData API response
 * @returns
 */
export const getActivityStatesEditValue = (payloadData: any) => {
  try {
    const { selectedStates } = payloadData;
    return { states: selectedStates, isCompleted: true };
  } catch (error) {
    return {};
  }
};
/**
 * @function
 * @description get steps for activity edit Form
 * @param {any} payloadData
 * @returns {Array<StepTypes>}
 */
export const getStepsForEditActivity = (payloadData: any): Array<StepTypes> => {
  const { type, entityType, isModifyState } = payloadData;
  const steps: Array<StepTypes> = [Step.ACTIVITY_SETUP, Step.SET_CONTROLS];
  try {
    if (isModifyState && isStatesAvailableForEntityType(entityType)) {
      steps.push(Step.MODIFY_STATES);
    }
    if (type == EVENT_ACTIVITY_TYPE) {
      steps.splice(1, 1);
    }
    return steps;
  } catch (error) {
    return steps;
  }
};

/**
 * @function
 * @description Check time frame profile step appear or not
 * @param {string} activityType activity type
 * @param {string} activityTypeName activity type name
 * @returns {boolean}
 */
export const isTimeFrameProfileAppear = (
  activityType: string,
  activityTypeName: string
): boolean => {
  try {
    return (
      activityType.toUpperCase() == ActivityType.MESSAGE &&
      activityTypeName.toUpperCase() === ActivityTypeName.SEND_SMS
    );
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description getParameters for Edit Activity
 * @param {Array<ISPParameter>} parameters Parameters list
 * @returns {Array<string | number>}
 */
const getParametersForEditActivity = (
  parameters: Array<ISPParameter>
): Array<string | number> => {
  try {
    return parameters.map((parameter: ISPParameter) => parameter.value);
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description get actual name of activity type
 * @param {Array<string>} activityTypesList - activity type names
 * @param {string} activityTypeName - selected activity type displayName
 * @returns {string} activity type name
 */
export const getNameOfActivityType = (
  activityTypesList: Array<string>,
  activityTypeName: string
): string => {
  try {
    return (
      activityTypesList.find((name: string) =>
        name.includes(activityTypeName)
      ) ?? activityTypeName
    );
  } catch (error) {
    return activityTypeName;
  }
};
