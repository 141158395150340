import React, { useState, memo, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Common from 'us.common';
import { IRootState } from 'us.collection/interfaces';
import { SettingType } from 'us.collection.admin/interfaces';
import { useTranslation } from 'react-i18next';
import { hasInTheMenu } from './Functions';

const { $Menu, $Empty, $Skeleton } = Common.Components;

/**
 * @description -  Menu component in the system setting main view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2901967003/View+System+Settings
 * @author Lashini Ayesha <lashinia@unicorn-solutions.com>
 * @since 16/03/2022
 */
const Menu: React.FC<PropsFromRedux> = memo((props) => {
	const { systemSettings } = props;
	const { settingTypes, isLoading } = systemSettings;
	const { t } = useTranslation();

	const { replace } = useHistory();
	const { search } = useLocation();

	const urlParams = new URLSearchParams(search);
	const typeId = urlParams.get('type-id');
	const settingId = urlParams.get('id');

	const [selectedKey, setSelectedKey] = useState<string>('');

	useEffect(() => {
		if (settingTypes) {
			// update the URL and the menu
			updateUI(
				settingTypes.length > 0
					? typeId &&
					  hasInTheMenu(settingTypes, typeId)
						? typeId
						: settingTypes[0].typeId.toString()
					: ''
			);
		}
	}, [settingTypes]);

	/**
	 * @description - Update the browser tab url according to given id value
	 * @param {string} typeId - Setting id if available
	 */
	const updateUI = (typeId: string) => {
		setSelectedKey(typeId);
		// update the URL params
		replace({
			pathname: '/system-settings',
			search: typeId
				? `?type-id=${typeId}${
						settingId
							? `&id=${settingId}`
							: ''
				  }`
				: '',
		});
	};

	return (
		<$Skeleton
			loading={isLoading}
			active
			paragraph={{
				rows: 4,
			}}>
			{settingTypes.length > 0 && (
				<$Menu
					defaultSelectedKeys={[selectedKey]}
					selectedKeys={[selectedKey]}
					className='sc-menu'
					selectable>
					{settingTypes.map(
						({
							typeId,
							typeName,
						}: SettingType) => {
							/**
							 * typeId === -1 means, new system setting type,
							 * otherwise already defined system setting type
							 */
							return (
								typeId !==
									-1 && (
									<$Menu.Item
										key={typeId.toString()}
										onClick={() =>
											updateUI(
												typeId.toString()
											)
										}>
										<span>
											{
												typeName
											}
										</span>
									</$Menu.Item>
								)
							);
						}
					)}
				</$Menu>
			)}
			{settingTypes.length === 0 && (
				<$Empty
					image={$Empty.PRESENTED_IMAGE_SIMPLE}
					description={t('COMMON.NO_DATA')}
				/>
			)}
		</$Skeleton>
	);
});

const mapStateToProps = (state: IRootState) => {
	const { systemSettings } = state.systemSettings;
	return { systemSettings };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Menu);
