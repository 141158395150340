export enum URLType {
    CASE = "case",
    SUBCASE = 'subcase',
    AR = 'ar',
    CREDITOR = 'creditor',
    CREDITORGROUP = 'creditorgroup',
    BUREAU = 'bureau',
    EXCASENO = 'caseno',
    CREDITOR_GROUP = 'creditor-group',
    TRANSACTIONSS = 'transactions',
    PAYMENT = 'payment'
}

export enum URLTypeId {
    CASE = "caseid",
    AR = 'arid',
    CREDITOR = 'creditorid',
    CREDITORGROUP = 'creditorgroupid',
    BUREAU = 'bureau',
    EXCASENO = 'caseno',
    PID = 'pid'
}

export enum EntityType {
    SUBCASE = 'S',
    CASE = "C",
    AR = 'A',
    CREDITOR = 'CRE',
    CREDITORGROUP = 'CREDITORGROUP',
    BUREAU = 'BU',
    PAYMENT = 'P'
}

export enum LevelType {
    SUBCASE = 'SubCase',
    CASE = "Case",
    AR = 'AR',
    CREDITOR = 'Creditor',
    PAYMENT = 'Payment',
    BUREAU = 'Bureau',
}
