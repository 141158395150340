export enum DebtorSelection {
  GET_DEBTOR_LIST_START = "GET_DEBTOR_LIST_START",
  GET_DEBTOR_LIST_SUCCESS = "GET_DEBTOR_LIST_SUCCESS",
  GET_DEBTOR_LIST_FAIL = "GET_DEBTOR_LIST_FAIL",

  RESET_DEBTOR_SELECTION_TABLE = "RESET_DEBTOR_SELECTION_TABLE",

  ADD_DEBTOR_START = "ADD_DEBTOR_START",
  ADD_DEBTOR_SUCCESS = "ADD_DEBTOR_SUCCESS",
  ADD_DEBTOR_FAIL = "ADD_DEBTOR_FAIL",

  ADD_DEBTOR_DRAWER = "ADD_DEBTOR_DRAWER",
}
