import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import {
  $Row,
  $Col,
  $Select,
  $Input,
  $TextArea,
  $Divider,
  $Skeleton,
  $AsyncInput,
} from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { ActivityTypeDetail, Parameters } from "./Components";
import { useFormikContext } from "formik";
import {
  isStatesAvailableForEntityType,
  isStepsInclude,
} from "us.collection.admin/functions";
import {
  ActivityType,
  AsyncFieldName,
  EntityType,
  EntityTypes,
  Step,
  EVENT_ACTIVITY_TYPE,
  DEFAULT_ACTIVITY_GROUP,
} from "us.collection.admin/constants";
import _ from "lodash";
import { getExistedData } from "./Components/AsyncInput/Functions";
import { IActivitySetup } from "./Interfaces";
import { ActivityVersion } from "./Constants";
import {
  getDropDownContent,
  isEventActivityType,
  getWorkflowType,
  getVersionOptions
} from "./Functions";

/**
 * @description Activity Setup  of Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */
const ActivitySetUp: React.FC<PropsFromRedux & IActivitySetup> = (props) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext() as any;
  const { entityType, activityType } = values as any;

  const newEntityType = useMemo(() => {
    return entityType == EntityTypes.AR ? EntityTypes.DEBTOR : entityType;
  }, [entityType]);

  const {
    entityTypes,
    activityGroups,
    stepDetail,
    drawer,
    activities,
    activityDetail,
    handleSaveBtnDisabledState,
    setStepDetail,
    getStates,
    getProperties,
    getEventList,
    templateCategories,
    getTemplateCategories,
  } = props;

  const { steps, formValues } = stepDetail;
  const { isModifyState } = formValues.setControlValue;
  const { data: entityTypeOptions, isFetching: isFetchingEntityTypes } =
    entityTypes;
  const { data: activityGroupOptions, isFetching: isFetchingActivityGroups } =
    activityGroups;
  const { isEdit } = drawer;

  const { list } = activities;
  const { data } = list;
  const [isActivityCodeExist, setIsActivityCodeExist] =
    useState<boolean>(false);
  const [isActivityNameExist, setIsActivityNameExist] =
    useState<boolean>(false);

  useEffect(() => {
    if (entityType) {
      if (values["activityCode"]) {
        setIsActivityCodeExist(
          checkDataIsAlreadyExisted("activityCode", values["activityCode"])
        );
      }
      if (values["name"]) {
        setIsActivityNameExist(
          checkDataIsAlreadyExisted("name", values["name"])
        );
      }
      getTemplateCategories && getTemplateCategories({ entityType });
    }
  }, [entityType]);

  useEffect(() => {
    entityType &&
      activityType !== EVENT_ACTIVITY_TYPE &&
      activityType &&
      getActivityTypeProperties(entityType, activityType);

    isEventActivityType(entityType, activityType) &&
      getEventList &&
      getEventList({ workflowType: getWorkflowType(entityType) });
  }, [entityType, activityType]);

  /**
   * @function
   * @description handle onChange event in isModifyState switch
   * @param {string} entityType checked value of isModifyState switch
   */
  const onChangeEntityType = (entityType: string) => {
    try {
      let newSteps: Array<any> = steps;
      if (isModifyState && isStatesAvailableForEntityType(entityType)) {
        if (!isStepsInclude(steps, Step.MODIFY_STATES)) {
          newSteps.push(Step.MODIFY_STATES);
        }
      } else {
        newSteps = _.pull(steps, Step.MODIFY_STATES);
      }
      getActivityState(entityType);
      setStepDetail &&
        setStepDetail({
          ...stepDetail,
          steps: newSteps,
        });
      getTemplateCategories && getTemplateCategories({ entityType });
      setValues({
        ...values,
        entityType,
        activityType: "",
        activityTypeName: "",
        templateProperty: "",
        printMode: "",
        printerName: "",
        updateDocumentDate: "",
        templateCategoryId: 0,
        activityGroup: DEFAULT_ACTIVITY_GROUP,
      });
    } catch (error) {}
  };

  /**
   * @function
   * @description get States for entity type
   * @param {string} entityType selected entityType
   */
  const getActivityState = (entityType: string) => {
    if (
      [EntityType.CASE, EntityType.INVOICE].includes(
        entityType.toLowerCase() as EntityType
      )
    ) {
      getStates && getStates({ entityType });
    }
  };

  /**
   * @function
   * @description get properties
   * @param {string} entityType selected entity type
   * @param {string} activityType selected activity type
   */
  const getActivityTypeProperties = (
    entityType: string,
    activityType: string
  ) => {
    if (entityType?.length > 0 && activityType?.length > 0) {
      getProperties && getProperties({ entityType, activityType });
    }
  };

  /**
   * @function
   * @description check whether enter value is existed or not
   * @param {string} value user enter value
   */
  const checkDataIsAlreadyExisted = (
    fieldName: string,
    value: string
  ): boolean => {
    try {
      if (isEdit) {
        if ((activityDetail.data as any)[fieldName] == value) {
          return false;
        } else {
          return getExistedData(data, newEntityType, fieldName).includes(
            value.toLowerCase()
          );
        }
      } else {
        return getExistedData(data, newEntityType, fieldName).includes(
          value.toLowerCase()
        );
      }
    } catch (error) {
      return false;
    }
  };
  /**
   * @function
   * @description check input value and update state
   * @param {string} value input value
   */
  const checkInputValue = (fieldName: string, value: string) => {
    const result = value.trim().length
      ? checkDataIsAlreadyExisted(fieldName, value)
      : false;
    try {
      if (AsyncFieldName.NAME.toLowerCase() == fieldName) {
        setIsActivityNameExist(result);
      } else {
        setIsActivityCodeExist(result);
      }
    } catch (error) {
      if (AsyncFieldName.NAME.toLowerCase() == fieldName) {
        setIsActivityNameExist(false);
      } else {
        setIsActivityCodeExist(false);
      }
    } finally {
      handleSaveBtnDisabledState({ [fieldName]: result });
    }
  };

  return (
    <div className="ad-layout-content">
      <$Row gutter={16}>
        <$Col span={12} className="pr-3">
          <$Skeleton
            active
            loading={isFetchingEntityTypes}
            paragraph={{ rows: 0 }}
            title={{ width: "100%" }}
          >
            <$Select
              name="entityType"
              formitem={{
                label: t("US.COLLECTION.ADMIN:ACTIVITIES.ENTITY_TYPE"),
              }}
              size="small"
              options={entityTypeOptions}
              allOption={false}
              optionText="entityType"
              optionValue="entityType"
              required
              onSelect={(value: string) => {
                onChangeEntityType(value);
              }}
              onSearchBy={["entityType"]}
              data-testid="add-activity-entityType"
            />
          </$Skeleton>
          <div className="activity-detail-section">
            <ActivityTypeDetail />
          </div>

          <$AsyncInput
            name="name"
            label={t("US.COLLECTION.ADMIN:ACTIVITIES.NAME")}
            size="small"
            required={true}
            disabled={isEdit}
            onBlur={(e: any) => {
              const value = e.target.value;
              checkInputValue("name", value);
            }}
            isValid={!isActivityNameExist}
            asyncError={t(
              "US.COLLECTION.VALIDATIONS:INVALID.NAME_IS_ALREADY_USED"
            )}
          />
          <$Input
            name="displayName"
            label={t("US.COLLECTION.ADMIN:ACTIVITIES.DISPLAY_NAME")}
            size="small"
            className="mb-2"
            required
          />
        </$Col>
        <$Col span={12} className="pl-3">
          <$TextArea
            name="description"
            style={{ height: 185 }}
            size="small"
            label={t("US.COLLECTION.ADMIN:ACTIVITIES.DESCRIPTION")}
          />
          <div style={{ marginTop: 18 }}>
            <$AsyncInput
              name="activityCode"
              label={t(
                `US.COLLECTION.ADMIN:ACTIVITIES.${
                  values?.activityType == EVENT_ACTIVITY_TYPE
                    ? "EVENT"
                    : "ACTIVITY"
                }_CODE`
              )}
              size="small"
              required={true}
              onBlur={(e: any) => {
                const value = e.target.value;
                checkInputValue("activityCode", value);
              }}
              isValid={!isActivityCodeExist}
              asyncError={t(
                "US.COLLECTION.VALIDATIONS:INVALID.ACTIVITY_CODE_IS_ALREADY_USED"
              )}
            />
          </div>
          <div style={{ marginTop: 12 }}>
            {values?.activityType != EVENT_ACTIVITY_TYPE && (
              <$Skeleton
                active
                loading={isFetchingActivityGroups}
                paragraph={{ rows: 0 }}
                title={{ width: "100%" }}
              >
                <$Select
                  name="activityGroup"
                  formitem={{
                    label: t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_GROUP"),
                  }}
                  size="small"
                  options={activityGroupOptions}
                  optionText="activityGroupName"
                  optionValue="activityGroupId"
                  defaultValue={DEFAULT_ACTIVITY_GROUP}
                  allOption={false}
                  onSearchBy={["activityGroupName", "activityGroupId"]}
                />
              </$Skeleton>
            )}
            {String(activityType).toLocaleUpperCase() ==
              ActivityType.MESSAGE && (
              <$Select
                name="activityVersion"
                formitem={{
                  label: t("US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_VERSION"),
                }}
                size="small"
                options={getVersionOptions(values?.templateCategoryId ,isEdit)}
                optionText="label"
                optionValue="value"
                allOption={false}
              />
            )}
            {String(activityType).toLocaleUpperCase() == ActivityType.MESSAGE &&
              values.activityVersion == ActivityVersion.V2 && (
                <$Select
                  name="templateCategoryId"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ADMIN:ACTIVITIES.DOCUMENT_CATEGORY"
                    ),
                  }}
                  size="small"
                  options={getDropDownContent(templateCategories.data)}
                  optionText="label"
                  optionValue="value"
                  allOption={false}
                  defaultActiveFirstOption={true}
                  required
                  loading={templateCategories.isFetching}
                />
              )}
          </div>
        </$Col>
      </$Row>
      {values?.activityType != EVENT_ACTIVITY_TYPE && (
        <>
          <$Divider className="mb-3" />
          <div>
            <strong>{t("US.COLLECTION.ADMIN:ACTIVITIES.PARAMETERS")}</strong>
            <Parameters />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const {   
    activity,
    stepDetail,
    drawer,
    activities,
    activityDetail,
    templateCategories,
  } = manageActivities;

  const { entityTypes, activityGroups } = activity;
  return {
    entityTypes,
    activityGroups,
    stepDetail,
    drawer,
    activities,
    activityDetail,
    templateCategories,
  };
};

const { activity, states, properties, template, eventList } = manageActivities;

const mapDispatchToProps = {
  setStepDetail: activity.setStep,
  getStates: states.get,
  getProperties: properties.get,
  getTemplateCategories: template.get,
  getEventList: eventList.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivitySetUp);
