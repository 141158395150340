import React from "react";
import { IPagination } from "./IPagination";
import * as Components from "us.common/components";
import { LeftOutlined, RightOutlined } from "us.icons";
import { useTranslation } from "react-i18next";

const { $Button, $Tooltip } = Components;

const Pagination: React.FC<IPagination> = (props) => {
  const { t } = useTranslation();
  const { currentPage, dataLength, onNavigation } = props;
  return (
    <div className="d-flex align-items-center">
      <$Tooltip title={t("US.COLLECTION.COMMON:COMMON.PREVIOUS_PAGE")}>
        <$Button
          data-testid="pagination-left"
          type="link"
          icon={<LeftOutlined />}
          disabled={currentPage === 0}
          onClick={() => onNavigation(false)}
        />
      </$Tooltip>
      {dataLength && dataLength > 1 && (
        <span
          className="text-center"
          style={{ width: 70 }}
          data-testid="page-count"
        >
          {currentPage + 1} / {dataLength}
        </span>
      )}
      {dataLength && dataLength === 1 && (
        <span className="text-center" style={{ width: 70 }}>
          1
        </span>
      )}
      <$Tooltip title={t("US.COLLECTION.COMMON:COMMON.NEXT_PAGE")}>
        <$Button
          data-testid="pagination-right"
          type="link"
          icon={<RightOutlined />}
          disabled={currentPage + 1 === dataLength}
          onClick={() => onNavigation(true)}
        />
      </$Tooltip>
    </div>
  );
};

export default Pagination;
