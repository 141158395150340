import React, { useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { IDetails } from "us.collection.admin/components/ManageFee/Interfaces";
import {
  DebtorType,
  DEBTOR_TYPE_OPTIONS,
  INITIAL_DRAWER_DATA,
  DrawerType,
  ManageFeeAction,
} from "us.collection.admin/components/ManageFee/Constants";
import { useLocation } from "react-router-dom";
import {
  getFeeDetailsById,
  checkNewBasisRecordIsDuplicate,
  checkBasisIsDirty,
} from "us.collection.admin/components/ManageFee/Functions";
import moment from "moment";
import { FeeBasisValidationSchema } from "us.collection.admin/components/ManageFee/Validations";
import { AddNewFeeBasis } from "us.collection.admin/components/ManageFee/Repository";
import * as Actions from "us.collection.admin/actions";
import _ from "lodash";
import "../../Home.scss";

const {
  $Button,
  $Form,
  $Popconfirm,
  $Alert,
  $DatePicker,
  $InputAmount,
  $Select,
} = Common.Components;

/**
 * @description - Fee Type Detail view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const Details: React.FC<PropsFromRedux & IDetails> = (props) => {
  const { t } = useTranslation();
  const {
    actionType,
    currentCurrency,
    currentDateFormat,
    currentLanguage,
    feeBasis,
    addNewFeeBasis,
    changeDrawerDetails,
    savePayload,
    drawerInfo,
  } = props;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");
  const [isError, setError] = useState<boolean>(false);

  /**
   * @description - get selected Fee basis record from the Table
   */
  const feeData = useMemo(() => {
    return getFeeDetailsById(
      feeBasis?.list,
      drawerInfo?.record?.feeId ?? -1,
      actionType,
      DrawerType.FEE_BASIS
    );
  }, [feeBasis?.list, drawerInfo?.record?.feeId, actionType]);

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   * @param {any} actions - Form Actions
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const basisPayload = AddNewFeeBasis.call({
      ...values,
      typeId,
      feeId: drawerInfo?.record?.feeId,
    });
    if (checkNewBasisRecordIsDuplicate(feeBasis?.list, basisPayload)) {
      setError(true);
    } else {
      setError(false);
      addNewFeeBasis && addNewFeeBasis(basisPayload);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...feeData,
      }}
      validationSchema={FeeBasisValidationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical" autoComplete="off" onSubmit={handleSubmit}>
            <div>
              <div className="mb-3">
                <$DatePicker
                  allowClear
                  label={t("US.COLLECTION.COMMON:COMMON.DATE")}
                  placeholder={t("US.COLLECTION.COMMON:COMMON.SELECT_DATE")}
                  name="fromDate"
                  format={currentDateFormat}
                  size="small"
                  defaultPickerValue={moment()}
                  value={moment(values?.fromDate)}
                  className="w-100"
                  required
                  data-testid="fee-date"
                />
              </div>
              <div className="mb-3">
                <$InputAmount
                  name="amount"
                  value={values?.amount}
                  label={t("US.COLLECTION.COMMON:COMMON.AMOUNT")}
                  currentLanguage={currentLanguage}
                  currentCurrency={currentCurrency}
                  size="small"
                  className="w-100"
                  required
                  max={9999999999}
                />
              </div>
              <div>
                <$Select
                  name="debtorType"
                  formitem={{
                    label: t("US.COLLECTION.ADMIN:MANAGE_FEE.DEBTOR_TYPE"),
                  }}
                  allOption={false}
                  required
                  defaultValue={DebtorType.P}
                  options={DEBTOR_TYPE_OPTIONS}
                  data-testid="debtor_Type"
                />
              </div>

              {/* Error Message */}
              {isError && (
                <div className="mt-5">
                  <$Alert
                    message={t(
                      "US.COLLECTION.ADMIN:MANAGE_FEE.NO_DUPLICATES_ALLOWED"
                    )}
                    type="error"
                    showIcon
                  />
                </div>
              )}
            </div>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  type="primary"
                  onClick={handleSubmit}
                  className="mr-2"
                  data-testid="save-btn"
                  loading={savePayload?.isSaving}
                  disabled={
                    !checkBasisIsDirty(actionType, values, feeData) ||
                    Object.keys(restProps.errors).length != 0
                  }
                >
                  {actionType === ManageFeeAction.ADD
                    ? t("US.COMMON:COMMON.SAVE")
                    : t("US.COMMON:COMMON.UPDATE")}
                </$Button>
                {checkBasisIsDirty(actionType, values, feeData) && (
                  <$Popconfirm
                    title={t(
                      "US.COMMON:COMMON.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                    )}
                    placement="topLeft"
                    onConfirm={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                    okText={t("US.COMMON:COMMON.YES")}
                    cancelText={t("US.COMMON:COMMON.NO")}
                  >
                    <$Button>{t("US.COMMON:COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {!checkBasisIsDirty(actionType, values, feeData) && (
                  <$Button
                    onClick={() =>
                      changeDrawerDetails &&
                      changeDrawerDetails(INITIAL_DRAWER_DATA)
                    }
                  >
                    {t("US.COMMON:COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, manageFee } = state;
  const { currentDateFormat, currentCurrency, currentLanguage } = common;
  const { feeBasis, feeRange, savePayload, drawerInfo } = manageFee;
  return {
    currentDateFormat,
    currentCurrency,
    currentLanguage,
    feeBasis,
    feeRange,
    savePayload,
    drawerInfo,
  };
};
const { newFeeBasis, feeTypeActions } = Actions.manageFee;
const { openDrawer } = feeTypeActions;

const mapDispatchToProps = {
  addNewFeeBasis: newFeeBasis.save,
  changeDrawerDetails: openDrawer,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Details);
