import React from "react";
import { Selection } from "./Components";
import {
  IGroupSelectionWithProps,
  ISelectCreditors,
  ISelectionWithProps,
} from "./Interfaces/Interface";
import PropTypes from "prop-types";
import { SelectionHOC } from "./Components/SelectionHOC";
import { SelectionWithRedux } from "./Components/SelectionWithRedux";

/**
 * @description -  Creditor selection Component.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2776727606/Select+Creditors+-+Common+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * @param name{string} - selected creditor ids. default [-1].
 * @groupName - groupName provides array of selected group ids. default undefined.
 * */
const $S = <T extends ISelectCreditors & Partial<ISelectionWithProps & IGroupSelectionWithProps>>({
  drawerProps,
  name,
  creditorNoName = "creditorNo",
  groupName,
  disabledKeys,
  disabledPropName,
  visible = false,
  withDrawer = true,
  title,
  width = "1200",
  onClose,
  onConfirm,
  onSelect,
  groupType,
  multiple = true,
  defaultAllSelectionFilter = false,
  useReduxProps = true,
  showCreditorGroup = true,
  isGroupsLoading = false,
  isCreditorsLoading = false,
  isInitLoading = false,
  creditors = [],
  selectedCreditors,
  getCreditors,
  getInitData,
  selectCreditors,
  removeCreditors,
  creditorGroups = [],
  restrictedExtraColumns
}: T) => {
  return (
    <>
      <SelectionHOC
        withDrawer={withDrawer}
        drawerProps={drawerProps}
        title={title}
        visible={visible}
        onClose={onClose}
        width={width}
      >
        {useReduxProps && (
          <SelectionWithRedux
            name={name}
            creditorNoName={creditorNoName}
            groupName={groupName}
            onClose={onClose}
            onConfirm={onConfirm}
            onSelect={onSelect}
            withDrawer={withDrawer}
            multiple={multiple}
            groupType={groupType}
            disabledKeys={disabledKeys}
            disabledPropName={disabledPropName}
            defaultAllSelectionFilter={defaultAllSelectionFilter}
            showCreditorGroup={showCreditorGroup}
          />
        )}
        {!useReduxProps && (
          <Selection
            name={name}
            creditorNoName={creditorNoName}
            groupName={groupName}
            onClose={onClose}
            onConfirm={onConfirm}
            onSelect={onSelect}
            withDrawer={withDrawer}
            multiple={multiple}
            groupType={groupType}
            disabledKeys={disabledKeys}
            disabledPropName={disabledPropName}
            defaultAllSelectionFilter={defaultAllSelectionFilter}
            isGroupsLoading={isGroupsLoading}
            isCreditorsLoading={isCreditorsLoading}
            isInitLoading={isInitLoading}
            creditors={creditors}
            selectedCreditors={selectedCreditors}
            getCreditors={getCreditors}
            getInitData={getInitData}
            selectCreditors={selectCreditors}
            removeCreditors={removeCreditors}
            creditorGroups={creditorGroups}
            showCreditorGroup={showCreditorGroup}
            restrictedExtraColumns={restrictedExtraColumns}
          />
        )}
      </SelectionHOC>
    </>
  );
};

$S.propTypes = {
  drawerProps: PropTypes.any,
  name: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  onClose: PropTypes.func,
};

export const $SelectCreditors = $S;
