import { FeeBasisHistoryTableColumns } from "../../Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";

/**
 * @description Get column search input field current value by key
 * @param key - input field name
 * @returns value - Current value of the input field
 */
export const getColumnFilterProps = (key: FeeBasisHistoryTableColumns) => {
  switch (key) {
    case FeeBasisHistoryTableColumns.MENU:
      return {};
    case FeeBasisHistoryTableColumns.DATE:
      return { customFilter: ColumnFilterTypes.DATE_TIME, showFilters: true };
    case FeeBasisHistoryTableColumns.AMOUNT:
      return { customFilter: ColumnFilterTypes.AMOUNT, showFilters: true };
    default:
      return { customFilter: true, showFilters: true };
  }
};
