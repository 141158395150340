
/**
 * This is the function that is used for custorm validation of the postal area in AntDesign forms.
 * @param rule the rule that is being checked
 * @param value the value input for the input field
 * @param callBack response funciton 
 * @param postalAreas list of postal areas that are considered
 */
export const postalCodeValidator = (rule: any, value: any, callBack: any, postalAreas:any[]) => {
    if(value === ''){
        callBack();
    }
    let inputValue = Number.parseInt(value);
    if (inputValue) {
        const matched = postalAreas.filter((po: any) => Number.parseInt(po.PostalCode) === inputValue);
        if (matched.length === 0) {
            callBack(rule.message);
        } else {
            callBack();
        }
    } else {
        callBack(rule.message);
    }
}
