import { IAPIAction } from "us.collection/interfaces";
import { GDPRComment } from "us.collection/constants";

export const GDPRCommentAction: Readonly<IAPIAction> = {
  gdprCommentView: {
    get: (data) => ({
      type: GDPRComment.GET_GDPRCOMMENT_VIEW_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GDPRComment.GET_GDPRCOMMENT_VIEW_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GDPRComment.GET_GDPRCOMMENT_VIEW_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: GDPRComment.GET_GDPRCOMMENT_VIEW_RESET,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  gdprComment: {
    save: (data) => ({
      type: GDPRComment.ADD_GDPR_COMMENT_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GDPRComment.ADD_GDPR_COMMENT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GDPRComment.ADD_GDPR_COMMENT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
