import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { $Button, $Popconfirm, $TableTree } from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import _ from "lodash";
import { DeleteOutlined } from "us.icons";

const { activity, followupCategory } = manageActivities;

/**
 * @description Followup Categories
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 08/06/2022
 */
const FollowupCategories: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { manageBreadCrumb, followupCategories, deleteFollowupCategory } =
    props;

  const { data } = followupCategories;
  /**
   * @function
   * @description handle cancel button click event of breadcrumb
   */
  const onClose = () => {
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: false,
        title: "",
      });
  };

  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      customRenderChild: (text: any, record: any, index: number) => {
        return (
          <$Popconfirm
            title={t("US.COLLECTION.ADMIN:ACTIVITIES.DELETE_CONFIRM")}
            onConfirm={() => {
              const { categoryId } = record;
              deleteFollowupCategory && deleteFollowupCategory({ categoryId });
            }}
            okText={t("US.COMMON:COMMON.YES")}
            cancelText={t("US.COMMON:COMMON.NO")}
          >
            <$Button icon={<DeleteOutlined />} size="small" danger />
          </$Popconfirm>
        );
      },
    },
    {
      title: t("US.COLLECTION.ADMIN:ACTIVITIES.NAME"),
      dataIndex: "categoryName",
      key: "categoryName",
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
    },
  ];

  return (
    <>
      <$TableTree
        rowKey="categoryId"
        data={data}
        size="small"
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        filterOnType={true}
        resetOnSourceChange={true}
        bordered
        pagination={{ defaultPageSize: 200 }}
        scroll={{ x: 400, y: "calc(100vh - 290px)" }}
        columns={columns}
        firstColSkipFilterProps={-1}
      />
      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button onClick={onClose}>
            {t("US.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { followupCategories } = manageActivities;
  return { followupCategories };
};
const mapDispatchToProps = {
  manageBreadCrumb: activity.manageBreadCrumb,
  deleteFollowupCategory: followupCategory.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowupCategories);
