import { IAPIActionBase } from "us.common/interfaces";
import { AddDebtor } from "us.common/constants";
import { IAddDebtor } from "us.common/interfaces/Actions";

export const addDebtorActions: Readonly<IAPIActionBase & IAddDebtor> = {
  postalCode: {
    get: (data) => ({
      type: AddDebtor.SEARCH_POSTAL_CODE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: AddDebtor.SEARCH_POSTAL_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: AddDebtor.SEARCH_POSTAL_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  municipalityCode: {
    get: (data) => ({
      type: AddDebtor.SEARCH_MUNICIPALITY_CODE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: AddDebtor.SEARCH_MUNICIPALITY_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: AddDebtor.SEARCH_MUNICIPALITY_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  otherPartyRoleTypes: {
    get: (data) => ({
      type: AddDebtor.GET_OTHER_PARTY_ROLE_TYPE_STRAT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    save: (data) => ({
      type: AddDebtor.SAVE_OTHER_PARTY_ROLE_TYPE_STRAT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: AddDebtor.GET_OTHER_PARTY_ROLE_TYPE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: AddDebtor.GET_OTHER_PARTY_ROLE_TYPE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  otherPartyRole: {
    delete: (data) => ({
      type: AddDebtor.DELETE_OTHER_PARTY_ROLE_TYPE_STRAT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: AddDebtor.DELETE_OTHER_PARTY_ROLE_TYPE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: AddDebtor.DELETE_OTHER_PARTY_ROLE_TYPE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  entity: {
    search: (data) => ({
      type: AddDebtor.SEARCH_ENTITY_STRAT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: AddDebtor.SEARCH_ENTITY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: AddDebtor.SEARCH_ENTITY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: AddDebtor.RESET_ENTITY_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
