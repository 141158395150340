import React from "react";
import { IOptionLabel } from "./Interfaces";
import { useTranslation } from "react-i18next";
import { getUSCVersionName } from "us.common/functions";
import { $Tag } from "us.common/components";

/**
 * @description Activity Execution
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3083632702/Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 19/08/2022
 */
const OptionLabel: React.FC<IOptionLabel> = (props) => {
  const { code, name, version, isVersionVisible } = props;
  const { t } = useTranslation();
  return (
    <div className="py-1">
      <div className="d-flex align-items-center justify-content-between">
        <div className="flex-grow-1" style={{ minWidth: 0 }}>
          <div>{`${t("US.COMMON:COMMON.CODE")} : ${code}`}</div>
          <div className="text-truncate">{`${t(
            "US.COMMON:COMMON.NAME"
          )} : ${name}`}</div>
        </div>
        {isVersionVisible && (
          <$Tag className={`ml-4 tag-version-${version}`}>{`${getUSCVersionName(
            version
          )}`}</$Tag>
        )}
      </div>
    </div>
  );
};
export default OptionLabel;
