import _ from "lodash";
// import { DocumentType } from "us.collection.routines-and-activities/constants";

type filter = {
  activityCode: string;
  displayName: string;
  description: string;
};

/**
 * Create dynamically <select> field options  array
 * @param values
 * @returns options array
 */
export const setOptions = (values: any) => {
  return values.map((item: any) => {
    return {
      label: item.value,
      value: item.id,
    };
  });
};

/**
 * Create data object for dynamically render fields
 * @param data
 * @returns
 */
export const setTableData = (
  data: any,
  level: string
): {
  tableData: any[];
  isDefault: boolean;
  buttonTabIndex:
    | {
        dateIndex: number;
        runIndex: number;
        cancelIndex: number;
      }
    | {};
} => {
  let isDefault: boolean = true;
  let tem: any = [];
  let tIndex: number = 2;
  let buttonTabIndex:
    | {
        dateIndex: number;
        runIndex: number;
        cancelIndex: number;
      }
    | {};

  data?.map((item: any) => {
    if (item.valueType?.toLowerCase() == "list") {
      isDefault = false;
      tem.push({
        ...item,
        field: "select",
        tabIndex: tIndex,
        options: setOptions(item?.value),
      });
      tIndex++;
    } else {
      if (
        item?.name?.toLowerCase() === "username" ||
        item?.name?.toLowerCase() === "user"
      ) {
        tem.push({
          ...item,
          field: "input",
        });
      } else if (checkDefaultParams(item?.name, level)) {
        tem.push({
          ...item,
          field: "input",
        });
      } else {
        isDefault = false;
        let field: string;
        switch (item?.parameterType?.toLowerCase()) {
          case "boolean":
            field = "switch";
            break;
          case "date":
            field = "datePicker";
            break;
          case "datetime":
            field = "datePicker";
            break;
          case "int":
            field = "input";
            break;
          case "string":
            field = "input";
            break;
          default:
            field = "input";
            break;
        }
        tem.push({
          ...item,
          tabIndex: tIndex,
          field,
        });
        tIndex++;
      }
    }
  });
  if (isDefault) {
    buttonTabIndex = {};
  } else {
    buttonTabIndex = {
      dateIndex: tIndex,
      runIndex: tIndex + 1,
      cancelIndex: tIndex + 2,
    };
  }
  return {
    tableData: tem,
    isDefault,
    buttonTabIndex,
  };
};

/**
 * check whether parameter is default or not
 * @param name parameter name
 * @param level activtity relavent level name, it can be subcase,case,ar,creditor,bureau,payment
 * @returns {boolean} - return true if parameter is default ,otherwise return false
 */
export const checkDefaultParams = (name: string, level: string): boolean => {
  let isDefault: boolean = false;
  if (
    level?.toLocaleLowerCase() === "subcase" ||
    level?.toLocaleLowerCase() === "case"
  ) {
    switch (name.toLocaleLowerCase()) {
      case "entityid":
        isDefault = true;
        break;
      case "entityno":
        isDefault = true;
        break;
      case "id":
        isDefault = true;
        break;
      case "caseno":
        isDefault = true;
        break;
      case "caseid":
        isDefault = true;
        break;
      default:
        break;
    }
  } else if (level?.toLocaleLowerCase() === "ar") {
    switch (name.toLocaleLowerCase()) {
      case "arno":
        isDefault = true;
        break;
      case "arid":
        isDefault = true;
        break;
      case "entityid":
        isDefault = true;
        break;
      case "entityno":
        isDefault = true;
        break;
      default:
        break;
    }
  } else if (level?.toLocaleLowerCase() === "creditor") {
    switch (name.toLocaleLowerCase()) {
      case "creditorno":
        isDefault = true;
        break;
      case "creditorid":
        isDefault = true;
        break;
      case "entityid":
        isDefault = true;
        break;
      case "entityno":
        isDefault = true;
        break;
      default:
        break;
    }
  } else if (level?.toLocaleLowerCase() === "payment") {
    switch (name.toLocaleLowerCase()) {
      case "paymentid":
        isDefault = true;
        break;
      case "paymentno":
        isDefault = true;
        break;
      case "entityid":
        isDefault = true;
        break;
      case "entityno":
        isDefault = true;
        break;
      default:
        break;
    }
  }
  switch (name.toLocaleLowerCase()) {
    case "user":
      isDefault = true;
      break;
    case "username":
      isDefault = true;
      break;
    default:
      break;
  }
  return isDefault;
};

/**
 * Create initial values for parameterlized activity
 * @param data
 * @param state current user and CaseNo,ArNo,CreditorNo, PaymentId
 * @returns initial values for parameterlized activity
 */
export const setInitialValue = (data: any, state: any) => {
  let result: any = {};
  data?.map((item: any) => {
    if (checkDefaultParams(item["name"], state?.userAndCase?.level)) {
      result[item["name"]] =
        item["name"]?.toLowerCase() === "user" ||
        item["name"]?.toLowerCase() === "username"
          ? state?.userAndCase?.user
          : state?.userAndCase?.caseNumber;
    } else {
      result[item["name"]] =
        item?.valueType?.toLowerCase() === "list" ? "" : item["value"];
    }
  });
  return result;
};

export const setAutoOptions = (values: any) => {
  return _.sortBy(values, (o) => {
    return parseInt(o.activityCode);
  }).map((item: any) => {
    return {
      label: item.displayName,
      value: item.activityCode,
      name: item.name,
      activityId: item.activityId,
    };
  });
};

export const setGroupsOptions = (values: any) => {
  let result: [] = values.map((item: any) => {
    return {
      label: item.activityGroup,
      value: item.activityGroupId,
    };
  });
  return _.uniqBy(result, "label");
};
export const filterData = (data: any, filterValue: string | number = "all") => {
  if (filterValue === "all") {
    return data?.values;
  } else {
    return data?.values?.filter(
      (item: any) => item?.activityGroupId === filterValue
    );
  }
};

export const setActivitiesData = (values: any) => {
  let result: any = [];
  Object.values(_.groupBy(values, "type")).map((item: any, index: number) => {
    result.push({
      key: index,
      group: item[0].type,
      activityCode: "",
      displayName: "",
      name: "",
      description: "",
      children: item,
    });
  });
  return result;
};

export const getLookUpTypeByCaseType = (caseType: string) => {
  switch (caseType) {
    case "sub-case":
      return "S";
    case "case":
      return "C";
    case "ar":
      return "A";
    case "creditor":
      return "CRE";
    case "bureau":
      return "B";
    case "payment":
      return "P";
    default:
      return "";
  }
};

// export const attachementType = (data: string): string => {
//   let type: string = "application/pdf";
//   switch (data?.toLowerCase()) {
//     case DocumentType.PDF:
//       type = "application/pdf";
//       break;
//     case DocumentType.DOC:
//       type = "text/doc";
//       break;
//     case DocumentType.HTML:
//       type = "text/html";
//       break;
//     case DocumentType.PNG:
//       type = "image/png";
//       break;
//     case DocumentType.JPG:
//       type = "image/jpg";
//       break;
//     case DocumentType.JPEG:
//       type = "image/jpeg";
//       break;
//     case DocumentType.TXT:
//       type = "text/plain";
//       break;
//     case DocumentType.XML:
//       type = "application/xml";
//       break;
//     default:
//       break;
//   }
//   return type;
// };
export const filterActivities = (activities: any, columnFilters: filter) => {
  let temArr: Array<any> = [];

  if (
    columnFilters.activityCode === "" &&
    columnFilters.displayName === "" &&
    columnFilters.description === ""
  ) {
    temArr = activities;
  } else {
    activities?.map((item: any) => {
      let temChildren: Array<any> = [];
      item?.children?.map((ch: any) => {
        if (
          columnFilters.activityCode != "" &&
          columnFilters.displayName != "" &&
          columnFilters.description != ""
        ) {
          if (
            columnFilters.activityCode === ch?.activityCode &&
            ch?.displayName
              ?.toLowerCase()
              .includes(columnFilters.displayName.toLowerCase()) &&
            ch?.description
              ?.toLowerCase()
              .includes(columnFilters.description.toLowerCase())
          ) {
            temChildren.push(ch);
          }
        } else if (
          columnFilters.activityCode != "" &&
          columnFilters.displayName != ""
        ) {
          if (
            columnFilters.activityCode === ch?.activityCode &&
            ch?.displayName
              ?.toLowerCase()
              .includes(columnFilters.displayName.toLowerCase())
          ) {
            temChildren.push(ch);
          }
        } else if (columnFilters.activityCode != "") {
          if (columnFilters.activityCode === ch?.activityCode) {
            temChildren.push(ch);
          }
        } else if (
          columnFilters.displayName != "" &&
          columnFilters.description != ""
        ) {
          if (
            ch?.displayName
              ?.toLowerCase()
              .includes(columnFilters.displayName.toLowerCase()) &&
            ch?.description
              ?.toLowerCase()
              .includes(columnFilters.description.toLowerCase())
          ) {
            temChildren.push(ch);
          }
        } else if (columnFilters.displayName != "") {
          if (
            ch?.displayName
              ?.toLowerCase()
              .includes(columnFilters.displayName.toLowerCase())
          ) {
            temChildren.push(ch);
          }
        } else if (columnFilters.description != "") {
          if (
            ch?.description
              ?.toLowerCase()
              .includes(columnFilters.description.toLowerCase())
          ) {
            temChildren.push(ch);
          }
        }
      });
      if (temChildren?.length > 0) {
        temArr.push({
          ...item,
          children: _.uniqBy(temChildren, "activityCode"),
        });
      }
    });
  }
  return temArr;
};
