export function ChangeRoleState(this: any,) {
    const { activeState, roleId } = this;
    return {
        roleId,
        body: [
            {
                op: 'replace',
                path: `/activeState`,
                value: !activeState,
            },
        ],
    }
}
