import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { PlusOutlined } from "us.icons";
import { FeeBasisHistory } from "../FeeBasisHistory";
import { FeeBasisTable } from "./Components/Table";
import {
  ManageFeeAction,
  DrawerType,
} from "us.collection.admin/components/ManageFee/Constants";
import { useLocation, useHistory } from "react-router-dom";
import * as Actions from "us.collection.admin/actions";
import { updateUrl } from "../../Functions";
import { IManageFee } from "us.collection.admin/components/ManageFee/Interfaces";
import { getSelectedFeeTypeNameForDrawerTitle } from "us.collection.admin/components/ManageFee/Functions";
import "../../Home.scss";
import { sortFeeBasicData } from "./Components";

const { $Skeleton, $Button, $Tabs, $Col } = Common.Components;

/**
 * @description - Fee Basis Main view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const FeeBasis: React.FC<PropsFromRedux & IManageFee> = ({
  getFeeBasisData,
  feeBasis,
  onCallAction,
  feeType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace } = history;
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");
  const feeId = urlParams.get("feeId"); // this can be use

  useEffect(() => {
    if (typeId) {
      getFeeBasisData && getFeeBasisData(typeId);
    }
  }, [typeId]);

  useEffect(() => {
    if (feeBasis.list.length > 0 && typeId != null) {
      const updatedFeeId =
        Number(feeId) == -1 ? Number(feeId) : sortFeeBasicData(feeBasis?.list)[0]?.feeId;
      updateUrl(Number(typeId), replace, updatedFeeId);
    } else {
      const updatedFeeId = Number(feeId) == -1 ? Number(feeId) : 0;
      updateUrl(Number(typeId), replace, updatedFeeId);
    }
  }, [feeBasis.list]);

  const onDoubleClickHandler = (feeId: number) => {
    updateUrl(Number(typeId), replace, feeId);
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {() => (
        <>
          <$Col
            className="collection-fee-basis"
            xl={{ span: Number(feeId) == -1 ? 14 : 10 }}
            xxl={{ span: 10 }}
          >
            <$Tabs
              className="bui-tabs"
              defaultActiveKey={"COLLECTION_FEE_BASIS"}
              onTabClick={(key: any) => {
                updateUrl(
                  Number(typeId),
                  replace,
                  key === "COLLECTION_FEE_BASIS" ? feeBasis?.list[0]?.feeId : -1
                );
              }}
            >
              {/* feeBasisLayout */}
              <$Tabs.TabPane
                tab={
                  <>
                    {`${
                      getSelectedFeeTypeNameForDrawerTitle(
                        feeType?.list,
                        Number(typeId)
                      ) ?? ""
                    }${" "}`}
                    {t("US.COLLECTION.ADMIN:MANAGE_FEE.BASIS")}
                  </>
                }
                key="COLLECTION_FEE_BASIS"
                className="px-3"
              >
                {/* <FeeBasisDetails/> */}

                <$Skeleton
                  loading={feeBasis?.isListLoading}
                  active
                  paragraph={{ rows: 2 }}
                >
                  <>
                    <div style={{ marginBottom: "-2rem" }}>
                      <$Button
                        onClick={() =>
                          onCallAction(
                            DrawerType.FEE_BASIS,
                            ManageFeeAction.ADD
                          )
                        }
                        type="dashed"
                        size="small"
                        id="add-new-basis"
                        icon={<PlusOutlined />}
                      >
                        {t("US.COLLECTION.ADMIN:MANAGE_FEE.NEW_FEE")}
                      </$Button>
                    </div>
                    <FeeBasisTable
                      onCallAction={onCallAction}
                      onDoubleClickHandler={onDoubleClickHandler}
                    />
                  </>
                </$Skeleton>
              </$Tabs.TabPane>
              {/* feeBasisHistoryLayout */}
              <$Tabs.TabPane
                tab={<>{t("US.COLLECTION.ADMIN:MANAGE_FEE.HISTORY")}</>}
                key="HISTORY"
                className="px-3"
              >
                <FeeBasisHistory onCallAction={onCallAction} />
              </$Tabs.TabPane>
            </$Tabs>
          </$Col>
          {/* Add / Edit Collection Fee */}
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeBasis, feeType } = manageFee;
  return {
    feeBasis,
    feeType,
  };
};
const { feeBasisActions } = Actions.manageFee;
const { getById } = feeBasisActions;

const mapDispatchToProps = {
  getFeeBasisData: getById,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FeeBasis);
