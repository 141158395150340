import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.admin/actions";
import * as API from "us.collection.admin/services";
import Common from "us.common";
import {
  Municipalities,
  MUNICIPALITY_DRAWER_DEFAULT,
} from "us.collection.admin/constants";
import { $Message } from "us.common/components";
import { i18n } from "us.helper";
import _ from "lodash";

const { municipalities } = Actions;
const { $MessageBox } = Common.Components;
const { search, detail } = API.municipalities;
const { init, municipalityData, municipality } = municipalities;
const { INIT_GET_MUNICIPALITIES, SAVE_MUNICIPALITY, DELETE_MUNICIPALITY } =
  Municipalities;

export const MunicipalitiesSagas = {
  municipalitiesSearch: {
    init: function* (): any {
      try {
        const { municipalityList, countries } = yield call(search.init);
        yield put(
          init.success({
            municipalities:
              municipalityList.status == 200 &&
              Array.isArray(municipalityList.data)
                ? municipalityList.data
                : [],
            countries:
              countries.status == 200 && Array.isArray(countries.data)
                ? countries.data
                : [],
          })
        );
      } catch (error) {
        yield put(init.fail(new Error()));
      }
    },
    delete: function* (action: any) {
      const params = action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.DELETING"), 0);
      try {
        const { deleteData, municipalityList, status } = yield call(
          search.delete,
          params
        );
        $Message.destroy();
        if (status === 200) {
          if (deleteData?.code == 1 && deleteData?.status) {
            $MessageBox(
              "success",
              "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.DELETE_MUNICIPALITY_SUCCESS",
              "",
              ""
            );
            yield put(municipality.success(municipalityList));
          } else if (deleteData?.code == 2 && !deleteData?.status) {
            $MessageBox(
              "error",
              "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.MUNICIPALITY_IS_IN_USE",
              "",
              ""
            );
            yield put(municipality.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.DELETE_MUNICIPALITY_FAILED",
              "",
              ""
            );
            yield put(municipality.fail(new Error()));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.DELETE_MUNICIPALITY_FAILED",
            "",
            ""
          );
          yield put(municipality.fail(new Error()));
        }
      } catch (error) {
        $Message.destroy();
        $MessageBox(
          "error",
          "US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.DELETE_MUNICIPALITY_FAILED",
          "",
          ""
        );
        yield put(municipality.fail(new Error()));
      }
    },
  },

  municipalityDetail: {
    save: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const { saveParams } = params ?? {};
      const { municipalityId } = saveParams ?? {};
      try {
        const { saveMunicipality, municipalityList } = yield call(
          detail.save,
          params
        );
        if (saveMunicipality.status === 200) {
          if (municipalityId == -1) {
            $MessageBox(
              "success",
              `US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.SAVE_MUNICIPALITY_SUCCESS`,
              "",
              ""
            );
          } else {
            $MessageBox(
              "success",
              `US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.UPDATE_MUNICIPALITY_SUCCESS`,
              "",
              ""
            );
          }
          yield put(municipalityData.success(saveMunicipality.data));

          if (
            Array.isArray(municipalityList.data) &&
            municipalityList.status === 200
          ) {
            yield put(municipality.success(municipalityList.data));
          } else {
            yield put(municipality.fail(new Error()));
          }

          if (init.manageDrawer)
            yield put(init.manageDrawer(MUNICIPALITY_DRAWER_DEFAULT));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.${
              municipalityId == -1 ? "SAVE" : "UPDATE"
            }_MUNICIPALITY_FAILED`,
            "",
            ""
          );
          yield put(municipalityData.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:MANAGE_MUNICIPALITIES.${
            municipalityId == -1 ? "SAVE" : "UPDATE"
          }_MUNICIPALITY_FAILED`,
          "",
          ""
        );
        yield put(municipalityData.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    INIT_GET_MUNICIPALITIES,
    MunicipalitiesSagas.municipalitiesSearch.init
  ),
  takeLatest(SAVE_MUNICIPALITY, MunicipalitiesSagas.municipalityDetail.save),
  takeLatest(
    DELETE_MUNICIPALITY,
    MunicipalitiesSagas.municipalitiesSearch.delete
  ),
];
