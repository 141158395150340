export enum FilterOptions {
  ALL = "all",
  SELECTED = "selected",
  NOT_SELECTED = "notSelected",
}

export enum ExtraColumnsOptions {
  MASTER_CREDITOR_ID = "masterCreditorId",
  MASTER_CREDITOR_NAME = "masterCreditorName",
  ZIP_CODE = "zipCode",
  ZIP_NAME = "zipName",
}

export enum SearchByLabels {
  CREDITOR_NO = "Creditor Number: ",
  CREDITOR_NAME = "Creditor Name: ",
}

export enum ColumnNames {
  CREDITOR_NUMBER = "pid",
  CREDITOR_NAME = "creditorName",
  COMPANY_ID = "companyID",
  GROUP_NAME = "groupName",
  MASTER_CREDITOR_ID = "masterCreditorId",
  MASTER_CREDITOR_NAME = "masterCreditorName",
  ZIP_CODE = "zipCode",
  ZIP_NAME = "zipName",
}

export const SearchByOptions: any = [
  { label: SearchByLabels.CREDITOR_NO, value: SearchByLabels.CREDITOR_NO },
  { label: SearchByLabels.CREDITOR_NAME, value: SearchByLabels.CREDITOR_NAME },
];

export const InitialFilters = {
  filteredInfo: null,
  sortedInfo: null,
};

export enum GroupType {
  ALL = "ALL",
  VAT_ORDER = "VATORDER",
  CLIENT_ORDER = "CLIENTORDER",
}
