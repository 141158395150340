import React from "react";
import { $Collapse } from "us.common/components";
import { IFollowUpCard } from "./Interfaces";
import { FollowUpHeader, FollowUpContent } from "./Components";

/**
 * @description - Follow-up card
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2912288817/Get+active+follow-up+for+EntityId+and+EntityType+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 09/03/2022
 */
const FollowUpCard: React.FC<IFollowUpCard> = (props) => {
  const {
    uniqueId,
    title,
    date,
    description,
    assignedTo,
    lastViewed,
    followUpPanel,
    onExecuteAction,
    followupId,
    followupType,
    entityDetails,
    activityExecution
  } = props;

  return (
    <$Collapse.Panel
      {...props}
      key={uniqueId}
      header={
        <FollowUpHeader
          title={title}
          date={date}
          description={description}
          followUpPanel={followUpPanel}
          assignedTo={assignedTo}
          lastViewed={lastViewed}
        />
      }
    >
      <FollowUpContent
        uniqueId={uniqueId}
        followUpPanel={followUpPanel}
        onExecuteAction={onExecuteAction}
        followupId={followupId}
        followupType={followupType}
        entityDetails={entityDetails}
        activityExecution={activityExecution}
      />
    </$Collapse.Panel>
  );
};

export default FollowUpCard;
