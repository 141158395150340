import _ from 'lodash';
import { NonProductionDataType } from 'us.collection.admin/constants';

export function UpdateNonProductionDays(this: any, redDays: NonProductionDataType) {
    const { nonSms: smsSchedule, nonEmail: emailSchedule, nonPrinting: printingSchedule } = this
    const {
        nonBanking,
        nonSms,
        nonPrinting,
        nonWorkflow,
        nonEmail } = redDays;

    return {
        calendarId: 1,
        calendarName: "Norwegian",
        smsSchedule,
        emailSchedule,
        printingSchedule,
        nonProductionDays: _.uniqBy([
            ...nonBanking,
            ...nonSms,
            ...nonPrinting,
            ...nonWorkflow,
            ...nonEmail
        ], 'reason')
    }
}