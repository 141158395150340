import { versionedHttpCollection } from 'us.helper/http/collection';

export default {
	/**
	 * A namespace
	 * @namespace activities
	 */
	activities: {
		/**
		 * Get all activities
		 * @returns {Promise} Promise object represents the API response
		 */
		get: <P extends { endPoint: string; parameters: Object }>(
			params: P
		): Promise<Array<any>> => {
			const { endPoint, parameters = {} } = params;
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'activityservice',
							endPoint,
							parameters,
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	activity: {
		/**
		 * get parameters for selected activity
		 */
		parameters: {
			get: <
				P extends {
					activityId: string;
					state: string;
				}
			>(
				params: P
			): Promise<any> => {
				return new Promise<any>(
					async (resolve, reject) => {
						const { activityId, state } =
							params;
						try {
							const { data, status } =
								await versionedHttpCollection.get(
									'activityservice',
									`activities/${activityId}/${state}/settings`,
									{},
									'v1'
								);
							if (status == 200) {
								resolve(data);
							} else {
								reject(data);
							}
						} catch (error) {
							reject(error);
						}
					}
				);
			},
		},
		execute: (params: any): Promise<any> => {
			return new Promise<any>(async (resolve, reject) => {
				try {
					const { endPoint, request } = params;
					const { data, status } =
						await versionedHttpCollection.post(
							'activityservice',
							endPoint,
							request,
							'v1'
						);
					if (status === 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
