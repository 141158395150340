import { CaseTypes } from "us.common/components/MainSearch/Constants";
import { ICaseSearchResult } from "us.common/reducers/MainSearch/Interfaces";
import { IFilterValues } from "../Interfaces";

/**
 * @function
 * @description Determine if the case should be included based on the filter criterias
 * @returns the boolean decision if the case should be included
 */
export const doesCaseInclude = (
  {
    caseType,
    mainAmount,
    balance,
    debtorName,
    creditorName,
    debtorNo,
    creditorNo,
    exCaseNo,
    birthDate,
    phone,
    address,
    lastMessageActivityName,
  }: ICaseSearchResult,
  {
    isOpenCase,
    isCloseCase,
    isNextDueDateCase,
    isSentenceCase,
    isCourtCase,
    caseNumber,
    mainAmount: filterMainAmont,
    balance: filterBalance,
    debtorNameNo,
    creditorNameNo,
    birthdate: filterBirthdate,
    phone: filterPhone,
    address: filterAddress,
    lastMsgActivityName,
  }: IFilterValues
): boolean => {
  try {
    return (
      ((isOpenCase && caseType === CaseTypes.OPEN) ||
        (isCloseCase && caseType === CaseTypes.CLOSED) ||
        (isNextDueDateCase && caseType === CaseTypes.NEXT_DUE_DATE) ||
        (isSentenceCase && caseType === CaseTypes.SENTENCE_RECEIVED) ||
        (isCourtCase && caseType === CaseTypes.SENT_TO_COURT) ||
        ![
          CaseTypes.OPEN,
          CaseTypes.CLOSED,
          CaseTypes.NEXT_DUE_DATE,
          CaseTypes.SENTENCE_RECEIVED,
          CaseTypes.SENT_TO_COURT,
        ].includes(caseType)) &&
      (caseNumber ? exCaseNo.toString().includes(caseNumber) : true) &&
      (filterMainAmont ? mainAmount.toString().includes(filterMainAmont) : true) &&
      (filterBalance ? balance.toString().includes(filterBalance) : true) &&
      (debtorNameNo
        ? debtorName.toUpperCase().includes(debtorNameNo.toUpperCase()) ||
          debtorNo === debtorNameNo
        : true) &&
      (creditorNameNo
        ? creditorName.toUpperCase().includes(creditorNameNo.toUpperCase()) ||
          creditorNo.toString() === creditorNameNo 
        : true) &&
      (filterBirthdate ? birthDate === filterBirthdate : true) &&
      (filterPhone ? phone === filterPhone : true) &&
      (filterAddress
        ? address
            .replaceAll(/\s+/g, " ")
            .toUpperCase()
            .includes(filterAddress.replaceAll(/\s+/g, " ").toUpperCase())
        : true) &&
      (lastMsgActivityName
        ? lastMessageActivityName
            .toUpperCase()
            .includes(lastMsgActivityName.toUpperCase())
        : true)
    );
  } catch (e) {
    return false;
  }
};
