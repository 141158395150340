import { SortOrderType } from '../Interfaces';

/**
 * @function
 * @description The function used to sort any type of data in any alphabet
 * @param value1 Comparing value 1
 * @param value2 Comparing value 2
 * @param sortOrder The order of the sorting SortOrderEnum.ASC or SortOrderEnum.DESC
 * @returns The sort result
 */
export const sortFunction = (
	value1: any,
	value2: any,
	sortOrder: SortOrderType
) => {
	if (typeof value1 === 'number' && typeof value2 === 'number') {
		return sortOrder === SortOrderType.ASC ? value1 - value2 : value2 - value1;
	}
	if (value1 !== '' && value2 !== '' && !isNaN(value1) && !isNaN(value2)) {
		return sortOrder === SortOrderType.ASC
			? Number(value1) - Number(value2)
			: Number(value2) - Number(value1);
	}
	return sortOrder === SortOrderType.ASC
		? new Intl.Collator().compare(value1 as string, value2 as string)
		: new Intl.Collator().compare(value2 as string, value1 as string);
};

/**
 * @function
 * @description This function is used to sorts an array of objects by the maximum value of a nested array of objects.
 * @param {Array<any>} payload - Array data for sort.
 * @param {SortOrderType} sortOrder - Sorting direction.
 * @param {string} sortKey - the key to sort by.
 * @param {string} sortArrayKey - key of the object for access nested array.
 * @returns Sorted array of objects.
 */
export const nestedSortFunction = (
	payload: Array<any>,
	sortOrder: SortOrderType,
	sortKey: string,
	sortArrayKey: string = 'arDetails'
) => {
	try {
		if (sortKey === 'creditorNumber') {
			return sortOrder === SortOrderType.ASC
				? payload.sort(
						(value1, value2) =>
							Math.max(
								...value1[sortArrayKey].map((o: any) =>
									Number(o.creditorNumber)
								)
							) -
							Math.max(
								...value2[sortArrayKey].map((o: any) =>
									Number(o.creditorNumber)
								)
							)
				  )
				: payload.sort(
						(value1, value2) =>
							Math.max(
								...value2[sortArrayKey].map((o: any) =>
									Number(o.creditorNumber)
								)
							) -
							Math.max(
								...value1[sortArrayKey].map((o: any) =>
									Number(o.creditorNumber)
								)
							)
				  );
		} else if (sortKey === 'creditorName') {
			return sortOrder === SortOrderType.ASC
				? payload.sort((value1, value2) =>
						[...value1[sortArrayKey]]
							?.sort((a: any, b: any) =>
								a?.creditorName?.localeCompare(b?.creditorName)
							)
							[value1[sortArrayKey]?.length - 1]?.creditorName?.localeCompare(
								[...value2[sortArrayKey]]?.sort((a: any, b: any) =>
									a?.creditorName?.localeCompare(b?.creditorName)
								)[value2[sortArrayKey]?.length - 1]?.creditorName
							)
				  )
				: payload.sort((value1, value2) =>
						[...value2[sortArrayKey]]
							?.sort((a: any, b: any) =>
								a?.creditorName?.localeCompare(b?.creditorName)
							)
							[value2[sortArrayKey]?.length - 1]?.creditorName?.localeCompare(
								[...value1[sortArrayKey]]?.sort((a: any, b: any) =>
									a?.creditorName?.localeCompare(b?.creditorName)
								)[value1[sortArrayKey]?.length - 1]?.creditorName
							)
				  );
		}
		return payload;
	} catch (e) {
		return payload;
	}
};
