import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { IAddNonExecutionDay } from "./Interfaces";
import { dateFormat } from "us.collection.admin/constants";
import { ApplyNonProductionTypes } from "./Components";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { nonProductionDays_New } from "us.collection.admin/actions";
import { maxDate } from "us.collection.admin/constants";
import moment from "moment";
import { ICriteria } from "us.collection.admin/interfaces";
import { getCriteria } from "us.collection.admin/functions";
import { AddOrUpdateNonExecutionDay } from "./Repository";
import { ValidationSchema } from "./Validation";
import { setAllCriteriaTrue } from "./Functions";

const { $Button, $Calendar, $Input, $Switch, $Form } = Common.Components;

const { redDays } = nonProductionDays_New;

/**
 * @description Add Non Execution Day Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const AddNonExecutionDay: React.FC<IAddNonExecutionDay & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const { selectedCriteria, values, onClose, addOrUpdateRedDay } = props;

  /**
   * @function
   * @description save or update red days
   * @param {any} data
   */
  const onSubmit = (data: any) => {
    const requestBody = AddOrUpdateNonExecutionDay.call(data);
    addOrUpdateRedDay && addOrUpdateRedDay(requestBody, selectedCriteria);
    onClose(false);
  };

  /**
   * @function
   * @description set selected date
   * @param { moment.Moment} date
   * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue
   */
  const selectedDate = (
    date: moment.Moment,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue("date", moment(date).format(dateFormat));
  };

  /**
   * @function
   * @description handle all switch change event
   * @param {boolean} value
   * @param {Array<ICriteria>} criteria
   * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue
   */
  const onChange = (
    value: boolean,
    criteria: Array<ICriteria>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    let data: Array<ICriteria> = [];
    try {
      if (value) {
        data = setAllCriteriaTrue(criteria);
      } else {
        data = getCriteria(selectedCriteria);
      }
      setFieldValue("criteria", data);
      setFieldValue("allSwitch", value);
    } catch (error) {}
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...values,
        allSwitch: false,
      }}
      onSubmit={onSubmit}
      validateOnChange
      validateOnBlur
      validationSchema={ValidationSchema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...rest
      }: any) => (
        <>
          <$Form>
            <div className="mb-3">
              <$Input
                label={t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.REASON")}
                name="reason"
                size="small"
                className="w-100"
                required
                dataTestid="addNonExecution-reason"
              />
            </div>
            <div className="mb-n4">
              <div className="ant-col ant-form-item-label">
                <label className="ant-form-item-required">
                  {t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.SELECT_DATE")}
                </label>
              </div>
            </div>
            <div className="inline-calendar">
              <$Calendar
                fullscreen={false}
                onSelect={(date: moment.Moment) =>
                  selectedDate(date, setFieldValue)
                }
                value={
                  moment(values.date).isValid()
                    ? moment(values.date)
                    : moment().add(1, "days")
                }
                validRange={[moment(), moment(maxDate)]}
                disabledDate={(current: moment.Moment) =>
                  current && current < moment().startOf("day")
                }
              />
            </div>
            <div className="mt-5 d-flex flex-1 align-items-top">
              <div className="mr-3">
                <$Switch
                  name="all"
                  checked={values.allSwitch}
                  onChange={(val: boolean) =>
                    onChange(val, values.criteria, setFieldValue)
                  }
                />
              </div>
              <div>{t("US.COMMON:COMMON.ALL")}</div>
            </div>
            <ApplyNonProductionTypes selectedCriteria={selectedCriteria} />
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button type="primary" onClick={handleSubmit}>
                  {t("US.COLLECTION.COMMON:COMMON.ADD_TO_LIST")}
                </$Button>
                <$Button onClick={() => onClose(false)}>
                  {t("US.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {};
};

const mapDispatchToProps = {
  addOrUpdateRedDay: redDays.saveOrUpdate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddNonExecutionDay);
