import React, { useState } from "react";
import {
  $Skeleton,
  $Radio,
  $TableTree,
  IOnFilter,
  IOnSort,
  $Tag,
} from "us.common/components";
import { useTranslation } from "react-i18next";
import { IActivityTable } from "./Interfaces";
import {
  getTableData,
  getGroupsOptions,
  getFilterActivities,
  handleLineSeparator,
} from "./Functions";
import { IActivity } from "us.common/interfaces";
import { getUSCVersionName } from "us.common/functions";

/**
 * @description Activity Execution
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3083632702/Activity+Execution+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 17/08/2022
 */
const ActivityTable: React.FC<IActivityTable> = (props) => {
  const { t } = useTranslation();
  const { onClose, dataSource, groupingProperty, handleSelect } = props;
  const [filterId, setFilterId] = useState<number | string>(0);
  const { groupingValue, tableGrouping } = groupingProperty;

  const columns: any = [
    {
      title: "",
      dataIndex: "group",
      key: "group",
      className: "group-td",
      width: 24,
      customRenderParent: (text: any, record: any, index: number) => {
        return {
          children: text,
          key: index,
          props: {
            colSpan: 4,
          },
        };
      },
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <></>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.CODE"),
      dataIndex: "activityCode",
      key: "activityCode",
      width: 120,
      className: "text-nowrap",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.activityCode}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.NAME"),
      dataIndex: "displayName",
      key: "displayName",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        const {displayName, isVersionVisible, activityVersion} = record ?? {}
        return {
          children: <div className='d-flex align-items-center justify-content-between' >
                      <span>{displayName}</span> 
                      {isVersionVisible && (
                        <$Tag className={`ml-2 tag-version-${activityVersion}`}>{`${getUSCVersionName(activityVersion)}`}</$Tag>
                      )}
                    </div>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
    {
      title: t("US.COMMON:ACTIVITY_EXECUTION.DESCRIPTION"),
      dataIndex: "description",
      key: "description",
      className: "text-nowrap",
      ellipsis: true,
      customSorter: (a: any, b: any) => a.localeCompare(b),
      customFilter: true,
      customRenderChild: (text: any, record: any, index: number) => {
        return {
          children: <span>{record?.description}</span>,
          key: index,
          props: {
            colSpan: 1,
          },
        };
      },
    },
  ];

  /**
   * @function
   * @description handle activity selection
   * @param {string} displayName
   */
  const onSelectedActivity = (record: IActivity): void => {
    const { displayName, activityVersion,activityId} = record ?? {}
    try {
      handleSelect(`${displayName}_${activityVersion}_${activityId}`, record);
      onClose();
    } catch (error) {}
  };

  /**
   * @function
   * @description handle table data sorting
   * @param {(data:Array<any>)=>Array<any>} sortData
   * @param {Array<any>} dataSource
   * @returns {IOnSort}
   */
  const handleSort: IOnSort = (sortData, dataSource) => {
    try {
      return sortData(
        dataSource.map((i: any) => {
          return {
            ...i,
            children: sortData(i.children),
          };
        })
      );
    } catch (error) {
      return [];
    }
  };

  /**
   * @function
   * @description handle table data sorting
   * @param {(data:Array<any>)=>Array<any>} searchData
   * @param {Array<any>} dataSource
   * @returns {IOnFilter}
   */
  const handleFilter: IOnFilter = (searchData, dataSource) => {
    let result: any[] = [];
    try {
      dataSource.flatMap((i: any) => {
        searchData(i?.children)?.length != 0 &&
          result.push({
            ...i,
            children:
              searchData(i?.children)?.length == 0
                ? []
                : searchData(i?.children),
          });
      });
      return result;
    } catch (error) {
      return result;
    }
  };

  return (
    <div className="d-flex select-activity-body">
      <div className={`activity-group ${handleLineSeparator(dataSource)}`}>
        <p>
          <strong>{t("US.COMMON:ACTIVITY_EXECUTION.ACTIVITY_GROUP")}</strong>
        </p>
        <div>
          <$Radio
            name="filter"
            optionText="label"
            optionValue="value"
            className=""
            size="small"
            value={filterId}
            buttonStyle="solid"
            optionStyle=""
            options={getGroupsOptions(dataSource, groupingProperty)}
            onChange={(e: any) => {
              setFilterId(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex-grow-1 activity-list">
        <$Skeleton loading={false} active paragraph={{ rows: 2 }}>
          <$TableTree
            onRow={(record: any, rowIndex: any) => {
              return {
                onDoubleClick: (event: any) => {
                  onSelectedActivity(record);
                },
              };
            }}
            rowKey={(record: any) => {
              return record.key;
            }}
            onSort={handleSort}
            onFilter={handleFilter}
            filterOnType={true}
            data={getTableData(
              getFilterActivities(dataSource, filterId, groupingValue),
              tableGrouping
            )}
            columns={columns}
            size="small"
            className="activity-table"
            bordered
            pagination={{ defaultPageSize: 20 }}
            scroll={{ x: 780, y: "calc(100vh - 170px)" }}
            defaultExpandAllRows={true}
            resetOnSourceChange={true}
          />
        </$Skeleton>
      </div>
    </div>
  );
};

export default ActivityTable;
