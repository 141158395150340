import { SettingsTableColumn } from '../Constants';
import { SettingsTableColumnType } from '../Interfaces';

/**
 * @function
 * @description Get width of the column by given column key
 * @param {SettingsTableColumnType} key - Column key
 * @returns {string} width(px) of the column
 */
export const getColumnWidth = (key: SettingsTableColumnType): string => {
	switch (key) {
		case SettingsTableColumn.MENU:
			return '22px';
		case SettingsTableColumn.NAME:
			return '120px';
		case SettingsTableColumn.VALUE:
		case SettingsTableColumn.DESCRIPTION:
			return '200px';
		default:
			return '';
	}
};
