import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { manageFollowupActions } from "us.collection.admin/actions";
import { IRootState } from "us.collection/interfaces";
import { $Empty, $Skeleton, $TableTree } from "us.common/components";
import { FollowupUniqueCol } from "us.collection.admin/constants";
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";
import { parse } from "query-string";
const { followupResult } = manageFollowupActions;

/**
 * @description Manage Followup
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942926869/Manage+Follow-up+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 04/04/2022
 */
export const FollowupResult: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { getFollowupResult, followupResults } = props;
  const { columns, result, isFetching } = followupResults;
  const searchParams = parse(location.search);
  const followupId: string = searchParams["followup-id"] as string;

  useEffect(() => {
    followupId && getFollowupResult && getFollowupResult({ followupId });
  }, [followupId]);

  /**
   * @function
   * @description Table data sort
   * @param sortData
   * @param {Array<any>} dataSource - table data
   * @returns sorted data
   */
  const handleSort: IOnSort = (sortData, dataSource) => {
    return sortData(dataSource);
  };

  /**
   * @function
   * @description Table data filter
   * @param sortData
   * @param {Array<any>} dataSource - table data
   * @returns filtered data
   */
  const handleFilter: IOnFilter = (searchData, dataSource) => {
    return searchData(dataSource);
  };
  return (
    <$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
      {result.length === 0 && !isFetching && (
        <$Empty
          className="pt-5"
          description={`${t("US.COLLECTION.ADMIN:BULKOPERATION.NO_DATA")}`}
        />
      )}
      {result.length > 0 && columns.length > 0 && !isFetching && (
        <$TableTree
          rowKey={FollowupUniqueCol.FOLLOWUP_ID}
          columns={columns}
          data={result}
          size="small"
          className="followup-result-table"
          onSort={handleSort}
          onFilter={handleFilter}
          filterOnType={true}
          resetOnSourceChange={true}
          bordered
          pagination={{ defaultPageSize: 100 }}
          scroll={{ x: 600, y: "calc(100vh - 260px)" }}
          firstColSkipFilterProps={-1}
          data-testid="manage-followup-result"
          onRow={(record: any, rowIndex: any) => {
            return {
              onDoubleClick: (event: any) => {},
            };
          }}
        />
      )}
    </$Skeleton>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { followupResults } = manageFollowups;
  return { followupResults };
};

const mapDispatchToProps = {
  getFollowupResult: followupResult.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FollowupResult);
