export enum SearchByValues {
  DEBTOR_NO = "Debtor Number:",
  DEBTOR_NAME = "Debtor Name:",
  OTHER_PARTY_NO = "Other Party Number:",
  OTHER_PARTY_NAME = "Other Party Name:",
}

export enum SearchFields {
  DEBTOR_NUMBER = "Debtor Number",
  DEBTOR_NAME = "Debtor Name",
  OTHER_PARTY_NUMBER = "Other Party Number",
  OTHER_PARTY_NAME = "Other Party Name",
  NUMBER = "Number",
  NAME = "Name",
  ALL_ENTITY = "AllEntity",
  OTEHR_PARTY_FREE_TEXT = "Other Party FreeText"
}

export const searchOption = [
  {
    label: SearchByValues.DEBTOR_NAME,
    value: SearchByValues.DEBTOR_NAME,
  },
  {
    label: SearchByValues.DEBTOR_NO,
    value: SearchByValues.DEBTOR_NO,
  },
  {
    label: SearchByValues.OTHER_PARTY_NAME,
    value: SearchByValues.OTHER_PARTY_NAME,
  },
  {
    label: SearchByValues.OTHER_PARTY_NO,
    value: SearchByValues.OTHER_PARTY_NO,
  },
];
