import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from 'us.common/services';
import { Entity } from 'us.common/constants/Entity';
import * as Action from 'us.common/actions';
import _ from 'lodash';
import { $Message } from 'us.common/components';
import { $MessageBox } from 'us.common/components';

const { entity } = Action;

const entitySagas = {
	get: function* (action: any): any {
		const params = action.payload;
		try {
			const data = yield call(API.entityDetails.getEntityDetail, params);
			yield put(entity.getSuccess(data));
		} catch (error) { }
	},
	getPostalAreas: function* (action: any): any {
		const params = action.payload?.data;
		try {
			const data = yield call(API.entityDetails.getPostelArea, params);
			if (!_.isEmpty(data)) {
				yield put(entity.getPostalAreasSuccess(data));
			} else {
				yield put(entity.getPostalAreasFail([]));
			}
		} catch (error) { }
	},
	getMunicipals: function* (action: any): any {
		const params = action.payload.data;
		try {
			const data = yield call(API.entityDetails.getMunicipals, params);
			if (data.errors) {
				yield put(entity.getMunicipalsFail([]));
			} else if (Array.isArray(data)) {
				yield put(entity.getMunicipalsSuccess(data));
			} else {
				yield put(entity.getMunicipalsFail([]));
			}
		} catch (error) {
			yield put(entity.getMunicipalsFail([]));
		}
	},
	addPostalArea: function* (action: any): any {
		const params = action.payload.data;
		try {
			const data = yield call(API.entityDetails.addPostalArea, params);
			if (data?.hasOwnProperty('postalCodeId')) {
				$MessageBox(
					'success',
					'US.COLLECTION.COMMON:ENTITYSELECTION.SUCCESSFULLY_ADDED',
					'',
					''
				)
				yield put(entity.addPostalAreaSuccess(data));
			} else if (data?.message?.includes('postalCode is Already Exists')) {
				yield put(entity.addPostalAreaFail({}));
				$MessageBox(
					'error',
					'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE_IS_ALREADY_EXIXTS',
					'',
					''
				)
			} else {
				yield put(entity.addPostalAreaFail({}));
				$MessageBox(
					'error',
					'US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE_REGISTRATION_FAIL',
					'',
					''
				)
			}
		} catch (error) {
			yield put(entity.addPostalAreaFail(error));
		}
	},

	postalcodeAvailability: {
		get: function* (action: any): any {
			const params = action.payload?.data;
			try {
				const data = yield call(API.entityDetails.getPostelArea, params);
				if (!_.isEmpty(data)) {
					yield put(entity.postalCodeAvailability.success(data))
				} else {
					yield put(entity.postalCodeAvailability.fail({}))
				}
			} catch (error) {
				yield put(entity.postalCodeAvailability.fail(error));
			}
		}
	}
};

export default [
	// takeEvery('GET_POSTAL_AREAS', getPostalAreas),
	//takeEvery('GET_MUNICIPALS', getMunicipals),
	//takeEvery('ADD_POSTAL_AREA', addPostalArea),

	takeLatest(Entity.GET_ENTITY_DETAIL_START, entitySagas.get),
	takeLatest(Entity.GET_POSTAL_AREAS, entitySagas.getPostalAreas),
	takeLatest(Entity.GET_MUNICIPALS, entitySagas.getMunicipals),
	takeLatest(Entity.ADD_POSTAL_AREAS, entitySagas.addPostalArea),

	takeLatest(Entity.GET_POSTAL_CODE_AVAILABILITY_START, entitySagas.postalcodeAvailability.get),
];
