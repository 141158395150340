import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'us.helper/types';
import { $Skeleton, $Tree, $Search, $Empty } from 'us.common/components';
import { manageActivities } from 'us.collection.admin/actions';
import { useFormikContext } from 'formik';
import { setTreeDataSource } from 'us.collection.admin/functions';
import { IActivityState } from 'us.collection.admin/interfaces';
import { searchStates, removeUncheckedStates } from './Functions';
import _, { isArray } from 'lodash';

const { activity } = manageActivities;

/**
 * @description Activity States Step of Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 08/06/2022
 */
const ActivityStates: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation();
	const { setFieldValue } = useFormikContext() as any;
	const { states, stepDetail } = props;
	const { formValues } = stepDetail;
	const { isFetching, data } = states;
	const { states: statesValue } = formValues.modifyStateValue;
	const [dataSource, setDataSource] = useState<Array<IActivityState>>(data);
	const [checkState, setCheckState] =
		useState<Array<string | number>>(statesValue);

	/**
	 * @function
	 * @description handle onCheck event
	 * @param {React.Key[]} checkedKeysValue check key array
	 */
	const onCheck = (checkedKeysValue: React.Key[], info: any) => {
		try {
			const { checked, node } = info;
			let keys: any = [];
			if (checked) {
				keys = _.uniq([...checkState, node.key]);
			} else {
				keys = removeUncheckedStates(data, checkState, node.key);
			}
			if (isArray(keys)) {
				setFieldValue('states', keys);
				setCheckState(keys);
				return keys;
			} else {
				return checkState;
			}
		} catch (error) {
			return checkState;
		}
	};

	/**
	 * @function
	 * @description handle onSearch
	 * @param {string} searchText search text
	 */
	const onSearch = (searchText: string) => {
		setDataSource(searchStates(data, searchText));
	};

	return (
		<div className='ad-layout-content'>
			<div className='modify-states'>
				<div className='modify-states-header'>
					<div>
						<$Search
							name='code'
							onSearch={(value: string) => onSearch(value)}
							onChange={(e: any) => onSearch(e.target.value)}
							enterButton
							className='w-100'
							size='small'
							allowClear={true}
						/>
					</div>
					<div className='my-3'>
						<strong>
							{t('US.COLLECTION.ADMIN:ACTIVITIES.ACTIVITY_STATE')}
						</strong>
					</div>
				</div>
				<div className='modify-states-body'>
					<$Skeleton loading={isFetching} active paragraph={{ rows: 2 }}>
						{dataSource.length > 0 && (
							<$Tree
								checkable
								checkedKeys={checkState}
								onCheck={(e: any, info: any) => onCheck(e, info)}
								treeData={setTreeDataSource(dataSource)}
								defaultExpandAll
								defaultCheckedKeys={statesValue}
								data-testid='state-tree'
							/>
						)}
						{dataSource.length == 0 && (
							<div className='p-3'>
								<$Empty
									description={`${t('US.COMMON:COMMON.NO_DATA')}`}
									image={$Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						)}
					</$Skeleton>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { manageActivities } = state;
	const { states, stepDetail } = manageActivities;

	return { states, stepDetail };
};
const mapDispatchToProps = {
	setStepDetail: activity.setStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivityStates);
