import { initialState } from "us.collection.admin/reducers/CreditorGroup/State";
import { CreditorGroup } from "us.collection.admin/constants/Actions";
import _ from "lodash";

const {
  GET_CREDITOR_GROUPS_IN_BUREAU,
  GET_CREDITOR_GROUPS_IN_BUREAU_SUCCESS,
  GET_CREDITOR_GROUPS_IN_BUREAU_FAIL,
  SAVE_CREDITOR_GROUP_IN_BUREAU,
  SAVE_CREDITOR_GROUP_IN_BUREAU_SUCCESS,
  SAVE_CREDITOR_GROUP_IN_BUREAU_FAIL,
  UPDATE_CREDITOR_GROUP_IN_BUREAU,
  SELECT_CREDITOR_GROUP,
  RESET_SELECTED_CREDITOR,
  MANAGE_CREDITOR_GROUP_DRAWER,
  GET_CREDITORS_BY_GROUP_ID,
  GET_CREDITORS_BY_GROUP_ID_SUCCESS,
  GET_CREDITORS_BY_GROUP_ID_FAIL,
} = CreditorGroup;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_CREDITOR_GROUPS_IN_BUREAU:
      return Object.assign({}, state, {
        creditorGroups: {
          data: [],
          isFetching: true,
        },
      });

    case GET_CREDITOR_GROUPS_IN_BUREAU_SUCCESS:
      return Object.assign({}, state, {
        creditorGroups: {
          data: _.orderBy(payload?.data, "id", "desc"),
          isFetching: false,
        },
      });

    case GET_CREDITOR_GROUPS_IN_BUREAU_FAIL:
      return Object.assign({}, state, {
        creditorGroups: {
          data: [],
          isFetching: false,
        },
      });

    case SAVE_CREDITOR_GROUP_IN_BUREAU:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: payload?.data,
          isFetching: true,
        },
      });

    case SAVE_CREDITOR_GROUP_IN_BUREAU_SUCCESS:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: { ...initialState.creditorGroupDetails.data },
          isFetching: false,
        },
      });

    case SAVE_CREDITOR_GROUP_IN_BUREAU_FAIL:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: { ...state.creditorGroupDetails },
          isFetching: false,
        },
      });

    case UPDATE_CREDITOR_GROUP_IN_BUREAU:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: payload?.data,
          isFetching: true,
        },
      });

    case SELECT_CREDITOR_GROUP:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: { ...payload?.data },
          isFetching: false,
        },
      });

    case RESET_SELECTED_CREDITOR:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: { ...initialState.creditorGroupDetails.data },
          isFetching: false,
        },
      });

    case MANAGE_CREDITOR_GROUP_DRAWER:
      return Object.assign({}, state, {
        drawer: payload?.data,
      });

    case GET_CREDITORS_BY_GROUP_ID:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: {},
          isFetching: true,
        },
        selectedCreditors: {
          data: [],
          isFetching: true,
        },
      });

    case GET_CREDITORS_BY_GROUP_ID_SUCCESS:
      const { creditors, ...groupDetails } = payload?.data;
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: groupDetails,
          isFetching: false,
        },
        selectedCreditors: {
          data: creditors.map((creditor: any) => {
            if (creditor["nin"] == null) {
              creditor.nin = "";
            }
            creditor.address =
              (creditor?.address1 ? `${creditor?.address1}, ` : ``) +
              (creditor?.address2 ? `${creditor?.address2}, ` : ``) +
              (creditor?.address3 ? `${creditor?.address3}, ` : ``) +
              (creditor?.zipCode ? `${creditor?.zipCode}-` : ``) +
              (creditor?.zipName ? `${creditor?.zipName}` : ``);
            return creditor;
          }),
          isFetching: false,
        },
      });

    case GET_CREDITORS_BY_GROUP_ID_FAIL:
      return Object.assign({}, state, {
        creditorGroupDetails: {
          data: {},
          isFetching: false,
        },
        selectedCreditors: {
          data: [],
          isFetching: false,
        },
      });

    default:
      return state;
  }
};
