import { Handlers } from "us.collection/constants";
import { IHandlerData } from "../Interfaces";

/**
 * Get handler name
 * @param state handler state
 * @param payloadData action payload
 * @returns handler state
 */
export const getHandlerName = (
  state: IHandlerData,
  payloadData: any
): IHandlerData => {
  try {
    switch (payloadData.type) {
      case Handlers.CASE:
        return {
          ...state,
          case: {
            ...state?.case,
            name: payloadData.caseHandlerName,
          },
        };
      case Handlers.COURT:
        return {
          ...state,
          court: {
            ...state?.court,
            name: payloadData.courtHandlerName,
          },
        };
      default:
        return state;
    }
  } catch (error) {
    console.log(error);
    return state;
  }
};

/**
 * Get Handler List
 * @param state handler state
 * @param payloadData action payload
 * @returns handler state
 */
export const getHandlerList = (
  state: IHandlerData,
  payloadData: any
): IHandlerData => {
  try {
    switch (payloadData?.dataType) {
      case Handlers.HANDLER_NAMES:
        return {
          ...state,
          case: {
            ...state?.case,
            name:
              payloadData.caseHandler.length > 0
                ? payloadData.caseHandler
                : Handlers.NOT_ASSIGNED,
          },
          court: {
            ...state?.court,
            name:
              payloadData.courtHandler.length > 0
                ? payloadData.courtHandler
                : Handlers.NOT_ASSIGNED,
          },
        };
      case Handlers.CASE_HANDLER_LIST:
        payloadData.list.push({
          caseHandlerId: -1,
          caseHandlerName: Handlers.NOT_ASSIGNED,
          externalUserName: "",
        });
        return {
          ...state,
          case: {
            ...state?.case,
            list: payloadData.list,
          },
        };
      case Handlers.COURT_HANDLER_LIST:
        payloadData.list.push({
          courtHandlerId: -1,
          courtHandlerName: Handlers.NOT_ASSIGNED,
          externalUserName: "",
        });
        return {
          ...state,
          court: {
            ...state?.court,
            list: payloadData.list,
          },
        };
      default:
        return state;
    }
  } catch (error) {
    console.log(error);
    return state;
  }
};
