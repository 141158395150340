import { takeLatest, put, call } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { CreditorGroup } from "us.collection.admin/constants/Actions";
import { $Message } from "us.common/components";

const { creditorGroup } = Actions;

const CreditorGroupSagas = {
  creditorGroups: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.creditorGroup.creditorGroups.get, params);
        if (
          data?.errors ||
          data?.error ||
          data?.errorType ||
          data?.code ||
          !Array.isArray(data)
        ) {
          $Message.error(`${data?.error ? data?.error : data?.errors?.map((err:any) => `${err.error} `)}`);
          yield put(
            creditorGroup.creditorGroups.fail(
              data?.error ? data?.error : data?.errorType
            )
          );
        } else {
          yield put(creditorGroup.creditorGroups.success(data));
        }
      } catch (error) {
        yield put(creditorGroup.creditorGroups.fail(error));
        console.log(error);
      }
    },
  },
  creditorGroup: {
    save: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.creditorGroup.creditorGroup.save, params);
        if (data?.errors || data?.error || data?.errorType || data?.code) {
          $Message.error(
            `${
              data?.error ? data?.error : data?.errors?.map((err:any) => `${err.error}. `)
            }`
          );
          yield put(
            creditorGroup.creditorGroup.fail(
              data?.error ? data?.error : data?.errorType
            )
          );
        } else {
          $Message.success(`Successfully added.`);
          yield put(creditorGroup.creditorGroup.success(data));
          if (creditorGroup.drawer.set) {
            yield put(
              creditorGroup.drawer.set({
                title: "",
                visible: false,
                isNewCreditorGroup: true,
              })
            );
          }
          if (creditorGroup.creditorGroups.get) {
            yield put(creditorGroup.creditorGroups.get({}));
          }
        }
      } catch (error) {
        yield put(creditorGroup.creditorGroup.fail(error));
        console.log(error);
      }
    },
    update: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.creditorGroup.creditorGroup.update, params);
        if (data?.errors || data?.error || data?.errorType || data?.code) {
          $Message.error(
            `${
              data?.error ? data?.error : data?.errors?.map((err:any) => `${err.error} `)
            }`
          );
          yield put(
            creditorGroup.creditorGroup.fail(
              data?.error ? data?.error : data?.errorType
            )
          );
        } else {
          yield put(creditorGroup.creditorGroup.success(data));
          $Message.success(`Edited successfully.`);
          if (creditorGroup.drawer.set) {
            yield put(
              creditorGroup.drawer.set({
                title: "",
                visible: false,
                isNewCreditorGroup: true,
              })
            );
          }
          if (creditorGroup.creditorGroups.get) {
            yield put(creditorGroup.creditorGroups.get({}));
          }
        }
      } catch (error) {
        yield put(creditorGroup.creditorGroup.fail(error));
        console.log(error);
      }
    },
    delete: function* (action: any) {
      const params = action?.payload?.data ?? -1;
      try {
        const data = yield call(API.creditorGroup.creditorGroup.delete, params);
        if (data?.errors || data?.error || data?.errorType || data?.code) {
          $Message.error(data?.error ? data?.error : data?.errors?.map((err:any) => `${err.error} `));
          if (creditorGroup.creditorGroup.deleteFail) {
            yield put(
              creditorGroup.creditorGroup.deleteFail(
                data?.error ? data?.error : data?.errorType
              )
            );
          }
        } else {
          $Message.success(`Successfully deleted.`);
          if (creditorGroup.creditorGroup.deleteSuccess) {
            yield put(creditorGroup.creditorGroup.deleteSuccess(data));
          }
          if (creditorGroup.creditorGroups.get) {
            yield put(creditorGroup.creditorGroups.get({}));
          }
        }
      } catch (error) {
        if (creditorGroup.creditorGroup.deleteFail) {
          yield put(creditorGroup.creditorGroup.deleteFail(error));
        }
        console.log(error);
      }
    },
  },
  selectedCreditors: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.creditorGroup.selectedCreditors.get, params);
        if (
          data?.errors ||
          data?.error ||
          data?.errorType ||
          data?.code ||
          data?.statusCode == 404
        ) {
          $Message.error(`${data?.error ? data?.error : data?.errors?.map((err:any) => `${err.error} `)}`);
          yield put(
            creditorGroup.selectedCreditors.fail(
              data?.error ? data?.error : data?.errorType
            )
          );
        } else {
          yield put(creditorGroup.selectedCreditors.success(data));
        }
      } catch (error) {
        yield put(creditorGroup.selectedCreditors.fail(error));
        console.log(error);
      }
    },
  },
};

export default [
  takeLatest(
    CreditorGroup.GET_CREDITOR_GROUPS_IN_BUREAU,
    CreditorGroupSagas.creditorGroups.get
  ),
  takeLatest(
    CreditorGroup.SAVE_CREDITOR_GROUP_IN_BUREAU,
    CreditorGroupSagas.creditorGroup.save
  ),
  takeLatest(
    CreditorGroup.UPDATE_CREDITOR_GROUP_IN_BUREAU,
    CreditorGroupSagas.creditorGroup.update
  ),
  takeLatest(
    CreditorGroup.DELETE_CREDITOR_GROUP_IN_BUREAU,
    CreditorGroupSagas.creditorGroup.delete
  ),
  takeLatest(
    CreditorGroup.GET_CREDITORS_BY_GROUP_ID,
    CreditorGroupSagas.selectedCreditors.get
  ),
];
