import { httpCollection } from "us.helper/http/collection";
import { INITIAL_FORM_VALUES } from "us.collection.admin/components/Municipalities/Constants";

export default {
  search: {
    init: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const municipalityList = await httpCollection.get(
            "entityservice",
            `entities/municipalities?countryCode=&searchText=`,
            {}
          );

          const countries = await httpCollection.get(
            "entityservice",
            "countries",
            {}
          );

          resolve({
            municipalityList,
            countries,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: ({ municipalityId }: { municipalityId: number }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.delete(
            "entityservice",
            `entities/municipalities`,
            municipalityId
          );
          const municipalityList = await httpCollection.get(
            "entityservice",
            `entities/municipalities?countryCode=&searchText=`,
            {}
          );

          resolve({
            status,
            deleteData: data,
            municipalityList: municipalityList.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  detail: {
    save: ({
      saveParams,
    }: {
      saveParams: typeof INITIAL_FORM_VALUES;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const saveMunicipality = await httpCollection.post(
            "entityservice",
            "entities/municipalities",
            saveParams
          );
          const municipalityList = await httpCollection.get(
            "entityservice",
            "entities/municipalities?countryCode=&searchText=",
            {}
          );

          setTimeout(() => {
            resolve({
              saveMunicipality,
              municipalityList,
            });
          }, 1500);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
