import { User } from "us.common/constants";
import {
  deleteExistingUser,
  setUserActivityStatusAsAString,
  updateStateExistingUser,
  getAzureADOptions,
  getUserRolesWithSelectedStatus,
  updateExistingUser,
} from "us.common/functions";
import { aDUserDetail, initialState, userData } from "./State";

const {
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  USER_STATE_CHANGE_SUCCESS,
  DELETE_USER_SUCCESS,
  MANAGE_USER_DRAWER,
  GET_AZURE_AD_USERS_START,
  GET_AZURE_AD_USERS_SUCCESS,
  GET_AZURE_AD_USERS_FAIL,
  GET_AUTHORIZATION_LEVEL_START,
  GET_AUTHORIZATION_LEVEL_SUCCESS,
  GET_AUTHORIZATION_LEVEL_FAIL,
  GET_INIT_USER_START,
  GET_INIT_USER_SUCCESS,
  GET_INIT_USER_FAIL,
  GET_CREDITOR_GROUP_START,
  GET_CREDITOR_GROUP_SUCCESS,
  GET_CREDITOR_GROUP_FAIL,
  GET_CREDITOR_BY_COMPANY_ID_START,
  GET_CREDITOR_BY_COMPANY_ID_SUCCESS,
  GET_CREDITOR_BY_COMPANY_ID_FAIL,
  GET_CREDITOR_COMPANY_START,
  GET_CREDITOR_COMPANY_SUCCESS,
  GET_CREDITOR_COMPANY_FAIL,
  SELECT_AD_USER,
  RESET_USER_FORM,
  GET_AZURE_AD_USER_BY_ID_START,
  GET_AZURE_AD_USER_BY_ID_SUCCESS,
  GET_AZURE_AD_USER_BY_ID_FAIL,
  GET_USER_BY_ID_START,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_HISTORY_BY_ID_START,
  GET_USER_HISTORY_BY_ID_SUCCESS,
  GET_USER_HISTORY_BY_ID_FAIL,
  UPDATE_USER_SUCCESS,
} = User;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_USERS_START:
      return Object.assign({}, state, {
        users: {
          data: [],
          isLoading: true,
        },
      });
    case GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        users: {
          data: setUserActivityStatusAsAString(payload?.data),
          isLoading: false,
        },
      });
    case GET_USERS_FAIL:
      return Object.assign({}, state, {
        users: {
          data: [],
          isLoading: false,
        },
      });
    case USER_STATE_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        users: {
          data: setUserActivityStatusAsAString(
            updateStateExistingUser(state.users.data, payload?.data)
          ),
          isLoading: false,
        },
      });
    case DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        users: {
          data: setUserActivityStatusAsAString(
            deleteExistingUser(state.users.data, payload?.data)
          ),
          isLoading: false,
        },
      });
    case MANAGE_USER_DRAWER:
      return Object.assign({}, state, {
        drawer: payload?.data,
      });
    case GET_AZURE_AD_USERS_START:
      return Object.assign({}, state, {
        adUsers: {
          data: [],
          optionsData: [],
          isLoading: true,
        },
      });
    case GET_AZURE_AD_USERS_SUCCESS:
      return Object.assign({}, state, {
        adUsers: {
          data: payload.data,
          optionsData: getAzureADOptions(payload.data, state.users?.data),
          isLoading: false,
        },
      });
    case GET_AZURE_AD_USERS_FAIL:
      return Object.assign({}, state, {
        adUsers: {
          data: [],
          optionsData: [],
          isLoading: false,
        },
      });
    case GET_CREDITOR_GROUP_START:
      return Object.assign({}, state, {
        creditorGroups: {
          data: [],
          isLoading: true,
        },
      });
    case GET_CREDITOR_GROUP_SUCCESS:
      return Object.assign({}, state, {
        creditorGroups: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_CREDITOR_GROUP_FAIL:
      return Object.assign({}, state, {
        creditorGroups: {
          data: [],
          isLoading: false,
        },
      });
    case GET_INIT_USER_START:
      return Object.assign({}, state, {
        roles: {
          data: [],
          isLoading: true,
        },
        authorizationLevel: {
          data: [],
          isLoading: true,
        },
        creditorGroups: {
          data: [],
          isLoading: true,
        },
      });
    case GET_INIT_USER_SUCCESS:
      return Object.assign({}, state, {
        roles: {
          data: getUserRolesWithSelectedStatus(payload.data),
          isLoading: false,
        },
      });
    case GET_INIT_USER_FAIL:
      return Object.assign({}, state, {
        roles: {
          data: [],
          isLoading: false,
        },
      });
    case GET_AUTHORIZATION_LEVEL_START:
      return Object.assign({}, state, {
        authorizationLevel: {
          data: [],
          isLoading: true,
        },
      });
    case GET_AUTHORIZATION_LEVEL_SUCCESS:
      return Object.assign({}, state, {
        authorizationLevel: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_AUTHORIZATION_LEVEL_FAIL:
      return Object.assign({}, state, {
        authorizationLevel: {
          data: [],
          isLoading: false,
        },
      });
    case GET_CREDITOR_COMPANY_START:
      return Object.assign({}, state, {
        creditorCompany: {
          data: [],
          isLoading: true,
        },
      });
    case GET_CREDITOR_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        creditorCompany: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_CREDITOR_COMPANY_FAIL:
      return Object.assign({}, state, {
        creditorCompany: {
          data: [],
          isLoading: false,
        },
      });
    case GET_CREDITOR_BY_COMPANY_ID_START:
      return Object.assign({}, state, {
        creditors: {
          data: [],
          isLoading: true,
        },
      });
    case GET_CREDITOR_BY_COMPANY_ID_SUCCESS:
      return Object.assign({}, state, {
        creditors: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_CREDITOR_BY_COMPANY_ID_FAIL:
      return Object.assign({}, state, {
        creditors: {
          data: [],
          isLoading: false,
        },
      });
    case SELECT_AD_USER:
      return Object.assign({}, state, {
        aDUser: {
          data: payload.data.aDDetail,
          isLoading: false,
        },
        user: {
          data: payload.data.userDetail,
          isLoading: false,
        },
      });
    case RESET_USER_FORM:
      return Object.assign({}, state, {
        authorizationLevel: {
          data: [],
          isLoading: false,
        },
        creditorGroups: {
          data: [],
          isLoading: false,
        },
        creditors: {
          data: [],
          isLoading: false,
        },
        creditorCompany: {
          data: [],
          isLoading: false,
        },
        aDUser: {
          data: aDUserDetail,
          isLoading: false,
        },
        user: {
          data: userData,
          isLoading: false,
        },
      });
    case GET_USER_BY_ID_START:
      return Object.assign({}, state, {
        user: {
          data: userData,
          isLoading: true,
        },
      });
    case GET_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        user: {
          data: {
            ...payload.data,
            roles: getUserRolesWithSelectedStatus(payload.data?.roles, true),
          },
          isLoading: false,
        },
      });
    case GET_USER_BY_ID_FAIL:
      return Object.assign({}, state, {
        user: {
          data: userData,
          isLoading: false,
        },
      });
    case GET_AZURE_AD_USER_BY_ID_START:
      return Object.assign({}, state, {
        aDUser: {
          data: aDUserDetail,
          isLoading: true,
        },
      });
    case GET_AZURE_AD_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        aDUser: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case GET_AZURE_AD_USER_BY_ID_FAIL:
      return Object.assign({}, state, {
        aDUser: {
          data: aDUserDetail,
          isLoading: false,
        },
      });
    case GET_USER_HISTORY_BY_ID_START:
      return Object.assign({}, state, {
        userHistory: {
          data: [],
          isLoading: true,
        },
      });
    case GET_USER_HISTORY_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        userHistory: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case GET_USER_HISTORY_BY_ID_FAIL:
      return Object.assign({}, state, {
        userHistory: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
