import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BoxIcons, HomeOutlined, IconTypes } from "us.icons";
import { $Divider, $Tooltip, $Button, $Skeleton } from "us.common/components";
import * as Azure from "us.helper/azure";
import packageJson from "../../../../../../../package.json";
import { IOperation, INavigationData } from "../../interface";
import { getRouteUrl } from "us.helper";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "us.helper/types";

interface IMegaMenuCommonViewProps {
  quickLinks: any[];
  unpin: (operation: IOperation) => void;
  onNavigate: (url: string, state?: INavigationData) => void;
}

const CommonView: React.FC<IMegaMenuCommonViewProps> = ({
  quickLinks,
  unpin,
  onNavigate,
}) => {
  const { t } = useTranslation(["US.COLLECTION.COMMON"], {
    useSuspense: true,
  });
  const { state: locationState } = useLocation();
  const state = locationState as INavigationData;
  const [selectedQuickLink, setSelectedQuickLink] =
    useState<IOperation | null>();

  const { isSaving, isLoading } = useSelector(
    (state: RootState) => state.UserInformation.quickLinks
  );

  const isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth: any;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }
  const authentication = auth;

  const onLogout = () => {
    localStorage.removeItem("@quickLinks");
    authentication.logout();
  };

  // handle quick links navigation
  const handleQuickLinks = (operation: IOperation) => {
    const { route, module } = operation;

    if (window.APP_MODULE === module && route) {
      onNavigate(route, state);
    } else {
      getRouteUrl.moduleRoute(module, route);
    }
  };

  // hadle static menu item in the left list of the menu
  const handleStaticMenuItemNavigation = (
    url: string,
    moduleName: string,
    state?: INavigationData
  ) => {
    if (window.APP_MODULE === moduleName) {
      onNavigate(url, state);
    } else {
      getRouteUrl.moduleRoute(moduleName, url);
    }
  };

  return (
    <div className="mega-menu-wrapper-fixed-left d-flex flex-column justify-content-between">
      <div className="mt-3 quick-link-wrap">
        <a
          href={window._ENV.REACT_APP_HOME_URL}
          tabIndex={-1}
          className="d-flex align-items-center justify-content-start quick-link-item"
        >
          <span className="mg-menu-icon-outer">
            <HomeOutlined />
          </span>
          <span>
            <a tabIndex={-1}>{t("US.COMMON:COMMON.HOME")}</a>
          </span>
        </a>
        <div
          className="d-flex align-items-center justify-content-start quick-link-item"
          tabIndex={-1}
          onClick={() => getRouteUrl.moduleRoute("collection", "/follow-up")}
        >
          <span className="mg-menu-icon-outer">
            <BoxIcons type={IconTypes.BOX_ICON} name="add-followup" />
          </span>
          <span>
            <a tabIndex={-1}>{t("US.COMMON:COMMON.FOLLOW_UP")}</a>
          </span>
        </div>
        <div
          className="d-flex align-items-center justify-content-start quick-link-item"
          tabIndex={-1}
          onClick={() => {
            handleStaticMenuItemNavigation(
              "/bureau/manage-creditor-groups",
              "collection",
              {
                ...state,
                currentTab: "bureau",
              }
            );
          }}
        >
          <span className="mg-menu-icon-outer">
            <BoxIcons type={IconTypes.BOX_ICON} name="creditor" />
          </span>
          <span>
            <a tabIndex={-1}>
              {t("US.COLLECTION.COMMON:COMMON.CREATE_NEW_CREDITOR")}
            </a>
          </span>
        </div>
        {quickLinks?.length > 0 && (
          <>
            <$Divider className="mb-2" />
            <div className="text-muted mb-1">
              <span>{t("US.COMMON:COMMON.QUICK_LINKS")}</span>
            </div>
          </>
        )}
        <div className="d-flex flex-column">
        <$Skeleton loading={isLoading} paragraph={{ rows: 2 }}>
          {quickLinks?.map((operation: IOperation) => {
            const { id, displayName } = operation;
            return (
              <div
                className="feature-link-item d-flex align-items-center justify-content-between quick-link-item"
                onMouseEnter={() => {
                  if (selectedQuickLink?.id !== id) {
                    setSelectedQuickLink(operation);
                  }
                }}
                onMouseLeave={() => setSelectedQuickLink(null)}
                onClick={() => handleQuickLinks(operation)}
                key={id}
              >
                <a key={id}>{displayName}</a>
                <span>
                  {selectedQuickLink?.id === id && (
                    <$Tooltip
                      placement="top"
                      title={t(
                        "US.COLLECTION.COMMON:COMMON.UNPIN_FROM_QUICK_LINKS"
                      )}
                    >
                      <$Button
                        onClick={(e: any) => {
                          e.stopPropagation();
                          unpin(operation);
                        }}
                        size="small"
                        type="link"
                        disabled={isSaving}
                      >
                        <BoxIcons type={IconTypes.BOX_ICON} name="unpin" />
                      </$Button>
                    </$Tooltip>
                  )}
                </span>
              </div>
            );
          })}
          </$Skeleton>
        </div>
      </div>
      <div className="menu-footer">
        <$Divider className="mb-2" />
        <div className="text-muted d-flex align-items-center font-sm mb-2">
          {t("US.COMMON:COMMON.MODULE_VERSION")}: v{packageJson.version}
        </div>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <svg viewBox="0 0 95 25" width="95" height="25">
              <g>
                <g>
                  <path
                    fill="#5FBA4E"
                    d="M69,7.1v11.9h11.9V7.1H69z M75.2,17.1c-2.8,0-4.1-2-4.1-4.1c0-2.1,1.3-4.2,4.1-4.2c2.7,0,4.1,2.1,4.1,4.2
			C79.2,15.1,78,17.1,75.2,17.1z"
                  />
                  <path
                    fill="#5FBA4E"
                    d="M75.2,10.2c-1.9,0-2.6,1.5-2.6,2.8c0,1.3,0.7,2.7,2.6,2.7c1.9,0,2.6-1.4,2.6-2.7C77.8,11.7,77,10.2,75.2,10.2
			z"
                  />
                </g>
                <path
                  fill="#9FCB3A"
                  d="M83,7.1v11.9h11.9V7.1H83z M92.6,17h-1.7l-2-3l-2,3h-1.7v0l2.7-4l-2.7-3.8V9.1h1.6l2,3l2-3h1.6v0.1L89.9,13
		L92.6,17L92.6,17z"
                />
                <g>
                  <path
                    fill="#129BD2"
                    d="M55.2,7.1v11.8H67V7.1H55.2z M61.7,17c-1.3,0-2.5,0-3.8,0V9.1c1.2,0,2.5,0,3.8,0c1.6,0,2.7,0.7,2.7,2.3
			c0,0.6-0.3,1.2-1,1.5c0.9,0.3,1.3,1.3,1.3,1.8C64.6,16.4,63.3,17,61.7,17z"
                  />
                  <path
                    fill="#129BD2"
                    d="M62.9,11.4c0-0.4-0.3-1-1.2-1h-2.3v1.8h2.3C62.6,12.2,62.9,11.8,62.9,11.4z"
                  />
                  <path
                    fill="#129BD2"
                    d="M61.7,13.5h-2.3v2h2.3c0.6,0,1.5-0.2,1.5-0.9C63.2,13.9,62.3,13.5,61.7,13.5z"
                  />
                </g>
                <path
                  fill="#435DA9"
                  d="M6.6,17.9v-1.5c-0.6,1.2-1.8,1.6-3,1.7c-2.3,0-3.8-1.4-3.8-3.7V9.5h0.8v4.9c0,1.9,1.2,2.9,3,2.9
		c1.7,0,3-1.3,3-3.1V9.5h0.8v8.5H6.6z"
                />
                <path
                  fill="#435DA9"
                  d="M10.1,9.5V11c0.7-1.2,1.8-1.6,3-1.7c2.3,0,3.8,1.4,3.8,3.7v4.9H16v-4.9c0-1.9-1.2-2.9-3-2.9
		c-1.7,0-3,1.3-3,3.1v4.7H9.2V9.5H10.1z"
                />
                <path
                  fill="#435DA9"
                  d="M19.8,6.7c0,0.9-1.4,0.9-1.4,0C18.4,5.8,19.8,5.8,19.8,6.7z M18.6,9.5v8.5h0.8V9.5H18.6z"
                />
                <path
                  fill="#435DA9"
                  d="M28.4,16.8c-0.9,0.9-2,1.3-3.1,1.3c-2.4,0-4.3-1.6-4.3-4.3c0-2.7,1.9-4.3,4.3-4.3c1.1,0,2.2,0.4,3.1,1.3
		l-0.5,0.5c-0.7-0.7-1.6-1-2.5-1c-1.9,0-3.5,1.2-3.5,3.5c0,2.3,1.6,3.5,3.5,3.5c0.9,0,1.8-0.4,2.5-1L28.4,16.8z"
                />
                <path
                  fill="#435DA9"
                  d="M29.4,13.7c0-2.7,1.9-4.4,4.3-4.4c2.4,0,4.3,1.7,4.3,4.4c0,2.7-1.9,4.3-4.3,4.3C31.3,18.1,29.4,16.4,29.4,13.7
		z M37.2,13.7c0-2.2-1.6-3.6-3.5-3.6c-1.9,0-3.5,1.4-3.5,3.6c0,2.2,1.6,3.5,3.5,3.5C35.6,17.2,37.2,15.9,37.2,13.7z"
                />
                <path
                  fill="#435DA9"
                  d="M40.3,9.5l0,1.5c0.5-1.1,1.7-1.6,2.8-1.6c0.6,0,1.2,0.2,1.8,0.5l-0.4,0.7c-0.4-0.3-0.9-0.4-1.4-0.4
		c-1.6,0-2.8,1.3-2.8,2.8v4.9h-0.8V9.5H40.3z"
                />
                <path
                  fill="#435DA9"
                  d="M46.6,9.5V11c0.6-1.2,1.8-1.6,3-1.7c2.3,0,3.8,1.4,3.8,3.7v4.9h-0.8v-4.9c0-1.9-1.2-2.9-3-2.9
		c-1.7,0-3,1.3-3,3.1v4.7h-0.8V9.5H46.6z"
                />
              </g>
            </svg>
          </div>
          <div>
            <a
              className="font-sm"
              target="_blank"
              href="https://unicorn-solutions.com/"
              tabIndex={-1}
            >
              Unicorn Solutions AS&copy;
            </a>
          </div>
        </div>
        <div className="font-sm text-muted d-flex justify-content-between align-items-center foot-link">
          <div>
            <a href="https://unicorn-solutions.com/about-us.html" tabIndex={-1} >About</a> <span className="mx-1">|</span>
            <a href="#" tabIndex={-1}>Terms of Use</a> <span className="mx-1">|</span>
            <a href="#" tabIndex={-1}>Privacy Policy</a>
          </div>
          <div>
            <svg viewBox="0 0 38 20" width="38" height="20">
              <g>
                <path
                  fill="#85C340"
                  d="M37.6,3.3c-0.5,0-1.1,0.1-1.6,0.2c-1,0.1-2,0.2-3,0.3c0,0-0.1,0-0.1,0c0-0.1-0.1-0.2-0.1-0.3
		c-0.4-0.7-1.3-1.4-2-1.8c-1.4-0.9-3.2-1.1-4.8-0.6c-0.6,0.2-1.2,0.5-1.9,0.8c-1,0.5-2.1,0.9-3,1.6c-0.9,0.6-1.4,1.6-2.5,1.9
		c-1,0.2-1.8-0.1-2.7-0.5c-1-0.4-1.9-0.8-2.9-1.2c-0.6-0.3-1.2-0.6-1.9-0.8c-0.6-0.1-1.3,0-2,0.1C8.4,3.3,7.5,3.6,6.8,4.3
		C6,5,5.4,6.1,5.5,7.1c0-0.1,0.4-0.4,0.3-0.6c0-1.7,1.5-2.4,2.9-2.8c1-0.2,1.9-0.3,2.9,0.1c1.5,0.6,2.9,1.3,4.4,1.8
		c0.7,0.3,1.4,0.5,2.2,0.4C19,5.9,19.5,5.6,20,5c1-1.2,2.4-1.8,3.8-2.4C25,2,26.1,1.4,27.5,1.5c1.6,0,2.9,0.6,4.1,1.7
		c0.7,0.7,1.3,1.4,0.9,2.4c-0.3,0.7-0.5,1-1.1,1.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.3-0.5,0.8-0.7,1.1c-0.4,0.7-0.8,1.3-1.5,1.7
		c0,0-0.1,0.1-0.1,0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.1-0.1-0.4-0.2-0.4-0.4c-0.1-0.2,0-0.4,0.1-0.7c0.1-0.4,0.2-0.8,0.2-1.3
		c0-0.4-0.2-0.7-0.2-1.1c0-0.3,0.2-0.7,0.1-1c0-0.5-0.5,0.2-0.6,0.4c-0.7,1.1-0.6,2.5-1.5,3.4c-0.1,0.1-0.2,0.5-0.3,0.6
		c0.5-0.6,1-1.2,1.3-1.9c0.2-0.6,0.2-1.3,0.5-1.8c0.4-0.6,0.3-0.1,0.2,0.2c-0.1,0.6,0.1,1.1,0.2,1.7c0.1,0.5,0,0.9-0.2,1.4
		c0,0.1-0.1,0.2-0.1,0.4c0,0.5,0.9,0.9,1.3,1.1c0,0,0.1-0.1,0.2-0.2c0.9-0.6,1.4-1.5,1.9-2.4c0.3-0.6,0.4-0.5,0.9-0.8
		c0.2-0.1,0.3-0.2,0.4-0.3c0.4-0.4,0.7-1,0.9-1.5C32.8,5.1,33,4.7,33,4.3c0.8-0.1,1.5-0.2,2.3-0.2c0.2,0,1.2-0.2,1.8-0.2
		c-0.1,0-0.2,0-0.2,0.1c-0.5,0.1-1,0.3-1.6,0.4c-0.7,0.2-1.5,0.4-2.2,0.5c-0.2,0-0.2,0.6-0.3,0.6c1-0.2,2-0.5,3.1-0.7
		c0.7-0.2,1.9-0.3,2.1-1.1C38.1,3.2,37.9,3.3,37.6,3.3z"
                />
                <path
                  fill="#1E62AC"
                  d="M13.8,17.6c0,0.4-0.2,0.8-0.5,1.1c-0.4,0.3-0.7,0.5-1.2,0.5c-0.2,0-0.4,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
		l0.1-0.6c0.1,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
		C13.1,17.1,13,17,12.9,17l-1.3-0.6c-0.2-0.1-0.4-0.3-0.5-0.5C11,15.7,11,15.5,11,15.2c0-0.4,0.2-0.7,0.5-1c0.3-0.2,0.7-0.4,1.1-0.4
		c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3l-0.2,0.5l-0.1,0c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.2
		c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.3l1.2,0.6c0.2,0.1,0.4,0.2,0.5,0.5
		C13.8,17.1,13.8,17.3,13.8,17.6z M17.7,17.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.6
		c-0.3-0.4-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.6-1.2c0.4-0.4,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5C17.6,16.5,17.7,16.9,17.7,17.4z
		 M17.1,17.4c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.5-0.7-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.8,0.3,1.1
		c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.6-0.2,0.8-0.5C17,18.1,17.1,17.8,17.1,17.4z M19.2,19.1h-0.6v-5.4h0.6V19.1z M23.1,19.1h-0.5
		v-0.6c-0.3,0.4-0.7,0.7-1.2,0.7c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.4-0.5-0.4-0.7v-2.4h0.6v2.5c0,0.2,0.1,0.3,0.2,0.4
		s0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-2.2h0.6V19.1z M25.5,18.9l0,0.2c-0.2,0-0.3,0.1-0.5,0.1
		c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.3-0.6V16h-0.5v-0.3h0.5v-0.8l0.5-0.2v1h0.8V16h-0.8v2.2c0,0.2,0,0.3,0.1,0.5
		c0.1,0.1,0.2,0.2,0.4,0.2L25.5,18.9z M26.6,14.4c0,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
		C26,14.6,26,14.5,26,14.4c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.2,0.1C26.6,14.3,26.6,14.3,26.6,14.4z M26.6,19.1
		H26v-3.4h0.6V19.1z M30.8,17.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.2-0.6c-0.3-0.4-0.5-0.8-0.5-1.2
		c0-0.5,0.2-0.9,0.6-1.2c0.4-0.4,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5C30.7,16.5,30.8,16.9,30.8,17.4z M30.2,17.4
		c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.5-0.7-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.8,0.3,1.1
		c0.2,0.3,0.5,0.5,0.9,0.5c0.3,0,0.6-0.2,0.8-0.5C30.1,18.1,30.2,17.8,30.2,17.4z M34.4,19.1h-0.6v-2.3c0-0.2-0.1-0.3-0.1-0.4
		C33.6,16.1,33.3,16,33,16c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6v2.3h-0.6v-3.4h0.5v0.6c0.1-0.2,0.3-0.4,0.4-0.5
		c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.4,0.4,0.4,0.7V19.1z M37.3,17.9c0,0.4-0.1,0.7-0.4,0.9
		c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.2l0.2-0.5c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1
		c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.2l-0.8-0.3c-0.2-0.1-0.3-0.2-0.4-0.4
		c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2l-0.1,0.4
		c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
		c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.3,0.2l0.9,0.3c0.1,0,0.2,0.1,0.3,0.2C37.2,17.7,37.3,17.8,37.3,17.9z M4.3,11
		c0,0.4-0.2,0.7-0.6,1.1c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.6-0.7-1V7.4h0.6v3.3
		c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1V7.4h0.4V11z M8.3,12.5H7.8v-2.3
		c0-0.2-0.1-0.3-0.1-0.4C7.5,9.5,7.3,9.4,6.9,9.4c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6v2.3H5.5V9.1H6v0.6
		c0.1-0.2,0.3-0.4,0.4-0.5C6.6,9.1,6.8,9,7.1,9c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.4,0.4,0.4,0.7V12.5z M10.1,7.8c0,0.1,0,0.2-0.1,0.2
		C10,8.1,9.9,8.2,9.8,8.2c-0.1,0-0.2,0-0.2-0.1C9.5,8,9.5,7.9,9.5,7.8c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1
		c0.1,0,0.2,0,0.2,0.1C10.1,7.7,10.1,7.7,10.1,7.8z M10.1,12.5H9.5V9.1h0.6V12.5z M13.9,9.3l-0.1,0.5c0,0-0.1,0-0.1,0
		c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c0,0.5,0.1,0.9,0.4,1.2
		c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4v0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5,0-0.9-0.1-1.2-0.4
		c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3S12.2,9,12.6,9c0.3,0,0.6,0,0.9,0.1C13.6,9.1,13.8,9.2,13.9,9.3z M17.9,10.8
		c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.4-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.2-1.3-0.6c-0.4-0.4-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.6-1.2
		C15.3,9.2,15.7,9,16.1,9c0.5,0,0.9,0.2,1.3,0.5C17.7,9.9,17.9,10.3,17.9,10.8z M17.2,10.8c0-0.4-0.1-0.8-0.3-1.1
		c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.5,0.9,0.5
		c0.3,0,0.6-0.2,0.8-0.5C17.1,11.5,17.2,11.2,17.2,10.8z M20.3,9.6L20.3,9.6c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.3,0.4v2.4h-0.5V9.1h0.5v0.7c0.2-0.3,0.3-0.5,0.5-0.6C19.7,9.1,19.9,9,20,9
		c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0V9.6z M23.7,12.5h-0.6v-2.3c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.3-0.4-0.4-0.8-0.4
		c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6v2.3h-0.6V9.1h0.5v0.6c0.1-0.2,0.3-0.4,0.4-0.5C22,9.1,22.2,9,22.4,9
		c0.3,0,0.6,0.1,0.9,0.2c0.3,0.2,0.4,0.4,0.4,0.7V12.5z"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonView;
