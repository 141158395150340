export enum FeeRangeTableColumns {
  MENU = "menu",
  FROM_AMOUNT = "fromAmount",
  TO_AMOUNT = "toAmount",
  NO_OF_FEES = "noOfFee",
  AMOUNT = "amount",
  DOUBLE_AMOUNT = "doubleAmount",
}

export const COLUMNS = [
  {
    id: 0,
    key: FeeRangeTableColumns.MENU,
    title: "",
  },
  {
    id: 1,
    key: FeeRangeTableColumns.FROM_AMOUNT,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.FROM_AMOUNT",
  },
  {
    id: 2,
    key: FeeRangeTableColumns.TO_AMOUNT,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.TO_AMOUNT",
  },
  {
    id: 3,
    key: FeeRangeTableColumns.NO_OF_FEES,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.NO_OF_FEES",
  },
  {
    id: 2,
    key: FeeRangeTableColumns.AMOUNT,
    title: "US.COMMON:COMMON.AMOUNT",
  },
  {
    id: 3,
    key: FeeRangeTableColumns.DOUBLE_AMOUNT,
    title: "US.COLLECTION.ADMIN:MANAGE_FEE.DOUBLE_AMOUNT",
  },
];
