import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import {
  $Button,
  $Checkbox,
  $Col,
  $Divider,
  $Input,
  $Row,
  $Select,
  $TextArea,
} from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { PlusOutlined } from "us.icons";
import { FieldName } from "us.collection.admin/constants";
import { useFormikContext } from "formik";
import { isFollowupCategoryExist } from "./Functions";

const { activity, followupCategory, followupCategories } = manageActivities;

/**
 * @description Followup Detail of Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 07/06/2022
 */
const FollowupDetail: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setTouched, touched } = useFormikContext();
  const {
    newFollowupCategory: categoryName,
    isFollowupCategoryExist: isCategoryExist,
    isNotifyActivityFailure,
  } = values as any;

  const {
    manageBreadCrumb,
    getFollowupCategories,
    followupCategories,
    saveFollowupCategory,
  } = props;
  const { data } = followupCategories;

  useEffect(() => {
    getFollowupCategories && getFollowupCategories({});
  }, []);

  /**
   * @function
   * @description handle followup category label
   */
  const onClickFollowCategoryLabel = () => {
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: true,
        title: FieldName.FOLLOWUP_CATEGORY,
      });
  };

  /**
   * @function
   * @description add new followup category
   * @param {string} categoryName followup category name
   */
  const onSaveFollowupCategory = () => {
    saveFollowupCategory && saveFollowupCategory({ categoryName });
  };

  const onChange = (value: string) => {
    setTouched({
      ...touched,
      newFollowupCategory: true,
    });
    setFieldValue("newFollowupCategory", value);
    setFieldValue(
      "isFollowupCategoryExist",
      isFollowupCategoryExist(data, value)
    );
  };

  return (
    <div className="sc-wf-panel">
      <$Row gutter={16} className="mb-2">
        <$Col span={12}>
          <$Input
            name="noOfDaysForNextFollowupDate"
            label={t(
              "US.COLLECTION.ADMIN:ACTIVITIES.NO_OF_DAYS_FOR_NEXT_FOLLOWUP_DATE"
            )}
            size="small"
            required
            type="number"
          />
        </$Col>
        <$Col span={12}>
          <a
            className="d-block"
            style={{ marginBottom: 2 }}
            onClick={onClickFollowCategoryLabel}
          >
            <strong>
              {t("US.COLLECTION.ADMIN:ACTIVITIES.FOLLOWUP_CATEGORY")}
            </strong>
          </a>
          <$Select
            name="followupCategory"
            formitem={{}}
            size="small"
            options={data}
            allOption={false}
            optionValue="categoryId"
            optionText="categoryName"
            onSearchBy={["categoryName", "categoryId"]}
            onClick={() => setFieldValue("newFollowupCategory", "")}
            dropdownRender={(menu: any) => (
              <div>
                {menu}
                <$Divider style={{ margin: "4px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 8,
                  }}
                >
                  <$Input
                    name="newFollowupCategory"
                    size="small"
                    className="w-100"
                    onChange={(e: any) => onChange(e.target.value)}
                  />
                  <$Button
                    type="default"
                    size="small"
                    className="ml-2"
                    style={{ marginTop: 4 }}
                    icon={<PlusOutlined />}
                    onClick={onSaveFollowupCategory}
                    disabled={isCategoryExist || categoryName.length == 0}
                  >
                    {t("US.COMMON:COMMON.ADD")}
                  </$Button>
                </div>
              </div>
            )}
          />
        </$Col>
      </$Row>
      <$TextArea
        name="followupReason"
        style={{ height: 80 }}
        size="small"
        label={t("US.COLLECTION.ADMIN:ACTIVITIES.FOLLOWUP_REASON")}
        className="mb-3"
        required
      />
      <$Checkbox
        name="isNotifyActivityFailure"
        onChange={(e: any) =>
          setFieldValue("isNotifyActivityFailure", e.target.checked)
        }
        checked={isNotifyActivityFailure}
      >
        {t(
          "US.COLLECTION.ADMIN:ACTIVITIES.NOTIFY_ACTIVITY_FAILURE_ON_THE_DAILY_FOLLOWUP"
        )}
      </$Checkbox>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { followupCategories } = manageActivities;

  return { followupCategories };
};
const mapDispatchToProps = {
  getFollowupCategories: followupCategories.get,
  saveFollowupCategory: followupCategory.save,
  manageBreadCrumb: activity.manageBreadCrumb,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FollowupDetail);
