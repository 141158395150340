import React, { useState } from "react";
import { Affix,  PageHeader, Button,  Drawer,  Table,  Form,  Select,  Input, Switch} from "antd";
import { useTranslation } from 'react-i18next';

interface IRoutines {
    onMinimize(): void;
  }
  
 
  export default (props: IRoutines) => {
    const { t } = useTranslation();
    const minimize = () => {
      props.onMinimize();
    };
  
    const onClose = () => {
      setDrawer({
        title: "",
        visible: false,
      });
    };
    const visibleDrawer = {
      title: "",
      visible: false,
    };
  
    const [drawer, setDrawer] = useState<any>(visibleDrawer);
    const showDrawer = () => {
      setDrawer({
        title:"",
        visible: true,
      });
    };
    const handleCancel = () => {
      onClose();
    };
   
    return (
      <>
        <Affix offsetTop={48}>
          <div className="page-header">
            <div className="d-flex align-items-center">
              <PageHeader
                onBack={minimize}
                className="px-0 py-3 mr-n3"
                title={t("US.COLLECTION.COMMON:APPLAYOUT.ROUTINES")}
              />
              <div className="ml-auto d-flex">
                                <Select
                      mode="multiple"
                      style={{ width: '350px' }}
                      placeholder="select one country"
                      defaultValue={['Case','Subcase']}
                    
                    >
                     
                    </Select>
              </div>
            </div>
          </div>
        </Affix>

        <div className="space-content">
          <Table rowKey="id" className="mt-3" size="small" />
        </div>
        <Drawer
          title={t("US.COLLECTION.ADMIN:ROUTINES.EDITROUTINES")}
          placement="right"
          width={450}
          closable={false}
          visible={drawer.visible}
          onClose={() => onClose()}
        >
          <Form>
            <Form.Item label={t("US.COLLECTION.COMMON:COMMON.NAME")}>
              <Input />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.COMMON:COMMON.DISPLAYNAME")}>
              <Input />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ROUTINES.ENTITYTYPE")}>
              <Input />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ROUTINES.ROUTINECODE")}>
              <Input />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ROUTINES.SHORTCUTCODE")}>
              <Input />
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.ADMIN:ROUTINES.ACTIVITYGROUP")}>
              <Select></Select>
            </Form.Item>
            <Form.Item label={t("US.COLLECTION.COMMON:COMMON.DESCRIPTION")}>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("US.COLLECTION.ADMIN:ROUTINES.AVAILABLEFOLLOWUP")}
            >
              <Switch />
            </Form.Item>
          </Form>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <Button className="mr-2" type="primary" htmlType="submit">
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </Button>
              <Button onClick={() => handleCancel()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </Button>
            </div>
          </div>
        </Drawer>
      </>
    );
  };
  