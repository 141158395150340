import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { RootState } from "us.helper/types";
import { $Button, $Input, $Skeleton } from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { SelectedDays } from "us.common/components";
import { IAddEditProfile } from "./Interfaces";
import { SaveExecutionProfile } from "us.collection.admin/components/ManageActivities/Components/ActivityDetail/Repository";
import { AddEditExecutionProfileSchema } from "./Validation";

const { executionProfile } = manageActivities;

/**
 * @description Activity Registration - Execution Profile
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3060891716/Activities+-+Execution+Profile+UI+Implementation
 * @author Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 25/07/2022
 */
const AddEditProfile: React.FC<PropsFromRedux & IAddEditProfile> = (props) => {
  const { t } = useTranslation();

  const {
    isNew,
    disable,
    profileValues,
    onCancel,
    saveOrEditExecutionProfile,
    stepDetail,
  } = props;
  const { formValues } = stepDetail;
  const { executionProfileValue } = formValues;
  const { isDetailsFetching } = executionProfileValue;

  /**
   * @function
   * @description formik handle submit
   * @param data
   */
  const handleSubmit = (data: any) => {
    const req = SaveExecutionProfile.call(data);
    saveOrEditExecutionProfile &&
      saveOrEditExecutionProfile(req, req.profileId == -1);
    onCancel && onCancel();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...profileValues }}
      onSubmit={handleSubmit}
      validationSchema={AddEditExecutionProfileSchema}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...rest
      }: any) => (
        <$Skeleton
          loading={isDetailsFetching}
          active
          paragraph={{ rows: 2 }}
          className={isDetailsFetching ? "px-5 pb-4" : ""}
        >
          <div className="ep-add">
            {isNew && (
              <strong className="d-block mb-3">
                {t("US.COLLECTION.ADMIN:ACTIVITIES.ADD_NEW_EXECUTION_PROFILE")}
              </strong>
            )}
            <div className="mb-3">
              <$Input
                name="profileName"
                label={t("US.COLLECTION.ADMIN:ACTIVITIES.PROFILE_NAME")}
                size="small"
                required
                disabled={disable}
              />
            </div>
            <strong className="d-block mb-2">
              {t("US.COLLECTION.ADMIN:ACTIVITIES.SEND_SMS")}
            </strong>
            <div>
              <SelectedDays name="schedules" />
              <div className="d-flex align-content-center justify-content-end mt-3">
                <$Button
                  className={isNew ? "mr-2" : ""}
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                  disabled={disable || !rest.isValid}
                >
                  {isNew && t("US.COMMON:COMMON.SAVE")}
                  {!isNew && t("US.COMMON:COMMON.UPDATE")}
                </$Button>
                {isNew && (
                  <$Button onClick={() => onCancel && onCancel()}>
                    {t("US.COMMON:COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </div>
        </$Skeleton>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { states, stepDetail } = manageActivities;
  return { states, stepDetail };
};
const mapDispatchToProps = {
  saveOrEditExecutionProfile: executionProfile.saveOrEdit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddEditProfile);
