import { httpAzure } from 'us.helper';
import {
	httpCollection,
	versionedHttpCollection,
} from 'us.helper/http/collection';
import { Guid } from 'guid-typescript';
import {
	IUser,
	IUserHistory,
	IUserB2C,
} from 'us.common/interfaces/UserManagement/User';
import { IPatchActiveState } from 'us.common/interfaces/UserManagement';
import { ActionTypes } from 'us.common/constants/Component/Notification';

/**
 * Get user list Azure Active Directory
 */
export const getAzureADUser = (): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get(
				'userservice',
				'directory/users',
				{}
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get User details from Azure Active Directory by user azureId
 * @param params
 */
export const getAzureADUserById = <P extends { azureUserId: Guid }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.getById(
				'userservice',
				'directory/users',
				params.azureUserId.toString()
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Save new user (User details get form Azure Active Directory)
 * @param params
 */
export const saveNewUser = (params: IUser): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'userservice',
				'users',
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
/**
 * Save B2C user
 * @param params
 */
export const saveNewB2CUser = (params: IUserB2C): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'userservice',
				'directory/users',
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};
/**
 * Get User list
 */
export const getUsers = () => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.get(
				'userservice',
				'users',
				{}
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get user details by userId
 * @param params
 */
export const getUserById = <P extends { id: number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.getById(
				'userservice',
				'users',
				params.id
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Update existing user details bu userId
 * @param params
 */
export const updateUser = (params: IUser): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.put(
				'userservice',
				`users/${params.id}`,
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Delete user by userId
 * @param params
 */
export const deleteUser = <P extends { id: number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.delete(
				'userservice',
				'users',
				params.id
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Delete B2Cuser by userId
 * @param params
 */
export const deleteB2CUser = <P extends { id: number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.delete(
				'userservice',
				'directory/users',
				params.id
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Create history record to user by userId
 * @param params
 */
export const saveUserHistory = (params: IUserHistory): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.post(
				'userservice',
				`users/${params.userId}/history`,
				params
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get user history records by UserId
 *  @param params
 */
export const getUserHistoryById = <P extends { id: number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.getById(
				'userservice',
				'users',
				`${params.id}/history`
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Change user active State by userId
 * @param params
 */
export const userDetailChangeById = <P extends IPatchActiveState>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await httpCollection.patch(
				'userservice',
				`users/${params.id}`,
				params.body
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 *  Get creditor groups list
 */
export const getCreditorGroups = (): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.get(
				'entityservice',
				'creditorgroups',
				{},
				'v1'
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get creditor company list by groupIds
 * @param params
 */
export const getCreditorCompany = (params: number[]): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.get(
				'entityservice',
				`creditorcompanies?groups=${params}`,
				{},
				'v1'
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get Creditors list using Creditor-groupId and Creditor-CompanyId
 * @param params
 */
export const getCreditors = <P extends { group: string[]; company: string[] }>(
	params: P
): Promise<any> => {
	console.error(params.group);
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data } = await versionedHttpCollection.get(
				'entityservice',
				`creditors?group=${params.group}&company=${params.company}`,
				{},
				'v1'
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Get user notifications
 */
export const getNotifications = <P extends { id: string; offset: number }>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { id, offset = 0 } = params;
			const { data } = await versionedHttpCollection.get(
				'notificationservice',
				'notification',
				{
					userid: id,
					offset: offset,
					limit: 5,
				},
				'v1'
			);
			resolve(data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Update user notifications
 */
export const updateNotifications = <
	P extends { notificationId: number; userId: string; type?: string }
>({
	notificationId,
	userId,
	type = 'SEEN',
}: P): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } =
				await versionedHttpCollection.patch(
					'notificationservice',
					`notification/${notificationId}?userid=${userId}&field=${type}`,
					{},
					'v1'
				);
			resolve({ data, status });
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * Retry user notifications
 */
export const retryNotifications = <
	P extends { actionType: string; extendedData: any; url: string }
>(
	params: P
): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const {
				actionType = ActionTypes.EXTERNAL_POST,
				extendedData,
				url,
			} = params;

			const { data, status } =
				actionType === ActionTypes.EXTERNAL_POST
					? await versionedHttpCollection.post(
							'paymentservice',
							url,
							extendedData,
							'v1'
					  )
					: await versionedHttpCollection.get(
							'paymentservice',
							url,
							extendedData,
							'v1'
					  );
			resolve({ data, status });
		} catch (error) {
			reject(error);
		}
	});
};

export const getAuthorityLevel = <P>({}: P): Promise<any> => {
	return new Promise<{}>(async (resolve, reject) => {
		try {
			const { data, status } =
				await versionedHttpCollection.get(
					'userservice',
					`authorizationlevels`,
					{},
					'v1'
				);
			resolve({ data, status });
		} catch (error) {
			reject(error);
		}
	});
};

export default {
	users: {
		get: (): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.get(
							'userservice',
							'users',
							{}
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	user: {
		getById: <P extends { id: number }>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { id } = params;
				try {
					const { data, status } =
						await httpCollection.getById(
							'userservice',
							'users',
							id
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.post(
							'userservice',
							'users',
							params
						);
					if (status == 201) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		update: (params: IUser): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { id } = params;
				try {
					const { data, status } =
						await httpCollection.put(
							'userservice',
							`users/${id}`,
							params
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		delete: <P extends { id: number }>(params: P): Promise<any> => {
			const { id } = params;
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.delete(
							'userservice',
							'users',
							id
						);
					if (status == 204) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		saveHistory: (params: IUserHistory): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { userId } = params;
				try {
					const { data, status } =
						await httpCollection.post(
							'userservice',
							`users/${userId}/history`,
							params
						);
					if (status == 201) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		getHistory: <P extends { userId: number }>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { userId } = params;
				try {
					const { data, status } =
						await httpCollection.getById(
							'userservice',
							'users',
							`${userId}/history`
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		patch: (params: any): Promise<any> => {
			const { userId, body } = params;
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.patch(
							'userservice',
							`users/${userId}`,
							body
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	aDUsers: {
		get: (): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.get(
							'userservice',
							'directory/users',
							{}
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	aDUser: {
		getById: <P extends { azureUserId: Guid }>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { azureUserId } = params;
				try {
					const { data } =
						await httpCollection.getById(
							'userservice',
							'directory/users',
							azureUserId.toString()
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	b2CUser: {
		save: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.post(
							'userservice',
							'directory/users',
							params
						);
					if (status == 201) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
		delete: <P extends { userId: number }>(
			params: P
		): Promise<any> => {
			const { userId } = params;
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.delete(
							'userservice',
							'directory/users',
							userId
						);
					if (status == 204) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	creditorGroups: {
		get: (): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'entityservice',
							'creditorgroups',
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	creditorCompanies: {
		get: (params: any): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'entityservice',
							`creditorcompanies?groups=${params}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	creditors: {
		get: <P extends { group: string[]; company: string[] }>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				const { group, company } = params;
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'entityservice',
							`creditors?group=${group}&company=${company}`,
							{},
							'v1'
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	notification: {
		get: <P extends { id: string; offset: number }>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { id, offset = 0 } = params;
					const { data } =
						await versionedHttpCollection.get(
							'notificationservice',
							'notification',
							{
								userid: id,
								offset: offset,
								limit: 5,
							},
							'v1'
						);
					resolve(data);
				} catch (error) {
					reject(error);
				}
			});
		},
		update: <
			P extends {
				notificationId: number;
				userId: string;
				type?: string;
			}
		>({
			notificationId,
			userId,
			type = 'SEEN',
		}: P): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.patch(
							'notificationservice',
							`notification/${notificationId}?userid=${userId}&field=${type}`,
							{},
							'v1'
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
		retry: <
			P extends {
				actionType: string;
				extendedData: any;
				url: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const {
						actionType = ActionTypes.EXTERNAL_POST,
						extendedData,
						url,
					} = params;

					const { data, status } =
						actionType ===
						ActionTypes.EXTERNAL_POST
							? await versionedHttpCollection.post(
									'paymentservice',
									url,
									extendedData,
									'v1'
							  )
							: await versionedHttpCollection.get(
									'paymentservice',
									url,
									extendedData,
									'v1'
							  );
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	authorityLevel: {
		get: <P>({}: P): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await versionedHttpCollection.get(
							'userservice',
							`authorizationlevels`,
							{},
							'v1'
						);
					resolve({ data, status });
				} catch (error) {
					reject(error);
				}
			});
		},
	},
	preferredLanguage: {
		update: <
			P extends {
				userId: string;
				languageCode: string;
			}
		>(
			params: P
		): Promise<any> => {
			return new Promise<{}>(async (resolve, reject) => {
				try {
					const { data, status } =
						await httpCollection.put(
							'userservice',
							'users/preferredlanguage',
							params
						);
					if (status == 200) {
						resolve(data);
					} else {
						reject(data);
					}
				} catch (error) {
					reject(error);
				}
			});
		},
	},
};
