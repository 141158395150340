
import { IUpdateNotificationPayload } from "us.common/components/NotificationMenu/Interface";


export function UpdateNotification(this: any): IUpdateNotificationPayload {

  return {
    notificationId: Number(this.notificationId),
    userId: this.userId,
    type: !this.type ? undefined : this.type
  };
}
