import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { $Affix, $Steps } from "us.common/components";
import { manageActivities } from "us.collection.admin/actions";
import { getStateName } from "us.collection.admin/functions";
import { StepTypes } from "us.collection.admin/constants";

const { activity } = manageActivities;

/**
 * @description Indicate Steps For Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 01/06/2022
 */
const Steps: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { stepDetail } = props;
  const { steps, currentStepId, formValues } = stepDetail;
  return (
    <div className="ad-layout-side">
      <$Affix offsetTop={48}>
        <aside>
          <$Steps direction="vertical" current={currentStepId}>
            {steps.map((title: string, index: number) => {
              return (
                <$Steps.Step
                  key={index}
                  title={t(`US.COLLECTION.ADMIN:ACTIVITIES.${title}`)}
                  description={
                    formValues[getStateName(title as StepTypes)].isCompleted
                      ? t("US.COLLECTION.ADMIN:ACTIVITIES.COMPLETED")
                      : t("US.COLLECTION.ADMIN:ACTIVITIES.NOT_COMPLETED")
                  }
                />
              );
            })}
          </$Steps>
        </aside>
      </$Affix>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { stepDetail } = manageActivities;

  return { stepDetail };
};
const mapDispatchToProps = {
  setStepDetail: activity.setStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Steps);
