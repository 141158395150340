import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  $Button,
  $Popconfirm,
  $Skeleton,
  $Switch,
  $Drawer,
  $Form,
  $Input,
  $Row,
  $Col,
  $TextArea,
  $AutoComplete,
  $Select,
  $Divider,
} from "us.common/components";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  userManagementActions,
  roleManagementActions,
} from "us.common/actions";
import { RoleSetup, CreditorSetup, UserHistory } from "./Components";
import "./UserSettings.scss";
import { ValidationSchema } from "./Validation";
import { userSummary } from "us.common/reducers/UserManagement/State";
import { IAzureADUser } from "us.common/interfaces";
import {
  setRolesDetail,
  getADUserInitialValues,
  getAccessAuthorityLevelId,
  getAccessAuthorityLevel,
  sortedOptionsData,
} from "./Functions";
import { AuthorityLevel } from "us.common/constants";
import { SaveUser, SaveUserHistory } from "./Repository";
import * as Azure from "us.helper/azure";
import Common from "us.common";
import { PreferredLanguage } from "./Constants";

const { user, init, aDUser } = userManagementActions;
const { roles } = roleManagementActions;

const UserDetail: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const {
    manageDrawer,
    resetForm,
    drawer,
    initUser,
    getUserDetail,
    adUsers,
    roles,
    authorizationLevel,
    setADUserDetail,
    saveUser,
    updateUser,
    getADUser,
    aDUser,
    user,
  } = props;
  const auth = new Azure.ADAuth();
  const currentUser: any = auth.currentUser();
  const { isNew, selectedUser } = drawer;
  const { id, azureUserId } = selectedUser;
  const { optionsData, data: adUsersData } = adUsers;
  const { data: rolesData, isLoading: isRolesLoading } = roles;
  const {
    data: authorizationLevelData,
    isLoading: isAuthorizationLevelLoading,
  } = authorizationLevel;
  const { data: aDDetail } = aDUser;
  const { data: userDetail, isLoading } = user;

  const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isNew) {
      getUserDetail && getUserDetail({ id });
      getADUser && getADUser({ azureUserId });
    }
    initUser && initUser({});
  }, []);

  /**
   * @function
   * @description handle drawer close event
   */
  const onClose = () => {
    manageDrawer &&
      manageDrawer({
        title: "",
        isNew: true,
        visible: false,
        selectedUser: userSummary,
      });
    resetForm && resetForm({});
  };
  /**
   * @function
   * @description Show Level and level description in drop down list
   * @param name Level name
   * @param description Level description
   * @returns
   */
  const authorityDropDownItem = (name: string, description: string) => {
    return {
      displayLabel: (
        <div className="py-1">
          <span className="d-block">{`${name}`}</span>
          <small className="d-block bui-label">{`${description} `}</small>
        </div>
      ),
      label: name,
      value: name,
    };
  };

  /**
   * @function
   * @description Custorm authority level list
   * @param {any} list Authoritation level list
   * @returns Array
   */
  const authorityOptions = (list: any) => {
    try {
      return list.map((level: any) => {
        const { name, description, levelId } = level ?? {};
        return authorityDropDownItem(name, description);
      });
    } catch {
      return [];
    }
  };

  /**
   * @function
   * @description update selected ad user detail
   * @param {string} email
   * @param {any} values
   */
  const updateSelectedADUser = (email: string, values: any) => {
    try {
      const aDDetail =
        adUsersData.find(
          ({ userPrincipalName }: IAzureADUser) => userPrincipalName == email
        ) ?? {};

      const {
        displayName,
        givenName,
        surname,
        businessPhones,
        userPrincipalName,
      } = aDDetail;

      setADUserDetail &&
        setADUserDetail({
          userDetail: {
            ...values,
            email: userPrincipalName,
            displayName,
            firstName: givenName,
            lastName: surname,
            telephoneNo: businessPhones[0],
          },
          aDDetail,
        });
    } catch (error) {}
  };

  /**
   * @function
   * @description - Handle language change.
   * @param {string} lngCode - selected language.
   */
  const changeLanguageHandler = (lngCode: string) => {
    let languageCode = PreferredLanguage.NORWEGIAN;
    let language: string = Common.Constants.languageCodes.nbNO,
      currency: string = Common.Constants.currencyCodes.nbNO,
      dateFormat: string = Common.Constants.dateFormats.nbNO;
    switch (lngCode) {
      case PreferredLanguage.ENGLISH_US:
        languageCode = PreferredLanguage.ENGLISH_US;
        language = Common.Constants.languageCodes.enGB;
        currency = Common.Constants.currencyCodes.enGB;
        dateFormat = Common.Constants.dateFormats.enGB;
        break;

      case PreferredLanguage.NORWEGIAN:
        languageCode = PreferredLanguage.NORWEGIAN;
        language = Common.Constants.languageCodes.nbNO;
        currency = Common.Constants.currencyCodes.nbNO;
        dateFormat = Common.Constants.dateFormats.nbNO;
        break;

      default:
        languageCode = PreferredLanguage.NORWEGIAN;
        break;
    }
    return {
      language,
      currency,
      dateFormat,
      languageCode,
      modifiedUser: currentUser.unique_name,
    };
  };

  /**
   * @function
   * @description handle submit
   * @param values
   */
  const handleSubmit = (values: any) => {
    try {
      const request = SaveUser.call(values, aDDetail);
      if (isNew) {
        saveUser && saveUser(request);
      } else {
        const historyRequest = SaveUserHistory.call(values, currentUser?.name);
        const languageDetails = changeLanguageHandler(values.preferredLanguage);
        updateUser && updateUser({ request, historyRequest, languageDetails });
      }
    } catch (error) {}
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...userDetail,
        ...getADUserInitialValues(aDDetail),
        roles: setRolesDetail(rolesData, userDetail.roles),
        comment: "",
        isEdit: !isNew,
      }}
      validationSchema={ValidationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...restProps
      }: any) => (
        <$Form onSubmit={handleSubmit}>
          <$Skeleton loading={!isNew && isLoading}>
            <div className="d-flex align-items-center mb-3">
              <$Switch
                name="activeState"
                defaultChecked={true}
                checked={values.activeState}
                onChange={(checked: boolean) => {
                  setFieldValue("activeState", checked);
                }}
              />
              <label className="ml-2">
                {t("US.COLLECTION.COMMON:COMMON.ACTIVE")}
              </label>
            </div>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$AutoComplete
                  name="email"
                  label={t("US.COLLECTION.COMMON:COMMON.USER")}
                  placeholder={t("US.COMMON:MANAGE_USER.SEARCH_USER")}
                  size="small"
                  disabled={!isNew}
                  options={sortedOptionsData(optionsData)}
                  className="w-100"
                  dropdownStyle={{
                    width: 290,
                  }}
                  virtual={false}
                  onSelect={(email: string) =>
                    updateSelectedADUser(email, values)
                  }
                  onChange={(email: string) => setFieldValue("email", email)}
                  filterOption={(inputValue: any, option: any) =>
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  size="small"
                  className="w-100"
                  label={t("US.COMMON:MANAGE_USER.DISPLAY_NAME")}
                  value={values.displayName}
                  name="displayName"
                  autoComplete="off"
                  //   onBlur={(e: any) => onChangeDiplayName(e)}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$Input
                  disabled
                  size="small"
                  className="w-100"
                  label={t("COMMON.FIRST_NAME")}
                  value={values.firstName}
                  name="firstName"
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  disabled
                  size="small"
                  className="w-100"
                  label={t("US.COMMON:MANAGE_USER.LAST_NAME")}
                  value={values.lastName}
                  name="lastName"
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-4">
              <$Col span={12}>
                <$Select
                  name="preferredLanguage"
                  formitem={{
                    label: t("US.COMMON:MANAGE_USER.PREFFERED_LANGUAGE"),
                  }}
                  size="small"
                  options={[
                    {
                      label: t("US.COMMON:COMMON.ENGLISH-US"),
                      value: "en",
                    },
                    {
                      label: t("US.COMMON:COMMON.NORWEGIAN"),
                      value: "no",
                    },
                  ]}
                  optionValue="value"
                  optionText="label"
                  allOption={false}
                  tabIndex={1}
                  disabled={false}
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  disabled
                  size="small"
                  className="w-100"
                  label={t("US.COMMON:MANAGE_USER.TELEPHONE")}
                  value={values.telephoneNo}
                  name="telephoneNo"
                />
              </$Col>
            </$Row>

            <$Skeleton
              active
              paragraph={{ rows: 1 }}
              loading={isAuthorizationLevelLoading}
            >
              <div className="role-setup pb-4 mb-4">
                <$Row gutter={16}>
                  <$Col span={12}>
                    <strong className="d-block mb-2">
                      {t("US.COMMON:MANAGE_USER.AUTHORITY_LEVEL")}
                    </strong>
                    <$Select
                      name="accessAuthorityLevel"
                      className="w-100"
                      size="small"
                      options={authorityOptions(authorizationLevelData)}
                      optionValue="value"
                      optionText="displayLabel"
                      optionLabelProp="label"
                      allOption={false}
                      tabIndex={1}
                      value={getAccessAuthorityLevel(
                        values?.accessAuthorityLevelId,
                        authorizationLevelData
                      )}
                      disabled={false}
                      onSelect={(value: string) =>
                        setFieldValue(
                          "accessAuthorityLevelId",
                          getAccessAuthorityLevelId(
                            value,
                            authorizationLevelData
                          )
                        )
                      }
                      dropdownMatchSelectWidth={false}
                    />
                  </$Col>
                  {values.accessAuthorityLevel ==
                    AuthorityLevel.CanDisables && (
                    <$Col span={12}>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: 28 }}
                      >
                        <$Switch
                          name="isCommentEnable"
                          checked={values.isCommentEnable}
                          onChange={(checked: boolean) => {
                            setFieldValue("isCommentEnable", checked);
                          }}
                        />
                        <label className="ml-2">
                          {t("US.COMMON:MANAGE_USER.IS_COMMENT_ENABLE")}
                        </label>
                      </div>
                    </$Col>
                  )}
                </$Row>
              </div>
            </$Skeleton>

            <$Row gutter={16} className="mt-3">
              <$Col span={6}>
                <div className="d-flex align-items-center">
                  <$Switch
                    name="maskPrivateData"
                    checked={values.maskPrivateData}
                    onChange={(checked: boolean) => {
                      setFieldValue("maskPrivateData", checked);
                    }}
                  />
                  <label className="ml-2">
                    {t("US.COMMON:MANAGE_USER.MASK_PRIVATE_DATA")}
                  </label>
                </div>
              </$Col>
              <$Col span={18}>
                <div className="d-flex align-items-center">
                  <$Switch
                    name="hasDebtCollectionLicense"
                    checked={values.hasDebtCollectionLicense}
                    onChange={(checked: boolean) => {
                      setFieldValue("hasDebtCollectionLicense", checked);
                    }}
                  />
                  <label className="ml-2">
                    {t(
                      "US.COMMON:MANAGE_USER.USER_HAS_DEBT_COLLECTION_LICENSE"
                    )}
                  </label>
                </div>
              </$Col>
            </$Row>

            <div className="role-setup d-flex flex-column my-4">
              <strong className="mb-2">
                {t("US.COLLECTION.COMMON:COMMON.ROLE_SETUP")}
              </strong>
              <$Skeleton
                active
                paragraph={{ rows: 1 }}
                loading={isRolesLoading}
              >
                <RoleSetup values={values} />
              </$Skeleton>
            </div>

            <div>
              <CreditorSetup />
            </div>

            {!isNew && (
              <div className="mt-4">
                <$TextArea
                  className="w-100"
                  required
                  label={t("US.COMMON:MANAGE_USER.COMMENT")}
                  name="comment"
                  autoSize={{
                    minRows: 4,
                    maxRows: 6,
                  }}
                />
              </div>
            )}
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                {!isNew && (
                  <>
                    <$Button
                      className="mr-3"
                      onClick={() => setIsHistoryVisible(true)}
                    >
                      {t("US.COMMON:MANAGE_USER.HISTORY")}
                    </$Button>
                    <$Divider type="vertical" className="my-3" />
                  </>
                )}

                <$Button
                  className="ml-3 mr-2"
                  disabled={!restProps.isValid}
                  onClick={handleSubmit}
                  type="primary"
                >
                  {isNew ? t("COMMON.SAVE") : t("COMMON.UPDATE")}
                </$Button>
                {restProps.dirty && (
                  <$Popconfirm
                    title={
                      isNew
                        ? t(
                            "US.COMMON:MANAGE_USER.USER_CREATION_NOT_BEEN_AND_YOUR_EXISTING_DATA_WILL_BE_LOST,DO_YOU_WISH_TO_CONTINUE_?"
                          )
                        : t(
                            "US.COMMON:MANAGE_USER.THE_EDITED_DETAILS_HAVE_NOT_BEEN_SAVED_AND_YOUR_EXISTING_DATA_WILL_BE_LOST,_DO_YOU_WISH_TO_CONTINUE?"
                          )
                    }
                    placement="topLeft"
                    onConfirm={onClose}
                    okText={t("COMMON.YES")}
                    cancelText={t("COMMON.NO")}
                  >
                    <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {!restProps.dirty && (
                  <$Button onClick={onClose}>{t("COMMON.CANCEL")}</$Button>
                )}
              </div>
            </div>
          </$Skeleton>

          <$Drawer
            title={t("US.COMMON:MANAGE_USER.HISTORY")}
            width={800}
            visible={isHistoryVisible}
            onClose={() => setIsHistoryVisible(false)}
          >
            <UserHistory />
          </$Drawer>
        </$Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { userManagement } = state;
  const { drawer, adUsers, roles, authorizationLevel, user, aDUser } =
    userManagement;
  return {
    drawer,
    adUsers,
    roles,
    authorizationLevel,
    user,
    aDUser,
  };
};
const mapDispatchToProps = {
  manageDrawer: user.openDrawer,
  resetForm: user.reset,
  initUser: init.get,
  getUserDetail: user.get,
  saveUser: user.save,
  updateUser: user.update,
  getRoles: roles.get,
  setADUserDetail: aDUser.set,
  getADUser: aDUser.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(UserDetail);
