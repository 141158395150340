import { HandlerAction } from "us.collection/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.collection/services";
import { Handler, Handlers } from "us.collection/constants";
import { $MessageBox } from "us.common/components";

const { CaseHandler, CourtHandler } = API.Handler;

const { handler, updateHandler } = HandlerAction;

const HandlerList = {
  get: function* (action: any) {
    const params = action.payload?.data;
    try {
      if (params.type === Handlers.CASE) {
        const { data, status } = yield call(CaseHandler.get, params);
        if (status === 200) {
          yield put(
            handler.success({
              list: data,
              status,
              dataType: Handlers.CASE_HANDLER_LIST,
            })
          );
        } else {
          yield put(handler.fail(data));
        }
      }
      if (params.type === Handlers.COURT) {
        const { data, status } = yield call(CourtHandler.get, params);
        if (status === 200) {
          yield put(
            handler.success({
              list: data,
              status,
              dataType: Handlers.COURT_HANDLER_LIST,
            })
          );
        } else {
          yield put(handler.fail(data));
        }
      }
    } catch (error) {
      yield put(handler.fail(error as any));
    }
  },
  update: function* (action: any) {
    const params = action.payload?.data;
    try {
      if (params.type === Handlers.CASE) {
        const { data, status } = yield call(CaseHandler.update, params);
        yield put(
          updateHandler.success({
            caseHandlerName:
              status === 200 ? params.caseHandlerName : params.previousName,
            type: params.type,
          })
        );
        if (status === 200 && !data?.isError) {
          $MessageBox(
            "success",
            "US.COLLECTION.LAYOUT:CASE.CASE_HANDLER_SUCCESSFULLY_UPDATED",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.LAYOUT:CASE.CASE_HANDLER_UPDATE_FAIL",
            "",
            ""
          );
        }
      }
      if (params.type === Handlers.COURT) {
        const { data, status } = yield call(CourtHandler.update, params);
        yield put(
          updateHandler.success({
            courtHandlerName:
              status === 200 ? params.courtHandlerName : params.previousName,
            type: params.type,
          })
        );
        if (status === 200 && !data?.isError) {
          $MessageBox(
            "success",
            "US.COLLECTION.LAYOUT:CASE.COURT_HANDLER_SUCCESSFULLY_UPDATED",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.LAYOUT:CASE.COURT_HANDLER_UPDATE_FAIL",
            "",
            ""
          );
        }
      }
    } catch (error) {
      switch (params.type) {
        case Handlers.CASE:
          yield put(
            updateHandler.fail({
              error,
              caseHandlerName: params.previousName,
              type: params.type,
            })
          );
          break;
        case Handlers.COURT:
          yield put(
            updateHandler.fail({
              error,
              courtHandlerName: params.previousName,
              type: params.type,
            })
          );
          break;
        default:
          break;
      }
    }
  },
};

export default [
  takeLatest(Handler.GET_HANDLER_START, HandlerList.get),
  takeLatest(Handler.UPDATE_HANDLER_START, HandlerList.update),
];
