export enum DebtorSubCriterias {
  AR_NUMBER = "AR Number",
  NAME = "Name",
  NUMBER = "Number",
  ADDRESS = "Address",
  TELEPHONE = "Telephone",
  PERSON_NUMBER = "Person Number",
  CASE_NUMBER = "Case Number",
  BIRTHDAY = "Birthday(yyyy/MM/dd)",
  REAL_ESTATE_GNR = "Real Estate GNR",
  REAL_ESTATE_BNR = "Real Estate BNR",
  REAL_ESTATE_SNR = "Real Estate SNR",
  REAL_ESTATE_FNR = "Real Estate FNR",
  EMPLOYER_NUMBER = "Employer Number",
  EMPLOYER_NIN = "Employer NIN",
  DOC_OF_TITLE_SEC_NO = "Document Of Title Section Number",
  VEHICLE_REGISTER_NUMBER = "Vehicle Register Number",
  FINANCIAL_ASSET_NAME = "Financial Asset Name",
}
