import _ from "lodash";
import moment from "moment";
import {
    ActivityVersion,
    DefaultField,
    EntityType,
    FollowUpUniqueColumn,
    InitialField,
    ParameterType,
    USCVersion,
} from "us.common/constants";
import {
    IActivity,
    IActivityBulkRequest,
    IDefaultData,
    ISetting,
    ISettingRequest,
} from "us.common/interfaces";

/**
 * @function
 * @description get select activity data
 * @param {Array<IActivity>} activities activities
 * @param {string} activityName select activity name
 * @returns {IActivity} select activity data
 */
export const getSelectedActivity = (
    activities: Array<IActivity>,
    activityName: string
): IActivity | undefined => {
    try {
        return activities.find((activity: IActivity) => {
            const { displayName } = activity;
            return displayName.toLowerCase() === activityName.toLowerCase();
        });
    } catch (error) { }
};

/**
 * @function
 * @description get parameter fields for initial value
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {object}
 */
export const getFieldName = (
    settings: Array<ISetting>,
    entityType: string
): object => {
    let fileds: any = {};
    try {
        settings
            .filter((setting: ISetting) => !isDefaultField(setting.name, entityType))
            .map((setting: ISetting) => {
                fileds[setting.name] = "";
            });
    } catch (error) { }
    return fileds;
};

/**
 * @function
 * @description set parameterList property values for execution request
 * @param {any} values -activity form data
 * @param {Array<ISetting>} settings - activity parameters
 * @param {IDefaultData} defaultData - default data about activity
 * @param {number | string} entityId - entityId
 * @returns
 */
export const getParameterList = (
    values: any,
    settings: Array<ISetting>,
    defaultData: IDefaultData,
    entityId: number | string
): Array<ISettingRequest> => {
    try {
        const { userName, entityType } = defaultData;
        const settingData = _.omit(values, [
            InitialField.Activity,
            InitialField.ExecutingDateTime,
            InitialField.ExecutingDateTimeWithParameter,
        ]);
        const commonSettingData: Array<ISettingRequest> = [];

        !_.isEmpty(settingData) &&
            settings
                .filter(
                    (setting: ISetting) => !isDefaultField(setting.name, entityType)
                )
                .map((setting: ISetting) => {
                    commonSettingData.push({
                        Name: setting.name,
                        DataType: setting.parameterType,
                        value: settingData[setting.name],
                    });
                });

        const userNameIndex: number = settings.findIndex(
            ({ name }: ISetting) =>
                name.toLowerCase() == DefaultField.UserName ||
                name.toLowerCase() == DefaultField.User
        );

        if (userNameIndex > 0) {
            commonSettingData.splice(userNameIndex, 0, {
                Name: settings[userNameIndex].name,
                DataType: ParameterType.VARCHAR,
                value: userName,
            });

            _.remove(settings, (o) => {
                return o.name.toLowerCase() == DefaultField.UserName ||
                    o.name.toLowerCase() == DefaultField.User
            })
        } else {
            commonSettingData.unshift({
                Name: DefaultField.UserName,
                DataType: ParameterType.VARCHAR,
                value: userName,
            });
        }

        !_.isEmpty(getDefaultFieldName(settings, entityType)) &&
            commonSettingData.unshift({
                Name: Object.keys(getDefaultFieldName(settings, entityType))[0],
                DataType: ParameterType.INT,
                value: entityId,
            });
        return commonSettingData;
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description check activity parameter field is default or nor
 * @param {string} fieldName field name
 * @param {string} entityType entityType
 * @returns {boolean}
 */
const isDefaultField = (fieldName: string, entityType: string): boolean => {
    try {
        switch (entityType) {
            case EntityType.CASE:
            case EntityType.INVOICE:
            case EntityType.SUB_CASE:
                switch (fieldName?.trim()?.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                        return true;
                    case DefaultField.EntitiyId:
                        return true;
                    case DefaultField.Id:
                    case DefaultField.CaseId:
                    case DefaultField.CaseNo:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case EntityType.DEBTOR:
                switch (fieldName?.trim()?.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                        return true;
                    case DefaultField.EntitiyId:
                        return true;
                    case DefaultField.ArNo:
                    case DefaultField.ArId:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case EntityType.CREDITOR:
                switch (fieldName?.trim()?.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                        return true;
                    case DefaultField.EntitiyId:
                        return true;
                    case DefaultField.CreditorNo:
                    case DefaultField.CreditorId:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            case EntityType.PAYMENT:
                switch (fieldName?.trim()?.toLowerCase()) {
                    case DefaultField.EntityNo:
                    case DefaultField.EntityId:
                        return true;
                    case DefaultField.EntitiyId:
                        return true;
                    case DefaultField.PaymentId:
                    case DefaultField.PaymentNo:
                    case DefaultField.User:
                    case DefaultField.UserName:
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    } catch (error) {
        return false;
    }
};

/**
 * @function
 * @description get default parameter fields
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {object}
 */
export const getDefaultFieldName = (
    settings: Array<ISetting>,
    entityType: string
): object => {
    let fileds: any = {};
    try {
        settings
            .filter((setting: ISetting) => isDefaultField(setting.name, entityType))
            .map((setting: ISetting) => {
                fileds[setting.name] = "";
            });
    } catch (error) { }
    return _.omit(fileds, [DefaultField.User, DefaultField.UserName]);
};

/**
 * @function
 * @description get activity parameter table data
 * @param {Array<ISetting>} settings activity parameters
 * @param {string} entityType entityType
 * @returns {Array<ISetting>} table data
 */
export const getParameterTableData = (
    settings: Array<ISetting>,
    entityType: string
): Array<ISetting> => {
    try {
        return settings.filter(
            (setting: ISetting) => !isDefaultField(setting.name, entityType)
        );
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description get relevant activity code
 * @param {Array<IActivity>} activityList activities
 * @param {string} activityId selected activity ID
 * @param {string | undefined} entityTypeFilter activity filters
 * @returns {string | number}
 */
export const getActivityCode = (
    activityList: Array<IActivity>,
    activityId: number,
    entityTypeFilter?: string
): string | number => {
    try {
        return (
            activityList.find((act: IActivity) =>
                entityTypeFilter
                    ? act.activityId == activityId &&
                    act.entitytype == entityTypeFilter
                    : act.activityId == activityId
            )?.activityCode ?? ""
        );
    } catch (error) {
        return "";
    }
};

/**
 * @function
 * @description - entity type conversion from EntityType to single activity execution's entityType field
 * @param {EntityType | string} entityType - entity type - EntityType
 * @returns {EntityTypes | ""}
 */
export const convertEntityType = (
    entityType: EntityType | string
): EntityType => {
    switch (entityType) {
        case EntityType.INVOICE:
        case EntityType.SUB_CASE:
            return EntityType.SUB_CASE;
        case EntityType.CASE:
            return EntityType.CASE;
        case EntityType.AR:
        case EntityType.DEBTOR:
            return EntityType.AR;
        case EntityType.CREDITOR:
            return EntityType.CREDITOR;
        case EntityType.PAYMENT:
            return EntityType.PAYMENT;
        default:
            return entityType as EntityType;
    }
};

/**
 * @function
 * @description set activities property values for execution request
 * @param {any} values -activity form data
 * @param {Array<ISetting>} settings - activity parameters
 * @param {IDefaultData} defaultData - dafualt data about activity
 * @param {Array<string>} entityIds - selected entityIds
 * @param {boolean} isEnableParameter - check activity has non default parameters
 * @returns {Array<IActivityBulkRequest>} activities data for execute request
 */
export const getActivitiesRequest = (
    values: any,
    settings: Array<ISetting>,
    defaultData: IDefaultData,
    entityIds: Array<string | number>,
    isEnableParameter: boolean
): Array<IActivityBulkRequest> => {
    try {
        const activitiesRequest: Array<IActivityBulkRequest> = [];

        entityIds.map((entityId: number | string) => {
            activitiesRequest.push({
                entityId,
                executingDateTime: setExecuteDateTime(values, isEnableParameter),
                parameterList: getParameterList(
                    values,
                    settings,
                    defaultData,
                    entityId
                ),
            });
        });
        return activitiesRequest;
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description set execution dateTime
 * @param {any} values activity form data
 * @param {boolean} isEnableParameter - check activity has non default parameters
 * @returns {string} execution dateTime
 */
export const setExecuteDateTime = (
    values: any,
    isEnableParameter: boolean
): string => {
    try {
        if (isEnableParameter) {
            return moment(
                values[InitialField.ExecutingDateTimeWithParameter]
            ).isValid()
                ? moment(values[InitialField.ExecutingDateTimeWithParameter]).format()
                : moment().format();
        } else {
            return moment(values[InitialField.ExecutingDateTime]).isValid()
                ? moment(values[InitialField.ExecutingDateTime]).format()
                : moment().format();
        }
    } catch (error) {
        return moment().format();
    }
};

/**
 * @function
 * @description check activity is valid or not
 * @param {Array<IActivity> }activities activity list
 * @param {string} activityName select activity
 * @returns true - activity is valid, otherwise false
 */
export const isValidActivity = (
    activities: Array<IActivity>,
    activityName: string
): boolean => {
    try {
        return activities.some(
            (activity: IActivity) => activity.displayName === activityName
        );
    } catch (error) {
        return false;
    }
};

/**
 * @function
 * @description filter activity according to followup entity type
 * @param {Array<IActivity>} data activity data
 * @param {string} filter filter value
 * @returns filter activities
 */
export const filterActivityByEntityType = (
    data: Array<IActivity>,
    filter: string | undefined
): Array<IActivity> => {
    try {
        return data.filter((activity: IActivity) => activity.entitytype == filter);
    } catch (error) {
        return data;
    }
};

/**
 * @function
 * @description get activities for activity table
 * @param {Array<IActivity>} data activity data
 * @param {string} filter filter value
 * @returns {Array<IActivityTableData>} formatted data for activity table
 */
export const getFilterActivityByEntityType = (
    data: Array<IActivity>,
    filter: string | undefined
): Array<IActivity> => {
    try {
        return filter ? filterActivityByEntityType(data, filter) : data;
    } catch (error) {
        return data;
    }
};

/**
 * @function
 * @description get entityIds according to selected followupIds
 * @param {Array<Object>} data followup result
 * @param {Array<string | number>} followUpIds selected followupIds
 * @param defaultData
 * @returns {Array<string>}  entityIds
 */
export const getEntityIds = (
    data: Array<Object>,
    followUpIds: Array<string | number>,
    defaultData: IDefaultData
): Array<string> => {
    try {
        return data
            .filter((followUp: any) =>
                followUpIds.includes(followUp[FollowUpUniqueColumn.FOLLOWUP_ID])
            )
            .map(
                (followUp: any) =>
                    followUp[getEntityFieldName(followUp, defaultData.entityType)]
            );
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description get main entity field name
 * @param {Object} rawData a raw data of followUo result table
 * @param {string} entityType entity type
 * @returns {string} entity field name
 */
export const getEntityFieldName = (
    rawData: Object,
    entityType: string
): string => {
    let fieldName: string | undefined;
    try {
        switch (entityType) {
            case EntityType.CASE:
            case EntityType.INVOICE:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueColumn.CASE_NO
                );
                break;
            case EntityType.DEBTOR:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueColumn.AR_NO
                );
                break;
            case EntityType.CREDITOR:
                fieldName = Object.keys(rawData).find(
                    (colum: string) => colum === FollowUpUniqueColumn.CREDITOR_NO
                );
                break;
            case EntityType.PAYMENT:
                fieldName = Object.keys(rawData).find(
                    (colum: string) =>
                        colum === FollowUpUniqueColumn.PAYMENT_NO ||
                        colum === FollowUpUniqueColumn.PAYMENT_ID
                );
                break;
            default:
                fieldName = undefined;
        }
    } catch (error) { }
    return typeof fieldName === "undefined"
        ? FollowUpUniqueColumn.CASE_NO
        : fieldName;
};

export const entityTypeConversion = (
    entityType: EntityType | string
): EntityType | string => {
    try {
        if (entityType == EntityType.SUB_CASE) {
            return EntityType.INVOICE;
        } else {
            return entityType;
        }
    } catch (error) {
        return entityType;
    }
};

/**
 * Add version for activity
 * @param activityList Activities
 * @returns 
 */
export const addNewVersionForActivity = (activityList: any): Array<any> => {
    const activityArray = <any>[];
    try{
        activityList.map((activity: any) =>{
            if(activity.activityVersion == ActivityVersion.V2){
                activityArray.push({...activity, key: activity.activityCode + ActivityVersion.V2, isVersionVisible: true })
                activityArray.push({...activity, activityVersion: ActivityVersion.V1, key: activity.activityCode + ActivityVersion.V1, isVersionVisible: true})
            }
            else{
                activityArray.push({...activity, key: activity.activityCode + activity.activityVersion, isVersionVisible: false})
            }
        })
        return activityArray;
    }
    catch{
        return activityArray;
    }
}
/**
 * @function
 * @param version - Activity version
 * @returns usc version
 */
 export const getUSCVersionName = (version: string): string =>{
    try{
        if(version === ActivityVersion.V2) {
            return USCVersion.USC4
        }
        return USCVersion.USC2
    }
    catch{
        return ""
    }
}