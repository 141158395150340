import React from "react";
import { PopOverContent } from "./Components";
import { IDynamicPopOver } from "./Interface";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { $Popover } from "us.common/components";
import { handleEndPointLocals } from "us.common/functions";

/**
 * @description - Component for dynamic popOver form.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2919530635/Court+Fee+Handling+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 10/03/2022
 * @param popOverProps - PopOver component props.
 * @param visible - Wether the popOver visible or not.
 * @param loading - Wether the dynamic popOver content is loading.
 * @param isSaving - Wether the form is submitting.
 * @param disabled - Content is disabled.
 * @param trigger - PopOver trigger type.
 * @param placement - PopOver placement.
 * @param metaData - Object that contain data for popOver content.
 * @param onClose - onClose function for close button.
 * @param onConfirm - onConfirm function called with values after pressing ok button.
 * @param onVisibleChange - Function for handle popOver visibility.
 * @param children - PopOver component child component.
 */
const $C = <T extends IDynamicPopOver>({
  popOverProps,
  visible = false,
  loading = false,
  isSaving = false,
  disabled = false,
  trigger = "click",
  placement = "rightBottom",
  metaData,
  onClose,
  onConfirm,
  onVisibleChange,
  onComplete,
  children,
}: T) => {
  const { t } = useTranslation();

  return (
    <>
      <$Popover
        title={
          <span className="font-weight-bold">
            {handleEndPointLocals(
              `US.COLLECTION.COMMON:COMMON.${metaData?.title}`
            )}
          </span>
        }
        content={
          <PopOverContent
            disabled={disabled}
            loading={loading}
            isSaving={isSaving}
            metaData={metaData}
            onClose={onClose}
            onConfirm={onConfirm}
            onComplete={onComplete}
          />
        }
        trigger={trigger}
        visible={visible}
        placement={placement}
        destroyTooltipOnHide={true}
        onVisibleChange={onVisibleChange}
        {...popOverProps}
      >
        {children}
      </$Popover>
    </>
  );
};

$C.propTypes = {
  popOverProps: PropTypes.any,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  isSaving: PropTypes.bool,
  disable: PropTypes.bool,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  metaData: PropTypes.any,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onVisibleChange: PropTypes.func,
  children: PropTypes.any,
};

export const $DynamicPopOver = $C;
