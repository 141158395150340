import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import LoginLayout from '../../components/Login/LoginLayout'
import LoginForm from '../../components/Login/LoginForm'
import FrogotPass from '../../components/Login/FrogotPass'
import ResetPass from '../../components/Login/ResetPass'
import EmailSent from '../../components/Login/EmailSent'
import ResetSuccess from '../../components/Login/ResetSuccess'
import './Login-container.scss'
import login_logo from './login-logo.svg'


interface ILoginProps extends RouteComponentProps {

}

const Login: React.SFC<ILoginProps> = (props) => {

    return (
        <div className="login">
            <div className="login-bg log-bg" ></div>
            <div className="d-flex flex-row">
                <LoginLayout   {...props} >
                    <LoginForm {...props}/>
                    {/* <FrogotPass /> */}
                    {/* <ResetPass /> */}
                    {/* <EmailSent email={'jhon@email.com'} {...props} /> */}
                    {/* <ResetSuccess {...props}/> */}
                </LoginLayout>
                <div className="d-flex flex-fill login-logo">
                    <img alt="logo" src={login_logo} />
                </div>
            </div>
        </div>
    );
}


export default withRouter(Login);
