import moment from "moment";
import { DateFormat } from "us.common/components/FollowUps/Constants";
import { FollowUpAction } from "us.common/components/FollowUps/Constants";

/**
 * @function
 * @description - repository function to prepare request object from form data for followup action endpoint
 * @param {any} this - fromData
 * @returns request data
 */
export function FollowUpActionReq(this: any) {
  return {
    followupdataIds: [this.key],
    actionName: this.action,
    noteText: this.comment,
    ...(this.action == FollowUpAction.POSTPONE
      ? { postponeDate: moment(this.postponeDate).format(DateFormat) }
      : {}),
    removeAfterExecution: this.action == FollowUpAction.UNDO ? false : true,
  };
}
