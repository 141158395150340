import { IInitialState } from "../Interfaces";
import { aDUserDetail } from "./ADUserData";
import { userData } from "./UserData";
import { userSummary } from "./UserSummary";


export const initialState: IInitialState = {
  users: {
    data: [],
    isLoading: false,
  },
  drawer: {
    title: '',
    isNew: true,
    visible: false,
    selectedUser: userSummary
  },
  adUsers: {
    data: [],
    optionsData: [],
    isLoading: false
  },
  user: {
    data: userData,
    isLoading: false
  },
  aDUser: {
    data: aDUserDetail,
    isLoading: false
  },
  roles: {
    data: [],
    isLoading: false
  },
  authorizationLevel: {
    data: [],
    isLoading: false,
  },
  creditorGroups: {
    data: [],
    isLoading: false,
  },
  creditorCompany: {
    data: [],
    isLoading: false,
  },
  creditors: {
    data: [],
    isLoading: false,
  },
  userHistory: {
    data: [],
    isLoading: false,
  },
};