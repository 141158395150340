export const getPostalAreas = (data: any) => ({
	type: 'GET_POSTAL_AREAS',
	payload: data,
});

export const getPostalAreasSuccess = (data: any) => ({
	type: 'GET_POSTAL_AREAS_SUCCESS',
	payload: data,
});

export const getPostalAreasFail = (data: any) => ({
	type: 'GET_POSTAL_AREAS_FAIL',
	payload: data,
});

export const getMunicipals = () => ({
	type: 'GET_MUNICIPALS',
});

export const getMunicipalsSuccess = (data: any) => ({
	type: 'GET_MUNICIPALS_SUCCESS',
	payload: data,
});

export const addPostalArea = (data: any) => ({
	type: 'ADD_POSTAL_AREA',
	payload: data,
});

export const addPostalAreaSuccess = (data: any) => ({
	type: 'ADD_POSTAL_AREA_SUCCESS',
	payload: data,
});
