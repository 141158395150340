import { GDPRComment } from "us.collection/constants";
import { getGDPRView } from "./Functions";
import { initialState } from "./State";

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GDPRComment.GET_GDPRCOMMENT_VIEW_START:
      return getGDPRView(action.payload.data);

    case GDPRComment.GET_GDPRCOMMENT_VIEW_SUCCESS:
      return Object.assign({}, state, {
        showGDPRCommentBox: false,
        showDashboard: false,
      });
    case GDPRComment.GET_GDPRCOMMENT_VIEW_FAIL:
      return Object.assign({}, state, {
        showGDPRCommentBox: false,
        showDashboard: false,
      });
    case GDPRComment.GET_GDPRCOMMENT_VIEW_RESET:
      return Object.assign({}, state, {
        showGDPRCommentBox: false,
        showDashboard: false,
      });
    case GDPRComment.ADD_GDPR_COMMENT_START:
      return Object.assign({}, state, {
        //showGDPRCommentBox : true,
      });
    case GDPRComment.ADD_GDPR_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        showGDPRCommentBox: false,
        showDashboard: true,
      });
    case GDPRComment.ADD_GDPR_COMMENT_FAIL:
      return Object.assign({}, state, {
        showGDPRCommentBox: true,
        showDashboard: false,
      });
    default:
      return state;
  }
};
