import { IAPIAction } from "us.collection/interfaces";
import { PaymentAgreement } from 'us.collection/constants';
import { IPaymenetAgreement } from 'us.collection/interfaces'

export const paymentAgreement: Readonly<IAPIAction & IPaymenetAgreement> = {
    amounts: {
        get: (data) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    details: {
        get: (data) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    interests: {
        get: (data) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    save: {
        save: (data) => ({
            type: PaymentAgreement.SAVE_PAYMENT_AGREEMENT_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: PaymentAgreement.SAVE_PAYMENT_AGREEMENT_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: PaymentAgreement.SAVE_PAYMENT_AGREEMENT_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    delete: {
        delete: (data) => ({
            type: PaymentAgreement.DELETE_PAYMENT_AGREEMENT_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: PaymentAgreement.DELETE_PAYMENT_AGREEMENT_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: PaymentAgreement.DELETE_PAYMENT_AGREEMENT_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    amountsAndDetails: {
        get: (data) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (
            data
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (
            error
        ) => ({
            type: PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
}