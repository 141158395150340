/**
 * @function
 * @description find activity type name using record key
 * @param {any} dataSource table data 
 * @param {any} selectedRowKeys key array of selected rows
 * @returns {any} selected records
 */
export const findSelectedName = (dataSource: any, selectedRowKeys: any): any => {
    try {
        const selectedRecord = dataSource.find(
            (data: any) => data.id == selectedRowKeys[0]
        );
        return selectedRecord ?? {};
    } catch (error) {
        return {};
    }
}