import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined, MenuOutlined } from "us.icons";
import {
  $Popconfirm,
  $Tooltip,
  $Button,
  $DynamicComponent,
} from "us.common/components";
import { IDragNDropItem } from "../../Interfaces";
import { SelectProps, ItemTypes, TypeCode } from "../../Constants";
import { useDrag, useDrop } from "react-dnd";
import { DynamicComponent } from "us.common/constants";
import "./DragNDropItem.scss";

/**
 * @description - Drag and Drop Item
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/TCUI/pages/3051487282/Drag+and+Drop+Component
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 17/07/2022
 */
const DragNDropItem: React.FC<IDragNDropItem> = ({
  data,
  index,
  selectProps = SelectProps,
  mode,
  onMove,
  onChange,
  onDeleteItem,
  onDropdownVisibleChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  // drag is referance to which element to be draggable
  /**
   * @description useDrag hook call
   */
  const [{ isDragging }, drag] = useDrag({
    item: { ...data, index, type: ItemTypes.SEQUENCE },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag() {
      return true;
    },
    end() {
      //   handleInstallments(values);
    },
  });

  /**
   * @description useDrop hook call
   */
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.SEQUENCE,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
    drop(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;

      // Determine mouse position
      const mousePosition = monitor.getClientOffset() as any;

      // Get pixels to the top
      const hoverClientY = mousePosition.y - hoveredRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove({ dragIndex, hoverIndex });

      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`d-flex align-items-center cq-item py-2 ${
        data.isNew ? "cq-item-new" : ""
      } ${isDragging ? "cq-item-dragging" : ""} ${
        isOver ? "cq-item-over" : ""
      }`}
    >
      <div className="d-flex  flex-row more-item">
        <div className="mr-3  pl-2 font-sm text-muted drag-courser">
          <MenuOutlined />
        </div>
      </div>
      <div className="px-3 font-lg font-weight-bold">{data.id + 1}</div>
      <div className="w-100">
        <$DynamicComponent
          key={data.id.toString()}
          name={`sequenceDetails.${index}.value`}
          value={data.value}
          component={data.componentType ?? DynamicComponent.Label}
          size="small"
          withColumns={true}
          visible={true}
          options={selectProps?.options}
          optionText={selectProps?.optionText}
          optionValue={selectProps?.optionValue}
          onSearchBy={selectProps?.onSearchBy}
          mode={mode}
          allowClear
          onChange={(val: Array<any>) => {
            onChange && onChange(val, data.id);
          }}
          onDropdownVisibleChange={(open: boolean) => {
            onDropdownVisibleChange(open, data.id);
          }}
        />
      </div>
      {data.type === TypeCode.ITEM_TYPE && (
        <div className="mx-2">
          <$Popconfirm
            title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
            onConfirm={() => onDeleteItem && onDeleteItem(data.id, data.value)}
          >
            <$Tooltip
              placement="top"
              title={t("US.COLLECTION.COMMON:COMMON.DELETE")}
            >
              <$Button
                type="link"
                size="small"
                danger
                icon={<CloseOutlined />}
              />
            </$Tooltip>
          </$Popconfirm>
        </div>
      )}
    </div>
  );
};

export default DragNDropItem;
