import React from 'react';
import { TabController } from 'us.common/components/MainSearch/Components';
import { IInvoiceTab } from 'us.common/components/MainSearch/Interfaces';
import Invoice from './Invoice';
import _ from 'lodash';

/**
 * @description - Tab container for invoice result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const InvoiceTab: React.FC<IInvoiceTab> = (props) => {
	const { tabInfo, isLoading, totalInvoiceSearchResult, changeSortOrderEvent } =
		props;
	const invoiceProps = _.omit(props, ['isLoading']);

	return (
		<TabController
			isLoading={isLoading}
			changeSortOrderEvent={changeSortOrderEvent}
			tabInfo={tabInfo}>
			<Invoice {...invoiceProps} totalSearchResult={totalInvoiceSearchResult} />
		</TabController>
	);
};
