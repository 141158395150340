import React from 'react';
import { TabController } from 'us.common/components/MainSearch/Components';
import { ICaseTab } from 'us.common/components/MainSearch/Interfaces';
import Case from './Case';
import _ from 'lodash';

/**
 * @description - Tab container for case result view.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const CaseTab: React.FC<ICaseTab> = (props) => {
	const { isLoading, tabInfo, totalCaseSearchResult, changeSortOrderEvent } =
		props;
	const caseProps = _.omit(props, ['isLoading']);

	return (
		<TabController
			isLoading={isLoading}
			changeSortOrderEvent={changeSortOrderEvent}
			tabInfo={tabInfo}>
			<Case {...caseProps} totalSearchResult={totalCaseSearchResult} />
		</TabController>
	);
};
