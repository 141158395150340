import { CaseTypes } from 'us.common/components/MainSearch/Constants';
import { IInvoiceSearchResult } from 'us.common/reducers/MainSearch/Interfaces';
import { IFilterValues } from '../Interfaces';

/**
 * @function
 * @description Determine if the invoice should be included based on the filter criteria
 * @param inv Element of invoice Result
 * @param filterValues The new filter values for the invoice tab
 * @returns the boolean decision if the invoice should be included
 */
export const doesInvoiceInclude = (
	inv: IInvoiceSearchResult,
	filterValues: IFilterValues
): boolean => {
	try {
		return (
			((filterValues.isOpenCase && inv.caseType === CaseTypes.OPEN) ||
				(filterValues.isCloseCase && inv.caseType === CaseTypes.CLOSED) ||
				(filterValues.isNextDueDateCase &&
					inv.caseType === CaseTypes.NEXT_DUE_DATE) ||
				![
					CaseTypes.OPEN,
					CaseTypes.CLOSED,
					CaseTypes.NEXT_DUE_DATE,
					CaseTypes.SENTENCE_RECEIVED,
					CaseTypes.SENT_TO_COURT,
				].includes(inv.caseType)) &&
			(filterValues.caseNumber
				? inv.exCaseNo.toString().includes(filterValues.caseNumber)
				: true) &&
			(filterValues.invoiceNumber
				? inv.invoiceNumber.toString().includes(filterValues.invoiceNumber)
				: true) &&
			(filterValues.mainAmount
				? inv.mainAmount.toString().includes(filterValues.mainAmount)
				: true) &&
			(filterValues.balance ? inv.balance === +filterValues.balance : true) &&
			(filterValues.debtorNameNo
				? inv.debtorName
						.toUpperCase()
						.includes(filterValues.debtorNameNo.toUpperCase()) ||
				  inv.debtorNo === filterValues.debtorNameNo
				: true) &&
			(filterValues.creditorNameNo
				? inv.creditorName
						.toUpperCase()
						.includes(filterValues.creditorNameNo.toUpperCase()) ||
				  inv.creditorNo.toString() === filterValues.creditorNameNo
				: true) &&
			(filterValues.birthdate
				? inv.birthDate === filterValues.birthdate
				: true) &&
			(filterValues.phone ? inv.phone === filterValues.phone : true) &&
			(filterValues.address
				? inv.address
						.replaceAll(/\s+/g, ' ')
						.toUpperCase()
						.includes(
							filterValues.address.replaceAll(/\s+/g, ' ').toUpperCase()
						)
				: true) &&
			(filterValues.lastMsgActivityName
				? inv.lastMessageActivityName
						.toUpperCase()
						.includes(filterValues.lastMsgActivityName.toUpperCase())
				: true)
		);
	} catch (e) {
		return false;
	}
};
