import {
  doesCreditorInclude,
  doesCreditorGroupInclude,
} from "us.common/components/MainSearch/Functions";
import {
  ICreditorGroupSearchResult,
  ICreditorSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { IFilterValues } from "../Interfaces";

/**
 * @function
 * @description To get the redux creditor search values filtered
 * @param creditorDetails The initial creditor search resutls - taken from Redux state
 * @param filterValues The formik input field values
 * @returns Filtered Creditor search results based on the formik input field values
 */
export const getCreditorDataFiltered = (
  creditorDetails: ICreditorGroupSearchResult[],
  filterValues: IFilterValues
): ICreditorGroupSearchResult[] | [] => {
  try {
    let creditorDataHolder: ICreditorSearchResult[];
    const filteredData = creditorDetails.flatMap((creditorGroupData) => {
      creditorDataHolder = creditorGroupData.creditors.flatMap(
        (creditorData) => {
          if (doesCreditorInclude(creditorData, filterValues)) {
            return [creditorData];
          } else {
            return [];
          }
        }
      );
      if (
        doesCreditorGroupInclude(creditorGroupData, filterValues) &&
        creditorDataHolder.length > 0
      ) {
        return [
          {
            ...creditorGroupData,
            creditors: creditorDataHolder,
          },
        ];
      } else {
        return [];
      }
    });
    return filteredData.map((creditor) => ({
      ...creditor,
      isCreditorGroupCollapsed:
        filteredData.length === 1 ? false : creditor.isCreditorGroupCollapsed,
    }));
  } catch (e) {
    return [];
  }
};
