import _ from "lodash";
import { ActivityExecution, Mode } from "us.common/constants";
import { getFieldName, getParameterTableData } from "us.common/functions";
import { initialState } from "./State";

const {
    GET_ACTIVITIES_START,
    GET_ACTIVITIES_SUCCESS,
    GET_ACTIVITIES_FAIL,
    GET_ACTIVITY_PARAMETERS_START,
    GET_ACTIVITY_PARAMETERS_SUCCESS,
    GET_ACTIVITY_PARAMETERS_FAIL,
    UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA,
    MANAGE_PARAMETER_DROP_DOWN,
    SET_ACTIVITY_DATA,
    RESET_ACTIVITY_DATA,
    EXECUTE_ACTIVITY_START,
    EXECUTE_ACTIVITY_SUCCESS,
    EXECUTE_ACTIVITY_FAIL,
    SET_ACTIVITY_MODE
} = ActivityExecution;

export default (state = initialState, { payload, type }: any) => {
    switch (type) {
        case GET_ACTIVITIES_START:
            return Object.assign({}, state, {
                activities: {
                    data: [],
                    isFetching: true
                }
            });
        case GET_ACTIVITIES_SUCCESS:
            return Object.assign({}, state, {
                activities: {
                    data: payload.data,
                    isFetching: false
                }
            });
        case GET_ACTIVITIES_FAIL:
            return Object.assign({}, state, {
                activities: {
                    data: [],
                    isFetching: false
                }
            });
        case GET_ACTIVITY_PARAMETERS_START:
            return Object.assign({}, state, {
                parameters: {
                    isEnableDropDown: false,
                    list: [],
                    dataSource: [],
                    isFetching: true
                },
            });
        case GET_ACTIVITY_PARAMETERS_SUCCESS:
            return Object.assign({}, state, {
                parameters: {
                    isEnableDropDown: !_.isEmpty(
                        getFieldName(payload.data.settings, state.defaultData.entityType)
                    ),
                    list: payload.data.settings,
                    dataSource: getParameterTableData(
                        payload.data.settings,
                        state.defaultData.entityType
                    ),
                    isFetching: false
                },
                activityFormDetail: {
                    initialValues: {
                        ...state.activityFormDetail.initialValues,
                        ...getFieldName(
                            payload.data.settings,
                            state.defaultData.entityType
                        ),
                    },
                },
            });
        case GET_ACTIVITY_PARAMETERS_FAIL:
            return Object.assign({}, state, {
                parameters: {
                    isEnableDropDown: false,
                    list: [],
                    dataSource: [],
                    isFetching: false
                },
                activityFormDetail: {
                    initialValues: initialState.activityFormDetail.initialValues,
                },

            });
        case EXECUTE_ACTIVITY_START:
            return Object.assign({}, state, {
                isRefreshRoutineHistory: false,
                isExecuting: true,
            });
        case EXECUTE_ACTIVITY_SUCCESS:
            return Object.assign({}, state, {
                isRefreshRoutineHistory: true,
                isExecuting: false,
            });
        case EXECUTE_ACTIVITY_FAIL:
            return Object.assign({}, state, {
                isRefreshRoutineHistory: true,
                isExecuting: false,
            });
        case UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA:
            return Object.assign({}, state, {
                defaultData: payload.data,
            });
        case MANAGE_PARAMETER_DROP_DOWN:
            return Object.assign({}, state, {
                parameters: {
                    ...state.parameters,
                    isEnableDropDown: false,
                },
            });
        case SET_ACTIVITY_DATA:
            return Object.assign({}, state, {
                activityFormDetail: {
                    initialValues: payload.data,
                },
            });
        case RESET_ACTIVITY_DATA:
            return Object.assign({}, state, {
                activityFormDetail: {
                    initialValues: initialState.activityFormDetail.initialValues,
                },
                parameters: {
                    ...state.parameters,
                    isEnableDropDown: false,
                },
            });
        case SET_ACTIVITY_MODE:
            return Object.assign({}, state, {
                activityExecutionMode: payload.data.mode
            });
        default:
            return state;
    }
};
