import React, { useEffect, useMemo } from 'react';
import {
	$AutoComplete,
	$Divider,
	$Message,
	$Tooltip,
} from 'us.common/components';
import { useTranslation } from 'react-i18next';
import { navigateStart } from 'us.common/actions/commonActions';
import { MainSearchActions } from 'us.common/actions';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'antd';
import QueryString from 'query-string';
import { ISearchQuery } from 'us.common/interfaces';
import {
	getMainSearchCriterias,
	isValidDate,
} from 'us.common/functions/MainSearch';
import { ISearchBar, ICombinedEnums } from './Interfaces';
import { ILabeledValue } from 'us.common/interfaces/MainSearch';
import {
	CaseSubCriterias,
	CreditorSubCriterias,
	COLEN,
	DebtorSubCriterias,
	InvoiceSubCriterias,
	MainCriterias,
} from 'us.common/constants';
import { getDropDownOptionValues } from 'us.common/functions/MainSearch';
import { getRouteUrl } from 'us.helper';
import {
	getMatchingSubCriterias,
	getMatchingSubEnum,
	getSearchRegExps,
	getFormikSearchBarValues,
} from './Functions';
import './SearchBar.scss';
import { RootState } from 'us.helper/types';
import { useLocation } from 'react-router-dom';

/**
 * @description - Main search input component for search data and view recent searches.
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/05/2022
 * */
const MainSearchBar: React.FC<PropsFromRedux> = (props) => {
	const { t } = useTranslation(['US.COLLECTION.COMMON'], {
		useSuspense: true,
	});
	const {
		backNavigation,
		viewedList,
		searchHistory,
		navigateStart,
		getSearchHistory,
	} = props;

	const { pathname } = useLocation();
	const historyPrefix: string = 'case-no:';

	const caseNo: number = useMemo(() => {
		// checking if routing has a caseNo
		const urlCaseNo = pathname.match(
			new RegExp('/case/[0-9]+', 'g')
		);
		let caseNo: number = 0;
		if (urlCaseNo && urlCaseNo?.length > 0) {
			caseNo = parseInt(
				urlCaseNo[0]
					.replace(new RegExp('/case/', 'g'), '')
					.trim()
			);
		}
		return caseNo;
	}, [pathname]);

	const historyBar = (
		<div className='recent-history'>
			<div className='my-2 mx-n2'>
				<$Divider className='m-0' />
			</div>
			{t('US.COLLECTION.COMMON:COMMON.RECENT_SEARCH_HISTORY')}
		</div>
	);

	useEffect(() => {
		if (!backNavigation?.isBack && viewedList?.length == 0) {
			getSearchHistory && getSearchHistory({});
		}
	}, [caseNo]);

	/**
	 * @function
	 * @description called to get the Autocomplete UI segment
	 * @param exCaseNo external case number of the history visited cas
	 * @param debtorName name of the case debtor
	 * @returns hisotry part of the autocomplete dropdown
	 */
	const GetHistoryUI = (exCaseNo: string, debtorName: string): any => {
		return {
			value: `${historyPrefix} ${exCaseNo}`,
			label: (
				<div className='d-flex recent-history-item'>
					<div className='pb-1 pr-3 rhi-caseno'>
						<$Tooltip
							placement='topLeft'
							title={exCaseNo}>
							<a type='link'>
								<strong>
									{
										exCaseNo
									}
								</strong>
							</a>
						</$Tooltip>
					</div>
					<div className='flex-grow-1 pb-1 pr-3'>
						{debtorName}
					</div>
				</div>
			),
		};
	};

	/**
	 * @function
	 * @description Navigate to proper view by exCaseNo
	 * @param exCaseNo external case number of the selected history case value
	 */
	const navigateToHistoryURL = (exCaseNo: string) => {
		// find exCaseNo included in the history
		const selectedItem = searchHistory.data?.filter(
			(historyItem: any) => historyItem.caseNo === exCaseNo
		)[0];
		// then check and navigate
		if (selectedItem) {
			getRouteUrl.moduleRoute(
				'collection',
				`/case/${exCaseNo}`
			);
		}
	};

	let isQuickFirstCall = true;
	/**
	 * @function
	 * @description Called on selection of a dropdown value to be searched.
	 * @var isQuickFirstCall is used to avoid the double calling this method from AutoComplete and input methods
	 * @param searchInput The full text of the input value at the time of pressing enter or clicking the dropdown value
	 * @returns navigate to the relevent url
	 */
	const handleSearch = (searchInput: string) => {
		if (!isQuickFirstCall) {
			return;
		}
		searchInput = searchInput.trim().replaceAll(/\s+/g, ' ');
		if (searchInput.includes(historyPrefix)) {
			const exCaseNo = searchInput
				.replace(historyPrefix, '')
				.trim();
			// it's a history item search
			navigateToHistoryURL(exCaseNo);
		} else {
			// it's a category input search
			let searchQuery: ISearchQuery;

			searchInput = searchInput
				? searchInput.trim().replaceAll(/\s+/g, ' ')
				: '';
			const mainCriteriasSelected: MainCriterias[] =
				Object.values(MainCriterias).filter((item) =>
					searchInput.match(
						new RegExp(
							`^\\s*${item + COLEN}`
						)
					)
				);

			if (mainCriteriasSelected.length > 0) {
				const matchingSubCriteria: ICombinedEnums =
					getMatchingSubEnum(
						mainCriteriasSelected[0]
					);

				const subCriteriasSelected = Object.values(
					matchingSubCriteria
				).filter((subCritera) => {
					if (
						subCritera ===
						DebtorSubCriterias.BIRTHDAY
					) {
						return searchInput
							.replaceAll(
								/[\(|\)]/g,
								''
							)
							.match(
								getSearchRegExps(
									mainCriteriasSelected[0],
									subCritera.replaceAll(
										/[\(|\)]/g,
										''
									)
								)
									.inputWithSubCriteria
							);
					}
					return searchInput.match(
						new RegExp(
							`^\\s*${
								mainCriteriasSelected +
								COLEN
							}\\s*${
								subCritera +
								COLEN
							}`
						)
					);
				});

				if (subCriteriasSelected.length > 0) {
					// main criteria and sub criteria both selected

					if (
						subCriteriasSelected[0] ===
						DebtorSubCriterias.BIRTHDAY
					) {
						// checking if the birthday is in the correct format
						if (
							searchInput
								.split(COLEN)
								.slice(2)
								.join(COLEN)
								.trim() !==
								'' &&
							!isValidDate(
								searchInput
									.split(
										COLEN
									)
									.slice(
										2
									)
									.join(
										COLEN
									)
									.trim()
							)
						) {
							$Message.warning({
								content: t(
									'US.COLLECTION.COMMON:COMMON.BIRTHDAY_IS_IN_INVALID_FORMAT'
								),
								style: {
									marginTop: '40px',
								},
							});
							isQuickFirstCall =
								false;
							setTimeout(() => {
								isQuickFirstCall =
									true;
							}, 500);
							return;
						}
					}
					searchQuery = {
						cat: mainCriteriasSelected[0],
						sub: subCriteriasSelected[0],
						value: searchInput
							.split(COLEN)
							.slice(2)
							.join(COLEN)
							.trim(),
					};
				} else {
					// only main criteria is selected
					searchQuery = {
						cat: mainCriteriasSelected[0],
						value: searchInput
							.split(COLEN)
							.slice(1)
							.join(COLEN)
							.trim(),
					};
				}
			} else {
				// searching only a value
				searchQuery = {
					value: searchInput.trim(),
				};
			}
			navigateStart({
				url:
					'/search?' +
					QueryString.stringify(searchQuery),
			});
		}
		isQuickFirstCall = false;
		setTimeout(() => {
			isQuickFirstCall = true;
		}, 500);
	};

	/**
	 * @function
	 * @description called upon input change of autocomplete
	 * @param inputValue The input value typed by user
	 * @param setValues Formik property setter
	 */
	const handleAutoCompleteChange = (
		inputValue: string,
		setValues: any
	) => {
		if (inputValue && inputValue.length >= 300) {
			$Message.warning(
				t(
					'US.COLLECTION.COMMON:COMMON.INPUT_LIMIT_EXCEEDS'
				)
			);
			return;
		}
		inputValue = inputValue
			? inputValue.trim().replaceAll(/\s+/g, ' ')
			: '';

		const mainCriteriasSelected: ILabeledValue<MainCriterias>[] =
			getMainSearchCriterias().filter(
				(mainCriteria: ILabeledValue<MainCriterias>) =>
					inputValue.match(
						getSearchRegExps(
							mainCriteria.label,
							''
						).inputWithMainCriteria
					)
			);
		if (mainCriteriasSelected.length > 0) {
			const matchingSubCriteria: ILabeledValue<
				| InvoiceSubCriterias
				| CaseSubCriterias
				| DebtorSubCriterias
				| CreditorSubCriterias
			>[] = getMatchingSubCriterias(
				mainCriteriasSelected[0].value
			);

			const subCriteriasSelected = matchingSubCriteria.filter(
				(subCritera) => {
					if (
						subCritera.label ===
						DebtorSubCriterias.BIRTHDAY
					) {
						return inputValue
							.replaceAll(
								/[\(|\)]/g,
								''
							)
							.match(
								getSearchRegExps(
									mainCriteriasSelected[0]
										.label,
									subCritera.label.replaceAll(
										/[\(|\)]/g,
										''
									)
								)
									.inputWithSubCriteria
							);
					}
					return inputValue.match(
						getSearchRegExps(
							mainCriteriasSelected[0]
								.label,
							subCritera.label
						).inputWithSubCriteria
					);
				}
			);

			if (
				subCriteriasSelected.length > 0 &&
				inputValue.trim().split(COLEN).length === 3
			) {
				// when main-criterial and sub-criteria is selected

				const searchingValue = inputValue
					.trim()
					.split(':')[2]
					.trim();
				setValues((preVals: any) =>
					getDropDownOptionValues(
						mainCriteriasSelected[0],
						matchingSubCriteria,
						searchingValue,
						preVals
					)
				);
			} else if (
				inputValue.trim().split(COLEN).length === 2
			) {
				// when only the min criteria is selected
				const searchingValue = inputValue
					.trim()
					.split(':')[1]
					.trim();
				setValues((preVals: any) =>
					getDropDownOptionValues(
						mainCriteriasSelected[0],
						matchingSubCriteria,
						searchingValue,
						preVals
					)
				);
			} else {
				setValues((preVals: any) =>
					getDropDownOptionValues(
						undefined,
						getMainSearchCriterias(),
						inputValue.trim(),
						preVals
					)
				);
			}
		} else {
			// when no criteria is selected
			setValues((preVals: any) =>
				getDropDownOptionValues(
					undefined,
					getMainSearchCriterias(),
					inputValue.trim(),
					preVals
				)
			);
		}
	};

	let formikInitialValues: ISearchBar = getFormikSearchBarValues(
		searchHistory.data,
		caseNo,
		GetHistoryUI,
		historyBar
	);

	return (
		<Formik
			enableReinitialize
			initialValues={formikInitialValues}
			onSubmit={() => {}}>
			{({ values, setValues, setFieldValue }: any) => (
				<div id='autocompleteid'>
					<$AutoComplete
						name='search'
						backfill
						className='center-search-input'
						dropdownClassName='center-search-dropdown'
						dropdownMatchSelectWidth={false}
						dropdownStyle={{ width: 300 }}
						listHeight='500px'
						style={{ width: '420px' }}
						options={values.options}
						// called on typing + drop selection
						onChange={(
							searchValue: string
						) => {
							handleAutoCompleteChange(
								searchValue,
								setValues
							);
						}}
						onSelect={(event: any) => {
							handleSearch(event);
						}}
						onDropdownVisibleChange={(
							openStatus: boolean
						) => {
							setFieldValue(
								'isSearchStart',
								openStatus,
								false
							);
						}}
						value={values.value}
						data-testid='mainsearch-bar'>
						<Input.Search
							placeholder={
								t(
									'US.COLLECTION.COMMON:COMMON.SEARCH_CASES,_SUBCASES,_DEBTOR,_CREDITOR'
								) ?? ''
							}
							onSearch={(
								inputValue: string
							) => {
								handleSearch(
									inputValue
								);
							}}
							data-testid='mainsearch-bar-input'
						/>
					</$AutoComplete>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: RootState) => {
	const { mainSearch } = state;

	const {
		history: searchHistoryResults,
		backNavigation,
		viewedList,
	} = mainSearch;

	return {
		searchHistory: searchHistoryResults,
		backNavigation,
		viewedList,
	};
};

const mapDispatchToProps = {
	navigateStart,
	getSearchHistory: MainSearchActions.history.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MainSearchBar);
