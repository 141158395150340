import { ITransactionTypesAction } from "us.collection/interfaces";
import { TransType } from "us.collection/constants";

/**
 * Transaction Types actions
 */

export const transactionTypes: ITransactionTypesAction = {
  save: (data) => ({
    type: TransType.SAVE_TRANSACTION_TYPE,
    payload: {
      data,
      isLoading: true,
    },
  }),
  success: (data) => ({
    type: TransType.SAVE_TRANSACTION_TYPE_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  fail: (error) => ({
    type: TransType.SAVE_TRANSACTION_TYPE_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  get: (filterType = "All", itemType?) => ({
    type: TransType.GET_TRANS_TYPE_STRAT,
    payload: {
      filterType,
      itemType,
      isLoading: true,
    },
  }),
  getSuccess: (data) => ({
    type: TransType.GET_TRANS_TYPE_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getFail: (error) => ({
    type: TransType.GET_TRANS_TYPE_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
};
