import { IInitialState } from "../Interfaces";
import { roleDetail } from "./RoleDetail";

export const initialState: IInitialState = {
  roles: {
    data: [],
    isLoading: false,
  },
  drawer: {
    title: '',
    isNew: true,
    visible: false,
    roleId: 0
  },
  modules: {
    data: [],
    isFetching: false
  },
  roleDetail: {
    detail: roleDetail,
    isFetching: false
  },
  roleHistory: {
    data: [],
    isFetching: false
  }
};