import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FieldArray } from "formik";
import "../../Court.scss";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "us.icons";
import Common from "us.common";
import * as Actions from "us.collection.admin/actions";
import { IManageCourtTypes } from "./interface";
import { AddNewCourtTypeValidationSchema } from "us.collection.admin/components/Court/Validations";
import { AddNewCourtType } from "us.collection.admin/components/Court/Repository";

const { $Button, $Popconfirm, $Input, $Tooltip, $Form } = Common.Components;

/**
 * @description - Manage court types component to Add, Edit, Delete court types
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2861694980/Manage+Court+Info+UI+-+View
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 02/02/2022
 */

const ManageCourtTypes: React.FC<PropsFromRedux & IManageCourtTypes> = memo(
  (props) => {
    const { t } = useTranslation([
      "US.COLLECTION.ADMIN",
      "US.COLLECTION.COMMON",
    ]);
    const {
      onClose,
      courtTypes,
      addNewCourtType,
      editCourtType,
      deleteCourtType,
    } = props;
    const [editStatus, setEditStatus] = useState<any>({
      isEdit: false,
      key: "",
    });
    return (
      <Formik
        enableReinitialize
        initialValues={{ courtTypes }}
        validationSchema={AddNewCourtTypeValidationSchema}
        onSubmit={(values: any, actions: any) => {}}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          ...rest
        }: any) => (
          <div className="manage-court-types">
            <$Form>
              <div className="d-flex mb-4">
                <div className="flex-grow-1 mr-2">
                  <$Input
                    placeholder={t("US.COLLECTION.ADMIN:COURT.NAME")}
                    label={t("US.COLLECTION.ADMIN:COURT.COURT_TYPE")}
                    name="name"
                    size="small"
                    required
                  />
                </div>
                <$Button
                  size="small"
                  data-testid="Manage_court_types_add"
                  disabled={values.name == undefined || values.name === ""}
                  onClick={() => {
                    rest.setFieldValue("courtTypes", [
                      ...values.courtTypes,
                      { courtTypeId: 0, name: values.name },
                    ]);
                    addNewCourtType &&
                      addNewCourtType(
                        AddNewCourtType.call({ ...values, courtTypeId: -1 })
                      );
                  }}
                  style={{ marginTop: 28 }}
                >
                  <PlusOutlined />
                  {t("US.COLLECTION.ADMIN:COURT.ADD")}
                </$Button>
              </div>
              <FieldArray
                name="courtTypes"
                render={(arrayHelpers) =>
                  values.courtTypes != undefined &&
                  courtTypes.map((item: any, index: number) => {
                    const { name, courtTypeId } = item;
                    return (
                      name !== "ALL" && (
                        <div className="court-types-list" key={index}>
                          <div className="ctl-item">
                            <div className="ctl-title">{name}</div>
                            <div className="ctl-actions">
                              <$Tooltip
                                placement="top"
                                title={t("US.COLLECTION.COMMON:COMMON.EDIT")}
                              >
                                <$Button
                                  type="link"
                                  size="small"
                                  data-testid="Manage_court_type_edit"
                                  onClick={() =>
                                    setEditStatus({
                                      isEdit:
                                        editStatus?.key !== index
                                          ? true
                                          : !editStatus?.isEdit,
                                      key: index,
                                    })
                                  }
                                >
                                  <EditOutlined />
                                </$Button>
                              </$Tooltip>
                              <$Popconfirm
                                title={t(
                                  "US.COLLECTION.ADMIN:COURT.DELETE_COURT_TYPE_CONFIRM"
                                )}
                                onConfirm={() => {
                                  const removedItem =
                                    arrayHelpers.remove(index);
                                  deleteCourtType &&
                                    deleteCourtType(courtTypeId);
                                }}
                              >
                                <$Tooltip
                                  placement="top"
                                  title={t(
                                    "US.COLLECTION.COMMON:COMMON.DELETE"
                                  )}
                                >
                                  <$Button
                                    type="link"
                                    data-testid="Manage_court_types_delete"
                                    size="small"
                                    danger
                                  >
                                    <DeleteOutlined />
                                  </$Button>
                                </$Tooltip>
                              </$Popconfirm>
                            </div>
                          </div>
                          {editStatus?.isEdit && editStatus?.key === index && (
                            <div className="ctl-item ctl-item-edit">
                              <div className="ctl-item-edit-inner">
                                <$Input
                                  name={`courtTypes[${index}].name`}
                                  size="small"
                                />
                                <$Tooltip
                                  placement="top"
                                  title={t("US.COLLECTION.COMMON:COMMON.SAVE")}
                                >
                                  <$Button
                                    type="link"
                                    data-testid="Manage_court_type_edit_save"
                                    size="small"
                                    onClick={() => {
                                      setEditStatus({
                                        isEdit: false,
                                        key: "",
                                      });
                                      const editedType =
                                        values.courtTypes[editStatus?.key];
                                      editCourtType &&
                                        editCourtType(
                                          AddNewCourtType.call({
                                            ...editedType,
                                          })
                                        );
                                    }}
                                  >
                                    <SaveOutlined />
                                  </$Button>
                                </$Tooltip>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })
                }
              />
            </$Form>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <$Button onClick={onClose}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        )}
      </Formik>
    );
  }
);

const mapStateToProps = (state: IRootState) => {
  const { courtInfo } = state;
  const { courtTypes } = courtInfo;

  return {
    courtTypes,
  };
};

const { courtInfo } = Actions;
const { newCourtType, existingCourtType, courtType } = courtInfo;
const { save } = newCourtType;
const { update } = existingCourtType;

const mapDispatchToProps = {
  addNewCourtType: save,
  editCourtType: update,
  deleteCourtType: courtType.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManageCourtTypes);
