import { PaginationDirection } from "../Interfaces";

/**
 * @function
 * @description To get the hit value to be used in the search parameter
 * @param currentHitValue The current hit value used in searching
 * @param paginationDirection The direction of search result navigation. NEXT|PREV|RESET
 * @returns The new hit value
 */
export const getNewHitValue = (
  currentHitValue: number,
  paginationDirection: PaginationDirection
): number => {
  if (paginationDirection === PaginationDirection.RESET) {
    return 1;
  } else {
    return paginationDirection === PaginationDirection.NEXT
      ? currentHitValue + 1
      : currentHitValue - 1;
  }
};
