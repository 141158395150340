import _ from "lodash";
import { ICheckedAction } from "us.common/components/RoleManagement/Components/RoleDetails/Interface";
import {
    IRoleDetail,
    IRoleDetailAction,
    IRoleSummary,
    IRoleSummaryWithExtendedFields,
} from "us.common/interfaces";
import { roleDetail } from "us.common/reducers/RoleManagement/State/RoleDetail";

/**
 * @function
 * @description set activity status as string
 * @param {Array<IRoleSummary>} roles roles summary data
 * @returns
 */
export const setRoleActivityStatusAsAString = (
    roles: Array<IRoleSummary>
): Array<IRoleSummaryWithExtendedFields> => {
    try {
        return roles.map((role: IRoleSummary) => ({
            ...role,
            activeStateString: role.activeState ? "active" : "inactive",
        }));
    } catch (error) {
        return [];
    }
};

/**
 * @function
 * @description get role detail
 * @param {Omit<IRoleDetail, "checkedAction">} detail role detail
 * @returns {IRoleDetail}
 */
export const getRoleDetail = (
    detail: Omit<IRoleDetail, "checkedAction">
): IRoleDetail => {
    try {
        const { actions } = detail;
        let checkedAction: ICheckedAction = {};
        Object.values(_.groupBy(actions, "module.id")).forEach(
            (items: Array<IRoleDetailAction>) => {
                items.forEach(({ id, module }: IRoleDetailAction) => {
                    checkedAction?.hasOwnProperty(module.id)
                        ? (checkedAction[module.id] = [...checkedAction[module.id], id])
                        : (checkedAction[module.id] = [id]);
                });
            }
        );
        return {
            ...detail,
            checkedAction,
        };
    } catch (error) {
        return roleDetail;
    }
};

/**
 * @function
 * @description set save errors message
 * @param {any} errors
 * @returns {string}
 */
export const setSaveRoleErrorMessage = (errors: any): string => {
    try {
        if (
            typeof errors?.errors[0] != "string" &&
            errors?.errors[0]?.error?.includes("role already exist")
        ) {
            return "AN_ACTIVE_ROLE_ALREADY_EXIST_WITH_THE_SAME_NAME";
        } else {
            return "ROLE_CAN_NOT_BE_CREATED";
        }
    } catch (error) {
        return "ROLE_CAN_NOT_BE_CREATED";
    }
};

/**
 * @function
 * @description update existing role list
 * @param {Array<IRoleSummary>} roles
 * @param {Omit<IRoleDetail, "checkedAction">} updatedRole updated role detail
 * @returns {Array<IRoleSummary>}
 */
export const updateExistingRoles = (
    roles: Array<IRoleSummary>,
    updatedRole: Omit<IRoleDetail, "checkedAction">
): Array<IRoleSummary> => {
    const { roleId } = updatedRole;
    try {
        _.remove(roles, (o) => o.roleId == roleId);
        roles.push(_.omit(updatedRole, ["actions"]));
        return roles;
    } catch (error) {
        return roles;
    }
};

/**
 * @function
 * @description set delete errors message
 * @param {any} errors
 * @returns {string}
 */
export const setDeleteErrorMessage = (errors: any): string => {
    try {
        if (errors?.errors[0]?.error?.includes("active users with this role")) {
            return "ROLE_IS_ALREADY_IN_USE";
        } else {
            return "ROLE_CAN_NOT_BE_DELETED`";
        }
    } catch (error) {
        return "ROLE_CAN_NOT_BE_DELETED`";
    }
};

/**
 * @function 
 * @description delete role form existing role list
 * @param {Array<IRoleSummary>} roles
 * @param {number} roleId  role Id
 * @returns {Array<IRoleSummary>}
 */
export const deleteExistingRole = (
    roles: Array<IRoleSummary>,
    roleId: number
): Array<IRoleSummary> => {
    try {
        return roles.filter((role: IRoleSummary) => role.roleId != roleId);
    } catch (error) {
        return roles;
    }
};