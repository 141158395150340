import { ICreditorGroupAction } from "us.collection.admin/interfaces";
import { IAPIAction } from "us.collection/interfaces";
import { CreditorGroup } from "us.collection.admin/constants/Actions";

export const creditorGroup: Readonly<IAPIAction & ICreditorGroupAction> = {
  creditorGroups: {
    get: (data) => ({
      type: CreditorGroup.GET_CREDITOR_GROUPS_IN_BUREAU,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CreditorGroup.GET_CREDITOR_GROUPS_IN_BUREAU_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorGroup.GET_CREDITOR_GROUPS_IN_BUREAU_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },

  creditorGroup: {
    save: (data) => ({
      type: CreditorGroup.SAVE_CREDITOR_GROUP_IN_BUREAU,
      payload: {
        data,
        isLoading: true,
      },
    }),
    update: (data) => ({
      type: CreditorGroup.UPDATE_CREDITOR_GROUP_IN_BUREAU,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CreditorGroup.SAVE_CREDITOR_GROUP_IN_BUREAU_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorGroup.SAVE_CREDITOR_GROUP_IN_BUREAU_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    select: (data) => ({
      type: CreditorGroup.SELECT_CREDITOR_GROUP,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: CreditorGroup.RESET_SELECTED_CREDITOR,
      payload: {
        data,
        isLoading: true,
      },
    }),
    delete: (groupId) => ({
      type: CreditorGroup.DELETE_CREDITOR_GROUP_IN_BUREAU,
      payload: {
        data: groupId,
        isLoading: true
      }
    }),
    deleteSuccess: (data) => ({
      type: CreditorGroup.DELETE_CREDITOR_GROUP_IN_BUREAU_SUCCESS,
      payload: {
        data,
        isLoading: false
      }
    }),
    deleteFail: (error) => ({
      type: CreditorGroup.DELETE_CREDITOR_GROUP_IN_BUREAU_FAIL,
      payload: {
        error,
        isLoading: false
      }
    })
  },

  drawer: {
    set: (data) => ({
      type: CreditorGroup.MANAGE_CREDITOR_GROUP_DRAWER,
      payload: {
        data,
        isLoading: true
      }
    }),
    success: (data) => ({
      type: CreditorGroup.MANAGE_CREDITOR_GROUP_DRAWER_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorGroup.MANAGE_CREDITOR_GROUP_DRAWER_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }), 
  },

  selectedCreditors: {
    get: (data) => ({
      type: CreditorGroup.GET_CREDITORS_BY_GROUP_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: CreditorGroup.GET_CREDITORS_BY_GROUP_ID_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: CreditorGroup.GET_CREDITORS_BY_GROUP_ID_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  }
};