export enum Mode {
    Click = "click",
    Initial = "initial"
}
export enum ActivityParametersState {
    DefaultState = "defaultState"
}

export enum DefaultField {
    EntityNo = 'entityno',
    EntitiyId = "entitiyid",
    EntityId = "entityid",
    Id = "id",
    CaseNo = "caseno",
    CaseId = "caseid",
    ArNo = "arno",
    ArId = "arid",
    CreditorId = "creditorid",
    CreditorNo = "creditorno",
    User = "user",
    UserName = "username",
    PaymentId = "paymentid",
    PaymentNo = "paymentno"
}
export enum InitialField {
    Activity = "activity",
    ExecutingDateTime = "executingDateTime",
    ExecutingDateTimeWithParameter = "executingDateTimeWithParameter",
}

export enum ParameterType {
    INT = "int",
    DATE = "date",
    DATETIME = "datetime",
    BOOLEAN = "boolean",
    LIST = "List",
    STRING = "string",
    VARCHAR = "varchar"
}

export enum Action {
    POSTPONE = "POSTPONE",
    REMOVE = "REMOVE",
    DONE = "DONE",
    UNDO = "UNDO"
}

export enum ActivityQueue {
    Express = "Express"
}
export enum ExecutionSource {
    FollowUpBulkExecution = "FollowUpBulkExecution",
    CasesAndSubCasesBulkExecution = "CasesAndSubCasesBulkExecution"
}
export enum ActivityVersion {
    V1 = "V1",
    V2 = "V2"
}

export const EVENT_ACTIVITY_TYPE = "Event";

export enum USCVersion {
    USC2 = "USC 2.0",
    USC4 = "USC 4.0"
}
export enum URLType {
    CASE = "case",
    SUBCASE = 'subcase',
    AR = 'ar',
    CREDITOR = 'creditor',
    CREDITORGROUP = 'creditorgroup',
    BUREAU = 'bureau',
    EXCASENO = 'caseno',
    CREDITOR_GROUP = 'creditor-group',
    TRANSACTIONSS = 'transactions',
    PAYMENT = 'payment',
    DEBTOR = 'debtor'
}
