import moment from "moment";

export function NewDebtor(this: any) {
  return {
    entRoleId: -1,
    entId: -1,
    entityType: "DEB",
    firstName: this.firstName,
    lastName: this.lastName,
    idNo: this.idNo,
    birthDate: this.birthDate,
    pid: "",
    gender: null,
    address1: this.address1,
    address2: this.address2,
    address3: this.address3,
    isAddressKnown: null,
    zipCode: this.zipCode,
    zipName: this.zipName,
    countyCode: null,
    municipalityCode: null,
    municipalityName: null,
    countryCode: this.country,
    email: this.emailAddress,
    preferredCommunicationMethod: null,
    bankAccountNo: "",
    debtorType: this.otherpartyType,
    creditorVATStatus: "",
    regDate: moment(),
    tags: this.tags,
    phoneNumbers: this.phoneNumbers,
    groupNo: 0,
    belongstocredno: 0,
    prductNo: 0,
    accountNo: null,
    remitAccount: null,
    contactInfo: null,
    caseHandler: 0,
    agreement: null,
    isVat: false,
    otherInfo: null,
    personNo: this.personNo,
    companyNo: this.nin,
    telephone: this.home?.trim(),
    sms: this.sms?.trim(),
    addrUnknown: false,
    isAddressknown: true,
    creditorReference: this.creditorReference
  };
}

export function NewRoleType(this: any) {
  return {
    otherPartyRoleType: this.addRoleType,
    otherPartyType: this.values?.otherpartyType,
  };
}

export function PostalCode(this: any, values: any) {
  return {
    countryCode: values?.country?.length === 0 ? "NO" : values?.country,
    searchText: this.zipcode,
    searchBy: "PostalCode",
  };
}

export function MunicipalityCode(this: any, values: any) {
  return {
    countryCode: values?.country?.length === 0 ? "NO" : values?.country,
    searchText: this.countyCode,
  };
}

export function OtherParty(this: any, data: any) {
  return {
    action: "add",
    entityId: this.id,
    ...data,
    birthDate: this.bDate,
    countryId: data?.country,
    otherpartyRoleType: this.role[0]?.label,
    otherpartyBelongstype: this.type,
    isAddressKnown: data?.zipCode?.length > 0 ? true : false,
    debtorEntRoleId: this.debtorEntRoleId,
    telephone: this.home?.trim(),
    sms: this.sms?.trim(),
  };
}

export function RoleType(otherPartyType: string, tab: string) {
  return {
    otherPartyType: otherPartyType,
    currentTab: tab,
  };
}

export function Entity(this: any) {
  return {
    roleType: this.props.roleType ?? "",
    searchCriteria: this.searchCriteria,
    text: this.text,
  };
}
