import * as Yup from "yup";

const AddEditExecutionProfileSchema = Yup.object().shape({
  activityExecutionProfileId: Yup.number(),
  profiles: Yup.array().of(
    Yup.object().shape({
      id: Yup.number(),
      profileName: Yup.string(),
    })
  ),
  profileName: Yup.string()
    .required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    )
    .when(
      ["activityExecutionProfileId", "profiles"],
      (activityExecutionProfileId: any, profiles: any, Yup: any) => {
        if (activityExecutionProfileId > -1) {
          return Yup.test(
            "profileName",
            "US.COLLECTION.VALIDATIONS:INVALID.THE_NAME_ALREADY_EXISTS_PLEASE_ENTER_ANOTHER NAME",
            (profileName: string) =>
              !profiles?.some(
                (profile: any) =>
                  profile?.profileName == profileName &&
                  profile?.id != activityExecutionProfileId
              )
          );
        } else {
          return Yup.test(
            "profileName",
            "US.COLLECTION.VALIDATIONS:INVALID.THE_NAME_ALREADY_EXISTS_PLEASE_ENTER_ANOTHER NAME",
            (profileName: string) =>
              !profiles?.some(
                (profile: any) => profile?.profileName == profileName
              )
          );
        }
      }
    ),
});

export default AddEditExecutionProfileSchema;
