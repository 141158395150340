import { Activity } from 'us.collection/constants';
import { initialState } from './State';
import _ from 'lodash';
import {
    setTableData,
    setGroupsOptions,
    setAutoOptions,
    setActivitiesData,
    filterData,
    setInitialValue
} from 'us.collection/functions';

export default (state = initialState, { payload, type }: any) => {

    switch (type) {
        case Activity.GET_ACTIVITIES_STRAT:
            return Object.assign({}, state, {
                activities: {
                    data: {
                        groups: []
                    },
                    isLoading: true
                }
            });
        case Activity.GET_ACTIVITIES_SUCCESS:
            return Object.assign({}, state, {
                activities: {
                    data: {
                        list: setAutoOptions(payload?.data),
                        groups: [{
                            label: 'All',
                            value: 'all'
                        },
                        ...setGroupsOptions(payload?.data)],
                        dataSource: setActivitiesData(payload?.data),
                        values: payload?.data
                    },
                    isLoading: false
                }
            });
        case Activity.GET_ACTIVITIES_FAIL:
            return Object.assign({}, state, {
                activities: {
                    data: {
                        groups: []
                    },
                    isLoading: false
                }
            });
        case Activity.GET_ACTIVITY_PARAMETERS_START:
            return Object.assign({}, state, {
                parameters: {
                    data: {
                        initailValue: state.initailValue
                    },
                    isLoading: true
                },
                isExecuteSuccess: false,
            });
        case Activity.GET_ACTIVITY_PARAMETERS_SUCCESS:
            return Object.assign({}, state, {
                parameters: {
                    data: {
                        initailValue: {
                            ...state.initailValue,
                            ...setInitialValue(payload?.data?.settings, state)
                        },
                        ...payload?.data,
                        dataSource: setTableData(payload?.data?.settings, state?.userAndCase?.level)
                    },
                    isLoading: false
                }
            });
        case Activity.GET_ACTIVITY_PARAMETERS_FAIL:
            return Object.assign({}, state, {
                parameters: {
                    data: {
                        initailValue: {}
                    },
                    isLoading: false
                }
            });

        case Activity.ACTIVITIES_FILTER_START:
            return Object.assign({}, state, {
                activities: {
                    data: {
                        ...state.activities.data,
                        dataSource: setActivitiesData(filterData(state.activities.data, payload?.data?.filterValue))
                    },
                    isLoading: false
                }
            });
        case Activity.SET_USER_NAME_CASE_NUMBER:
            return Object.assign({}, state, {
                userAndCase: payload?.data
            });
        case Activity.EXCUTE_ACTIVITY_STRAT:
            return Object.assign({}, state, {
                isExecuteSuccess: false,
                isRefreshRoutines: false,
                isExecuting: true
            });
        case Activity.EXCUTE_ACTIVITY_SUCCESS:
            return Object.assign({}, state, {
                isExecuteSuccess: true,
                isRefreshRoutines: true,
                isExecuting: false,
                activityDetail: initialState.activityDetail,
                parameters: {
                    data: {
                        initailValue: {}
                    },
                    isLoading: false
                }
            });
        case Activity.EXCUTE_ACTIVITY_FAIL:
            return Object.assign({}, state, {
                isExecuteSuccess: true,
                isRefreshRoutines: true,
                isExecuting: false,
            });
        case Activity.SET_SELECT_ACTIVITY:
            return Object.assign({}, state, {
                activityDetail: {
                    ...payload?.data,
                    clickTime: Math.random()
                }
            });
        case Activity.RESET_SELECT_ACTIVITY:
            return Object.assign({}, state, {
                activityDetail: initialState.activityDetail,
                parameters: {
                    data: {
                        initailValue: {}
                    },
                    isLoading: false
                }
            });
        case Activity.RESET_PENDING_ACTIVITY_STATE:
            return Object.assign({}, state, {
                isPendingActivityExecuteSuccess: false,
                isPendingActivityNotAvailable: false,
            });
        case Activity.ALL_PENDING_ACTIVITY_EXCUTE_STRAT:
            return Object.assign({}, state, {
                isPendingActivityExecuteSuccess: false,
                isPendingActivityNotAvailable: false,
                isRefreshRoutines: false
            });
        case Activity.ALL_PENDING_ACTIVITY_EXCUTE_SUCCESS:
            return Object.assign({}, state, {
                isPendingActivityExecuteSuccess: true,
                isPendingActivityNotAvailable: false,
                isRefreshRoutines: true
            });
        case Activity.ALL_PENDING_ACTIVITY_EXCUTE_FAIL:
            return Object.assign({}, state, {
                isPendingActivityExecuteSuccess: false,
                isPendingActivityNotAvailable: true,
                isRefreshRoutines: true
            });
        default:
            return state;
    }
}