import {
  FilterCriteriasEnum,
  IFormikTabInfo,
  IFormikTabs,
} from "../Interfaces";
import {
  ICaseSearchResult,
  ICreditorGroupSearchResult,
  IDebtorSearchResult,
  IInvoiceSearchResult,
  ICreditorSearchResult,
  IDebtorARSearchResult,
  IDebtorCaseSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";
import { MainCriterias } from "us.common/constants";

export type CombinedKeyType = IInvoiceSearchResult &
  ICaseSearchResult &
  ICreditorGroupSearchResult &
  ICreditorSearchResult &
  IDebtorSearchResult &
  IDebtorARSearchResult &
  IDebtorCaseSearchResult;

/**
 * @function
 * @description to check if the filter value is a boolean and if it is true.
 * @param {FilterCriteriasEnum} currentKey - The filter criteria key.
 * @param {IFormikTabInfo<CombinedKeyType>} currentTabInfo - Tab information of selected tab.
 * @returns If the filter criteria value is a boolean and checked.
 */
export const isFilterValueChecked = (
  currentKey: FilterCriteriasEnum,
  currentTabInfo: IFormikTabInfo<CombinedKeyType>
): boolean => {
  try {
    const correspondingValue = currentTabInfo.filterKeyValues.find(
      (keyValue: any) => keyValue.key === currentKey
    )?.value;
    if (typeof correspondingValue === "boolean") {
      return correspondingValue;
    } else if (
      typeof correspondingValue === "string" &&
      correspondingValue.trim() !== ""
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

/**
 * @function
 * @description to get the filter value for the key (fieldKey)
 * @param {IFormikTabInfo<CombinedKeyType>} currentTabInfo - Formik state initial tab information.
 * @param {FilterCriteriasEnum} fieldKey - The corresponding key of the filter values.
 * @returns the appropriate field value of the key.
 */
export const getCurrentInputFieldValue = (
  currentTabInfo: IFormikTabInfo<CombinedKeyType>,
  fieldKey: FilterCriteriasEnum
): string | boolean | undefined => {
  try {
    return currentTabInfo.filterKeyValues.find(
      (keyVal: any) => keyVal.key === fieldKey
    )?.value;
  } catch (e) {
    return undefined;
  }
};

/**
 * @function
 * @description To get the numeric value of the filter values.
 * @param {FilterCriteriasEnum} fieldKey - Filter criteria key value.
 * @param {IFormikTabInfo<CombinedKeyType>} currentTabInfo - Formik state initial tab information.
 * @returns The numeric value of for the corresponding filter eky value.
 */
export const getCurrentInputAmount = (
  fieldKey: FilterCriteriasEnum,
  currentTabInfo: IFormikTabInfo<CombinedKeyType>
): number | undefined => {
  try {
    const parsedValue = parseInt(
      currentTabInfo.filterKeyValues
        .find((keyVal: any) => keyVal.key === fieldKey)
        ?.value.toString() ?? ""
    );
    return isNaN(parsedValue) ? undefined : parsedValue;
  } catch (e) {
    return undefined;
  }
};

/**
 * @function
 * @description To get the position of the key - used in generation of dynamic name
 * @param {FilterCriteriasEnum} fieldKey - Filter criteria key value.
 * @param {IFormikTabInfo<CombinedKeyType>} currentTabInfo - Formik state initial tab information.
 * @returns the index of the field - position of the field key
 */
const getCorrespondingFilterIndex = (
  fieldKey: FilterCriteriasEnum,
  currentTabInfo: IFormikTabInfo<CombinedKeyType>
): number => {
  try {
    return currentTabInfo.filterKeyValues.findIndex(
      (keyVal) => keyVal.key === fieldKey
    );
  } catch (e) {
    return -1;
  }
};

/**
 * @function
 * @description To get the Object key of IFormikTabs for the viewing tab.
 * @param {MainCriterias} currentTab - The tab we are viewing.
 * @returns the object key value of IFormikTabs.
 */
const getTabKeyStringValue = (currentTab: MainCriterias): keyof IFormikTabs => {
  try {
    switch (currentTab) {
      case MainCriterias.CASE:
        return "case";

      case MainCriterias.INVOICE:
        return "invoice";

      case MainCriterias.DEBTOR:
        return "debtor";

      case MainCriterias.CREDITOR:
        return "creditor";

      default:
        return "invoice";
    }
  } catch (e) {
    return "invoice";
  }
};

/**
 * @function
 * @description To get the place of the object where we store the values.
 * @param {FilterCriteriasEnum} fieldKey - The filter field key value.
 * @param {MainCriterias} currentTab - Selected tab.
 * @param {IFormikTabInfo<CombinedKeyType>} currentTabInfo - Formik state initial tab information.
 * @returns The string representation of the formik object.
 */
export const getInputName = (
  fieldKey: FilterCriteriasEnum,
  currentTab: MainCriterias,
  currentTabInfo: IFormikTabInfo<CombinedKeyType>
): string => {
  try {
    return `tabInfo.${getTabKeyStringValue(
      currentTab
    )}.filterKeyValues[${getCorrespondingFilterIndex(
      fieldKey,
      currentTabInfo
    )}].value`;
  } catch (e) {
    return "";
  }
};
