import { ManageFee } from "us.collection.admin/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { IManageFeeAction } from "us.collection.admin/interfaces";

const {
  GET_FEE_TYPES,
  GET_FEE_TYPES_SUCCESS,
  GET_FEE_TYPES_FAIL,
  GET_FEE_BASIS,
  GET_FEE_BASIS_SUCCESS,
  GET_FEE_BASIS_FAIL,
  GET_FEE_RANGE,
  GET_FEE_RANGE_SUCCESS,
  GET_FEE_RANGE_FAIL,
  GET_FEE_BASIS_HISTORY,
  GET_FEE_BASIS_HISTORY_SUCCESS,
  GET_FEE_BASIS_HISTORY_FAIL,
  SAVE_FEE_TYPES,
  SAVE_FEE_TYPES_SUCCESS,
  SAVE_FEE_TYPES_FAIL,
  SAVE_FEE_RANGE,
  SAVE_FEE_RANGE_SUCCESS,
  SAVE_FEE_RANGE_FAIL,
  SAVE_FEE_BASIS,
  SAVE_FEE_BASIS_SUCCESS,
  SAVE_FEE_BASIS_FAIL,
  DELETE_FEE_BASIS,
  DELETE_FEE_BASIS_SUCCESS,
  DELETE_FEE_BASIS_FAIL,
  DELETE_FEE_RANGE,
  DELETE_FEE_RANGE_SUCCESS,
  DELETE_FEE_RANGE_FAIL,
  CHANGE_DRAWER_DETAILS,
  MERGE_FEE_RANGE,
} = ManageFee;

export const manageFee: Readonly<IAPIAction & IManageFeeAction> = {
  feeTypeActions: {
    get: (data) => ({
      type: GET_FEE_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_FEE_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_FEE_TYPES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: CHANGE_DRAWER_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  feeBasisActions: {
    getById: (data) => ({
      type: GET_FEE_BASIS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_FEE_BASIS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_FEE_BASIS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  feeBasisHistoryActions: {
    getById: (data) => ({
      type: GET_FEE_BASIS_HISTORY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_FEE_BASIS_HISTORY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_FEE_BASIS_HISTORY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  feeRangeActions: {
    getById: (data) => ({
      type: GET_FEE_RANGE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_FEE_RANGE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_FEE_RANGE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    update: (data) => ({
      type: MERGE_FEE_RANGE,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  newFeeType: {
    save: (data) => ({
      type: SAVE_FEE_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_FEE_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_FEE_TYPES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newFeeBasis: {
    save: (data) => ({
      type: SAVE_FEE_BASIS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_FEE_BASIS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_FEE_BASIS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newFeeRange: {
    save: (data) => ({
      type: SAVE_FEE_RANGE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_FEE_RANGE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_FEE_RANGE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  existingFeeBasis: {
    deleteById: (data) => ({
      type: DELETE_FEE_BASIS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_FEE_BASIS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_FEE_BASIS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  existingFeeRange: {
    deleteById: (data) => ({
      type: DELETE_FEE_RANGE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_FEE_RANGE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_FEE_RANGE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
