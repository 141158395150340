export enum ActivityExecution {
    GET_ACTIVITIES_START = "GET_ACTIVITIES_START",
    GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS",
    GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL",

    GET_ACTIVITY_PARAMETERS_START = "GET_ACTIVITY_PARAMETERS_START",
    GET_ACTIVITY_PARAMETERS_SUCCESS = "GET_ACTIVITY_PARAMETERS_SUCCESS",
    GET_ACTIVITY_PARAMETERS_FAIL = "GET_ACTIVITY_PARAMETERS_FAIL",

    EXECUTE_ACTIVITY_START = "EXECUTE_ACTIVITY_START",
    EXECUTE_ACTIVITY_SUCCESS = "EXECUTE_ACTIVITY_SUCCESS",
    EXECUTE_ACTIVITY_FAIL = "EXECUTE_ACTIVITY_FAIL",

    UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA = "UPDATE_ACTIVITY_PARAMETERS_DEFAULT_DATA",

    MANAGE_PARAMETER_DROP_DOWN = "MANAGE_PARAMETER_DROP_DOWN",

    SET_ACTIVITY_DATA = "SET_ACTIVITY_DATA",
    RESET_ACTIVITY_DATA = "RESET_ACTIVITY_DATA",
    SET_ACTIVITY_MODE = "SET_ACTIVITY_MODE"
}