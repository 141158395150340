import {
  IDebtorSearchResult,
  ICreditorGroupSearchResult,
} from "us.common/reducers/MainSearch/Interfaces";

/**
 * @function
 * @description Change the collapse status of selected debtor row.
 * @param {IDebtorSearchResult[]} debtorSearchResults - Array of search results in debtor tab.
 * @param {IDebtorSearchResult} debtorRow - Selected debtor record.
 * @returns Updated array of objects.
 */
export const setDebtorCollapseState = (
  debtorSearchResults: IDebtorSearchResult[],
  debtorRow: IDebtorSearchResult
): IDebtorSearchResult[] => {
  try {
    if (Array.isArray(debtorSearchResults)) {
      return debtorSearchResults.map((debtor: IDebtorSearchResult) => {
        if (debtor.debtorEntNo === debtorRow.debtorEntNo) {
          return {
            ...debtor,
            isARCollapsed: !debtor.isARCollapsed,
          };
        } else {
          return { ...debtor };
        }
      });
    }
    return [];
  } catch (e) {
    return debtorSearchResults;
  }
};

/**
 * @function
 * @description Change the collapse status of selected creditor row.
 * @param {ICreditorGroupSearchResult[]} creditorSearchResults - Array of creditor search result in creditor tab.
 * @param {number} groupId - Selected group id.
 * @returns Updated array of objects.
 */
export const setCreditorCollapseState = (
  creditorSearchResults: ICreditorGroupSearchResult[],
  groupId: number
): ICreditorGroupSearchResult[] => {
  try {
    if (Array.isArray(creditorSearchResults)) {
      return creditorSearchResults.map(
        (creditorGroup: ICreditorGroupSearchResult) => {
          if (creditorGroup.groupId === groupId) {
            return {
              ...creditorGroup,
              isCreditorGroupCollapsed: !creditorGroup.isCreditorGroupCollapsed,
            };
          } else {
            return { ...creditorGroup };
          }
        }
      );
    }
    return [];
  } catch (e) {
    return creditorSearchResults;
  }
};
