export enum ManageFee {
  GET_FEE_TYPES = "GET_FEE_TYPES",
  GET_FEE_TYPES_SUCCESS = "GET_FEE_TYPES_SUCCESS",
  GET_FEE_TYPES_FAIL = "GET_FEE_TYPES_FAIL",
  CHANGE_DRAWER_DETAILS = "CHANGE_DRAWER_DETAILS",

  SAVE_FEE_TYPES = "SAVE_FEE_TYPES",
  SAVE_FEE_TYPES_SUCCESS = "SAVE_FEE_TYPES_SUCCESS",
  SAVE_FEE_TYPES_FAIL = "SAVE_FEE_TYPES_FAIL",

  GET_FEE_BASIS = "GET_FEE_BASIS",
  GET_FEE_BASIS_SUCCESS = "GET_FEE_BASIS_SUCCESS",
  GET_FEE_BASIS_FAIL = "GET_FEE_BASIS_FAIL",

  SAVE_FEE_BASIS = "SAVE_FEE_BASIS",
  SAVE_FEE_BASIS_SUCCESS = "SAVE_FEE_BASIS_SUCCESS",
  SAVE_FEE_BASIS_FAIL = "SAVE_FEE_BASIS_FAIL",

  DELETE_FEE_BASIS = "DELETE_FEE_BASIS",
  DELETE_FEE_BASIS_SUCCESS = "DELETE_FEE_BASIS_SUCCESS",
  DELETE_FEE_BASIS_FAIL = "DELETE_FEE_BASIS_FAIL",

  GET_FEE_BASIS_HISTORY = "GET_FEE_BASIS_HISTORY",
  GET_FEE_BASIS_HISTORY_SUCCESS = "GET_FEE_BASIS_HISTORY_SUCCESS",
  GET_FEE_BASIS_HISTORY_FAIL = "GET_FEE_BASIS_HISTORY_FAIL",

  REVERT_FEE_BASIS_HISTORY = "REVERT_FEE_BASIS_HISTORY",
  REVERT_FEE_BASIS_HISTORY_SUCCESS = "REVERT_FEE_BASIS_HISTORY_SUCCESS",
  REVERT_FEE_BASIS_HISTORY_FAIL = "REVERT_FEE_BASIS_HISTORY_FAIL",

  GET_FEE_RANGE = "GET_FEE_RANGE",
  GET_FEE_RANGE_SUCCESS = "GET_FEE_RANGE_SUCCESS",
  GET_FEE_RANGE_FAIL = "GET_FEE_RANGE_FAIL",
  MERGE_FEE_RANGE = "MERGE_FEE_RANGE",

  SAVE_FEE_RANGE = "SAVE_FEE_RANGE",
  SAVE_FEE_RANGE_SUCCESS = "SAVE_FEE_RANGE_SUCCESS",
  SAVE_FEE_RANGE_FAIL = "SAVE_FEE_RANGE_FAIL",

  DELETE_FEE_RANGE = "DELETE_FEE_RANGE",
  DELETE_FEE_RANGE_SUCCESS = "DELETE_FEE_RANGE_SUCCESS",
  DELETE_FEE_RANGE_FAIL = "DELETE_FEE_RANGE_FAIL",
}
