export enum DynamicComponent {
  Skeliton,
  Input,
  Label,
  Switch,
  TextArea,
  DatePicker,
  InputAmount,
  DateLabel,
  InputNumber,
  AmountLabel,
  Select,
  Blank,
}
