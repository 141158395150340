import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  $PageHeader,
  $Divider,
  $Affix,
  $Breadcrumb,
} from 'us.common/components';
import * as Action from 'us.common/actions';
import { useField } from 'formik';
import {
  AddPostalArea,
  SelectMunicipal,
  SelectPostalArea,
} from 'us.common/components';

const PostalArea: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const {
    name = 'selectedPostalCodes',
    updateDrawerName,
    multiple,
    postalAreaClose,
    onSubmitSelection,
    countryCode,
  } = props;

  const [field, ,] = useField(name);

  const [selectedPostalCodes, setSelectedPostalCodes] = useState<Array<any>>(
    []
  );

  const [changeDrawerView, setchangeDrawerView] = useState({
    selectPostalAreaView: true,
    addPostalAreaView: false,
    selectMunicipalesView: false,
  });
  const [municipleData, setMunicipleData] = useState({});
  const onClickSelectPostalArea = () => {
    setchangeDrawerView({
      selectPostalAreaView: true,
      addPostalAreaView: false,
      selectMunicipalesView: false,
    });
  };
  const onClickAddPostalArea = () => {
    setchangeDrawerView({
      selectPostalAreaView: false,
      addPostalAreaView: true,
      selectMunicipalesView: false,
    });
  };
  const onClickSelectMuniciples = () => {
    setchangeDrawerView({
      selectPostalAreaView: false,
      addPostalAreaView: false,
      selectMunicipalesView: true,
    });
  };

  const selectMunicipleDetails = (data: any) => {
    setMunicipleData(data);
    onClickAddPostalArea();
  };

  useEffect(() => {
    let codesArray: any = [];
    field.value?.map((val: any) => {
      codesArray.push(val.postalCode);
    });
    field.value?.length && setSelectedPostalCodes(field.value);
  }, []);

  return (
    <>
      {changeDrawerView.selectPostalAreaView ? (
        <>
          {/* <$Breadcrumb>                           
                        <$Breadcrumb.Item>Select Postal Area</$Breadcrumb.Item>
                    </$Breadcrumb> */}
          <SelectPostalArea
            {...props}
            name={name}
            selectedPostalCodes={selectedPostalCodes}
            multiple={multiple}
            onSelect={(selectedKeys: Array<any>) =>
              setSelectedPostalCodes(selectedKeys)
            }
            onSubmitSelection={(selectedKeys: Array<any>) =>
              onSubmitSelection && onSubmitSelection(selectedKeys)
            }
            onClickAddPostalArea={() => onClickAddPostalArea()}
            postalAreaClose={() => postalAreaClose && postalAreaClose()}
          />
        </>
      ) : null}
      {changeDrawerView.addPostalAreaView ? (
        <>
          <$Breadcrumb>
            <$Breadcrumb.Item onClick={() => onClickSelectPostalArea()}>
              <a href="#">{t('COMMON.SELECT_POSTAL_AREA')}</a>
            </$Breadcrumb.Item>
            <$Breadcrumb.Item>
              {t('US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA')}
            </$Breadcrumb.Item>
          </$Breadcrumb>
          <$Affix offsetTop={80}>
            <div className="page-header">
              <div className="d-flex align-items-center">
                <$PageHeader
                  className="px-0 py-0 mr-n3"
                  onBack={() => {
                    onClickSelectPostalArea();
                    updateDrawerName(
                      t('US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA')
                    );
                  }}
                  title={t(
                    'US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA'
                  )}
                />
              </div>
            </div>
          </$Affix>
          <$Divider className="mt-2" />
          <AddPostalArea
            {...props}
            onClickSelectMuniciples={() => onClickSelectMuniciples()}
            onCancelClick={() => onClickSelectPostalArea()}
            municipleData={municipleData}
          />
        </>
      ) : null}
      {changeDrawerView.selectMunicipalesView ? (
        <>
          <$Breadcrumb>
            <$Breadcrumb.Item onClick={() => onClickSelectPostalArea()}>
              <a href="#">{t('COMMON.SELECT_POSTAL_AREA')}</a>
            </$Breadcrumb.Item>
            <$Breadcrumb.Item
              onClick={() => {
                onClickAddPostalArea();
                updateDrawerName(
                  t('US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA')
                );
              }}
            >
              <a href="#">
                {t('US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA')}
              </a>
            </$Breadcrumb.Item>
            <$Breadcrumb.Item>
              {t('COMMON.SELECT_MUNICIPALITY_NAME')}
            </$Breadcrumb.Item>
          </$Breadcrumb>
          <$Affix offsetTop={80}>
            <div className="page-header">
              <div className="d-flex align-items-center">
                <$PageHeader
                  className="px-0 py-0 mr-n3"
                  onBack={() => {
                    onClickAddPostalArea();
                    // props.updateDrawerName(
                    // 	t(
                    // 		'US.COLLECTION.COMMON:ENTITYSELECTION.SELECTPOSTALAREA'
                    // 	)
                    // );
                    updateDrawerName(
                      t('US.COLLECTION.COMMON:ENTITYSELECTION.NEW_POSTAL_AREA')
                    );
                  }}
                  title={t(
                    'US.COLLECTION.COMMON:ENTITYSELECTION.SELECTMUNICIPALITYNAME'
                  )}
                />
              </div>
            </div>
          </$Affix>
          <$Divider className="mt-2 mb-3" />
          <SelectMunicipal
            {...props}
            onCancelClick={() => onClickAddPostalArea()}
            selectMunicipleDetails={(data: any) => selectMunicipleDetails(data)}
            countryCode={countryCode}
          />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {
  updateDrawerName: Action.entity.ChangePostalDrawerTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostalArea);
