import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import { IRootState } from 'us.collection/interfaces';
import { CloseOutlined, PlusOutlined } from 'us.icons';
import Common from 'us.common';
import './SystemSettings.scss';
import {
	DrawerType,
	DRAWER_DEFAULT_STATE,
} from 'us.collection.admin/components/SystemSettings/Constants';
import * as Actions from 'us.collection.admin/actions';
import { ISystemSettings, SystemSetting } from 'us.collection.admin/interfaces';
import { IDrawer } from './Interfaces';
import {
	Menu,
	SettingsTable,
	SettingDetails,
} from 'us.collection.admin/components/SystemSettings/Components';
import { handleGoBack } from "us.helper/utility";

const { $PageHeader, $Affix, $Button, $Search, $Drawer, $Popconfirm } =
	Common.Components;

/**
 * @description -  System settings home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2901967003/View+System+Settings
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 16/03/2022
 */
const Home: React.FC<PropsFromRedux & ISystemSettings> = (props) => {
	const { t } = useTranslation([
		'US.COLLECTION.ADMIN',
		'US.COLLECTION.COMMON',
	]);
	const history = useHistory();
	const { replace, action } = history;
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	const typeId = urlParams.get('type-id');

	const { getAllSettings, searchSettings, resetSettingTypes } = props;

	const [drawer, setDrawer] = useState<IDrawer>(DRAWER_DEFAULT_STATE);
	const [isDetailsEdited, setDetailsEdited] = useState<boolean>(false);

	useEffect(() => {
		if(action !== "REPLACE"){
			getAllSettings && getAllSettings({});
		}
	  }, [action]);

	/**
	 * @description Open the drawer by type
	 * @param {DrawerType} type - Type of the drawer
	 */
	const openDrawer = (type: DrawerType) => {
		const title =
			type === DrawerType.ADD
				? t(
						'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.ADD_NEW'
				  )
				: t('US.COLLECTION.ADMIN:SYSTEM_SETTINGS.EDIT');
		setDrawer({
			visible: true,
			type,
			title,
		});
	};

	// close the opened drawer
	const closeDrawer = () => {
		resetSettingTypes && resetSettingTypes();
		// reset the URL
		updateUrl();
		// update the drawer state to default
		setDrawer(DRAWER_DEFAULT_STATE);
	};

	/**
	 * @description - Handle edit button click event
	 * @param {SystemSetting} record - System setting record
	 */
	const handleEditSetting = (record: SystemSetting) => {
		// update the URL params
		updateUrl(record.id);
		// open drawer to update the record
		openDrawer(DrawerType.UPDATE);
	};

	/**
	 * @description - Update the browser tab url according to given id value
	 * @param {number} id - Setting id if available
	 */
	const updateUrl = (id?: number) => {
		// update the URL params
		replace({
			pathname: '/system-settings',
			search: typeId
				? `?type-id=${typeId}${id ? `&id=${id}` : ''}`
				: `${id ? `?id=${id}` : ''}`,
		});
	};

	/**
	 * @description - Handle search system setting by filtering types and settings
	 * @param {string} searchText - search keyword to test by system setting name
	 */
	const handleSearch = (searchText: string) => {
		searchSettings && searchSettings(searchText);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{}}
			onSubmit={() => {}}>
			{() => (
				<div className='system-category'>
					<div className='space-content'>
						<$Affix offsetTop={48}>
							<div className='page-header header-border'>
								<div className='d-flex flex-row align-items-center justify-content-between'>
									<div className='d-flex align-items-center'>
										<$PageHeader
											className='px-0'
											onBack={() => handleGoBack(history)}
											title={t(
												'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTINGS'
											)}
										/>
									</div>
								</div>
							</div>
						</$Affix>
					</div>

					<div className='sc-layout'>
						<$Affix offsetTop={90}>
							<aside className='sc-layout-side'>
								<div>
									<$Search
										size='small'
										name='settingsSearch'
										className='w-100'
										allowClear={
											true
										}
										placeholder={t(
											'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SEARCH'
										)}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) =>
											handleSearch(
												e
													.target
													.value
											)
										}
									/>
								</div>
								<div className='mt-3'>
									<Menu />
								</div>
							</aside>
						</$Affix>
						<div className='flex-fill'>
							<div className='sc-layout-content'>
								<div className='mb-4'>
									<$Button
										id='add-new-setting'
										className='px-0'
										type='link'
										size='small'
										icon={
											<PlusOutlined />
										}
										onClick={() => {
											openDrawer(
												DrawerType.ADD
											);
										}}>
										{t(
											'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.NEW_SYSTEM_SETTING'
										)}
									</$Button>
								</div>

								<SettingsTable
									onEdit={
										handleEditSetting
									}
								/>
							</div>
						</div>
					</div>

					<$Drawer
						title={
							<div className='d-flex justify-content-between align-items-center'>
								<div className='d-flex'>
									<div className='mr-3'>
										{t(
											drawer.title
										)}
									</div>
								</div>

								<div className='ant-drawer-close'>
									{!isDetailsEdited && (
										<CloseOutlined
											onClick={
												closeDrawer
											}
										/>
									)}

									{isDetailsEdited && (
										<$Popconfirm
											title={t(
												'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?'
											)}
											placement='bottomRight'
											onConfirm={
												closeDrawer
											}
											okText={t(
												'COMMON.YES'
											)}
											cancelText={t(
												'COMMON.NO'
											)}>
											<CloseOutlined />
										</$Popconfirm>
									)}
								</div>
							</div>
						}
						width={500}
						visible={drawer.visible}
						closable={false}
						onClose={closeDrawer}
						destroyOnClose>
						<SettingDetails
							onChange={(
								isChanged: boolean
							) => {
								setDetailsEdited(
									isChanged
								);
							}}
							onClose={closeDrawer}
							type={drawer.type}
						/>
					</$Drawer>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state: IRootState) => {
	const { systemSettings, settingUpdate, settingSave } =
		state.systemSettings;
	return { systemSettings, settingUpdate, settingSave };
};

const { settings } = Actions.systemSettings;

const mapDispatchToProps = {
	getAllSettings: settings.get,
	searchSettings: settings.filter,
	resetSettingTypes: settings.resetSettingTypes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
