import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Admin from "us.collection.admin";

interface ICntManageFollowup extends RouteComponentProps {}
const Cnt_ManageFollowup: React.FC<ICntManageFollowup> = (props) => {
  return (
    <>
      <Admin.Components.ManageFollowup.Home />
    </>
  );
};

export default withRouter(Cnt_ManageFollowup);
