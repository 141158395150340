export enum SideBarMenu {
  AGREEMENTS = "agreements",
  ASSETS = "assets",
  DOCUMENTS = "documents",
  INTEREST = "interest",
  CASE_INFORMATION = "case-information",
  DEBTOR_INFORMATION = "debtor-information",
  ENFORCEMENTS = "enforcements",
  EXTENDED_FIELDS = "extended-fields",
  HISTORY = "history",
  MESSAGE_TEMPLATES = "message-templates",
  OTHER_PARTY = "other-party",
  PART_PAYMENTS = "part-payments",
  SENTENCES = "sentences",
  TRANSACTIONS = "transactions",
  DISTRIBUTION = "distribution",
  APPLICABLE_COURTS = "applicable-courts",
  MANAGE_CREDITOR_GROUPS = "manage-creditor-groups"
}
