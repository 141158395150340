import * as Yup from "yup";
import { StepTypes, Step, ActivityType } from "us.collection.admin/constants";
import { ActivityVersion } from "../Components/ActivitySetUp/Constants";

const validationSchema = {
  [Step.ACTIVITY_SETUP]: Yup.object().shape({
    entityType: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    activityType: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    activityTypeName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    name: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    displayName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    activityCode: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
    ),
    activityVersion: Yup.string(),
    templateCategoryId: Yup.number().when(["activityVersion", "activityType"], {
      is: (activityVersion, activityType) => activityVersion === ActivityVersion.V2 && activityType?.toLowerCase() === ActivityType.MESSAGE.toLowerCase(),
      then: Yup.number().required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD").min(1, "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"),
    }),
  }),
  [Step.SET_CONTROLS]: Yup.object().shape({
    noOfDaysForNextFollowupDate: Yup.number().when("isEnableAutoWFFollowup", {
      is: true,
      then: Yup.number()
        .min(
          0,
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
        )
        .required(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
        )
        .typeError(
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
        ),

    }),
    followupReason: Yup.string().when("isEnableAutoWFFollowup", {
      is: true,
      then: Yup.string().required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD"
      ),
    }),
    newFollowupCategory: Yup.string().when(
      ["isFollowupCategoryExist", "isEnableAutoWFFollowup"],
      {
        is: true,
        then: Yup.string().test(
          "isFollowupCategoryExist",
          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD",
          (val: any) => {
            val = typeof val == "undefined" ? false : val;
            return !val;
          }
        ),
      }
    ),
  }),
  [Step.EXECUTION_PROFILE]: Yup.object().shape({
    activityExecutionProfileId: Yup.number().min(
      1,
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD"
    ),
  }),
};
export const getStepValidationSchema = (step: StepTypes) => {
  switch (step) {
    case Step.ACTIVITY_SETUP:
      return validationSchema[Step.ACTIVITY_SETUP];
    case Step.SET_CONTROLS:
      return validationSchema[Step.SET_CONTROLS];
    case Step.EXECUTION_PROFILE:
      return validationSchema[Step.EXECUTION_PROFILE];
    default:
      return Yup.object();
  }
};
