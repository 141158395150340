export enum ManageFeeAction {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  REVERT = "REVERT",
}

export enum DrawerType {
  FEE_TYPE = "FEE_TYPE",
  FEE_BASIS = "FEE_BASIS",
  FEE_BASIS_HISTORY = "FEE_BASIS_HISTORY",
  FEE_RANGE = "FEE_RANGE",
}

export const INITIAL_DRAWER_DATA = {
  title: "",
  visible: false,
  drawerType: DrawerType.FEE_TYPE,
  actionType: ManageFeeAction.ADD,
  record: {},
};

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: 0,
};

export enum DebtorType {
  P = "P",
  N = "N",
}

export const DEBTOR_TYPE_OPTIONS = [
  {
    label: DebtorType.P,
    value: "P",
  },
  {
    label: DebtorType.N,
    value: "N",
  },
];
