import { INotifications } from "../Interfaces";

export const initialState: INotifications = {
  data: [],
  scroll: false,
  isLoading: false,
  unReadCount: 0,
  pendingEvents: [],
  notificationUpdateId: 0,
  retry:{
    data: null,
    notificationId: 0
  }
};
