import { FeeBasisTableColumns } from "../../Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";
import { IFeeBasisData } from "us.collection.admin/components/ManageFee/Interfaces";
import _ from "lodash";

/**
 * @description Get column search input field current value by key
 * @param key - input field name
 * @returns value - Current value of the input field
 */
export const getColumnFilterProps = (key: FeeBasisTableColumns) => {
  switch (key) {
    case FeeBasisTableColumns.MENU:
      return {};
    case FeeBasisTableColumns.DATE:
      return { customFilter: ColumnFilterTypes.DATE, showFilters: true };
    case FeeBasisTableColumns.AMOUNT:
      return { customFilter: ColumnFilterTypes.AMOUNT, showFilters: true };
    default:
      return { customFilter: true, showFilters: true };
  }
};


/**
 * @description It takes an array of objects, sorts them by a property, and returns the sorted array
 * @param data - Array<IFeeBasisData>
 * @returns An array of IFeeBasisData objects.
 */
export const sortFeeBasicData = (data: Array<IFeeBasisData>): Array<IFeeBasisData> => {
  try {
    return _.orderBy(data, [FeeBasisTableColumns.DATE], ['desc']);
  } catch (error) {
    return data;
  }
}