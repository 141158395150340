export enum PopOverDataType {
  VARCHAR = "varchar",
  INT = "int",
  BIT = "bit",
  DATE_TIME = "datetime",
  DECIMAL = "decimal"
}

export enum DependencyTypes {
  DISABLE = "disable",
  VISIBLE = "visible"
}

export enum Conditions {
  VALUE = "value",
  TRUE = "true",
  FALSE = "false"
}

