import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import "./Home.scss";
import {
  FeeType,
  FeeBasis,
  FeeRange,
  FeeTypeDetails,
  FeeBasisDetails,
  FeeRangeDetails,
} from "us.collection.admin/components/ManageFee/Components";
import { handleGoBack } from "us.helper/utility";
import { useHistory, useLocation } from "react-router-dom";
import * as Actions from "us.collection.admin/actions";
import {
  getDrawerTitle,
  updateUrl,
  getSelectedFeeTypeNameForDrawerTitle,
  deleteFeeRangeFromTable,
} from "./Functions";
import { DrawerType, INITIAL_DRAWER_DATA, ManageFeeAction } from "./Constants";
import { sortFeeBasicData } from "./Components/FeeBasis/Components";

const { $PageHeader, $Affix, $Row, $Drawer, $Message } = Common.Components;

/**
 * @description - Manage Fee home view component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3116171308/Manage+Fee+-+UI+Implementation+Design
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 07/09/2022
 */
const ManageFee: React.FC<PropsFromRedux> = ({
  getFeeTypes,
  feeType,
  deleteFeeBasis,
  deleteFeeRange,
  feeBasis,
  changeDrawerDetails,
  drawerInfo,
  feeRange,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace, action } = history;
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const typeId = urlParams.get("typeId");
  const feeId = urlParams.get("feeId");

  useEffect(() => {
    if(action !== "REPLACE"){
      getFeeTypes && getFeeTypes({});
    }
  }, [action]);

  useEffect(() => {
    if (feeType?.list?.length > 0) {
      if (feeBasis?.list?.length > 0) {
        updateUrl(
          feeType?.list[0]?.feeTypeId,
          replace,
          sortFeeBasicData(feeBasis?.list)[0]?.feeId
        );
      } else {
        updateUrl(feeType?.list[0]?.feeTypeId, replace);
      }
    }
  }, [feeType.list]);

  /**
   * @description Function for handle drawer actions and list actions
   * @param drawerType Drawer type for identification
   * @param actionType To identify the click action
   * @param record Table row record
   */
  const onHandleAction = (
    drawerType: DrawerType,
    actionType: ManageFeeAction,
    record?: any
  ) => {
    if (
      actionType == ManageFeeAction.ADD ||
      actionType === ManageFeeAction.EDIT
    ) {
      changeDrawerDetails &&
        changeDrawerDetails({
          title: getDrawerTitle(
            actionType,
            drawerType,
            getSelectedFeeTypeNameForDrawerTitle(feeType?.list, Number(typeId))
          ),
          visible: true,
          drawerType,
          actionType,
          record,
        });
    } else if (actionType === ManageFeeAction.DELETE) {
      if (drawerType === DrawerType.FEE_BASIS) {
        const payload = {
          isDeleted: 1,
          feeId: record?.feeId,
        };
        deleteFeeBasis &&
          deleteFeeBasis({
            payload,
            feeTypeId: record?.feeType,
            type: DrawerType.FEE_BASIS,
          });
      } else if (drawerType === DrawerType.FEE_RANGE) {
        if (feeRange?.list?.length > 1) {
          deleteFeeRange &&
            deleteFeeRange(deleteFeeRangeFromTable(record, feeRange?.list));
        } else {
          $Message.error(
            t(
              "US.COLLECTION.ADMIN:MANAGE_FEE.THERE_SHOULD_BE_ATLEAST_ONE_FEE_RANGE"
            )
          );
        }
      }
    } else if (actionType === ManageFeeAction.REVERT) {
      if (drawerType === DrawerType.FEE_BASIS_HISTORY) {
        const payload = {
          isDeleted: 0,
          feeId: record?.feeId,
        };
        deleteFeeBasis &&
          deleteFeeBasis({
            payload,
            feeTypeId: record?.feeType,
            type: DrawerType.FEE_BASIS_HISTORY,
          });
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        setFieldValue,
        ...restProps
      }: any) => (
        <>
          <div className="collection-fee">
            <$Affix offsetTop={48}>
              <div className="page-header header-border pr-0">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      title={t("US.COLLECTION.ADMIN:MANAGE_FEE.MANAGE_FEE")}
                      onBack={() => handleGoBack(history)}
                    />
                  </div>
                </div>
              </div>
            </$Affix>

            <div className="collection-fee-layout">
              {/* feeTypeLayout */}
              <FeeType
                onCallAction={(
                  drawerType: DrawerType,
                  actionType: ManageFeeAction,
                  record?: any
                ) => {
                  onHandleAction(drawerType, actionType, record);
                }}
              />
              <div className="flex-fill">
                <div className="collection-fee-layout-content">
                  <$Row gutter={0}>
                    {/* feeBasisMainLayout */}
                    <FeeBasis
                      onCallAction={(
                        drawerType: DrawerType,
                        actionType: ManageFeeAction,
                        record?: any
                      ) => {
                        onHandleAction(drawerType, actionType, record);
                      }}
                    />
                    {/* feeRangeLayout */}
                    {Number(feeId) != -1 && (
                      <FeeRange
                        onCallAction={(
                          drawerType: DrawerType,
                          actionType: ManageFeeAction,
                          record?: any
                        ) => {
                          onHandleAction(drawerType, actionType, record);
                        }}
                      />
                    )}
                  </$Row>
                </div>
              </div>
            </div>
          </div>
          <$Drawer
            title={drawerInfo.title}
            width={500}
            visible={drawerInfo.visible}
            destroyOnClose
            maskClosable={false}
            onClose={() =>
              changeDrawerDetails && changeDrawerDetails(INITIAL_DRAWER_DATA)
            }
          >
            {drawerInfo.drawerType == DrawerType.FEE_TYPE && (
              <FeeTypeDetails actionType={drawerInfo.actionType} />
            )}
            {drawerInfo.drawerType == DrawerType.FEE_BASIS && (
              <FeeBasisDetails actionType={drawerInfo.actionType} />
            )}
            {drawerInfo.drawerType == DrawerType.FEE_RANGE && (
              <FeeRangeDetails actionType={drawerInfo.actionType} />
            )}
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFee } = state;
  const { feeType, feeBasis, drawerInfo, feeRange } = manageFee;
  return {
    feeType,
    feeBasis,
    drawerInfo,
    feeRange,
  };
};

const { feeTypeActions, existingFeeBasis, existingFeeRange, newFeeBasis } =
  Actions.manageFee;
const { get, openDrawer } = feeTypeActions;

const mapDispatchToProps = {
  getFeeTypes: get,
  deleteFeeBasis: existingFeeBasis.deleteById,
  deleteFeeRange: existingFeeRange.deleteById,
  changeDrawerDetails: openDrawer,
  revertBasisHistory: newFeeBasis.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ManageFee);
