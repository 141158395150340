import {
	ICreditorGroupSearchResult,
	ICreditorSearchResult,
} from 'us.common/reducers/MainSearch/Interfaces';
import { IFilterValues } from '../Interfaces';
import { CreditorStatusTypes } from '../Constants';

/**
 * @function
 * @description Determine if the creditorGroup object should be included based on the filter criterias
 * @returns the boolean decision if the creditorGroup should be included
 */
export const doesCreditorGroupInclude = (
	{ groupId, groupName }: ICreditorGroupSearchResult,
	{ creditorGroupIdName }: IFilterValues
): boolean => {
	try {
		return creditorGroupIdName
			? groupName.toUpperCase().includes(creditorGroupIdName.toUpperCase()) ||
					groupId.toString().includes(creditorGroupIdName)
			: true;
	} catch (e) {
		return false;
	}
};

/**
 * @function
 * @description Determine if the creditor object should be included based on the filter criterias
 * @param creditorData Element of CreditorGroup.Creditor Result
 * @param creditorFilters The new filter values for the creditor tab
 * @returns the boolean decision if the creditorGroup.creditor should be included
 */
export const doesCreditorInclude = (
	{ creditorName, creditorNumber, isCreditorActive }: ICreditorSearchResult,
	{ creditorNameNo, creditorStatus }: IFilterValues
) => {
	try {
		return (
			(creditorNameNo
				? creditorName.toUpperCase().includes(creditorNameNo.toUpperCase()) ||
				  creditorNumber.toString().includes(creditorNameNo)
				: true) &&
			(creditorStatus === CreditorStatusTypes.ALL
				? true
				: (creditorStatus === CreditorStatusTypes.ACTIVE && isCreditorActive) ||
				  (creditorStatus === CreditorStatusTypes.INACTIVE &&
						!isCreditorActive))
		);
	} catch {
		return false;
	}
};
