import React, { Fragment, useContext, useEffect, useState } from "react";
import { ConfigurationContext } from "us.common/ConfigurationContext/ConfigurationProvider";
import {
  IMegaMenuProps,
  IOperation,
} from "us.common/components/Menu/MegaMenu/interface";
import { CommonView, Modules, Menu } from "./Components";
import { isQuickLink } from "./Functions";
import * as Azure from "us.helper/azure";
import { RootState } from "us.helper/types";
import { UserInformationAction } from "us.common/actions";
import { connect, ConnectedProps } from "react-redux";
import "./MegaMenu.scss";

const MegaMenu: React.FC<IMegaMenuProps & PropsFromRedux> = ({
  style,
  isMenuOpen,
  quickLinks,
  onNavigate,
  getQuickLinks,
  addQuickLink,
  removeQuickLink,
}) => {
  const context = useContext(ConfigurationContext);
  const [selectedModule, setSelectedModule] = useState<any>();

  let isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let authentication: Azure.ADAuth | Azure.B2CAuth;
  authentication = isAzureAD ? new Azure.ADAuth() : new Azure.B2CAuth();
  const currentUser: any = authentication?.currentUser();

  useEffect(() => {
    getQuickLinks && getQuickLinks({ userId: currentUser?.oid });
  }, []);

  useEffect(() => {
    // set default selected menu as first
    if (context && !isMenuOpen && context?.configurationDetail?.length > 0) {
      setSelectedModule(context?.configurationDetail[0]);
    }
  }, [isMenuOpen]);

  // add to quick link handler
  const onAddToQuickLink = (selectedMenuLink: IOperation) => {
    if (!isQuickLink(quickLinks, selectedMenuLink)) {
      addQuickLink &&
        addQuickLink({
          userId: currentUser?.oid,
          operationId: selectedMenuLink.id,
        });
    }
  };

  // remove quick link handler
  const onRemoveQuickLink = ({id}: IOperation) => {
    removeQuickLink &&
      removeQuickLink({
        userId: currentUser?.oid,
        operationId: id,
      });
  };

  return (
    <div className={style} id="megaMenu">
      <div className="mega-menu-wrapper">
        <CommonView
          quickLinks={quickLinks}
          unpin={onRemoveQuickLink}
          onNavigate={onNavigate}
        />
        <Fragment>
          <div className="mega-menu-wrapper-fixed-left mega-menu-wrapper-modulebg">
            <Modules
              selectedModule={selectedModule}
              onSelectModule={(item: any) => setSelectedModule(item)}
            />
          </div>
          <div className="mega-menu-wrapper-fixed-left mega-menu-wrapper-featurebg">
            {selectedModule && (
              <Menu
                quickLinks={quickLinks}
                module={selectedModule}
                onAddToQuickLink={onAddToQuickLink}
                onRemoveFromQuickLink={onRemoveQuickLink}
                onNavigate={(url: string | undefined) => url && onNavigate(url)}
              />
            )}
          </div>
        </Fragment>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { UserInformation } = state;
  const { quickLinks } = UserInformation;

  return {
    quickLinks: quickLinks.data,
  };
};

const { getQuickLinks, addQuickLink, removeQuickLink } = UserInformationAction;

const mapDispatchToProps = {
  getQuickLinks: getQuickLinks.get,
  addQuickLink: addQuickLink.save,
  removeQuickLink: removeQuickLink.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MegaMenu);
