export enum UserInformation {
  GET_USER_PROFILE_PHOTO_START = "GET_USER_PROFILE_PHOTO_START",
  GET_USER_PROFILE_PHOTO_SUCCESS = "GET_USER_PROFILE_PHOTO_SUCCESS",
  GET_USER_PROFILE_PHOTO_FAIL = "GET_USER_PROFILE_PHOTO_FAIL",

  GET_TENANT_DETAILS_START = "GET_TENANT_DETAILS_START",
  GET_TENANT_DETAILS_SUCCESS = "GET_TENANT_DETAILS_SUCCESS",
  GET_TENANT_DETAILS_FAIL = "GET_TENANT_DETAILS_FAIL",

  GET_QUICK_LINKS_START = "GET_QUICK_LINKS_START",
  GET_QUICK_LINKS_SUCCESS = "GET_QUICK_LINKS_SUCCESS",
  GET_QUICK_LINKS_FAIL = "GET_QUICK_LINKS_FAIL",

  ADD_QUICK_LINK_START = "ADD_QUICK_LINK_START",
  ADD_QUICK_LINK_SUCCESS = "ADD_QUICK_LINK_SUCCESS",
  ADD_QUICK_LINK_FAIL = "ADD_QUICK_LINK_FAIL",

  REMOVE_QUICK_LINK_START = "REMOVE_QUICK_LINK_START",
  REMOVE_QUICK_LINK_SUCCESS = "REMOVE_QUICK_LINK_SUCCESS",
  REMOVE_QUICK_LINK_FAIL = "REMOVE_QUICK_LINK_FAIL",
}