import React, { Fragment, useState } from 'react';
import './SensitiveLabel.scss';

interface ISensitiveLabel {
	value: string;
	masked?: boolean;
}

/**
 * @description -  System Settings Sensitive Label component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2556297251/System+Category+Home+View
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 22/04/2022
 */
const SensitiveLabel: React.FC<ISensitiveLabel> = ({
	value,
	masked = true,
}) => {
	const [isVisible, setVisibility] = useState<boolean>(false);

	/**
	 * @description Handle masking value visibility
	 */
	const handleMasking = () => {
		setVisibility(!isVisible);
		setAutoClose();
	};

	// auto hide visible masking value after 5 seconds
	const setAutoClose = () => {
		setTimeout(() => {
			setVisibility(false);
		}, 5000);
	};

	return (
		<Fragment>
			{/* Masking enabled and user can be able to toggle the label to view the data */}
			{masked && (
				<div
					onClick={handleMasking}
					className='sensitive-lable'>
					{isVisible && (
						<div className='unlock'>
							{value}
						</div>
					)}
					{!isVisible && (
						<div className='text-info lock'>
							***
						</div>
					)}
				</div>
			)}
			{/* No masking for the value and display */}
			{!masked && <div>{value}</div>}
		</Fragment>
	);
};

export default SensitiveLabel;
