/**
 * @function
 * @description Find profiles by given value
 * @param {Array<IActivityState>} profiles array of profiles
 * @param {string} searchKey search value
 * @returns { Array<any>}
 */
export const findProfiles = (
    profiles: Array<any>,
    searchKey: string
): Array<any> => {
    try {
        return profiles.filter(
            ({ profileName }: { profileName: string }) =>
              profileName.includes(searchKey)
          );
    } catch (error) {
        return [];
    }
};