import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	$Button,
	$Divider,
	$Space,
	$Table,
	$Tooltip,
} from 'us.common/components';
import { IResultView } from 'us.common/components/MainSearch/Interfaces';
import { DoubleLeftOutlined, LeftOutlined, RightOutlined } from 'us.icons';
import { PaginationDirection } from '../Interfaces';

/**
 * @description - Result view component with pagination.
 * @author Samitha hewawasam <samitha@unicorn-solutions.com>
 * @since 10/03/2022
 * */
export const ResultView: React.FC<IResultView> = (props) => {
	const {
		rowKey,
		tableColumns,
		currentHit,
		dataSource,
		tableClassName,
		totalSearchResult,
		paginationChangeEvent,
	} = props;
	const { t } = useTranslation();

	return (
		<div className='mt-3'>
			<$Table
				className={tableClassName}
				size='small'
				dataSource={dataSource}
				columns={tableColumns}
				pagination={false}
				rowKey={rowKey}
			/>
			<div className='d-flex justify-content-end mr-5 mt-3'>
				<$Space>
					<$Tooltip
						title={t(
							'US.COLLECTION.COMMON:COMMON.RESET_PAGES'
						)}>
						<$Button
							type='link'
							icon={
								<DoubleLeftOutlined />
							}
							disabled={
								currentHit === 1
							}
							onClick={() =>
								paginationChangeEvent(
									PaginationDirection.RESET
								)
							}
							data-testid='searchResults-paginationDirectionReset'
						/>
					</$Tooltip>
					<$Tooltip
						title={t(
							'US.COLLECTION.COMMON:COMMON.PREVIOUS_PAGE'
						)}>
						<$Button
							type='link'
							icon={<LeftOutlined />}
							disabled={
								currentHit <= 1
							}
							onClick={() =>
								paginationChangeEvent(
									PaginationDirection.PREV
								)
							}
							data-testid='searchResults-paginationDirectionPrev'
						/>
					</$Tooltip>
					<$Divider />
					<span>{`${t(
						'US.COLLECTION.COMMON:COMMON.PAGE'
					)} ${currentHit}`}</span>
					<$Divider />
					<$Tooltip
						title={t(
							'US.COLLECTION.COMMON:COMMON.NEXT_PAGE'
						)}>
						<$Button
							type='link'
							icon={<RightOutlined />}
							disabled={
								totalSearchResult <
								100
							}
							onClick={() =>
								paginationChangeEvent(
									PaginationDirection.NEXT
								)
							}
							data-testid='searchResults-paginationDirectionNext'
						/>
					</$Tooltip>
				</$Space>
			</div>
		</div>
	);
};
