import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IRootState } from "us.collection/interfaces";
import {
  $Row,
  $Col,
  $TableTree,
  $Select,
  $Button,
  $Popconfirm,
  $Divider,
} from "us.common/components";
import { manageFollowupActions } from "us.collection.admin/actions";
import { AssignType, FormFieldName } from "us.collection.admin/constants";
import { DeleteOutlined, PlusOutlined } from "us.icons";
import {
  handleTableData,
  handleDeleteAssignee,
  generateAssignToList,
} from "./Functions";

const { users, roles } = manageFollowupActions;

/**
 * @description - Manage Follow-up Query Space Component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2943713306/Follow-up+Custom+Query+Save+View+UI+Implementation
 * @authors Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 05/04/2022
 */
const AssigneeSelection: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext() as any;

  const { getFollowUpUsers, getFollowUpRoles, users, roles } = props;
  const { data: userList } = users;
  const { data: roleList } = roles;
  const { assignType, assignTo, assignToList } = values;

  /**
   * @function
   * @description - columns of the assign users table
   * @param assignToList - current assignToList form value
   * @param setFieldValue - formik setFieldValue function
   * @returns columns
   */
  const assignToListColumns = (
    assignToList: Array<any>,
    setFieldValue: any
  ): Array<any> => {
    return [
      {
        title: "",
        key: "more",
        dataIndex: "more",
        width: "40px",
        customRenderChild: (text: any, record: any) => {
          return (
            <$Popconfirm
              placement="top"
              title={`${t(
                "US.COLLECTION.ADMIN:BULKOPERATION.ASSIGN_TO_DELETE_CONFIRM"
              )}`}
              okText={t("US.COLLECTION.COMMON:COMMON.YES")}
              cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
              onConfirm={() =>
                handleDeleteAssignee(assignToList, record, setFieldValue)
              }
            >
              <$Button icon={<DeleteOutlined />} size="small" danger />
            </$Popconfirm>
          );
        },
      },
      {
        title: t("US.COLLECTION.ADMIN:BULKOPERATION.ASSIGN_TO"),
        dataIndex: "assignTo",
        key: "assignTo",
        ellipsis: true,
      },
    ];
  };

  /**
   * @function
   * @description - function triggered when select a assign type
   * @param {AssignType} assignType - selected assignType out of All, User or Role
   * @returns void
   */
  const onSelectAssignType = (assignType: AssignType) => {
    if (assignType == AssignType.USER) {
      getFollowUpUsers && getFollowUpUsers({});
    } else if (assignType == AssignType.ROLE) {
      getFollowUpRoles && getFollowUpRoles({});
    }
    setFieldValue("assignToList", []);
  };

  /**
   * @function
   * @description
   * @param errors
   * @returns
   */
  const isErrorMessageAppearance = (
    errors: any,
    fieldName: string
  ): boolean => {
    try {
      if (fieldName == FormFieldName.AssignTo) {
        return errors?.hasOwnProperty(FormFieldName.AssignTo);
      } else {
        return (
          errors?.hasOwnProperty(FormFieldName.AssignToList) &&
          !errors?.hasOwnProperty(FormFieldName.AssignTo)
        );
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <$Row gutter={8} className="mt-4">
        <$Col span={12}>
          <$Select
            size="small"
            name="assignType"
            className="w-100"
            formitem={{
              label: t("US.COLLECTION.ADMIN:BULKOPERATION.ASSIGN_TYPE"),
            }}
            allOption={false}
            dropdownMatchSelectWidth={false}
            options={[
              {
                label: t("COMMON.ALL"),
                value: AssignType.ALL,
              },
              {
                label: t("US.COLLECTION.ADMIN:BULKOPERATION.USER"),
                value: AssignType.USER,
              },
              {
                label: t("US.COLLECTION.ADMIN:BULKOPERATION.ROLE"),
                value: AssignType.ROLE,
              },
            ]}
            onSelect={(value: AssignType) => onSelectAssignType(value)}
            dataTestid={"assignType-select"}
            hideSearch={true}
          />
        </$Col>
        <$Col span={12}>
          <$Select
            size="small"
            name="assignTo"
            className="w-100"
            formitem={{
              label: t("US.COLLECTION.ADMIN:BULKOPERATION.ASSIGN_TO"),
            }}
            allOption={false}
            dropdownMatchSelectWidth={false}
            options={generateAssignToList(
              values,
              userList,
              roleList,
              assignType
            )}
            optionText={assignType == AssignType.USER ? "displayName" : "name"}
            optionValue={assignType == AssignType.USER ? "id" : "roleId"}
            onSearchBy={
              assignType == AssignType.USER ? ["displayName"] : ["name"]
            }
            disabled={assignType == AssignType.ALL}
            dataTestid={"assignTo-select"}
          />
          {isErrorMessageAppearance(errors, FormFieldName.AssignTo) && (
            <div className="my-2 text-error">
              {t("US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_AN_ASSIGNEE")}
            </div>
          )}
        </$Col>
      </$Row>
      <$Divider orientation="left">
        <$Button
          onClick={() =>
            handleTableData(values, setFieldValue, userList, roleList)
          }
          size="small"
          icon={<PlusOutlined />}
          disabled={assignTo == ""}
          data-testid={"addAssignTo-btn"}
        >
          {t("US.COLLECTION.ADMIN:BULKOPERATION.ADD_ASSIGNEE")}
        </$Button>
      </$Divider>
      {isErrorMessageAppearance(errors, FormFieldName.AssignToList) && (
        <div className="my-2 text-error">
          {t(
            "US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_THE_SELECTED_ASSIGNEE_TO_THE_LIST"
          )}
        </div>
      )}
      <$TableTree
        rowKey="key"
        data={assignToList}
        size="small"
        className="csq-table my-3"
        filterOnType={true}
        resetOnSourceChange={true}
        bordered
        pagination={false}
        scroll={{ y: "calc(100vh - 525px)" }}
        columns={assignToListColumns(assignToList, setFieldValue)}
        data-testid={"assignToList-table"}
      />
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { customQuery } = manageFollowups;
  const { users, roles } = customQuery;
  return { users, roles };
};

const mapDispatchToProps = {
  getFollowUpUsers: users.get,
  getFollowUpRoles: roles.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssigneeSelection);
