import moment from "moment";
import { validateNumber } from "us.common/functions";
import { dateFormats } from "us.collection/components/ManageAgreements/Constants";

const getNumber = (value: any, currentLanguage: string): number => {
  const returnVal = validateNumber(value, currentLanguage);
  return returnVal && returnVal > 0 ? returnVal : 0;
};

export function PartPayment(
  this: any,
  total: number,
  metaData: any,
  currentLanguage: string
) {
  const {
    payDate,
    mainAmount,
    interestCostAmount,
    interestMainAmount,
    collectionFee,
    costFee,
    courtFee,
    otherFee,
    intersetCostsBalance,
    interestMainBalance,
    interestDate,
    isSendSMSNotification,
  } = this;
  const { caseId, entityType } = metaData;
  return {
    payDate: moment(payDate).isValid()
      ? moment(payDate).endOf("day").format(dateFormats.req)
      : moment().add(1, "days").format(dateFormats.req),
    mainAmount: getNumber(mainAmount, currentLanguage),
    interestCostAmount: getNumber(interestCostAmount, currentLanguage),
    interestMainAmount: getNumber(interestMainAmount, currentLanguage),
    collectionFee: getNumber(collectionFee, currentLanguage),
    costFee: getNumber(costFee, currentLanguage),
    courtFee: getNumber(courtFee, currentLanguage),
    otherFee: getNumber(otherFee, currentLanguage),
    totalAmount: total,
    interestCostBalance: getNumber(intersetCostsBalance, currentLanguage),
    interestMainBalance: getNumber(interestMainBalance, currentLanguage),
    id: 1,
    caseNo: caseId,
    agreementType: entityType,
    isSendSMSNotification: isSendSMSNotification,
    InterestCollectionAmount: 0.0,
    InterestCourtAmount: 0.0,
    InterestOtherAmount: 0.0,
    caseType: entityType,
    interestDate: moment(payDate).isValid()
      ? moment(interestDate).endOf("day").format(dateFormats.req)
      : moment().format(dateFormats.req),
  };
}
