import { sortFunction } from 'us.common/components/MainSearch/Functions';
import {
	IDebtorSearchResult,
	IDebtorARSearchResult,
	IDebtorCaseSearchResult,
} from 'us.common/reducers/MainSearch/Interfaces';
import { ITab } from '../Interfaces';

/**
 * @function
 * @description To get the debtor search results filtered based on the formik input values.
 * @param debtors debtor search results
 * @param tabInfo The formik input values
 * @returns sorted list of debtor search results based on the formik input values for filtering
 */
export const getDebtorValSorted = (
	debtors: IDebtorSearchResult[] | [],
	{
		sortKey,
		sortOrder,
	}: ITab<IDebtorSearchResult & IDebtorARSearchResult & IDebtorCaseSearchResult>
): IDebtorSearchResult[] | [] => {
	try {
		let levelOfDebtorSort: 'DEBTOR' | 'AR' | 'CASE' = 'CASE';
		if (!debtors || debtors.length === 0) {
			levelOfDebtorSort = 'CASE';
		} else if (
			Object.keys(debtors.length > 0 ? debtors[0] : []).some(
				(debtorKey) => debtorKey == sortKey
			)
		) {
			levelOfDebtorSort = 'DEBTOR';
		} else if (
			Object.keys(debtors ? debtors[0].arDetails[0] : []).some(
				(arkey) => arkey == sortKey
			)
		) {
			levelOfDebtorSort = 'AR';
		}

		if (!debtors || debtors.length === 0) {
			return debtors;
		}
		if (levelOfDebtorSort === 'DEBTOR') {
			return debtors.sort((value1, value2) =>
				sortFunction(
					value1[sortKey as keyof IDebtorSearchResult],
					value2[sortKey as keyof IDebtorSearchResult],
					sortOrder
				)
			);
		} else if (levelOfDebtorSort === 'AR') {
			return (debtors as IDebtorSearchResult[]).map((debtor) => ({
				...debtor,
				arDetails: debtor.arDetails.sort((value1, value2) =>
					sortFunction(
						value1[sortKey as keyof IDebtorARSearchResult],
						value2[sortKey as keyof IDebtorARSearchResult],
						sortOrder
					)
				),
			}));
		} else if (levelOfDebtorSort === 'CASE') {
			return (debtors as IDebtorSearchResult[]).map((debtor) => ({
				...debtor,
				arDetails: (debtor.arDetails as IDebtorARSearchResult[]).map(
					(ardetail) => ({
						...ardetail,
						caseDetails: ardetail.caseDetails.sort((value1, value2) =>
							sortFunction(
								value1[sortKey as keyof IDebtorCaseSearchResult],
								value2[sortKey as keyof IDebtorCaseSearchResult],
								sortOrder
							)
						),
					})
				),
			}));
		} else {
			return debtors;
		}
	} catch (e) {
		return [];
	}
};
