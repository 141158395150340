import {
    IActivity,
    ISetting,
    IDefaultData,
} from "us.common/interfaces";
import { ActivityQueue, ExecutionSource } from "us.common/constants";
import { convertEntityType, getActivitiesRequest, getEntityIds } from "us.common/functions";

export function ExecuteBulk(
    this: any,
    list: Array<ISetting>,
    activityList: Array<IActivity>,
    defaultData: IDefaultData,
    dataSource: Array<Object> = [],
    selectedRowKeys: Array<string | number> = [],
    isEnableParameter: boolean,
    entityType?: string,
    isRowKeysAsEntityIds?: boolean,
    bulkExecutionSource?: string,
    version?: string
) {
    const { activityId } = this;
    return {
        activityBatchId: -1,
        activityCode: activityList.find(
            (act: IActivity) => act.activityId == activityId && (entityType ? act.entitytype == entityType : true)
        )?.activityCode,
        activityQueue: ActivityQueue.Express,
        executionSource: bulkExecutionSource,
        activities: getActivitiesRequest(
            this,
            list,
            defaultData,
            isRowKeysAsEntityIds ? selectedRowKeys : getEntityIds(dataSource, selectedRowKeys, defaultData),
            isEnableParameter
        ),
        entityType: convertEntityType(activityList.find(
            (act: IActivity) => act.activityId == activityId)?.entitytype ?? ""),
        activityVersion: version,
    };
}
