import React from "react";
import { $Notification } from "us.common/components";
import { LoadingOutlined } from "us.icons";
import { handleEndPointLocals } from "us.common/functions";

/**
 * @function
 * @description Display global notification according to real time notification content.
 * @param {any} content - Notification trigger content.
 * @returns Global notification.
 */
export const openGlobalNotification = (content: any): any => {
  const { eventID, title, description, isInProgress, type } = content ?? {};
  const notificationProps: any = {
    message: handleEndPointLocals(`US.COMMON:COMMON.${title}`),
    description: handleEndPointLocals(`US.COMMON:COMMON.${description}`),
    key: eventID,
    placement: "topRight",
    top: 54
  };

  if (isInProgress) {
    return $Notification.info({
      ...notificationProps,
      icon: <LoadingOutlined spin />,
      duration: 8,
    });
  } else {
    return $Notification[type == "ERROR" ? "error" : "success"]({
      ...notificationProps,
      duration: 7,
    });
  }
};
