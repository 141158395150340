import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  calculateCreditAmount,
  calculateCreditBalance,
} from "us.common/components/CreditNote/Functions";
import { ICreditNote } from "us.common/components/CreditNote/Interface";
import { CreditNotesTable, CreditOption, Summary } from "./Components";
import validationSchema from "./Validations";

import {
  $Button,
  $Divider,
  $Form,
  $TextArea,
  $AmountLabel,
  $DateLabel,
  $Skeleton,
  $AsyncInput,
  $Alert,
} from "us.common/components";

const AddCreditNote: React.FC<ICreditNote> = (props) => {
  const { t } = useTranslation();
  const {
    invoiceDetails,
    orderlines,
    creditNote,
    isLoading = false,
    isSaving = false,
    isExporting = false,
    isDefault = true,
    isViewOnly = false,
    isExportVisible = false,
    isInvoiceNo,
    tableColumns = [],
    onReset,
    onClose,
    onSave,
    onExport,
    onBlurInput,
    onClearInput,
    invoiceNo=''
  } = props;

  const isFormDisabled = !invoiceDetails.creditedAmount
    ? false
    : invoiceDetails.amount - Math.abs(invoiceDetails.creditedAmount) == 0;

  useEffect(() => {
    isDefault &&
      setTimeout(() => {
        document
          .querySelector(`[data-testid=async-input-invoice-no]` as any)
          ?.focus();
      }, 200);
  }, []);

  const handleCreditNoteSubmit = (
    creditDetails: any,
    { isValidating }: any
  ) => {
    const { creditNoteDetails, balance } = creditDetails;
    if (isValidating) return;
    onSave &&
      onSave({
        ...invoiceDetails,
        ...creditDetails,
        creditAmount: calculateCreditAmount(creditNoteDetails),
        creditAmountBalance: calculateCreditBalance(creditNoteDetails, balance),
      });
  };

  const handleCreditNoteExport = (creditDetails: any) => {
    const { creditNoteDetails, balance } = creditDetails;
    onExport &&
      onExport({
        ...invoiceDetails,
        ...creditDetails,
        creditAmount: calculateCreditAmount(creditNoteDetails),
        creditAmountBalance: calculateCreditBalance(creditNoteDetails, balance),
      });
  };

  const initialValues = {
    ...creditNote?.data,
    pId: invoiceDetails.pid,
    kid: invoiceDetails.kid,
    installmentId: invoiceDetails.installmentId,
    creditOption: "1",
    invoiceNo: invoiceNo != '' ? invoiceNo : invoiceDetails.invoiceNo,
    amount:
      invoiceDetails.creditedAmount < 0
        ? invoiceDetails?.amount - Math.abs(invoiceDetails.creditedAmount)
        : invoiceDetails.amount,
    subCaseId: invoiceDetails.subCaseId,
    balance: invoiceDetails.balance,
    creditNoteDetails: orderlines,
    comment: invoiceDetails.creditInvoiceDescription,
  };

  return (
    <>
      <Formik
        initialValues={!isViewOnly ? initialValues : creditNote?.data}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleCreditNoteSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          resetForm,
          setValues,
          ...rest
        }: any) => (
          <$Form>
            <div className="d-flex justify-content-between async-input-space">
              <$AsyncInput
                name="invoiceNo"
                label={t("US.COMMON:CREDIT_NOTE.INVOICE_NO")}
                dataTestid="async-input-invoice-no"
                size="small"
                type="number"
                disabled={!isDefault || !isInvoiceNo || isLoading}
                onBlur={(e: any) => {
                  e.target.value !== "" &&
                    e.target.value != 0 &&
                    onBlurInput &&
                    onBlurInput(e);
                }}
                onClear={onClearInput}
                onPressEnter={() =>
                  document
                    .querySelector(
                      `[data-testid=async-input-invoice-no]` as any
                    )
                    ?.blur()
                }
                formitem={{
                  extra: isInvoiceNo
                    ? t("US.COMMON:CREDIT_NOTE.PRESS_ENTER_TO_SEARCH")
                    : null,
                }}
              />
              <Summary invoiceDetails={invoiceDetails} loading={isLoading} />
            </div>

            <$Skeleton active paragraph={{ rows: 3 }} loading={isLoading}>
              {isFormDisabled && (
                <$Alert
                  className="my-2"
                  showIcon
                  message={t(
                    "US.COMMON:CREDIT_NOTE.THIS_INVOICE_IS_FULLY_CREDITED"
                  )}
                  type="info"
                />
              )}
              <$Divider />
              <div>
                {!isViewOnly && (
                  <CreditOption
                    values={values}
                    invoiceDetails={invoiceDetails}
                    disabled={
                      (invoiceDetails?.isExported && isExportVisible) ||
                      isFormDisabled ||
                      (!invoiceDetails.invoiceNo ? true : false)
                    }
                  />
                )}
                <div className="d-flex flex-fill mt-3  invoice-table-content">
                  <CreditNotesTable
                    isDefault={isDefault}
                    disabled={
                      (invoiceDetails?.isExported && isExportVisible) ||
                      isFormDisabled
                    }
                    tableColumns={tableColumns}
                  />
                </div>
              </div>

              <$Divider className="mb-2 mt-5" />
              <div className="mb-2">
                <$TextArea
                  name="comment"
                  className="mt-1"
                  label={t("COMMON.COMMENT")}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  disabled={
                    (invoiceDetails?.isExported && isExportVisible) ||
                    isFormDisabled ||
                    (!invoiceDetails.invoiceNo ? true : false)
                  }
                  maxLength={255}
                />
              </div>

              {!isDefault && (
                <div className="mb-2">
                  {t("COMMON.DATE")} :
                  <$DateLabel value={values?.voucherDate} />
                </div>
              )}
            </$Skeleton>

            <div className="drawer-footer-fixed align-items-center justify-content-end">
              <div className="d-flex flex-fill align-items-center">
                <div className="ft-price-section">
                  <div className="ft-price-amount ft-price-amount-main">
                    <$AmountLabel
                      value={calculateCreditAmount(values.creditNoteDetails)}
                    />
                  </div>
                  <div>{t("US.COMMON:CREDIT_NOTE.CREDIT_NOTE_AMOUNT")}</div>
                </div>

                <div className="ft-price-section">
                  <div className="ft-price-amount">
                    <$AmountLabel
                      value={calculateCreditBalance(
                        values.creditNoteDetails,
                        values.balance
                      )}
                    />
                  </div>
                  <div>
                    {isDefault && t("US.COMMON:CREDIT_NOTE.BALANCE_TO_PAY")}
                    {!isDefault &&
                      t("US.COMMON:CREDIT_NOTE.BALANCE_TO_PAY_BY_MEMBER")}
                  </div>
                </div>
              </div>
              <div>
                {!isViewOnly &&
                  (isDefault
                    ? true
                    : calculateCreditBalance(
                        values.creditNoteDetails,
                        values.balance
                      ) !== 0) &&
                  isExportVisible &&
                  invoiceDetails?.isExported && (
                    <$Button
                      className="mr-2"
                      htmlType="submit"
                      type="primary"
                      data-testid="save-and-settle-button"
                      disabled={
                        isFormDisabled ||
                        !rest.isValid ||
                        isSaving ||
                        isExporting ||
                        (!invoiceDetails.invoiceNo ? true : false)
                      }
                      loading={isValidating || isSaving || isExporting}
                      onClick={() => {
                        handleCreditNoteExport &&
                          handleCreditNoteExport(values);
                        rest.setFieldValue("isSaveOnly", false);
                      }}
                    >
                      {isDefault && t("US.COMMON:CREDIT_NOTE.EXPORT")}
                      {!isDefault && t("US.COMMON:CREDIT_NOTE.SAVE_AND_SETTLE")}
                    </$Button>
                  )}
                {!isViewOnly && (
                  <$Button
                    className="mr-2"
                    htmlType="submit"
                    data-testid="submit-button"
                    type="primary"
                    disabled={
                      isFormDisabled ||
                      !rest.isValid ||
                      isSaving ||
                      isExporting ||
                      isExportVisible ||
                      (!invoiceDetails.invoiceNo ? true : false)
                    }
                    loading={isValidating || isSaving || isExporting}
                    onClick={(e: any) => {
                      handleSubmit(e);
                      rest.setFieldValue("isSaveOnly", true);
                    }}
                  >
                    {t("COMMON.SAVE")}
                  </$Button>
                )}
                
                <$Button
                  type="default"
                  className="mr-2"
                  data-testid="reset-button"
                  onClick={() =>
                    !isInvoiceNo
                      ? setValues({
                          ...initialValues,
                          creditNoteDetails: [...orderlines],
                        })
                      : onReset && onReset()
                  }
                  disabled={
                    !rest.dirty || isSaving || isExporting || isExportVisible
                  }
                  loading={isValidating}
                >
                  {t("COMMON.RESET")}
                </$Button>

                <$Button
                  data-testid="cancel-button"
                  disabled={isSaving || isExporting}
                  onClick={() => onClose && onClose()}
                >
                  {t("COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </$Form>
        )}
      </Formik>
    </>
  );
};

export default AddCreditNote;
