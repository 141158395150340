/**
 * @property name provides array of selected values
 */

import React from "react";
import { Selection } from "./Components";
import { ICheckBoxList } from "us.common/components/antd/CheckBoxList/Interface";
import PropTypes from "prop-types";
import "./CheckBoxList.scss";

const $C = <T extends ICheckBoxList>({
  name,
  options,
  optionText,
  optionValue,
  extraText,
  disabled,
}: T) => {
  return (
    <>
      <Selection
        name={name}
        options={options}
        optionText={optionText}
        optionValue={optionValue}
        extraText={extraText}
        disabled={disabled}
      />
    </>
  );
};

$C.propTypes = {
  name: PropTypes.string,
  options: PropTypes.any,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
};

export const $CheckBoxList = $C;
