import { IFollowupResult } from "us.collection.admin/interfaces";
import {
  FollowupUniqueCol,
  ColumnDataType,
  ColumnDataTypeInterface,
  DateFormats,
  FollowupEntityTypeBadgeInterface,
  FollowupEntityTypeBadge,
  FollowupEntityType,
  AllFollowupEntityTypeInterface,
  LastAction,
  FollowupAction,
  UrlModule,
  UrlModuleInterface,
} from "us.collection.admin/constants";
import { EntityType } from "us.collection/constants";
import { getColorForWorkFlowState } from "us.collection/functions/WorkFlowState";
import {
  $Popover,
  $Button,
  $Avatar,
  $Tooltip,
  $Tag,
  $AmountLabel,
  $DateLabel,
} from "us.common/components";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  FolderOpenFilled,
  EyeFilled,
} from "us.icons";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { getRouteUrl } from "us.helper/utility";
import "./FollowupResult.scss";
/**
 * @description - Get column dataType for FollowUp result table
 * @param data - column value
 * @returns  dataType as a string
 */
const getDataType = (data: any): ColumnDataTypeInterface => {
  try {
    if (moment(data, DateFormats.REQ, true).isValid()) {
      return ColumnDataType.DATE;
    } else if (/^\d*\.\d*$/.test(data)) {
      return ColumnDataType.AMOUNT;
    } else {
      return ColumnDataType.STRING;
    }
  } catch (error) {
    return ColumnDataType.STRING;
  }
};

/**
 * @description - Get EntityType Badge for the column
 * @param entityType - entityType string
 * @param record - A single followUp result data row
 * @returns  Abbreviation for entityType as a string
 */
const getEntityTypeBadge = (
  entityType: string,
  record: any
): FollowupEntityTypeBadgeInterface => {
  switch (entityType) {
    case FollowupEntityType.CASE:
      return record?.Casetype == EntityType.CASE
        ? FollowupEntityTypeBadge.CASE
        : record?.Casetype == undefined
        ? FollowupEntityTypeBadge.CASE
        : FollowupEntityTypeBadge.INVOICE;
    case FollowupEntityType.INVOICE:
      return FollowupEntityTypeBadge.INVOICE;
    case FollowupEntityType.DEBTOR:
      return FollowupEntityTypeBadge.DEBTOR;
    case FollowupEntityType.CREDITOR:
      return FollowupEntityTypeBadge.CREDITOR;
    case FollowupEntityType.PAYMENT:
      return FollowupEntityTypeBadge.PAYMENT;
    default:
      return "";
  }
};

/**
 * * @remarks
 * This method is to get find linked column with respect to the entityType.
 * @description - Get find linked column with respect to the entityType
 * @param entityType - entityType
 * @returns  final array of possible columns to linked.
 */
const getLinkedColumnsForEntity = (
  entityType: AllFollowupEntityTypeInterface
): Array<string> => {
  switch (entityType) {
    case FollowupEntityType.CASE:
    case FollowupEntityType.INVOICE:
      return [FollowupUniqueCol.EX_CASE_NO];
    case FollowupEntityType.CREDITOR:
      return [FollowupUniqueCol.CREDITOR_NO];
    case FollowupEntityType.DEBTOR:
      return [FollowupUniqueCol.AR_NO];
    case FollowupEntityType.PAYMENT:
      return [FollowupUniqueCol.PAYMENT_ID, FollowupUniqueCol.PAYMENT_NO];
    default:
      return [];
  }
};

/**
 * @function
 * @description set icon according to action name
 * @param {string} actionName last action name
 * @returns {JSX.Element} icon
 */
const getNonActivityStatusIcon = (actionName: string): JSX.Element => {
  try {
    switch (actionName.toUpperCase()) {
      case FollowupAction.REMOVE:
        return <CloseCircleOutlined className="mr-1 text-error" />;
      case FollowupAction.DONE:
        return <CheckCircleOutlined className="mr-1 text-success" />;
      case FollowupAction.POSTPONE:
        return <ClockCircleOutlined className="mr-1 text-warning" />;
      default:
        return <InfoCircleOutlined className="mr-1" />;
    }
  } catch (error) {
    return <InfoCircleOutlined className="mr-1" />;
  }
};

/**
 * @function
 * @description set last action status icon
 * @param {string} status last action status
 * @param {string} actionName last action name
 * @returns {JSX.Element} icon
 */
const getLastActionStatusIcon = (
  status: string,
  actionName: string
): JSX.Element => {
  try {
    switch (status) {
      case LastAction.EXECUTED:
        return <CheckCircleFilled className="mr-1 text-success" />;
      case LastAction.FAILED:
      case LastAction.PENDING_FAILED:
        return <CloseCircleFilled className="mr-1 text-error" />;
      case LastAction.OPEN:
        return <FolderOpenFilled className="mr-1" />;
      case LastAction.PENDING:
        return <ClockCircleFilled className="mr-1 text-warning" />;
      case LastAction.VIEW:
        return <EyeFilled className="mr-1" />;
      default:
        return getNonActivityStatusIcon(actionName);
    }
  } catch (error) {
    return getNonActivityStatusIcon(actionName);
  }
};
/**
 * @description - Get matching url for the entityType
 * @param entityType - entityType string
 * @returns  url module
 */
const getUrlForEntity = (entityType: string): UrlModuleInterface => {
  switch (entityType) {
    case FollowupEntityType.CASE:
    case FollowupEntityType.INVOICE:
      return UrlModule.CASE;
    case FollowupEntityType.DEBTOR:
      return UrlModule.AR;
    case FollowupEntityType.CREDITOR:
      return UrlModule.CREDITOR;
    case FollowupEntityType.PAYMENT:
      return UrlModule.PAYMENT;
    default:
      return "";
  }
};

/**
 * @description - Generate followUp result columns of the UI table which is stored in redux state - A main function.
 * @param data - API result for a particular fallowUp
 * @returns  final array of Table columns
 */
export const generateFollowUpResultColumns = (
  data: IFollowupResult,
  entityType: AllFollowupEntityTypeInterface
): any => {
  const { followupHeaders, followupData } = data;
  const resultColumns: any = [
    {
      key: FollowupUniqueCol.TYPE,
      title: "",
      dataIndex: FollowupUniqueCol.TYPE,
      width: "40px",
      fixed: "left",
      customRenderChild: (text: any, record: any) => {
        return (
          <$Avatar shape="square" size="small">
            {getEntityTypeBadge(entityType, record)}
          </$Avatar>
        );
      },
    },
  ];
  const lastColumn = {
    key: FollowupUniqueCol.FINAL_COL,
    title: "",
    dataIndex: FollowupUniqueCol.FINAL_COL,
  };

  try {
    followupHeaders.map((dataIndex: string | any) => {
      const dataType = getDataType(followupData[0][`${dataIndex}`]);
      if (getLinkedColumnsForEntity(entityType).includes(dataIndex)) {
        resultColumns.push({
          key: dataIndex,
          title:
            dataIndex == FollowupUniqueCol.EX_CASE_NO
              ? FollowupUniqueCol.CASE_NO
              : dataIndex,
          dataIndex: dataIndex,
          className: "text-nowrap",
          width: 120,
          fixed: "left",
          ellipsis: true,
          customSorter: (a: any, b: any) => a - b,
          customFilter: true,
          customRenderChild: (text: any, record: any) => {
            return (
              <$Tooltip title={record[`${dataIndex}`]}>
                <Link
                  to={"#"}
                  onClick={() => {
                    entityType == FollowupEntityType.PAYMENT
                      ? getRouteUrl.paymentDistribution(record[`${dataIndex}`])
                      : getRouteUrl.moduleRoute(
                          "COLLECTION",
                          `/${getUrlForEntity(entityType)}/${
                            record[`${dataIndex}`]
                          }`
                        );
                  }}
                >
                  {record[`${dataIndex}`]}
                </Link>
              </$Tooltip>
            );
          },
        });
      } else if (dataIndex == FollowupUniqueCol.WORKFLOW_STATE) {
        resultColumns.push({
          key: dataIndex,
          title: dataIndex,
          dataIndex,
          className: "text-nowrap",
          width: 150,
          ellipsis: true,
          customSorter: (a: any, b: any) => a.localeCompare(b),
          customFilter: true,
          customRenderChild: (text: any, record: any) => {
            return (
              record[`${dataIndex}`] && (
                <$Tooltip title={record[`${dataIndex}`]}>
                  <$Tag
                    className={`state-tag tag-status-${getColorForWorkFlowState(
                      record[`${dataIndex}`]
                    )}`}
                  >
                    {record[`${dataIndex}`]}
                  </$Tag>
                </$Tooltip>
              )
            );
          },
        });
      } else if (
        dataIndex != FollowupUniqueCol.FOLLOWUP_ID &&
        dataIndex != FollowupUniqueCol.FOLLOWUP_CASE_STATE &&
        dataIndex != FollowupUniqueCol.CASE_TYPE &&
        dataIndex != FollowupUniqueCol.CASE_NO &&
        dataIndex != FollowupUniqueCol.LAST_ACTION_NAME &&
        dataIndex != FollowupUniqueCol.LAST_ACTION_DATE &&
        dataIndex != FollowupUniqueCol.LAST_ACTION_STATUS &&
        dataIndex != FollowupUniqueCol.LAST_ACTION_USER
      ) {
        resultColumns.push({
          key: dataIndex,
          title: dataIndex,
          dataIndex,
          align: dataType == ColumnDataType.AMOUNT ? "right" : "",
          className:
            dataType == ColumnDataType.AMOUNT
              ? "right-has-sort right-has-filter text-nowrap"
              : "text-nowrap",
          width: setColWidthByDataIndex(dataIndex),
          ellipsis: true,
          customSorter: (a: any, b: any) =>
            dataType == ColumnDataType.AMOUNT ? a - b : a.localeCompare(b),
          customFilter: dataType == ColumnDataType.STRING ? true : dataType,
          customRenderChild: (text: any, record: any) => {
            const cellDataType = getDataType(record[`${dataIndex}`]);
            return (
              <>
                {cellDataType == ColumnDataType.STRING && (
                  <$Tooltip title={record[`${dataIndex}`]}>
                    {record[`${dataIndex}`]}
                  </$Tooltip>
                )}
                {cellDataType == ColumnDataType.AMOUNT && (
                  <$AmountLabel value={Number(record[`${dataIndex}`])} />
                )}
                {cellDataType == ColumnDataType.DATE && (
                  <$DateLabel value={record[`${dataIndex}`]} />
                )}
              </>
            );
          },
        });
      }
    });
    const newColumns = reArrangeColumns(resultColumns, entityType);
    return [...newColumns, lastColumn];
  } catch (error) {
    return resultColumns;
  }
};

/**
 * * @remarks
 * This method is a part of the generateFollowUpResultColumns main function.
 * @description - set column width according to the data index
 * @param dataIndex - column name
 * @returns  width of the column
 */
const setColWidthByDataIndex = (dataIndex: string): number => {
  let colLength: number = 150;
  try {
    switch (dataIndex.toLowerCase()) {
      case FollowupUniqueCol.COUNTRY_ID:
        colLength = 120;
        break;
      case FollowupUniqueCol.REASON:
      case FollowupUniqueCol.COMMENT:
      case FollowupUniqueCol.NOTE:
        colLength = 300;
        break;
      case FollowupUniqueCol.BALANCE:
      case FollowupUniqueCol.AMOUNT:
        colLength = 130;
        break;
      default:
        colLength = 150;
        break;
    }
    if (dataIndex.length > 12) {
      colLength = dataIndex.length * 12;
    }
    return colLength;
  } catch (error) {
    return 150;
  }
};

/**
 * * @remarks
 * This method is a part of the generateFollowUpResultColumns main function.
 * @description - Re-arrange columns in table as the requirement
 * @param columns - array of columns in the table
 * @param entityType - entityType
 * @returns  final array of columns correctly placed
 */
const reArrangeColumns = (
  columns: Array<any>,
  entityType: AllFollowupEntityTypeInterface
): any => {
  try {
    let newColumns = [...columns];
    const linkedColIndex = newColumns.findIndex((col: any) =>
      getLinkedColumnsForEntity(entityType).includes(col.dataIndex)
    );
    if (linkedColIndex > -1) {
      newColumns = moveElementInArr(newColumns, linkedColIndex, 1);
    }
    const workFlowStateIndex = newColumns.findIndex(
      (col: any) => FollowupUniqueCol.WORKFLOW_STATE == col.dataIndex
    );
    if (workFlowStateIndex > -1) {
      newColumns = moveElementInArr(newColumns, workFlowStateIndex, 2);
    }
    const caseHandlerIndex = newColumns.findIndex(
      (col: any) => FollowupUniqueCol.CASE_HANDLER == col.dataIndex
    );
    if (caseHandlerIndex > -1) {
      newColumns = moveElementInArr(
        newColumns,
        caseHandlerIndex,
        newColumns.length - 1
      );
    }
    return newColumns;
  } catch (error) {
    return columns;
  }
};
/**
 * * @remarks
 * This method is a part of the reArrangeColumns function again which is used in generateFollowUpResultColumns main function.
 * @description - Move specific element of the array to another index position
 * @param array - array
 * @param initialIndex - index of element which is wanted to move in current array
 * @param finalIndex - index which is wanted to placed in final array
 * @returns  final array which is moved according to arguments provided.
 */
const moveElementInArr = (
  array: Array<any>,
  initialIndex: number,
  finalIndex: number
): Array<any> => {
  try {
    array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
    return array;
  } catch (error) {
    return array;
  }
};
