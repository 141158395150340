export enum MunicipalityActions {
  NEW = "NEW",
  EDIT = "EDIT",
  DELETE = "DELETE"
}
export const MUNICIPALITY_DRAWER_DEFAULT = {
  municipalityId: -1,
  visible: false,
  title: "",
  type: MunicipalityActions.NEW,
};