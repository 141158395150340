import React, { useEffect, useState } from "react";
import {
  $Breadcrumb,
  $Button,
  $Form,
  $PageHeader,
  $Popconfirm,
  $Skeleton,
} from "us.common/components";
import { useTranslation } from "react-i18next";
import {
  Steps,
  ActivitySetUp,
  ActivityStates,
  SetControls,
  ExecutionProfile,
} from "./Components";
import { RootState } from "us.helper/types";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { Step, FieldName, EVENT_ACTIVITY_TYPE } from "us.collection.admin/constants";
import { manageActivities } from "us.collection.admin/actions";
import { ActivityTypeNames } from "us.collection.admin/components/ManageActivities/Components/ActivityDetail/Components/ActivitySetUp/Components/ActivityTypeDetail/Components";
import { FollowupCategories } from "./Components/SetControls/Components/FollowupDetail/Components";
import { getStateName } from "us.collection.admin/functions";
import { getStepValidationSchema } from "./Validation";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { SaveActivity } from "./Repository";
import { initialStepDetail } from "us.collection.admin/reducers/ManageActivities/State";

const { activity, activityDetail } = manageActivities;

/**
 * @description Activity Registration
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3004104720/Add+Edit+Activity+UI+Implementation
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 02/06/2022
 */
const ActivityDetail: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const {
    stepDetail,
    breadCrumb,
    drawer,
    states,
    parameters,
    activityDetail,
    properties,
    init,
    saveOrEditActivity,
    manageBreadCrumb,
    manageDrawer,
    setStepDetail,
    getActivityDetail,
  } = props;

  const { data: activityTypesList } = properties;
  const { currentStepName, steps, currentStepId, formValues } = stepDetail;
  const { data: stateData } = states;
  const { data: parameterData } = parameters;
  const { isEdit } = drawer;
  const { title, isVisible } = breadCrumb;
  const { isFetching } = activityDetail;
  const isLastStep: boolean = steps.length - 1 == currentStepId;
  const searchParams = parse(search);
  const activityId: string = searchParams["activity-id"] as string;
  const entityType: string = searchParams['entity-type'] as string;

  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState<{
    name: boolean;
    activityCode: boolean;
  }>({ name: false, activityCode: false });

  useEffect(() => {
    init && init({entityType});
    if (isEdit) {
      getActivityDetail && getActivityDetail({ activityId });
    }
  }, []);

  /**
   * @function
   * @description close the breadcrumb
   */
  const onCloseBreadCrumb = () => {
    manageBreadCrumb &&
      manageBreadCrumb({
        isVisible: false,
        title: "",
      });
  };

  /**
   * @function
   * @description close the drawer
   */
  const onCloseDrawer = () => {
    manageDrawer && manageDrawer({ isVisible: false, isEdit: false });
    onCloseBreadCrumb();
    setStepDetail && setStepDetail({ ...initialStepDetail });
  };

  /**
   * @function
   * @description formik handle submit
   * @param data
   */
  const handleSubmit = (data: any) => {
    if (isLastStep) {
      handleSave(data);
    } else {
      handleNextNavigation(data);
    }
  };

  /**
   * @function
   * @description Handle step click event
   * @param {any} data form data
   */
  const handleNextNavigation = (data: any) => {
    const newCurrentStepId = currentStepId + 1;
    const currentStep = steps[newCurrentStepId];

    setStepDetail &&
      setStepDetail({
        ...stepDetail,
        currentStepId: newCurrentStepId,
        currentStepName: currentStep,
        formValues: {
          ...formValues,
          [getStateName(currentStepName)]: { ...data, isCompleted: true },
        },
      });
  };

  /**
   * @function
   * @description handle back button click event
   * @param values
   */
  const handleBackNavigation = (values: any) => {
    setStepDetail && setStepDetail(setStepPayloadData(values));
  };

  /**
   * @function
   * @description set payload data for back button action call
   * @param {any} data form data
   * @returns {object}
   */
  const setStepPayloadData = (data: any) => {
    const newCurrentStepId = currentStepId - 1;
    const currentStep = steps[newCurrentStepId];
    try {
      return {
        ...stepDetail,
        currentStepId: newCurrentStepId,
        currentStepName: currentStep,
        formValues: {
          ...formValues,
          [getStateName(currentStepName)]: data,
        },
      };
    } catch (error) {
      return {
        ...stepDetail,
        currentStepId: newCurrentStepId,
        currentStepName: currentStep,
      };
    }
  };

  /**
   * @function
   * @description handle saving form data
   * @param data
   */
  const handleSave = (data: any) => {
    const formData = {
      ...formValues,
      [getStateName(currentStepName)]: data,
    };

    const payload = SaveActivity.call(
      formData,
      steps,
      stateData,
      parameterData,
      activityTypesList
    );
    saveOrEditActivity && saveOrEditActivity(payload, !isEdit);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formValues[getStateName(currentStepName)],
      }}
      onSubmit={handleSubmit}
      validationSchema={getStepValidationSchema(currentStepName)}
      validateOnMount
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        setFieldValue,
        ...rest
      }: any) => (
        <$Form>
          <$Skeleton
            active
            loading={isEdit ? isFetching : false}
            paragraph={{ rows: 3 }}
            className={isEdit ? "p-3" : ""}
            title={{ width: "100%" }}
          >
            {isVisible && (
              <div className="px-4 pt-3">
                <$Breadcrumb className="mb-0">
                  <$Breadcrumb.Item onClick={onCloseBreadCrumb}>
                    <a>
                      {isEdit &&
                        t(`US.COLLECTION.ADMIN:ACTIVITIES.EDIT_ACTIVITY`)}
                      {!isEdit &&
                        t(`US.COLLECTION.ADMIN:ACTIVITIES.ADD_NEW_ACTIVITY`)}
                    </a>
                  </$Breadcrumb.Item>
                  <$Breadcrumb.Item>
                    {t(`US.COLLECTION.ADMIN:ACTIVITIES.${title}`)}
                  </$Breadcrumb.Item>
                </$Breadcrumb>
                <$PageHeader
                  className="p-0 mb-4"
                  onBack={onCloseBreadCrumb}
                  title={t(
                    title !== FieldName.FOLLOWUP_CATEGORY
                      ? `US.COLLECTION.ADMIN:ACTIVITIES.SELECT_${title}`
                      : "US.COLLECTION.ADMIN:ACTIVITIES.FOLLOWUP_CATEGORY"
                  )}
                />
              </div>
            )}
            {!isVisible && (
              <>
                <div className="ad-layout">
                  <Steps />
                  <>
                    {currentStepName == Step.ACTIVITY_SETUP && (
                      <ActivitySetUp
                        handleSaveBtnDisabledState={(disableDetail: {
                          [key: string]: boolean;
                        }) => {
                          setIsDisabledSaveBtn({
                            ...isDisabledSaveBtn,
                            ...disableDetail,
                          });
                        }}
                      />
                    )}
                    {currentStepName == Step.EXECUTION_PROFILE && (
                      <ExecutionProfile />
                    )}
                    {currentStepName == Step.SET_CONTROLS && <SetControls />}
                    {currentStepName == Step.MODIFY_STATES && (
                      <ActivityStates />
                    )}
                  </>
                </div>
                <div className="drawer-footer-fixed align-content-center justify-content-end">
                  <div>
                    {currentStepId > 0 && (
                      <$Button
                        className="mr-2"
                        onClick={() => handleBackNavigation(values)}
                      >
                        {t(`US.COLLECTION.COMMON:COMMON.BACK`)}
                      </$Button>
                    )}
                    {values?.activityType != EVENT_ACTIVITY_TYPE && (
                      <$Button
                        className="mr-2"
                        onClick={handleSubmit}
                        type="primary"
                        disabled={
                          !rest.isValid ||
                          isDisabledSaveBtn.activityCode ||
                          isDisabledSaveBtn.name
                        }
                        data-testid="add-activity-next"
                      >
                        {isLastStep && t("US.COLLECTION.COMMON:COMMON.SAVE")}
                        {!isLastStep && t("US.COLLECTION.COMMON:COMMON.NEXT")}
                      </$Button>
                    )}
                    {values?.activityType === EVENT_ACTIVITY_TYPE && (
                      <$Button
                        className="mr-2"
                        onClick={handleSubmit}
                        type="primary"
                        disabled={
                          !rest.isValid ||
                          isDisabledSaveBtn.activityCode ||
                          isDisabledSaveBtn.name
                        }
                        data-testid="add-activity-save"
                      >
                        {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                      </$Button>
                    )}
                    {rest.dirty && (
                      <$Popconfirm
                        title={t(
                          "US.COLLECTION.ADMIN:ACTIVITIES.ADDNEWCANCELMSG"
                        )}
                        placement="topLeft"
                        onConfirm={onCloseDrawer}
                        okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                        cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                      >
                        <$Button>
                          {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                        </$Button>
                      </$Popconfirm>
                    )}
                    {!rest.dirty && (
                      <$Button onClick={onCloseDrawer}>
                        {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                      </$Button>
                    )}
                  </div>
                </div>
              </>
            )}
            {isVisible && title === FieldName.FOLLOWUP_CATEGORY && (
              <div className="px-4">
                <FollowupCategories />
              </div>
            )}

            {isVisible && title !== FieldName.FOLLOWUP_CATEGORY && (
              <div className="px-4">
                <ActivityTypeNames />
              </div>
            )}
          </$Skeleton>
        </$Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const {
    stepDetail,
    drawer,
    breadCrumb,
    states,
    parameters,
    activityDetail,
    properties,
  } = manageActivities;

  return {
    stepDetail,
    drawer,
    breadCrumb,
    states,
    parameters,
    activityDetail,
    properties,
  };
};
const mapDispatchToProps = {
  init: activity.init,
  manageBreadCrumb: activity.manageBreadCrumb,
  manageDrawer: activity.manageDrawer,
  setStepDetail: activity.setStep,
  getActivityDetail: activityDetail.get,
  saveOrEditActivity: activity.saveOrEdit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ActivityDetail);
