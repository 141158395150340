import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { stringify, parse } from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "us.helper/types";
import {
  $Affix,
  $Search,
  $Menu,
  $Empty,
  $Skeleton,
  $Tag,
} from "us.common/components";
import { IEntityPanel } from "./Interfaces";
import {
  ActivitySummaryFilters,
  EntityTypes,
} from "us.collection.admin/constants";
import { getSelectedEntityType } from "./Functions";

/**
 * @description -  Activity Summary Home component
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3001221121/Get+Activity+Summary+UI+Implementation
 * @author Mahesh Suranga <maheshsu@unicorn-solutions.com>
 * @since 31/05/2022
 */
const EntityPanel: React.FC<PropsFromRedux & IEntityPanel> = (props) => {
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { state, search } = useLocation();

  const { list } = props;

  const { data, dataSource, isFetching } = list;

  useEffect(() => {
    if (data.length > 0) {
      const entityType = getSelectedEntityType(dataSource, search);
      handleFilterChange(ActivitySummaryFilters.ENTITY_TYPE, entityType);
    }
  }, [data]);

  /**
   * @function
   * @description - read from already typed search filters and update with newly searched field value
   * @param {string} field - search field name
   * @param {any} value - search value
   * @returns
   */
  const handleFilterChange = (field: string, value: any) => {
    const previousFilters = parse(search);
    const newFilters = stringify({ ...previousFilters, [field]: value });

    replace({ state, search: newFilters });
  };

  return (
    <$Affix offsetTop={96}>
      <aside className="activities-layout-side">
        <div className="activitieslist-search">
          <$Search
            size="small"
            name="activitiesSearch"
            className="w-100"
            allowClear={true}
            value={parse(search)[ActivitySummaryFilters.SEARCH_KEY]}
            placeholder={t("US.COLLECTION.ADMIN:ACTIVITIES.SEARCH")}
            onChange={(e: any) =>
              handleFilterChange(
                ActivitySummaryFilters.SEARCH_KEY,
                e.target.value
              )
            }
            data-testid="activity-search"
          />
        </div>
        <div>
          <$Skeleton
            loading={isFetching}
            active
            paragraph={{
              rows: 4,
            }}
          >
            {dataSource.length > 0 && (
              <$Menu
                defaultSelectedKeys={[
                  getSelectedEntityType(dataSource, search),
                ]}
                className="activities-type-menu"
                data-testid="activity-type-menu"
              >
                {dataSource.map(
                  ({
                    entitytype,
                    count,
                  }: {
                    entitytype: string;
                    count: number;
                  }) => (
                    <$Menu.Item
                      key={entitytype}
                      onClick={() =>
                        handleFilterChange(
                          ActivitySummaryFilters.ENTITY_TYPE,
                          entitytype == EntityTypes.DEBTOR
                            ? EntityTypes.AR
                            : entitytype
                        )
                      }
                    >
                      <span className="atm-title">
                        {entitytype == EntityTypes.DEBTOR
                          ? EntityTypes.AR
                          : entitytype}
                      </span>
                      <$Tag className={`ml-2 atm-count`}>{count}</$Tag>
                    </$Menu.Item>
                  )
                )}
              </$Menu>
            )}
            {dataSource.length === 0 && (
              <$Empty
                image={$Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("COMMON.NO_DATA")}
              />
            )}
          </$Skeleton>
        </div>
      </aside>
    </$Affix>
  );
};

const mapStateToProps = (state: RootState) => {
  const { manageActivities } = state;
  const { activities } = manageActivities;
  const { list } = activities;
  return { list };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EntityPanel);
