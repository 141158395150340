export enum Activity {

    GET_ACTIVITY_PARAMETERS_START = "GET_ACTIVITY_PARAMETERS_START",
    GET_ACTIVITY_PARAMETERS_SUCCESS = "GET_ACTIVITY_PARAMETERS_SUCCESS",
    GET_ACTIVITY_PARAMETERS_FAIL = "GET_ACTIVITY_PARAMETERS_FAIL",

    GET_ACTIVITIES_STRAT = "GET_ACTIVITIES_STRAT",
    GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS",
    GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL",

    EXCUTE_ACTIVITY_STRAT = "EXCUTE_ACTIVITY_STRAT",
    EXCUTE_ACTIVITY_SUCCESS = "EXCUTE_ACTIVITY_SUCCESS",
    EXCUTE_ACTIVITY_FAIL = "EXCUTE_ACTIVITY_FAIL",

    ACTIVITIES_FILTER_START = "ACTIVITIES_FILTER_START",

    SET_USER_NAME_CASE_NUMBER = 'SET_USER_NAME_CASE_NUMBER',

    SET_SELECT_ACTIVITY = 'SET_SELECT_ACTIVITY',
    RESET_SELECT_ACTIVITY = 'RESET_SELECT_ACTIVITY',

    ALL_PENDING_ACTIVITY_EXCUTE_STRAT = "ALL_PENDING_ACTIVITY_EXCUTE_STRAT",
    ALL_PENDING_ACTIVITY_EXCUTE_SUCCESS = "ALL_PENDING_ACTIVITY_EXCUTE_SUCCESS",
    ALL_PENDING_ACTIVITY_EXCUTE_FAIL = "ALL_PENDING_ACTIVITY_EXCUTE_FAIL",

    RESET_PENDING_ACTIVITY_STATE = 'RESET_PENDING_ACTIVITY_STATE',
}