import _ from 'lodash';
import {
  IAzureADUser,
  IOptionItem,
  IRoleSummary,
  IUser,
  IUserRole,
  IUserSummary,
  IUserSummaryWithExtendedFields,
} from 'us.common/interfaces';

/**
 * @function
 * @description set activity status as string
 * @param {Array<IUserSummary>} users roles summary data
 * @returns
 */
export const setUserActivityStatusAsAString = (
  users: Array<IUserSummary>
): Array<IUserSummaryWithExtendedFields> => {
  try {
    return users.map((user: IUserSummary) => ({
      ...user,
      activeStateString: user.activeState ? 'active' : 'inactive',
      userRoleName: setUserRolesToOneString(user.roles),
    }));
  } catch (error) {
    return [];
  }
};

/**
 *
 * @param roles
 * @returns
 */
const setUserRolesToOneString = (roles: Array<IRoleSummary>): string => {
  try {
    return roles.length != 0
      ? roles.map(({ name }: { name: string }) => name).join(',')
      : '';
  } catch (error) {
    return '';
  }
};

/**
 * @function
 * @description update State Existing User
 * @param {Array<IUserSummary>} users
 * @param {{ userId: number; activeState: boolean; }} updatedUser updated user state and userId
 * @returns {Array<IUserSummary>}
 */
export const updateStateExistingUser = (
  users: Array<IUserSummary>,
  updatedUser: { userId: number; activeState: boolean }
): Array<IUserSummary> => {
  const { userId, activeState } = updatedUser;
  try {
    return users.map((user: IUserSummary) =>
      user.id == userId ? { ...user, activeState } : user
    );
  } catch (error) {
    return users;
  }
};

/**
 * @function
 * @description delete user form user list
 * @param {Array<IUserSummary>} users
 * @param {number} userId userId
 * @returns {Array<IUserSummary>}
 */
export const deleteExistingUser = (
  users: Array<IUserSummary>,
  userId: number
): Array<IUserSummary> => {
  try {
    return users.filter(({ id }: IUserSummary) => id != userId);
  } catch (error) {
    return users;
  }
};

/**
 * @function
 * @description get azure users option list
 * @param {Array<IAzureADUser>} aDUsers
 * @returns { Array<IOptionItem> }
 */
export const getAzureADOptions = (
  aDUsers: Array<IAzureADUser>,
  users: Array<IUserSummary>
): Array<IOptionItem> => {
  try {
    const userListInDB = new Set(users.map((user: any) => user.azureUserId));
    const filterAzureUserNotInDB = aDUsers.filter(
      ({ id }) => !userListInDB.has(id)
    );

    return filterAzureUserNotInDB.map(
      ({ userPrincipalName }: IAzureADUser) => ({
        label: userPrincipalName,
        value: userPrincipalName,
      })
    );
  } catch (error) {
    return aDUsers.map(({ userPrincipalName }: IAzureADUser) => ({
      label: userPrincipalName,
      value: userPrincipalName,
    }));
  }
};

/**
 * @function
 * @description get User Roles With Selected Status
 * @param {Array<IRoleSummary>} roles
 * @returns {Array<IUserRole>}
 */
export const getUserRolesWithSelectedStatus = (
  roles: Array<IRoleSummary>,
  isSelected: boolean = false
): Array<IUserRole> => {
  try {
    return roles
      .filter(({ activeState }: IRoleSummary) => activeState)
      .map((role: IRoleSummary) => ({ ...role, isSelected }));
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description set save errors message
 * @param {any} errors
 * @returns {string}
 */
export const setSaveUserErrorMessage = (errors: any): string => {
  try {
    if (
      typeof errors?.errors[0] != 'string' &&
      errors?.errors[0]?.error?.includes('user already')
    ) {
      return 'USER_ALREADY_EXISTS';
    } else {
      return 'USER_CAN_NOT_BE_CREATED';
    }
  } catch (error) {
    return 'USER_CAN_NOT_BE_CREATED';
  }
};

/**
 * @function
 * @description update existing user list
 * @param {Array<IUserSummary>} users
 * @param {IUser} updatedUser updated user detail
 * @returns {Array<IUserSummary>}
 */
export const updateExistingUser = (
  users: Array<IUserSummary>,
  updatedUser: IUser
): Array<IUserSummary> => {
  const {
    id,
    accessAuthorityLevelId,
    activeState,
    azureUserId,
    displayName,
    email,
    hasDebtCollectionLicense,
    isCommentEnable,
    maskPrivateData,
    roles,
  } = updatedUser;
  try {
    _.remove(users, (o) => o.id == id);
    users.push({
      id,
      accessAuthorityLevelId,
      activeState,
      azureUserId,
      displayName,
      email,
      hasDebtCollectionLicense,
      isCommentEnable,
      maskPrivateData,
      roles,
    });
    return users;
  } catch (error) {
    return users;
  }
};
