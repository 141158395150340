import { matchPath } from "react-router-dom";
// import { IMetaData } from "us.collection.case/interfaces";
import { Types, EntityTypes, Url } from "us.collection/components/ActivityExecution/Constants"

export const getCaseNumberAndType = (location: any, metaData?: any): {
  type: string;
  caseNo: string;
  eType: string;
  url: string;
  typeId: string
} => {
  const { creditorId } = location.state ?? {};
  const { caseId, entityType } = metaData ?? {}
  const { params }: any = matchPath(location.pathname, {
    path: "/:caseType/:caseId?/:module?",
    exact: true,
    strict: false,
  });
  let caseNo = params["caseId"];
  let type: string = "All";
  let eType: string = "";
  let url: string = "";
  let typeId = params["caseId"];
  switch (params["caseType"]) {
    case 'case':
      if (entityType === 'S') {
        type = Types.SUBCASE;
        eType = EntityTypes.SUBCASE;
      } else {
        type = Types.CASE;
        eType = EntityTypes.CASE;
      }
      url = Url.CASE;
      caseNo = caseId;
      break;
    case "ar":
      type = Types.AR;
      eType = EntityTypes.AR;
      url = Url.AR;
      break;
    case "creditor":
      type = Types.CREDITOR;
      eType = EntityTypes.CREDITOR;
      url = Url.CREDITOR;
      caseNo = creditorId;
      break;
    case "transactions":
      type = Types.TRANSACTIONS;
      eType = EntityTypes.TRANSACTIONS;
      url = Url.TRANSACTIONS
      break;
    case "payment":
      type = Types.PAYMENT;
      eType = EntityTypes.PAYMENT;
      url = Url.PAYMENT;
      break;
    case "bureau":
      type = Types.BUREAU;
      eType = EntityTypes.BUREAU;
      url = Url.BUREAU;
      caseNo = 0;
      break;
    default:
      type = Types.DEFAULT;
      eType = EntityTypes.DEFAULT;
      break;
  }
  return {
    type,
    caseNo,
    eType,
    url,
    typeId
  };
};