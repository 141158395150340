import { ICriteria, INonProductionDay } from "us.collection.admin/interfaces";
import moment from "moment";
import {
  NonProductionDayTypeInterface,
  criteria,
  NonProductionDataType,
  dateFormat,
} from "us.collection.admin/constants";
import { Guid } from "guid-typescript";

/**
 * @function
 * @description add or update red days to current redDays state
 * @param {Array<INonProductionDay>} redDays current redDays
 * @param {INonProductionDay} day new or existing day
 * @returns  {Array<INonProductionDay>}
 */
export const addOrUpdateRedDays = (
  redDays: Array<INonProductionDay>,
  day: INonProductionDay,
  uniqueKey: Guid
): Array<INonProductionDay> => {
  try {
    const { key } = day;
    if (!key.isEmpty()) {
      let isMatched = false
      redDays = redDays.map((redDay: INonProductionDay) => {
        if (key.equals(redDay.key)) {
          isMatched = true
          return day
        } else {
          return redDay
        }
      });
      if (!isMatched) {
        redDays.push(day);
      }
    } else {
      redDays.push({ ...day, key: uniqueKey });
    }
    return [...redDays];
  } catch (error) {
    return [...redDays];
  }
};

/**
 * @function
 * @description get Criteria array object
 * @param {NonProductionDayTypeInterface} selectedCriteria selected criteria
 * @returns {Array<ICriteria>}
 */
export const getCriteria = (
  selectedCriteria: NonProductionDayTypeInterface
): Array<ICriteria> => {
  try {
    return criteria.map(({ type, value }: ICriteria) => ({
      type,
      value: type == selectedCriteria ? true : false,
    }));
  } catch (error) {
    return criteria;
  }
};

/**
 * @function
 * @description get Red Day InitialValues
 * @param {NonProductionDayTypeInterface} selectedCriteria selected criteria
 * @returns
 */
export const getRedDayInitialValues = (
  selectedCriteria: NonProductionDayTypeInterface
): INonProductionDay => {
  try {
    return {
      id: -1,
      key: Guid.createEmpty(),
      isFixedDate: false,
      date: moment().add(1, 'days').format(dateFormat),
      reason: "",
      criteria: getCriteria(selectedCriteria),
    };
  } catch (error) {
    return {
      id: -1,
      key: Guid.createEmpty(),
      isFixedDate: false,
      date: moment().format(dateFormat),
      reason: "",
      criteria: [],
    };
  }
};

/**
 * @function
 * @description remove red day
 * @param {Array<INonProductionDay>} redDays all redDays
 * @param {number} removeDayId day Id
 * @returns {Array<INonProductionDay>}
 */
export const removeRedDays = (
  redDays: Array<INonProductionDay>,
  removeDayId: Guid
): Array<INonProductionDay> => {
  try {
    return redDays.filter(({ key }: INonProductionDay) => !key.equals(removeDayId));
  } catch (error) {
    return redDays;
  }
};

/**
 * @function
 * @description add or update red days to current redDays state
 * @param {NonProductionDataType} redDays all redDays
 * @param {INonProductionDay} day new or existing day
 * @returns {NonProductionDataType}
 */
export const addOrUpdateRedDayRelevantCriteria = (
  redDays: NonProductionDataType,
  day: INonProductionDay
): NonProductionDataType => {
  try {
    const { criteria } = day;
    const uniqueKey = Guid.create();
    criteria.forEach(({ type, value }: ICriteria) =>
      value
        ? (redDays[type] = addOrUpdateRedDays(redDays[type], day, uniqueKey))
        : (redDays[type] = removeRedDayFromOtherCriteria(redDays[type], day))
    );
    return redDays;
  } catch (error) {
    return redDays;
  }
};

/**
 * @function
 * @description remove red day other criteria
 * @param {Array<INonProductionDay>} redDays all redDays
 * @param {INonProductionDay} day new or existing day
 * @returns {Array<INonProductionDay>}
 */
const removeRedDayFromOtherCriteria = (
  redDays: Array<INonProductionDay>,
  day: INonProductionDay
): Array<INonProductionDay> => {
  try {
    const { reason } = day;
    const key: undefined | Guid = redDays.find(
      (redDay: INonProductionDay) => redDay.reason == reason
    )?.key;
    return key ? removeRedDays(redDays, key) : redDays;
  } catch (error) {
    return redDays;
  }
};

/**
 * @function
 * @description remove red day
 * @param {Array<INonProductionDay>} redDays all redDays
 * @param {INonProductionDay} day new or existing day
 * @returns
 */
export const removeRedDayFromRelevantCriteria = (
  redDays: NonProductionDataType,
  day: INonProductionDay
): NonProductionDataType => {
  try {
    const { criteria, key } = day;

    criteria.forEach(
      ({ type, value }: ICriteria) =>
        value && (redDays[type] = removeRedDays(redDays[type], key))
    );
    return redDays;
  } catch (error) {
    return redDays;
  }
};

/**
 * @function
 * @description set red day for relevant criteria
 * @param {Array<Omit<INonProductionDay, "key">>} data API response
 * @returns {NonProductionDataType}
 */
export const setRedDayForRelevantCriteria = (
  data: Array<Omit<INonProductionDay, "key">>
): NonProductionDataType => {
  const redDays: NonProductionDataType = {
    nonBanking: [],
    nonSms: [],
    nonPrinting: [],
    nonWorkflow: [],
    nonEmail: [],
  };
  try {
    data.map((item: Omit<INonProductionDay, "key">) => {
      const { criteria } = item;
      const uniqueKey = Guid.create();
      criteria.forEach(
        ({ type, value }: ICriteria) =>
          value && redDays[type].push({ ...item, key: uniqueKey })
      );
    });
    return { ...redDays };
  } catch (error) {
    return { ...redDays };
  }
};
