export enum CourtInfoAction {
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum CourtInfoTableColumn {
  MENU = "menu",
  COURT_NAME = "courtName",
  COURT_ADDRESS = "address",
  POSTAL_CODE = "postalCode",
  POSTAL_AREA = "postPlace",
  TELEPHONE = "telephone",
  ORGANIZATION_NO = "orgNo",
}

export const COLUMNS = [
  {
    id: 0,
    key: CourtInfoTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: CourtInfoTableColumn.COURT_NAME,
    title: "NAME",
  },
  {
    id: 2,
    key: CourtInfoTableColumn.COURT_ADDRESS,
    title: "ADDRESS",
  },
  {
    id: 3,
    key: CourtInfoTableColumn.POSTAL_CODE,
    title: "POSTAL_CODE",
  },
  {
    id: 4,
    key: CourtInfoTableColumn.POSTAL_AREA,
    title: "POSTAL_AREA",
  },
  {
    id: 5,
    key: CourtInfoTableColumn.TELEPHONE,
    title: "TELEPHONE",
  },
  {
    id: 6,
    key: CourtInfoTableColumn.ORGANIZATION_NO,
    title: "ORGANIZATION_NO",
  },
];

export enum ColumnSearchType {
  STRING = "string",
  NUMBER = "number",
}

export enum Align {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

export const DEFAULT_TABLE_INFO = {
  filters: [],
  sorter: { columnKey: "", order: "" },
  data: [],
};

export const DEFAULT_COLUMN_FILTERS = {
  courtName: "",
  address: "",
  postalCode: "",
  postPlace: "",
  telephone: "",
  orgNo: "",
};

export enum DrawerTypes {
  COURT_ITEM_ADD = "AddNew",
  COURT_ITEM_EDIT = "Edit",
  MANAGE_COURT_TYPES = "ManageCourtTypes",
}

export enum PostalDrawerTypes {
  POSTAL_CODES = "postalCodes",
  RELEVANT_POSTAL_CODES = "relavantPostalCodes",
}

export const InitialFormData = {
  courtType: -1,
  courtName: "",
  address1: "",
  address2: "",
  postalCode: "",
  postalArea: "",
  telephone: "",
  orgNo: "",
  bankAccountNo: "",
  selectedPostalCodes: [],
};

export const InitialDrawerData = {
  visibility: false,
  drawerType: "",
};

export enum PostalCodeParams {
  COUNTRY_CODE = "NO",
  POSTAL_CODE = "PostalCode",
  ALL_SEARCH_TYPE = 'all'
}