import { ACTIVITY_VERSION_DROP_DOWN_V1, ACTIVITY_VERSION_DROP_DOWN_V2 } from "../Constants";
import { DropDown, TemplateCategory } from "../Interfaces";
import { EVENT_ACTIVITY_TYPE, EntityType } from "us.collection.admin/constants";
import { EntityTypeShortForm } from "us.common/constants";
import { trimText } from "us.common/functions";

/**
 * @function
 * @description Check wether the activity type is event or not
 * @param {string} entityType selected entity type
 * @param {string} activityType selected activity type
 * @returns {boolean} activity type is event or not
 */
export const isEventActivityType = (
  entityType: string,
  activityType: string
): boolean => {
  try {
    return (
      (trimText(entityType) == EntityType.INVOICE ||
        trimText(entityType) == EntityType.CASE) &&
      trimText(activityType) == EVENT_ACTIVITY_TYPE?.toLowerCase()
    );
  } catch (error) {
    return false;
  }
};

/**
 * @function
 * @description Get short form workflow type
 * @param {string} entityType selected entity type
 * @returns {string} entity type
 */
export const getWorkflowType = (entityType: string): string => {
  try {
    switch (trimText(entityType)) {
      case EntityType.INVOICE:
        return EntityTypeShortForm.SUB_CASE;
      case EntityType.CASE:
        return EntityTypeShortForm.CASE;
      default:
        return "";
    }
  } catch (error) {
    return "";
  }
};

/**
 * @function
 * @description get template category list options
 * @param {TemplateCategory[]} categories template categories data
 * @returns {DropDown<Number>} category options array
 */
export const getDropDownContent = (
  categories: TemplateCategory[]
): DropDown<Number>[] => {
  const list = [
    {
      label: "",
      value: 0,
    },
  ];
  try {
    if (categories.length > 0) {
      categories.map(({ categoryDisplayName, categoryId }: any) =>
        list.push({ label: categoryDisplayName, value: categoryId })
      );
      return list;
    } else return list;
  } catch {
    return list;
  }
};

/**
 * Get version selection
 * @param categoryId - Template category id
 * @param isEdit - Is edit drawer or new drawer
 * @returns 
 */
export const getVersionOptions = (categoryId: number, isEdit: boolean): Array<any> => {
  if(categoryId || !isEdit){
    return ACTIVITY_VERSION_DROP_DOWN_V2;
  }
  return ACTIVITY_VERSION_DROP_DOWN_V1;
}
