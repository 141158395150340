import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    fromAmount: Yup.number(),
    // .test(
    //   "fromAmount",
    //   "US.COLLECTION.VALIDATIONS:REQUIRED.FROM_AMOUNT_CANNOT_BE_GREATER_THAN_TO_AMOUNT",
    //   function (val: any) {
    //     if (
    //       this.parent.fromAmount != undefined &&
    //       this.parent.toAmount != undefined
    //     ) {
    //       return val < this.parent.toAmount;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    toAmount: Yup.number()
      .test(
        "toAmount",
        "US.COLLECTION.VALIDATIONS:REQUIRED.TO_AMOUNT_CANNOT_BE_LESSER_THAN_FROM_AMOUNT",
        function (val: any) {
          if (this.parent.fromAmount != undefined) {
            return val > this.parent.fromAmount;
          } else {
            return true;
          }
        }
      )
      .test(
        "toAmountCannotBeEmpty",
        "US.COLLECTION.VALIDATIONS:REQUIRED.TO_AMOUNT_CANNOT_BE_EMPTY",
        function (val: any) {
          if (this.parent.rangeLength != 0) {
            return val > 0;
          } else {
            return true;
          }
        }
      ),
    noOfFee: Yup.number()
      .nullable()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_TYPE"),
    amount: Yup.number().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD"
    ),
    doubleAmount: Yup.number().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD"
    ),
  });
};
