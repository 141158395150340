import * as Yup from 'yup';

export default () => {
	return Yup.object().shape({
		typeId: Yup.string().required(
			'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.SYSTEM_SETTING_TYPE_REQUIRED'
		),
		name: Yup.string()
			.trim()
			.required(
				'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.NAME_REQUIRED'
			),
		description: Yup.string()
			.trim()
			.required(
				'US.COLLECTION.ADMIN:SYSTEM_SETTINGS.DESCRIPTION_REQUIRED'
			),
	});
};
