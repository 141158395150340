import { takeLatest, put, call } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { ManageFollowup } from "us.collection.admin/constants/Actions";
import { generateFollowUpResultColumns } from "us.collection.admin/functions";
import Common from "us.common";
import {
  CustomQueryDrawerType,
  InitialCustomQueryDrawer,
} from "us.collection.admin/constants";

const { $MessageBox } = Common.Components;

const { manageFollowupActions } = Actions;

const ManageFollowupSagas = {
  categories: {
    get: function* (action: any): any {
      try {
        const data = yield call(API.manageFollowup.categories.get);
        if (Array.isArray(data)) {
          yield put(manageFollowupActions.categories.success(data));
        } else {
          yield put(manageFollowupActions.categories.fail(data));
        }
      } catch (error) {
        yield put(manageFollowupActions.categories.fail(error as any));
      }
    },
    save: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageFollowup.categories.save, params);
        $MessageBox(
          "success",
          `US.COLLECTION.ADMIN:BULKOPERATION.FOLLOW_UP_CATEGORY_ADDED_SUCCESSFULLY`,
          "",
          ""
        );
        if (manageFollowupActions.categories.get) {
          yield put(manageFollowupActions.categories.get({}));
        }
      } catch (error) {
        const errorObj = error as any;
        if (errorObj?.message == "CategoryName is already exists.") {
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:BULKOPERATION.FOLLOW_UP_CATEGORY_ALREADY_EXISTS`,
            "",
            ""
          );
        }
        if (manageFollowupActions.categories.saveFail) {
          yield put(manageFollowupActions.categories.saveFail(error as any));
        }
      }
    },
  },
  list: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageFollowup.list.get, params);
        if (Array.isArray(data)) {
          yield put(manageFollowupActions.followups.success(data));
        } else {
          yield put(manageFollowupActions.followups.fail(data));
        }
      } catch (error) {
        yield put(manageFollowupActions.followups.fail(error as any));
      }
    },
  },
  followupResult: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageFollowup.result.get, params);
        yield put(
          manageFollowupActions.followupResult.success({
            data,
            column: generateFollowUpResultColumns(data, data.entityType),
            result: data.followupData,
          })
        );
      } catch (error) {
        yield put(manageFollowupActions.followupResult.fail(error as any));
      }
    },
  },
  followup: {
    remove: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.manageFollowup.followup.remove, params);
        if (
          data.hasOwnProperty("followupId") &&
          manageFollowupActions.followups.removeSuccess
        ) {
          yield put(manageFollowupActions.followups.removeSuccess(data));
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:BULKOPERATION.SUCCESSFULLY_DELETED`,
            "",
            ""
          );
        } else if (manageFollowupActions.followups.removeFail) {
          yield put(manageFollowupActions.followups.removeFail(data));
          $MessageBox(
            "error",
            `US.COLLECTION.ADMIN:BULKOPERATION.DELETION_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        if (manageFollowupActions.followups.removeFail) {
          yield put(manageFollowupActions.followups.removeFail(error as any));
        }
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:BULKOPERATION.DELETION_FAILED`,
          "",
          ""
        );
      }
    },
  },
  customQuery: {
    entityTypes: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(
            API.manageFollowup.customQuery.entityTypes.get,
            params
          );
          yield put(manageFollowupActions.entityTypes.success(data));
        } catch (error) {
          yield put(manageFollowupActions.entityTypes.fail(error as any));
        }
      },
    },
    properties: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(
            API.manageFollowup.customQuery.properties.get,
            params
          );
          if (manageFollowupActions.customQuery.getPropertiesSuccess) {
            yield put(
              manageFollowupActions.customQuery.getPropertiesSuccess(data)
            );
          }
        } catch (error) {
          if (manageFollowupActions.customQuery.getPropertiesFail) {
            yield put(
              manageFollowupActions.customQuery.getPropertiesFail(error as any)
            );
          }
        }
      },
    },
    result: {
      get: function* (action: any): any {
        const params = action?.payload?.data?.customQuery ?? {};
        const entityType = action?.payload?.data?.entityType ?? {};
        const type = action?.payload?.data?.type ?? {};
        const title = action?.payload?.data?.title ?? {};
        try {
          const data = yield call(
            API.manageFollowup.customQuery.result.get,
            params
          );
          if (data?.errors) {
            if (manageFollowupActions.customQuery.executeFail) {
              yield put(
                manageFollowupActions.customQuery.executeFail(data?.errors)
              );
            }
            $MessageBox(
              "error",
              data?.errors[0]?.message,
              "",
              ""
            );
          } else {
            if (manageFollowupActions.customQuery.executeSuccess) {
              yield put(
                manageFollowupActions.customQuery.executeSuccess({
                  response: data,
                  entityType,
                })
              );
            }
            if(manageFollowupActions.customQuery.manageDrawer){
              yield put(manageFollowupActions.customQuery.manageDrawer({
                ...InitialCustomQueryDrawer,
                title,
                type,
              }))
            }
          }
        } catch (error) {
          if (manageFollowupActions.customQuery.executeFail) {
            yield put(
              manageFollowupActions.customQuery.executeFail(error as any)
            );
          }
        }
      },
    },
    activities: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(
            API.manageFollowup.customQuery.activities.get,
            params
          );

          yield put(manageFollowupActions.activities.success(data));
        } catch (error) {
          yield put(manageFollowupActions.activities.fail(error as any));
        }
      },
    },
    users: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.manageFollowup.customQuery.users.get);
          yield put(manageFollowupActions.users.success(data));
        } catch (error) {
          yield put(manageFollowupActions.users.fail(error as any));
        }
      },
    },
    roles: {
      get: function* (action: any): any {
        const params = action?.payload?.data ?? {};
        try {
          const data = yield call(API.manageFollowup.customQuery.roles.get);
          yield put(manageFollowupActions.roles.success(data));
        } catch (error) {
          yield put(manageFollowupActions.roles.fail(error as any));
        }
      },
    },
    save: function* (action: any): any {
      const params = action?.payload?.data?.request ?? {};
      const history = action?.payload?.data?.history ?? {};
      try {
        if (params.isDynamicFollowup) {
          $MessageBox(
            "info",
            `US.COLLECTION.ADMIN:BULKOPERATION.CUSTOM_QUERY_VALIDATING`,
            "",
            ""
          );
          const data = yield call(
            API.manageFollowup.customQuery.result.get,
            params.customQuery
          );
          if (data?.errors) {
            $MessageBox(
              "error",
              `US.COLLECTION.ADMIN:BULKOPERATION.CUSTOM_QUERY_IS_NOT_VALID`,
              "",
              ""
            );
            return
          }
        }
        const data = yield call(API.manageFollowup.customQuery.save, params);
        if (params?.followupId == -1) {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:BULKOPERATION.FOLLOW_UP_SAVED_SUCCESSFULLY`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "success",
            `US.COLLECTION.ADMIN:BULKOPERATION.FOLLOW_UP_EDITED_SUCCESSFULLY`,
            "",
            ""
          );
        }
        yield put(manageFollowupActions.customQuery.success(data));

        if (manageFollowupActions.customQuery.manageDrawer) {
          yield put(
            manageFollowupActions.customQuery.manageDrawer({
              ...InitialCustomQueryDrawer,
              title: "",
              type: CustomQueryDrawerType.VIEW_RESULT,
              visible: false,
            })
          );
        }
        if (manageFollowupActions.followUpDetails.reset) {
          yield put(manageFollowupActions.followUpDetails.reset({}));
        }
        history.push("/manage-followup/all");
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ADMIN:BULKOPERATION.FOLLOW_UP_SAVING_FAILED`,
          "",
          ""
        );
        yield put(manageFollowupActions.customQuery.fail(error as any));
      }
    },
  },
  followUpDetails: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const history = action?.payload?.data?.history;
      try {
        const data = yield call(API.manageFollowup.followUpDetails.get, params);
        yield put(manageFollowupActions.followUpDetails.success(data));
        history.push("custom-query");
      } catch (error) {
        yield put(manageFollowupActions.followUpDetails.fail(error as any));
      }
    },
  },
};

export default [
  takeLatest(
    ManageFollowup.GET_FOLLOWUP_CATEGORIES_START,
    ManageFollowupSagas.categories.get
  ),
  takeLatest(
    ManageFollowup.SAVE_FOLLOW_UP_CATEGORY,
    ManageFollowupSagas.categories.save
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOWUP_LIST_START,
    ManageFollowupSagas.list.get
  ),
  takeLatest(
    ManageFollowup.REMOVE_FOLLOWUP_START,
    ManageFollowupSagas.followup.remove
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOWUP_RESULT_START,
    ManageFollowupSagas.followupResult.get
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOW_UP_ENTITY_TYPES,
    ManageFollowupSagas.customQuery.entityTypes.get
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOW_UP_CUSTOM_QUERY_PROPERTIES,
    ManageFollowupSagas.customQuery.properties.get
  ),
  takeLatest(
    ManageFollowup.EXECUTE_CUSTOM_QUERY,
    ManageFollowupSagas.customQuery.result.get
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOW_UP_ACTIVITIES,
    ManageFollowupSagas.customQuery.activities.get
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOW_UP_USERS,
    ManageFollowupSagas.customQuery.users.get
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOW_UP_ROLES,
    ManageFollowupSagas.customQuery.roles.get
  ),
  takeLatest(
    ManageFollowup.SAVE_CUSTOM_QUERY,
    ManageFollowupSagas.customQuery.save
  ),
  takeLatest(
    ManageFollowup.GET_FOLLOWUP_DETAILS,
    ManageFollowupSagas.followUpDetails.get
  ),
];
