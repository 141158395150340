import * as Components from './components';
import * as  Routes from './routes';
import * as Containers from './containers';
import * as Reducers from './reducers';

export default {
    Components,
    Containers,
    Routes,
    Reducers
}