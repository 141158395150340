import { User } from 'us.common/constants';

export const getUsers = () => ({
	type: User.GET_USERS_START,
});

export const getUserSuccess = (data: any) => ({
	type: User.GET_USERS_SUCCESS,
	payload: data,
});

export const getUserFail = (data: any) => ({
	type: User.GET_USERS_FAIL,
	payload: data,
});

export const getCreditorGroups = () => ({
	type: User.GET_CREDITOR_GROUP_START,
});

export const getCreditorGroupsSuccess = (data: any) => ({
	type: User.GET_CREDITOR_GROUP_SUCCESS,
	payload: data,
});
export const getCreditorCompanyByGroupId = (data: any) => ({
	type: User.GET_CREDITOR_COMPANY_START,
	payload: data,
});

export const getCreditorCompaniesSuccess = (data: any) => ({
	type: User.GET_CREDITOR_COMPANY_SUCCESS,
	payload: data,
});

export const getCreditorByCompanyId = (data: any) => ({
	type: User.GET_CREDITOR_BY_COMPANY_ID_START,
	payload: data,
});

export const getCreditorByCompanyIdSuccess = (data: any) => ({
	type: User.GET_CREDITOR_BY_COMPANY_ID_SUCCESS,
	payload: data,
});
export const getUserById = (data: any) => ({
	type: User.GET_USER_BY_ID_START,
	payload: data,
});

export const getUserByIdSuccess = (data: any) => ({
	type: User.GET_USER_BY_ID_SUCCESS,
	payload: data,
});

export const getAzureADUsers = () => ({
	type: User.GET_AZURE_AD_USERS_START,
});

export const getAzureADUserSuccess = (data: any) => ({
	type: User.GET_AZURE_AD_USERS_SUCCESS,
	payload: data,
});

export const getAzureADUserById = (data: any) => ({
	type: User.GET_AZURE_AD_USER_BY_ID_START,
	payload: data,
});

export const getAzureADUserByIdSuccess = (data: any) => ({
	type: User.GET_AZURE_AD_USER_BY_ID_SUCCESS,
	payload: data,
});
export const manageUserDrawer = (data: any) => ({
	type: User.MANAGE_USER_DRAWER,
	payload: data,
});

export const saveNewUser = (data: any) => ({
	type: User.SAVE_NEW_USER_START,
	payload: data,
});

export const saveNewUserSuccess = (data: any) => ({
	type: User.SAVE_NEW_USER_SUCCESS,
	payload: data,
});

export const saveNewB2CUser = (data: any) => ({
	type: User.SAVE_NEW_B2C_USER_START,
	payload: data,
});

export const saveNewB2CUserSuccess = (data: any) => ({
	type: User.SAVE_NEW_B2C_USER_SUCCESS,
	payload: data,
});
export const saveUserHistory = (data: any) => ({
	type: User.ADD_USER_HISTORY_START,
	payload: data,
});

export const updateUser = (data: any, history: any) => ({
	type: User.UPDATE_USER_START,
	payload: data,
	history: history,
});

export const updateUserSuccess = (data: any) => ({
	type: User.UPDATE_USER_SUCCESS,
	payload: data,
});

export const deleteUser = (data: any) => ({
	type: User.DELETE_USER_START,
	payload: data,
});

export const resetSelectedUser = () => ({
	type: User.RESET_SELECTED_USER_START,
});
export const deleteUserSuccess = (data: any, userId: any) => ({
	type: User.DELETE_USER_SUCCESS,
	payload: data,
	userId: userId,
});

export const getUserHistoryById = (data: any) => ({
	type: User.GET_USER_HISTORY_BY_ID_START,
	payload: data,
});

export const getUserHistorySuccess = (data: any) => ({
	type: User.GET_USER_HISTORY_BY_ID_SUCCESS,
	payload: data,
});

export const newUserView = () => ({
	type: User.NEW_USER_VIEW_START,
});
export const newUserSkeleton = () => ({
	type: User.NEW_USER_SKELETON_START,
});
export const editUserView = (data: any) => ({
	type: User.EDIT_USER_VIEW_START,
	payload: data,
});
export const editB2CUserView = (data: any) => ({
	type: User.EDIT_B2C_USER_VIEW_START,
	payload: data,
});

export const userStateChange = (data: any) => ({
	type: User.USER_STATE_CHANGE_START,
	payload: data,
});
export const userStateChangeSuccess = (data: any) => ({
	type: User.USER_STATE_CHANGE_SUCCESS,
	payload: data,
});

export const edituser = (data: any) => ({
	type: User.EDIT_B2C_USER,
	payload: data,
});
export const edituserSuccess = (data: any) => ({
	type: User.EDIT_B2C_USER_SUCCESS,
	payload: data,
});

export const edituserFail = (data: any) => ({
	type: User.EDIT_B2C_USER_FAIL,
	payload: data,
});

export const deleteB2CUser = (data: any) => ({
	type: User.DELETE_B2C_USER_START,
	payload: data,
});

export const deleteB2CUserSuccess = (data: any, userId: any) => ({
	type: User.DELETE_B2C_USER_SUCCESS,
	payload: data,
	userId: userId,
});

export const getAuthorizationLevel = (data: any) => ({
	type: User.GET_AUTHORIZATION_LEVEL_START,
	payload: data,
});
export const getAuthorizationLevelSuccess = (data: any) => ({
	type: User.GET_AUTHORIZATION_LEVEL_SUCCESS,
	payload: data,
});
export const getAuthorizationLevelFail = (data: any) => ({
	type: User.GET_AUTHORIZATION_LEVEL_FAIL,
	payload: data,
});
