export enum User {
    GET_USERS_START = "GET_USERS_START",
    GET_USERS_START_SUCCESS = "GET_USERS_START_SUCCESS",
    GET_USERS_START_FAIL = "GET_USERS_START_FAIL",

    GET_CREDITOR_GROUP_START = "GET_CREDITOR_GROUP_START",
    GET_CREDITOR_GROUP_SUCCESS = "GET_CREDITOR_GROUP_SUCCESS",
    GET_CREDITOR_GROUP_FAIL = "GET_CREDITOR_GROUP_FAIL",

    GET_CREDITOR_COMPANY_START = "GET_CREDITOR_COMPANY_START",
    GET_CREDITOR_COMPANY_SUCCESS = "GET_CREDITOR_COMPANY_SUCCESS",
    GET_CREDITOR_COMPANY_FAIL = "GET_CREDITOR_COMPANY_FAIL",

    GET_CREDITOR_BY_COMPANY_ID_START = "GET_CREDITOR_BY_COMPANY_ID_START",
    GET_CREDITOR_BY_COMPANY_ID_SUCCESS = "GET_CREDITOR_BY_COMPANY_ID_SUCCESS",
    GET_CREDITOR_BY_COMPANY_ID_FAIL = "GET_CREDITOR_BY_COMPANY_ID_FAIL",

    GET_USER_BY_ID_START = "GET_USER_BY_ID_START",
    GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS",
    GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL",

    GET_AZURE_AD_USERS_START = "GET_AZURE_AD_USERS_START",
    GET_AZURE_AD_USERS_SUCCESS = "GET_AZURE_AD_USERS_SUCCESS",
    GET_AZURE_AD_USERS_FAIL = "GET_AZURE_AD_USERS_FAIL",

    GET_AZURE_AD_USER_BY_ID_START = "GET_AZURE_AD_USER_BY_ID_START",
    GET_AZURE_AD_USER_BY_ID_SUCCESS = "GET_AZURE_AD_USER_BY_ID_SUCCESS",
    GET_AZURE_AD_USER_BY_ID_FAIL = "GET_AZURE_AD_USER_BY_ID_FAIL",

    MANAGE_USER_DRAWER = "MANAGE_USER_DRAWER",

    NEW_USER_SKELETON_START = "NEW_USER_SKELETON_START",

    SAVE_NEW_USER_START = "SAVE_NEW_USER_START",
    SAVE_NEW_USER_SUCCESS = "SAVE_NEW_USER_SUCCESS",
    SAVE_NEW_USER_FAIL = "SAVE_NEW_USER_FAIL",

    ADD_USER_HISTORY_START = "ADD_USER_HISTORY_START",
    ADD_USER_HISTORY_SUCCESS = "ADD_USER_HISTORY_SUCCESS",
    ADD_USER_HISTORY_FAIL = "ADD_USER_HISTORY_FAIL",

    UPDATE_USER_START = "UPDATE_USER_START",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAIL = "UPDATE_USER_FAIL",

    EDIT_USER_VIEW_START = "EDIT_USER_VIEW_START",
    EDIT_USER_VIEW_SUCCESS = "EDIT_USER_VIEW_SUCCESS",
    EDIT_USER_VIEW_FAIL = "EDIT_USER_VIEW_FAIL",

    EDIT_B2C_USER_VIEW_START = "EDIT_B2C_USER_VIEW_START",

    DELETE_USER_START = "DELETE_USER_START",
    DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
    DELETE_USER_FAIL = "DELETE_USER_FAIL",

    RESET_SELECTED_USER_START = "RESET_SELECTED_USER_START",
    RESET_SELECTED_USER_SUCCESS = "RESET_SELECTED_USER_SUCCESS",
    RESET_SELECTED_USER_FAIL = "RESET_SELECTED_USER_FAIL",

    GET_USER_HISTORY_BY_ID_START = "GET_USER_HISTORY_BY_ID_START",
    GET_USER_HISTORY_BY_ID_SUCCESS = "GET_USER_HISTORY_BY_ID_SUCCESS",
    GET_USER_HISTORY_BY_ID_FAIL = "GET_USER_HISTORY_BY_ID_FAIL",

    USER_STATE_CHANGE_START = "USER_STATE_CHANGE_START",
    USER_STATE_CHANGE_SUCCESS = "USER_STATE_CHANGE_SUCCESS",
    USER_STATE_CHANGE_FAIL = "USER_STATE_CHANGE_FAIL",

    NEW_USER_VIEW_START = "NEW_USER_VIEW_START",

    SAVE_NEW_B2C_USER_START = "SAVE_NEW_B2C_USER_START",
    SAVE_NEW_B2C_USER_SUCCESS = "SAVE_NEW_B2C_USER_SUCCESS",
    SAVE_NEW_B2C_USER_FAIL = "SAVE_NEW_B2C_USER_FAIL"

}