export enum ActivitySummaryFilters {
  SEARCH_KEY = "search-key",
  ENTITY_TYPE = "entity-type",
}

export enum EntityTypes {
  CASE = "Case",
  INVOICE = "Invoice",
  CREDITOR = "Creditor",
  DEBTOR = "Debtor",
  BUREAU = "Bureau",
  PAYMENT = "Payment",
  AR = "AR"
}

export const EntityTypePriority : any = {
  [EntityTypes.INVOICE]: 1,
  [EntityTypes.CASE]: 2,
  [EntityTypes.DEBTOR]: 3,
  [EntityTypes.CREDITOR]: 4,
  [EntityTypes.BUREAU]: 5,
  Default: Number.MAX_SAFE_INTEGER,
};
