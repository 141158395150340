import { httpCollection, versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';

export default {
  creditorGroups: {
    get: <P extends {}>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            "creditorgroups",
            {},
            ServiceConfig()[`entityservice`]['creditorGroups']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  creditorGroup: {
    save: <P extends {}>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.post(
            "entityservice",
            "creditorgroups",
            params,
            ServiceConfig()[`entityservice`]['creditorGroups.save']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    update: <P extends {id: number}>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const {id, ...body} = params
          const { data } = await versionedHttpCollection.put(
            "entityservice",
            `creditorgroups/${params?.id}`,
            body,
            ServiceConfig()[`entityservice`]['creditorGroups.update']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: (id: number): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.delete(
            "entityservice",
            "creditorgroups",
            id,
            {},
            ServiceConfig()[`entityservice`]['creditorGroups.delete']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  selectedCreditors: {
    get: <P extends {creditorGroupId: number, withCreditors: boolean}>(params: P): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "entityservice",
            `creditorgroups/${params?.creditorGroupId}?withcreditors=${params.withCreditors}`,
            {},
            ServiceConfig()[`entityservice`]['creditorGroups.withCreditors']
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  }
};
