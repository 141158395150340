import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { Formik } from "formik";
import { BoxIcons, IconTypes, ReloadOutlined } from "us.icons";
import { Followups } from "./Components/Followups";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { parse, stringify } from "query-string";
import { manageFollowupActions } from "us.collection.admin/actions";
import "./Home.scss";
import { DefaultCategory } from "us.collection.admin/constants";
import { $Tooltip } from "us.common/components";
import { handleGoBack } from "us.helper/utility";

const {
  $Button,
  $PageHeader,
  $Divider,
  $Affix,
  $Switch,
  ManageFollowUpLeftPanel,
} = Common.Components;
const { categories, followups, followUpDetails } = manageFollowupActions;

/**
 * @description Manage Followup
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2942926869/Manage+Follow-up+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 04/04/2022
 */
const BulkOperation: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { categoryId = DefaultCategory.categoryID } =
    useParams<{ categoryId?: string }>();

  const { categories, getCategories, getFollowups, resetFollowUpDetails } =
    props;
  const { data, isFetching } = categories;
  const searchParams = parse(location.search);
  const isSelfCreated: boolean =
    searchParams["self-created"] && searchParams["self-created"] == "true"
      ? true
      : false;

  useEffect(() => {
    getCategories && getCategories({});
  }, []);

  /**
   * @function
   * @description add search params
   * @param location location object
   * @param {string} field key of search param
   * @param {string} value value of search param
   */
  const addSearchParams = (field: string, value: string) => {
    const previousSearchParams = parse(location.search);
    const newSearchParams = stringify({
      ...previousSearchParams,
      [field]: value,
    });
    history.replace({ search: newSearchParams });
  };

  /**
   * @function
   * @description handle add new custom sql
   */
  const handleAddNewCustomSql = () => {
    resetFollowUpDetails && resetFollowUpDetails({});
    history.push("custom-query");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values: any, actions: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        setFieldValue,
        ...rest
      }: any) => (
        <>
          <$Affix offsetTop={48}>
            <div className="page-header header-border pr-0">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    title={t(
                      "US.COLLECTION.ADMIN:BULKOPERATION.MANAGE_FOLLOWUP"
                    )}
                    onBack={() => handleGoBack(history)}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    type="dashed"
                    size="small"
                    icon={
                      <BoxIcons
                        className="mr-1"
                        type={IconTypes.BOX_ICON}
                        name="new-query"
                      />
                    }
                    disabled
                  >
                    {t("US.COLLECTION.ADMIN:BULKOPERATION.NEW_QUERY")}
                  </$Button>
                  <$Button
                    type="dashed"
                    size="small"
                    onClick={() => handleAddNewCustomSql()}
                    icon={
                      <BoxIcons
                        className="mr-1"
                        type={IconTypes.BOX_ICON}
                        name="custom-sql"
                      />
                    }
                    className="ml-2"
                  >
                    {t("US.COLLECTION.ADMIN:BULKOPERATION.CUSTOM_SQL")}
                  </$Button>
                  <div className="d-flex align-items-center ml-3">
                    <$Switch
                      size="small"
                      name="isCreatedByMe"
                      checked={isSelfCreated}
                      onChange={(value: boolean) => {
                        addSearchParams("self-created", value.toString());
                      }}
                    />
                    <label className="ml-2">
                      {t("US.COLLECTION.ADMIN:BULKOPERATION.CREATED_BY_ME")}
                    </label>
                  </div>
                </div>
                <div className="pr-3">
                  <$Tooltip title={t("COMMON.REFRESH")}>
                    <$Button
                      type="dashed"
                      size="small"
                      icon={<ReloadOutlined />}
                      onClick={() =>
                        getFollowups &&
                        getFollowups({
                          categoryId:
                            categoryId.toLowerCase() ==
                            DefaultCategory.categoryID
                              ? -1
                              : categoryId,
                          isUserFollowup: false,
                        })
                      }
                    />
                  </$Tooltip>
                </div>
              </div>
            </div>
          </$Affix>

          <div className="bo-layout">
            <$Affix offsetTop={80}>
              <aside className="bo-layout-side">
                <div className="pt-3">
                  {/* <FollowupCategory /> */}
                  <ManageFollowUpLeftPanel
                    dataSource={data}
                    isLoading={isFetching}
                    valueField="categoryID"
                    labelField="categoryName"
                    defaultSelectedKeys={[categoryId.toLowerCase()]}
                  />
                </div>
              </aside>
            </$Affix>
            <div className="flex-fill">
              <div className="bo-layout-content">
                <div>
                  <Followups />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { manageFollowups } = state;
  const { categories } = manageFollowups;
  return {
    categories,
  };
};
const mapDispatchToProps = {
  getCategories: categories.get,
  getFollowups: followups.get,
  resetFollowUpDetails: followUpDetails.reset,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(BulkOperation);
