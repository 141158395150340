const initialState: any = {
	postalAreas: [],
	municiples: [],
	newPostalAreaSucessMsg: '',
	isLoading: false,
	error: null,
};

export default (state = initialState, action: any) => {
	let newState = state;

	switch (action.type) {
		case 'GET_POSTAL_AREAS':
			newState = {
				...state,
				isLoading: true,
			};
			break;

		case 'GET_POSTAL_AREAS_SUCCESS':
			newState = {
				...state,
				postalAreas: action.payload,
				isLoading: false,
				error: null,
			};
			break;

		case 'GET_POSTAL_AREAS_FAIL':
			newState = {
				...state,
				postalAreas: [],
				isLoading: false,
				error: null,
			};
			break;

		case 'GET_MUNICIPALS_SUCCESS':
			newState = {
				...state,
				municiples: action.payload.data,
				error: null,
			};
			break;

		case 'ADD_POSTAL_AREA_SUCCESS':
			newState = {
				...state,
				newPostalAreaSucessMsg: action.payload,
				error: null,
			};
			break;

		default:
			break;
	}
	return newState;
};
