import { takeLatest, put, call } from "redux-saga/effects";
import * as API from "us.collection.admin/services";
import * as Actions from "us.collection.admin/actions";
import { NonProductionDay } from "us.collection.admin/constants/Actions";
import { $MessageBox } from "us.common/components";

const { nonProductionDays_New } = Actions;

const NonProductionDaySagas = {
  calendar: {
    update: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.calendar.update, params);
        $MessageBox(
          "success",
          `US.COMMON:MESSAGES.SUCCESSFULLY_UPDATED`,
          "",
          ""
        );
        yield put(nonProductionDays_New.calendar.success(data));
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.CALENDAR_UPDATE_FAILED",
          "",
          ""
        );
        yield put(nonProductionDays_New.calendar.fail(error as any));
      }
    },
    init: function* (action: any): any {
      try {
        const params = action?.payload?.data ?? {};
        const data = yield call(API.nonProductionDays.calendar.init, params);
        if (nonProductionDays_New.calendar.initSuccess) {
          yield put(nonProductionDays_New.calendar.initSuccess(data));
        }
      } catch (error) {
        if (nonProductionDays_New.calendar.initFail) {
          yield put(nonProductionDays_New.calendar.initFail(error as any));
        }
      }
    },
  },

  ScheduleTimeSlots: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.ScheduleTimeSlots.get, params);
        yield put(nonProductionDays_New.ScheduleTimeSlots.success(data));
      } catch (error) {
        yield put(nonProductionDays_New.ScheduleTimeSlots.fail(error as any));
      }
    }
  },
  redDays: {
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.nonProductionDays.redDays.get, params);
        yield put(nonProductionDays_New.redDays.success(data));
      } catch (error) {
        yield put(nonProductionDays_New.redDays.fail(error as any));
      }
    },
  },
};

export default [
  takeLatest(
    NonProductionDay.GET_SCHEDULES_TIME_SLOTS,
    NonProductionDaySagas.ScheduleTimeSlots.get
  ),
  takeLatest(
    NonProductionDay.GET_NON_PRODUCTION_DAYS,
    NonProductionDaySagas.redDays.get
  ),
  takeLatest(
    NonProductionDay.SAVE_CALENDAR,
    NonProductionDaySagas.calendar.update
  ),
  takeLatest(
    NonProductionDay.INIT_NON_PRODUCTION,
    NonProductionDaySagas.calendar.init
  ),
];
