import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { $Button, $Col, $Message, $Row, $Tooltip } from 'us.common/components';
import { CloseOutlined } from 'us.icons';

import { TabResults } from './Components';
import { SearchFilters } from './Components';
import { ISearchQuery } from 'us.common/interfaces';
import {
	ICaseSearchResult,
	ICreditorGroupSearchResult,
	ICreditorSearchResult,
	IDebtorARSearchResult,
	IDebtorCaseSearchResult,
	IDebtorSearchResult,
	IInvoiceSearchResult,
} from 'us.common/reducers/MainSearch/Interfaces';
import { ConnectedProps, connect } from 'react-redux';
import { MainSearchActions } from 'us.common/actions';
import QueryString from 'query-string';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { DebtorSubCriterias, MainCriterias } from 'us.common/constants';
import {
	getCreditorValSorted,
	getDebtorDataFiltered,
	getDebtorValSorted,
	getQueryObject,
	getQueryStringParsed,
	getCreditorDataFiltered,
	getNewHitValue,
	getSelectedTab,
	isTabVisible,
	getDebtorArData,
	getTabStateHolderHistory,
	filterTabResult,
} from './Functions';
import {
	CombinedKeyType,
	IChangeSortOrderEvent,
	IFilterValues,
	ISearchRequestObject,
	ITabsInfo,
	PaginationDirection,
	SortOrderType,
} from './Interfaces';
import moment from 'moment';
import { CreditorStatusTypes } from './Constants';
import './MainSearch.scss';
import { RootState } from 'us.helper/types';
import _ from 'lodash';

export const MainSearch: React.FC<PropsFromRedux & RouteComponentProps> = (
	props
) => {
	const { t } = useTranslation();

	const {
		caseSearchResults,
		creditorSearchResults,
		debtorSearchResults,
		invoiceSearchResults,
		backNavigation,
		viewedList,
		searchAll,
		searchInvRef,
		searchCase,
		searchCreditor,
		searchDebtor,
		searchInvoice,
		DebtorUpdate,
		CreditorUpdate,
		setNavigation,
	} = props;
	const history = useHistory();
	const location = useLocation();
	const parsedQueryValue: ISearchQuery = useMemo(() => {
		return getQueryStringParsed(QueryString.parse(location.search));
	}, [location.search.toString()]);

	const [tabStateHolder, setTabStateHolder] = useState<ITabsInfo>({
		caseTab: {
			sortKey: 'exCaseNo',
			sortOrder: SortOrderType.DESC,
			hitValue: 1,
			isTabVisible: isTabVisible(MainCriterias.CASE, parsedQueryValue),
		},
		invoiceTab: {
			sortKey: 'exCaseNo',
			sortOrder: SortOrderType.DESC,
			hitValue: 1,
			isTabVisible: isTabVisible(MainCriterias.INVOICE, parsedQueryValue),
		},
		creditorTab: {
			sortKey: 'groupName',
			sortOrder: SortOrderType.ASC,
			hitValue: 1,
			isTabVisible: isTabVisible(MainCriterias.CREDITOR, parsedQueryValue),
		},
		debtorTab: {
			sortKey: 'debtorName',
			sortOrder: SortOrderType.ASC,
			hitValue: 1,
			isTabVisible: isTabVisible(MainCriterias.DEBTOR, parsedQueryValue),
		},
		selectedTab: getSelectedTab(parsedQueryValue),
		filterValues: {
			isOpenCase: true,
			isNextDueDateCase: true,
			isCloseCase: true,
			isCourtCase: true,
			isSentenceCase: true,
			caseNumber: '',
			invoiceNumber: '',
			mainAmount: '',
			balance: '',
			debtorNameNo: '',
			birthdate: '',
			creditorNameNo: '',
			phone: '',
			address: '',
			lastMsgActivityName: '',
			creditorStatus: CreditorStatusTypes.ACTIVE,
			creditorGroupIdName: '',
			custId: '',
			arNo: '',
		},
	});

	useEffect(() => {
		if (
			(backNavigation && backNavigation.isBack) ||
			(history.action === 'POP' && viewedList?.length > 0)
		) {
			const tabStateHistory = getTabStateHolderHistory(
				tabStateHolder,
				backNavigation
			);
			setTabStateHolder({
				...tabStateHolder,
				...tabStateHistory,
			});
		}
	}, []);

	useEffect(() => {
		if (
			(backNavigation && backNavigation.isBack) ||
			(history.action === 'POP' && viewedList?.length > 0)
		) {
			const oldSearchQuery = getQueryStringParsed(
				QueryString.parse(backNavigation.searchString)
			);
			if (!_.isEqual(oldSearchQuery, parsedQueryValue)) {
				updateTabs(backNavigation.currentHit);
				setNavigation && setNavigation({ ...backNavigation, searchString: '' });
			}
		} else {
			updateTabs();
		}
	}, [parsedQueryValue]);

	const updateTabs = (hitValue?: number) => {
		callTheAppropriateEndPoints({ ...parsedQueryValue });
		const selectedTab = getSelectedTab(parsedQueryValue);
		setTabStateHolder((tabState) => ({
			...tabState,
			invoiceTab: {
				...tabState.invoiceTab,
				isTabVisible: isTabVisible(MainCriterias.INVOICE, parsedQueryValue),
				hitValue:
					selectedTab == MainCriterias.INVOICE && hitValue ? hitValue : 1,
			},
			caseTab: {
				...tabState.caseTab,
				isTabVisible: isTabVisible(MainCriterias.CASE, parsedQueryValue),
				hitValue: selectedTab == MainCriterias.CASE && hitValue ? hitValue : 1,
			},
			debtorTab: {
				...tabState.debtorTab,
				isTabVisible: isTabVisible(MainCriterias.DEBTOR, parsedQueryValue),
				hitValue:
					selectedTab == MainCriterias.DEBTOR && hitValue ? hitValue : 1,
			},
			creditorTab: {
				...tabState.creditorTab,
				isTabVisible: isTabVisible(MainCriterias.CREDITOR, parsedQueryValue),
				hitValue:
					selectedTab == MainCriterias.CREDITOR && hitValue ? hitValue : 1,
			},
			selectedTab,
		}));
	};

	/**
	 * @function
	 * @description To toggle ar data between expand and collapse
	 * @param {IDebtorSearchResult} debtorRow The debtor search result row clicked
	 */
	const handleDebtorARClick = ({ debtorEntNo }: IDebtorSearchResult): void => {
		const debtorArData = getDebtorArData(
			debtorEntNo,
			debtorSearchResults.data as IDebtorSearchResult[]
		);
		DebtorUpdate && DebtorUpdate(debtorArData);
	};

	/**
	 * @function
	 * @description To toggle creditor data of creditor group search result between expand and collapse
	 * @param {ICreditorGroupSearchResult} creditorGroupSearch The creditor-group search result row clicked
	 */
	const handleCreditorGroupClick = (
		creditorGroupSearch: ICreditorGroupSearchResult
	) => {
		CreditorUpdate &&
			CreditorUpdate(
				(creditorSearchResults.data as ICreditorGroupSearchResult[]).map(
					(creditorGroup: ICreditorGroupSearchResult) => {
						if (creditorGroup.groupId === creditorGroupSearch.groupId) {
							return {
								...creditorGroup,
								isCreditorGroupCollapsed:
									!creditorGroup.isCreditorGroupCollapsed,
							};
						} else {
							return { ...creditorGroup };
						}
					}
				)
			);
	};

	/**
	 * @function
	 * @description To call the proper API endpoint based on the search query and reset the pagination
	 * @param {ISearchQuery} objectParsedQuery The search queyr URL parsed value
	 * @param {number} hit The current search page
	 */
	const callTheAppropriateEndPoints = (
		objectParsedQuery: ISearchQuery,
		hit: number = 1
	) => {
		const searchQueryObject: ISearchRequestObject = getQueryObject(
			objectParsedQuery,
			hit
		);
		switch (objectParsedQuery.cat) {
			case MainCriterias.INVOICE_REF:
				searchInvRef && searchInvRef(searchQueryObject);
				break;
			case MainCriterias.INVOICE:
				searchInvoice && searchInvoice(searchQueryObject);
				break;
			case MainCriterias.CASE:
				searchCase && searchCase(searchQueryObject);
				break;
			case MainCriterias.CREDITOR:
				searchCreditor && searchCreditor(searchQueryObject);
				break;
			case MainCriterias.DEBTOR:
				const { SecValue, SearchValue } = searchQueryObject;
				if (
					searchQueryObject.FieldType === DebtorSubCriterias.BIRTHDAY &&
					(SearchValue + SecValue).trim() &&
					!moment((SearchValue + SecValue).trim(), 'YYYY-MM-DD', true).isValid()
				) {
					$Message.warning(
						t('US.COLLECTION.COMMON:COMMON.BIRTHDAY_IS_IN_INVALID_FORMAT')
					);
					return;
				}
				searchDebtor && searchDebtor(searchQueryObject);
				break;
			default:
				searchAll && searchAll(searchQueryObject);
				break;
		}
	};

	/**
	 * @function
	 * @description To call API based on the pagination values
	 * @param {PaginationDirection} direction whether the page navigation is forward or backward.
	 * @param {currentTab} currentTab The current tab we have opened.
	 */
	const handlePagination = (
		direction: PaginationDirection,
		currentTab: MainCriterias
	) => {
		let newHitValue: number = 1;
		setTabStateHolder((tabstate) => {
			switch (currentTab) {
				case MainCriterias.INVOICE:
					newHitValue = getNewHitValue(
						tabStateHolder.invoiceTab.hitValue,
						direction
					);
					searchInvoice &&
						searchInvoice(getQueryObject(parsedQueryValue, newHitValue));
					return {
						...tabstate,
						invoiceTab: {
							...tabstate.invoiceTab,
							hitValue: newHitValue,
						},
					};
				case MainCriterias.CASE:
					newHitValue = getNewHitValue(
						tabStateHolder.caseTab.hitValue,
						direction
					);
					searchCase &&
						searchCase(getQueryObject(parsedQueryValue, newHitValue));
					return {
						...tabstate,
						caseTab: {
							...tabstate.caseTab,
							hitValue: newHitValue,
						},
					};
				case MainCriterias.DEBTOR:
					newHitValue = getNewHitValue(
						tabStateHolder.debtorTab.hitValue,
						direction
					);
					searchDebtor &&
						searchDebtor(getQueryObject(parsedQueryValue, newHitValue));
					return {
						...tabstate,
						debtorTab: {
							...tabstate.debtorTab,
							hitValue: newHitValue,
						},
					};
				case MainCriterias.CREDITOR:
					newHitValue = getNewHitValue(
						tabStateHolder.debtorTab.hitValue,
						direction
					);
					searchCreditor &&
						searchCreditor(getQueryObject(parsedQueryValue, newHitValue));
					return {
						...tabstate,
						creditorTab: {
							...tabstate.creditorTab,
							hitValue: newHitValue,
						},
					};
				default:
					return tabstate;
			}
		});
	};

	const tabChangeEventHandler = (selectedTab: MainCriterias) => {
		setTabStateHolder((preVals) => {
			return {
				...preVals,
				selectedTab,
			};
		});
	};

	const changeSortOrderEventHandler: IChangeSortOrderEvent = (
		key: keyof CombinedKeyType,
		direction?: SortOrderType
	) => {
		switch (tabStateHolder.selectedTab) {
			case MainCriterias.INVOICE:
				setTabStateHolder((preTabState) => {
					return {
						...preTabState,
						invoiceTab: {
							...preTabState.invoiceTab,
							sortKey: key as keyof IInvoiceSearchResult,
							sortOrder: direction ? direction : SortOrderType.ASC,
						},
					};
				});
				break;
			case MainCriterias.CASE:
				setTabStateHolder((preTabState) => {
					return {
						...preTabState,
						caseTab: {
							...preTabState.caseTab,
							sortKey: key as keyof ICaseSearchResult,
							sortOrder: direction ? direction : SortOrderType.ASC,
						},
					};
				});
				break;
			case MainCriterias.DEBTOR:
				setTabStateHolder((preTabState) => {
					return {
						...preTabState,
						debtorTab: {
							...preTabState.debtorTab,
							sortKey: key as keyof IDebtorSearchResult &
								IDebtorARSearchResult &
								IDebtorCaseSearchResult,
							sortOrder: direction ? direction : SortOrderType.ASC,
						},
					};
				});
				break;
			case MainCriterias.CREDITOR:
				setTabStateHolder((preTabState) => {
					return {
						...preTabState,
						creditorTab: {
							...preTabState.creditorTab,
							sortKey: key as keyof ICreditorGroupSearchResult &
								ICreditorSearchResult,
							sortOrder: direction ? direction : SortOrderType.ASC,
						},
					};
				});
				break;

			default:
				break;
		}
	};

	return (
		<div className='space-content search-results'>
			<$Row gutter={16}>
				<$Col span={4} xxl={{ span: 3, offset: 1 }}>
					<SearchFilters
						tabStates={tabStateHolder}
						filterValueChangeHandler={(values: IFilterValues) => {
							setTabStateHolder((preState) => {
								return {
									...preState,
									filterValues: { ...values },
								};
							});
						}}
					/>
				</$Col>
				<$Col
					span={20}
					offset={0}
					xxl={{ span: 19, offset: 1 }}
					className='pt-3'>
					{parsedQueryValue.value && (
						<h3 className='mb-2'>
							{t('US.COLLECTION.COMMON:COMMON.SEARCH_RESULTS_FOR')}{' '}
							{`"${parsedQueryValue.value.trim()}"`}
						</h3>
					)}
					{!parsedQueryValue.value && (
						<h3 className='mb-2'>
							{t('US.COLLECTION.COMMON:COMMON.SEARCH_RESULTS')}
						</h3>
					)}
					<TabResults
						{...props}
						invoices={filterTabResult(
							invoiceSearchResults.data,
							tabStateHolder.filterValues,
							tabStateHolder.invoiceTab.sortKey,
							tabStateHolder.invoiceTab.sortOrder
						)}
						cases={filterTabResult(
							caseSearchResults.data,
							tabStateHolder.filterValues,
							tabStateHolder.caseTab.sortKey,
							tabStateHolder.caseTab.sortOrder
						)}
						debtors={getDebtorValSorted(
							getDebtorDataFiltered(
								debtorSearchResults.data ?? [],
								tabStateHolder.filterValues
							),
							tabStateHolder.debtorTab
						)}
						creditors={getCreditorValSorted(
							getCreditorDataFiltered(
								creditorSearchResults.data ?? [],
								tabStateHolder.filterValues
							),
							tabStateHolder.creditorTab
						)}
						isCaseLoading={caseSearchResults.isLoading}
						isCreditorLoading={creditorSearchResults.isLoading}
						isDebtorLoading={debtorSearchResults.isLoading}
						isInvoiceLoading={invoiceSearchResults.isLoading}
						selectedTab={tabStateHolder.selectedTab}
						tabInfo={tabStateHolder}
						tabChangeEvent={tabChangeEventHandler}
						totalInvoiceSearchResult={invoiceSearchResults.data?.length}
						totalCaseSearchResult={caseSearchResults.data?.length}
						totalDebtorSearchResult={debtorSearchResults.data?.length}
						totalCreditorSearchResult={creditorSearchResults.data?.length}
						paginationChangeEvent={(direction, selectedTab) =>
							handlePagination(direction, selectedTab)
						}
						aRClickEvent={handleDebtorARClick}
						creditorGroupClickEvent={handleCreditorGroupClick}
						changeSortOrderEvent={changeSortOrderEventHandler}
					/>
				</$Col>
			</$Row>
			<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.CLOSE')}>
				<$Button
					className='search-results-close'
					onClick={() => history.goBack()}
					icon={<CloseOutlined />}
					data-testid='searchResults-close'
				/>
			</$Tooltip>
		</div>
	);
};

const mapStateToProps = (state: RootState) => {
	const { mainSearch } = state;
	const {
		case: caseSearchResults,
		creditor: creditorSearchResults,
		debtor: debtorSearchResults,
		invoice: invoiceSearchResults,
		backNavigation,
		viewedList,
	} = mainSearch;

	return {
		caseSearchResults,
		creditorSearchResults,
		debtorSearchResults,
		invoiceSearchResults,
		backNavigation,
		viewedList,
	};
};

const {
	all,
	invoiceRef,
	invoice,
	case: mainSearchCase,
	creditor,
	debtor,
} = MainSearchActions;

const mapDispatchToProps = {
	searchAll: all.search,
	searchInvRef: invoiceRef.search,
	searchInvoice: invoice.search,
	searchCase: mainSearchCase.search,
	searchCreditor: creditor.search,
	searchDebtor: debtor.search,
	DebtorUpdate: debtor.updateData,
	CreditorUpdate: creditor.updateData,
	setNavigation: all.set,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(React.memo(MainSearch));
