const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    userRole: '',
    accessToken: null,
    authRedirectPath: '/',
    isAuthenticated: false,
    error: null,
    isCheckAuth: false,
    userFeatures: null
}

export default (state = initialState, action: any) => {
    let newState = state;

    switch (action.type) {

        case 'AUTH_SUCCESS':
            newState = {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                userRole: action.payload.userRole,
                authRedirectPath: action.payload.authRedirectPath,
                accessToken: action.payload.token,
                isAuthenticated: true,
                userFeatures: action.payload.userFeatures,
                error: null,
            }

            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('username', action.payload.email);
            break;
        case 'AUTH_FAIL':
            newState = {
                ...state,
                isAuthenticated: false,
            }
            localStorage.removeItem('token');

            break;

        case 'AUTH_CHECK_USER_DATA':
            newState = {
                ...state,
                userFeatures: action.payload.userFeatures,
                isAuthenticated: true,
                isCheckAuth: true
            }
            break;
        case 'SET_AUTH_REDIRECT_PATH':
            newState = {
                ...state,
                authRedirectPath: action.payload
            }
            break;
        case 'LOGOUT':
            console.log('Log out from app');
            newState = {
                ...state,
                isAuthenticated: false,
                isCheckAuth: true
            }
            break;

        default:
            break;
    }
    return newState;

}