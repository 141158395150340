import React from "react";
import { NonExecutionDay } from "../NonExecutionDays";
import { $Col, $Collapse, $Row, SelectedDays } from "us.common/components";
import { useTranslation } from "react-i18next";

/**
 * @description Non Email Days Home Component
 * @link Design document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3049914453/Non+Email+Days+UI+Design
 * @author Rajitha Sanjayamal <rajithasa@unicorn-solutions.com>
 * @since 21/07/2022
 */
const NonEmailDays: React.FC = () => {
  const { t } = useTranslation();

  return (
    <$Collapse className="non-sms-days" ghost defaultActiveKey={["1", "2"]}>
      <$Collapse.Panel
        header={t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.NON_EMAIL_DAYS")}
        key="1"
      >
        <$Row gutter={16}>
          <$Col xl={{ span: 12 }} xxl={{ span: 10 }} className="px-3">
            <NonExecutionDay />
          </$Col>
        </$Row>
      </$Collapse.Panel>
      <$Collapse.Panel
        header={t("US.COLLECTION.ADMIN:NON_PRODUCTION_DAYS.EMAIL_ALLOWED_TIME")}
        key="2"
      >
        <$Row gutter={16}>
          <$Col xl={{ span: 12 }} xxl={{ span: 10 }} className="px-3">
            <SelectedDays name={"nonEmail"} />
          </$Col>
        </$Row>
      </$Collapse.Panel>
    </$Collapse>
  );
};

export default NonEmailDays;
