export enum MunicipalityColumns {
  MENU = "menu",
  MUNICIPALITY_ID = "municipalityId",
  MUNICIPALITY_CODE = "municipalityCode",
  MUNICIPALITY_NAME = "municipalityName",
  COUNTRY_NAME = "countryName",
  COUNTRY_CODE = "countryCode",
}

export const INITIAL_FORM_VALUES = {
  municipalityId: -1,
  municipalityCode: "",
  municipalityName: "",
  countryCode: "",
};
