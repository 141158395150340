import { AuthorizationLevel } from "us.collection/constants";

/**
 * Get GDPR view level 
 * @param accessAuthorityLevel User authority level
 * @param isCommentEnable Default comment enable or disable
 * @returns GDPR comment box display or not
 */
export const getGDPRViewDetails = (
  accessAuthorityLevel: number,
  isCommentEnable: boolean
): boolean => {
  try {
    if (
      accessAuthorityLevel == AuthorizationLevel.COMPULSARY ||
      accessAuthorityLevel == AuthorizationLevel.OPTIONAL ||
      (accessAuthorityLevel == AuthorizationLevel.CAN_DISABLE &&
        !isCommentEnable)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
