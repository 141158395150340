import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";

import { authStart } from "us.common/actions";
import "./LoginForm.scss";
import { useTranslation } from "react-i18next";


interface ILoginConProps extends RouteComponentProps {
  authStart: any;
}

const LoginCon: React.SFC<ILoginConProps> = (props) => {
  const { t } = useTranslation();
  const { history } = props;


  useEffect(() => {}, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  // Only show error after a field is touched.
  // const usernameError = isFieldTouched('username') && getFieldError('username');
  // const passwordError = isFieldTouched('password') && getFieldError('password');

  return (
    <div className="pt-4 pr-5">
      <div className="mb-4">
        <h1 className="title">Log in</h1>
        <p className="des">Welcome to UnicornBOX</p>
      </div>
      <Form>
        <Form.Item label={t("us.collection.common:login.userName")}>
          <Input />
        </Form.Item>

        <Form.Item label={t("us.collection.common:login.password")}>
          <Input.Password />
        </Form.Item>

        <div className="my-3">
          <Button type="primary" htmlType="submit" className="mb-4" block>
            {t("us.collection.common:login.login")}
          </Button>

          <a href="# ">{t("us.collection.common:login.forgotPassword")}</a>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loginResponce: state.auth,
  };
};

const mapDispatchToProps = {
  authStart,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginCon);
