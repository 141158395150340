import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    amount: Yup.number()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD")
      .test(
        "amount",
        "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD",
        function (val: any) {
          return val != 0;
        }
      ),
    fromDate: Yup.date()
      .nullable()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_GIVE_VALID_RECORD")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_DATE"),
  });
};
