import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { BoxIcons,IconTypes } from 'us.icons';
import './LoginLayout.scss';
interface ILoginLayoutProps extends RouteComponentProps {
  // title: string
}

const LoginLayout: React.SFC<ILoginLayoutProps> = (props) => {

  return (
    <div className="d-flex flex-column login-layout">
      <div className="login-layout-body">
        {props.children}
      </div>
      <div className="login-layout-footer py-2">
        <div className="py-2" ><a href="#">Terms</a> <span className="px-2"></span> <a href="#">Privacy</a></div>
        <p>Copyright Unicorn Solutions AS, All right reserved.</p>
      </div>
    </div>
  )
}

export default LoginLayout;