import { INonProductionDay } from 'us.collection.admin/interfaces';

export function AddOrUpdateNonExecutionDay(this: INonProductionDay) {
    const { id, key, date, reason, criteria, isFixedDate } = this
    return {
        id,
        key,
        date,
        reason,
        criteria,
        isFixedDate
    }
}